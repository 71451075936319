import {
  default as cssVariables,
  default as scssVariables,
} from '@work4all/assets/theme/variablesDark.scss';

import { useEventCallback } from '@mui/material/utils';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  ColumnInstance,
  Row,
  UseColumnOrderState,
  UseResizeColumnsState,
} from 'react-table';
import { AutoSizer } from 'react-virtualized';
import { VariableSizeList } from 'react-window';

import { useRemToPx } from '@work4all/data/lib/hooks/useRemToPx';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { CardConfig } from '@work4all/models/lib/table-schema/card-config';

import { reactRefSetter } from '@work4all/utils';

import { IBasicTableProps } from '../../BasicTable';
import { useTableStateBag } from '../../hooks/useTableStateBag';
import { ICssClasses, TableMode, TableRow } from '../../types';
import { SEPARATOR_ROW_HEIGHT } from '../row-render/components/SeparatorRow/SeparatorRow';
import { RenderRow } from '../row-render/RenderRow';
import { accesByString } from '../utils';

import { InfiniteLoader } from './components/InfiniteLoader';

export interface ILoaderProps {
  cardsView: boolean;
  cardConfig: CardConfig | null;
  // selectedRowIds: UseRowSelectState<never>['selectedRowIds'];
  columnResizing: UseResizeColumnsState<never>['columnResizing'];
  columnOrder: UseColumnOrderState<never>['columnOrder'];
  prepareRow: (row: Row) => void;
  rows: TableRow[];
  allItemsCount?: IBasicTableProps['allItemsCount'];
  loadMoreItems?: (
    startIndex: number,
    stopIndex: number,
    rows: TableRow[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => Promise<any> | null | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isItemLoaded?: (index: number, row: Row<any>) => boolean;
  onRowDoubleClick?: (id: string) => void;
  onRowClick?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    row: TableRow
  ) => void;
  onRowContextMenu?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    row: TableRow
  ) => void;
  // expandedRowIds: UseExpandedState<never>['expanded'];
  mode: TableMode;
  classes?: ICssClasses;
  rowHeightRem?: string;
  visibleColumns: ColumnInstance[];
  customVisibleColumns?: string[];
  manualGroupBy?: boolean;
  isVirtual: boolean;
  fitContentHeight?: boolean;
  threshold?: number;
  minimumBatchSize?: number;
  width: number;
  onScroll?: (params: {
    scrollLeft: number;
    scrollWidth: number;
    clientWidth: number;
    scrollTop: number;
    scrollHeight: number;
    clientHeight: number;
  }) => void;
  /**
   * On mobile devices we show a toolbar at the bottom of the container. Pass
   * the toolbar's height here ot add as much bottom padding to the table
   * container, so the toolbar does not obstruct the last row.
   */
  bottomPadding?: string | null;

  scrollRef?: React.MutableRefObject<HTMLDivElement>;

  entity?: Entities;
}

/**
 * It's kinda dangerous to use React memo here:
 * Rows are lazy loaded. Rows can change even when "rows" array stay the same.
 * So, shallow comparison of React.memo doesn't work here.
 * But without optimizations of List performance is bad
 * "state" object in props guarantees that rows will update when their state change.
 *
 * Problem appears only with "selectedRowIds" state. Any other state change triggers re-rendering
 */

export const Loader = React.forwardRef<VariableSizeList, ILoaderProps>(
  function Loader(props, ref) {
    const { rows, bottomPadding } = props;

    const rem = useRemToPx(1);
    const rowHeight = useRemToPx(
      props.rowHeightRem || cssVariables.basicTableRowHeight
    );

    const tableState = useTableStateBag();

    const resolvedRowHeight = useCallback(
      (row: TableRow) => {
        if (!row) {
          return 0;
        }

        const isCardView = props.cardsView && props.cardConfig;

        if (!isCardView) {
          return rowHeight;
        }

        const cellsById = new Map(
          row.allCells?.map((cell) => [cell.column.id, cell])
        );

        const infoRows =
          props.cardConfig?.info?.filter((info) => {
            return !!tableState?.visibleColumns?.find(
              (column) =>
                column.id === info.key &&
                row?.original &&
                ((!!info.type &&
                  info.columns.some((id) => cellsById.get(id)?.value)) ||
                  props.customVisibleColumns?.some((key) =>
                    Object.prototype.hasOwnProperty.call(row.original, key)
                  ) ||
                  info.columns.find((colId) => {
                    const val = accesByString(row.original, colId);
                    return val && val !== '';
                  }))
            );
          }).length ?? 0;

        const cellPadding = parseFloat(
          scssVariables.basicTableCellPadding || '0'
        );
        const contentSlotHeight = props.cardConfig?.content != null ? 2 : 0;

        // Every slot in a card layout has a fixed height that can be computed from
        // the config.
        //
        // - Icon and Title - 1.5rem + padding top and bottom
        // - Content - 4rem + padding top and bottom
        // - Each Info line - 1rem tall + padding top and bottom
        //
        // + 1rem of padding + 1px bottom border.
        const infoRowHeight = 1.33;
        const titleHeight = 2.5 * cellPadding;
        const cardVerticalPadding = 2 * 0.75;
        const cardHeight =
          rem *
          (titleHeight +
            infoRowHeight * infoRows +
            contentSlotHeight +
            cardVerticalPadding);

        // based on icon + padding
        const minCardHeight = rem * (cardVerticalPadding + 2);
        return Math.max(minCardHeight, cardHeight);
      },
      [
        props.cardConfig,
        props.cardsView,
        props.customVisibleColumns,
        rem,
        rowHeight,
        tableState?.visibleColumns,
      ]
    );

    const listRef = useRef<VariableSizeList>(null);

    const calcItemSize = useCallback(
      (index: number) => {
        const row = rows[index];

        if (row?.isGrouped) {
          return rowHeight;
        }

        const isSectionStart = row?.original.meta?.isSectionStart ?? false;

        if (isSectionStart) {
          return resolvedRowHeight(row) + SEPARATOR_ROW_HEIGHT;
        }

        return resolvedRowHeight(row);
      },
      [rows, resolvedRowHeight, rowHeight]
    );

    useEffect(() => {
      if (listRef.current) {
        listRef.current.resetAfterIndex(0);
      }
    }, [
      tableState?.tableInstance?.state?.expanded,
      tableState?.tableInstance?.rows,
      props.cardsView,
      resolvedRowHeight,
    ]);

    const onScrollProp = useEventCallback(props.onScroll);

    const total =
      props.allItemsCount === 'rowsLength'
        ? props.rows.length
        : props.allItemsCount;

    const [outerElement, setOuterElement] = useState<HTMLDivElement>(null);

    const setOuterRef = props.scrollRef
      ? reactRefSetter(setOuterElement, props.scrollRef)
      : setOuterElement;
    useEffect(() => {
      if (!outerElement) return;

      const handleScroll = () => {
        const {
          scrollLeft,
          scrollWidth,
          clientWidth,
          scrollTop,
          scrollHeight,
          clientHeight,
        } = outerElement;

        onScrollProp?.({
          scrollLeft,
          scrollWidth,
          clientWidth,
          scrollTop,
          scrollHeight,
          clientHeight,
        });
      };

      outerElement.addEventListener('scroll', handleScroll, { passive: true });

      return () => {
        outerElement.removeEventListener('scroll', handleScroll);
      };
    }, [onScrollProp, outerElement]);

    function renderContent({
      width,
      height,
    }: {
      height: number;
      width: number;
    }) {
      if (!props.isVirtual) {
        return (
          <div ref={setOuterRef} style={{ height, width, overflow: 'auto' }}>
            {props.rows.map((_, index) => {
              return (
                <RenderRow key={index} data={props} index={index} style={{}} />
              );
            })}
          </div>
        );
      }

      return (
        <InfiniteLoader
          isItemLoaded={(index) => {
            const r = props.rows[index];
            if (props.isItemLoaded) {
              return props.isItemLoaded(index, r);
            }

            return Boolean(r && !r.original?.skeleton);
          }}
          itemCount={total}
          loadMoreItems={(...args) => {
            return props.loadMoreItems?.(...args, props.rows);
          }}
          mode={props.mode}
          rows={props.rows}
          threshold={props.threshold}
          minimumBatchSize={props.minimumBatchSize}
        >
          {({ onRowsRendered, registerChild }) => (
            <VariableSizeList
              style={{
                '--table-bottom-padding':
                  bottomPadding != null ? bottomPadding : undefined,
              }}
              ref={reactRefSetter(registerChild, ref, listRef)}
              width={width}
              height={height}
              itemSize={calcItemSize}
              estimatedItemSize={resolvedRowHeight(rows[0])}
              itemCount={total}
              overscanCount={3}
              itemData={props}
              onItemsRendered={({
                visibleStartIndex: startIndex,
                visibleStopIndex: stopIndex,
              }) => onRowsRendered?.({ startIndex, stopIndex })}
              outerRef={setOuterRef}
              innerElementType={
                bottomPadding != null ? InnerElementWithPadding : undefined
              }
            >
              {RenderRow}
            </VariableSizeList>
          )}
        </InfiniteLoader>
      );
    }

    if (props.fitContentHeight) {
      return (
        <>
          {props.rows.map((_, index) => {
            return (
              <RenderRow key={index} data={props} index={index} style={{}} />
            );
          })}
        </>
      );
    }
    return <AutoSizer>{renderContent}</AutoSizer>;
  }
);

// Add a padding at the bottom of the list on mobile so that the floating
// buttons do not obstruct the last row.
const InnerElementWithPadding = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(function InnerElementWithPadding({ style, ...rest }, ref) {
  return (
    <div
      ref={ref}
      style={{
        ...style,
        height: `calc(${
          style.height as number
        }px + var(--table-bottom-padding, 0px))`,
      }}
      {...rest}
    />
  );
});
