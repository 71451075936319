import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { IdType } from '../edit-table/types';

interface SelectedPositionProviderState {
  selectedPositionIds?: IdType[];
  setSelectedPositionIds?: (input: IdType[]) => void;
}

const SelectedPositionContext =
  createContext<SelectedPositionProviderState>(null);

export const useSelectedPositionState = () => {
  const context = useContext(SelectedPositionContext);
  if (context === null)
    throw new Error(
      'useSelectedPosition must be used within a <SelectedPositionProvider>'
    );
  return context;
};

export const SelectedPositionProvider = (props: PropsWithChildren<unknown>) => {
  const [selectedPositionIds, setSelectedPositionIdsInternal] = useState<
    IdType[]
  >([]);

  const setSelectedPositionIds = useCallback((newIds: IdType[]) => {
    if (Array.isArray(newIds)) setSelectedPositionIdsInternal(newIds);
  }, []);

  const value = useMemo(() => {
    return { selectedPositionIds, setSelectedPositionIds };
  }, [selectedPositionIds, setSelectedPositionIds]);

  return (
    <SelectedPositionContext.Provider value={value}>
      {props.children}
    </SelectedPositionContext.Provider>
  );
};
