import styles from './statistics-styles.module.scss';

import { Theme, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { StatisticSalesVoumeByItem } from '@work4all/models/lib/Classes/StatisticSalesVoumeByItem.entity';
import { StatisticsSalesVolumeByItemColumn } from '@work4all/models/lib/Classes/StatisticsSalesVolumeByItemColumn.entity';
import { StatisticsSalesVolumeByItemRow } from '@work4all/models/lib/Classes/StatisticsSalesVolumeByItemRow.entity';

import { BasicTable } from '../BasicTable';
import { BasicTableColumn, ICssClasses } from '../types';
import { CurrencyCell } from '../utils';

import { getStatisticFormatedDate } from './getStatisticFormatedDate';

interface StatisticSalesVolumeByItemTableProps {
  data: StatisticSalesVoumeByItem;
  tableClasses?: ICssClasses;
}

interface MonthDefinition {
  amount: number;
  date: string;
}

interface StatisticItemRemodeled {
  artikel: Article;
  sum: number;
  months: MonthDefinition[];
}

const GetTotalSum = (
  months: StatisticsSalesVolumeByItemColumn[],
  rowIndex: number
) => {
  let result = 0;
  months.forEach((column) => {
    result += column.rows?.[rowIndex].amount;
  });

  return result;
};

const GetTotalMonthValue = (data: StatisticItemRemodeled[], month: number) => {
  let result = 0;

  data.forEach((row) => {
    result += row.months[month].amount;
  });

  if (result === 0) {
    return '';
  } else {
    return (
      <CurrencyCell
        className={clsx({ [styles.red]: result < 0 })}
        value={result}
        maximumFractionDigits={0}
        minimumFractionDigits={0}
        hideUnit={false}
      />
    );
  }
};

const GetTotalTotalSumValue = (data: StatisticItemRemodeled[]) => {
  let result = 0;

  data.forEach((row) => {
    result += row.sum;
  });

  if (result === 0) {
    return '';
  } else {
    return (
      <CurrencyCell
        className={clsx({ [styles.red]: result < 0 })}
        value={result}
        maximumFractionDigits={0}
        minimumFractionDigits={0}
        hideUnit={false}
      />
    );
  }
};

export const StatisticSalesVolumeByItemTable: React.FC<
  StatisticSalesVolumeByItemTableProps
> = (props) => {
  const { data, tableClasses } = props;

  const isViewportDownMd = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('md')
  );

  const { t } = useTranslation();

  const newData: StatisticItemRemodeled[] = useMemo(() => {
    let result: StatisticItemRemodeled[] = [];

    if (data.columns === undefined) {
      return [];
    }
    const months = [...data.columns].sort((a, b) => {
      if (new Date(a.rangeStart) > new Date(b.rangeStart)) {
        return 1;
      }
      if (new Date(a.rangeStart) < new Date(b.rangeStart)) {
        return -1;
      }
      return 0;
    });

    months?.[0].rows.forEach(
      (row: StatisticsSalesVolumeByItemRow, rowIndex) => {
        const newItem: StatisticItemRemodeled = {
          artikel: row.artikel,
          sum: GetTotalSum(months, rowIndex),
          months: months.map((col) => {
            return {
              amount: col.rows?.[rowIndex].amount,
              date: col.rows?.[rowIndex].date,
            };
          }),
        };
        result.push(newItem);
      }
    );

    result = result.sort((a, b) => {
      if (a.sum > b.sum) {
        return -1;
      }
      if (a.sum < b.sum) {
        return 1;
      }
      return 0;
    });

    return result;
  }, [data]);

  const columns = useMemo<BasicTableColumn[]>(() => {
    const defaultProperties: BasicTableColumn = {
      accessor: '',
      width: 130,
      Cell: ({ value }: { value: number }) => {
        if (value === 0) {
          return '';
        }
        return (
          <CurrencyCell
            className={clsx({ [styles.red]: value < 0 })}
            value={value}
            maximumFractionDigits={0}
            minimumFractionDigits={0}
            hideUnit={true}
          />
        );
      },
      alignment: 'right',
    };

    let result: BasicTableColumn[] = [
      {
        Header: t('COMMON.ARTICLE'),
        accessor: 'artikel.name',
        sticky: 'left',
        width: isViewportDownMd ? 150 : 300,
      },

      {
        ...defaultProperties,
        Header: t('COMMON.SUM'),
        accessor: 'sum',
        Footer: GetTotalTotalSumValue(newData),
        Cell: ({ value }: { value: number }) => {
          if (value === 0) {
            return '';
          }
          return (
            <CurrencyCell
              className={clsx({ [styles.red]: value < 0 })}
              value={value}
              maximumFractionDigits={0}
              minimumFractionDigits={0}
              hideUnit={true}
            />
          );
        },
      },
    ];

    if (newData[0] !== undefined) {
      result = result.concat(
        newData?.[0].months.map((month, monthIndex) => {
          return {
            ...defaultProperties,
            Header: getStatisticFormatedDate(new Date(month.date), 'MMM yyyy'),
            accessor: `months[${monthIndex}].amount`,
            Footer: GetTotalMonthValue(newData, monthIndex),
          };
        })
      );
    }

    return result;
  }, [newData, t, isViewportDownMd]);

  const apiRef = useRef<TableInstance>(null);

  return (
    <BasicTable
      displayFooter={true}
      mode="client"
      ref={apiRef}
      reordableColumns={true}
      resizableColumns={false}
      selectableRows={true}
      data={newData}
      columns={columns}
      allItemsCount={newData.length || 0}
      cardsView={false}
      selectableMultiple={false}
      className={styles.table}
      classes={tableClasses}
    />
  );
};
