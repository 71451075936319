import { gql, useMutation } from '@apollo/client';
import { nanoid } from 'nanoid';
import React, { PropsWithChildren, useCallback, useState } from 'react';
import { deepEqual } from 'rxdb';
import { Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ObjectLockResult } from '@work4all/models/lib/Classes/ObjectLockResult.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ObjectLockResultEnum } from '@work4all/models/lib/Enums/ObjectLockResultEnum.enum';
import {
  ObjectTypeByEntity,
  ObjectTypesUnion,
} from '@work4all/models/lib/GraphQLEntities/Entities';

import { isDev } from '@work4all/utils';

import { LockContext } from './LockContext';
import { ILockInfo } from './types';

// Duplicated from mask-metadata.tsx to not import app to library
const NEW_ENTITY_ID = 'new';

const SET_LOCK = gql`
  mutation SetObjectLock(
    $objectType: ObjectType!
    $objectPrimaryKey: [PrimaryKey]!
    $application: String
  ) {
    setObjectLock(
      objectType: $objectType
      objectPrimaryKey: $objectPrimaryKey
      application: $application
    ) {
      lockResult
      application
      ownerCode
      user: benutzer {
        id: code
        firstName: vorname
        lastName: nachname
        displayName: anzeigename
      }
    }
  }
`;

interface SetLockResponse {
  setObjectLock: ObjectLockResult;
}

interface SetLockVars {
  objectType: ObjectTypesUnion;
  objectPrimaryKey: string[];
  application: string;
}

const REMOVE_LOCK = gql`
  mutation RemoveObjectLock(
    $objectType: ObjectType!
    $objectPrimaryKey: [PrimaryKey]!
    $application: String!
  ) {
    removeObjectLock(
      objectType: $objectType
      objectPrimaryKey: $objectPrimaryKey
      application: $application
    )
  }
`;

interface RemoveLockResponse {
  removeObjectLock: ObjectLockResult;
}

interface RemoveLockVars {
  objectType: ObjectTypesUnion;
  objectPrimaryKey: string[];
  application: string;
}

/**
 * we keep the nanoId arround in the tabs specific session, so that we can lock pending edits in different active tabs agains each other
 * session storage is rest when tab is closed - this has one drawback its not working when a tab is duplicated, as this copies the session storage
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage?retiredLocale=de#content
 */
if (!sessionStorage.getItem('nanoid')) {
  sessionStorage.setItem('nanoid', nanoid());
}

const application = `work4all 2.0 ${
  navigator.userAgent
} ${sessionStorage.getItem('nanoid')}`;

export const LockProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [setLock] = useMutation<SetLockResponse, SetLockVars>(SET_LOCK);

  const [removeLock] = useMutation<RemoveLockResponse, RemoveLockVars>(
    REMOVE_LOCK
  );

  let setLockSubscription: Subscription | undefined;

  const lock = useCallback(
    (args: {
      subEntityType?: Entities;
      subEntityIds?: string[];
      forcedObjectType?: ObjectTypesUnion;
    }) => {
      if (!args) return;
      const { subEntityType, subEntityIds, forcedObjectType } = args;

      if (subEntityIds[0] === NEW_ENTITY_ID) {
        setObjectLockValue((p) => ({
          ...p,
          locked: false,
          user: null,
        }));
        return;
      }

      const objectType = forcedObjectType || ObjectTypeByEntity[subEntityType];

      // There are two possible outcomes when trying to acquire a lock:
      //
      // 1. Acquired the lock successfully. In this case we need to refresh the
      //    lock until the mask is closed;
      // 2. Couldn't acquire the lock because the entity is already locked by
      //    someone else. In this keep trying again until we get the lock so that
      //    we can switch the mask to "write" mode.

      setLockSubscription && setLockSubscription.unsubscribe();

      if (!objectType || !subEntityIds || subEntityIds.length === 0) {
        // ToDo this happens for navigateing from businesspartners to contacts and back with history stack
        // ToDo: https://work4all.atlassian.net/browse/WW-4090
        if (isDev()) {
          console.warn('requesting object lock for undefined object');
        }
        return;
      }

      setObjectLockValue((p) => {
        return {
          ...p,
          loading: true,
        };
      });

      setLockSubscription = timer(0, 10 * 1000)
        .pipe(
          switchMap(async () => {
            let locked = false;
            let userRes;
            Promise.all(
              subEntityIds.map(async (id) => {
                const response = await setLock({
                  variables: {
                    objectType,
                    objectPrimaryKey: [id],
                    application,
                  },
                });

                const { lockResult, user } = response.data.setObjectLock;
                userRes = locked ? userRes : user;
                locked =
                  locked || lockResult === ObjectLockResultEnum.OBJECT_HAS_LOCK; //if one element could not aquire the log, all wont get it
              })
            ).finally(() => {
              setObjectLockValue((p) => ({
                ...p,
                locked,
                user: userRes,
                loading: false,
              }));
            });
          })
        )
        .subscribe();
    },
    [setLock, removeLock]
  );

  const unlock = useCallback(
    (args: {
      subEntityType?: Entities;
      subEntityIds?: string[];
      forcedObjectType?: ObjectTypesUnion;
    }) => {
      if (!args) return;
      const { subEntityType, subEntityIds, forcedObjectType } = args;

      const objectType = forcedObjectType || ObjectTypeByEntity[subEntityType];

      if (
        !objectType ||
        !subEntityIds ||
        subEntityIds.length === 0 ||
        subEntityIds[0] === undefined
      ) {
        if (isDev()) {
          // ToDo: https://work4all.atlassian.net/browse/WW-4090
          console.warn('requesting object unlock for undefined object');
        }
        return;
      }

      setLockSubscription && setLockSubscription.unsubscribe();

      Promise.all(
        subEntityIds
          .filter((x) => x !== 'undefined')
          .map(async (id) => {
            await removeLock({
              variables: {
                objectType,
                objectPrimaryKey: [id],
                application,
              },
            });
          })
      ).finally(() => {
        setObjectLockValue((p) => ({
          ...p,
          loading: false,
          locked: false,
        }));
      });
    },
    [setLock, removeLock]
  );

  const [value, setValue] = useState<ILockInfo>({
    lock,
    unlock,
    loading: false,
    locked: false,
    user: null,
  });

  const setObjectLockValue = useCallback(
    (callBack: (val: ILockInfo) => ILockInfo) => {
      setValue((oldValues) => {
        const newValues = callBack(oldValues);
        if (deepEqual(newValues, oldValues)) return oldValues;
        return newValues;
      });
    },
    [setValue]
  );

  return <LockContext.Provider value={value}>{children}</LockContext.Provider>;
};
