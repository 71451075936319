import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ContactOrBusinessPartner } from '../contact-or-business-partner';

export interface SuggestionsFoundTextProps {
  suggestions: ContactOrBusinessPartner[];
  layout?: 'table' | 'cards';
}

export function SuggestionsFoundText(props: SuggestionsFoundTextProps) {
  const { suggestions, layout } = props;

  const { t } = useTranslation();

  if (!suggestions || suggestions.length < 1) {
    return null;
  }

  const suggestionsFoundText = t('ASSIGN_INCOMING_EMAILS.SUGGESTIONS_FOUND', {
    count: suggestions.length,
  });

  return (
    <Typography
      component="div"
      variant={layout === 'cards' ? 'caption' : 'body2'}
      color={'warning.main'}
      noWrap
    >
      {suggestionsFoundText}
    </Typography>
  );
}
