import styles from '../StatisticPage.module.scss';

import { LinearProgress } from '@mui/material';
import { DateTime } from 'luxon';
import { useObservableState } from 'observable-hooks';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { debounceTime, distinctUntilChanged } from 'rxjs';

import { StatisticSalesVolumeByCostCenterTable } from '@work4all/components/lib/dataDisplay/basic-table/reporting/StatisticSalesVolumeByCostCenterTable';

import { useDataProvider } from '@work4all/data';

import { StatisticSalesVoumeByCostCenter } from '@work4all/models/lib/Classes/StatisticSalesVoumeByCostCenter.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { StatisticTimeRange } from '@work4all/models/lib/Enums/StatisticTimeRange.enum';

import { settings, useSetting } from '../../../settings';
import { useTableBorders } from '../../../utils/use-table-borders/use-table-borders';
import { GeneralSetting, useGeneralSetting } from '../util/use-general-setting';
import { useOldestInvoice } from '../util/use-oldest-invoice';

import { DateWheelPickerField } from './date-wheel-picker-field/DateWheelPickerField';

export function StatisticSalesVolumeByCostCenterContainer() {
  const startBusinessYearSetting = useGeneralSetting({
    name: GeneralSetting.startBusinessYear,
  });

  if (!startBusinessYearSetting?.data?.[0]) {
    return null;
  }
  return (
    <StatisticSalesVolumeByCostCenterContainerInner
      startBusinessYearSetting={startBusinessYearSetting}
    />
  );
}

export function StatisticSalesVolumeByCostCenterContainerInner(props: {
  startBusinessYearSetting;
}) {
  const statisticConsiderInvoiceLedger = useSetting(
    settings.statisticConsiderInvoiceLedger()
  );
  const oldestInvoice = useOldestInvoice();
  const startBusinessYearSetting = props.startBusinessYearSetting;

  const { t } = useTranslation();

  const startBusinessYear = startBusinessYearSetting?.data?.[0]?.value || 1;
  const currentDate = DateTime.now()
    .minus({ years: DateTime.now().month < startBusinessYear ? 1 : 0 })
    .set({ month: startBusinessYear })
    .startOf('month')
    .toJSDate();

  const [currentYear, setCurrentYear] = useObservableState(
    (input$) => input$.pipe(distinctUntilChanged(), debounceTime(200)),
    currentDate
  );

  const requestData: DataRequest = useMemo(() => {
    const currentYearToDateTime = DateTime.fromJSDate(currentYear);
    const startDate = currentYearToDateTime;
    const endDate = currentYearToDateTime.plus({ month: 11 }).endOf('month');

    const data: StatisticSalesVoumeByCostCenter = {
      start: null,
      end: null,
      timeSpanUnit: null,
      columnCount: null,
      columns: [
        {
          rangeStart: null,
          rangeEnd: null,
          name: null,
          rowCount: null,
          rows: [
            {
              date: null,
              objId: null,
              amount: null,
              costCenter: {
                number: null,
                name: null,
                id: null,
              },
            },
          ],
        },
      ],
    };

    return {
      entity: Entities.statisticSalesVoumeByCostCenter,
      data: data,
      vars: {
        rangeType: StatisticTimeRange.MONTH,
        rangeStart: startDate.toFormat('yyyy-MM-dd'),
        rangeEnd: endDate.toFormat('yyyy-MM-dd'),
        onlyRaInvoices: statisticConsiderInvoiceLedger.value,
      },
      completeDataResponse: true,
    };
  }, [currentYear, statisticConsiderInvoiceLedger.value]);

  const data = useDataProvider(requestData);

  const isLoading = data.loading || data.pending;

  const tableBorders = useTableBorders();

  return (
    <>
      {isLoading && (
        <LinearProgress
          style={{ position: 'absolute', width: '100%', zIndex: 200 }}
        />
      )}
      <div className={styles.controlBar}>
        <div className={styles.picker}>
          <DateWheelPickerField
            min={
              oldestInvoice?.data?.[0]?.date
                ? new Date(oldestInvoice?.data?.[0]?.date)
                : new Date()
            }
            max={currentDate}
            unit="year"
            label={t('COMMON.BUSINESS_YEAR')}
            value={currentYear}
            onChange={(value) => {
              setCurrentYear(value);
            }}
          />
        </div>
      </div>
      <div className={styles.tableWrapper}>
        <StatisticSalesVolumeByCostCenterTable
          tableClasses={tableBorders}
          data={data.data as StatisticSalesVoumeByCostCenter}
        />
      </div>
    </>
  );
}
