import { createContext, useContext } from 'react';

interface TablePrefilterState {
  prefilter: unknown[];
}

const Context = createContext<TablePrefilterState | null>(null);
export const TablePrefilterProvider = Context.Provider;

export const useTablePrefilterContext = () => {
  const context = useContext(Context);

  if (!context)
    throw new Error(
      'context is null. Make sure you wrapped your component with <TablePrefilterProvider />'
    );
  return context;
};
