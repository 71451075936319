import styles from './TicketPreview.module.scss';

import { Stack } from '@mui/material';
import Divider from '@mui/material/Divider';
import { useSnackbar } from 'notistack';
import type React from 'react';
import { MouseEventHandler, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useDataMutation,
  useInaccessibleFields,
  useUser,
} from '@work4all/data';
import {
  TempFileManagerContext,
  useTempFileManager,
} from '@work4all/data/lib/hooks/data-provider/useTempFileManager';
import { settings, useSetting } from '@work4all/data/lib/settings';

import { InputTicketAttachementsRelation } from '@work4all/models/lib/Classes/InputTicketAttachementsRelation.entity';
import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';
import { TicketStatus } from '@work4all/models/lib/Enums/TicketStatus.enum';

import { isHTMLEmpty, typeNameToSdObjType } from '@work4all/utils';
import {
  DateFormatPreset,
  formatDateString,
} from '@work4all/utils/lib/date-utils/formatDateString';

import { Attachments } from '../../attachments';
import {
  InfoCard,
  InfoCards,
  IPreviewTitleProps,
  PreviewEditMessage,
  PreviewWrapper,
} from '../components';
import {
  BusinessPartnerPreviewPickerInput,
  ContactPreviewPickerInput,
  DatePreviewPickerInput,
  PreviewInputsProvider,
  PreviewTextEditorInput,
  PreviewTitleInput,
  ProjectPreviewPickerInput,
  TicketKindPreviewPickerInput,
  TicketStatusPreviewPickerInput,
  UserPreviewPickerInput,
} from '../components/preview-input';
import { EntityHistory } from '../EntityHistory';
import { useEntityPreview } from '../hooks/use-entity-preview';
import { includesHtml } from '../utils';

import { TicketPreviewFields } from './TicketPreviewContainer';

export enum TICKETACTIONS {
  addAttachments,
}

export type TicketHandlers = {
  onCloseClicked?: MouseEventHandler<HTMLButtonElement>;
  onEditClicked?: MouseEventHandler<HTMLButtonElement>;
  onShareClicked?: () => void;
  /**this preview has subactions */
  onActionElClicked?: (
    event: React.MouseEvent<HTMLButtonElement>,
    action: TICKETACTIONS
  ) => void;
  openMaskTab?: (tab: string) => void;
} & Pick<IPreviewTitleProps, 'onEmailClicked' | 'onEmailRef' | 'onPhoneClick'>;

export type ITicketPreviewProps = {
  tickets: Ticket[];
} & TicketHandlers &
  Pick<IPreviewTitleProps, 'phoneIconRef'>;

export function TicketPreview(props: ITicketPreviewProps) {
  const {
    tickets,
    onCloseClicked,
    onEditClicked,
    onShareClicked,
    onEmailClicked,
    onEmailRef,
    onPhoneClick,
    phoneIconRef,
    openMaskTab,
  } = props;
  const { t } = useTranslation();

  const user = useUser();

  const { isInaccessible } = useInaccessibleFields();
  const [newComment, setNewComment] = useState('');

  const titleIsAccessible = !isInaccessible(Entities.ticket, 'title');
  const dateIsAccessible = !isInaccessible(Entities.ticket, 'creationDate');

  const [mutate] = useDataMutation<
    Ticket,
    EMode.upsert,
    {
      attachements: InputTicketAttachementsRelation;
    }
  >({
    entity: Entities.ticket,
    mutationType: EMode.upsert,
    responseData: TicketPreviewFields as unknown as Ticket,
  });

  const {
    loading,
    isMultiselect,
    commonFields,
    activePicker,
    handleLock,
    onPopoverClose,
    onEdit,
    locked,
    lockedByUser,
    canEditAllSelected,
  } = useEntityPreview({
    user,
    subEntityType: Entities.ticket,
    entries: tickets,
    mutate,
  });

  const customerIsCommon = commonFields.includes('businessPartner');

  const ticket = {
    ...tickets[0],
    problemDescription: !includesHtml(tickets[0].problemDescription)
      ? tickets[0].problemDescription.replace(/\r\n/g, '<br>')
      : tickets[0].problemDescription,
  };

  const cleanedPersistantAttachmentList = useMemo(() => {
    return ticket?.attachmentList?.map((x) => ({
      ...x,
      fileName: x.fileName,
      __typename: undefined,
    }));
  }, [ticket?.attachmentList]);

  const tempFileManager = useTempFileManager(cleanedPersistantAttachmentList, {
    maxAttachmentTotalSize: 50 * 1024 * 1024,
  });

  const { value: isTicketSolutionRequiered } = useSetting(
    settings.isTicketSolutionRequiered()
  );

  const solutionText = ticket?.solutionText ?? '';

  const { enqueueSnackbar } = useSnackbar();

  return (
    <PreviewInputsProvider
      isLoading={loading}
      activeInputAccessor={activePicker}
      handleLock={handleLock}
      commonInputs={commonFields}
      isMultiselect={isMultiselect}
      entity={Entities.ticket}
      isLocked={locked}
      lockedByUser={lockedByUser}
      numberOfSelectedRows={tickets.length}
      canEditAllSelected={canEditAllSelected}
    >
      <TempFileManagerContext.Provider value={tempFileManager}>
        <PreviewWrapper>
          <PreviewTitleInput
            onClose={(e) => {
              if (e.target.value !== ticket?.title) {
                onEdit({ title: e.target.value });
              }
              onPopoverClose();
            }}
            onCloseClicked={onCloseClicked}
            onEditClicked={onEditClicked}
            onShareClicked={onShareClicked}
            value={ticket?.title}
            accessor="title"
            disabled={isMultiselect || !titleIsAccessible}
            onEmailClicked={onEmailClicked}
            onEmailRef={onEmailRef}
            onPhoneClick={onPhoneClick}
            phoneIconRef={phoneIconRef}
            leftSubLabel={ticket?.number ? `${ticket.number}` : undefined}
          />

          {isMultiselect && <PreviewEditMessage entityId={ticket.id} />}
          <Stack direction={'column'}>
            <InfoCards>
              {dateIsAccessible && (
                <InfoCard
                  disabled={true}
                  truncate
                  label={t('FIELDS.creationDate')}
                >
                  {isMultiselect
                    ? `(${t('COMMON.MULTIPLE')})`
                    : formatDateString(
                        ticket?.creationDate,
                        DateFormatPreset.DATE_SIMPLE_2YEAR
                      )}
                </InfoCard>
              )}

              <DatePreviewPickerInput
                labelTranslationKey="ticket.followUpDate"
                onClose={onPopoverClose}
                value={ticket?.followUpDate}
                onChange={(value) => {
                  onEdit({ followUpDate: new Date(value).toISOString() });
                }}
                accessor="followUpDate"
              />

              <BusinessPartnerPreviewPickerInput
                labelTranslationKey="COMMON.BUSINESS_PARTNER"
                onClose={onPopoverClose}
                value={ticket?.businessPartner?.data}
                onChange={(value) => {
                  onEdit({
                    businessPartner: value
                      ? {
                          data: value,
                          businessPartnerType: typeNameToSdObjType(
                            value.__typename
                          ),
                          id: value.id,
                        }
                      : null,
                    businessPartnerId: value ? value.id : 0,
                    businessPartnerType: typeNameToSdObjType(value?.__typename),
                  });
                }}
                accessor="businessPartner"
                showFooter
              />

              <ContactPreviewPickerInput
                labelTranslationKey="COMMON.CONTACT"
                onClose={onPopoverClose}
                value={ticket?.contact}
                onChange={(value) => {
                  onEdit({
                    contact: value,
                    contactId: value ? value.id : 0,
                  });
                }}
                accessor="contact"
                prefilter={[
                  {
                    businessPartnerType: { $eq: SdObjType.KUNDE },
                  },
                  {
                    businessPartnerId: {
                      $eq: ticket?.businessPartner?.data.id,
                    },
                  },
                ]}
                disabled={!customerIsCommon || !ticket?.businessPartner?.data}
              />

              <TicketStatusPreviewPickerInput
                labelTranslationKey="COMMON.STATUS"
                onClose={onPopoverClose}
                value={ticket?.status1}
                onChange={(value) => {
                  if (
                    isTicketSolutionRequiered &&
                    value === TicketStatus.ERLEDIGT &&
                    isHTMLEmpty(solutionText)
                  ) {
                    enqueueSnackbar(t('TICKET_STATUS_PICKER.PREVENT_MESSAGE'), {
                      variant: 'error',
                      autoHideDuration: 3000,
                    });

                    return;
                  }

                  onEdit({ status1: value });
                }}
                accessor="status1"
              />

              <UserPreviewPickerInput
                labelTranslationKey="COMMON.EDITOR"
                onClose={onPopoverClose}
                value={ticket?.editor1}
                onChange={(value) => {
                  onEdit({
                    editor1: value,
                    editor1Id: value ? value.id : 0,
                  });
                }}
                accessor="editor1"
              />

              <ProjectPreviewPickerInput
                labelTranslationKey="COMMON.PROJECT"
                onClose={onPopoverClose}
                value={ticket?.project}
                onChange={(value) => {
                  onEdit({
                    project: value,
                    projectId: value && !Array.isArray(value) ? value.id : 0,
                  });
                }}
                accessor="project"
                showFooter
              />

              <TicketKindPreviewPickerInput
                labelTranslationKey="COMMON.KIND"
                onClose={onPopoverClose}
                value={ticket?.ticketKind}
                onChange={(value) =>
                  onEdit({
                    ticketKindId: value ? value.id : 0,
                  })
                }
                accessor="ticketKind"
              />
            </InfoCards>
            {!isMultiselect ? (
              <>
                <Divider orientation="horizontal" />

                <PreviewTextEditorInput
                  labelTranslationKey="FIELDS.problemDescription"
                  value={ticket?.problemDescription}
                  onClose={(problemDescription) => {
                    if (problemDescription !== ticket?.problemDescription) {
                      onEdit({ problemDescription });
                    }
                    onPopoverClose();
                  }}
                  accessor="problemDescription"
                />

                {ticket.attachmentList.length ? (
                  <Stack
                    direction={'column'}
                    justifyContent="space-between"
                    width={'100%'}
                    alignItems=""
                    sx={{ padding: '0 1rem 1rem 1rem ' }}
                  >
                    <Attachments
                      disableAddAction={true}
                      disableRemoveAction={true}
                      layout="compact"
                      disableEditAction={true}
                      truncate={{
                        after: 3,
                        onMoreClick: () => {
                          openMaskTab('attachments');
                        },
                      }}
                      newestAttachmentsFirst
                    />
                  </Stack>
                ) : null}

                <Divider orientation="horizontal" />

                <EntityHistory
                  fitContainer={false}
                  entity={Entities.ticket}
                  fullWidth
                  id={ticket.id}
                  className={styles.hist}
                  breadcrumbTitle={`Ticket ${ticket.number} | ${ticket.title}`}
                  newComment={newComment}
                  onNewCommentChange={setNewComment}
                />
              </>
            ) : null}
          </Stack>
        </PreviewWrapper>
      </TempFileManagerContext.Provider>
    </PreviewInputsProvider>
  );
}
