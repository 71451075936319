import React, { useCallback } from 'react';
import { TableInstance } from 'react-table';

import { Order } from '@work4all/models/lib/Classes/Order.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';

import { EntityTable, IEntityTable } from './entity-table/EntityTable';
import { useEntityDataTable } from './entity-table/use-entity-data-table';
import schema from './schemata/order-table-schema.json';
import { useErpHandlersTemplate } from './use-erp-handlers-template';

const defaultSort = [{ field: 'date', direction: SortDirection.DESCENDING }];

export const OrdersTable = React.forwardRef<TableInstance, IEntityTable>(
  function OrdersTable(props, ref) {
    const { ...rest } = props;

    const template = useErpHandlersTemplate();
    const { prepareRowDisplayModifiers, ...dataTable } = useEntityDataTable<
      Order,
      never
    >({
      schema: schema as never,
      defaultSort,
    });

    const rowModifiers = useCallback(
      (value: Order) => {
        const modifiers = prepareRowDisplayModifiers(value);
        return {
          ...modifiers,
          isFaded: value.isClosed && value.frozen,
          isShade1: !value.isClosed && value.frozen,
        };
      },
      [prepareRowDisplayModifiers]
    );

    return (
      <EntityTable
        ref={ref}
        template={template}
        {...dataTable}
        prepareRowDisplayModifiers={rowModifiers}
        {...rest}
      />
    );
  }
);
