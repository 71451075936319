import { useMemo } from 'react';

export const useAddressBlock = (props: {
  senderFontColor?: string;
  senderName?: string;
  senderStreet?: string;
  senderPostalCode?: string;
  senderCity?: string;
  address?: string;
  marginTop?: number;
  marginBottom?: number;
}) => {
  const {
    address,
    marginBottom = 50,
    marginTop = 35,
    senderName,
    senderCity,
    senderPostalCode,
    senderStreet,
  } = props;
  const senderFontColor = '#aaa';
  return useMemo(
    () => ({
      columns: [
        {
          width: '*',
          stack: [
            {
              text:
                senderName +
                ' - ' +
                senderStreet +
                ' - ' +
                senderPostalCode +
                ' ' +
                senderCity,
              color: senderFontColor,
              fontSize: 8,
              marginBottom: 5,
            },
            address,
          ].filter((x) => Boolean(x)),
          bold: true,
          marginBottom,
          marginTop,
        },
      ],
    }),
    [
      address,
      marginBottom,
      marginTop,
      senderCity,
      senderFontColor,
      senderName,
      senderPostalCode,
      senderStreet,
    ]
  );
};
