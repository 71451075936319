import { useCallback } from 'react';

import { useHttpClient } from '../http-client/hooks/use-http-client';

import { useUser } from './useUser';

export const useConvertTempFile = () => {
  const client = useHttpClient();
  const user = useUser();

  return useCallback(
    (body: {
      tempfileId: string;
      targetId?: string;
      target: string;
      fileLinkType: string;
    }) => {
      return client.put({
        url: `${user.baseUrl}/api/file/converttempfile`,
        body,
        contentType: 'application/json',
      });
    },
    [client, user]
  );
};
