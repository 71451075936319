import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { LockProvider } from '../../../hooks';
import { useEntityPreviewData } from '../../entity-preview/hooks/use-entity-preview-data';

import { TicketHandlers, TicketPreview } from './TicketPreview';

export type ITicketPreviewContainerProps = {
  ticketIds: string[];
  fromFile?: boolean;
  phoneIconRef?: React.MutableRefObject<undefined>;
} & TicketHandlers;

export const TicketPreviewFields: Ticket<EMode.query> = {
  id: null,
  title: null,
  creationDate: null,
  number: null,
  followUpDate: null,
  problemDescription: null,
  status1: null,
  editor1: {
    id: null,
    displayName: null,
    lastName: null,
    firstName: null,
  },
  attachmentList: [
    {
      id: null,
      fileName: null,
      fileInfos: {
        fileSize: null,
        downloadUrl: null,
        fileEntityFilename: null,
        previewUrl: null,
        previewMimeType: null,
        downloadMimeType: null,
        fileRequestType: null,
        fileServiceProviderInfos: {
          customUrl: null,
          exists: null,
          fileName: null,
          fspUrl: null,
          id: null,
          key: null,
          mimeType: null,
          size: null,
          thumbnail: null,
        },
      },
      lastModificationDate: null,
    },
  ],
  projectId: null,
  businessPartner: {
    id: null,
    businessPartnerType: null,
    data: {
      customer: {
        id: null,
        name: null,
        website: null,
      },
      supplier: {
        id: null,
        name: null,
        website: null,
      },
    },
  },
  contact: {
    id: null,
    displayName: null,
    businessPartnerId: null,
    businessPartnerType: null,
    eMail: null,
  },
  project: {
    id: null,
    name: null,
    number: null,
  },
  ticketKind: {
    id: null,
    name: null,
  },
  solutionText: null,
};

export function TicketPreviewContainer(props: ITicketPreviewContainerProps) {
  const { ticketIds, ...handlers } = props;
  const tickets = useEntityPreviewData<Ticket>(
    ticketIds,
    Entities.ticket,
    TicketPreviewFields
  );

  if (!tickets) {
    return null;
  }

  return (
    <LockProvider>
      <TicketPreview {...handlers} tickets={tickets} />
    </LockProvider>
  );
}
