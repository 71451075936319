import styles from './TimelineSection.module.scss';

import { DateTime } from 'luxon';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { usePickerVisibilityState } from '@work4all/components/lib/input/date-time-input-picker/hooks/usePickerVisibilityState';
import { LabeledTimeInputWithDropdown } from '@work4all/components/lib/input/labeled-time-input';
import { Body1 } from '@work4all/components/lib/typography/body1/Body1';

import { useSetting } from '@work4all/data/lib/settings/use-setting';

import {
  DEFAULT_CALENDAR_WORK_DAY_END,
  DEFAULT_CALENDAR_WORK_DAY_START,
} from '../../../../../../../../containers/calendar/constants';
import { settings } from '../../../../../../../../settings';

export function TimelineSection() {
  const { t } = useTranslation();

  const [anchorWorkDayStart, setAnchorWorkDayStart] =
    useState<HTMLDivElement | null>(null);
  const [anchorWorkDayEnd, setAnchorWorkDayEnd] =
    useState<HTMLDivElement | null>(null);

  const calendarWorkDayStart = useSetting(settings.calendarWorkDayStart());
  const calendarWorkDayEnd = useSetting(settings.calendarWorkDayEnd());

  const {
    showPicker: showWorkDayStartTimePicker,
    onShowPicker: onWorkDayStartShowTimePicker,
    onHidePicker: onWorkDayStartHideTimePicker,
  } = usePickerVisibilityState();

  const {
    showPicker: showWorkDayEndTimePicker,
    onShowPicker: onWorkDayEndShowTimePicker,
    onHidePicker: onWorkDayEndHideTimePicker,
  } = usePickerVisibilityState();

  return (
    <Collapse title={t('SETTINGS.CALENDAR.TIMELINE')} defaultOpen>
      <div className={styles.xSplit}>
        <Body1>{t('SETTINGS.CALENDAR.WORKTIME_DURATION')}</Body1>

        <div className={styles.labelInput} ref={setAnchorWorkDayStart}>
          <LabeledTimeInputWithDropdown
            value={
              DateTime.fromISO(calendarWorkDayStart.value).toFormat('HH:mm') ??
              DEFAULT_CALENDAR_WORK_DAY_START
            }
            anchorEl={anchorWorkDayStart}
            showDropdown={showWorkDayStartTimePicker}
            onShowDropdown={onWorkDayStartShowTimePicker}
            onHideDropdown={onWorkDayStartHideTimePicker}
            label={t('COMMON.STARTDATE')}
            placeholder="hh:mm"
            onTimeSelect={(time) => {
              calendarWorkDayStart.set(time.toISO());
            }}
          />
        </div>
        <div className={styles.labelInput} ref={setAnchorWorkDayEnd}>
          <LabeledTimeInputWithDropdown
            className={styles.labelInput}
            value={
              DateTime.fromISO(calendarWorkDayEnd.value).toFormat('HH:mm') ??
              DEFAULT_CALENDAR_WORK_DAY_END
            }
            anchorEl={anchorWorkDayEnd}
            showDropdown={showWorkDayEndTimePicker}
            onShowDropdown={onWorkDayEndShowTimePicker}
            onHideDropdown={onWorkDayEndHideTimePicker}
            label={t('COMMON.END')}
            placeholder="hh:mm"
            onTimeSelect={(time) => {
              calendarWorkDayEnd.set(time.toISO());
            }}
          />
        </div>
      </div>
    </Collapse>
  );
}
