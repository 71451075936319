import styles from './Permit.module.scss';

import CheckIcon from '@mui/icons-material/Check';
import LockIcon from '@mui/icons-material/Lock';
import UndoIcon from '@mui/icons-material/Undo';
import { Stack } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import { UserPicker } from '@work4all/components/lib/components/entity-picker/user-picker/UserPicker';

import { useUser } from '@work4all/data';

import { ControllerPlus } from '../../../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import {
  Button,
  PickerTargetButton,
} from '../../../../../../../../locked-inputs';
import { Collapse } from '../../../../../../../components';
import { InboundInvoiceFormValue } from '../../../../../types';

export function Permit() {
  const { t } = useTranslation();
  const form = useFormContextPlus<InboundInvoiceFormValue>();
  const { control } = form;

  const user = useUser();

  return (
    <Collapse className={styles.permit} title={t('MASK.PERMIT')} defaultOpen>
      <Stack direction="row" flexWrap="wrap">
        <ControllerPlus
          control={control}
          name="approvalByUser"
          render={({ field: approvalUserField }) => {
            return (
              <EntityPickerPopover
                picker={
                  <UserPicker
                    value={approvalUserField.value}
                    onChange={approvalUserField.onChange}
                    multiple={false}
                  />
                }
              >
                <PickerTargetButton
                  className={styles.input}
                  label={t('INPUTS.APPROVAL_BY')}
                  value={approvalUserField.value?.displayName}
                />
              </EntityPickerPopover>
            );
          }}
        />

        <div>
          <ControllerPlus
            control={control}
            name="releaseUser"
            render={({ field: releaseUserField }) => {
              if (releaseUserField.value)
                return (
                  <Button
                    type="button"
                    className={clsx(styles.button, styles.approved)}
                    startIcon={<UndoIcon />}
                    size="large"
                    onClick={() => {
                      releaseUserField.onChange(null);
                    }}
                  >
                    {t('INPUTS.APPROVED_BY', {
                      name: user.displayName,
                    })}
                  </Button>
                );
              return (
                <Button
                  type="button"
                  className={styles.button}
                  startIcon={<CheckIcon />}
                  size="large"
                  onClick={() => {
                    releaseUserField.onChange({
                      id: user.benutzerCode,
                      displayName: user.displayName,
                    });
                  }}
                >
                  {t('INPUTS.AUTHORIZE')}
                </Button>
              );
            }}
          />
        </div>
        <ControllerPlus
          control={control}
          name="blockedByUser"
          render={({ field }) => {
            const isSet = !!field.value;
            return (
              <Button
                type="button"
                className={clsx(styles.button, {
                  [styles.locked]: isSet,
                })}
                startIcon={<LockIcon />}
                size="large"
                onClick={() => {
                  if (isSet) field.onChange(null);
                  else
                    field.onChange({
                      id: user.benutzerCode,
                      displayName: user.displayName,
                    });
                }}
              >
                {isSet
                  ? t('INPUTS.REMOVE_ACCOUNTING_LOCK')
                  : t('INPUTS.SET_ACCOUNTING_LOCK')}
              </Button>
            );
          }}
        />
      </Stack>
    </Collapse>
  );
}
