import { Stack } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import { FixedDataPicker } from '@work4all/components/lib/components/entity-picker/fixed-data-picker/FixedDataPicker';
import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';
import { PickerTargetButton } from '@work4all/components/lib/input/picker-target-button';

import { ERPTypes } from '@work4all/models/lib/Classes/ERPTypes.entity';

import { settings, useSetting } from '../../../../../../../../../settings';
import { SimpleReportSignatureNameSource } from '../../../../../../../../../settings/settings';

export const accessorByType = {
  [SimpleReportSignatureNameSource.user]: (data) =>
    data?.user?.displayName || '',
  [SimpleReportSignatureNameSource.user2]: (data) =>
    data?.user2?.displayName || '',
  [SimpleReportSignatureNameSource.contact]: (data) =>
    data?.businessPartnerContactCombined?.contact?.displayName || '',
  [SimpleReportSignatureNameSource.additionalContact1]: (data) =>
    data?.additionalAddress1?.contact?.displayName || '',
  [SimpleReportSignatureNameSource.additionalContact2]: (data) =>
    data?.additionalAddress2?.contact?.displayName || '',
  [SimpleReportSignatureNameSource.additionalContact3]: (data) =>
    data?.additionalAddress3?.contact?.displayName || '',
};

export const SignatureConf = (props: { bzObj: ERPTypes }) => {
  const { bzObj } = props;

  const {
    set: setSimpleReportSignatureConf,
    value: simpleReportSignatureConf,
  } = useSetting(settings.simpleReportSignatureConf());

  const { t } = useTranslation();

  const dataSet = useMemo<
    { id: SimpleReportSignatureNameSource; name: string }[]
  >(() => {
    const result = [
      SimpleReportSignatureNameSource.user,
      SimpleReportSignatureNameSource.user2,
      SimpleReportSignatureNameSource.contact,
      SimpleReportSignatureNameSource.additionalContact1,
      SimpleReportSignatureNameSource.additionalContact2,
      SimpleReportSignatureNameSource.additionalContact3,
    ].map((type) => ({
      id: type,
      name: `${t('SIMPLE_REPORT.SIGNATURE_SOURCE.' + type)} (${accessorByType[
        type
      ](bzObj)})`,
    }));

    return result;
  }, [t, bzObj]);

  const currentValue = useMemo(() => {
    return dataSet.find((x) => x.id === simpleReportSignatureConf.nameSource);
  }, [dataSet, simpleReportSignatureConf.nameSource]);

  return (
    <Stack gap="1rem">
      <Divider title={t('COMMON.SIGNATURE')} />

      <EntityPickerPopover
        picker={
          <FixedDataPicker
            multiple={false}
            value={currentValue}
            onChange={(value) => {
              setSimpleReportSignatureConf({
                nameSource: value.id as SimpleReportSignatureNameSource,
              });
            }}
            dataSet={dataSet}
          />
        }
      >
        <PickerTargetButton
          label={t('SIMPLE_REPORT.SIGNATURE_SOURCE.label')}
          value={currentValue.name}
        />
      </EntityPickerPopover>
    </Stack>
  );
};
