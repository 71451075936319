import React from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  ILedgerAccountPickerProps,
  LedgerAccountPicker,
} from '@work4all/components/lib/components/entity-picker/ledger-account-picker/LedgerAccountPicker';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

interface Props extends Omit<ILedgerAccountPickerProps<false>, 'multiple'> {
  error?: string;
  label?: string;
}

export const LedgerAccountPickerField = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { t } = useTranslation();

    const { error, ...pickerProps } = props;
    const { value, onChange } = pickerProps;

    return (
      <EntityPickerPopover
        picker={<LedgerAccountPicker {...pickerProps} multiple={false} />}
      >
        <PickerTargetButton
          ref={ref}
          onClear={() => {
            onChange(null);
          }}
          label={props.label ?? t('INPUTS.STANDARD_ACCOUNT')}
          error={error}
          value={value?.name ? value?.number + ' | ' + value?.name : ''}
        />
      </EntityPickerPopover>
    );
  }
);
