import { useTranslation } from 'react-i18next';

import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useContactActions } from '../../../../../../hooks/use-contact-actions';
import { ContactPicker } from '../../../../../entity-picker/contact-picker/ContactPicker';
import { withExtras } from '../../utils';

import { PreviewPickerInput } from './PreviewPickerInput';

export const ContactPreviewPickerInput = withExtras<Contact>(
  ({ value, onChange, prefilter, ...rest }) => {
    const { t } = useTranslation();
    const { actions } = useContactActions(value, {
      displayEMail: false,
      noButtonPadding: true,
    });

    return (
      <PreviewPickerInput<Contact>
        {...rest}
        value={value}
        formattedValue={value?.displayName}
        picker={
          <ContactPicker
            value={value}
            onChange={onChange}
            multiple={false}
            prefilter={prefilter}
          />
        }
        onChange={onChange}
        entity={Entities.contact}
        actions={actions}
        disabledTooltip={t('COMMON.CONTACT_PROJECT_TOOLTIP')}
      />
    );
  }
);
