import styles from './TaskPreview.module.scss';

import { Divider } from '@mui/material';
import { MouseEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useDataMutation,
  useInaccessibleFields,
  useUser,
} from '@work4all/data';

import { Task } from '@work4all/models/lib/Classes/Task.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { typeNameToSdObjType } from '@work4all/utils';

import { IConvertPopoverProps } from '../../convert-popover/ConvertPopover';
import {
  InfoCard,
  InfoCards,
  PreviewEditMessage,
  PreviewTextContent,
  PreviewWrapper,
} from '../components';
import {
  BusinessPartnerPreviewPickerInput,
  ContactPreviewPickerInput,
  DatePreviewPickerInput,
  PreviewInputsProvider,
  PreviewTitleInput,
  ProjectPreviewPickerInput,
  TaskStatusPreviewPickerInput,
  UserPreviewPickerInput,
} from '../components/preview-input';
import { EntityHistory } from '../EntityHistory';
import { useEntityPreview } from '../hooks/use-entity-preview';

import { taskPreviewFields } from './TaskPreviewContainer';

export type ITaskPreviewProps = {
  tasks: Task[];
  onCloseClicked?: MouseEventHandler<HTMLButtonElement>;
  onEditClicked?: MouseEventHandler<HTMLButtonElement>;
  onShareClicked?: () => void;
  convertProps?: Pick<IConvertPopoverProps, 'exclude' | 'onClick'>;
};

export function TaskPreview(props: ITaskPreviewProps) {
  const { tasks, onCloseClicked, onEditClicked, onShareClicked, convertProps } =
    props;
  const { t } = useTranslation();

  const user = useUser();
  const { isInaccessible } = useInaccessibleFields();

  const titleIsAccessible = !isInaccessible(Entities.task, 'title');
  const creatorIsAccessible = !isInaccessible(Entities.task, 'creator');
  const noteIsAccessible = !isInaccessible(Entities.task, 'note');

  const [mutate] = useDataMutation<Task, EMode.upsert>({
    entity: Entities.task,
    mutationType: EMode.upsert,
    responseData: taskPreviewFields as unknown as Task,
  });

  const {
    loading,
    isMultiselect,
    commonFields,
    activePicker,
    handleLock,
    onPopoverClose,
    onEdit,
    locked,
    lockedByUser,
    canEditAllSelected,
  } = useEntityPreview({
    user,
    subEntityType: Entities.task,
    entries: tasks,
    mutate,
  });

  const [newComment, setNewComment] = useState('');

  const customerIsCommon = commonFields.includes('businessPartner');
  const creatorIsCommon = commonFields.includes('creator');

  const task = tasks[0];

  return (
    <PreviewInputsProvider
      isLoading={loading}
      activeInputAccessor={activePicker}
      handleLock={handleLock}
      commonInputs={commonFields}
      isMultiselect={isMultiselect}
      entity={Entities.task}
      isLocked={locked}
      lockedByUser={lockedByUser}
      numberOfSelectedRows={tasks.length}
      canEditAllSelected={canEditAllSelected}
    >
      <PreviewWrapper>
        <PreviewTitleInput
          onClose={(e) => {
            if (e.target.value !== task?.title) {
              onEdit({ title: e.target.value });
            }
            onPopoverClose();
          }}
          onCloseClicked={onCloseClicked}
          onEditClicked={onEditClicked}
          onShareClicked={onShareClicked}
          convertProps={convertProps}
          value={task?.title}
          accessor="title"
          disabled={isMultiselect || !titleIsAccessible}
        />

        {isMultiselect && <PreviewEditMessage entityId={String(task.id)} />}

        <InfoCards columns={3}>
          {creatorIsAccessible && (
            <InfoCard staticField={true} label={t('COMMON.CREATOR')}>
              {creatorIsCommon
                ? task?.creator?.displayName || '-'
                : `(${t('COMMON.MULTIPLE')})`}
            </InfoCard>
          )}

          <UserPreviewPickerInput
            labelTranslationKey="COMMON.RESPONSIBLE"
            onClose={onPopoverClose}
            value={task?.user}
            onChange={(value) => {
              onEdit({
                userId: value.id,
                user: { id: value.id, displayName: value.displayName },
              });
            }}
            accessor="user"
          />

          <DatePreviewPickerInput
            labelTranslationKey="COMMON.BEGIN"
            onClose={onPopoverClose}
            value={task?.date}
            onChange={(value) => {
              onEdit({ date: new Date(value).toISOString() });
            }}
            accessor="date"
          />

          <DatePreviewPickerInput
            labelTranslationKey="FIELDS.end_task"
            onClose={onPopoverClose}
            value={task?.endDate}
            onChange={(value) => {
              onEdit({ endDate: new Date(value).toISOString() });
            }}
            accessor="endDate"
          />

          <BusinessPartnerPreviewPickerInput
            labelTranslationKey="COMMON.BUSINESS_PARTNER"
            onClose={onPopoverClose}
            value={task?.businessPartner?.data}
            onChange={(value) => {
              onEdit({
                businessPartner: value
                  ? {
                      data: value,
                      businessPartnerType: typeNameToSdObjType(
                        value.__typename
                      ),
                      id: value.id,
                    }
                  : null,
                businessPartnerId: value ? value.id : 0,
                businessPartnerType: typeNameToSdObjType(value?.__typename),
              });
            }}
            accessor="businessPartner"
            showFooter
          />

          <ContactPreviewPickerInput
            labelTranslationKey="COMMON.CONTACT"
            onClose={onPopoverClose}
            value={task?.contact}
            onChange={(value) => {
              onEdit({
                contact: value,
                contactId: value ? value.id : 0,
              });
            }}
            accessor="contact"
            prefilter={[
              {
                businessPartnerType: { $eq: SdObjType.KUNDE },
              },
              {
                businessPartnerId: {
                  $eq: task?.businessPartner?.data.id,
                },
              },
            ]}
            disabled={!customerIsCommon || !task?.businessPartner?.data}
          />

          <ProjectPreviewPickerInput
            labelTranslationKey="COMMON.PROJECT"
            onClose={onPopoverClose}
            value={task?.project}
            onChange={(value) => {
              onEdit({
                project: value,
                projectId: value && !Array.isArray(value) ? value.id : 0,
              });
            }}
            accessor="project"
            showFooter
          />

          <TaskStatusPreviewPickerInput
            labelTranslationKey="COMMON.STATUS"
            onClose={onPopoverClose}
            value={task?.status}
            onChange={(value) => {
              onEdit({ status: value });
            }}
            accessor="status"
          />
        </InfoCards>

        <Divider orientation="horizontal" />

        {noteIsAccessible && (
          <PreviewTextContent>{task?.note}</PreviewTextContent>
        )}

        <Divider orientation="horizontal" />

        <EntityHistory
          fitContainer={false}
          entity={Entities.task}
          fullWidth
          id={task.id}
          className={styles.hist}
          breadcrumbTitle={task.title}
          newComment={newComment}
          onNewCommentChange={setNewComment}
        />
      </PreviewWrapper>
    </PreviewInputsProvider>
  );
}
