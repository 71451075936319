import React from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { LabeledCurrencyInput } from '@work4all/components/lib/input/labeled-currency-input';

import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { PathsOf } from '@work4all/utils/lib/paths-of/paths-of';

import { CurrencyPickerField } from '../../../../../../../../../../components/entity-picker/CurrencyPickerField';
import { LedgerAccountPickerField } from '../../../../../../../../../../components/entity-picker/LedgerAccountPickerField';
import { PriceGroupPickerField } from '../../../../../../../../../../components/entity-picker/PriceGroupPickerField';
import { TaxGroupPickerField } from '../../../../../../../../../../components/entity-picker/TaxGroupPickerField';
import { ControllerPlus } from '../../../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import {
  CheckboxRadioItem,
  LabeledInput,
} from '../../../../../../../../locked-inputs';
import { ControlWrapper } from '../../../../../../../components/control-wrapper';
import { useMaskContext } from '../../../../../../../hooks/mask-context';
import { BusinessPartners } from '../../../../../BusinessPartnerOverlayController';
import { BPMaskFormValue } from '../../../../../types';

export const PriceAndTax: React.FC = () => {
  const { t } = useTranslation();
  const { register, control } =
    useFormContextPlus<PathsOf<BPMaskFormValue, 1>>();

  const mask = useMaskContext<BusinessPartners>();

  return (
    <Collapse defaultOpen={true} title={t('MASK.PRICE_AND_TAX')}>
      <ControlWrapper>
        <ControllerPlus
          name="currency"
          control={control}
          render={({ field, fieldState }) => {
            return (
              <CurrencyPickerField
                {...field}
                error={fieldState?.error?.message}
                onChange={(value) => {
                  field.onChange(value);
                }}
              />
            );
          }}
        />
        <ControllerPlus
          name="taxGroup"
          control={control}
          render={({ field }) => {
            return <TaxGroupPickerField {...field} clearable />;
          }}
        />

        {mask.entity === Entities.customer && (
          <ControllerPlus
            name="priceGroup"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <PriceGroupPickerField
                  {...field}
                  error={fieldState?.error?.message}
                />
              );
            }}
          />
        )}
        {mask.entity === Entities.supplier && (
          <ControllerPlus
            name="defaultAccount"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <LedgerAccountPickerField
                  {...field}
                  error={fieldState?.error?.message}
                />
              );
            }}
          />
        )}
      </ControlWrapper>
      {mask.entity === Entities.customer && (
        <ControlWrapper>
          <LabeledCurrencyInput
            label={t('INPUTS.CREDIT_LIMIT')}
            unit="€"
            defaultValue={mask.data?.creditLimit}
            {...register('creditLimit', {
              value: mask.data?.creditLimit,
            })}
          />
          <LabeledCurrencyInput
            label={t('INPUTS.DISCOUNT_PROPOSAL')}
            unit="%"
            defaultValue={(mask.data as Customer)?.discountProposal}
            {...register('discountProposal', {
              value: (mask.data as Customer)?.discountProposal,
            })}
          />
          <LabeledCurrencyInput
            label={t('INPUTS.DISCOUNT_ANNUAL_RENT')}
            unit="%"
            defaultValue={(mask.data as Customer)?.discount2}
            {...register('discount2', {
              value: (mask.data as Customer)?.discount2,
            })}
          />
        </ControlWrapper>
      )}
      <ControlWrapper>
        <LabeledInput
          defaultValue={(mask.data as Customer)?.firmSign}
          label={t('INPUTS.GROUP_INDICATOR')}
          {...register('firmSign', {
            value: (mask.data as Customer)?.firmSign,
          })}
        />
        <LabeledInput
          label={t('INPUTS.VAT_ID')}
          placeholder={t('INPUTS.VAT_ID')}
          defaultValue={mask.data?.vatId}
          {...register('vatId', { value: mask.data?.vatId })}
        />
        <ControlWrapper paddingBottom={false}>
          <CheckboxRadioItem
            label={t('INPUTS.NO_VAT')}
            defaultChecked={mask.data?.isGrossInvoice}
            {...register('isGrossInvoice')}
          />

          <ControllerPlus
            name={'dontConsiderIntrastat'}
            control={control}
            render={({ field }) => {
              return (
                <CheckboxRadioItem
                  {...field}
                  label={t('INPUTS.INTRASTAT')}
                  checked={Boolean(field.value === 0)}
                  onChange={(e) => {
                    field.onChange(e.target.checked ? 0 : 1);
                  }}
                />
              );
            }}
          />
        </ControlWrapper>
      </ControlWrapper>
    </Collapse>
  );
};
