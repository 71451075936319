import styles from './RowSizePicker.module.scss';

import { ListItem, ListItemButton, ListItemText, Popover } from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@work4all/components/lib/components/tooltip/Tooltip';

import { settings, useSetting } from '../../../../../../../../../../settings';
import { PickerTargetButton } from '../../../../../../../../locked-inputs';

export const RowSizePicker = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const erpSize = useSetting(settings.erpRowSize());

  const ref = useRef();

  const actions: (typeof erpSize.value)[] = ['SINGLE', 'AUTO'];

  const onClick = (action: typeof erpSize.value) => {
    setOpen(false);
    erpSize.set(action);
  };

  return (
    <Tooltip title={t('POSITIONS.ROW_SIZE.TOOLTIP')} placement="top">
      <div ref={ref}>
        <Popover
          open={open}
          onClose={() => setOpen(false)}
          anchorEl={ref.current}
          classes={{ paper: styles.root }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          {actions.map((action) => (
            <ListItem key={action} disablePadding sx={{ minWidth: 100 }}>
              <ListItemButton role={undefined} onClick={() => onClick(action)}>
                <ListItemText primary={t(`POSITIONS.ROW_SIZE.${action}`)} />
              </ListItemButton>
            </ListItem>
          ))}
        </Popover>
        <PickerTargetButton
          label={t('COMMON.CELL_HEIGHT')}
          value={t(`POSITIONS.ROW_SIZE.${erpSize.value}`)}
          onClick={() => setOpen(true)}
        />
      </div>
    </Tooltip>
  );
};
