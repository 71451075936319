import styles from './PreviewMobileWrapper.module.scss';

import EditIcon from '@mui/icons-material/Edit';
import FastForwardIcon from '@mui/icons-material/FastForward';
import Fab from '@mui/material/Fab';
import { useTranslation } from 'react-i18next';

import { ConvertPopover } from '@work4all/components/lib/components/convert-popover/ConvertPopover';
import { Tooltip } from '@work4all/components/lib/components/tooltip/Tooltip';
import { useMobileToolbarCustomActionsComponents } from '@work4all/components/lib/dataDisplay/actions-bar/hooks/use-toolbar-custom-actions';
import {
  ButtonWithLink,
  CustomToolbar,
} from '@work4all/components/lib/dataDisplay/actions-bar/ToolbarTypes';

import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ActionsMenuMobile } from './table/components/toolbar/components/actions-bar-mobile/ActionsMenuMobile';

export interface PreviewMobileWrapperProps {
  active: boolean;
  entityIds: (number | string)[];
  actions?: Partial<{
    edit: ButtonWithLink<string>;
    remove: { disabled?: boolean; handler: () => void };
    convert: {
      exclude: Entities;
      handler: (entity: Entities) => void;
    };
    export: () => void;
    custom?: CustomToolbar;
    additionalActionsLeft?: JSX.Element;
  }>;
  children: React.ReactNode;
}

export function PreviewMobileWrapper(props: PreviewMobileWrapperProps) {
  const { active, actions = {}, children, entityIds } = props;

  const convertPopoverState = usePopoverState('top');
  const { t } = useTranslation();

  const customComponents = useMobileToolbarCustomActionsComponents({
    custom: actions?.custom,
    skipMode: true,
    selectedRowsIdsList: entityIds,
  });

  if (!active) {
    //  eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }
  return (
    <div className={styles.root}>
      {children}

      <div className={styles.toolbar}>
        {customComponents}
        {actions.additionalActionsLeft}
        {actions.edit?.handler && (
          <Tooltip
            activateForDisabled={{ activeateOnClick: true }}
            title={actions.edit.disabled ? t('RIGHTS.MISSING') : undefined}
          >
            <Fab
              size="medium"
              color="primary"
              onClick={() => actions.edit.handler(undefined)}
              disabled={actions.edit.disabled}
            >
              <EditIcon />
            </Fab>
          </Tooltip>
        )}
        {actions.convert && (
          <Fab size="medium" onClick={convertPopoverState.handleClick}>
            <FastForwardIcon />
          </Fab>
        )}

        <ActionsMenuMobile
          remove={
            actions.remove && {
              disabled: actions.remove.disabled,
              onClick: actions.remove.handler,
            }
          }
          export={
            actions.export && {
              onClick: actions.export,
            }
          }
        />
      </div>

      {actions.convert ? (
        <ConvertPopover
          exclude={actions.convert.exclude}
          onClick={(entity) => {
            actions.convert.handler(entity);
            convertPopoverState.onClose();
          }}
          popoverState={convertPopoverState}
        />
      ) : null}
    </div>
  );
}
