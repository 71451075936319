import { ChangeEvent } from '@work4all/models/lib/Classes/ChangeEvent.entity';
import { CommentEvent } from '@work4all/models/lib/Classes/CommentEvent.entity';
import { CreateEvent } from '@work4all/models/lib/Classes/CreateEvent.entity';
import { Event } from '@work4all/models/lib/Classes/Event.entity';

import { assertNever, isDev } from '@work4all/utils';

import { ChangeEventView } from './ChangeEventView';
import { CommentEventView } from './comment-event-view';
import { CreateEventView } from './CreateEventView';

export interface EventViewProps {
  event: Event;
  canAddOrInsertImage: boolean;
  onAfterDelete: () => void;
}

export function EventView(props: EventViewProps) {
  const { event, canAddOrInsertImage, onAfterDelete } = props;

  const typename = event.__typename;

  switch (typename) {
    case 'CreateEvent':
      return <CreateEventView event={event as CreateEvent} />;
    case 'ChangeEvent':
      return <ChangeEventView event={event as ChangeEvent} />;
    case 'CommentEvent':
      return (
        <CommentEventView
          event={event as CommentEvent}
          canAddOrInsertImage={canAddOrInsertImage}
          onAfterDelete={onAfterDelete}
        />
      );
    // TODO: ask about that ChildItemCreated event WW-696
    case 'ChildItemCreated':
      return null;
    default:
      if (isDev()) {
        // @ts-expect-error To fix this we need to have proper literal types for
        // __typename properties instead of just string type.
        assertNever(event, `Unknown event type ${typename}`);
      }

      return null;
  }
}
