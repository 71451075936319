import { useCallback } from 'react';

import de from './de.json';
import en from './en.json';

export const useSimpleModeTranslation = () => {
  const t = useCallback(
    (
      lang: 'de' | 'en',
      translationKey: string,
      params?: Record<string, string | number>
    ) => {
      const translationFile = lang === 'de' ? de : en;
      let translation = translationFile[translationKey] || '';

      if (params) {
        Object.keys(params).forEach((key) => {
          translation = translation.replace('{{' + key + '}}', params[key]);
        });
      }

      return translation;
    },
    []
  );

  return { t };
};
