import { createContext, PropsWithChildren, useContext } from 'react';

import { Position } from '@work4all/models/lib/Classes/Position.entity';

import { useShadowBzObjectApi } from './use-shadow-bz-object-api';

type ReturnUseShadowBzObjectApi = ReturnType<typeof useShadowBzObjectApi>['1'];
interface ShadoBzObjectApi extends ReturnUseShadowBzObjectApi {
  positions: Position[];
}

interface ShadowBzObjectApiProviderProps {
  value: ShadoBzObjectApi;
}

const ShadowBzObjectContext = createContext<ShadoBzObjectApi | null>(null);

export const ShadowBzObjectApiProvider = (
  props: PropsWithChildren<ShadowBzObjectApiProviderProps>
) => {
  const { children, value } = props;
  return (
    <ShadowBzObjectContext.Provider value={value}>
      {children}
    </ShadowBzObjectContext.Provider>
  );
};

export const useShadowBzObjectApiContext = () => {
  const state = useContext(ShadowBzObjectContext);
  if (!state) {
    throw new Error(
      'Context is null. Make sure you wrapped your component with <ShadowBzObjectContext />'
    );
  }
  return state;
};
