import styles from './FilePage.module.scss';

import { useApolloClient } from '@apollo/client';
import {
  Add,
  ChevronRight,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Slide,
  Stack,
  Typography,
} from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEventCallback } from '@mui/material/utils';
import clsx from 'clsx';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DropTargetMonitor, useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { GlobalHotKeys } from 'react-hotkeys';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useSearchParams } from 'react-router-dom';

import { useAppState, WebsiteFavicon } from '@work4all/components';
import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import { ContactPicker } from '@work4all/components/lib/components/entity-picker/contact-picker/ContactPicker';
import { PreviewTitle } from '@work4all/components/lib/components/entity-preview/components';
import { FixedBackground } from '@work4all/components/lib/components/FixedBackground';
import { ResizableArea } from '@work4all/components/lib/components/ResizableArea';
import { useCallModalContext } from '@work4all/components/lib/hooks/call-modal/useCallModalContext';
import { BaseActionButton } from '@work4all/components/lib/input/base-action-button/BaseActionButton';
import { TakeRestHeight } from '@work4all/components/lib/layout/y-split';
import {
  EventType,
  sendAmplitudeData,
} from '@work4all/components/lib/utils/amplitude/amplitude';

import {
  useCustomBackgroundsContext,
  useDataProvider,
  useLocation,
  useMatch,
  useNavigate,
  useRefetchOnEntityChanges,
  useWidgetsDataBag,
  WidgetsDataProvider,
  WidgetSelectedItemId,
} from '@work4all/data';
import { CustomFieldsConfigProvider } from '@work4all/data/lib/custom-fields';
import { useEntityEvents } from '@work4all/data/lib/entity-events/use-entity-events';
import { useRoutesMetaBag } from '@work4all/data/lib/hooks/useRoutesMetaBag';

import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { ContactTelephonenumbers } from '@work4all/models/lib/Classes/ContactTelephonenumbers.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ObjectType } from '@work4all/models/lib/Enums/ObjectType.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';
import { FileType, FileTypes } from '@work4all/models/lib/File';
import { LayoutTypes } from '@work4all/models/lib/Layout';

import { assertNever } from '@work4all/utils';
import { removeDetailsFromLocation } from '@work4all/utils/lib/removeFromString';

import { usePageTitle } from '../../../hooks';
import { settings, useSetting } from '../../../settings';
import { formatPageTitle } from '../../../utils/format-page-title';
import { NEW_ENTITY_ID } from '../../mask-overlays/mask-overlay/mask-metadata';
import { useReplicationState } from '../../replication-state/use-replication-state';

import {
  ISelectedItemContextValue,
  SelectedItemContext,
} from './components/card-widgets/selected-item-context';
import { WidgetsController } from './components/card-widgets/WidgetsController/WidgetsController';
import { ChronoFileListContainer } from './components/chrono-file-list/ChronoFileListContainer';
import { DropOverlay } from './components/DropOverlay';
import { CategoryCard } from './components/file-info-cards/CategoryCard';
import { ContactCard } from './components/file-info-cards/ContactCard';
import { NoteCard } from './components/file-info-cards/NoteCard';
import {
  customerDisplayItemsMobile,
  supplierDisplayItemsMobile,
} from './components/file-info-panel/consts';
import { CustomerFileInfoPanel } from './components/file-info-panel/CustomerFileInfoPanel';
import {
  IProjectFileInfoPanelProps,
  ProjectFileInfoPanel,
} from './components/file-info-panel/ProjectFileInfoPanel';
import { SupplierFileInfoPanel } from './components/file-info-panel/SupplierFileInfoPanel';
import { IFileInfoPanelItems } from './components/file-info-panel/types';
import { FilePreviewPanel } from './components/file-preview-panel/FilePreviewPanel';
import { FileContext, FilePageViewType } from './components/FileContext';
import { FileHeader, IFileHeaderProps } from './components/FileHeader';
import { useCustomer } from './components/queries/use-customer';
import { useProject } from './components/queries/use-project';
import { useSalesOpportunities } from './components/queries/use-sales-opportunities';
import { useServiceContracts } from './components/queries/use-service-contracts';
import { useSupplier } from './components/queries/use-supplier';
import { mapFileContactMetadata } from './components/utils/map-file-contact-metadata';
import { useYellowNoteMutations } from './components/yellow-note/use-yellow-note-mutations';
import { YellowNoteContext } from './components/yellow-note/yellow-note-context';
import { YellowNote } from './components/yellow-note/YellowNote';
import { useFileViewModeSetting } from './hooks/useFileViewModeSetting';

export const resolvePreviewType = (fileType: FileType) => {
  switch (fileType) {
    case FileType.CUSTOMER:
    case FileType.SUPPLIER:
      return 'business-partner';
    case FileType.PROJECT:
      return 'project';
    default:
      assertNever(fileType as never, `Unknown file type: ${fileType}`);
  }
};

const Content: React.FC = () => {
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      collect: (monitor: DropTargetMonitor) => {
        return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    []
  );

  const [moreContactInfo, setMoreContactInfo] = useState(false);

  const appState = useAppState();

  const { t } = useTranslation();

  const fileContext = useContext(FileContext);

  const { item: selectedItem, setItem: setSelectedItem } =
    useContext(SelectedItemContext);

  const navigate = useNavigate();

  const location = useLocation();

  const [widgetCardIsFocused, setWidgetCardIsFocused] = useState(true);

  function getItemHref(entity: Entities, itemId: string, tab?: string) {
    const baseRoute = `${removeDetailsFromLocation(
      location.pathname
    )}/details/${entity}/`;

    const baseRouteWithItemId = `${baseRoute}${itemId}`;

    if (tab) {
      return `${baseRouteWithItemId}?openTab=${tab}`;
    }

    return baseRouteWithItemId;
  }

  useEffect(() => {
    const manageSelection = (e: MouseEvent) => {
      if (appState.overlayActive === true) return;
      const isInPresentationContext = (e.target as HTMLElement).closest(
        '[role=presentation]'
      );
      const isTempLinkInjection = (e.target as HTMLElement).closest(
        '[id=temp-dl-inject]'
      );
      const isInWidgetRow = (e.target as HTMLElement).closest('[class*=row--]');
      const isInPreviewArea = (e.target as HTMLElement).closest(
        `.${styles.previewPanelWrap}`
      );
      if (
        !isInPreviewArea &&
        !isInWidgetRow &&
        !isInPresentationContext &&
        !isTempLinkInjection
      ) {
        setSelectedItem(null);
      }
    };
    window.document.body.addEventListener('click', manageSelection, true);
    return () => {
      window.document.body.removeEventListener('click', manageSelection, true);
    };
  }, [appState.overlayActive, setSelectedItem]);

  const basePath = fileContext?.type + 's';
  const { setRouteState } = useRoutesMetaBag();

  const isViewportDownMd = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('md')
  );
  const isViewportDownLg = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('lg')
  );

  const closeHandler = useCallback(() => {
    sendAmplitudeData(EventType.CloseFilePage, {
      entryPoint: fileContext.type,
    });
    setRouteState(basePath, null);
    navigate(`/${basePath}`);
  }, [basePath, fileContext.type, navigate, setRouteState]);

  const { activeView, onActiveViewChange, contactMetadata, id, type } =
    useContext(FileContext);

  const completeTelephonenumbersNumbersRequest = useMemo<DataRequest>(() => {
    return {
      entity: Entities.contactTelephonenumbers,
      completeDataResponse: true,
      vars: {
        sdObjType:
          type === FileType.CUSTOMER ? SdObjType.KUNDE : SdObjType.LIEFERANT,
        sdObjMemberCode: parseInt(id),
        contactCode: contactMetadata?.selectedContact,
      },
      data: {
        central: null,
        directDial: null,
        directFax: null,
        fax: null,
        mobile: null,
        others: null,
        privateMobile: null,
        privateTelephone: null,
      } as ContactTelephonenumbers,
    };
  }, [contactMetadata?.selectedContact, id, type]);

  const completeTelephoneNumbersResult =
    useDataProvider<ContactTelephonenumbers>(
      completeTelephonenumbersNumbersRequest,
      type === FileType.PROJECT
    );

  const completeTelephoneNumbers =
    completeTelephoneNumbersResult.data as unknown as ContactTelephonenumbers;

  const [searchParams] = useSearchParams();
  const ids = searchParams.getAll('ids');

  const handlePageChange = (page: number) => {
    const newId = ids[page - 1];

    navigate({
      pathname: `/${basePath}/${newId}`,
      search: location.search,
    });
  };

  const paginationProps: IFileHeaderProps['pagination'] =
    ids.length > 1 && ids.includes(id)
      ? {
          disabled: contactMetadata?.selectedContact != null,
          count: ids.length,
          page: ids.indexOf(id) + 1,
          onChange: handlePageChange,
        }
      : null;

  const selectedContact = useMemo<Contact | null>(() => {
    if (!contactMetadata?.contacts || !contactMetadata?.selectedContact) {
      return null;
    }

    const selectedContact = contactMetadata.contacts.find(
      (contact) => contact.id === contactMetadata.selectedContact
    );

    return selectedContact;
  }, [contactMetadata?.contacts, contactMetadata?.selectedContact]);

  const widgetsDataBag = useWidgetsDataBag();

  /**
   * Event handler for ArrowUp and ArrowDown keys to manage the selected item state.
   * Pass this function to the <GlobalHotKeys> component's handlers.
   *
   * At this moment, only selecting previous (ArrowUp) and next (ArrowDown) item in the same widget
   * is supported. Hotkeys for switching between widgets (for example,
   * ArrowLeft and ArrowRight) can be easily added later, if needed.
   */
  const handleArrowHotKeys = useEventCallback((event: KeyboardEvent): void => {
    if (widgetCardIsFocused === true) {
      if (event.key !== 'ArrowUp' && event.key !== 'ArrowDown') return;

      if (!selectedItem) return;

      const widget = widgetsDataBag.widgetsData[selectedItem.widgetId];
      if (!widget) return;

      const index = widget.data.findIndex(
        (item) => item.id === selectedItem.id
      );
      if (index === -1) return;

      const delta = event.key === 'ArrowUp' ? -1 : 1;
      const nextIndex = index + delta;

      if (nextIndex < 0 || nextIndex >= widget.data.length) return;

      setSelectedItem({
        ...selectedItem,
        id: widget.data[nextIndex].id,
      });
    }

    event.preventDefault();
  });

  const { activeBackground } = useCustomBackgroundsContext();

  const previewSize = useSetting(
    settings.filePreviewSize({
      fileType: resolvePreviewType(fileContext.type),
    })
  );

  const { viewMode, setViewMode } = useFileViewModeSetting();

  const businessPartnerWrapperRef = useRef(null);

  const mobileFileInfoConf = useMemo(() => {
    return activeView !== FilePageViewType.basedata
      ? []
      : fileContext.type === FileType.CUSTOMER
      ? customerDisplayItemsMobile
      : supplierDisplayItemsMobile;
  }, [activeView, fileContext.type]);

  const { isReady: isViewReady } = useReplicationState('settings');

  const { setCallModal } = useCallModalContext();

  if (!fileContext) {
    return null;
  }

  function renderFileInfoWrap(
    displayItems?: IFileInfoPanelItems[] | IProjectFileInfoPanelProps[]
  ) {
    const handleEdit = () => {
      navigate(`details/${fileContext.type}/${fileContext.id}`);
    };

    const handleSettings = () => {
      navigate(`details/file-settings/${fileContext.type}`);
    };

    const handleAddContact = () => {
      navigate({
        pathname: `details/contact/${NEW_ENTITY_ID}`,
        search: createSearchParams({
          template: `${fileContext.type}/${fileContext.id}`,
        }).toString(),
      });
    };

    const handleEditContact = (contactId: number) => {
      navigate({
        pathname: `details/contact/${contactId}`,
      });
    };

    switch (fileContext.type) {
      case FileType.CUSTOMER:
        return (
          <CustomerFileInfoWrap
            id={fileContext.id}
            onEdit={handleEdit}
            onSettings={handleSettings}
            selectedContactId={contactMetadata?.selectedContact}
            onSelectedContactIdChange={contactMetadata?.onSelectedContactChange}
            onAddContact={handleAddContact}
            onEditContact={handleEditContact}
            displayItems={displayItems}
          />
        );
      case FileType.SUPPLIER:
        return (
          <SupplierFileInfoWrap
            id={fileContext.id}
            onEdit={handleEdit}
            onSettings={handleSettings}
            selectedContactId={contactMetadata?.selectedContact}
            onSelectedContactIdChange={contactMetadata?.onSelectedContactChange}
            onAddContact={handleAddContact}
            onEditContact={handleEditContact}
            displayItems={displayItems}
          />
        );
      case FileType.PROJECT:
        return (
          <ProjectFileInfoWrap
            id={fileContext.id}
            onEdit={handleEdit}
            onSettings={handleSettings}
            displayItems={displayItems}
          />
        );
      default:
        return null;
    }
  }

  function renderPreview() {
    return (
      <FilePreviewPanel
        onCloseClicked={() => setSelectedItem(null)}
        onEditClicked={() => {
          navigate(getItemHref(selectedItem.type, selectedItem.id as string));
        }}
        entityIds={[selectedItem.id]}
        // TODO:  AKRAM - I really don't like it that we put here some specific things
        // It should be refactored with this https://work4all.atlassian.net/browse/WW-3651
        openMaskTab={(tab: string) => {
          navigate(
            getItemHref(selectedItem.type, selectedItem.id as string, tab)
          );
        }}
        entityType={selectedItem.type}
      />
    );
  }

  function renderContactInfo() {
    const handleEditContact = (contactId: number) => {
      navigate({
        pathname: `details/contact/${contactId}`,
      });
    };

    return (
      <>
        {!isViewportDownLg && (
          <PreviewTitle
            onCloseClicked={() => {
              contactMetadata?.onSelectedContactChange(null);
            }}
            onEditClicked={() => {
              handleEditContact(selectedContact.id);
            }}
          >
            <EntityPickerPopover
              picker={
                <ContactPicker
                  multiple={false}
                  value={selectedContact}
                  onChange={(result) =>
                    contactMetadata.onSelectedContactChange(result?.id ?? null)
                  }
                  prefilter={[
                    {
                      businessPartnerId: {
                        $eq: fileContext.id,
                      },
                    },
                  ]}
                />
              }
            >
              <Button color="secondary" endIcon={<ArrowDropDownIcon />}>
                <Typography
                  variant="h4"
                  component="span"
                  color="text.primary"
                  sx={{ lineHeight: 1 }}
                >
                  {selectedContact.displayName}
                </Typography>
              </Button>
            </EntityPickerPopover>
          </PreviewTitle>
        )}
        <Stack padding="1rem">
          <ContactCard
            email={selectedContact?.eMail}
            phoneNumber={
              completeTelephoneNumbers?.directDial ||
              completeTelephoneNumbers?.mobile ||
              t('CONTACT.NO_DIRECT_NUMEBR')
            }
            onPhoneNumberClick={() =>
              setCallModal({
                data: selectedContact,
                telephoneNumbers: completeTelephoneNumbers,
              })
            }
          />
          {(!isViewportDownMd || moreContactInfo) && (
            <>
              <Divider style={{ margin: '1rem 0' }} />
              <CategoryCard
                categories={selectedContact?.categoryAssignmentList}
                entity={Entities.contact}
                id={selectedContact?.id}
                businesspartnerType={selectedContact?.businessPartnerType}
              />
              <Divider style={{ margin: '1rem 0' }} />
              <NoteCard note={selectedContact?.note} />
              <Divider title="" style={{ paddingTop: '1rem' }} />
              <BaseActionButton
                title={t('COMMON.SHOW_LESS')}
                icon={<KeyboardArrowUp />}
                onClick={() => setMoreContactInfo(false)}
              />
            </>
          )}

          {isViewportDownMd && !moreContactInfo && (
            <>
              <Divider title="" style={{ paddingTop: '1rem' }} />
              <BaseActionButton
                title={t('COMMON.SHOW_MORE')}
                icon={<KeyboardArrowDown />}
                onClick={() => setMoreContactInfo(true)}
              />
            </>
          )}
        </Stack>
      </>
    );
  }

  const handleAddContact = () => {
    navigate({
      pathname: `details/contact/${NEW_ENTITY_ID}`,
      search: createSearchParams({
        template: `${fileContext.type}/${fileContext.id}`,
      }).toString(),
    });
  };

  const activeDrop = canDrop && isOver;

  return (
    <div className={styles.wrapper}>
      <GlobalHotKeys
        keyMap={{
          MOVE_UP: 'up',
          MOVE_DOWN: 'down',
          CLOSE: 'escape',
        }}
        handlers={{
          MOVE_UP: handleArrowHotKeys,
          MOVE_DOWN: handleArrowHotKeys,
          CLOSE: closeHandler,
        }}
      />
      {activeBackground && <FixedBackground src={activeBackground.url} />}

      <div
        id="wrapperHeader"
        className={styles['wrapper__header-slot']}
        style={{ height: `var(--projectInfoHeight)`, flex: 'none' }}
      >
        <FileHeader
          withPaper
          onClose={closeHandler}
          onResetContact={() => contactMetadata.onSelectedContactChange(null)}
          type={fileContext.type}
          selectedContact={selectedContact}
          title={fileContext.title}
          subTitle={
            (selectedContact || activeView === FilePageViewType.basedata) &&
            fileContext.title
          }
          pagination={paginationProps}
          onCloseBaseData={() => onActiveViewChange(null)}
          activeView={activeView}
          viewMode={viewMode}
          setViewMode={setViewMode}
        />
      </div>
      <div></div>
      {(fileContext.pending || widgetsDataBag.loading === true) && (
        <div style={{ position: 'relative', zIndex: 10 }}>
          <LinearProgress style={{ width: '100%', position: 'absolute' }} />
        </div>
      )}
      <div
        ref={drop}
        className={clsx(styles['wrapper__body-slot'], {
          [styles['wrapper__body-slot-mobileView']]: isViewportDownLg,
          [styles.activeDrop]: activeDrop,
        })}
      >
        {isViewportDownLg && (
          <>
            {selectedItem?.id && (
              <Dialog
                fullScreen
                open={selectedItem?.id !== undefined}
                TransitionComponent={Slide}
                TransitionProps={{
                  // @ts-expect-error MUI component seems to be typed
                  // incorrectly. The props are passed down to the Slide
                  // component and the transition is correct, so everything
                  // is working as expected.
                  direction: 'left',
                }}
              >
                <div
                  id="fileInfoPanelWrap"
                  className={clsx(styles['previewPanelWrap'], {
                    [styles.active]: selectedItem?.id !== undefined,
                    [styles['maxHeightDialog']]: selectedItem?.id !== undefined,
                  })}
                >
                  {renderPreview()}
                </div>
              </Dialog>
            )}
            {isViewportDownLg && (
              <div
                id="fileInfoPanelWrap"
                className={clsx(styles['previewPanelWrap'], {
                  [styles['fixedSize']]: selectedItem?.id !== undefined,
                  [styles.active]: selectedItem?.id !== undefined,
                })}
              >
                {!contactMetadata?.selectedContact
                  ? renderFileInfoWrap(mobileFileInfoConf)
                  : renderContactInfo()}
              </div>
            )}

            {activeView !== FilePageViewType.basedata && !selectedContact && (
              <List className={styles.mobileSubNavigationList}>
                <ListItem
                  button
                  divider={true}
                  onClick={() => {
                    onActiveViewChange(FilePageViewType.basedata);
                  }}
                >
                  <ListItemText
                    className={styles.itemText}
                    classes={{ primary: styles.primary }}
                  >
                    <Box display="flex" gap="0.5rem" alignItems="center">
                      {(fileContext?.data as Customer | Supplier)?.website && (
                        <WebsiteFavicon
                          website={
                            (fileContext?.data as Customer | Supplier)?.website
                          }
                        />
                      )}{' '}
                      {fileContext?.title}
                    </Box>
                  </ListItemText>
                  <ListItemIcon>
                    <ChevronRight />
                  </ListItemIcon>
                </ListItem>

                {fileContext.type !== FileType.PROJECT && (
                  <ListItem
                    button
                    divider={true}
                    ref={businessPartnerWrapperRef}
                  >
                    <div style={{ width: '100%', display: 'flex' }}>
                      <EntityPickerPopover
                        anchorEl={businessPartnerWrapperRef}
                        picker={
                          <ContactPicker
                            multiple={false}
                            value={contactMetadata?.contacts?.find(
                              (x) => x.id === contactMetadata.selectedContact
                            )}
                            onChange={(result) =>
                              contactMetadata.onSelectedContactChange(
                                result?.id
                              )
                            }
                            prefilter={[
                              {
                                businessPartnerId: {
                                  $eq: fileContext.id,
                                },
                              },
                            ]}
                          />
                        }
                      >
                        <ListItemText
                          className={styles.itemText}
                          classes={{ primary: styles.primary }}
                        >
                          {t('FILE_CONTACTS.CONTACTS')} (
                          {fileContext?.contactMetadata?.contacts?.length})
                        </ListItemText>
                      </EntityPickerPopover>
                      <ListItemIcon onClick={handleAddContact}>
                        <Add color="primary" />
                      </ListItemIcon>
                    </div>
                  </ListItem>
                )}
              </List>
            )}
          </>
        )}

        <YellowNote />

        {(!isViewportDownLg || activeView !== FilePageViewType.basedata) && (
          <TakeRestHeight
            style={{ overflow: 'auto' }}
            className={
              styles[
                isViewportDownMd
                  ? 'widgetControllerWrapMd'
                  : 'widgetControllerWrap'
              ]
            }
          >
            {isViewReady ? (
              viewMode === 'list' ? (
                <CustomFieldsConfigProvider entity={Entities.chronoFileItem}>
                  <ChronoFileListContainer
                    id={fileContext.id}
                    type={fileContext.type}
                  />
                </CustomFieldsConfigProvider>
              ) : (
                <div
                  tabIndex={0}
                  onBlur={() => setWidgetCardIsFocused(false)}
                  onFocus={() => setWidgetCardIsFocused(true)}
                >
                  <WidgetsController viewMode={viewMode} />
                </div>
              )
            ) : (
              <div className={styles.loader}>
                <CircularProgress />
              </div>
            )}
          </TakeRestHeight>
        )}

        {!isViewportDownLg && (
          <ResizableArea
            className={styles.resizable}
            handles="left"
            size={previewSize.value}
            onResize={previewSize.set}
          >
            <div
              id="fileInfoPanelWrap"
              className={clsx(styles['previewPanelWrap'], {
                [styles.active]: selectedItem?.id !== undefined,
              })}
            >
              {selectedItem?.id === undefined
                ? selectedContact
                  ? renderContactInfo()
                  : renderFileInfoWrap()
                : renderPreview()}
            </div>
          </ResizableArea>
        )}

        {/**this can not be unmounted if not active, as the access to the files only is possible on dragstart and therefore the respective dropzones need to be hooked already */}
        <DropOverlay active={activeDrop} />
      </div>
    </div>
  );
};

const CustomerFileInfoWrap = (props: FileInfoWrapProps) => {
  const {
    id,
    onEdit,
    onSettings,
    selectedContactId,
    onSelectedContactIdChange,
    onAddContact,
    onEditContact,
    displayItems,
  } = props;

  const { customer, refetch, pending } = useCustomer(id);
  const { salesOpportunities } = useSalesOpportunities(id);
  const { serviceContracts } = useServiceContracts(id);

  useRefetchOnEntityChanges({
    entity: Entities.customer,
    objectsType: [ObjectType.KUNDENANSPRECHPARTNER],
    refetch,
    skipIfCurrentUser: true,
  });

  return (
    <CustomerFileInfoPanel
      customer={customer}
      loading={pending}
      onEdit={onEdit}
      onSettings={onSettings}
      selectedContactId={selectedContactId}
      onSelectedContactIdChange={onSelectedContactIdChange}
      onAddContact={onAddContact}
      onEditContact={onEditContact}
      displayItems={displayItems as IFileInfoPanelItems[]}
      openSalesOpportunityList={salesOpportunities}
      activeServiceContractList={serviceContracts}
    />
  );
};

const SupplierFileInfoWrap = (props: FileInfoWrapProps) => {
  const {
    id,
    onEdit,
    onSettings,
    selectedContactId,
    onSelectedContactIdChange,
    onAddContact,
    onEditContact,
    displayItems,
  } = props;

  const { supplier, refetch, pending } = useSupplier(id);

  useRefetchOnEntityChanges({
    entity: Entities.supplier,
    objectsType: [ObjectType.LIEFERANTENANSPRECHPARTNER],
    refetch,
    skipIfCurrentUser: true,
  });

  return (
    <SupplierFileInfoPanel
      supplier={supplier}
      loading={pending}
      onEdit={onEdit}
      onSettings={onSettings}
      selectedContactId={selectedContactId}
      onSelectedContactIdChange={onSelectedContactIdChange}
      onAddContact={onAddContact}
      onEditContact={onEditContact}
      displayItems={displayItems as IFileInfoPanelItems[]}
    />
  );
};

const ProjectFileInfoWrap = (props: FileInfoWrapProps) => {
  const { id, onEdit, onSettings, displayItems } = props;
  const { project, refetch } = useProject(id);

  useRefetchOnEntityChanges({
    entity: Entities.project,
    refetch,
    skipIfCurrentUser: true,
  });

  return (
    <ProjectFileInfoPanel
      project={project}
      onEdit={onEdit}
      onSettings={onSettings}
      displayItems={displayItems as IFileInfoPanelItems[]}
    />
  );
};

interface FileInfoWrapProps {
  id: string;
  onEdit: () => void;
  onSettings: () => void;
  selectedContactId?: number | null;
  onSelectedContactIdChange?: (contactId: number | null) => void;
  onAddContact?: () => void;
  onEditContact?: (contactId: number) => void;
  displayItems?: IFileInfoPanelItems[] | IProjectFileInfoPanelProps[];
}

interface Props {
  id: string;
  selectedContact: number | null;
  activeView: FilePageViewType;
  onSelectedContactChange: (contactId: number | null) => void;
  onActiveViewChange: (activeView: FilePageViewType | null) => void;
}

const ProjectFile: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const { project, pending } = useProject(props.id);
  const { updateYellowNote, deleteYellowNote } = useYellowNoteMutations({
    entity: Entities.project,
    id: Number(props.id),
  });

  usePageTitle(formatPageTitle([t('COMMON.PROJECT'), project?.name]));

  return (
    <FileContext.Provider
      value={{
        id: props.id,
        pending,
        contactMetadata: null,
        title: project?.name || '',
        type: FileType.PROJECT,
        onActiveViewChange: props.onActiveViewChange,
        activeView: props.activeView,
        data: project,
      }}
    >
      <YellowNoteContext
        data={project?.postIt ?? null}
        updateYellowNote={updateYellowNote}
        deleteYellowNote={deleteYellowNote}
      >
        <Content />
      </YellowNoteContext>
    </FileContext.Provider>
  );
};

const SupplierFile: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const { supplier, pending } = useSupplier(props.id);
  const { updateYellowNote, deleteYellowNote } = useYellowNoteMutations({
    entity: Entities.supplier,
    id: Number(props.id),
  });

  usePageTitle(formatPageTitle([t('COMMON.SUPPLIER'), supplier?.name]));

  const contactMetadata = useMemo(() => {
    return mapFileContactMetadata(supplier);
  }, [supplier]);

  return (
    <FileContext.Provider
      value={{
        id: props.id,
        pending,
        contactMetadata: contactMetadata
          ? {
              ...contactMetadata,
              selectedContact: props.selectedContact,
              onSelectedContactChange: props.onSelectedContactChange,
            }
          : null,
        title: supplier?.name || '',
        data: supplier,
        type: FileType.SUPPLIER,
        onActiveViewChange: props.onActiveViewChange,
        activeView: props.activeView,
      }}
    >
      <YellowNoteContext
        data={supplier?.postIt ?? null}
        updateYellowNote={updateYellowNote}
        deleteYellowNote={deleteYellowNote}
      >
        <Content />
      </YellowNoteContext>
    </FileContext.Provider>
  );
};

const CustomerFile: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const { customer, pending } = useCustomer(props.id);
  const { updateYellowNote, deleteYellowNote } = useYellowNoteMutations({
    entity: Entities.customer,
    id: Number(props.id),
  });

  usePageTitle(formatPageTitle([t('COMMON.CUSTOMER'), customer?.name]));

  const contactMetadata = useMemo(() => {
    return mapFileContactMetadata(customer);
  }, [customer]);

  return (
    <FileContext.Provider
      value={{
        id: props.id,
        pending,
        contactMetadata: contactMetadata
          ? {
              ...contactMetadata,
              selectedContact: props.selectedContact,
              onSelectedContactChange: props.onSelectedContactChange,
            }
          : null,
        title: customer?.name || '',
        data: customer,
        type: FileType.CUSTOMER,
        onActiveViewChange: props.onActiveViewChange,
        activeView: props.activeView,
      }}
    >
      <YellowNoteContext
        data={customer?.postIt ?? null}
        updateYellowNote={updateYellowNote}
        deleteYellowNote={deleteYellowNote}
      >
        <Content />
      </YellowNoteContext>
    </FileContext.Provider>
  );
};

export const FileDetailPage: React.FC = () => {
  const { params } = useMatch('/:type/:id/*');
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const id = params.id;
  const type = params.type;
  const currentContactId = Number(searchParams.get('contact')) || null;
  const curentView = FilePageViewType[searchParams.get('activeView')] ?? null;

  const client = useApolloClient();

  useEntityEvents(async (event) => {
    if (
      event.type === 'delete' &&
      entityFileTypeMatch(event.entity, type as FileTypes) &&
      event.ids.includes(id)
    ) {
      setRouteState(type, null);
      setTimeout(() => {
        navigate(`/${type}`);
      }, 1000);
    }
    if (event.type === 'upsert') {
      //refetch the active layout data if an entity updates and we are mounted
      client.resetStore();
    } else if (event.entity === Entities.contact && event.type === 'delete') {
      handleSelectedContactChange(null);
    }
  });

  const [selectionContext, setSelectionContext] =
    useState<null | WidgetSelectedItemId>(null);

  const setSelectedItem = useCallback((item: WidgetSelectedItemId | null) => {
    setSelectionContext(item ?? null);
  }, []);

  const { setRouteState } = useRoutesMetaBag();
  useEffect(() => {
    /**
     *  save selected currrent page id
     *  next time user navigatess to search page he will be automatically redirected to this page.
     *  If user wants to get back to search page he needs to close selected file
     *  In this case state will be removed.
     *  */
    setRouteState(type, id, {
      ...(currentContactId != null && { contact: currentContactId }),
      ...(curentView && { activeView: curentView }),
    });
  }, [curentView, currentContactId, id, setRouteState, type]);

  useEffect(() => {
    sendAmplitudeData(EventType.OpenFilePage, {
      entryPoint: type,
    });
  }, [type]);

  function handleSelectedContactChange(contactId: number | null) {
    // @ts-expect-error It is automatically (implicitly) typed
    setSearchParams({
      ...(contactId != null && { contact: contactId }),
      ...(curentView && { activeView: curentView }),
    });
  }

  function handleActiveViewChange(activeView: FilePageViewType | null) {
    // @ts-expect-error It is automatically (implicitly) typed
    setSearchParams({
      ...(currentContactId != null && { contact: currentContactId }),
      ...(activeView && { activeView }),
    });
  }

  let layoutType: LayoutTypes = LayoutTypes.NoType;
  let comp: JSX.Element = <div>404</div>;
  switch (type) {
    case FileTypes.Customers:
      layoutType = LayoutTypes.Customer;
      comp = (
        <CustomerFile
          id={id}
          selectedContact={currentContactId}
          activeView={curentView}
          onSelectedContactChange={handleSelectedContactChange}
          onActiveViewChange={handleActiveViewChange}
        />
      );
      break;
    case FileTypes.Suppliers:
      layoutType = LayoutTypes.Supplier;
      comp = (
        <SupplierFile
          id={id}
          activeView={curentView}
          selectedContact={currentContactId}
          onSelectedContactChange={handleSelectedContactChange}
          onActiveViewChange={handleActiveViewChange}
        />
      );
      break;
    case FileTypes.Projects:
      layoutType = LayoutTypes.Project;
      comp = (
        <ProjectFile
          id={id}
          activeView={curentView}
          selectedContact={currentContactId}
          onSelectedContactChange={handleSelectedContactChange}
          onActiveViewChange={handleActiveViewChange}
        />
      );
      break;
  }

  const contextValue = useMemo<ISelectedItemContextValue>(() => {
    return {
      item: selectionContext,
      setItem: setSelectedItem,
    };
  }, [selectionContext, setSelectedItem]);

  const { viewMode } = useFileViewModeSetting();

  return (
    <SelectedItemContext.Provider value={contextValue}>
      <WidgetsDataProvider
        fileType={type as FileTypes}
        layoutType={layoutType}
        id={id}
        contactId={currentContactId}
        selectedItem={selectionContext}
        onSelectedItemChange={setSelectedItem}
        viewMode={viewMode}
      >
        {comp}
      </WidgetsDataProvider>
    </SelectedItemContext.Provider>
  );
};

const entityFileTypeMatch = (entityType: Entities, fileType?: FileTypes) => {
  switch (fileType) {
    case FileTypes.Customers:
      return entityType === Entities.customer;

    case FileTypes.Suppliers:
      return entityType === Entities.supplier;

    case FileTypes.Projects:
      return entityType === Entities.project;

    default:
      return false;
  }
};
