import styles from './OptionItem.module.scss';

import { Typography } from '@mui/material';

import { Option } from '../../types';

export const OptionItem = ({ icon, name, onClick }: Option) => {
  return (
    <div onClick={onClick} className={styles.root}>
      <div className={styles.icon}>{icon}</div>
      <Typography variant="body1">{name}</Typography>
    </div>
  );
};
