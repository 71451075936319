import styles from './KeyNavigationHandlerContainer.module.scss';

import clsx from 'clsx';
import React, { useLayoutEffect } from 'react';
import { useRef } from 'react';

import { reactRefSetter } from '@work4all/utils';

type Props = {
  autoFocus?: boolean;
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

/**
 * Container which allows to handle key navigation.
 */
export const KeyNavigationHandlerContainer = React.forwardRef<
  HTMLDivElement,
  Props
>(({ className, autoFocus = true, ...props }, ref) => {
  const internalRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!autoFocus) return;
    internalRef.current?.focus();
  }, [autoFocus]);

  return (
    <div
      className={clsx(className, styles.container)}
      tabIndex={0}
      autoFocus={autoFocus}
      ref={reactRefSetter(ref, internalRef)}
      {...props}
    />
  );
});
