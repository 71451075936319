import { useEventCallback } from '@mui/material/utils';
import React, { PropsWithChildren, useMemo } from 'react';

import { settings, useSetting } from '../../../../settings';

import {
  FavoriteLinksContext,
  IFavoriteLinksContextValue,
} from './favorite-links-context';
import {
  addFavoriteLink,
  addFavoriteLinksFolder,
  combineLinks,
  IAddFavoriteLinkOptions,
  IAddFavoriteLinksFolderOptions,
  ICombineLinksOptions,
  IMoveFavoriteLinkOptions,
  IMoveFavoriteLinksFolderOptions,
  IRenameFavoriteLinksFolderOptions,
  moveFavoriteLink,
  moveFavoriteLinksFolder,
  removeFavoriteLink,
  removeFavoriteLinkByHref,
  removeFavoriteLinksFolder,
  renameFavoriteLinksFolder,
} from './other';

export const FavoriteLinksProvider: React.FC<PropsWithChildren<unknown>> = (
  props
) => {
  const { children } = props;

  const favoriteLinks = useSetting(settings.favoriteLinks());
  const handleAddFavoriteLink = useEventCallback(
    (options: IAddFavoriteLinkOptions) => {
      const newValue = addFavoriteLink(options, favoriteLinks.value);
      favoriteLinks.set(newValue);
    }
  );

  const handleMoveFavoriteLink = useEventCallback(
    (options: IMoveFavoriteLinkOptions) => {
      const newValue = moveFavoriteLink(options, favoriteLinks.value);
      favoriteLinks.set(newValue);
    }
  );

  const handleFavoriteRemoveLink = useEventCallback((linkId: string) => {
    const newValue = removeFavoriteLink(linkId, favoriteLinks.value);
    favoriteLinks.set(newValue);
  });

  const handleFavoriteRemoveLinkByHref = useEventCallback((href: string) => {
    const newValue = removeFavoriteLinkByHref(href, favoriteLinks.value);
    favoriteLinks.set(newValue);
  });

  const handleCombineLinks = useEventCallback(
    (options: ICombineLinksOptions) => {
      const newValue = combineLinks(options, favoriteLinks.value);
      favoriteLinks.set(newValue);
    }
  );

  const handleAddFavoriteLinksFolder = useEventCallback(
    (options: IAddFavoriteLinksFolderOptions = {}) => {
      const newValue = addFavoriteLinksFolder(options, favoriteLinks.value);
      favoriteLinks.set(newValue);
    }
  );

  const handleRenameFavoriteLinksFolder = useEventCallback(
    (options: IRenameFavoriteLinksFolderOptions) => {
      const newValue = renameFavoriteLinksFolder(options, favoriteLinks.value);
      favoriteLinks.set(newValue);
    }
  );

  const handleRemoveFavoriteLinksFolder = useEventCallback((id: string) => {
    const newValue = removeFavoriteLinksFolder(id, favoriteLinks.value);
    favoriteLinks.set(newValue);
  });

  const handleMoveFavoriteLinksFolder = useEventCallback(
    (options: IMoveFavoriteLinksFolderOptions) => {
      const newValue = moveFavoriteLinksFolder(options, favoriteLinks.value);
      favoriteLinks.set(newValue);
    }
  );

  const contextValue = useMemo<IFavoriteLinksContextValue>(
    () => ({
      favoriteLinks: favoriteLinks.value,
      addFavoriteLink: handleAddFavoriteLink,
      moveFavoriteLink: handleMoveFavoriteLink,
      removeFavoriteLink: handleFavoriteRemoveLink,
      removeFavoriteLinkByHref: handleFavoriteRemoveLinkByHref,
      combineLinks: handleCombineLinks,
      removeFavoriteLinksFolder: handleRemoveFavoriteLinksFolder,
      addFavoriteLinksFolder: handleAddFavoriteLinksFolder,
      renameFavoriteLinksFolder: handleRenameFavoriteLinksFolder,
      moveFavoriteLinksFolder: handleMoveFavoriteLinksFolder,
    }),
    [
      favoriteLinks,
      handleAddFavoriteLink,
      handleMoveFavoriteLink,
      handleFavoriteRemoveLink,
      handleFavoriteRemoveLinkByHref,
      handleCombineLinks,
      handleRemoveFavoriteLinksFolder,
      handleAddFavoriteLinksFolder,
      handleRenameFavoriteLinksFolder,
      handleMoveFavoriteLinksFolder,
    ]
  );

  return (
    <FavoriteLinksContext.Provider value={contextValue}>
      {children}
    </FavoriteLinksContext.Provider>
  );
};
