import styles from './PDFTextmarks.module.scss';

import { PDFDocumentProxy } from 'pdfjs-dist';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDrop, XYCoord } from 'react-dnd';
import { useTranslation } from 'react-i18next';

import { remToPx } from '@work4all/data/lib/hooks/useRemToPx';

import { DndTypes } from '@work4all/utils/lib/variables';

import { useResizeObserver } from '../../hooks';

import { DragBox } from './DragBox';

const DEFAULT_CONF = {
  offsetTop: remToPx(1),
  offsetBottom: remToPx(1),
  offsetLeft: 20,
  offsetRight: 20,
  pageGap: remToPx(1),
};

export interface PDFTextmarkConf {
  pdfTextMarkItems?: PDFTextmark[];
  onItemsChange?: (value: PDFTextmark[]) => void;
  onPDFInit?: (pdf: PDFDocumentProxy) => void;
  disabled?: boolean;
  pageGap?: number;
  offsetTop?: number;
  offsetBottom?: number;
  offsetLeft?: number;
  offsetRight?: number;
}

type Props = PDFTextmarkConf & {
  pdf: PDFDocumentProxy;
};

export interface PDFTextmark {
  type: DndTypes;
  id: string;
  top: number;
  left: number;
  title: string;
  accessor: string;
  pageNumber: number;
}

const calculateAbsolutePosition = (props: {
  width: number;
  height: number;
  xRel: number;
  yRel: number;
  offsetBottom: number;
  offsetLeft: number;
  offsetRight: number;
  offsetTop: number;
  pageNumber: number;
  numPages: number;
  pageGap: number;
}) => {
  const {
    height,
    width,
    xRel,
    yRel,
    offsetLeft = 0,
    offsetRight = 0,
    offsetTop = 0,
    offsetBottom = 0,
    pageNumber = 1,
    numPages = 1,
    pageGap = 0,
  } = props;

  const pageIndex = pageNumber - 1;
  const innerHeight = height - offsetTop - offsetBottom;
  const innerWidth = width - offsetLeft - offsetRight;
  const totalPageGap = (numPages - 1) * pageGap;
  const pageHeight = (innerHeight - totalPageGap) / numPages;

  const totalOffsetTop =
    offsetTop + pageIndex * pageGap + pageIndex * pageHeight;

  const y = yRel * pageHeight + totalOffsetTop;
  const x = innerWidth * xRel + offsetLeft;

  return { x, y };
};

const calculateRelativePosition = (props: {
  width: number;
  height: number;
  xAbs: number;
  yAbs: number;
  offsetBottom: number;
  offsetLeft: number;
  offsetRight: number;
  offsetTop: number;
  numPages: number;
  pageGap: number;
}) => {
  const {
    height,
    width,
    xAbs,
    yAbs,
    offsetLeft = 0,
    offsetRight = 0,
    offsetBottom = 0,
    offsetTop = 0,
    numPages = 1,
    pageGap = 0,
  } = props;

  const innerWidth = width - offsetLeft - offsetRight;
  const innerHeight = height - offsetTop - offsetBottom;
  const totalPageGap = (numPages - 1) * pageGap;
  const pageHeight = (innerHeight - totalPageGap) / numPages;

  const pageNumber = Math.floor((yAbs / height) * numPages + 1);

  const pageIndex = pageNumber - 1;

  const x = (xAbs - offsetLeft) / innerWidth;
  const y =
    (yAbs - offsetTop - pageIndex * pageGap - pageIndex * pageHeight) /
    pageHeight;

  return { x, y, pageNumber };
};

export const PDFTextmarkContainer = (props: Props) => {
  const {
    pdfTextMarkItems,
    onItemsChange,
    pageGap = 0,
    offsetBottom = 0,
    offsetLeft = 0,
    offsetRight = 0,
    offsetTop = 0,
    disabled,
    pdf,
  } = { ...DEFAULT_CONF, ...props };

  const { t } = useTranslation();
  const numPages = pdf?.numPages;

  const wrapRef = useRef<HTMLDivElement>(null);

  const [boxes, setBoxes] = useState<{
    [key: string]: {
      top: number;
      left: number;
      title: string;
    };
  }>({});

  const placeObjects = useCallback(() => {
    const result = {};
    for (const item of pdfTextMarkItems) {
      const pos = calculateAbsolutePosition({
        width: wrapRef.current.clientWidth,
        height: wrapRef.current.clientHeight,
        xRel: item.left,
        yRel: item.top,
        pageNumber: item.pageNumber,
        numPages,
        pageGap,
        offsetBottom,
        offsetLeft,
        offsetRight,
        offsetTop,
      });
      result[item.id] = {
        top: pos.y,
        left: pos.x,
        title: item.title,
      };
    }
    setBoxes(result);
  }, [
    numPages,
    offsetBottom,
    offsetLeft,
    offsetRight,
    offsetTop,
    pageGap,
    pdfTextMarkItems,
  ]);

  useResizeObserver(wrapRef, placeObjects);

  useEffect(() => {
    placeObjects();
  }, [placeObjects]);

  const moveBox = useCallback(
    (id: string, left: number, top: number) => {
      const resultingBoxes = {
        ...boxes,
        [id]: {
          ...boxes[id],
          left,
          top,
        },
      };
      setBoxes(resultingBoxes);

      const returnResult = Object.keys(resultingBoxes).map((key) => {
        const box = resultingBoxes[key];
        const pos = calculateRelativePosition({
          width: wrapRef.current.clientWidth,
          height: wrapRef.current.clientHeight,
          xAbs: box.left,
          yAbs: box.top,
          pageGap,
          numPages,
          offsetBottom,
          offsetLeft,
          offsetRight,
          offsetTop,
        });
        return {
          ...pdfTextMarkItems.find((i) => i.id === key),
          top: pos.y,
          left: pos.x,
          pageNumber: pos.pageNumber,
        };
      });

      onItemsChange?.(returnResult);
    },
    [
      boxes,
      pdfTextMarkItems,
      offsetBottom,
      offsetLeft,
      offsetRight,
      offsetTop,
      onItemsChange,
      pageGap,
      numPages,
    ]
  );

  const [, drop] = useDrop(
    () => ({
      accept: DndTypes.BOX,
      drop(item: PDFTextmark, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset() as XYCoord;
        const left = Math.round(item.left + delta.x);
        const top = Math.round(item.top + delta.y);
        moveBox(item.id, left, top);
        return undefined;
      },
    }),
    [moveBox]
  );

  const onDelete = (id: string) => {
    onItemsChange(pdfTextMarkItems.filter((x) => x.id !== id));
  };

  return (
    <div ref={wrapRef} className={styles.textmarkWrap}>
      <div ref={drop} className={styles.textmarkInnerWrap}>
        {Object.keys(boxes).map((key) => {
          const { left, top, title } = boxes[key] as {
            top: number;
            left: number;
            title: string;
          };
          return (
            <DragBox
              key={key}
              id={key}
              left={left}
              top={top}
              onDelete={() => {
                onDelete(key);
              }}
              disabled={disabled}
            >
              {t(title)}
            </DragBox>
          );
        })}
      </div>
    </div>
  );
};
