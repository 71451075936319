import { isEqual } from 'lodash';
import {
  useCallback,
  useDebugValue,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ZodError } from 'zod';

import { useSettingsContext } from './context';
import { SettingScope } from './types';

// Keep track of names for which we have already reported an invalid value.
const didReportInvalidSettingValue = new Set<string>();

export interface UseSettingOptions<T> {
  name: string;
  scope: SettingScope;
  defaultValue: T;
  /**
   * Optional parseFn for transforming the saved value before returning it.
   * create via https://transform.tools/typescript-to-zod
   */
  parseFn?: (value: unknown) => T;
}

export interface UseSettingReturn<T = unknown> {
  value: T;
  set: (value: T) => void;
  delete: () => void;
}

export function useSetting<T = unknown>(
  options: UseSettingOptions<T>
): UseSettingReturn<T> {
  const { name, scope, defaultValue, parseFn } = options;

  const { getValue, setSetting, deleteSetting, subscribe } =
    useSettingsContext();

  const [savedValue, setSavedValue] = useState<T>(
    () => getValue()[scope][name] as T
  );

  useEffect(() => {
    function onStateChange() {
      const newValue = getValue()[scope][name] as T;
      setSavedValue((oldValue) => {
        if (isEqual(oldValue, newValue)) {
          return oldValue;
        }

        return newValue;
      });
    }

    onStateChange();

    return subscribe(onStateChange);
  }, [name, scope, getValue, subscribe]);

  const boundSetSetting = useCallback(
    (value: T) => setSetting({ name, scope, value }),
    [setSetting, name, scope]
  );

  const boundDeleteSetting = useCallback(
    () => deleteSetting({ name, scope }),
    [deleteSetting, name, scope]
  );

  const parsedValue = useMemo(() => {
    if (savedValue === undefined || !parseFn) {
      return savedValue;
    }

    try {
      return parseFn(savedValue);
    } catch (err: unknown) {
      if (err instanceof ZodError) {
        // Report invalid setting values to the console only once.
        if (!didReportInvalidSettingValue.has(name)) {
          didReportInvalidSettingValue.add(name);

          const message = [
            `UserSettings: Invalid value for setting "${name}".`,
            '\n\nIssues:',
            ...err.issues.map(
              (issue) => `\n - ${issue.path}: ${issue.message}`
            ),
          ].join('');

          console.error(message);
        }
      } else {
        console.error(err);
      }

      return undefined;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedValue, parseFn]);

  const value = parsedValue === undefined ? defaultValue : parsedValue;

  useDebugValue({
    name,
    scope,
    savedValue,
    parsedValue,
    defaultValue,
    value,
  });

  return useMemo(() => {
    return {
      value: value,
      set: boundSetSetting,
      delete: boundDeleteSetting,
    };
  }, [value, boundSetSetting, boundDeleteSetting]);
}
