import CloseIcon from '@mui/icons-material/Close';
import UndoIcon from '@mui/icons-material/Undo';
import { Box, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';

import { MailboxContent } from '@work4all/models/lib/Classes/MailboxContent.entity';
import { MailAssignStatus } from '@work4all/models/lib/Enums/MailAssignStatus.enum';

import { useMailboxContentManagerContext } from '../mailbox-content-manager-context';

export function MailboxContentStatusChangeButtonCell(
  props: CellProps<MailboxContent, MailAssignStatus>
) {
  const mailboxContent = props.row.original;
  const id = mailboxContent.id;

  const { t } = useTranslation();

  const context = useMailboxContentManagerContext();

  const canIgnore = context.canIgnore(id);
  const canReset = context.canReset(id);

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: '8px',
      }}
    >
      {canIgnore && (
        <Tooltip
          title={t('LIST.MAILBOXCONTENT.ACTION.IGNORE')}
          placement="right"
        >
          <IconButton
            color="primary"
            sx={{
              flex: 'none',
              padding: 0,
              marginRight: '6px',
            }}
            onClick={async () => {
              await context.ignore([id]);
              context.refetch();
            }}
          >
            <CloseIcon sx={{ color: 'var(--text01)' }} />
          </IconButton>
        </Tooltip>
      )}
      {canReset && (
        <Tooltip title={t('LIST.MAILBOXCONTENT.ACTION.UNDO')} placement="right">
          <IconButton
            color="primary"
            sx={{
              flex: 'none',
              padding: 0,
            }}
            onClick={async () => {
              await context.reset([id]);
              context.refetch();
            }}
          >
            <UndoIcon sx={{ color: 'var(--text01)' }} />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
}
