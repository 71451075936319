import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

import { InputCustomField } from './InputCustomField.entity';
import { InputPosition } from './InputPosition.entity';

export class InputAngebot<T extends EMode = EMode.entity> {
  /** Alias: abgelehnt */
  abgelehnt?: number;
  /** Alias: bezugsgroesse */
  bezugsgroesse?: number;
  /** Alias: supportId */
  supportId?: string;
  /** Alias: teilrechnungslogikCode */
  teilrechnungslogikCode?: number;
  /** Alias: dispositionsBeginn */
  dispositionsBeginn?: string;
  /** Alias: dispositionsEnde */
  dispositionsEnde?: string;
  /** Alias: reservierung */
  reservierung?: number;
  /** Alias: notForeCast */
  notForeCast?: number;
  /** Alias: freigabe */
  freigabe?: number;
  /** Alias: gueltigBis */
  gueltigBis?: string;
  /** Alias: shopGenehmigt */
  shopGenehmigt?: boolean;
  /** Alias: shopGenehmigtDurchLoginID */
  shopGenehmigtDurchLoginID?: string;
  /** Alias: shopGenehmigtDatum */
  shopGenehmigtDatum?: string;
  /** Alias: code */
  code?: number;
  /** Alias: nummer */
  nummer?: number;
  /** Alias: auftragsNummer */
  auftragsNummer?: number;
  /** Alias: hauptadresseApCode */
  hauptadresseApCode?: number;
  /** Alias: hauptadresseCompanyType */
  hauptadresseCompanyType?: SdObjType;
  /** Alias: hauptadresseCompanyCode */
  hauptadresseCompanyCode?: number;
  /** Alias: hauptadresseText */
  hauptadresseText?: string;
  /** Alias: hauptadresseAnPostfach */
  hauptadresseAnPostfach?: boolean;
  /** Alias: hauptAdresseAbteilungCode */
  hauptAdresseAbteilungCode?: number;
  /** Alias: nebenadresse1AbteilungCode */
  nebenadresse1AbteilungCode?: number;
  /** Alias: nebenadresse2AbteilungCode */
  nebenadresse2AbteilungCode?: number;
  /** Alias: nebenadresse3AbteilungCode */
  nebenadresse3AbteilungCode?: number;
  /** Alias: nebenadresse1ApCode */
  nebenadresse1ApCode?: number;
  /** Alias: nebenadresse2ApCode */
  nebenadresse2ApCode?: number;
  /** Alias: nebenadresse3ApCode */
  nebenadresse3ApCode?: number;
  /** Alias: nebenadresse1CompanyCode */
  nebenadresse1CompanyCode?: number;
  /** Alias: nebenadresse2CompanyCode */
  nebenadresse2CompanyCode?: number;
  /** Alias: nebenadresse3CompanyCode */
  nebenadresse3CompanyCode?: number;
  /** Alias: nebenadresse1Text */
  nebenadresse1Text?: string;
  /** Alias: nebenadresse2Text */
  nebenadresse2Text?: string;
  /** Alias: nebenadresse3Text */
  nebenadresse3Text?: string;
  /** Alias: nebenadresse1CompanyType */
  nebenadresse1CompanyType?: SdObjType;
  /** Alias: nebenadresse2CompanyType */
  nebenadresse2CompanyType?: SdObjType;
  /** Alias: nebenadresse3CompanyType */
  nebenadresse3CompanyType?: SdObjType;
  /** Alias: nebenadresse1AnPostfach */
  nebenadresse1AnPostfach?: boolean;
  /** Alias: nebenadresse2AnPostfach */
  nebenadresse2AnPostfach?: boolean;
  /** Alias: nebenadresse3AnPostfach */
  nebenadresse3AnPostfach?: boolean;
  /** Alias: bankverbindungCode */
  bankverbindungCode?: number;
  /** Alias: benutzerCode */
  benutzerCode?: number;
  /** Alias: benutzerCode2 */
  benutzerCode2?: number;
  /** Alias: datum */
  datum?: string;
  /** Alias: eingefroren */
  eingefroren?: boolean;
  /** Alias: ihrZeichen */
  ihrZeichen?: string;
  /** Alias: unserZeichen */
  unserZeichen?: string;
  /** Alias: kopftext */
  kopftext?: string;
  /** Alias: schlusstext */
  schlusstext?: string;
  /** Alias: rtfKopftext */
  rtfKopftext?: string;
  /** Alias: rtfSchlusstext */
  rtfSchlusstext?: string;
  /** Alias: kostenstelle */
  kostenstelle?: number;
  /** Alias: kurs */
  kurs?: number;
  /** Alias: kursDatum */
  kursDatum?: string;
  /** Alias: notiz */
  notiz?: string;
  /** Alias: projektCode */
  projektCode?: number;
  /** Alias: status1 */
  status1?: number;
  /** Alias: status2 */
  status2?: number;
  /** Alias: status3 */
  status3?: number;
  /** Alias: status4 */
  status4?: number;
  /** Alias: status5 */
  status5?: number;
  /** Alias: waehrungCode */
  waehrungCode?: number;
  /** Alias: feld1 */
  feld1?: string;
  /** Alias: feld2 */
  feld2?: string;
  /** Alias: feld3 */
  feld3?: string;
  /** Alias: feld4 */
  feld4?: string;
  /** Alias: feld5 */
  feld5?: string;
  /** Alias: feld6 */
  feld6?: string;
  /** Alias: feld7 */
  feld7?: string;
  /** Alias: projektVorgangCode */
  projektVorgangCode?: number;
  /** Alias: leistungsort */
  leistungsort?: string;
  /** Alias: auftragsbeginn */
  auftragsbeginn?: string;
  /** Alias: bestelldatum */
  bestelldatum?: string;
  /** Alias: lieferadresseGeaendert */
  lieferadresseGeaendert?: boolean;
  /** Alias: skonto */
  skonto?: number;
  /** Alias: skontofrist */
  skontofrist?: number;
  /** Alias: skonto2 */
  skonto2?: number;
  /** Alias: skontoFrist2 */
  skontoFrist2?: number;
  /** Alias: sprachCode */
  sprachCode?: number;
  /** Alias: zahlungsfrist */
  zahlungsfrist?: number;
  /** Alias: lieferungsArtZiel */
  lieferungsArtZiel?: number;
  /** Alias: lieferungsArtCode */
  lieferungsArtCode?: number;
  /** Alias: zahlungCode */
  zahlungCode?: number;
  /** Alias: wert */
  wert?: number;
  /** Alias: bruttowert */
  bruttowert?: number;
  /** Alias: preisgruppe */
  preisgruppe?: number;
  /** Alias: lookupCode */
  lookupCode?: number;
  /** Alias: brutto */
  brutto?: boolean;
  /** Alias: automatischeNummerierung */
  automatischeNummerierung?: boolean;
  /** Alias: wartungsCode */
  wartungsCode?: number;
  /** Alias: abgeschlossen */
  abgeschlossen?: boolean;
  /** Alias: mobileBearbeitung */
  mobileBearbeitung?: any;
  /** Alias: auftragsDatum */
  auftragsDatum?: string;
  /** Alias: lieferterminAbgehend */
  lieferterminAbgehend?: string;
  /** Alias: leistungsbeginn */
  leistungsbeginn?: string;
  /** Alias: leistungsende */
  leistungsende?: string;
  /** Alias: createdByLoginId */
  createdByLoginId?: string;
  /** Alias: webShopOrderDefinitionData */
  webShopOrderDefinitionData?: string;
  /** Alias: niederlassungsCode */
  niederlassungsCode?: number;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];
  /** Alias: positionen */
  positionen?: InputPosition<T>[];
  /** Alias: createdByUserCode */
  createdByUserCode?: number;
  /** Alias: costCenterNumber */
  costCenterNumber?: number;

  __typename?: string;
}

const fields: FieldDefinitions<InputAngebot> = {
  abgelehnt: {
    alias: 'abgelehnt',
  },
  bezugsgroesse: {
    alias: 'bezugsgroesse',
  },
  supportId: {
    alias: 'supportId',
  },
  teilrechnungslogikCode: {
    alias: 'teilrechnungslogikCode',
  },
  dispositionsBeginn: {
    alias: 'dispositionsBeginn',
  },
  dispositionsEnde: {
    alias: 'dispositionsEnde',
  },
  reservierung: {
    alias: 'reservierung',
  },
  notForeCast: {
    alias: 'notForeCast',
  },
  freigabe: {
    alias: 'freigabe',
  },
  gueltigBis: {
    alias: 'gueltigBis',
  },
  shopGenehmigt: {
    alias: 'shopGenehmigt',
  },
  shopGenehmigtDurchLoginID: {
    alias: 'shopGenehmigtDurchLoginID',
  },
  shopGenehmigtDatum: {
    alias: 'shopGenehmigtDatum',
  },
  code: {
    alias: 'code',
  },
  nummer: {
    alias: 'nummer',
  },
  auftragsNummer: {
    alias: 'auftragsNummer',
  },
  hauptadresseApCode: {
    alias: 'hauptadresseApCode',
  },
  hauptadresseCompanyType: {
    alias: 'hauptadresseCompanyType',
  },
  hauptadresseCompanyCode: {
    alias: 'hauptadresseCompanyCode',
  },
  hauptadresseText: {
    alias: 'hauptadresseText',
  },
  hauptadresseAnPostfach: {
    alias: 'hauptadresseAnPostfach',
  },
  hauptAdresseAbteilungCode: {
    alias: 'hauptAdresseAbteilungCode',
  },
  nebenadresse1AbteilungCode: {
    alias: 'nebenadresse1AbteilungCode',
  },
  nebenadresse2AbteilungCode: {
    alias: 'nebenadresse2AbteilungCode',
  },
  nebenadresse3AbteilungCode: {
    alias: 'nebenadresse3AbteilungCode',
  },
  nebenadresse1ApCode: {
    alias: 'nebenadresse1ApCode',
  },
  nebenadresse2ApCode: {
    alias: 'nebenadresse2ApCode',
  },
  nebenadresse3ApCode: {
    alias: 'nebenadresse3ApCode',
  },
  nebenadresse1CompanyCode: {
    alias: 'nebenadresse1CompanyCode',
  },
  nebenadresse2CompanyCode: {
    alias: 'nebenadresse2CompanyCode',
  },
  nebenadresse3CompanyCode: {
    alias: 'nebenadresse3CompanyCode',
  },
  nebenadresse1Text: {
    alias: 'nebenadresse1Text',
  },
  nebenadresse2Text: {
    alias: 'nebenadresse2Text',
  },
  nebenadresse3Text: {
    alias: 'nebenadresse3Text',
  },
  nebenadresse1CompanyType: {
    alias: 'nebenadresse1CompanyType',
  },
  nebenadresse2CompanyType: {
    alias: 'nebenadresse2CompanyType',
  },
  nebenadresse3CompanyType: {
    alias: 'nebenadresse3CompanyType',
  },
  nebenadresse1AnPostfach: {
    alias: 'nebenadresse1AnPostfach',
  },
  nebenadresse2AnPostfach: {
    alias: 'nebenadresse2AnPostfach',
  },
  nebenadresse3AnPostfach: {
    alias: 'nebenadresse3AnPostfach',
  },
  bankverbindungCode: {
    alias: 'bankverbindungCode',
  },
  benutzerCode: {
    alias: 'benutzerCode',
  },
  benutzerCode2: {
    alias: 'benutzerCode2',
  },
  datum: {
    alias: 'datum',
  },
  eingefroren: {
    alias: 'eingefroren',
  },
  ihrZeichen: {
    alias: 'ihrZeichen',
  },
  unserZeichen: {
    alias: 'unserZeichen',
  },
  kopftext: {
    alias: 'kopftext',
  },
  schlusstext: {
    alias: 'schlusstext',
  },
  rtfKopftext: {
    alias: 'rtfKopftext',
  },
  rtfSchlusstext: {
    alias: 'rtfSchlusstext',
  },
  kostenstelle: {
    alias: 'kostenstelle',
  },
  kurs: {
    alias: 'kurs',
  },
  kursDatum: {
    alias: 'kursDatum',
  },
  notiz: {
    alias: 'notiz',
  },
  projektCode: {
    alias: 'projektCode',
  },
  status1: {
    alias: 'status1',
  },
  status2: {
    alias: 'status2',
  },
  status3: {
    alias: 'status3',
  },
  status4: {
    alias: 'status4',
  },
  status5: {
    alias: 'status5',
  },
  waehrungCode: {
    alias: 'waehrungCode',
  },
  feld1: {
    alias: 'feld1',
  },
  feld2: {
    alias: 'feld2',
  },
  feld3: {
    alias: 'feld3',
  },
  feld4: {
    alias: 'feld4',
  },
  feld5: {
    alias: 'feld5',
  },
  feld6: {
    alias: 'feld6',
  },
  feld7: {
    alias: 'feld7',
  },
  projektVorgangCode: {
    alias: 'projektVorgangCode',
  },
  leistungsort: {
    alias: 'leistungsort',
  },
  auftragsbeginn: {
    alias: 'auftragsbeginn',
  },
  bestelldatum: {
    alias: 'bestelldatum',
  },
  lieferadresseGeaendert: {
    alias: 'lieferadresseGeaendert',
  },
  skonto: {
    alias: 'skonto',
  },
  skontofrist: {
    alias: 'skontofrist',
  },
  skonto2: {
    alias: 'skonto2',
  },
  skontoFrist2: {
    alias: 'skontoFrist2',
  },
  sprachCode: {
    alias: 'sprachCode',
  },
  zahlungsfrist: {
    alias: 'zahlungsfrist',
  },
  lieferungsArtZiel: {
    alias: 'lieferungsArtZiel',
  },
  lieferungsArtCode: {
    alias: 'lieferungsArtCode',
  },
  zahlungCode: {
    alias: 'zahlungCode',
  },
  wert: {
    alias: 'wert',
  },
  bruttowert: {
    alias: 'bruttowert',
  },
  preisgruppe: {
    alias: 'preisgruppe',
  },
  lookupCode: {
    alias: 'lookupCode',
  },
  brutto: {
    alias: 'brutto',
  },
  automatischeNummerierung: {
    alias: 'automatischeNummerierung',
  },
  wartungsCode: {
    alias: 'wartungsCode',
  },
  abgeschlossen: {
    alias: 'abgeschlossen',
  },
  mobileBearbeitung: {
    alias: 'mobileBearbeitung',
  },
  auftragsDatum: {
    alias: 'auftragsDatum',
  },
  lieferterminAbgehend: {
    alias: 'lieferterminAbgehend',
  },
  leistungsbeginn: {
    alias: 'leistungsbeginn',
  },
  leistungsende: {
    alias: 'leistungsende',
  },
  createdByLoginId: {
    alias: 'createdByLoginId',
  },
  webShopOrderDefinitionData: {
    alias: 'webShopOrderDefinitionData',
  },
  niederlassungsCode: {
    alias: 'niederlassungsCode',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  customFields: {
    alias: 'customFields',
  },
  positionen: {
    alias: 'positionen',
  },
  createdByUserCode: {
    alias: 'createdByUserCode',
  },
  costCenterNumber: {
    alias: 'costCenterNumber',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputAngebotEntityDefinition: EntitiyDefinition<InputAngebot> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'InputAngebot',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
