import { useTranslation } from 'react-i18next';

import { EMailTemplateKind } from '@work4all/models/lib/Enums/EMailTemplateKind.enum';

import { FixedDataPicker } from '../fixed-data-picker/FixedDataPicker';
import { IPickerProps } from '../types';

export type IEMailTemplateKindPickerProps<TMultiple extends boolean> =
  IPickerProps<{ id: EMailTemplateKind; name: string }, TMultiple>;

const AVAILABLE_CONTEXT = [
  EMailTemplateKind.TICKET,
  EMailTemplateKind.APPOINTMENT_NOTIFICATION,
  EMailTemplateKind.KEINE,
  EMailTemplateKind.ERP_OBJECTS,
];

export function EMailTemplateKindPicker<TMultiple extends boolean>(
  props: IEMailTemplateKindPickerProps<TMultiple>
) {
  const { ...rest } = props;

  const { t } = useTranslation();

  const dataSet = AVAILABLE_CONTEXT.map((value) => ({
    id: value,
    name: t('E_MAIL_TEMPLATE_KIND.' + value),
  }));

  return <FixedDataPicker dataSet={dataSet} sortData {...rest} />;
}
