import { MenuItem as _MenuItem } from '@mui/material';
import { Button as _Button } from '@mui/material';
import { IconButton as _IconButton } from '@mui/material';
import { Autocomplete as _Autocomplete } from '@mui/material';

import { Chip as _Chip } from '@work4all/components/lib/dataDisplay/chip/Chip';
import { ActionButton as _ActionButton } from '@work4all/components/lib/input/action-button/ActionButton';
import { BaseActionButton as _BaseActionButton } from '@work4all/components/lib/input/base-action-button/BaseActionButton';
import { CheckboxRadioItem as _CheckboxRadioItem } from '@work4all/components/lib/input/checkbox-radio-item/CheckboxRadioItem';
import { ContainedButton as _ContainedButton } from '@work4all/components/lib/input/contained-button/ContainedButton';
import { DateTimeInputPicker as _DateTimInputPicker } from '@work4all/components/lib/input/date-time-input-picker';
import { TextEditor as _TextEditor } from '@work4all/components/lib/input/format-text/TextEditor';
import { LabeledInputWithUnit as BaseLabeledInputWithUnit } from '@work4all/components/lib/input/label-input-with-unit';
import { LabeledCurrencyInput as BaseLabeledCurrencyInput } from '@work4all/components/lib/input/labeled-currency-input/LabeledCurrencyInput';
import { LabeledCurrencyInputWithSeparator as BaseLabeledCurrencyInputWithSeparator } from '@work4all/components/lib/input/labeled-currency-input-with-separator/LabeledCurrencyInputWithSeparator';
import { LabeledInput as BaseLabeledInput } from '@work4all/components/lib/input/labeled-input';
import { MultiStepSearch as BaseMultiStepSearch } from '@work4all/components/lib/input/multi-step-search';
import { PickerTargetButton as _PickerTargetButton } from '@work4all/components/lib/input/picker-target-button';

import { withAutoDisable } from '../mask-overlay/utils/with-auto-disable';

export const ActionButton = withAutoDisable(_ActionButton);
// withAutoDisable loses the generics when used with components like
// Autocomplete. Use type assertion as a workaround.
export const Autocomplete = withAutoDisable(
  _Autocomplete
) as typeof _Autocomplete;
export const BaseActionButton = withAutoDisable(_BaseActionButton);
export const CheckboxRadioItem = withAutoDisable(_CheckboxRadioItem);
export const ContainedButton = withAutoDisable(_ContainedButton);
export const DateTimeInputPicker = withAutoDisable(_DateTimInputPicker);
export const LabeledInput = withAutoDisable(BaseLabeledInput);
export const LabeledCurrencyInput = withAutoDisable(BaseLabeledCurrencyInput);
export const LabeledCurrencyInputWithSeparator = withAutoDisable(
  BaseLabeledCurrencyInputWithSeparator
);
export const LabeledInputWithUnit = withAutoDisable(BaseLabeledInputWithUnit);
export const MultiStepSearch = withAutoDisable(BaseMultiStepSearch);
export const MenuItem = withAutoDisable(_MenuItem);
export const Button = withAutoDisable(_Button);
export const IconButton = withAutoDisable(_IconButton);
export const Chip = withAutoDisable(_Chip);
export const PickerTargetButton = withAutoDisable(_PickerTargetButton);
export const TextEditor = withAutoDisable(_TextEditor);
