import { useCallback } from 'react';

import { useDataMutation } from '@work4all/data';

import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { entityDefinition } from '@work4all/models/lib/Classes/entityDefinitions';
import { InputStammdatenPostIt } from '@work4all/models/lib/Classes/InputStammdatenPostIt.entity';
import { FieldDefinitions } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { invariant, isDev } from '@work4all/utils';

import { YELLOW_NOTE_FIELDS_ENTITY } from './yellow-note-fields';

type UseYellowNoteMutationOptions = { entity: Entities; id: number };

export function useYellowNoteMutations({
  entity,
  id,
}: UseYellowNoteMutationOptions) {
  if (isDev()) {
    const definition = entityDefinition[entity];
    invariant(definition, `Can't find definition for entity ${entity}`);

    const fields =
      definition.fieldDefinitions as FieldDefinitions<EntityWithYellowNote>;
    invariant(fields.postIt, () => {
      return (
        `Entity ${entity} does not have a "postIt" field in its definition.` +
        `Only entities with "postIt" field can be used with this hook`
      );
    });
  }

  const updateYellowNote = useUpdateYellowNoteMutation({ entity, id });
  const deleteYellowNote = useDeleteYellowNoteMutation({ entity, id });

  return { updateYellowNote, deleteYellowNote };
}

function useUpdateYellowNoteMutation({
  entity,
  id,
}: UseYellowNoteMutationOptions) {
  const mutate = useEntityWithYellowNameMutation({ entity });

  const updateYellowNote = useCallback(
    async (content: string) => {
      await mutate(
        { id },
        { relations: { postIt: { set: { body: content } } } }
      );
    },
    [mutate, id]
  );

  return updateYellowNote;
}

function useDeleteYellowNoteMutation({
  entity,
  id,
}: UseYellowNoteMutationOptions) {
  const mutate = useEntityWithYellowNameMutation({ entity });

  const deleteYellowNote = useCallback(async () => {
    await mutate({ id }, { relations: { postIt: { delete: true } } });
  }, [mutate, id]);

  return deleteYellowNote;
}

function useEntityWithYellowNameMutation({ entity }: { entity: Entities }) {
  const [mutate] = useDataMutation<
    EntityWithYellowNote,
    EMode.upsert,
    { postIt: InputStammdatenPostIt }
  >({
    entity,
    mutationType: EMode.upsert,
    responseData: UPDATE_ENTITY_WITH_YELLOW_NOTE_FIELDS,
    resetStore: false,
  });

  return mutate;
}

type EntityWithYellowNote = Pick<Customer, 'id' | 'postIt'>;

const UPDATE_ENTITY_WITH_YELLOW_NOTE_FIELDS: EntityWithYellowNote = {
  id: null,
  postIt: YELLOW_NOTE_FIELDS_ENTITY,
};
