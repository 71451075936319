import React from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import { PickerTargetButton } from '@work4all/components/lib/input/picker-target-button';

import { MailboxTypeOption } from './MailboxTypeOption';
import { MailboxTypePicker, MailboxTypePickerProps } from './MailboxTypePicker';

interface Props extends MailboxTypePickerProps {
  error?: string;
  label?: string;
  required?: boolean;
}

export const MailboxTypePickerField = React.forwardRef<HTMLDivElement, Props>(
  function MailboxTypePickerField(props, ref) {
    const { error, label, required, ...pickerProps } = props;
    const { value } = pickerProps;

    const { t } = useTranslation();

    return (
      <EntityPickerPopover picker={<MailboxTypePicker {...pickerProps} />}>
        <PickerTargetButton
          ref={ref}
          label={label || t('COMMON.POST_OFFICE_BOX')}
          error={error}
          required={required}
          value={<MailboxTypeOption value={value} />}
        />
      </EntityPickerPopover>
    );
  }
);
