import { memo, useMemo } from 'react';
import { CellProps } from 'react-table';
import sanitizeHtml from 'sanitize-html';

import { MailboxContent } from '@work4all/models/lib/Classes/MailboxContent.entity';
import { MailAssignStatus } from '@work4all/models/lib/Enums/MailAssignStatus.enum';

export const MailboxContentBodyCell = memo(
  (
    props: CellProps<MailboxContent, MailAssignStatus> & {
      body?: string;
    }
  ) => {
    const body = props?.value || props.body || '';

    const sanitizedBody = useMemo(() => {
      return sanitizeHtml(body.replace(/&nbsp;|&zwjn;/g, ''), {
        allowedTags: [],
        allowedAttributes: {},
      });
    }, [body]);

    return sanitizedBody;
  }
);
