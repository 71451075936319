import { useEventCallback } from '@mui/material/utils';
import { DateTime } from 'luxon';
import {
  ChangeEventHandler,
  FocusEventHandler,
  useCallback,
  useEffect,
  useState,
} from 'react';

export const useInputState = (
  validateDateString: (dateString?: string) => DateTime | false,
  dateToString: (date?: DateTime) => string,
  currentDate: DateTime | null | undefined,
  onChange: (date: DateTime) => void
) => {
  const [date, setDate] = useState<DateTime | null | undefined>(
    currentDate ? DateTime.fromObject(currentDate.toObject()) : undefined
  );
  const [dateString, setDateString] = useState<string>(
    currentDate ? dateToString(currentDate) : ''
  );

  const onChangeCallback = useEventCallback(onChange);

  const convertDateToString = useCallback(
    (date?: DateTime) => {
      if (!date) {
        setDateString('');
        return;
      }
      setDateString(dateToString(date));
    },
    [setDateString, dateToString]
  );

  const convertCurrentStringToDate = useCallback(
    (dateString: string, resetInvalid?: boolean) => {
      const validDate = validateDateString(dateString);
      if (validDate) {
        setDate(validDate);
        onChangeCallback(validDate);
      } else {
        if (resetInvalid) {
          convertDateToString(date);
        } else {
          setDateString(dateString);
        }
      }
    },
    [validateDateString, onChangeCallback, convertDateToString, date]
  );

  useEffect(() => {
    setDate(currentDate);
  }, [currentDate]);

  useEffect(() => {
    convertDateToString(date);
  }, [date, dateToString, convertDateToString]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const v = e.target.value;
    convertCurrentStringToDate(v, true);
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    const v = e.target.value;
    if (v === dateString) return;
    convertCurrentStringToDate(v, true);
  };
  return {
    dateString,
    date,
    setDate,
    onChange: handleChange,
    onBlur: handleBlur,
  };
};
