import styles from './TableSection.module.scss';

import { Typography } from '@mui/material';
import clsx from 'clsx';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Table, TableStateBagProvider } from '@work4all/components';
import { ActionsBar } from '@work4all/components/lib/dataDisplay/actions-bar/ActionsBar';
import { useRowSelection } from '@work4all/components/lib/dataDisplay/basic-table/hooks/useRowSelection';

import { useUser } from '@work4all/data';

import { canAddPayment } from '@work4all/utils/lib/permissions';

import { usePaymentHnadlers, usePaymentTable } from '../hooks';
import { Payment } from '../interfaces';

export const TableSection = () => {
  const { t } = useTranslation();
  const { watch, getValues } = useFormContext();
  const { selectedRows, onSelectedRowsChange } = useRowSelection();

  const watchPayments = watch('payments');
  const payments: Payment[] = useMemo(
    () => watchPayments ?? [],
    [watchPayments]
  );

  const { columns, displayFooter } = usePaymentTable(payments);
  const showRemoveIcon = selectedRows.length === 1;

  const { onAdd, onRemove } = usePaymentHnadlers({
    incomingInvoiceId: getValues('id'),
    payments,
    selectedRows,
  });

  const user = useUser();
  const canUserAddPayment = canAddPayment(user);

  return (
    <div className={styles.wrapper}>
      <div>
        <Typography variant="h4">{t('COMMON.PAYMENTS')}</Typography>

        <ActionsBar
          add={canUserAddPayment ? onAdd : undefined}
          remove={showRemoveIcon ? onRemove : undefined}
          hideMoreButton
          hideTooltip
        />
      </div>

      <TableStateBagProvider tableInstance={null}>
        <Table
          mode="client"
          columns={columns}
          data={payments}
          allItemsCount={payments.length}
          className={clsx({ [styles.table]: payments.length > 0 })}
          onSelectedRowsChange={onSelectedRowsChange}
          displayFooter={displayFooter}
          resizableColumns
          hideHeaderTooltip
        />
      </TableStateBagProvider>
    </div>
  );
};
