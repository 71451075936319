import styles from './Base.module.scss';

import clsx from 'clsx';
import React, { useMemo } from 'react';

import { Tooltip } from '../../../components/tooltip/Tooltip';
import {
  BaseActionButton,
  IBaseActionButtonProps,
} from '../../base-action-button/BaseActionButton';

export const Base: React.FC<IBaseActionButtonProps> = (props) => {
  const tooltipValue = useMemo(() => {
    if (props.disabled && props?.disableReason) return props.disableReason;

    return props.title;
  }, [props?.disableReason, props.disabled, props.title]);

  return (
    <Tooltip title={tooltipValue} placement="top">
      <span>
        <BaseActionButton
          className={clsx(styles.button, { [styles.disabled]: props.disabled })}
          {...props}
        />
      </span>
    </Tooltip>
  );
};
