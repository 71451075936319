import { MouseEventHandler } from 'react';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { LockProvider } from '../../../hooks';
import { IConvertPopoverProps } from '../../convert-popover/ConvertPopover';
import { useEntityPreviewData } from '../hooks/use-entity-preview-data';

import { AppointmentPreview } from './AppointmentPreview';

export type IAppointmentPreviewContainerProps = {
  appointmentIds: number[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCloseClicked?: MouseEventHandler<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onEditClicked?: MouseEventHandler<any>;
  onShareClicked?: () => void;
  convertProps?: Pick<IConvertPopoverProps, 'exclude' | 'onClick'>;
};
export const appointmentPreviewFields: Appointment<EMode.query> = {
  id: null,
  title: null,
  user: {
    id: null,
    displayName: null,
  },
  city: null,
  startDate: null,
  endDate: null,
  businessPartner: {
    id: null,
    businessPartnerType: null,
    data: {
      customer: {
        id: null,
        name: null,
        website: null,
      },
      supplier: {
        id: null,
        name: null,
        website: null,
      },
    },
  },
  contact: {
    id: null,
    displayName: null,
  },
  project: {
    id: null,
    name: null,
  },
  contractId: null,
  contract: {
    id: null,
    note: null,
    contractNumber: null,
  },
  deliveryNoteId: null,
  deliveryNote: {
    id: null,
    note: null,
    number: null,
  },
  note: null,
  isWholeDay: null,
  toAbsolute: null,
  fromAbsolute: null,
  appointmentState: { id: null, name: null },
  appointmentAttendeeList: [
    {
      id: null,
      ressource: {
        displayName: null,
        id: null,
        userKind: null,
        loginName: null,
      },
      user: {
        id: null,
        displayName: null,
      },
      contact: {
        id: null,
        displayName: null,
      },
      businessPartner: {
        id: null,
        data: {
          customer: { id: null, name: null, website: null },
          supplier: { id: null, name: null, website: null },
        },
      },
    },
  ],
};
export function AppointmentPreviewContainer(
  props: IAppointmentPreviewContainerProps
) {
  const {
    appointmentIds,
    onCloseClicked,
    onEditClicked,
    onShareClicked,
    convertProps,
    ...others
  } = props;
  const appointments = useEntityPreviewData<Appointment>(
    appointmentIds,
    Entities.appointment,
    appointmentPreviewFields
  );
  if (!appointments) {
    return null;
  }

  return (
    <LockProvider>
      <AppointmentPreview
        onCloseClicked={onCloseClicked}
        onEditClicked={onEditClicked}
        onShareClicked={onShareClicked}
        convertProps={convertProps}
        appointments={appointments}
        {...others}
      />
    </LockProvider>
  );
}
