import { RxCollection } from 'rxdb';
import { Observable } from 'rxjs';

export type RecreateCollection = (name: string) => Promise<RxCollection>;
export type GetCollection$ = (
  collectionName: string
) => Observable<RxCollection>;

export enum Adapter {
  indexeddb = 'indexeddb',
  memory = 'memory',
  idb = 'idb',
  cordovasqlite = 'cordova-sqlite',
}
