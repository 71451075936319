import styles from '../PositionPicker.module.scss';

import { useEventCallback } from '@mui/material/utils';
import React, { useMemo, useRef, useState } from 'react';
import { TableInstance } from 'react-table';

import { actionsMapper } from '@work4all/components/lib/dataDisplay/actions-bar/ActionsBar';
import {
  ColumnInstance,
  FilterType,
  useTableStateBag,
} from '@work4all/components/lib/dataDisplay/basic-table';
import {
  filterIsEmpty,
  genFilterTitle,
} from '@work4all/components/lib/dataDisplay/basic-table/utils/genFilterTitle';
import { IActionConfig } from '@work4all/components/lib/input/actions/types';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ActionConfig } from '../../../../../../../../../../../components/data-tables/table/components/toolbar/components/actions-bar-mobile/ActionsMenuMobile';
import { FiltersOverlay } from '../../../../../../../../../../../components/data-tables/table/components/toolbar/components/filters/filters-overlay/FiltersOverlay';
import { SelectedFiltersList } from '../../../../../../../../../../../components/data-tables/table/components/toolbar/components/filters/selected-filters-list/SelectedFiltersList';
import { WithBubble } from '../../../../../../../../../../../components/data-tables/table/components/with-bubble/WithBubble';
import { TableFilter } from '../../../../../../../../../../../components/data-tables/table/TableFilter';
import {
  settings,
  useSetting,
} from '../../../../../../../../../../../settings';

interface PositionsFilterProps {
  disabled: boolean;
  tableInstanceRef: React.RefObject<TableInstance>;
  entity: Entities;
}

export const PositionsFilter = (props: PositionsFilterProps) => {
  const { tableInstanceRef, entity, disabled } = props;
  const tableStateBag = useTableStateBag();
  const { tableState } = tableStateBag;

  const [showFilterOverlay, setFilterOverlay] = useState(false);
  const filtersRootRef = useRef<HTMLDivElement | HTMLButtonElement>();

  const closedEntitiesSetting = useSetting(
    settings.hideClosedEntities({ entityType: entity })
  );

  const setHideClosedEntitiesSetting = useEventCallback((value: boolean) => {
    closedEntitiesSetting.set(value);
  });

  // TODO this is copy of Toolbar so maybe we can make hook
  const chips = useMemo(() => {
    // visibleColumns don't change when filters are updated
    // to trigger memo update I pass additional filters value.
    return (
      tableInstanceRef.current?.columns
        ?.map((col: ColumnInstance) => {
          if (!col.isPrimaryFilter && filterIsEmpty(col)) {
            return null;
          }

          return {
            title: filterIsEmpty(col)
              ? (col.Header as string)
              : genFilterTitle(col),
            readOnly: !!col.filterValue?.readOnly,
            renderFilter: (item) => (
              <TableFilter column={col}>{item}</TableFilter>
            ),
            handleDelete: filterIsEmpty(col)
              ? undefined
              : () => {
                  if (
                    (col as ColumnInstance).filterType ===
                    FilterType.ClosedStatus
                  ) {
                    col.setFilter({
                      filterType: FilterType.ClosedStatus,
                      value: false,
                    });

                    setHideClosedEntitiesSetting(false);
                  } else {
                    col.setFilter(null);
                  }
                },
          };
        })
        .filter(Boolean)
        // Move all read-only filter to the beginning of the list.
        .sort((a, b) => {
          if (a.readOnly && !b.readOnly) {
            return -1;
          }
          return 0;
        }) ?? []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableState?.filters]);

  const actions: Record<'filter', (IActionConfig & ActionConfig) | null> =
    useMemo(() => {
      return {
        filter: tableStateBag?.tableInstance?.allColumns.find(
          (x) => x.canFilter
        )
          ? {
              onClick: () => {
                setFilterOverlay(true);
              },
              children: (
                <WithBubble count={chips.length}>
                  <SelectedFiltersList
                    list={chips}
                    classes={{
                      root: styles['chip-background'],
                    }}
                  />
                </WithBubble>
              ),
              rootRef: filtersRootRef,
            }
          : null,
      };
    }, [chips, tableStateBag?.tableInstance?.allColumns]);
  const isGrouped = tableState?.groupBy?.length > 0;
  return (
    <React.Fragment>
      {showFilterOverlay && (
        <FiltersOverlay
          overlayOpen={showFilterOverlay}
          onClose={() => setFilterOverlay(false)}
        />
      )}
      {actionsMapper('filter', { ...actions, isGrouped, disabled })}
    </React.Fragment>
  );
};
