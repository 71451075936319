import styles from './RemovePositionButton.module.scss';

import { IconButton } from '@mui/material';
import React from 'react';

import { ReactComponent as RemoveIcon } from '@work4all/assets/icons/remove.svg';

import { IdType } from '../edit-table/types';
import { useSelectedPositionState } from '../hooks/use-selected-position';

interface RemovePositionButtonProps {
  onRemovePosition: (positionId: IdType[]) => void;
  disabled: boolean;
}

export const RemovePositionButton = (props: RemovePositionButtonProps) => {
  const { onRemovePosition, disabled } = props;

  const { selectedPositionIds } = useSelectedPositionState();
  if (!(selectedPositionIds.length > 0)) return null;

  return (
    <IconButton
      disabled={disabled}
      onClick={() => onRemovePosition(selectedPositionIds)}
    >
      <RemoveIcon className={styles.icon} />
    </IconButton>
  );
};
