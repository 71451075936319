import styles from './WidgetPreview.module.scss';

import { Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Calculation } from '@work4all/models/lib/Classes/Calculation.entity';
import { Contract } from '@work4all/models/lib/Classes/Contract.entity';
import { DeliveryNote } from '@work4all/models/lib/Classes/DeliveryNote.entity';
import { Invoice } from '@work4all/models/lib/Classes/Invoice.entity';
import { Offer } from '@work4all/models/lib/Classes/Offer.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { typeNameToEntity } from '@work4all/utils';

interface WidgetPreviewProps {
  item: Offer | Invoice | Contract | Calculation | DeliveryNote;
}

export const WidgetPreview = (props: WidgetPreviewProps) => {
  const { item } = props;
  const { t } = useTranslation();

  const entity = useMemo(() => {
    return typeNameToEntity(item?.__typename);
  }, [item]);

  return (
    <div className={styles['preview']}>
      <div className={styles['preview-container']}>
        <div className={styles['preview-text-container']}>
          <div>
            <Typography color="primary" variant="h4">
              {t(`COMMON.${entity.toString().toUpperCase()}`)}
            </Typography>
            <Typography variant="body2" noWrap>
              {entity === Entities.contract
                ? (item as Contract).contractNumber
                : item.number}
            </Typography>
          </div>
        </div>
        <svg
          width="54"
          height="62"
          viewBox="0 0 54 62"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.400024 4.39997C0.400024 2.04355 2.31028 0.133301 4.66669 0.133301H38.8L51.6 12.9333V55.6C51.6 57.9564 49.6898 59.8666 47.3334 59.8666H4.66669C2.31028 59.8666 0.400024 57.9564 0.400024 55.6V4.39997Z"
            fill="var(--ui01"
          />
          <path
            d="M1.40002 5.39997C1.40002 3.04355 3.31028 1.1333 5.66669 1.1333H39.8L52.6 13.9333V56.6C52.6 58.9564 50.6898 60.8666 48.3334 60.8666H5.66669C3.31028 60.8666 1.40002 58.9564 1.40002 56.6V5.39997Z"
            fill="var(--brand01)"
            stroke="var(--brand01)"
            stroke-width="2"
            fillOpacity="0.14"
          />
        </svg>
      </div>
    </div>
  );
};
