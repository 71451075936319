import styles from './../../MaskOverlay.module.scss';

import { memo, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useLock } from '@work4all/components/lib/hooks';

import { useUser } from '@work4all/data';
import { useCustomFieldsConfig } from '@work4all/data/lib/custom-fields';
import { useDataMutation } from '@work4all/data/lib/hooks/data-provider/useDataMutation';
import { useSearchHistory } from '@work4all/data/lib/hooks/use-search-history';
import { useEntityJsonSchema } from '@work4all/data/lib/json-schema/EntityJsonSchemasContext';

import { BusinessPartnerContactCombined } from '@work4all/models/lib/Classes/BusinessPartnerContactCombined.entity';
import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { CostCenter } from '@work4all/models/lib/Classes/CostCenter.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Group } from '@work4all/models/lib/Classes/Group.entity';
import { InputProjektRelation } from '@work4all/models/lib/Classes/InputProjektRelation.entity';
import { LookUp } from '@work4all/models/lib/Classes/LookUp.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { useJSONSchemaResolver } from '@work4all/utils';
import {
  canAddProject,
  canDeleteProject,
  canEditProject,
} from '@work4all/utils/lib/permissions';

import { usePageTitle } from '../../../../../hooks';
import { formatPageTitle } from '../../../../../utils/format-page-title';
import {
  MaskTab,
  MaskTabContext,
  MaskTabPanel,
  MaskTabs,
} from '../../../mask-tabs';
import { MaskOverlayStandardSubmitButton } from '../../components';
import { INDIVIDUAL_TAB_ID } from '../../components/custom-fields/contants';
import { CUSTOM_FIELDS_DATA } from '../../components/custom-fields/custom-fields-data';
import { IndividualTabPanel } from '../../components/custom-fields/IndividualTabPanel';
import { normalizeCustomFields } from '../../components/custom-fields/normalize-custom-fields';
import { prepareInputWithCustomFields } from '../../components/custom-fields/prepare-input-with-custom-fields';
import { Form } from '../../components/form';
import { MaskContent } from '../../components/MaskContent/MaskContent';
import { MaskOverlayDeleteMenuItem } from '../../components/MaskOverlayDeleteMenuItem';
import { MaskOverlayFullscreenToggleButton } from '../../components/MaskOverlayFullscreenToggleButton';
import { MaskOverlayHeader } from '../../components/MaskOverlayHeader/MaskOverlayHeader';
import { MaskOverlayMenuWrapper } from '../../components/MaskOverlayMenuWrapper';
import {
  MaskContextProvider,
  useMaskConfig,
  useMaskContextValue,
} from '../../hooks/mask-context';
import { useConfirmBeforeCloseMask } from '../../hooks/use-confrm-before-close-mask';
import { EntityRightsContext } from '../../hooks/use-entity-rights';
import { useStandardDeleteEntityHandler } from '../../hooks/use-standard-delete-entity-handler';
import { normalizeFormValue } from '../../hooks/useExtendedFormContext';
import { useInitialFormValue } from '../../hooks/useInitialFormValue';
import { MaskControllerProps } from '../../types';
import { pickUpdateFields } from '../../utils/pick-update-fields';
import { useFormUpdate } from '../../utils/use-form-update';

import { GeneralTabPanel } from './components/tab-panels/general/GeneralTabPanel';
import { useProjectDefaultData } from './hooks/use-project-default-data';
import { ProjectMaskFormValue } from './types';

export const ProjectOverlayController = (props: MaskControllerProps) => {
  const { t } = useTranslation();
  const mask = useMaskConfig(props);

  const customFields = useCustomFieldsConfig({ entity: Entities.project });

  const requestData = useMemo(() => {
    // we will always filter by id and will always try to normalize the data
    // so an id field will be available
    const filter = [{ id: { $eq: mask.id } }];

    const data: Project<EMode.query> = {
      id: null,
      name: null,
      note: null,
      number: null,
      externalProjectManager: null,
      budget: null,
      lockInhouseServices: null,
      lockExternalServices: null,
      endDateInner: null,
      startDateInner: null,
      endDateOuter: null,
      startDateOuter: null,
      // projectCategories:[{
      //   id:null,
      //   name: null
      // }],
      group: {
        id: null,
        name: null,
      },
      costCenter: {
        id: null,
        name: null,
      },
      projectStatus: {
        name: null,
        id: null,
      },
      projectKind: {
        name: null,
        id: null,
      },
      parentProject: {
        name: null,
        id: null,
      },
      customer: {
        name: null,
        id: null,
        website: null,
      },
      customerContact: {
        displayName: null,
        name: null,
        id: null,
      },
      supplier: {
        name: null,
        id: null,
        website: null,
      },
      supplierContact: {
        displayName: null,
        name: null,
        id: null,
      },
      internalProjectManager: {
        displayName: null,
        id: null,
      },
      categoryAssignmentList: [
        {
          id: null,
          categoryName: null,
          categoryId: null,
          categoryKindName: null,
        },
      ],
      customFieldList: [CUSTOM_FIELDS_DATA],
      additionalAddress1: {
        businessPartner: {
          id: null,
          businessPartnerType: null,
          data: {
            customer: { id: null, name: null, number: null, website: null },
            supplier: { id: null, name: null, number: null, website: null },
          },
        },
      },
      additionalAddress2: {
        businessPartner: {
          id: null,
          businessPartnerType: null,
          data: {
            customer: { id: null, name: null, number: null, website: null },
            supplier: { id: null, name: null, number: null, website: null },
          },
        },
      },
      projectAccessGroups: [
        {
          id: null,
          name: null,
        },
      ],
      childProjects: [{ id: null }],
    };

    return {
      filter,
      entity: Entities.project,
      data,
    };
  }, [mask.id]);

  // TODO Similar changes must be made to the queries for "parent" or "template"
  // entities. Right now they use "live" queries that will get updated after
  // a mutation.
  //
  // Maybe there is a better way to implement this. What we need is fetch the
  // data once, re-fetch if the query changes, and be able to manually refresh
  // the query after a form submit. But ignore all updates caused by changes
  // to Apollo's cache.
  const initialFormValue = useInitialFormValue<ProjectMaskFormValue>(
    requestData,
    mask.isCreateMode
  );

  const user = useUser();

  const newEntityData: Project = useProjectDefaultData(mask);

  const dataRaw = mask.isCreateMode
    ? newEntityData
    : initialFormValue.value ?? newEntityData;

  const data = useMemo(() => {
    return normalizeCustomFields(normalizeFormValue(dataRaw), customFields);
  }, [dataRaw, customFields]);

  //validation and mutation
  const schema = useEntityJsonSchema(mask.entity);
  const resolver = useJSONSchemaResolver(schema);

  const form = useForm<ProjectMaskFormValue>({
    resolver,
    mode: 'onChange',
    defaultValues: data,
    shouldFocusError: false,
    context: {
      schema,
    },
  });

  const { handleSubmit, reset, formState } = form;

  useLock(
    {
      subEntityType: Entities.project,
      subEntityIds: data.id ? [data.id.toString()] : [],
    },
    [data]
  );

  useEffect(() => {
    reset(data);
  }, [reset, data]);

  useConfirmBeforeCloseMask(formState.isDirty);

  const { saveSearchItem } = useSearchHistory();
  const [mutate] = useDataMutation<Project, EMode.upsert, InputProjektRelation>(
    {
      entity: mask.entity,
      mutationType: EMode.upsert,
      responseData: requestData.data as unknown as Project<EMode.entity>,
      onCompleted: (data) => {
        if (mask.isCreateMode) {
          saveSearchItem(Entities.project, {
            id: data.id.toString(),
            number: data.number,
            name: data.name,
          });
        }

        props.onAfterSave(data);
      },
    }
  );

  usePageTitle(
    formatPageTitle([t(`COMMON.${mask.entity.toUpperCase()}`), data?.name]),
    {
      priority: 1,
    }
  );

  const handleDeleteEntitiesClick = useStandardDeleteEntityHandler(mask);

  const submitForm = async (submitData: ProjectMaskFormValue) => {
    const relations: InputProjektRelation = {
      projectCategories: {
        add: submitData?.categoryAssignmentList
          ?.filter(
            (currentAssignment) =>
              !data?.categoryAssignmentList?.find(
                (prevAssignment) =>
                  currentAssignment.categoryId === prevAssignment.categoryId
              )
          )
          ?.map((assignment) => ({
            categoryCode: assignment.categoryId,
          })),
        remove: data.categoryAssignmentList
          ?.filter(
            (prevAssignment) =>
              !submitData?.categoryAssignmentList.find(
                (currentAssignment) =>
                  currentAssignment.categoryId === prevAssignment.categoryId
              )
          )
          ?.map((assignment) => assignment.categoryId),
      },
    };

    const updateRaw = mask.isCreateMode
      ? submitData
      : pickUpdateFields(submitData, formState.dirtyFields);

    if (updateRaw.projectAccessGroups && !submitData.parentProjectId) {
      const accessGroupIds = (data.projectAccessGroups || []).map((x) => x.id);
      const newAccessGroupIds = updateRaw.projectAccessGroups.map((x) => x.id);
      const add = newAccessGroupIds.filter((x) => !accessGroupIds.includes(x));
      const remove = accessGroupIds.filter(
        (x) => !newAccessGroupIds.includes(x)
      );
      relations.projectAccessGroups = { add, remove };
    }

    const updateMapped = prepareInputWithCustomFields(updateRaw);
    await mutate(updateMapped, { relations });
  };

  const entityRights = useMemo(
    () => ({
      create: canAddProject(user),
      read: false,
      update: canEditProject(user, data),
      delete: canDeleteProject(user, data),
    }),
    [data, user]
  );

  useFormUpdate<ProjectMaskFormValue>(
    {
      customer: (customer: Customer) => {
        const customerId = customer?.id ?? 0;
        return { customerId };
      },
      customerContact: (contact: Contact) => {
        const customerContactId = contact?.id ?? 0;

        return { customerContactId };
      },
      supplier: (supplier: Supplier) => {
        const supplierId = supplier?.id ?? 0;
        return { supplierId };
      },
      supplierContact: (contact: Contact) => {
        const supplierContactId = contact?.id ?? 0;

        return { supplierContactId };
      },
      projectKind: (value: LookUp) => {
        const projectKindId = value?.id ?? 0;

        return { projectKindId };
      },
      projectStatus: (value: LookUp) => {
        const projectStatusId = value?.id ?? 0;

        return { projectStatusId };
      },
      parentProject: (project: Project) => {
        const parentProjectId = project?.id ?? 0;
        if (!parentProjectId) {
          return {
            parentProjectId,
          };
        }

        const update: Project = {};
        if (parentProjectId) {
          update.parentProjectId = parentProjectId;
        }

        if (project?.projectAccessGroups) {
          update.projectAccessGroups = project?.projectAccessGroups;
        }

        return update;
      },
      group: (group: Group) => {
        const groupId = group?.id ?? 0;
        return { groupId };
      },
      internalProjectManager: (value: User) => {
        const internalProjectManagerId = value?.id ?? 0;

        return { internalProjectManagerId };
      },
      costCenter: (value: CostCenter) => {
        const costCenterId = value?.id ?? 0;

        return { costCenterId };
      },
      additionalAddress1: (value: BusinessPartnerContactCombined) => {
        const additionalAddressId = value?.businessPartner?.id || 0;
        const additionalAddressType =
          value?.businessPartner?.businessPartnerType === SdObjType.KUNDE
            ? 1
            : 0;

        return { additionalAddressId, additionalAddressType };
      },
      additionalAddress2: (value: BusinessPartnerContactCombined) => {
        const additionalAddress2Id = value?.businessPartner?.id || 0;
        const additionalAddress2Type =
          value?.businessPartner?.businessPartnerType === SdObjType.KUNDE
            ? 1
            : 0;

        return { additionalAddress2Id, additionalAddress2Type };
      },
    },
    form
  );

  const maskContext = useMaskContextValue({ ...mask, data, customFields });
  const shouldRenderIndividualTab = customFields && customFields.length > 0;

  return (
    <MaskContextProvider value={maskContext}>
      <EntityRightsContext.Provider value={entityRights}>
        <FormProvider {...form}>
          <MaskTabContext defaultValue="general">
            <Form
              className={styles.maskForm}
              onSubmit={handleSubmit(submitForm)}
            >
              <MaskOverlayHeader
                title={t(`COMMON.${mask.entity.toUpperCase()}`)}
                subTitle={data?.name}
                actions={
                  <>
                    <MaskOverlayStandardSubmitButton />
                    <MaskOverlayFullscreenToggleButton />
                    {mask.isEditMode && (
                      <MaskOverlayMenuWrapper>
                        <MaskOverlayDeleteMenuItem
                          disabled={mask.wip || !entityRights.delete}
                          onClick={handleDeleteEntitiesClick}
                        />
                      </MaskOverlayMenuWrapper>
                    )}
                  </>
                }
                tabs={
                  <ProjectTabs
                    renderIndividualTab={shouldRenderIndividualTab}
                  />
                }
              />
              <Content renderIndividualTab={shouldRenderIndividualTab} />
            </Form>
          </MaskTabContext>
        </FormProvider>
      </EntityRightsContext.Provider>
    </MaskContextProvider>
  );
};

const ProjectTabs = memo(function ProjectTabs({
  renderIndividualTab,
}: {
  renderIndividualTab: boolean;
}) {
  const { t } = useTranslation();

  return (
    <MaskTabs>
      <MaskTab value="general" label={t('MASK.GENERAL')}></MaskTab>
      {renderIndividualTab && (
        <MaskTab value={INDIVIDUAL_TAB_ID} label={t('MASK.INDIVIDUAL')} />
      )}
    </MaskTabs>
  );
});

const Content = memo(function ProjectTabPanels({
  renderIndividualTab,
}: {
  renderIndividualTab: boolean;
}) {
  return (
    <MaskContent>
      <MaskTabPanel value="general">
        <GeneralTabPanel />
      </MaskTabPanel>

      {renderIndividualTab && (
        <MaskTabPanel value={INDIVIDUAL_TAB_ID}>
          <IndividualTabPanel />
        </MaskTabPanel>
      )}
    </MaskContent>
  );
});
