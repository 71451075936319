import { useApolloClient, useMutation } from '@apollo/client';
import { useEventCallback } from '@mui/material/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import {
  catchError,
  defer,
  EMPTY,
  firstValueFrom,
  map,
  merge,
  Observable,
  Subject,
  switchMap,
  takeUntil,
  tap,
  timer,
} from 'rxjs';

import { translateInput } from '@work4all/data/lib/hooks/data-provider';

import { InboundDeliveryNote } from '@work4all/models/lib/Classes/InboundDeliveryNote.entity';
import { InputEingangsrechnungRelation } from '@work4all/models/lib/Classes/InputEingangsrechnungRelation.entity';
import { InputErpAnhangAttachementsRelation } from '@work4all/models/lib/Classes/InputErpAnhangAttachementsRelation.entity';
import { ModifyShadowREResult } from '@work4all/models/lib/Classes/ModifyShadowREResult.entity';
import { Order } from '@work4all/models/lib/Classes/Order.entity';
import { RELedgerAccountSplit } from '@work4all/models/lib/Classes/RELedgerAccountSplit.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { settings, useSetting } from '../../../../../../settings';
import { NEW_ENTITY_ID } from '../../../mask-metadata';
import { IUseShadowObjectApiOptions } from '../../erp/hooks/use-bz-shadow-object-api/IUseShadowObjectApiOptions';

import {
  CREATE_SHADOW_RE_OBJECT,
  CreateShadowReObjectResponse,
  CreateShadowReObjectVars,
  DELETE_SHADOW_RE_OBJECT,
  DeleteShadowBzObjectVars,
  DeleteShadowReObjectResponse,
  MODIFY_SHADOW_BZ_OBJECT_ADD_POSITION,
  MODIFY_SHADOW_BZ_OBJECT_MODIFY_POSITION,
  MODIFY_SHADOW_BZ_OBJECT_MOVE_POSITION,
  MODIFY_SHADOW_BZ_OBJECT_REMOVE_BOOKING,
  MODIFY_SHADOW_RE_OBJECT,
  ModifyShadowBzObjectAddPositionResponse,
  ModifyShadowBzObjectAddPositionVars,
  ModifyShadowBzObjectModifyBookingResponse,
  ModifyShadowBzObjectModifyBookingVars,
  ModifyShadowBzObjectMovePositionResponse,
  ModifyShadowBzObjectMovePositionVars,
  ModifyShadowBzObjectRemoveBookingResponse,
  ModifyShadowBzObjectRemoveBookingVars,
  ModifyShadowReObjectResponse,
  ModifyShadowReObjectVars,
  PERSIST_SHADOW_RE_OBJECT,
  PersistShadowReObjectResponse,
  PersistShadowReObjectVars,
} from './use-shadow-re-object-graphql';

export type MutationType = 'create' | 'modify' | 'persist';

export type ShadowReObjectApiMethods = ReturnType<
  typeof useShadowReObjectApi
>[1];

export function useShadowReObjectApi(options: IUseShadowObjectApiOptions) {
  const { entity, id, parentEntity, parentId, skip, projectId } = options;

  const client = useApolloClient();

  const [shadowObject, setShadowObject] = useState<
    (ModifyShadowREResult & { type: MutationType }) | null
  >(null);

  const [deferredQueue, setDeferredQueue] = useState<Subject<
    Observable<ModifyShadowREResult>
  > | null>(() => new Subject());
  const [immediateQueue, setImmediateQueue] = useState<Subject<
    Observable<ModifyShadowREResult>
  > | null>(() => new Subject());
  const [stop, setStop] = useState<Subject<void> | null>(() => new Subject());
  const [stopped, setStopped] = useState<Subject<void> | null>(
    () => new Subject()
  );

  const [isDirty, setIsDirty] = useState(false);

  const init = useCallback(() => {
    unstable_batchedUpdates(() => {
      setDeferredQueue(new Subject());
      setImmediateQueue(new Subject());
      setStop(new Subject());
      setStopped(new Subject());

      setIsDirty(false);
    });
  }, []);

  const [mutateCreate] = useMutation<
    CreateShadowReObjectResponse,
    CreateShadowReObjectVars
  >(CREATE_SHADOW_RE_OBJECT, {
    onCompleted(response) {
      setShadowObject({ ...response.createShadowRe, type: 'create' });
      init();
    },
  });

  const [mutateModify] = useMutation<
    ModifyShadowReObjectResponse,
    ModifyShadowReObjectVars
  >(MODIFY_SHADOW_RE_OBJECT);

  const [mutateAddPosition] = useMutation<
    ModifyShadowBzObjectAddPositionResponse,
    ModifyShadowBzObjectAddPositionVars
  >(MODIFY_SHADOW_BZ_OBJECT_ADD_POSITION);

  const [mutateMovePosition] = useMutation<
    ModifyShadowBzObjectMovePositionResponse,
    ModifyShadowBzObjectMovePositionVars
  >(MODIFY_SHADOW_BZ_OBJECT_MOVE_POSITION);

  const [mutateModifyBooking] = useMutation<
    ModifyShadowBzObjectModifyBookingResponse,
    ModifyShadowBzObjectModifyBookingVars
  >(MODIFY_SHADOW_BZ_OBJECT_MODIFY_POSITION);

  const [mutateRemoveBooking] = useMutation<
    ModifyShadowBzObjectRemoveBookingResponse,
    ModifyShadowBzObjectRemoveBookingVars
  >(MODIFY_SHADOW_BZ_OBJECT_REMOVE_BOOKING);

  const [mutatePersist] = useMutation<
    PersistShadowReObjectResponse,
    PersistShadowReObjectVars
  >(PERSIST_SHADOW_RE_OBJECT, {
    onCompleted() {
      // Clears out the cache and then re-executes all active queries.
      // This will ensure no stale data is displayed.
      client.resetStore();
    },
  });

  const [mutateDelete] = useMutation<
    DeleteShadowReObjectResponse,
    DeleteShadowBzObjectVars
  >(DELETE_SHADOW_RE_OBJECT);

  useEffect(() => {
    if (!deferredQueue || !immediateQueue || !stop) return;

    const subscription = merge(
      deferredQueue.pipe(
        tap(() => setIsDirty(true)),
        takeUntil(stop),
        switchMap((request) => {
          return timer(250).pipe(map(() => request));
        })
      ),
      immediateQueue.pipe(
        tap(() => setIsDirty(true)),
        takeUntil(stop)
      )
    )
      .pipe(
        switchMap((request) => {
          return request.pipe(
            takeUntil(merge(deferredQueue, immediateQueue)),
            catchError(() => EMPTY)
          );
        }),
        tap((result) => {
          setIsDirty(true);
          setShadowObject({ ...result, type: 'modify' });
        })
      )
      .subscribe({
        complete() {
          stopped.next();
        },
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [deferredQueue, immediateQueue, stop, stopped]);

  useEffect(() => {
    const variables: CreateShadowReObjectVars =
      id == null || id === NEW_ENTITY_ID
        ? {
            sdObjMemberCode: Number(parentId),
            projectCode: projectId ? Number(projectId) : undefined,
          }
        : {
            reCode: Number(id),
          };
    if (!skip) mutateCreate({ variables });
  }, [mutateCreate, entity, id, parentEntity, parentId, skip, projectId]);

  useEffect(() => {
    const id = shadowObject?.id;

    if (id) {
      return () => {
        mutateDelete({ variables: { id } });
      };
    }
  }, [mutateDelete, shadowObject?.id]);

  const modify = useEventCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (value: any, relations: InputEingangsrechnungRelation) => {
      if (!shadowObject) return;

      const { id } = shadowObject;
      deferredQueue.next(
        defer(() =>
          mutateModify({
            variables: {
              id,
              invoiceData: translateInput(entity, value),
              relations,
            },
          }).then(({ data }) => data.modifyShadowRe)
        )
      );
    }
  );

  const inboundInvoiceCloseConnectedObjects = useSetting(
    settings.inboundInvoiceCloseConnectedObjects()
  );

  const persist = useEventCallback(
    async (
      attachments?: InputErpAnhangAttachementsRelation,
      receipts?: InputErpAnhangAttachementsRelation
    ) => {
      if (!shadowObject) return;

      const promise = firstValueFrom(
        stopped.pipe(
          switchMap(async () => {
            await mutatePersist({
              variables: {
                id: shadowObject.id,
                attachments,
                receipts,
                closeAssignedObjects:
                  inboundInvoiceCloseConnectedObjects.value === true,
              },
            });
          }),
          tap(() => {
            init();
          })
        )
      );

      stop.next();

      await promise;
    }
  );

  const addBooking = useEventCallback((booking: RELedgerAccountSplit) => {
    if (!shadowObject) return;

    const { id } = shadowObject;

    immediateQueue.next(
      defer(async () => {
        const addPositionResponse = await mutateAddPosition({
          variables: { id },
        });

        const standardAcccount =
          addPositionResponse.data.modifyShadowReAddBuchung.data.supplier
            .standardAccount;
        const bookings =
          addPositionResponse.data.modifyShadowReAddBuchung.data.buchungen;

        const last = bookings[bookings.length - 1];

        if (!last) {
          throw new Error('Could not find the newly created booking.');
        }

        const bookingId = last.id;

        const modifyBookingResponse = await mutateModifyBooking({
          variables: {
            id,
            booking: resolveBookingInput({
              konto:
                standardAcccount !== null
                  ? { id: standardAcccount }
                  : undefined,
              ...booking,
              id: bookingId,
            }),
          },
        });

        return modifyBookingResponse.data.modifyShadowREModifyLine;
      })
    );
  });

  const recreateTable = useEventCallback(
    ({
      orders,
      deliveryNotes,
    }: {
      orders: Order[];
      deliveryNotes: InboundDeliveryNote[];
    }) => {
      if (!shadowObject) return;

      const { id } = shadowObject;

      const fromOrders = orders.map((order) => order.id);
      const fromDeliveryNotes = deliveryNotes.map((note) => note.id);

      immediateQueue.next(
        defer(() => {
          return mutateAddPosition({
            variables: { id, fromOrders, fromDeliveryNotes, recreate: true },
          }).then((response) => {
            return response.data.modifyShadowReAddBuchung;
          });
        })
      );
    }
  );

  const movePosition = useEventCallback((positionId: number, index: number) => {
    if (!shadowObject) return;

    const { id } = shadowObject;

    deferredQueue.next(
      defer(() =>
        mutateMovePosition({
          variables: {
            id,
            positionId,
            index,
          },
        }).then(({ data }) => data.modifyShadowBzObjectMovePosition)
      )
    );
  });

  const removeBookings = useEventCallback((lineIds: number[]) => {
    if (!shadowObject) return;

    const { id } = shadowObject;

    for (const lineId of lineIds) {
      immediateQueue.next(
        defer(() =>
          mutateRemoveBooking({
            variables: { id, lineId },
          }).then(({ data }) => data.modifyShadowReRemoveBuchung)
        )
      );
    }
  });

  const editBooking = useEventCallback((booking: RELedgerAccountSplit) => {
    if (!shadowObject) return;

    const { id } = shadowObject;

    immediateQueue.next(
      defer(() =>
        mutateModifyBooking({
          variables: {
            id,
            booking: resolveBookingInput(booking),
          },
        }).then(({ data }) => data.modifyShadowREModifyLine)
      )
    );
  });

  return [
    shadowObject,
    useMemo(
      () => ({
        isDirty,
        persist,
        modify,
        recreateTable,
        addBooking,
        movePosition,
        editBooking,
        removeBookings,
      }),
      [
        isDirty,
        persist,
        modify,
        recreateTable,
        addBooking,
        movePosition,
        editBooking,
        removeBookings,
      ]
    ),
  ] as const;
}

function resolveBookingInput(booking: RELedgerAccountSplit) {
  const expandedBooking: RELedgerAccountSplit = { ...booking };

  if (booking.konto !== undefined) {
    expandedBooking.ledgerAccountId = booking.konto?.id ?? 0;
  }

  if (booking.costCenter !== undefined) {
    expandedBooking.costCenterId = booking.costCenter?.id ?? 0;
  }

  if (booking.project !== undefined) {
    expandedBooking.projectId = booking.project?.id ?? 0;
  }

  if (booking.costGroup !== undefined) {
    expandedBooking.costGroupId = booking.costGroup?.id ?? 0;
  }

  delete expandedBooking['__typename'];

  return translateInput<RELedgerAccountSplit>(
    Entities.rELedgerAccountSplit,
    expandedBooking
  );
}
