import styles from './CrystalReportOptionsConf.module.scss';

import { MoreVert } from '@mui/icons-material';
import { Box, IconButton, Popover, Stack } from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';
import { CheckboxRadioItem } from '@work4all/components/lib/input/checkbox-radio-item';

import { useUser } from '@work4all/data';
import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { ReportOption } from '@work4all/models/lib/Classes/ReportOption.entity';
import { BzObjType } from '@work4all/models/lib/Enums/BzObjType.enum';

import { settings, useSetting } from '../../../../../../../../../settings';
import { useReportValues } from '../use-report-values';

interface CrystalReportOptionsConfProps {
  options: ReportOption[];
  bzObjType: BzObjType;
  reportId: number;
  reportOptionValues?: Record<number, boolean>;
  onChangeReportOptionValues?: (optId: number, value: boolean) => void;
}

export const CrystalReportOptionsConf = (
  props: CrystalReportOptionsConfProps
) => {
  const {
    options,
    bzObjType,
    reportId,
    reportOptionValues,
    onChangeReportOptionValues,
  } = props;

  const user = useUser();

  const crystalReportOptGlobalConf = useSetting(
    settings.crystalReportOptGlobalConf()
  );

  const handleOnAdminConfChange = useCallback(
    (
      optId: number,
      key: 'defaultValue' | 'userEditAllowed',
      value: boolean
    ) => {
      const persistedSettings = { ...crystalReportOptGlobalConf?.value };
      if (!persistedSettings[bzObjType]) {
        persistedSettings[bzObjType] = [];
      }

      const persistedReportConf = persistedSettings[bzObjType]?.find(
        (x) => x.reportId === reportId
      );
      if (!persistedReportConf) {
        persistedSettings[bzObjType].push({
          reportId,
          options: [],
        });
      }

      const persistedReportOptions = persistedSettings[bzObjType].find(
        (x) => x.reportId === reportId
      ).options;

      let persistedReportOptConf = persistedReportOptions.find(
        (x) => x.optionId === optId
      );
      if (!persistedReportOptConf) {
        persistedReportOptions.push({
          defaultValue: false,
          optionId: optId,
          userEditAllowed: true,
        });

        persistedReportOptConf = persistedReportOptions.find(
          (x) => x.optionId === optId
        );
      }

      persistedReportOptConf[key] = value;

      crystalReportOptGlobalConf.set(persistedSettings);
    },
    [bzObjType, crystalReportOptGlobalConf, reportId]
  );

  const { globalReportOptSettings } = useReportValues({
    bzObjectType: bzObjType,
    reportId,
  });

  const { t } = useTranslation();

  const { handleClick, ...popoverState } = usePopoverState('bottom');
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionSettings, setSelectedOptionSettings] = useState(null);

  return (
    <Stack>
      <Popover
        {...popoverState}
        transformOrigin={{ vertical: 'center', horizontal: 'left' }}
      >
        <Box padding="0 1rem">
          <CheckboxRadioItem
            checked={selectedOptionSettings?.defaultValue}
            label={t('REPORT.OPTIONS.SET_BY_DEFAULT')}
            onChange={(e) =>
              handleOnAdminConfChange(
                selectedOption?.optionId,
                'defaultValue',
                e.target.checked
              )
            }
          />

          <CheckboxRadioItem
            checked={selectedOptionSettings?.userEditAllowed}
            label={t('REPORT.OPTIONS.EDITABLE_BY_USER')}
            onChange={(e) =>
              handleOnAdminConfChange(
                selectedOption?.optionId,
                'userEditAllowed',
                e.target.checked
              )
            }
          />
        </Box>
      </Popover>
      {options?.length && <Divider title={t('MASK.OPTIONS')} />}
      {options?.map((opt, idx) => {
        const globalSetting = globalReportOptSettings.options?.find(
          (x) => x.optionId === opt.optionId
        ) || {
          defaultValue: false,
          optionId: opt.optionId,
          userEditAllowed: true,
        };

        return (
          <div className={styles.checkboxWrap} key={idx}>
            <div style={{ width: '100%' }}>
              <CheckboxRadioItem
                checked={reportOptionValues[opt.optionId]}
                label={opt.displayname}
                onChange={(e) =>
                  onChangeReportOptionValues(opt.optionId, e.target.checked)
                }
                disabled={!globalSetting.userEditAllowed}
                controlMargin={'xs'}
                className={styles.checkbox}
              />
            </div>
            {user.isMaster ? (
              <IconButton
                className={styles.settingsButton}
                size="small"
                onClick={(e) => {
                  setSelectedOptionSettings(globalSetting);
                  setSelectedOption(opt);
                  handleClick(e);
                }}
              >
                <MoreVert />
              </IconButton>
            ) : null}
          </div>
        );
      })}
    </Stack>
  );
};
