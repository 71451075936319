import { createContext, useContext } from 'react';

import { EMailSignature } from '@work4all/models/lib/Classes/EMailSignature.entity';
import { EMailTemplate } from '@work4all/models/lib/Classes/EMailTemplate.entity';

export type ApplyProps = {
  template?: EMailTemplate | null;
  signature?: EMailSignature | null;
  force?: boolean;
  skipSave?: boolean;
  shouldDirtyAffectedFields?: boolean;
};
export interface EmailTemplaterContext {
  /**
   * Email templates are currently should only enabled when creating new emails.
   *
   * If `true`, methods `applyTemplate` and `refreshTemplate` will do nothing.
   * In this case the template picker should not be displayed in the UI.
   */
  disabled: boolean;

  /** The currently selected email template. */
  signature: EMailSignature | null;
  /**
   * Set the given signature as selected and replace the current email signature section using
   * this signature.
   */
  applySignature: (props: ApplyProps) => void;

  /** The currently selected email template. */
  template: EMailTemplate | null;

  /**
   * Set the given template as selected and replace the current email body using
   * this template.
   *
   * If there is already content in the email body (the RTE is not empty), will
   * ask for confirmation first.
   *
   * Will do nothing if email templates are disabled.
   */
  applyTemplate: (props: ApplyProps) => void;

  /**
   * Variable tells if there is any processsing of template.
   */
  processing: boolean;

  initialTemplate?: EMailTemplate;
}

const EmailTemplaterContext = createContext<EmailTemplaterContext | null>(null);

export const EmailTemplaterContextProvider =
  EmailTemplaterContext.Provider as React.Provider<EmailTemplaterContext>;

export function useEmailTemplaterContext(): EmailTemplaterContext {
  const context = useContext(EmailTemplaterContext);

  if (context === null) {
    throw new Error(
      'useEmailTemplaterContext must be used within <EmailTemplaterContextProvider>'
    );
  }

  return context;
}
