import { httpClient, HttpClientResponse } from '@work4all/data';

import { Environment, environment } from '../../../environments';

interface AutoDiscoverServerParams {
  domain: string | undefined;
  environment: string;
}

export const getAutodiscoveryBaseUrl = async (
  email: string
): Promise<HttpClientResponse<string[]> | null> => {
  let domain;
  const atPos = email.indexOf('@');
  if (atPos !== -1) {
    domain = email.substring(atPos + 1, email.length);
  }

  const autoDiscoveryApiEnvironmentParam =
    AutoDiscoveryApiEnvironmentParamMap[environment.configuration];

  try {
    const res = await httpClient.get<string[], AutoDiscoverServerParams>({
      url: 'https://ws.work4all.de/api/service/autodiscoverserver',
      params: {
        domain: domain || undefined,
        environment: autoDiscoveryApiEnvironmentParam,
      },
      contentType: 'application/json',
    });

    return res;
  } catch (e) {
    return e;
  }
};

const AutoDiscoveryApiEnvironmentParamMap: Record<
  Environment['configuration'],
  string
> = {
  development: 'Dev',
  preview: 'Dev',
  staging: 'Staging',
  production: 'Production',
};
