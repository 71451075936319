import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { DEFAULT_PRE_FILTER } from '@work4all/components/lib/constants';

import { nameof } from '@work4all/data/lib/helper/nameof';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { EMailTemplateKind } from '@work4all/models/lib/Enums/EMailTemplateKind.enum';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useTablePrefilterContext } from '../../../containers/file-entities-lists/components/TablePrefilterProvider';
import { mapAppointmentToEmailParams } from '../../../containers/mask-overlays/mask-overlay/views/appointment/utils/mapAppointmentToEmailParams';
import { EntityTable, IEntityTable } from '../entity-table/EntityTable';
import { useEntityDataTable } from '../entity-table/use-entity-data-table';
import schema from '../schemata/appointment-table-schema.json';

import { useEmailTemplate } from './hooks/use-email-template';
import { useFixedAppointmentsData } from './hooks/use-fixed-appointments-data';

const APPOINTMENT_FORCE_REQUEST_FIELDS: Appointment<EMode.query> = {
  fromAbsolute: null,
  toAbsolute: null,
  appointmentAttendeeList: [
    {
      id: null,
      ressource: { id: null, eMail: null },
      user: {
        id: null,
        eMail: null,
      },
      contact: {
        id: null,
        eMail: null,
      },
      businessPartner: {
        id: null,
        data: {
          customer: { id: null, eMail: null },
          supplier: { id: null, eMail: null },
        },
      },
    },
  ],
  contact: {
    id: null,
    displayName: null,
    eMail: null,
  },
};

const defaultSort = [
  {
    field: nameof<Appointment>('startDate'),
    direction: SortDirection.DESCENDING,
  },
];

export const AppointmentsTable = React.forwardRef<TableInstance, IEntityTable>(
  function AppointmentsTable(_props, ref) {
    const { prefilter } = useTablePrefilterContext();
    const adaptedPreFilter = useMemo(
      () => [...DEFAULT_PRE_FILTER[Entities.appointment], ...(prefilter ?? [])],
      [prefilter]
    );

    const dataTable = useEntityDataTable<Appointment, never>({
      schema: schema as never,
      prefilter: adaptedPreFilter,
      defaultSort,
      forceRequestFields: APPOINTMENT_FORCE_REQUEST_FIELDS,
    });

    // Fix appointment start and end dates for full day appointments.
    const data = useFixedAppointmentsData(dataTable.data);
    const { t } = useTranslation();

    const getEmailParams = useCallback(
      async (_template, context: Appointment) => {
        const entityTemplate = {
          entity: Entities.appointment,
          id: context.id,
        };

        return {
          entityTemplate,
          params: mapAppointmentToEmailParams(context, {
            hourKey: t('COMMON.CLOCK'),
            wholeDay: t('COMMON.WHOLEDAYEVENT'),
          }),
        };
      },
      [t]
    );

    const { action, component } = useEmailTemplate({
      getEmailParams,
      kind: [
        EMailTemplateKind.KEINE,
        EMailTemplateKind.APPOINTMENT_NOTIFICATION,
      ],
      noTemplate: true,
    });

    return (
      <React.Fragment>
        {component}
        <EntityTable
          ref={ref}
          {...dataTable}
          data={data}
          actions={{
            custom: {
              right: [action],
            },
          }}
        />
      </React.Fragment>
    );
  }
);
