import { useEventCallback } from '@mui/material/utils';
import { createSearchParams, URLSearchParamsInit } from 'react-router-dom';

import { useLocation, useNavigate } from '@work4all/data';

import { NEW_ENTITY_ID } from '../mask-metadata';
import { useMaskMetaData } from '../MaskOverlay';
import { MaskConfig } from '../types';

export const useOnOpenMask = () => {
  const metaData = useMaskMetaData();

  const navigate = useNavigate();

  const location = useLocation();
  return useEventCallback(() => {
    const getNavigationPropeties = (config: MaskConfig) => {
      const { entity, id, template, params = {} } = config;
      // We can't use navigate('./{id}') form here because this component doesn't
      // actually render inside react-router's Route.
      // So relative navigation is not possible.

      let pathname = location.pathname;

      if (entity !== metaData.subEntityType) {
        // This might be problematic if the URL contains this fragment already in
        // some other place unrelated to the mask.
        pathname = pathname.replace(metaData.subEntityType, entity);
      }

      pathname = pathname.replace(
        metaData.subEntityId,
        id?.toString() ?? NEW_ENTITY_ID
      );

      const searchInit: URLSearchParamsInit = {};

      if (template) {
        searchInit.template = `${template.entity}:${template.id}`;
      }

      for (const [key, value] of Object.entries(params)) {
        if (value != null) {
          searchInit[key] = value;
        }
      }
      return {
        pathname,
        search: `${createSearchParams(searchInit)}`,
      };
    };

    return {
      getHref: (config: MaskConfig) => {
        const { pathname, search } = getNavigationPropeties(config);
        return `${pathname}?${search}`;
      },
      handler: (config: MaskConfig) => {
        const { pathname, search } = getNavigationPropeties(config);
        navigate(
          {
            pathname,
            search,
          },
          { replace: true }
        );
      },
    };
  });
};
