import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { PreviewTitle } from '../../../preview-title/PreviewTitle';
import { usePreviewInput } from '../../hooks';
import { PreviewTitleInputProps } from '../../types';

export const PreviewTitleInput = ({
  value = '',
  accessor,
  disabled,
  onClose,
  ...rest
}: PreviewTitleInputProps) => {
  const ref = useRef<PreviewTitle>(null);
  const { t } = useTranslation();
  const {
    previewValue,
    onClick,
    setValue,
    CircularProgress,
    isMultiselect,
    canEditAllSelected,
    isActive,
    isLoading,
  } = usePreviewInput<string>({
    formattedValue: value,
    accessor,
    disabled,
    ref,
    value,
    renderPreviewValue: ({ isMultiselect, numberOfSelectedRows }) => {
      return isMultiselect
        ? `${t('COMMON.SELECTION')}: ${numberOfSelectedRows} ${t(
            'COMMON.ELEMENTS'
          )}`
        : null;
    },
  });

  return (
    <PreviewTitle
      {...rest}
      ref={ref}
      onClick={onClick}
      actions={isActive && isLoading ? <CircularProgress /> : undefined}
      label={previewValue}
      onClose={(e) => {
        if (e.target.value !== value) {
          setValue(e.target.value);
        }

        onClose(e);
      }}
      showEdit={!isMultiselect && canEditAllSelected}
    >
      {previewValue}
    </PreviewTitle>
  );
};
