import { z } from 'zod';

import { tableLayoutZod } from '@work4all/components';

import { DeepRequired } from '@work4all/utils/lib/deep-required/DeepRequired';

const tableLayoutBreakpointsZod = z.enum(['sm', 'md', 'lg', 'xl']);

export const tableLayoutDefaultValueZod = z.record(
  tableLayoutBreakpointsZod,
  tableLayoutZod
);
export type TableLayoutDefaultValue = DeepRequired<
  z.infer<typeof tableLayoutDefaultValueZod>
>;
