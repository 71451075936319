import { DateTime } from 'luxon';
import { useMemo } from 'react';
import sanitizeHtml from 'sanitize-html';

import { useUser } from '@work4all/data';

import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { useTimeTracker } from '../../../../../../time-tracker/use-time-tracker';
import { useSelectedDay } from '../../../../../../time-tracker/useTimeTrackingOverview';

import { MaskOverlayTimeTrack } from './MaskOverlayTimeTrack';

export interface BeforeMaskOpen {
  isValid: boolean;
  ticketData: Ticket;
}

const roundBottom = (date: DateTime, step: number) => {
  const minute = Math.floor(date.get('minute') / step) * step;
  return date.set({ minute });
};

export function MaskOverlayTicketTimeTrack({
  ticket,
  menuItemMode = false,
  beforeMaskOpen,
}: {
  ticket?: Ticket;
  menuItemMode?: boolean;
  beforeMaskOpen?: () => Promise<BeforeMaskOpen>;
}) {
  const user = useUser();
  const options = useMemo(() => {
    const today = DateTime.now().startOf('day');

    return {
      from: today.startOf('day').toJSDate(),
      to: today.endOf('days').toJSDate(),
      userId: user.benutzerCode,
    };
  }, []);
  const { selectedDay } = useSelectedDay(options);

  const { result } = useTimeTracker({ amplitudeEntryPoint: 'TicketMask' });
  const { state, presentSince } = result || {
    state: 'initial',
    presentSince: undefined,
  };

  const { startDateTime, endDateTime } = useMemo(() => {
    let start: DateTime | undefined;
    let end: DateTime | undefined;
    if (selectedDay) {
      const previousEntry = selectedDay.timeTrackingItems
        .map((x) => x.endDateTime)
        .sort((a, b) => {
          return new Date(a) > new Date(b) ? -1 : 1;
        })?.[0];
      end = DateTime.now();

      if (previousEntry) {
        // case 3. next entry
        start = DateTime.fromISO(previousEntry);
      } else {
        const isDayStarted = state !== 'initial';
        if (isDayStarted) {
          // case 2. first entry - day started
          start = presentSince;
        } else {
          // case 1. first entry - no day started
          start = roundBottom(DateTime.now().minus({ hours: 1 }), 15);
        }
      }
    }

    if (start > end) {
      console.warn(
        'There were inconsitent data. Check time tracker and time tracking overview'
      );
      start = roundBottom(DateTime.now().minus({ hours: 1 }), 15);
    }

    return {
      startDateTime: start?.startOf('minutes')?.toJSDate(),
      endDateTime: end?.startOf('minutes')?.toJSDate(),
    };
    // we want to rerender if value change not refenrece to presentSince
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDay, state, presentSince?.toISO()]);

  let customer: Customer | undefined = undefined;
  if (ticket?.businessPartner?.businessPartnerType === SdObjType.KUNDE) {
    customer = ticket?.businessPartner.data as Customer;
  }
  let comment: string | undefined = undefined;
  if (ticket?.problemDescription) {
    comment = sanitizeHtml(ticket.problemDescription, { allowedTags: [] });
  }

  return (
    <MaskOverlayTimeTrack
      amplitudeEntryPoint="TicketMask"
      menuItemMode={menuItemMode}
      init={{
        project: ticket?.project,
        projectProcess: ticket?.projectProcess,
        customer,
        ticket,
        comment,
        startDateTime,
        endDateTime,
      }}
      beforeMaskOpen={beforeMaskOpen}
    />
  );
}
