import { Box, Stack } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';
import { CheckboxRadioItem } from '@work4all/components/lib/input/checkbox-radio-item';
import { LabeledInput } from '@work4all/components/lib/input/labeled-input';

import { BzObjType } from '@work4all/models/lib/Enums/BzObjType.enum';

import { settings, useSetting } from '../../../../../../../settings';
import { Collapse } from '../../../../components';

import { ColumnsEditor } from './components/columns-editor/ColumnsEditor';
import { CompanyLogoFileController } from './components/company-logo-file-controller/CompanyLogoFileController';

export const SimpleReportConf = (props: { bzObjType: BzObjType }) => {
  const simpleReportConf = useSetting(settings.simpleReportConf());
  const { t } = useTranslation();
  const { bzObjType } = props;

  const upsertChanges = useCallback(
    (field: string, value) => {
      simpleReportConf.set({
        ...simpleReportConf.value,
        [field]: value,
      });
    },
    [simpleReportConf]
  );

  return (
    <Box margin="0 -3rem 0 -2rem">
      <Collapse title={t('SIMPLE_REPORT.META_DATA')} defaultOpen={false}>
        <Stack gap="0">
          <CheckboxRadioItem
            label={t('SIMPLE_REPORT.CLIENT_NUMBER')}
            defaultChecked={simpleReportConf.value.metaShowClientNumber}
            onChange={(e) =>
              upsertChanges('metaShowClientNumber', e.target.checked)
            }
          />
          <CheckboxRadioItem
            label={t('SIMPLE_REPORT.CONTACT')}
            defaultChecked={simpleReportConf.value.metaShowContact}
            onChange={(e) => upsertChanges('metaShowContact', e.target.checked)}
          />
          <CheckboxRadioItem
            label={t('SIMPLE_REPORT.PHONE_NUMBER')}
            defaultChecked={simpleReportConf.value.metaShowPhoneNumber}
            onChange={(e) =>
              upsertChanges('metaShowPhoneNumber', e.target.checked)
            }
          />
          <CheckboxRadioItem
            label={t('SIMPLE_REPORT.E_MAIL')}
            defaultChecked={simpleReportConf.value.metaShowEMail}
            onChange={(e) => upsertChanges('metaShowEMail', e.target.checked)}
          />
          <CheckboxRadioItem
            label={t('SIMPLE_REPORT.DATE')}
            defaultChecked={simpleReportConf.value.metaShowDate}
            onChange={(e) => upsertChanges('metaShowDate', e.target.checked)}
          />
          <CheckboxRadioItem
            label={t('SIMPLE_REPORT.HEADER_TEXT')}
            defaultChecked={simpleReportConf.value.metaShowHeaderText}
            onChange={(e) =>
              upsertChanges('metaShowHeaderText', e.target.checked)
            }
          />
          <CheckboxRadioItem
            label={t('SIMPLE_REPORT.FOOTER_TEXT')}
            defaultChecked={simpleReportConf.value.metaShowFooterText}
            onChange={(e) =>
              upsertChanges('metaShowFooterText', e.target.checked)
            }
          />
        </Stack>
      </Collapse>
      <Collapse title={t('SIMPLE_REPORT.POSITIONS')} defaultOpen={false}>
        <Stack gap="0">
          <CheckboxRadioItem
            label={t('SIMPLE_REPORT.SHOW_PRICES')}
            defaultChecked={
              bzObjType !== BzObjType.LIEFERSCHEIN &&
              simpleReportConf.value.positionShowPrices
            }
            disabled={bzObjType === BzObjType.LIEFERSCHEIN}
            onChange={(e) =>
              upsertChanges('positionShowPrices', e.target.checked)
            }
          />
          <CheckboxRadioItem
            label={t('SIMPLE_REPORT.SHOW_ADDITIONAL_TEXTS')}
            defaultChecked={simpleReportConf.value.positionShowAdditionalText}
            onChange={(e) =>
              upsertChanges('positionShowAdditionalText', e.target.checked)
            }
          />
        </Stack>
      </Collapse>
      <Collapse title={t('SIMPLE_REPORT.PAGE')} defaultOpen={false}>
        <Stack gap="1rem">
          <Divider title={t('SIMPLE_REPORT.MARGINS')} />

          <Stack direction="row" gap="1rem">
            <div style={{ width: '100%' }}></div>
            <LabeledInput
              style={{ width: '100%' }}
              label={t('SIMPLE_REPORT.TOP')}
              defaultValue={simpleReportConf.value.pageMarginTop || 100}
              type="number"
              onChange={(e) =>
                upsertChanges('pageMarginTop', parseFloat(e.target.value))
              }
            />
            <div style={{ width: '100%' }}></div>
          </Stack>

          <Stack direction="row" gap="1rem">
            <LabeledInput
              style={{ width: '100%' }}
              label={t('SIMPLE_REPORT.LEFT')}
              defaultValue={simpleReportConf.value.pageMarginLeft || 50}
              type="number"
              onChange={(e) =>
                upsertChanges('pageMarginLeft', parseFloat(e.target.value))
              }
            />
            <div style={{ width: '100%' }}></div>
            <LabeledInput
              style={{ width: '100%' }}
              label={t('SIMPLE_REPORT.RIGHT')}
              defaultValue={simpleReportConf.value.pageMarginRight || 50}
              type="number"
              onChange={(e) =>
                upsertChanges('pageMarginRight', parseFloat(e.target.value))
              }
            />
          </Stack>

          <Stack direction="row" gap="1rem">
            <div style={{ width: '100%' }}></div>
            <LabeledInput
              style={{ width: '100%' }}
              label={t('SIMPLE_REPORT.BOTTOM')}
              defaultValue={simpleReportConf.value.pageMarginBottom || 75}
              type="number"
              onChange={(e) =>
                upsertChanges('pageMarginBottom', parseFloat(e.target.value))
              }
            />
            <div style={{ width: '100%' }}></div>
          </Stack>
        </Stack>
      </Collapse>
      <Collapse title={t('SIMPLE_REPORT.PAGE_HEADER')} defaultOpen={false}>
        <Stack gap="1rem">
          <Divider title={t('SIMPLE_REPORT.COMPANY_LOGO')} />
          <CompanyLogoFileController />
          <Stack direction="row" gap="1rem">
            <LabeledInput
              label={t('SIMPLE_REPORT.LOGO_WIDTH')}
              defaultValue={simpleReportConf.value.logoWidth || 150}
              type="number"
              onChange={(e) =>
                upsertChanges('logoWidth', parseFloat(e.target.value))
              }
            />
          </Stack>
          <Divider title={t('SIMPLE_REPORT.MARGINS')} />

          <Stack direction="row" gap="1rem">
            <div style={{ width: '100%' }}></div>
            <LabeledInput
              style={{ width: '100%' }}
              label={t('SIMPLE_REPORT.TOP')}
              defaultValue={simpleReportConf.value.headerMarginTop || 30}
              type="number"
              onChange={(e) =>
                upsertChanges('headerMarginTop', parseFloat(e.target.value))
              }
            />
            <div style={{ width: '100%' }}></div>
          </Stack>

          <Stack direction="row" gap="1rem">
            <LabeledInput
              style={{ width: '100%' }}
              label={t('SIMPLE_REPORT.LEFT')}
              defaultValue={simpleReportConf.value.headerMarginLeft || 30}
              type="number"
              onChange={(e) =>
                upsertChanges('headerMarginLeft', parseFloat(e.target.value))
              }
            />
            <div style={{ width: '100%' }}></div>
            <LabeledInput
              style={{ width: '100%' }}
              label={t('SIMPLE_REPORT.RIGHT')}
              defaultValue={simpleReportConf.value.headerMarginRight || 30}
              type="number"
              onChange={(e) =>
                upsertChanges('headerMarginRight', parseFloat(e.target.value))
              }
            />
          </Stack>

          <Stack direction="row" gap="1rem">
            <div style={{ width: '100%' }}></div>
            <LabeledInput
              style={{ width: '100%' }}
              label={t('SIMPLE_REPORT.BOTTOM')}
              defaultValue={simpleReportConf.value.headerMarginBottom || 30}
              type="number"
              onChange={(e) =>
                upsertChanges('headerMarginBottom', parseFloat(e.target.value))
              }
            />
            <div style={{ width: '100%' }}></div>
          </Stack>
        </Stack>
      </Collapse>
      <Collapse title={t('SIMPLE_REPORT.PAGE_FOOTER')} defaultOpen={false}>
        <Stack gap="1rem">
          <Divider title={t('SIMPLE_REPORT.MARGINS')} />

          <Stack direction="row" gap="1rem">
            <div style={{ width: '100%' }}></div>
            <LabeledInput
              style={{ width: '100%' }}
              label={t('SIMPLE_REPORT.TOP')}
              defaultValue={simpleReportConf.value.footerMarginTop || 30}
              type="number"
              onChange={(e) =>
                upsertChanges('footerMarginTop', parseFloat(e.target.value))
              }
            />
            <div style={{ width: '100%' }}></div>
          </Stack>

          <Stack direction="row" gap="1rem">
            <LabeledInput
              style={{ width: '100%' }}
              label={t('SIMPLE_REPORT.LEFT')}
              defaultValue={simpleReportConf.value.footerMarginLeft || 30}
              type="number"
              onChange={(e) =>
                upsertChanges('footerMarginLeft', parseFloat(e.target.value))
              }
            />
            <div style={{ width: '100%' }}></div>
            <LabeledInput
              style={{ width: '100%' }}
              label={t('SIMPLE_REPORT.RIGHT')}
              defaultValue={simpleReportConf.value.footerMarginRight || 30}
              type="number"
              onChange={(e) =>
                upsertChanges('footerMarginRight', parseFloat(e.target.value))
              }
            />
          </Stack>

          <Stack direction="row" gap="1rem">
            <div style={{ width: '100%' }}></div>
            <LabeledInput
              style={{ width: '100%' }}
              label={t('SIMPLE_REPORT.BOTTOM')}
              defaultValue={simpleReportConf.value.footerMarginBottom || 30}
              type="number"
              onChange={(e) =>
                upsertChanges('footerMarginBottom', parseFloat(e.target.value))
              }
            />
            <div style={{ width: '100%' }}></div>
          </Stack>
          <ColumnsEditor
            columns={simpleReportConf.value.footerColumns}
            onChange={(e) => upsertChanges('footerColumns', e)}
          />
        </Stack>
      </Collapse>
    </Box>
  );
};
