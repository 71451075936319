import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { ProjectProcessKind } from '../Enums/ProjectProcessKind.enum';

import { Appointment } from './Appointment.entity';
import { LookUp } from './LookUp.entity';
import { ProjectPlanningFeedback } from './ProjectPlanningFeedback.entity';
import { TimeTracking } from './TimeTracking.entity';
import { User } from './User.entity';

export class ProjectProcess<T extends EMode = EMode.entity> {
  /** Alias: abgeschlossen */
  isClosed?: number;
  /** Alias: art */
  kindId?: ProjectProcessKind;
  /** Alias: artikelCode */
  articleId?: number;
  /** Alias: bCode */
  creatorUserId?: number;
  /** Alias: bemerkung */
  comment?: string;
  /** Alias: benutzer */
  user?: User<T>;
  /** Alias: bewertung */
  valuation?: number;
  /** Alias: code */
  id?: number;
  /** Alias: creationDate */
  creationDate?: string;
  /** Alias: dauer */
  duration?: number;
  /** Alias: endeDatum */
  endDateInner?: string;
  /** Alias: gespraechspunkteCode */
  talkingPointsId?: number;
  /** Alias: index */
  index?: number;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: jahr */
  jahr?: number;
  /** Alias: kalkulationPositionCode */
  calculationPositionId?: number;
  /** Alias: kostenst */
  costCenterId?: number;
  /** Alias: monat */
  month?: number;
  /** Alias: nummer */
  number?: number;
  /** Alias: parentCode */
  parentId?: number;
  /** Alias: planKosten */
  planningCosts?: number;
  /** Alias: planungsAnzahl */
  planningAmount?: number;
  /** Alias: positionenCode */
  positionId?: number;
  /** Alias: projektCode */
  projectId?: number;
  /** Alias: ressource */
  ressource?: string;
  /** Alias: ressourcenKlasse */
  ressourceClass?: LookUp<T>;
  /** Alias: ressourcenKlasseCode */
  ressourceClassId?: number;
  /** Alias: rueckmeldungen */
  projectPlanningFeedbackList?: ProjectPlanningFeedback<T>[];
  /** Alias: startDatum */
  startDatum?: string;
  /** Alias: summeErloese */
  sumRevenues?: number;
  /** Alias: ueberbuchenMoeglich */
  overBookingPossible?: number;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: vorgang */
  process?: string;
  /** Alias: timeTrackings */
  timeTrackings?: TimeTracking<T>[];
  /** Alias: projectStepAppointments */
  projectProcessAppointmentList?: Appointment<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<ProjectProcess> = {
  isClosed: {
    alias: 'abgeschlossen',
  },
  kindId: {
    alias: 'art',
  },
  articleId: {
    alias: 'artikelCode',
  },
  creatorUserId: {
    alias: 'bCode',
  },
  comment: {
    alias: 'bemerkung',
  },
  user: {
    alias: 'benutzer',
    entity: Entities.user,
  },
  valuation: {
    alias: 'bewertung',
  },
  id: {
    alias: 'code',
  },
  creationDate: {
    alias: 'creationDate',
  },
  duration: {
    alias: 'dauer',
  },
  endDateInner: {
    alias: 'endeDatum',
  },
  talkingPointsId: {
    alias: 'gespraechspunkteCode',
  },
  index: {
    alias: 'index',
  },
  insertTime: {
    alias: 'insertTime',
  },
  jahr: {
    alias: 'jahr',
  },
  calculationPositionId: {
    alias: 'kalkulationPositionCode',
  },
  costCenterId: {
    alias: 'kostenst',
  },
  month: {
    alias: 'monat',
  },
  number: {
    alias: 'nummer',
  },
  parentId: {
    alias: 'parentCode',
  },
  planningCosts: {
    alias: 'planKosten',
  },
  planningAmount: {
    alias: 'planungsAnzahl',
  },
  positionId: {
    alias: 'positionenCode',
  },
  projectId: {
    alias: 'projektCode',
  },
  ressource: {
    alias: 'ressource',
  },
  ressourceClass: {
    alias: 'ressourcenKlasse',
    entity: Entities.lookUp,
  },
  ressourceClassId: {
    alias: 'ressourcenKlasseCode',
  },
  projectPlanningFeedbackList: {
    alias: 'rueckmeldungen',
    entity: Entities.projectPlanningFeedback,
  },
  startDatum: {
    alias: 'startDatum',
  },
  sumRevenues: {
    alias: 'summeErloese',
  },
  overBookingPossible: {
    alias: 'ueberbuchenMoeglich',
  },
  updateTime: {
    alias: 'updateTime',
  },
  process: {
    alias: 'vorgang',
  },
  timeTrackings: {
    alias: 'timeTrackings',
    entity: Entities.timeTracking,
  },
  projectProcessAppointmentList: {
    alias: 'projectStepAppointments',
    entity: Entities.appointment,
  },
  __typename: {
    alias: '__typename',
  },
};

export const projectProcessEntityDefinition: EntitiyDefinition<ProjectProcess> =
  {
    local: {},
    remote: {
      queryName: 'getProjektVorgaenge',
      fragmentName: 'ProjektVorgang',
      withPaginationWrapper: true,
      params: [
        { name: 'querySize', type: 'Int' },
        { name: 'queryPage', type: 'Int' },
        { name: 'querySortOrder', type: 'SortOrderStyle' },
        { name: 'querySortBy', type: 'String' },
        { name: 'filter', type: 'String' },
      ],
    },
    fieldDefinitions: fields,
  };
