import styles from './PickerTargetButton.module.scss';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import clsx from 'clsx';
import { forwardRef } from 'react';

export const PickerTargetButton = forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement> & { hover?: boolean }
>((props, ref) => {
  return (
    <button
      ref={ref}
      {...props}
      type="button"
      className={clsx(props.className, styles.pickerButton, {
        [styles.hover]: props.hover,
      })}
    >
      <span className={styles.pickerButtonContent}>{props.children}</span>
      {!props.disabled && (
        <ArrowDropDownIcon className={styles.pickerButtonDropdownIcon} />
      )}
    </button>
  );
});
