import styles from './Security.module.scss';

import { gql, useMutation } from '@apollo/client';
import { Email, GppGood, PhoneAndroid } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';
import { BaseActionButton } from '@work4all/components/lib/input/base-action-button/BaseActionButton';

import { useDataProvider, useUser } from '@work4all/data';
import { logoutUser } from '@work4all/data/lib/actions/user-actions';

import { InputSetMfaMode } from '@work4all/models/lib/Classes/InputSetMfaMode.entity';
import { SetMfaModeResponse } from '@work4all/models/lib/Classes/SetMfaModeResponse.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { MfaMode } from '@work4all/models/lib/Enums/MfaMode.enum';

import { DisableConfirmDialog } from './DisableConfirmDialog';
import { MfaConfirmDialog } from './MfaConfirmDialog';

const SET_TOKEN_MFA_AUTH = gql`
  mutation setTokenMfaAuth($input: InputSetMfaMode!) {
    updateUserMfaMode(input: $input) {
      ok
      errorMessage
      qrCodeBase64
      secret
    }
  }
`;

export function Security() {
  const { t } = useTranslation();
  const [mfaConfirmDialogOpen, setMfaConfirmDialogOpen] = useState(false);
  const [mfaMode, setMfaMode] = useState<MfaMode>(null);
  const [disableConfirmDialogOpen, setDisableConfirmDialogOpen] =
    useState(false);

  const user = useUser();
  const { enqueueSnackbar } = useSnackbar();

  const [inProgress, setInProgress] = useState<MfaMode>(null);

  const [mfaUpdateResult, setMfaUpdateResult] =
    useState<SetMfaModeResponse>(null);

  const userReq = useMemo<DataRequest>(() => {
    return {
      entity: Entities.user,
      filter: [
        {
          id: {
            $eq: user.benutzerCode,
          },
        },
      ],
      data: {
        id: null,
        mfaMode: null,
      } as User,
    };
  }, [user.benutzerCode]);

  const { data: userData } = useDataProvider<User>(userReq);

  const currentMfaMode = userData?.[0]?.mfaMode;

  const dispatch = useDispatch();

  const [mutate] = useMutation<
    { updateUserMfaMode: SetMfaModeResponse },
    { input: InputSetMfaMode }
  >(SET_TOKEN_MFA_AUTH);

  const activateMfa = useCallback(
    async (mfaMode: MfaMode) => {
      setInProgress(mfaMode);
      const res = await mutate({
        variables: {
          input: {
            mfaMode,
            userCode: user.benutzerCode,
          } as InputSetMfaMode,
        },
      });
      setInProgress(null);

      setMfaUpdateResult(res.data.updateUserMfaMode);

      if (res?.data?.updateUserMfaMode?.ok) {
        setMfaMode(mfaMode);
        if (mfaMode !== MfaMode.NORMAL) {
          setMfaConfirmDialogOpen(true);
        }
      }
    },
    [mutate, user.benutzerCode]
  );

  const handleAuthValidation = useCallback(
    (success: boolean) => {
      setMfaConfirmDialogOpen(false);
      setDisableConfirmDialogOpen(false);

      if (success === false) {
        enqueueSnackbar(t('MFA.VALIDATION_FAILED'), {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
      if (success) {
        /**
         * User is logged out due to token invalidation
         */
        dispatch(logoutUser());
      }
    },
    [dispatch, enqueueSnackbar, t]
  );

  const handleDisableAuth = useCallback(
    async (success: boolean) => {
      setDisableConfirmDialogOpen(false);
      if (success) {
        await activateMfa(MfaMode.NORMAL);
        /**
         * User is logged out due to token invalidation
         */
        dispatch(logoutUser());
      }
    },
    [activateMfa, dispatch]
  );

  return (
    <>
      <DisableConfirmDialog
        open={disableConfirmDialogOpen}
        onClose={handleDisableAuth}
      />

      <MfaConfirmDialog
        open={mfaConfirmDialogOpen}
        onClose={handleAuthValidation}
        qrCode={mfaUpdateResult?.qrCodeBase64}
        secret={mfaUpdateResult?.secret}
        mfaMode={mfaMode}
      />
      <Box sx={{ padding: '1rem' }}>
        <Stack alignItems="baseline" gap="1rem" width="100%">
          <Divider title={t('MFA.TITLE')} style={{ width: '100%' }} />
          <Stack
            direction="row"
            gap="0.25rem"
            className={clsx({
              [styles.success]:
                currentMfaMode && currentMfaMode !== MfaMode.NORMAL,
            })}
          >
            {currentMfaMode && currentMfaMode !== MfaMode.NORMAL ? (
              <Box paddingLeft="0.5rem">
                <GppGood />
              </Box>
            ) : null}
            <Typography
              className={clsx({
                [styles.success]:
                  currentMfaMode && currentMfaMode !== MfaMode.NORMAL,
              })}
            >
              {t(
                !currentMfaMode || currentMfaMode === MfaMode.NORMAL
                  ? 'MFA.DESC'
                  : 'MFA.DESC_SUCCESS'
              )}
              {currentMfaMode !== MfaMode.NORMAL &&
                ': ' +
                  t(
                    currentMfaMode === MfaMode.MAIL
                      ? 'MFA.ACTIVATE_EMAIL'
                      : 'MFA.ACTIVATE_AUTH_APP'
                  )}
            </Typography>
          </Stack>
          {currentMfaMode === MfaMode.NORMAL ? (
            <ToggleButtonGroup
              value={currentMfaMode}
              exclusive
              sx={{ flex: 'none', width: '100%' }}
            >
              <ToggleButton
                value={MfaMode.TOKEN}
                onClick={() => activateMfa(MfaMode.TOKEN)}
                sx={{ width: '100%', display: 'flex', gap: '0.25rem' }}
                disabled={inProgress !== null}
              >
                {inProgress === MfaMode.TOKEN ? (
                  <CircularProgress size="1.25rem" />
                ) : (
                  <PhoneAndroid />
                )}
                {t('MFA.ACTIVATE_AUTH_APP')}
              </ToggleButton>
              <ToggleButton
                value={MfaMode.MAIL}
                onClick={() => activateMfa(MfaMode.MAIL)}
                sx={{ width: '100%', display: 'flex', gap: '0.25rem' }}
                disabled={inProgress !== null}
              >
                {inProgress === MfaMode.MAIL ? (
                  <CircularProgress size="1.25rem" />
                ) : (
                  <Email />
                )}
                {t('MFA.ACTIVATE_EMAIL')}
              </ToggleButton>
            </ToggleButtonGroup>
          ) : null}
          {currentMfaMode !== MfaMode.NORMAL && (
            <Stack direction="row" alignItems="center" gap="2rem">
              <BaseActionButton
                onClick={() =>
                  activateMfa(
                    currentMfaMode === MfaMode.MAIL
                      ? MfaMode.TOKEN
                      : MfaMode.MAIL
                  )
                }
                icon={
                  currentMfaMode === MfaMode.MAIL ? <PhoneAndroid /> : <Email />
                }
              >
                {t('MFA.SWITCH_TO')}:&nbsp;
                {t(
                  currentMfaMode === MfaMode.MAIL
                    ? 'MFA.ACTIVATE_AUTH_APP'
                    : 'MFA.ACTIVATE_EMAIL'
                )}
              </BaseActionButton>
              <BaseActionButton
                onClick={() => setDisableConfirmDialogOpen(true)}
              >
                {t('MFA.DEACTIVATE')}
              </BaseActionButton>
            </Stack>
          )}
        </Stack>
      </Box>
    </>
  );
}
