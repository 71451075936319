import styles from './EditWrap.module.scss';

import { Edit, KeyboardTab } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import clsx from 'clsx';

interface Props {
  onEditModeToggle: (value: boolean) => void;
  children: React.ReactNode;
  editMode: boolean;
  disabled?: boolean;
}

export const EditWrap = (props: Props) => {
  const { children, onEditModeToggle, disabled = false, editMode } = props;

  return (
    <Box className={clsx(styles.editWrap, { [styles.disabled]: disabled })}>
      <Box
        onClick={() => {
          onEditModeToggle(!editMode);
        }}
        className={clsx(styles.iconContainer, { [styles.active]: editMode })}
      >
        {editMode ? (
          <IconButton>
            <KeyboardTab color="primary" className={styles.rotate} />
          </IconButton>
        ) : (
          <IconButton>
            <Edit color="primary" />
          </IconButton>
        )}
      </Box>

      {children}
    </Box>
  );
};
