import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDeleteEntity, useUser } from '@work4all/data';

import { CommentEvent } from '@work4all/models/lib/Classes/CommentEvent.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useDialogs } from '../../../../../../dialog-manager/use-dialogs';
import { EventLayout } from '../../event-layout';
import { useEventsViewStateContext } from '../../events-view-state-context';

import { ViewContent } from './ViewContent';

export interface CommentEventViewProps {
  event: CommentEvent;
  canAddOrInsertImage: boolean;
  onAfterDelete: () => void;
}

export const CommentEventView = memo(function CommentEventView(
  props: CommentEventViewProps
) {
  const { event, canAddOrInsertImage, onAfterDelete } = props;

  const user = useUser();
  const dialogs = useDialogs();
  const { t } = useTranslation();
  const [deleteEntity] = useDeleteEntity();

  const {
    state: { isUserEditingAComment, openedCommentId },
    startEditingComment,
    stopEditingComment,
  } = useEventsViewStateContext();

  const options = useMemo(() => {
    if (isUserEditingAComment || event.user?.id !== user.benutzerCode)
      return undefined;

    return [
      {
        icon: <EditIcon />,
        name: t('COMMON.EDIT'),
        onClick: () => {
          startEditingComment(event.id);
        },
      },
      {
        icon: <DeleteIcon />,
        name: t('ALERTS.BTN_DELETE'),
        onClick: async () => {
          const confirmed = await dialogs.confirm({
            title: t('ALERTS.DELETE_COMMENT.TITLE'),
            description: t('ALERTS.DELETE_COMMENT.DESCRIPTION'),
            confirmLabel: t('ALERTS.BTN_DELETE'),
            cancelLabel: t('ALERTS.BTN_ABORT'),
          });

          if (confirmed) {
            await deleteEntity({
              ids: [event.id],
              type: Entities.event,
            });

            onAfterDelete();
          }
        },
      },
    ];
  }, [
    isUserEditingAComment,
    event.user?.id,
    event.id,
    user.benutzerCode,
    t,
    startEditingComment,
    dialogs,
    deleteEntity,
    onAfterDelete,
  ]);

  return (
    <EventLayout event={event} options={options}>
      <ViewContent
        event={event}
        isEditMode={openedCommentId === event.id}
        onEditFinished={stopEditingComment}
        canAddOrInsertImage={canAddOrInsertImage}
      />
    </EventLayout>
  );
});
