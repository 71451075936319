import styles from './DragHandlerIconButton.module.scss';

import {
  IconButton,
  ListItem,
  ListItemButton,
  Popover,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as DragIndicatorIcon } from '@work4all/assets/icons/drag-vertical.svg';
import { ReactComponent as PlusDownIcon } from '@work4all/assets/icons/plus_down.svg';
import { ReactComponent as PlusUpIcon } from '@work4all/assets/icons/plus_up.svg';
import { ReactComponent as RemoveIcon } from '@work4all/assets/icons/remove.svg';

interface DragHandlerIconButtonProps {
  onAddAfter?: () => void;
  onAddBefore?: () => void;
  onRemove: () => void;
}

export const DragHandlerIconButton = ({
  onAddAfter,
  onAddBefore,
  onRemove,
}: DragHandlerIconButtonProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const ref = useRef();

  const removeItem = () => {
    setOpen(false);
    onRemove();
  };

  return (
    <React.Fragment>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={ref.current}
        classes={{ paper: styles.root }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <ListItem
          disablePadding
          sx={{
            minWidth: 100,
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          {onAddBefore && (
            <ListItemButton
              className={clsx(styles.color, styles.flex)}
              role={undefined}
              onClick={() => onAddBefore()}
            >
              <PlusUpIcon />
              <Typography>{t('ERP.ADD_POSITION_BEFORE')}</Typography>
            </ListItemButton>
          )}

          {onAddAfter && (
            <ListItemButton
              className={clsx(styles.color, styles.flex)}
              role={undefined}
              onClick={() => onAddAfter()}
            >
              <PlusDownIcon />
              <Typography>{t('ERP.ADD_POSITION_AFTER')}</Typography>
            </ListItemButton>
          )}

          <ListItemButton
            className={clsx(styles.color, styles.flex)}
            role={undefined}
            onClick={() => removeItem()}
          >
            <RemoveIcon />
            <Typography>{t('MASK.REMOVE')}</Typography>
          </ListItemButton>
        </ListItem>
      </Popover>

      <IconButton
        ref={ref}
        className={styles.color}
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
      >
        <DragIndicatorIcon />
      </IconButton>
    </React.Fragment>
  );
};
