import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { SplitButton } from '@work4all/components/lib/components/split-button/SplitButton';

import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

import {
  usePositionTableActions,
  UsePositionTableActionsOptions,
} from '../hooks/use-position-table-actions';

interface NewPostitionButtonProps
  extends Pick<UsePositionTableActionsOptions, 'onAddSpecial'> {
  disabled: boolean;
  tableInstanceRef: React.RefObject<TableInstance>;
}

export const NewPostitionButton = (props: NewPostitionButtonProps) => {
  const { onAddSpecial, disabled, tableInstanceRef } = props;
  const { t } = useTranslation();

  const actions = usePositionTableActions({
    onAddSpecial,
    disabled,
    tableInstanceRef,
  });
  const options = useMemo(() => {
    const first = actions[0];
    return [
      {
        id: first.actionKey,
        name: first.title,
        icon: <first.IconComponent />,
        onClick: first.onClick,
      },
      ...actions.map((action) => ({
        id: action.actionKey,
        name: action.title,
        icon: <action.IconComponent style={{ color: 'var(--text03)' }} />,
        onClick: action.onClick,
        group: [
          ErpPositionsKind.STANDARD,
          ErpPositionsKind.TEXTZEILE as string,
        ].includes(action.actionKey)
          ? ''
          : t('COMMON.FUNCTIONS'),
      })),
    ];
  }, [actions, t]);
  return (
    <SplitButton
      disabled={disabled}
      options={options}
      firstIsTitle
      onClick={(option, event) => {
        option.onClick(event);
      }}
      variant="outlined"
      renderItem={(option) => {
        return (
          <Box display="flex" gap="0.75rem" alignItems="center">
            {option.icon}
            {option.name}
          </Box>
        );
      }}
    />
  );
};
