import { ButtonBase } from '@mui/material';
import React, { useRef, useState } from 'react';

import { ReactComponent as ActiveFilterIcon } from '@work4all/assets/icons/filter-alt.svg';

import { NavigationPopover } from '@work4all/components/lib/navigation/navigation-popover';

interface Props {
  children?: React.ReactNode;
  disabled?: boolean;
  title: string;
  filterButtonClass?: string;
  customComponent?: React.ReactNode;
}

export const PopoverFilterWrap: React.FC<Props> = (props) => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  return (
    <div>
      <ButtonBase
        disabled={props.disabled}
        className={props.filterButtonClass}
        ref={anchorRef}
        onClick={() => setOpen(true)}
      >
        {props.customComponent || <ActiveFilterIcon />}
      </ButtonBase>
      <NavigationPopover
        open={open}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorRef.current}
        initialView={{
          title: props.title,
          view: props.children,
        }}
      />
    </div>
  );
};
