import styles from './YSplit.module.scss';

import clsx from 'clsx';
import React from 'react';

import { TestDataAttributes } from '@work4all/utils';

export const YSplit: React.FC<
  {
    children?: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
  } & TestDataAttributes
> = (props) => (
  <div
    className={clsx(styles['y-split'], props.className)}
    data-test-id={props['data-test-id']}
    style={props.style}
  >
    {props.children}
  </div>
);
