import { isArray } from 'lodash';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  SalesOpportunityRatingStatusPicker,
  SalesOpportunityRatingStatusPickerProps,
} from '@work4all/components/lib/components/entity-picker/sales-opportunities-rating-status-picker/SalesOpportunityRatingStatusPicker';

import { useUser } from '@work4all/data';

import { SalesOpportunityRating } from '@work4all/models/lib/Classes/SalesOpportunityRating.entity';
import { SalesOpportunityRatingStatus } from '@work4all/models/lib/Classes/SalesOpportunityRatingStatus.entity';

import { PickerTargetButton } from '../../../containers/mask-overlays/locked-inputs';

import { SettingsPickerFooter } from './components/SettingsPickerFooter';

interface SalesOpportunityRatingStatusPickerFieldProps
  extends Omit<
    SalesOpportunityRatingStatusPickerProps<false>,
    'multiple' | 'value'
  > {
  error?: string;
  name?: string;
  label?: string;
  value: number | SalesOpportunityRatingStatus | SalesOpportunityRating[];
}

export const SalesOpportunityRatingStatusPickerField = React.forwardRef<
  HTMLDivElement,
  SalesOpportunityRatingStatusPickerFieldProps
>(function SalesOpportunityRatingStatusPickerField(props, ref) {
  const { error, ...pickerProps } = props;
  const { value, onChange } = pickerProps;
  const { t } = useTranslation();

  const popoverRef = useRef<EntityPickerPopover>(null);

  const user = useUser();

  const valueTemplate = getRating(value);

  return (
    <EntityPickerPopover
      ref={popoverRef}
      footer={user.isMaster ? <SettingsPickerFooter /> : undefined}
      picker={
        <SalesOpportunityRatingStatusPicker
          multiple={false}
          {...pickerProps}
          value={valueTemplate}
        />
      }
    >
      <PickerTargetButton
        ref={ref}
        value={
          valueTemplate
            ? `${valueTemplate.ratingValue} %${
                valueTemplate.name ? ` - ${valueTemplate.name}` : ''
              }`
            : ''
        }
        name={props.name}
        label={t('COMMON.RATED_STATUS')}
        error={error}
        onClear={() => {
          onChange(null);
        }}
      />
    </EntityPickerPopover>
  );
});

const getRating = (value): SalesOpportunityRatingStatus => {
  if (typeof value === 'number')
    return {
      name: '',
      ratingValue: value,
      __typename: 'SalesOpportunityRatingStatus',
    };

  if (isRating(value)) {
    const first = value[0];
    return {
      name: '',
      ratingValue: first.ratingPercent,
      __typename: 'SalesOpportunityRatingStatus',
    };
  } else if (isRatingTemplate(value)) {
    return value;
  }
  return undefined;
};

const isRating = (
  input: SalesOpportunityRatingStatus | SalesOpportunityRating[]
): input is SalesOpportunityRating[] => {
  return (
    isArray(input) &&
    input.length &&
    input[0].__typename === 'SalesOpportunityRating'
  );
};

const isRatingTemplate = (
  input: SalesOpportunityRatingStatus | SalesOpportunityRating[]
): input is SalesOpportunityRatingStatus => {
  return (
    input &&
    '__typename' in input &&
    input.__typename === 'SalesOpportunityRatingStatus'
  );
};
