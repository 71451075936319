import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useTenant } from './routing/TenantProvider';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type OpenStatePerRoute = Record<string, { id: string; params: any }>;

interface IRoutesMetaBag {
  states: OpenStatePerRoute;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setRouteState: (route: string, id: string | null, routeParams?: any) => void;
}

const RoutesMetaContext = React.createContext<IRoutesMetaBag | null>(null);

export const useRoutesMetaBag = () => {
  const bag = useContext(RoutesMetaContext);
  if (!bag) {
    throw new Error(
      'Context is undefined! Make sure you wrapped your component with <RoutesMetaProvider />'
    );
  }

  return bag;
};

export const RoutesMetaProvider: React.FC<PropsWithChildren<unknown>> = (
  props
) => {
  const [states, setStates] = useState<IRoutesMetaBag['states']>({});
  const { activeTenant } = useTenant();

  useEffect(() => {
    setStates({});
  }, [activeTenant]);

  const setRouteState = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (route: string, id: string | null, routeParams?: any) => {
      setStates((old) => {
        if (id === null) {
          const n = { ...old };
          delete n[route];
          return n;
        }

        return { ...old, [route]: { id, params: routeParams } };
      });
    },
    []
  );

  return (
    <RoutesMetaContext.Provider value={{ states, setRouteState }}>
      {props.children}
    </RoutesMetaContext.Provider>
  );
};
