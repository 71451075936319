import styles from './styles.module.scss';

import CheckIcon from '@mui/icons-material/Check';
import { Button, Divider } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { LabeledInputWithUnit } from '../../../../../locked-inputs';
import { ControlWrapper } from '../../../../components';
import { useBookingAssistance } from '../../hooks';

interface ContentProps {
  id: number;
  discount: number;
}

export const BookingAssistance = () => {
  const { getValues } = useFormContext();
  const id = getValues('id');
  const discount = getValues('skontoPercent');

  if (id) {
    return <BookingAssistanceContent id={id} discount={discount} />;
  }

  return null;
};

export const BookingAssistanceContent = ({ id, discount }: ContentProps) => {
  const { t } = useTranslation();
  const { data, canCreate, create, edit } = useBookingAssistance({
    incomingInvoiceId: id,
    discount,
  });

  return (
    <div className={styles.bookingAssistance}>
      <Divider>{t('PAYMENT.BOOKING_ASSISTANCE_TITLE')}</Divider>

      <ControlWrapper columns={2} paddingTop>
        <LabeledInputWithUnit
          label={t('INPUTS.DISCOUNT')}
          unit={'%'}
          type="number"
          value={data.discount}
          onChange={(e) => {
            edit('discount', e?.target?.value);
          }}
        />
        <LabeledInputWithUnit
          label={t('COMMON.DISCOUNT_AMOUNT')}
          unit={'€'}
          type="number"
          value={data.discountAmount}
          onChange={(e) => {
            edit('discountAmount', e?.target?.value);
          }}
        />
      </ControlWrapper>

      <ControlWrapper columns={2}>
        <LabeledInputWithUnit
          label={t('INPUTS.PAYMENT')}
          unit={'€'}
          type="number"
          value={data.paymentAmount}
          onChange={(e) => {
            edit('paymentAmount', e?.target?.value);
          }}
        />
        <Button
          disabled={!canCreate}
          startIcon={<CheckIcon />}
          onClick={create}
        >
          {t('PAYMENT.BOOKING_ASSISTANCE_BUTTON')}
        </Button>
      </ControlWrapper>
    </div>
  );
};
