import { useTranslation } from 'react-i18next';

import { ReAccountingStatus } from '@work4all/models/lib/Enums/ReAccountingStatus.enum';

type Props = {
  value: ReAccountingStatus;
};

export const AccountingStatusCell = ({ value }: Props) => {
  const { t } = useTranslation();

  return t(`ACCOUNTING_STATUSES.${value}`);
};
