import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton } from '@mui/material';

import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { Option } from '../../types';

import { OptionsPopover } from './OptionsPopover';

type Props = {
  options: Option[] | undefined;
  className?: string;
};

export const OptionsPopoverButton = ({ options, className }: Props) => {
  const optionsPopoverState = usePopoverState();

  if (!options) return null;

  return (
    <>
      <OptionsPopover popoverState={optionsPopoverState} options={options} />

      <IconButton
        size="large"
        className={className}
        onClick={(e) => {
          optionsPopoverState.handleClick(e);
        }}
      >
        <MoreVertIcon />
      </IconButton>
    </>
  );
};
