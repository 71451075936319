import styles from './General.module.scss';

import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useGetTravelReceiptsStatus } from '@work4all/components/lib/components/entity-preview/travel-receipts-preview/use-get-travel-receipts-status';
import { Card } from '@work4all/components/lib/dataDisplay/card';

import { useUser } from '@work4all/data';

import { ReceiptKindInternal } from '@work4all/models/lib/Enums/ReceiptKindInternal.enum';

import { ReceiptKindTravelCostPickerField } from '../../../../../../components/entity-picker/ReceiptKindTravelCostPickerField';
import { TravelCostInvoiceKindPickerField } from '../../../../../../components/entity-picker/TravelCostInvoiceKindPickerField';
import { TravelExpenseReportPaymentMethodPickerField } from '../../../../../../components/entity-picker/TravelExpenseReportPaymentMethodPickerField';
import { ControllerPlus } from '../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../form-plus/use-form-context-plus';
import { LabeledInput } from '../../../../locked-inputs';
import { Collapse, ControlWrapper } from '../../../components';
import { TravelReceiptsFormValue } from '../type';

export const GeneralInfo = () => {
  const { t } = useTranslation();

  const form = useFormContextPlus<TravelReceiptsFormValue>();
  const { register, control } = form;
  const getTravelReceiptsStatus = useGetTravelReceiptsStatus();

  const user = useUser();

  return (
    <Collapse title={t('MASK.GENERAL')} className={styles.general} defaultOpen>
      <Card>
        <ControlWrapper>
          {!user.supplierCode && (
            <Typography color="error">{t('ERROR.NO_SUPPLIER')}</Typography>
          )}
        </ControlWrapper>

        <ControlWrapper>
          <LabeledInput
            label={t('INPUTS.DESCRIPTION')}
            {...register('description')}
            required
          />
        </ControlWrapper>
        <ControlWrapper columns={2}>
          <ControllerPlus
            name="travelExpenses"
            control={control}
            render={({ field }) => {
              return (
                <LabeledInput
                  label={t('COMMON.STATUS')}
                  disabled
                  value={getTravelReceiptsStatus(field.value).label}
                />
              );
            }}
          />
          <ControllerPlus
            name="paymentMethod"
            control={control}
            render={({ field }) => {
              return (
                <TravelExpenseReportPaymentMethodPickerField
                  {...field}
                  required
                />
              );
            }}
          />
        </ControlWrapper>
        <ControlWrapper columns={2}>
          <ControllerPlus
            name="receiptKind"
            control={control}
            render={({ field }) => {
              return (
                <React.Fragment>
                  <TravelCostInvoiceKindPickerField {...field} required />
                  <ControllerPlus
                    name="receiptKindTravelCost"
                    control={control}
                    render={({ field: travelField }) => {
                      return (
                        <ReceiptKindTravelCostPickerField
                          disabled={
                            field.value?.typeOfReceiptType !==
                            ReceiptKindInternal.FAHRTKOSTEN
                              ? true
                              : undefined
                          }
                          {...travelField}
                        />
                      );
                    }}
                  />
                </React.Fragment>
              );
            }}
          />
        </ControlWrapper>
      </Card>
    </Collapse>
  );
};
