import styles from './EntityPickerActions.module.scss';

import { Theme, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { WebsiteFavicon } from '@work4all/components';
import {
  BusinessPartnerPicker,
  IBusinessPartnerPickerProps,
} from '@work4all/components/lib/components/entity-picker/business-partner-picker/BusinessPartnerPicker';
import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';

import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

import { EntityPickerActions } from './EntityPickerActions';

interface BusinessPartnerPickerFieldProps
  extends Omit<
    IBusinessPartnerPickerProps<false>,
    'multiple' | 'onTabChange' | 'onChange'
  > {
  error?: string;
  label?: string;
  onChange: (value: { data: Supplier | Customer; entity: Entities }) => void;
  labeledInputClassName?: string;
  disabled?: boolean;
}

export const BusinessPartnerPickerField = React.forwardRef<
  HTMLDivElement,
  BusinessPartnerPickerFieldProps
>(function BusinessPartnerPickerField(props, ref) {
  const { error, ...pickerProps } = props;
  const { value, onChange, label, labeledInputClassName, disabled } =
    pickerProps;

  const { t } = useTranslation();

  const [entity, setEntity] = useState(() => {
    return value ? getBusinessPartnerType(value) : Entities.customer;
  });

  const popoverRef = useRef<EntityPickerPopover>(null);

  const inputLabel =
    label ||
    (value ? (
      <Box>
        {t(
          `COMMON.${
            getBusinessPartnerType(value) === Entities.customer
              ? 'CUSTOMER'
              : 'SUPPLIER'
          }`
        )}{' '}
        <span className={styles.secondaryInfo}>{value?.number || ''}</span>
      </Box>
    ) : (
      `${t('COMMON.CUSTOMER')}/${t('COMMON.SUPPLIER')}`
    ));

  const inputValue = value ? (
    <Box
      paddingTop="1rem"
      display="flex"
      gap="0.5rem"
      alignContent="center"
      alignItems="center"
      data-test-id="bp-picker-label-value"
    >
      {value && <WebsiteFavicon website={value?.website} />}
      <Typography noWrap>{value?.name ?? ''}</Typography>
    </Box>
  ) : (
    ''
  );

  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );

  return (
    <EntityPickerPopover
      ref={popoverRef}
      fullscreen={isMobile}
      picker={
        <BusinessPartnerPicker
          {...pickerProps}
          multiple={false}
          onTabChange={setEntity}
          onChange={(value) => {
            onChange({
              data: value,
              entity,
            });
          }}
          favorites={true}
        />
      }
      footer={
        <EntityPickerActions
          entity={entity}
          multiple={false}
          onChange={(value) => {
            onChange({
              data: value,
              entity,
            });
            popoverRef.current?.close();
          }}
        ></EntityPickerActions>
      }
    >
      <PickerTargetButton
        ref={ref}
        data-test-id="businesspartner-picker-field"
        value={inputValue}
        label={inputLabel}
        error={error}
        onClear={() => {
          onChange(null);
        }}
        className={labeledInputClassName}
        disabled={disabled}
      />
    </EntityPickerPopover>
  );
});

function getBusinessPartnerType(
  businessPartner: Customer | Supplier
): Entities {
  if (businessPartner) {
    const typename: string = businessPartner.__typename;

    if (typename === 'Kunde') {
      return Entities.customer;
    }
  }

  return Entities.supplier;
}
