import styles from '../participant/Participant.module.scss';

import AddIcon from '@mui/icons-material/Add';
import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import clsx from 'clsx';
import _ from 'lodash';
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import { Card } from '@work4all/components/lib/dataDisplay/card';
import { PickerTargetButton } from '@work4all/components/lib/input/picker-target-button';

import { AppointmentAttendee } from '@work4all/models/lib/Classes/AppointmentAttendee.entity';
import { ContactUnionWrapper } from '@work4all/models/lib/Classes/ContactUnionWrapper.entity';
import { ContactKind } from '@work4all/models/lib/Enums/ContactKind.enum';

import { useControllerPlus } from '../../../../../../../../form-plus/use-controller-plus';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import { Button, Chip } from '../../../../../../../../locked-inputs';
import { TabPanelSectionProps } from '../../../../../../..';
import { ControlWrapper } from '../../../../../../../components/control-wrapper';
import { sortAttendees } from '../../../../../AppointmentOverlayController';
import { AppointmentMaskFormValue } from '../../../../../types';
import {
  ContactRessourcePicker,
  renderChipContent,
} from '../contactpicker/ContactRessourcePicker';

import { AttendeesPicker } from './attendee-picker/AttendeePicker';
import {
  filterDuplicateContactAttendees,
  isAttendee,
  isResource,
  isTheSameAttendee,
  mapAttendee,
  mapAttendeeContact,
  mapResourceContact,
} from './utils';

const RESOURCE_CONTACT_TYPES = [ContactKind.RESSOURCE];

const collapseClasses = {
  root: styles.collapseContainer,
  wrapperInner: styles.collapseWrapperInner,
};

export const Participant: React.FC<TabPanelSectionProps> = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const upMd = useMediaQuery(theme.breakpoints.up('md'));

  const pickerAttendeeWrapperRef = useRef<HTMLDivElement>();
  const pickerAttendeePopoverRef = useRef<EntityPickerPopover>();
  const pickerResourceWrapperRef = useRef<HTMLDivElement>();
  const pickerResourcePopoverRef = useRef<EntityPickerPopover>();

  const { control, watch } = useFormContextPlus<AppointmentMaskFormValue>();

  function renderChipInput({
    label,
    contacts,
    onDelete,
    forceLineBreak = false,
    error,
  }: {
    label: string;
    contacts: ContactUnionWrapper[];
    onDelete: (contact: ContactUnionWrapper) => void;
    forceLineBreak: boolean;
    error: string;
  }) {
    const [userGroup, contactGroup] = _.partition(contacts, (ctc) =>
      [ContactKind.BENUTZER, ContactKind.MITARBEITER].includes(ctc.contactKind)
    );

    const filteredContactGroup = filterDuplicateContactAttendees(contactGroup);

    const userGroupEls =
      userGroup.length > 0
        ? userGroup.map((item) => {
            return (
              <Chip
                key={item.id}
                withSeperator={true}
                className={styles.chip}
                maxWidth={false}
                label={renderChipContent(item)}
                handleDelete={() => {
                  onDelete(item);
                }}
              />
            );
          })
        : undefined;
    const contactGroupEls =
      filteredContactGroup.length > 0
        ? filteredContactGroup.map((item) => {
            return (
              <Chip
                key={item.id}
                withSeperator={true}
                className={styles.chip}
                maxWidth={false}
                label={renderChipContent(item)}
                handleDelete={() => {
                  onDelete(item);
                }}
              />
            );
          })
        : undefined;

    return (
      <PickerTargetButton
        multiline={true}
        error={error}
        value={
          (userGroup.length || contactGroup.length) && forceLineBreak ? (
            <div>
              {userGroupEls?.length && (
                <div className={styles.chipsContainer}>{userGroupEls}</div>
              )}
              {contactGroupEls?.length && (
                <div className={styles.chipsContainer}>{contactGroupEls}</div>
              )}
            </div>
          ) : (
            <div className={styles.chipsContainer}>
              {userGroupEls}
              {contactGroupEls}
            </div>
          )
        }
        label={label}
      />
    );
  }

  const { field } = useControllerPlus({
    control,
    name: 'appointmentAttendeeList',
  });

  const { value, onChange } = field;

  const {
    attendees,
    resources,
    attendeeContacts,
    resourceContacts,
    handleChange,
    handleDelete,
  } = useMemo(() => {
    const all = Array.isArray(value) ? value : [];

    const attendees = all.filter(isAttendee);
    const resources = all.filter(isResource);

    if (attendees.length + resources.length !== all.length) {
      throw new Error(
        "Couldn't map attendee list to resource and attendee contacts."
      );
    }

    const attendeeContacts = attendees.map(mapAttendeeContact);
    const resourceContacts = resources.map(mapResourceContact);

    const handleChange = (
      originalAttendees: AppointmentAttendee[],
      fixedItems: AppointmentAttendee[]
    ) => {
      return (selected: ContactUnionWrapper[]) => {
        const mapped = selected.map((contact) => {
          const found = originalAttendees.find((resource) => {
            return isTheSameAttendee(resource, contact);
          });

          if (found) {
            return found;
          }

          return mapAttendee(contact);
        });
        onChange(sortAttendees([...fixedItems, ...mapped]));
      };
    };

    const handleDelete = (contact: ContactUnionWrapper) => {
      onChange(
        all.filter((original) => {
          return !isTheSameAttendee(original, contact);
        })
      );
    };

    return {
      attendees,
      resources,
      attendeeContacts,
      resourceContacts,
      handleDelete,
      handleChange,
    };
  }, [value, onChange]);

  const businessPartner = watch('businessPartner.data');

  return (
    <Collapse
      classes={collapseClasses}
      title={t('COMMON.PARTICIPANT_RESOURCES')}
      className={clsx(styles.collapse)}
      defaultOpen={true}
    >
      <Card>
        <ControlWrapper
          ref={pickerAttendeeWrapperRef}
          className={clsx(styles.container)}
          paddingBottom={attendeeContacts.length > 0}
        >
          <EntityPickerPopover
            ref={pickerAttendeePopoverRef}
            anchorEl={pickerAttendeeWrapperRef}
            autoclose={false}
            picker={
              <AttendeesPicker
                value={attendeeContacts}
                onChange={handleChange(attendees, resources)}
                businessPartner={businessPartner}
              />
            }
          >
            {attendeeContacts.length > 0 ? (
              renderChipInput({
                label: t('COMMON.PARTICIPANT'),
                contacts: attendeeContacts,
                onDelete: handleDelete,
                forceLineBreak: upMd,
                error: field.error,
              })
            ) : (
              <span></span>
            )}
          </EntityPickerPopover>
        </ControlWrapper>
        <ControlWrapper
          ref={pickerResourceWrapperRef}
          className={styles.container}
          paddingBottom={resourceContacts.length > 0}
        >
          <EntityPickerPopover
            ref={pickerResourcePopoverRef}
            anchorEl={pickerResourceWrapperRef}
            picker={
              <ContactRessourcePicker
                multiple={true}
                value={resourceContacts}
                contactKindList={RESOURCE_CONTACT_TYPES}
                onChange={handleChange(resources, attendees)}
                renderChipContent={renderChipContent}
              />
            }
          >
            {resourceContacts.length > 0 ? (
              renderChipInput({
                label: t('COMMON.RESOURCES'),
                contacts: resourceContacts,
                onDelete: handleDelete,
                forceLineBreak: upMd,
                error: field.error,
              })
            ) : (
              <span></span>
            )}
          </EntityPickerPopover>
        </ControlWrapper>
        <Grid container spacing={2}>
          <Grid item xs={'auto'}>
            {attendeeContacts.length === 0 && (
              <Button
                onClick={() => pickerAttendeePopoverRef.current?.open()}
                startIcon={<AddIcon />}
                size="large"
                sx={{ justifyContent: 'flex-start' }}
              >
                {t('COMMON.ADD_PARTICIPANT')}
              </Button>
            )}
          </Grid>
          <Grid item xs={'auto'}>
            {resourceContacts.length === 0 && (
              <Button
                onClick={() => pickerResourcePopoverRef.current?.open()}
                startIcon={<AddIcon />}
                size="large"
                sx={{ justifyContent: 'flex-start' }}
              >
                {t('COMMON.ADD_RESOURCE')}
              </Button>
            )}
          </Grid>
        </Grid>
      </Card>
    </Collapse>
  );
};
