import styles from './EmailTemplatePreview.module.scss';

import { useMemo } from 'react';

import { usePageZoom } from '@work4all/data';

import { EMailTemplate } from '@work4all/models/lib/Classes/EMailTemplate.entity';

import { PreviewTitle, PreviewWrapper } from '../components';
import { setDefaultHeadStyles } from '../utils';

export type IEmailTemplatePreviewProps = {
  emailTemplate: EMailTemplate;
  onCloseClicked?: React.MouseEventHandler<HTMLButtonElement>;
  onEditClicked?: React.MouseEventHandler<HTMLButtonElement>;
};

export function EmailTemplatePreview(props: IEmailTemplatePreviewProps) {
  const { emailTemplate, onCloseClicked, onEditClicked } = props;

  const body = useMemo(() => {
    let result = emailTemplate?.body;

    if (emailTemplate?.signature?.body) {
      result += emailTemplate?.signature?.body;
    }
    return result;
  }, [emailTemplate?.body, emailTemplate?.signature?.body]);

  const { zoomLevel } = usePageZoom();

  return (
    <PreviewWrapper>
      <div className={styles.wrap}>
        <PreviewTitle
          onCloseClicked={onCloseClicked}
          onEditClicked={onEditClicked}
        >
          {emailTemplate.name}
        </PreviewTitle>

        <iframe
          className={styles.content}
          title="Email template Preview"
          srcDoc={
            setDefaultHeadStyles({
              value: body || '',
              textmarkStyles: true,
              zoom: zoomLevel,
            }) || body
          }
        />
      </div>
    </PreviewWrapper>
  );
}
