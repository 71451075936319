import React from 'react';
import { TableInstance } from 'react-table';

import { Note } from '@work4all/models/lib/Classes/Note.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';

import { EntityTable, IEntityTable } from './entity-table/EntityTable';
import { useEntityDataTable } from './entity-table/use-entity-data-table';
import schema from './schemata/note-table-schema.json';

const defaultSort = [{ field: 'date', direction: SortDirection.DESCENDING }];

export const NotesTable = React.forwardRef<TableInstance, IEntityTable>(
  function NotesTable(_props, ref) {
    const dataTable = useEntityDataTable<Note, never>({
      schema: schema as never,
      defaultSort,
    });

    return <EntityTable ref={ref} {...dataTable} />;
  }
);
