import React from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  SalesOpportunitiesGroupPicker,
  SalesOpportunitiesGroupPickerProps,
} from '@work4all/components/lib/components/entity-picker/sales-opportunities-group-picker/SalesOpportunitiesGroupPicker';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

interface Props
  extends Omit<SalesOpportunitiesGroupPickerProps<false>, 'multiple'> {
  error?: string;
}

export const SalesOpportunitiesGroupPickerField = React.forwardRef<
  HTMLDivElement,
  Props
>((props, ref) => {
  const { error, ...pickerProps } = props;
  const { value, onChange } = pickerProps;

  const { t } = useTranslation();

  return (
    <EntityPickerPopover
      picker={
        <SalesOpportunitiesGroupPicker {...pickerProps} multiple={false} />
      }
    >
      <PickerTargetButton
        ref={ref}
        onClear={() => {
          onChange(null);
        }}
        label={t('COMMON.GROUP')}
        error={error}
        value={value?.name || ''}
      />
    </EntityPickerPopover>
  );
});
