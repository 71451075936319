import { createContext, useCallback, useEffect, useState } from 'react';
import type { TableInstance } from 'react-table';

import { useDeepMemo } from '@work4all/utils/lib/hooks/use-deep-memo';
import { useForceUpdate } from '@work4all/utils/lib/hooks/use-force-update';

export const ColumnVisibilityContext =
  createContext<IColumnVisibilityContext | null>(null);

export interface IColumnVisibilityContext {
  toggledColumnId: string | null;
  toggleColumnVisibility: (columnId: string) => void;
  disabledColumns: string[];
}

type Props = {
  tableInstanceRef: React.MutableRefObject<TableInstance<object>>;
  onVisibilityChange?: (columns: string[]) => void;
  orderColumns?: (columns: string[]) => string[];
  disabledColumns?: string[];
};

export function useColumnVisibilityManager({
  tableInstanceRef,
  onVisibilityChange,
  orderColumns,
  disabledColumns,
}: Props): IColumnVisibilityContext {
  const [toggledColumnId, setToggledColumnId] = useState<string | null>(null);

  const forceUpdate = useForceUpdate();
  const toggleColumnVisibility = useCallback(
    (columnId: string): void => {
      const tableInstance = tableInstanceRef.current;
      if (!tableInstance) return;

      const column = tableInstance.allColumns.find(
        (column) => column.id === columnId
      );

      if (!column) {
        return;
      }

      tableInstance.toggleHideColumn(columnId);

      const nextColumns = [...tableInstance.visibleColumns];

      // If column was visible simply hide it and return.
      if (column.isVisible) {
        forceUpdate();
        const ids = nextColumns.map((column) => column.id);
        onVisibilityChange?.(ids.filter((x) => x === columnId));
        return;
      }

      // Otherwise save its id to trigger the animation and append it after all
      // of the sticky columns.
      setToggledColumnId(columnId);

      nextColumns.push(column);

      let ids = nextColumns.map((column) => column.id);
      if (orderColumns) {
        ids = orderColumns(ids);
      }
      tableInstance?.setColumnOrder(ids);

      onVisibilityChange?.(ids);
    },
    [forceUpdate, onVisibilityChange, orderColumns, tableInstanceRef]
  );

  useEffect(() => {
    if (toggledColumnId === null) return;

    const timeout = window.setTimeout(() => {
      setToggledColumnId(null);
    }, 300);

    return () => {
      window.clearTimeout(timeout);
    };
  }, [toggledColumnId]);

  const _disabledColumns = useDeepMemo(() => {
    return disabledColumns ?? [];
  }, [disabledColumns]);

  return {
    toggledColumnId,
    toggleColumnVisibility,
    disabledColumns: _disabledColumns,
  };
}
