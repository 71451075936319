import styles from './GeneralTabPanel.module.scss';

import { Box } from '@mui/material';

import { MuiThemeProvider } from '@work4all/components';
import { Card } from '@work4all/components/lib/dataDisplay/card';

import { Panel } from '../../../../../components/panel/Panel';

import { Document } from './components/document/Document';

export const GeneralTabPanel = () => {
  return (
    <Panel className={styles.panel}>
      <Box className={styles.container}>
        <Card className={styles.card} noPadding data-theme="light">
          <MuiThemeProvider pageTheme={'light'} themeOnly>
            <Document />
          </MuiThemeProvider>
        </Card>
      </Box>
    </Panel>
  );
};
