import { useMemo } from 'react';

import {
  ALLOWED_CUSTOM_FIELD_TYPES,
  createCustomColumn,
  ParsedCustomFieldConfig,
} from '@work4all/data/lib/custom-fields';

import {
  ICustomCellConfigBase,
  IDefaultCellConfig,
  ITableColumnConfig,
} from '@work4all/models/lib/table-schema/table-schema';

export function useTableColumns<
  CustomColumns extends Record<string, ICustomCellConfigBase>
>(
  columns: (
    | ITableColumnConfig<IDefaultCellConfig>
    | ITableColumnConfig<CustomColumns[string]>
  )[],
  customFields: ParsedCustomFieldConfig[]
) {
  return useMemo(() => {
    if (!customFields || customFields.length === 0) return columns;

    return [
      ...columns,
      ...customFields
        .filter((field) => ALLOWED_CUSTOM_FIELD_TYPES.includes(field.fieldType))
        .map(createCustomColumn),
    ];
  }, [columns, customFields]);
}
