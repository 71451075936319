import { EventBus, PDFViewer } from 'pdfjs-dist/legacy/web/pdf_viewer';
import { useEffect, useMemo, useRef, useState } from 'react';

import { clamp } from '@work4all/utils/lib/math';

import { MAX_SCALE, MIN_SCALE, SCALE_MODIFIER } from './constants';

// TODO: Import TextLayerMode from pdfjs-dist
//
// There is an issue with pdfjs-dist exports.
// For some reason can't import TextLayerMode from pdfjs-dist/legacy/web/pdf_viewer
// event though it's supposed to be there.
// I was not able to find a solution.
// Just declare the const here, until this issue is resolved.

const TextLayerMode = {
  DISABLE: 0,
  ENABLE: 1,
  ENABLE_ENHANCE: 2,
};

export type UsePDFViewerOptions = {
  container: React.RefObject<HTMLDivElement>;
  viewer: React.RefObject<HTMLDivElement>;
  enableTextLayer: boolean;
};

export type PDFViewerApi = {
  viewer: InstanceType<typeof PDFViewer>;
  setScale: (scale: number) => number;
  zoomIn: () => number;
  zoomOut: () => number;
  autosize: () => number;
};

export function usePDFViewerApi(options: UsePDFViewerOptions): {
  api: PDFViewerApi;
  minScale: number;
} {
  const [initialOptions] = useState(options);

  const [api, setApi] = useState<PDFViewerApi | null>(null);
  const [minScale, setMinScale] = useState(MIN_SCALE);

  const minScaleRef = useRef(minScale);

  useEffect(() => {
    minScaleRef.current = minScale;
  }, [minScale]);

  useEffect(() => {
    const viewer = new PDFViewer({
      container: initialOptions.container.current,
      viewer: initialOptions.viewer.current,
      eventBus: new EventBus({}),
      linkService: null,
      l10n: null,
      renderer: 'canvas',
      textLayerMode: initialOptions.enableTextLayer
        ? TextLayerMode.ENABLE
        : TextLayerMode.DISABLE,
    });

    function setScale(scale: number) {
      const clamped = clamp(scale, minScaleRef.current, MAX_SCALE);
      viewer.currentScale = clamped;
      return clamped;
    }

    function zoomIn() {
      const scale = viewer.currentScale * SCALE_MODIFIER;
      setScale(scale);
      return scale;
    }

    function zoomOut() {
      const scale = viewer.currentScale / SCALE_MODIFIER;
      setScale(scale);
      return scale;
    }

    function autosize(): number {
      viewer.currentScaleValue = 'page-width';
      const currentScale = viewer.currentScale;
      setMinScale(currentScale);
      return currentScale;
    }

    setApi({
      viewer,
      setScale,
      zoomIn,
      zoomOut,
      autosize,
    });

    return () => {
      viewer.cleanup();
    };
  }, [initialOptions]);

  return useMemo(() => ({ api, minScale }), [api, minScale]);
}
