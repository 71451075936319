import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ArticleKind } from '@work4all/models/lib/Enums/ArticleKind.enum';

import { Tooltip } from '../../../../components/tooltip/Tooltip';

export type IArticleKindCellProps = {
  value: ArticleKind;
};

export const ArticleKindCell = (props: IArticleKindCellProps) => {
  const { value } = props;
  const { t } = useTranslation();
  return value ? (
    <Tooltip title={t('ARTICLE_KIND.LONG.' + value)} placement="left">
      <Typography>{t('ARTICLE_KIND.SHORT.' + value)}</Typography>
    </Tooltip>
  ) : null;
};
