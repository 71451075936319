import { isEqual } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';
import { TableInstance } from 'react-table';

import { EditModeTableInstance } from '@work4all/components/lib/dataDisplay/basic-table/plugins/useEditMode';
import { SELECTION_COLUMN_ID } from '@work4all/components/lib/dataDisplay/basic-table/utils/makeRowsSelectable';

import { UseSettingReturn } from '@work4all/data/lib/settings/use-setting';

import { useDebouncedCallback } from '@work4all/utils/lib/hooks/use-debounce-callback';

export interface UseEditableColumnsSettingsProps<T extends object> {
  tableInstance: TableInstance<T> & EditModeTableInstance;
  columnSettings: UseSettingReturn<{
    visibility: string[];
    order: string[];
    width: Record<string, number>;
  }>;
  allowedColumns?: string[];
}

export const useEditableColumnsSettings = <T extends object>(
  props: UseEditableColumnsSettingsProps<T>
) => {
  const { tableInstance, allowedColumns, columnSettings } = props;

  const setSettingsOnce = useDebouncedCallback(
    useCallback(
      (newSettings: typeof columnSettings.value) => {
        if (
          !newSettings.visibility.filter((x) => SELECTION_COLUMN_ID !== x)
            .length
        )
          return;
        columnSettings?.set(newSettings);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [columnSettings?.set]
    ),
    300
  );
  // width of columns
  const prevWidth = useRef<Record<string, number>>(
    tableInstance.state.columnResizing?.columnWidths
  );
  useEffect(() => {
    if (allowedColumns || !columnSettings) return;
    const width = tableInstance.state.columnResizing?.columnWidths;
    if (isEqual(prevWidth.current, width) || JSON.stringify(width) === '{}')
      return;
    prevWidth.current = width;
    setSettingsOnce({
      ...columnSettings.value,
      width: { ...columnSettings.value.width, ...width },
    });
  }, [
    columnSettings,
    setSettingsOnce,
    tableInstance.state.columnResizing?.columnWidths,
    allowedColumns,
  ]);

  // order of columns
  const prevOrder = useRef<string[]>([]);
  useEffect(() => {
    if (allowedColumns || !columnSettings) return;
    const order = tableInstance.state.columnOrder;

    if (isEqual(prevOrder.current, order)) return;

    const visible = tableInstance.visibleColumns;
    const visibleIds = visible.map((x) => x.id).sort();

    prevOrder.current = order;
    setSettingsOnce({
      ...columnSettings.value,
      visibility: visibleIds,
      order,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tableInstance.state.columnOrder,
    setSettingsOnce,
    columnSettings,
    allowedColumns,
  ]);

  // react on value change from settings
  const prevSavedOrder = useRef<string[]>([]);
  useEffect(() => {
    if (allowedColumns || !columnSettings) return;
    const order = columnSettings.value.order;
    if (isEqual(prevSavedOrder.current, order)) return;
    prevSavedOrder.current = order;
    tableInstance.setColumnOrder(order);
  }, [
    tableInstance.setColumnOrder,
    tableInstance,
    allowedColumns,
    columnSettings,
  ]);
};
