import React from 'react';
import { TableInstance } from 'react-table';

import { CheckList } from '@work4all/models/lib/Classes/CheckList.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';

import { EntityTable, IEntityTable } from './entity-table/EntityTable';
import { useEntityDataTable } from './entity-table/use-entity-data-table';
import schema from './schemata/checkList-table-schema.json';

const defaultSort = [{ field: 'date', direction: SortDirection.DESCENDING }];

export const ChecklistsTable = React.forwardRef<TableInstance, IEntityTable>(
  function ChecklistsTable(_props, ref) {
    const dataTable = useEntityDataTable<CheckList, never>({
      schema: schema as never,
      defaultSort,
    });

    return <EntityTable ref={ref} {...dataTable} />;
  }
);
