import styles from './TableHeading.module.scss';

import TableCell, { TableCellBaseProps } from '@mui/material/TableCell';
import clsx from 'clsx';
import React from 'react';

interface ITableHeadingProps {
  children?: React.ReactNode;
  component?: React.ElementType<TableCellBaseProps>;
  className?: string;
}

export const TableHeading: React.FC<ITableHeadingProps> = (props) => {
  return (
    <TableCell
      className={clsx(styles.heading, props.className)}
      component={props.component}
    >
      {props.children}
    </TableCell>
  );
};
