import { AppointmentState } from '@work4all/models/lib/Classes/AppointmentState.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { AppointmentStatePicker } from '../../../../../entity-picker/appointmentStatePicker/AppointmentStatePicker';
import { withExtras } from '../../utils';

import { PreviewPickerInput } from './PreviewPickerInput';

export const AppointmentStatePreviewPickerInput = withExtras<AppointmentState>(
  ({ value, onChange, ...rest }) => {
    return (
      <PreviewPickerInput<AppointmentState>
        {...rest}
        picker={
          <AppointmentStatePicker
            value={value}
            onChange={onChange}
            multiple={false}
          />
        }
        value={value}
        formattedValue={value?.name}
        onChange={onChange}
        entity={Entities.appointmentState}
      />
    );
  }
);
