import { noop } from 'lodash';
import {
  createContext,
  type PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { MuiThemeProvider } from '@work4all/components';

type Theme = 'light' | 'dark' | 'auto';
export const getDefaultThemeValue = () =>
  (localStorage.getItem('work4all.theme') as Theme) || 'auto';

const PageThemeContext = createContext<{
  value: Theme;
  set: (val: Theme) => void;
}>({
  value: getDefaultThemeValue(),
  set: noop,
});

export const HookedMuiThemeProvider: React.FC<PropsWithChildren<unknown>> = (
  props
) => {
  const [theme, setTheme] = useState<Theme>(getDefaultThemeValue());

  useEffect(() => {
    const onStorage = () => {
      const defaultTheme = getDefaultThemeValue();
      setTheme(defaultTheme);
    };
    window.addEventListener('storage', onStorage);
    return () => {
      window.removeEventListener('storage', onStorage);
    };
  }, [theme]);

  const value = useMemo(() => {
    return {
      value: theme,
      set: (theme: Theme) => {
        setTheme(theme);
        localStorage.setItem('work4all.theme', theme);
      },
    };
  }, [theme]);

  const [preferredColorScheme, setPreferredColorScheme] = useState<
    'dark' | 'light'
  >(
    window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light'
  );

  useEffect(() => {
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (event) => {
        setPreferredColorScheme(event.matches ? 'dark' : 'light');
      });
  });
  const resultingTheme = useMemo(() => {
    if (value.value === 'auto') {
      return preferredColorScheme;
    }
    return value.value;
  }, [value.value, preferredColorScheme]);

  return (
    <PageThemeContext.Provider value={value}>
      <MuiThemeProvider pageTheme={resultingTheme}>
        {props.children}
      </MuiThemeProvider>
    </PageThemeContext.Provider>
  );
};

export const usePageTheme = () => {
  return useContext(PageThemeContext);
};
