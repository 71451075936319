import styles from './Preview.module.scss';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkIcon from '@mui/icons-material/Link';
import { Box, IconButton, LinearProgress } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import i18next from 'i18next';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import {
  FileAndThumbnail,
  getDocumentFileList,
  useSelectedIndex,
} from '@work4all/components/lib/components/entity-preview/FileListPreview';
import { FileListGalleryPagination } from '@work4all/components/lib/components/entity-preview/FileListPreview/FileListGalleryPagination';
import { FileListPreviewContent } from '@work4all/components/lib/components/entity-preview/FileListPreview/FileListPreviewContent';
import { Card } from '@work4all/components/lib/dataDisplay/card';
import { FileButton } from '@work4all/components/lib/input/FileButton';
import {
  IPreviewProps,
  MIME_TYPES,
  Preview as PreviewComp,
} from '@work4all/components/lib/preview/Preview';

import { TempFileManagerContext } from '@work4all/data/lib/hooks/data-provider/useTempFileManager';

import { Document } from '@work4all/models/lib/Classes/Document.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useFormContextPlus } from '../../../../../form-plus/use-form-context-plus';
import { useMaskContext } from '../../../../hooks/mask-context';
import { useEntityRightsContext } from '../../../../hooks/use-entity-rights';
import { DocumentMaskContext } from '../../context';
import { DocumentMaskFormValue } from '../../types';

import { PreviewOld } from './PreviewOld';

const collapseClasses = {
  root: styles.collapseContainer,
  wrapper: styles.collapseWrapperContent,
};

export const Preview = () => {
  const mask = useMaskContext();

  if (mask.entity === Entities.document) {
    return <PreviewNew />;
  } else {
    return <PreviewOld />;
  }
};

function PreviewNew() {
  const maskExtras = useContext(DocumentMaskContext);

  if (maskExtras.type === 'file') {
    return <PreviewAsFileList />;
  } else {
    return <PreviewAsFile />;
  }
}

function PreviewAsFileList() {
  const { t } = useTranslation();

  const tfm = useContext(TempFileManagerContext);
  const isUploadingFiles = tfm.currentUploadingFiles > 0;

  const mask = useMaskContext<Document>();
  const doc = mask.data;

  const files = useMemo<(FileAndThumbnail & { id?: string })[]>(() => {
    if (mask.mode === 'create') {
      return tfm.temporaryFileUploads.map((file) => {
        return {
          id: file.id as string,
          file: file.fileInfos,
          thumbnail: null,
        };
      });
    } else {
      return getDocumentFileList(doc);
    }
  }, [mask.mode, doc, tfm.temporaryFileUploads]);

  const [_index, setIndex] = useSelectedIndex({ length: files.length });
  const index = files.length === 1 ? 0 : _index;

  const maskExtras = useContext(DocumentMaskContext);
  const rights = useEntityRightsContext();

  function renderPreviewHeaderBar() {
    return (
      <DocumentFileActions
        onAddFiles={(files) => {
          maskExtras.addFiles(files);
        }}
        onRemoveFile={() => {
          if (index !== -1) {
            const file = files[index];
            if (file && file?.id) {
              maskExtras.removeFile(file.id);
            }
          }
        }}
        count={files.length}
        page={index}
        onPageChange={setIndex}
        readOnly={!mask.isCreateMode}
      />
    );
  }

  return (
    <Collapse
      title={t('COMMON.PREVIEW')}
      defaultOpen
      classes={collapseClasses}
      className={styles.collapse}
      headerBar={renderPreviewHeaderBar()}
    >
      <Card noPadding sx={{ height: '100%' }}>
        <Box
          padding="0 1rem 1rem 1rem"
          height="100%"
          display="flex"
          flexDirection="column"
        >
          {isUploadingFiles && (
            <Box sx={{ height: 0, width: '100%' }}>
              <LinearProgress hidden />
            </Box>
          )}
          <FileListPreviewContent
            files={files}
            selectedIndex={index}
            onSelectedIndexChange={setIndex}
            iconProps={{ showPreviewExternallyIcon: rights.update }}
          />
        </Box>
      </Card>
    </Collapse>
  );
}

function PreviewAsFile() {
  const { t } = useTranslation();

  const tfm = useContext(TempFileManagerContext);

  const { watch } = useFormContextPlus<DocumentMaskFormValue>();

  const filePath = watch('filePath');
  const basedOnTemplate = watch('basedOnTemplate');
  const formFileInfos = watch('fileInfos');

  const mask = useMaskContext();
  const maskExtras = useContext(DocumentMaskContext);
  const rights = useEntityRightsContext();

  const uploadTempFile = tfm.temporaryFileUploads?.[0];

  const fileInfos = formFileInfos || uploadTempFile?.fileInfos;
  const isLinkable =
    maskExtras.type === 'link' &&
    typeof filePath === 'string' &&
    filePath.startsWith('https');

  const isTplCreate = maskExtras.type === 'template' && mask.mode === 'create';

  let previewAttrs: IPreviewProps = {
    url: fileInfos?.previewUrl,
    mimeType: fileInfos?.previewMimeType as MIME_TYPES,
    noPreview: !fileInfos?.fileServiceProviderInfos?.exists
      ? i18next.t('MASK.NO_PREVIEW_DOWNLOAD')
      : i18next.t('MASK.NO_PREVIEW_FOR', {
          type: fileInfos?.downloadMimeType,
        }),
    downloadProps:
      fileInfos?.downloadUrl && filePath
        ? {
            url: fileInfos?.downloadUrl,
            filePath: filePath,
          }
        : undefined,
    fileName: fileInfos?.fileEntityFilename,
    noPreviewUrl:
      fileInfos?.fileServiceProviderInfos?.fspUrl || fileInfos?.downloadUrl,
    fspUrl: fileInfos?.fileServiceProviderInfos?.fspUrl,
    isLinkable: isLinkable,
  };
  if (isLinkable) {
    previewAttrs = {
      ...previewAttrs,
      url: filePath,
      mimeType: MIME_TYPES.urllist,
      downloadProps: undefined,
      fileName: filePath,
    };
  }
  if (isTplCreate) {
    previewAttrs = {
      ...previewAttrs,
      noPreview: i18next.t('MASK.NO_TEMPLATE_PREVIEW'),
      mimeType: basedOnTemplate?.fileInfos?.previewMimeType as MIME_TYPES,
      url: basedOnTemplate?.fileInfos?.previewUrl,
    };
  }

  return (
    <Collapse
      title={t('COMMON.PREVIEW')}
      defaultOpen
      classes={collapseClasses}
      className={styles.collapse}
      headerBar={<DocumentLinkActions href={filePath} />}
    >
      <PreviewComp
        {...previewAttrs}
        iconProps={{ showPreviewExternallyIcon: rights.update }}
      />
    </Collapse>
  );
}

function DocumentFileActions({
  readOnly,
  onAddFiles,
  onRemoveFile,
  count,
  page,
  onPageChange,
}: {
  readOnly: boolean;
  onAddFiles: (files: File[]) => void;
  onRemoveFile: () => void;
  count: number;
  page: number;
  onPageChange: (index: number) => void;
}) {
  const shouldShowPagination = count > 1 && page !== -1;

  const showDeleteButton = count > 1 && page !== -1;

  return (
    <Stack direction="row">
      {!readOnly && (
        <>
          <FileButton
            multiple
            onChange={(event) => {
              const files = Array.from(event.currentTarget.files);
              event.currentTarget.value = '';

              onAddFiles(files);
            }}
          >
            {({ onClick }) => {
              return (
                <IconButton color="primary" onClick={onClick}>
                  <AddIcon />
                </IconButton>
              );
            }}
          </FileButton>

          {showDeleteButton && (
            <IconButton color="primary" onClick={onRemoveFile}>
              <DeleteIcon />
            </IconButton>
          )}
        </>
      )}

      {shouldShowPagination && (
        <Box ml="auto">
          <FileListGalleryPagination
            count={count}
            page={page}
            onChange={onPageChange}
          />
        </Box>
      )}
    </Stack>
  );
}

interface DocumentLinkActionsProps {
  href: string;
}

function DocumentLinkActions(props: DocumentLinkActionsProps) {
  const { href } = props;

  const { t } = useTranslation();

  return (
    <Stack direction="row" justifyContent="flex-end" flex="1">
      <Button size="small" startIcon={<LinkIcon />} href={href} target="_blank">
        {t('MASK.OPEN_LINK')}
      </Button>
    </Stack>
  );
}
