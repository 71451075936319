import styles from './ProjectTable.module.scss';

import React, { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { useUser } from '@work4all/data';

import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { ITableSchema } from '@work4all/models/lib/table-schema/table-schema';

import { usePageHeaderTabs } from '../../../containers/file-entities-lists/components/contentWrapper/use-page-header-tabs';
import { EntityTable, IEntityTable } from '../entity-table/EntityTable';
import { useEntityDataTable } from '../entity-table/use-entity-data-table';
import { useFileActions } from '../FileActions';
import { GridStateProvider } from '../grid/GridStateProvider';
import jsonSchema from '../schemata/project-table-schema.json';
import { useSyncScroll } from '../table/hooks/use-sync-scroll';
import { TableAreas } from '../table/Table';
import { useTableLayoutState } from '../table-layout';

import { ProjectBanderoleInfo } from './components/ProjectBanderoleInfo';
import { ProjectRightsGrid } from './components/ProjectRightsGrid';
import { ProjectTableQuickFilters } from './components/ProjectTableQuickFilters';
import { useProjectActions } from './components/use-project-actions';
import { useProjectGrid } from './hooks/use-project-grid';
import { useRightsSchema } from './hooks/use-rights.schema';

type IProjectTableCustomColumns = Record<string, never>;

const defaultSort = [
  { field: 'startDateOuter', direction: SortDirection.DESCENDING },
];

export const ProjectTable = React.forwardRef<TableInstance, IEntityTable>(
  function ProjectTable(props, ref) {
    return (
      <GridStateProvider>
        <ProjectTableInner {...props} ref={ref} />
      </GridStateProvider>
    );
  }
);

export const ProjectTableInner = React.forwardRef<TableInstance, IEntityTable>(
  function ProjectTableInner(props, ref) {
    const user = useUser();
    const schema = jsonSchema as unknown as ITableSchema<never>;
    const { entity: entityType } = schema;

    const { t } = useTranslation();
    const tabs = useMemo(() => {
      return [
        {
          key: 'LIST',
          label: t('PROJECT_TIME.DISPLAY_MODE.LIST'),
        },
        {
          key: 'RIGHT',
          label: t('MORE.RIGHTS'),
        },
      ];
    }, [t]);

    const { activeTab } = usePageHeaderTabs({
      tabs,
      hasPermission: user.isMaster,
    });

    const matrixVisible = activeTab === 'RIGHT';
    const matrixSchema = useRightsSchema(schema, matrixVisible);

    const layoutState = useTableLayoutState();
    const [layout] = layoutState;

    const forceRequestFields: Project = useMemo<Project>(() => {
      if (!user.isMaster) return props.forceRequestFields;
      return {
        ...props.forceRequestFields,
        projectAccessGroups: [
          {
            id: null,
          },
        ],
      };
    }, [props.forceRequestFields, user.isMaster]);

    const { prepareRowDisplayModifiers, ...dataTable } = useEntityDataTable<
      Project,
      IProjectTableCustomColumns
    >({
      schema: matrixSchema,
      defaultSort,
      forceRequestFields,
    });

    const rowModifiers = useCallback(
      (value) => {
        const modifiers = prepareRowDisplayModifiers(value);
        return {
          ...modifiers,
          isFaded: value.projectStatus?.closedStatus === -1,
        };
      },
      [prepareRowDisplayModifiers]
    );

    const listScrollRef = useRef<HTMLDivElement>();
    const gridScrollRef = useRef<HTMLDivElement>();
    useSyncScroll('vertical', listScrollRef, gridScrollRef);

    const matrix = useProjectGrid({
      projects: dataTable.data as Project[],
      refetch: dataTable.refetch,
    });
    const projectActions = useProjectActions(matrix);
    const { fileActionDefinitions } = useFileActions({
      entity: entityType,
    });

    const classes = useMemo(() => {
      return {
        headerWrapper: matrixVisible ? styles.headerRow : undefined,
      };
    }, [matrixVisible]);

    const actions = useMemo(() => {
      return matrixVisible
        ? {
            custom: {
              left: projectActions,
              hideDivider: true,
            },
          }
        : {
            custom: {
              left: fileActionDefinitions,
            },
          };
    }, [fileActionDefinitions, matrixVisible, projectActions]);

    const areas = useMemo(() => {
      const _areas: TableAreas = {
        left: {
          content: <ProjectTableQuickFilters />,
          resizable: true,
        },
        center: matrixVisible && {
          content: <ProjectRightsGrid scrollRef={gridScrollRef} {...matrix} />,
        },
      };
      if (matrixVisible) {
        _areas.right = undefined;
      }

      return _areas;
    }, [matrix, matrixVisible]);

    const overrides = useMemo(() => {
      if (matrixVisible)
        return {
          actions,
          areas,
        };
      return {};
    }, [actions, areas, matrixVisible]);

    return (
      <>
        {matrixVisible && <ProjectBanderoleInfo />}
        <EntityTable
          ref={ref}
          layout={matrixVisible ? 'table' : layout}
          areas={areas}
          actions={actions}
          {...dataTable}
          scrollRef={listScrollRef}
          rowHeightRem={matrixVisible ? '3rem' : undefined}
          basicClasses={classes}
          prepareRowDisplayModifiers={rowModifiers}
          {...props}
          overrides={overrides}
        />
      </>
    );
  }
);
