import { localeDe, localeEnGB } from '@mobiscroll/react';
import { useTranslation } from 'react-i18next';

export function useMobiscrollLanguage() {
  const {
    i18n: { language },
  } = useTranslation();
  switch (language) {
    case 'en-US':
    case 'en':
      return { ...localeEnGB, dateFormatLong: 'DDD, D MMM YYYY' };
    default:
      return { ...localeDe, dateFormatLong: 'DDD. D. MMM. YYYY' };
  }
}
