import styles from './DropOverlay.module.scss';

import { Send } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useEventCallback } from '@mui/material/utils';
import clsx from 'clsx';
import { useRef } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { ReactComponent as CreateNewIcon } from '@work4all/assets/icons/create_new_folder.svg';

import { useLocation, useNavigate } from '@work4all/data';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useBinaryTransfer } from '../../../../hooks/useBinaryTransfer';

interface FilesItem {
  files: File[];
  items: DataTransferItemList;
}

interface DropProps {
  type: Entities;
  onDrop: (dropped) => void;
  disabled?: boolean;
}

const DropOverlayElement: React.FC<DropProps> = ({
  type,
  onDrop,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const dragStartCount = useRef<number>();
  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: [NativeTypes.FILE],
    drop(item) {
      onDrop(item);
    },
    canDrop: () => {
      if (disabled) return false;
      return true;
    },
    collect: (monitor) => {
      const item = monitor.getItem() as FilesItem;
      if (dragStartCount.current === undefined && item?.items?.length > 0) {
        dragStartCount.current = item.items?.length;
      } else if (item === null) {
        dragStartCount.current = undefined;
      }

      return {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      };
    },
  }));

  let content = null;
  switch (type) {
    case Entities.document:
      content = (
        <>
          <CreateNewIcon />
          <Typography variant="h4">
            {t('FILE.dropoperation.create', { context: 'document' })}
          </Typography>
        </>
      );
      break;
    case Entities.eMail:
      content = (
        <>
          <Send />
          <Typography variant="h4">
            {t('FILE.dropoperation.create', { context: 'eMail' })}
          </Typography>
        </>
      );
      break;
    case Entities.inboundInvoice:
      content = (
        <>
          <CreateNewIcon />
          <Typography variant="h4">
            {t('FILE.dropoperation.create', { context: 'inboundInvoice' })}
          </Typography>
        </>
      );
      break;
  }

  return (
    <div
      ref={drop}
      className={clsx(styles.element, {
        [styles.accept]: isOver && canDrop,
        [styles.cant]: !canDrop,
      })}
    >
      {content}
    </div>
  );
};

interface Props {
  active: boolean;
}
export const DropOverlay: React.FC<Props> = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const binaryTransfertCtx = useBinaryTransfer();

  const onDrop = useEventCallback((item: FilesItem, entity: Entities) => {
    binaryTransfertCtx.setTransferData(item.files);

    if (searchParams.get('basedon') !== 'transfer') {
      searchParams.delete('basedon');
      searchParams.append('basedon', 'transfer');
    }

    navigate({
      pathname: `${location.pathname}/details/${entity}/new`,
      search: searchParams.toString(),
    });
  });

  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.active]: props.active,
      })}
    >
      <DropOverlayElement
        type={Entities.document}
        onDrop={(item) => onDrop(item, Entities.document)}
      />
      <DropOverlayElement
        type={Entities.eMail}
        onDrop={(item) => onDrop(item, Entities.eMail)}
      />
      <DropOverlayElement
        type={Entities.inboundInvoice}
        disabled={true}
        onDrop={() => {
          //noop
        }}
      />
    </div>
  );
};
