import { User } from '@work4all/models/lib/Classes/User.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { UserPicker } from '../../../../../entity-picker/user-picker/UserPicker';
import { withExtras } from '../../utils';

import { PreviewPickerInput } from './PreviewPickerInput';

export const UserPreviewPickerInput = withExtras<User>(
  ({ value, onChange, ...rest }) => {
    return (
      <PreviewPickerInput<User>
        {...rest}
        value={value}
        formattedValue={value?.displayName}
        picker={
          <UserPicker value={value} onChange={onChange} multiple={false} />
        }
        onChange={onChange}
        entity={Entities.user}
      />
    );
  }
);
