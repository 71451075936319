import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog, DialogActions, DialogContent } from '../../dialog';

export type ConfirmDialogProps = {
  open: boolean;
  title: string;
  description: string;
  /**
   * @default "Yes"
   */
  confirmLabel?: React.ReactNode;
  /**
   * @default "No"
   */
  cancelLabel?: React.ReactNode;
  onConfirm: () => void;
  onCancel: () => void;
};

export const ConfirmDialog: React.FC<ConfirmDialogProps> = (props) => {
  const { t } = useTranslation();

  const {
    open,
    title,
    description,
    confirmLabel = t('INPUTS.YES'),
    cancelLabel = t('INPUTS.NO'),
    onConfirm,
    onCancel,
  } = props;

  return (
    <Dialog open={open} title={title} onClose={onCancel}>
      <DialogContent>{description}</DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onConfirm}>
          {confirmLabel}
        </Button>
        <Button color="primary" onClick={onCancel}>
          {cancelLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
