import React from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  IPositionPickerProps,
  PositionPicker,
} from '@work4all/components/lib/components/entity-picker/position-picker/PositionPicker';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

interface PositionPickerFieldProps extends IPositionPickerProps {
  error?: string;
  name?: string;
}

export const PositionPickerField = React.forwardRef<
  HTMLDivElement,
  PositionPickerFieldProps
>(function PositionPickerField(props, ref) {
  const { error, ...pickerProps } = props;
  const { value, onChange } = pickerProps;

  const { t } = useTranslation();

  return (
    <EntityPickerPopover picker={<PositionPicker {...pickerProps} />}>
      <PickerTargetButton
        ref={ref}
        name={props.name}
        value={value ? `${value.number} ${value.longtext}` : ''}
        label={t('COMMON.POSITION')}
        error={error}
        onClear={() => {
          onChange(null);
        }}
      />
    </EntityPickerPopover>
  );
});
