import type { TFunction } from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FixedDataPicker } from '@work4all/components/lib/components/entity-picker/fixed-data-picker/FixedDataPicker';
import { IPickerProps } from '@work4all/components/lib/components/entity-picker/types';
import { Selection } from '@work4all/components/lib/components/entity-picker/utils/selection-model';

import { ErpPositionSlMode } from '@work4all/models/lib/Enums/ErpPositionSlMode.enum';
import { InsertBomStyle } from '@work4all/models/lib/Enums/InsertBomStyle.enum';

export interface BomVariant {
  id: InsertBomStyle;
  name: string;
}

export type IBomVariantPickerProps<TMultiple extends boolean> = IPickerProps<
  BomVariant,
  TMultiple
> & {
  simpleOnly?: boolean;
};

export function BomVariantPicker<TMultiple extends boolean>(
  props: IBomVariantPickerProps<TMultiple>
) {
  const { value, onChange, ...rest } = props;
  const { options: BomVariants } = useBomVariantOptions(props.simpleOnly);
  return (
    <FixedDataPicker
      dataSet={BomVariants}
      {...rest}
      value={value}
      clearable={false}
      onChange={(value) => {
        if (Array.isArray(value)) {
          const ids = value.map((x) => x.id);
          const orginal: BomVariant[] = BomVariants.filter((x) =>
            ids.includes(x.id)
          );
          onChange(orginal as Selection<BomVariant, TMultiple>);
          return;
        }
        const single: BomVariant = BomVariants.find((x) => x.id === value.id);
        onChange(single as Selection<BomVariant, TMultiple>);
      }}
    />
  );
}

export const mapBomVariantToOption = (
  id: InsertBomStyle,
  t: TFunction
): BomVariant => ({
  id,
  name: `${t(`COMMON.VARIANT`)} ${id.replace('BOM_STYLE_', '')}`,
});

export const useBomVariantOptions = (simpleOnly?: boolean) => {
  const { t } = useTranslation();
  const options = useMemo(() => {
    return BOM_VARIANT_LOOK_UP.filter((x) => {
      if (simpleOnly) {
        return !BOM_SIMPLE_EXLUDED_LOOK_UP.includes(x.id);
      }
      return true;
    }).map((item) => mapBomVariantToOption(item.id, t));
  }, [simpleOnly, t]);

  return { options };
};

export const BOM_SIMPLE_EXLUDED_LOOK_UP = [
  InsertBomStyle.BOM_STYLE_5,
  InsertBomStyle.BOM_STYLE_6,
];

export const BOM_VARIANT_LOOK_UP = [
  {
    id: InsertBomStyle.BOM_STYLE_1,
  },
  {
    id: InsertBomStyle.BOM_STYLE_2,
  },
  {
    id: InsertBomStyle.BOM_STYLE_3,
  },
  {
    id: InsertBomStyle.BOM_STYLE_4,
  },
  {
    id: InsertBomStyle.BOM_STYLE_5,
  },
  {
    id: InsertBomStyle.BOM_STYLE_6,
  },
];

export const SLMODE_TO_BOM: Record<ErpPositionSlMode, InsertBomStyle | null> = {
  [ErpPositionSlMode.NOT_SELECTED]: null,
  [ErpPositionSlMode.NUR_KOMPONENTEN_EINFUEGEN]: InsertBomStyle.BOM_STYLE_2,
  [ErpPositionSlMode.NUR_KOMPONENTEN_EINFUEGEN_MIT_STUECKLISTE_ALS_TITEL]:
    InsertBomStyle.BOM_STYLE_1,
  [ErpPositionSlMode.STUECKLISTE_MIT_SUB_POSITIONEN_IM_LANGTEXT]:
    InsertBomStyle.BOM_STYLE_4,
  [ErpPositionSlMode.STUECKLISTE_OOHNE_SUB_POSITIONEN_IM_LANGTEXT]:
    InsertBomStyle.BOM_STYLE_3,
  [ErpPositionSlMode.STUECKLISTE_WIRD_ZU_ARTIKEL_MIT_SUB_POSITIONEN_IM_LANGTEXT]:
    InsertBomStyle.BOM_STYLE_5,
  [ErpPositionSlMode.STUECKLISTE_WIRD_ZU_ARTIKEL_OHNE_SUB_POSITIONEN_IM_LANGTEXT]:
    InsertBomStyle.BOM_STYLE_6,
};
