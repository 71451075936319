import { useEffect } from 'react';
import {
  FieldValues,
  Path,
  useController,
  UseControllerProps,
} from 'react-hook-form';

import { useMaskTabPanelContext } from '../mask-tabs/MaskTabPanelContext';

export function useControllerPlus<
  TFieldValues extends FieldValues = FieldValues,
  TName extends Path<TFieldValues> = Path<TFieldValues>
>(props: UseControllerProps<TFieldValues, TName>) {
  const { ...controllerProps } = props;

  const formState = controllerProps.control._formState;
  const controller = useController({ ...controllerProps });

  useTabAutoRegister(props.name);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const context = props.control._options.context as { schema: any } | undefined;
  const schema = context?.schema;

  const required = isFieldRequired(schema, props.name);

  const { field, fieldState } = controller;
  const { errors } = formState;

  const propsPlus = {
    ...controller,
    field: {
      ...field,
      required,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      error: fieldState.error?.message ?? errors[props.name as any]?.message,
    },
  };

  return propsPlus;
}

function useTabAutoRegister(name: string) {
  const tabPanelContext = useMaskTabPanelContext({ optional: true });

  const register = tabPanelContext?.register;

  useEffect(() => {
    if (register) {
      return register(name);
    }
  }, [register, name]);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isFieldRequired(schema: any, name: string) {
  if (!schema) return false;

  const path = name.split('.');

  const nestedSchemaPath = path.slice(0, path.length - 1);
  const nestedProperty = path[path.length - 1];

  let nestedSchema = schema;

  while (nestedSchemaPath.length > 0) {
    const property = nestedSchemaPath.shift();
    nestedSchema = nestedSchema.properties[property];
  }

  return !!nestedSchema?.required?.includes(nestedProperty);
}
