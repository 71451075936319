import styles from './../../MaskOverlay.module.scss';

import ShareIcon from '@mui/icons-material/Share';
import { Box, Button } from '@mui/material';
import { DateTime } from 'luxon';
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AttachmentRenameContext } from '@work4all/components/lib/components/entity-preview/FileListPreview/attachment-rename-context';
import { useAttachmentRenameContextState } from '@work4all/components/lib/components/entity-preview/FileListPreview/use-attachment-rename-context-state';
import { useLock } from '@work4all/components/lib/hooks/object-lock';

import { IUser, useUser } from '@work4all/data';
import { useDataMutation } from '@work4all/data/lib/hooks/data-provider/useDataMutation';
import {
  TempFileManagerContext,
  useTempFileManager,
} from '@work4all/data/lib/hooks/data-provider/useTempFileManager';
import { useSearchHistory } from '@work4all/data/lib/hooks/use-search-history';
import { useEntityJsonSchema } from '@work4all/data/lib/json-schema/EntityJsonSchemasContext';
import { parseNameAndExtension } from '@work4all/data/lib/utils';

import { W4ADateFormat } from '@work4all/models/lib/additionalEnums/DateFormat.enum';
import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Document } from '@work4all/models/lib/Classes/Document.entity';
import { FileEntity } from '@work4all/models/lib/Classes/FileEntity.entity';
import { InputBriefRelation } from '@work4all/models/lib/Classes/InputBriefRelation.entity';
import { InputDokumentRelation } from '@work4all/models/lib/Classes/InputDokumentRelation.entity';
import { InputLetterDocumentFileRelation } from '@work4all/models/lib/Classes/InputLetterDocumentFileRelation.entity';
import { InputTopicMarkRelation } from '@work4all/models/lib/Classes/InputTopicMarkRelation.entity';
import { Letter } from '@work4all/models/lib/Classes/Letter.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { useJSONSchemaResolver } from '@work4all/utils';
import {
  canAddDocument,
  canDeleteDocument,
  canEditDocument,
} from '@work4all/utils/lib/permissions';

import { usePageTitle } from '../../../../../hooks';
import { useBinaryTransfer } from '../../../../../hooks/useBinaryTransfer';
import { settings, useSetting } from '../../../../../settings';
import { formatPageTitle } from '../../../../../utils/format-page-title';
import { resolveEntityVariant } from '../../../../file-entities-lists';
import { useFormContextPlus } from '../../../form-plus/use-form-context-plus';
import {
  MaskTab,
  MaskTabContext,
  MaskTabPanel,
  MaskTabs,
} from '../../../mask-tabs';
import { MaskOverlayStandardSubmitButton } from '../../components';
import { Form } from '../../components/form';
import { MaskContent } from '../../components/MaskContent/MaskContent';
import { MaskOverlayDeleteMenuItem } from '../../components/MaskOverlayDeleteMenuItem';
import { MaskOverlayFullscreenToggleButton } from '../../components/MaskOverlayFullscreenToggleButton';
import { MaskOverlayHeader } from '../../components/MaskOverlayHeader/MaskOverlayHeader';
import { MaskOverlayMenuWrapper } from '../../components/MaskOverlayMenuWrapper';
import { HistoryTabPanel } from '../../components/tab-panels/history/HistoryTabPanel';
import {
  MaskContextProvider,
  useMaskConfig,
  useMaskContext,
  useMaskContextValue,
} from '../../hooks/mask-context';
import { useConfirmBeforeCloseMask } from '../../hooks/use-confrm-before-close-mask';
import { EntityRightsContext } from '../../hooks/use-entity-rights';
import { useStandardDeleteEntityHandler } from '../../hooks/use-standard-delete-entity-handler';
import { normalizeFormValue } from '../../hooks/useExtendedFormContext';
import { useInitialFormValue } from '../../hooks/useInitialFormValue';
import { useMaskMetaData } from '../../MaskOverlay';
import { MaskControllerProps } from '../../types';
import {
  AssignableEntityResult,
  useAssignableTemplateEntity,
} from '../../utils/use-assignable-template-entity';
import { useFormUpdate } from '../../utils/use-form-update';

import { GeneralTabPanel } from './components/tab-panels/general/GeneralTabPanel';
import { DocumentMaskContext } from './context';
import { CreateDocumentEntry, DocumentInit } from './CreateDocumentEntry';
import { DocumentMaskFormValue } from './types';
import { useMutateDocumentFiles } from './use-mutate-child-documents';

export const considerExternalSourcesRegExp = /^(ftp|http(s)|ssh|wss):\/\/(.*)/i;
export type DocumentsTypesIntersection<T extends EMode = EMode.query> =
  | Document<T>
  | Letter<T>;

//structure of the entity that can be edited
export enum DocumentsGroups {
  general = 'general',
}

export const DocumentsOverlayController = (props: MaskControllerProps) => {
  const { t, i18n } = useTranslation();

  const mask = useMaskConfig(props);
  const { params } = mask;
  const { basedon, customizedTitle } = params;

  let maskName = t(`COMMON.${mask.entity.toUpperCase()}`);
  if (customizedTitle) {
    maskName = i18n.exists(customizedTitle)
      ? t(customizedTitle)
      : customizedTitle;
  }

  const bt = useBinaryTransfer();

  const [init, setInit] = useState<DocumentInit | null>(null);

  const maskContext = useMaskContextValue({ ...mask, data: {} });

  useEffect(() => {
    // The mask can only be initialized once. If init is not null, we don't need
    // to do anything.
    if (init !== null) {
      return;
    }

    if (basedon === 'transfer' && bt.transferData) {
      const file = bt.transferData[0];

      if (!file) {
        console.warn('No file was found in the BinaryTransfer');
        return;
      }

      setInit({
        type: 'file',
        content: [file],
      });
    }
  }, []);

  if (mask.mode === 'create' && init === null) {
    return (
      <MaskContextProvider value={maskContext}>
        <Box display="flex" flexDirection="column" height={'100%'}>
          <MaskOverlayHeader title={maskName} tabs={null} />
          <div className={styles.tabsWrapper}>
            <CreateDocumentEntry
              multiple={mask.entity === Entities.document}
              onInitReady={setInit}
            />
          </div>
        </Box>
      </MaskContextProvider>
    );
  } else {
    return <DocumentsForm {...props} init={init} />;
  }
};

interface DocumentsFormProps extends MaskControllerProps {
  init: DocumentInit | null;
}

const EmptyArray = [];
const DocumentsForm = (props: DocumentsFormProps) => {
  const { t, i18n } = useTranslation();
  const mask = useMaskConfig(props);

  const { init } = props;
  const { params } = mask;
  const { customizedTitle, presetFields = '{}' } = params;

  let maskName = t(`COMMON.${mask.entity.toUpperCase()}`);
  if (customizedTitle) {
    maskName = i18n.exists(customizedTitle)
      ? t(customizedTitle)
      : customizedTitle;
  }

  const presetData = JSON.parse(presetFields);
  const { value: defaultTemplate, set: saveAsDefaultTemplate } = useSetting(
    settings.lastUsedDocTemplate({
      entity: mask.entity,
      docType: presetData.infoWindowName || 'genericdoc',
    })
  );

  const user = useUser();
  const tfm = useTempFileManager(EmptyArray);

  useEffect(() => {
    if (mask.mode === 'create') {
      if (!init) {
        throw new Error('No init data was provided for document mask');
      }

      if (init.type === 'file') {
        tfm.resetChanges();
        tfm.uploadFiles(init.content);
      }
    }
    // The TempFileManager is not needed in the dependencies array as it
    // changing by itself should not trigger anything.

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mask.mode, init]);

  const schema = useEntityJsonSchema(mask.entity);

  const requestData = useMemo(() => {
    // we will always filter by id and will always try to normalize the data
    // so an id field will be available
    const filter = [{ id: { $eq: mask.id } }];
    const entity = mask.entity;

    const FileInfoFragment: FileEntity<EMode.query> = {
      previewUrl: null,
      downloadUrl: null,
      fileRequestType: null,
      previewMimeType: null,
      fileEntityFilename: null,
      downloadMimeType: null,
      fileServiceProviderInfos: {
        id: null,
        exists: null,
        fileName: null,
        mimeType: null,
        fspUrl: null,
      },
    };

    const DocumentFileFragment: Document<EMode.query> = {
      thumbnail: {
        id: null,
        filename: null,
        fileInfos: {
          ...FileInfoFragment,
        },
      },
      fileInfos: {
        ...FileInfoFragment,
      },
      noteHtml: null,
    };

    const data: DocumentsTypesIntersection = {
      id: null,
      note: null,
      date: null,
      user: {
        id: null,
        displayName: null,
        firstName: null,
        lastName: null,
      },
      userId: null,
      topicMarkList: [
        {
          name: null,
          id: null,
        },
      ],

      businessPartnerId: null,
      businessPartnerType: null,
      businessPartner: {
        id: null,
        data: {
          customer: {
            name: null,
            id: null,
            mainContactId: null,
            number: null,
            website: null,
            contactList: [
              {
                id: null,
                displayName: null,
                role: null,
                layedOff: null,
                salutation: {
                  id: null,
                  isMale: null,
                  isFemale: null,
                },
              },
            ],
          },
          supplier: {
            name: null,
            id: null,
            mainContactId: null,
            number: null,
            website: null,
            contactList: [
              {
                id: null,
                displayName: null,
                role: null,
                layedOff: null,
                salutation: {
                  id: null,
                  isMale: null,
                  isFemale: null,
                },
              },
            ],
          },
        },
      },
      contact: {
        name: null,
        firstName: null,
        displayName: null,
        businessPartnerId: null,
        businessPartnerType: null,
        eMail: null,
        id: null,
      },
      project: {
        name: null,
        id: null,
        number: null,
        projectSubDirectories: [
          {
            id: null,
            name: null,
          },
        ],
      },
      projectId: null,
      filePath: null,
      fileInfos: {
        ...FileInfoFragment,
      },
    };

    if (mask.entity === Entities.document) {
      (data as unknown as Document).infoWindowName = null;

      Object.assign(data, {
        ...DocumentFileFragment,
        childDocuments: [
          {
            id: null,
            ...DocumentFileFragment,
          },
        ],
      });
    }

    return {
      filter,
      entity,
      data,
    };
  }, [mask.entity, mask.id]);

  // TODO Similar changes must be made to the queries for "parent" or "template"
  // entities. Right now they use "live" queries that will get updated after
  // a mutation.
  //
  // Maybe there is a better way to implement this. What we need is fetch the
  // data once, re-fetch if the query changes, and be able to manually ferfesh
  // the query after a form submit. But ignore all updates caused by changes
  // to Apollo's cache.
  const initialFormValue = useInitialFormValue<DocumentsTypesIntersection>(
    requestData,
    mask.mode !== 'edit'
  );
  const template = useAssignableTemplateEntity(mask);

  const metaData = useMaskMetaData();
  const { entityVariant } = resolveEntityVariant(metaData?.subEntityType);

  const newEntityData = useMemo(() => {
    const fileName = init ? getNameFromInit(init) : '';

    const data = createDefaultDocumentData({
      filePath: fileName,
      note:
        init?.type === 'link' ? fileName : parseNameAndExtension(fileName).name,
      template,
      user,
    });

    return {
      ...data,
      ...presetData,
      basedOnTemplate: defaultTemplate,
      infoWindowName: entityVariant ?? '',
    };

    //we only try to use the defaultTemplate initally, not after we have changed it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [init, template, user, presetFields]);

  const dataRaw = mask.isCreateMode
    ? newEntityData
    : initialFormValue.value ?? newEntityData;

  const data = useMemo(() => {
    return normalizeFormValue(dataRaw);
  }, [dataRaw]);

  const customRules = useCallback(
    (data) => {
      if (init?.type === 'template' && !data.basedOnTemplate) {
        return {
          basedOnTemplate: {
            message: t('ERROR.REQUIRED_TEMPLATE'),
            type: 'customValidation',
          },
        };
      }
      return true;
    },
    [init?.type, t]
  );
  //validation and mutation
  const resolver = useJSONSchemaResolver(schema, customRules);
  const form = useForm<DocumentMaskFormValue>({
    resolver,
    mode: 'onChange',
    defaultValues: data,
    shouldFocusError: false,
    context: {
      schema,
    },
  });

  const { handleSubmit, reset, formState, getValues } = form;

  useLock(
    {
      subEntityType: mask.entity,
      subEntityIds: mask.id ? [mask.id.toString()] : [],
    },
    [mask.entity, mask.id]
  );

  useEffect(() => {
    reset(data);
  }, [reset, data]);

  const basedOnTemplate = form.watch('basedOnTemplate');

  useEffect(() => {
    if (basedOnTemplate) {
      saveAsDefaultTemplate(basedOnTemplate);
    }
  }, [basedOnTemplate, saveAsDefaultTemplate]);

  const isDirty =
    Object.keys(formState.dirtyFields).length > 0 ||
    tfm.temporaryFileUploads?.length > 0;

  useConfirmBeforeCloseMask(isDirty);

  const { saveSearchItemFromEnityData } = useSearchHistory();

  const [mutate] = useDataMutation<
    DocumentsTypesIntersection<EMode.entity>,
    EMode.upsert,
    InputDokumentRelation | InputBriefRelation
  >({
    entity: mask.entity,
    mutationType: EMode.upsert,
    responseData:
      requestData.data as unknown as DocumentsTypesIntersection<EMode.entity>,
    onCompleted: (data) => {
      if (mask.isCreateMode) {
        saveSearchItemFromEnityData(data as Document | Letter);
      }
      props.onAfterSave(data);
    },
  });

  useFormUpdate(
    {
      'businessPartner.data': (businessPartner: Customer | Supplier) => {
        const businessPartnerId = businessPartner?.id ?? 0;
        const businessPartnerType = getBusinessPartnerType(businessPartner);
        const contact = businessPartner?.mainContact ?? getValues('contact');

        return {
          businessPartnerId,
          businessPartnerType,
          contact,
        };
      },

      contact: (contact: Contact) => {
        const contactId = contact?.id ?? 0;

        return { contactId };
      },
      project: (project: Project) => {
        const projectId = project?.id ?? 0;
        const projectSubDirectories = project?.projectSubDirectories ?? [];

        const businessPartnerField = getValues('businessPartner.data');

        if (businessPartnerField !== null) {
          return {
            projectId,
            assignedToFolder: null,
            projectSubDirectories,
          };
        } else {
          const businessPartner =
            project?.customer ?? project?.supplier ?? null;

          return {
            projectId,
            projectSubDirectories,
            'businessPartner.data': businessPartner,
          };
        }
      },
      user: (user: User) => {
        const userId = user?.id ?? 0;

        return { userId: userId };
      },
    },
    form
  );

  usePageTitle(
    formatPageTitle([
      t(`COMMON.${mask.entity.toUpperCase()}`),
      data?.fileInfos?.fileEntityFilename,
    ]),
    {
      priority: 1,
    }
  );

  const handleDeleteEntitiesClick = useStandardDeleteEntityHandler(mask);

  const attachmentRenameContextState = useAttachmentRenameContextState({
    nameDisabled: mask.isEditMode,
    noteDisabled: false,
  });

  const { mutateDocumentFiles } = useMutateDocumentFiles({
    childDocumentList: [dataRaw, ...(dataRaw.childDocuments || [])],
    entity: mask.entity,
    getNote: attachmentRenameContextState.getNote,
  });

  const submitForm = async (data) => {
    const assignedToFolder = getValues('assignedToFolder');
    const currentTopicMarkList = getValues('topicMarkList');
    const filePath = getValues('filePath');
    const basedOnTemplate = getValues('basedOnTemplate');

    function makeRelations() {
      let topic: InputTopicMarkRelation = undefined;

      if (currentTopicMarkList) {
        topic = {
          set: currentTopicMarkList ? currentTopicMarkList[0]?.id : 0,
        };
      }

      let fileContent: InputLetterDocumentFileRelation = undefined;
      let fileContents: InputLetterDocumentFileRelation[] = undefined;

      if (mask.mode === 'create') {
        if (init.type === 'template') {
          if (basedOnTemplate) {
            fileContent = {
              subDirectory: assignedToFolder?.name,
              targetfileName: filePath,
              templateCode: basedOnTemplate.id,
            };
          }
        }

        if (init.type === 'file') {
          const files = tfm.temporaryFileUploads;

          if (mask.entity === Entities.document) {
            if (files.length > 0) {
              fileContents = tfm.temporaryFileUploads.map((attachment) => {
                const attachmentName =
                  attachmentRenameContextState.getName(attachment.id) ??
                  attachment.fileName;

                return {
                  subDirectory: assignedToFolder?.name,
                  targetfileName: attachmentName,
                  tempFileId: attachment.id as string,
                  bodyHtml:
                    attachmentRenameContextState.getNote(attachment.id) || ' ',
                };
              });
            }
          } else {
            const firstFile = files[0];

            fileContent = {
              subDirectory: assignedToFolder?.name,
              targetfileName: firstFile.fileName,
              tempFileId: firstFile.id as string,
            };
          }
        }
      }

      const result =
        topic || fileContent || fileContents
          ? { topic, fileContent, fileContents }
          : undefined;
      return result;
    }

    const relations = makeRelations();

    if (mask.mode === 'edit') {
      const keysToMutate = { id: data.id };
      Object.keys(formState.dirtyFields).forEach((key) => {
        keysToMutate[key] = data[key];
      });
      await mutateDocumentFiles();
      await mutate(keysToMutate, relations ? { relations } : undefined);
    } else {
      await mutate(data, relations ? { relations } : undefined);
    }
  };

  const entityRights = useMemo(
    () => ({
      read: true,
      create: canAddDocument(user),
      update: canEditDocument(user, data as unknown as Document),
      delete: canDeleteDocument(user, data as unknown as Document),
    }),
    [data, user]
  );

  let documentType = init?.type;
  if (mask.isEditMode) {
    documentType = 'file';
    if (considerExternalSourcesRegExp.exec(initialFormValue.value?.filePath)) {
      documentType = 'link';
    }
  }

  const maskContext = useMaskContextValue({ ...mask, data });

  const maskExtras = useMemo(() => {
    const uploadFiles = tfm.uploadFiles;
    const markFilesToDelete = tfm.markFilesToDelete;
    const resetChanges = tfm.resetChanges;

    return {
      type: documentType,
      addFiles(files: File[]): void {
        uploadFiles(files);
      },
      removeFile(id: string): void {
        markFilesToDelete([id]);
      },
      setFiles(files: File[]): void {
        resetChanges();
        uploadFiles(files);

        const file = files[0];

        //eslint-disable-next-line
        //@ts-ignore
        form.setValue('filePath', file.name);

        if (!form.getFieldState('note').isTouched) {
          form.setValue('note', file.name);
        }
      },
    };
  }, [
    tfm.uploadFiles,
    tfm.markFilesToDelete,
    tfm.resetChanges,
    documentType,
    form,
  ]);

  return (
    <MaskContextProvider value={maskContext}>
      <DocumentMaskContext.Provider value={maskExtras}>
        <EntityRightsContext.Provider value={entityRights}>
          <TempFileManagerContext.Provider value={tfm}>
            <FormProvider {...form}>
              <MaskTabContext defaultValue={mask.params?.tab ?? 'general'}>
                <Form
                  className={styles.maskForm}
                  onSubmit={handleSubmit(submitForm)}
                >
                  <MaskOverlayHeader
                    title={maskName}
                    subTitle={form.watch('note')}
                    actions={
                      <>
                        {mask.isEditMode && (
                          <Button
                            disabled
                            size="large"
                            startIcon={<ShareIcon />}
                          >
                            {t('INPUTS.SHARE')}
                          </Button>
                        )}
                        <MaskOverlayStandardSubmitButton
                          isDirty={attachmentRenameContextState.isDirty}
                        />
                        <MaskOverlayFullscreenToggleButton />
                        {mask.mode === 'edit' && (
                          <MaskOverlayMenuWrapper>
                            <MaskOverlayDeleteMenuItem
                              disabled={mask.wip || !entityRights.delete}
                              onClick={handleDeleteEntitiesClick}
                            />
                          </MaskOverlayMenuWrapper>
                        )}
                      </>
                    }
                    tabs={<DocumentTabs />}
                  />

                  <AttachmentRenameContext.Provider
                    value={attachmentRenameContextState}
                  >
                    <Content />
                  </AttachmentRenameContext.Provider>
                </Form>
              </MaskTabContext>
            </FormProvider>
          </TempFileManagerContext.Provider>
        </EntityRightsContext.Provider>
      </DocumentMaskContext.Provider>
    </MaskContextProvider>
  );
};

const DocumentTabs = memo(function DocumentTabs() {
  const { t } = useTranslation();

  return (
    <MaskTabs>
      <MaskTab value="general" label={t('MASK.GENERAL')}></MaskTab>
      <MaskTab value="history" label={t('MASK.HISTORY')}></MaskTab>
    </MaskTabs>
  );
});

const Content = memo(function DocumentTabPanels() {
  const mask = useMaskContext();
  const { setNote } = useContext(AttachmentRenameContext);
  const { watch } = useFormContextPlus<DocumentMaskFormValue>();

  const { id, childDocuments, noteHtml } = watch();

  useEffect(() => {
    if (mask.entity !== Entities.document || !id) return;

    setNote(id.toString(), noteHtml);
    childDocuments.forEach((doc) => {
      const id = (doc as Document)?.id;
      setNote(id, doc?.noteHtml);
    });
  }, [id, noteHtml, setNote, childDocuments, mask.entity]);

  return (
    <MaskContent>
      <MaskTabPanel value="general">
        <GeneralTabPanel />
      </MaskTabPanel>

      <MaskTabPanel value="history">
        <HistoryTabPanel />
      </MaskTabPanel>
    </MaskContent>
  );
});

type ICreateDocumentDefaultParams = {
  note?: string;
  template: AssignableEntityResult;
  user: IUser;
  filePath?: string;
};

function createDefaultDocumentData(
  params: ICreateDocumentDefaultParams
): DocumentsTypesIntersection<EMode.entity> {
  const { note = '', template, user, filePath = '' } = params;

  const common: DocumentsTypesIntersection<EMode.entity> = {
    note: note,
    filePath,
    date: DateTime.now().toFormat(W4ADateFormat.DEFAULT),
    user: {
      displayName: `${user.firstName} ${user.lastName}`,
      id: user.benutzerCode,
    },
    userId: user.benutzerCode,
    creatorUserId: user.benutzerCode,
    creator: {
      displayName: `${user.firstName} ${user.lastName}`,
      id: user.benutzerCode,
    },
    topicMarkList: null,
  };

  if (template.enabled) {
    if (!template.data) {
      return null;
    }

    const data: DocumentsTypesIntersection<EMode.entity> = {
      ...common,
      businessPartner: {
        id: template.data.businessPartner?.data?.id ?? 0,
        data: template.data.businessPartner?.data
          ? { ...template.data.businessPartner?.data, mainContact: null }
          : null,
      },
      businessPartnerId: template.data.businessPartner?.data?.id ?? 0,
      businessPartnerType:
        template.data.businessPartner?.businessPartnerType ??
        template.data.businessPartnerType ??
        SdObjType.LIEFERANT,
      contact: template.data.contact,
      contactId: template.data.contact?.id ?? 0,
      project: template.data.project,
      projectId: template.data.project?.id || 0,
      topicMarkList: template.data.topicMarkList,
    };
    return data;
  }

  return {
    ...common,
    businessPartner: null,
    businessPartnerId: 0,
    businessPartnerType: SdObjType.LIEFERANT,
    contact: null,
    contactId: 0,
    project: null,
    projectId: 0,
  };
}

function getBusinessPartnerType(
  businessPartner: Customer | Supplier
): SdObjType {
  if (businessPartner) {
    const typename: string = (
      businessPartner as unknown as Record<string, string>
    ).__typename;

    if (typename === 'Kunde') {
      return SdObjType.KUNDE;
    }
  }

  return SdObjType.LIEFERANT;
}

const getNameFromInit = (init: DocumentInit) => {
  switch (init.type) {
    case 'file':
      if (init.content.length === 1) {
        return init.content[0].name;
      } else {
        return '';
      }
    case 'link':
      return init.content;
    default:
      return '';
  }
};
