import { Grid } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { PROJECT_DATA } from '@work4all/components/lib/components/entity-picker/project-picker/projectPickerPresets';

import { InboundDeliveryNote } from '@work4all/models/lib/Classes/InboundDeliveryNote.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { InboundDeliveryNoteKindPickerField } from '../../../../../../../../../../components/entity-picker/InboundDeliveryNoteKindPickerField';
import { ProjectPickerField } from '../../../../../../../../../../components/entity-picker/ProjectPickerField';
import { UserPickerField } from '../../../../../../../../../../components/entity-picker/UserPickerField';
import { ControllerPlus } from '../../../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import { LabeledInput } from '../../../../../../../../locked-inputs';
import { useMaskContext } from '../../../../../../../hooks/mask-context';
import { ErpData } from '../../../../../ErpData';
import { FormCheck } from '../../../../FormCheck';

import { ErpCostCenter } from './components/ErpCostCenter';

const SEARCH_ALL = [];

export const Assignment: React.FC = () => {
  const { t } = useTranslation();

  const mask = useMaskContext();
  const { control, register, getValues } = useFormContextPlus<ErpData>();

  return (
    <Collapse defaultOpen={true} title={t('MASK.ASSIGNMENT')}>
      <Grid container spacing="1rem">
        <Grid item xs={12} sm={4}>
          <ControllerPlus
            control={control}
            name="user2"
            render={({ field, fieldState }) => {
              return (
                <UserPickerField
                  {...field}
                  label={t('INPUTS.RESPONSIBLE')}
                  error={fieldState?.error?.message}
                  clearable={false}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ControllerPlus
            control={control}
            name="user"
            render={({ field, fieldState }) => {
              return (
                <UserPickerField
                  {...field}
                  label={t('COMMON.PERSON')}
                  value={field.value}
                  error={fieldState?.error?.message}
                  clearable={false}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={6} sm={2}>
          <LabeledInput label={t('INPUTS.OUR_SIGN')} {...register('ourSign')} />
        </Grid>
        <Grid item xs={6} sm={2}>
          <LabeledInput
            placeholder={t('INPUTS.YOUR_SIGN')}
            label={t('INPUTS.YOUR_SIGN')}
            {...register('yourSign')}
          />
        </Grid>
        <Grid item md={8} sm={8} xs={12}>
          <ControllerPlus
            control={control}
            name="project"
            render={({ field }) => {
              const businessPartnerId = getValues('businessPartnerId');
              const businessPartnerType = getValues('businessPartnerType');
              const name =
                businessPartnerType === SdObjType.KUNDE
                  ? 'customerId'
                  : 'supplierId';
              const bpFilter = businessPartnerId
                ? [
                    {
                      $and: [
                        {
                          $or: [
                            {
                              [name]: {
                                $eq: businessPartnerId,
                              },
                            },
                          ],
                        },
                      ],
                    },
                  ]
                : [];

              return (
                <ProjectPickerField
                  {...field}
                  data={PROJECT_DATA}
                  useSearchHistory={false}
                  excludeClosedStatus
                  prefilter={bpFilter}
                  searchPrefilter={SEARCH_ALL}
                  listPrefilter={SEARCH_ALL}
                />
              );
            }}
          />
        </Grid>

        <Grid item md={4} sm={4} xs={12}>
          <ErpCostCenter />
        </Grid>
        <Grid item md={12} xs={12}>
          <FormCheck<ErpData, InboundDeliveryNote>
            entity={mask.entity}
            allowedEntities={[Entities.inboundDeliveryNote]}
            control={control}
            render={({ control }) => (
              <ControllerPlus
                control={control}
                name={'inboundDeliveryNoteKind'}
                render={({ field }) => {
                  return <InboundDeliveryNoteKindPickerField {...field} />;
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </Collapse>
  );
};
