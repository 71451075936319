import { useMemo } from 'react';

import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { MaskExtendedConfig } from '../../../types';
import { useAssignableTemplateEntity } from '../../../utils/use-assignable-template-entity';

export const useProjectDefaultData = (mask: MaskExtendedConfig): Project => {
  const template = useAssignableTemplateEntity(mask);
  return useMemo<Project>(() => {
    const project: Project = {};
    project.startDateOuter = new Date().toISOString();

    const contact =
      template?.data?.contact ||
      template?.data?.businessPartner?.data?.mainContact ||
      null;
    if (template?.data?.businessPartnerType === SdObjType.KUNDE) {
      project.customer = template?.data?.businessPartner?.data;
      project.customerId = template?.data?.businessPartner?.data?.id;
      project.customerContact = contact;
      project.customerContactId = contact?.id;
    } else if (template?.data?.businessPartnerType === SdObjType.LIEFERANT) {
      project.supplier = template?.data?.businessPartner?.data;
      project.supplierId = template?.data?.businessPartner?.data?.id;
      project.supplierContact = contact;
      project.supplierContactId = contact?.id;
    }
    return project;
  }, [template]);
};
