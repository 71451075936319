import styles from './../../MaskOverlay.module.scss';

import { gql, useMutation } from '@apollo/client';
import SaveIcon from '@mui/icons-material/Save';
import SendIcon from '@mui/icons-material/Send';
import { pick } from 'lodash';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { LABEL_VISIBILITY, MaskActionButton } from '@work4all/components';
import { AttachmentsUploadButton } from '@work4all/components/lib/components/attachments/AttachmentsUploadButton';
import { EmailStatus } from '@work4all/components/lib/components/email-status/EmailStatus';
import { useLock } from '@work4all/components/lib/hooks';
import { htmlParser } from '@work4all/components/lib/input/format-text/TextEditor/utils/html-parser';

import {
  IUser,
  prepareResponse,
  useDataMutation,
  useDataProvider,
  useUser,
} from '@work4all/data';
import { useEntityEventsContext } from '@work4all/data/lib/entity-events/entity-events-context';
import {
  TempFileManagerContext,
  useTempFileManager,
} from '@work4all/data/lib/hooks/data-provider/useTempFileManager';
import { useSearchHistory } from '@work4all/data/lib/hooks/use-search-history';
import { useSyncEmailStatus } from '@work4all/data/lib/hooks/use-sync-email-status';
import { useEntityJsonSchema } from '@work4all/data/lib/json-schema/EntityJsonSchemasContext';
import { EMPTY_UID } from '@work4all/data/lib/utils/empty-uid';

import { IAttachmentEntity } from '@work4all/models';
import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { EMail } from '@work4all/models/lib/Classes/EMail.entity';
import { EMailSignature } from '@work4all/models/lib/Classes/EMailSignature.entity';
import { EMailTemplate } from '@work4all/models/lib/Classes/EMailTemplate.entity';
import { InputEMailAnhangAttachementsRelation } from '@work4all/models/lib/Classes/InputEMailAnhangAttachementsRelation.entity';
import { InputEMailRelation } from '@work4all/models/lib/Classes/InputEMailRelation.entity';
import { InputTopicMarkRelation } from '@work4all/models/lib/Classes/InputTopicMarkRelation.entity';
import { MailboxConfiguration } from '@work4all/models/lib/Classes/MailboxConfiguration.entity';
import { MailSearchContactsResultItem } from '@work4all/models/lib/Classes/MailSearchContactsResultItem.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { SaveSendMailJob } from '@work4all/models/lib/Classes/SaveSendMailJob.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { ContactType } from '@work4all/models/lib/Enums/ContactType.enum';
import { EMailKind } from '@work4all/models/lib/Enums/EMailKind.enum';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SaveSendMailJobMode } from '@work4all/models/lib/Enums/SaveSendMailJobMode.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { useJSONSchemaResolver } from '@work4all/utils';
import {
  canAddEmail,
  canDeleteEmail,
  canEditEmail,
} from '@work4all/utils/lib/permissions';

import { usePageTitle } from '../../../../../hooks';
import { useBinaryTransfer } from '../../../../../hooks/useBinaryTransfer';
import { formatPageTitle } from '../../../../../utils/format-page-title';
import {
  MaskTab,
  MaskTabContext,
  MaskTabPanel,
  MaskTabs,
} from '../../../mask-tabs';
import { Form } from '../../components/form';
import { MaskContent } from '../../components/MaskContent/MaskContent';
import { MaskOverlayDeleteMenuItem } from '../../components/MaskOverlayDeleteMenuItem';
import { MaskOverlayFullscreenToggleButton } from '../../components/MaskOverlayFullscreenToggleButton';
import { MaskOverlayHeader } from '../../components/MaskOverlayHeader/MaskOverlayHeader';
import { MaskOverlayMenuWrapper } from '../../components/MaskOverlayMenuWrapper';
import { MaskOverlaySubmitButton } from '../../components/MaskOverlaySubmitButton';
import { HistoryTabPanel } from '../../components/tab-panels/history/HistoryTabPanel';
import {
  MaskContextProvider,
  useMaskConfig,
  useMaskContextValue,
} from '../../hooks/mask-context';
import { useConfirmBeforeCloseMask } from '../../hooks/use-confrm-before-close-mask';
import { EntityRightsContext } from '../../hooks/use-entity-rights';
import { useStandardDeleteEntityHandler } from '../../hooks/use-standard-delete-entity-handler';
import { normalizeFormValue } from '../../hooks/useExtendedFormContext';
import { MaskControllerProps } from '../../types';
import {
  AssignableEntityResult,
  useAssignableTemplateEntity,
} from '../../utils/use-assignable-template-entity';
import { useFormUpdate } from '../../utils/use-form-update';

import { CopyAttachmentsDialog } from './components/copy-attachments-dialog/CopyAttachmentsDialog';
import { GeneralTabPanel } from './components/tab-panels//general/GeneralTabPanel';
import { EmailTemplater } from './email-templater/EmailTemplater';
import { EmailActions, EMAILMODES } from './EmailActions';
import { useConvertAttachments } from './hooks/useConvertAttachments';
import { EmailMaskFormValue } from './types';
import { useEmailContactData } from './use-email-contact-data';
import { calculateToMailContacts } from './utils/calculateToMailContacts';

const removeHtmlComments = (val: string) => {
  return val?.replace(/<![\s\S]*?>/g, '') || '';
};

const vars = { rewriteInlineAttachements: true };

const EMailRequestData: EMail<EMode.query> = {
  userId: null,
  id: null,
  subject: null,
  bodyHtml: null,
  rewrittenBodyHtml: null,
  businessPartnerId: null,
  businessPartnerType: null,
  saveSendMailJob: {
    id: null,
    errorMessages: null,
    jobState: null,
  },
  date: null,
  from: null,
  to: null,
  toMailContacts: null,
  cc: null,
  ccMailContacts: null,
  bcc: null,
  businessPartner: {
    id: null,
    data: {
      customer: {
        name: null,
        id: null,
        mainContactId: null,
        number: null,
        website: null,
      },
      supplier: {
        name: null,
        id: null,
        mainContactId: null,
        number: null,
        website: null,
      },
    },
  },
  contact: {
    id: null,
    displayName: null,
    eMail: null,
    businessPartnerId: null,
    businessPartnerType: null,
  },
  contactId: null,
  project: {
    name: null,
    id: null,
    number: null,
  },
  projectId: null,
  topicMarkList: [
    {
      id: null,
      name: null,
    },
  ],
  kind: null,
  priority: null,
  attachmentList: [
    {
      id: null,
      fileName: null,
      displayFilename: null,
      fileInfos: {
        fileSize: null,
        downloadUrl: null,
        fileEntityFilename: null,
        previewUrl: null,
        previewMimeType: null,
        downloadMimeType: null,
        fileRequestType: null,
        fileServiceProviderInfos: {
          customUrl: null,
          exists: null,
          fileName: null,
          fspUrl: null,
          id: null,
          key: null,
          mimeType: null,
          size: null,
          thumbnail: null,
        },
      },
    },
  ],
};

const START_JOB_SAVE_SEND_MAIL = gql`
  mutation startJobSaveSendMail(
    $jobAction: SaveSendMailJobMode!
    $mail: InputEMail!
    $mailRelation: InputEMailRelation
    $mailServiceId: ID
    $senderMailAddress: String
    $attachToEntityType: EMailAttachToEntityType
    $attachToEntityPK: PrimaryKey
  ) {
    startJobSaveSendMail(
      jobAction: $jobAction
      mail: $mail
      mailRelation: $mailRelation
      mailServiceId: $mailServiceId
      senderMailAddress: $senderMailAddress
      attachToEntityType: $attachToEntityType
      attachToEntityPK: $attachToEntityPK
    ) {
      id
      createdMail {
        ${prepareResponse(Entities.eMail, EMailRequestData)}
      }
    }
  }
`;

const templateIdAccessor = 'templateId';
const actionModeAccessor = 'mode';
const basedon = 'basedon';
const emailTemplateAccessor = 'emailTemplate';
const ticketTemplateContextAccessor = 'ticketTemplateContext';
const senderAddress = 'senderAddress';
const tempFileAttachements = 'tempFileAttachements';
const receivers = 'receivers';
const forceNoTemplate = 'forceNoTemplate';
const processedMailTemplateArgs = 'processedMailTemplateArgs';

export type EmailMaskCustomParams =
  | 'tab'
  | typeof templateIdAccessor
  | typeof actionModeAccessor
  | typeof basedon
  | typeof ticketTemplateContextAccessor
  | typeof emailTemplateAccessor
  | typeof senderAddress
  | typeof tempFileAttachements
  | typeof receivers
  | typeof forceNoTemplate
  | typeof processedMailTemplateArgs;

const prefixIfNeeded = (pfx: string, target?: string) => {
  if (target && !target.trim().startsWith(pfx)) {
    return pfx + target;
  }
  return target;
};

export const EmailOverlayController = (
  props: MaskControllerProps<EmailMaskCustomParams>
) => {
  const { onOpenMask } = props;

  const { emit: emitEntityEvent } = useEntityEventsContext();

  const { t } = useTranslation();
  const mask = useMaskConfig(props);
  const { params } = mask;
  const {
    basedon,
    senderAddress,
    tempFileAttachements,
    receivers,
    processedMailTemplateArgs,
    forceNoTemplate,
  } = params;

  const bt = useBinaryTransfer();

  const user = useUser();

  const { templateId: previousEmailId = null, mode: actionMode = null } =
    mask.params;

  const clickedOnSend = useRef(false);
  const [startJobSaveSendMail] = useMutation<{
    startJobSaveSendMail: SaveSendMailJob;
  }>(START_JOB_SAVE_SEND_MAIL);

  const [isTemplateInitialized, setTemplateInitialized] = useState(false);

  const mailboxesRequest = useMemo<DataRequest>(() => {
    const data: MailboxConfiguration = {
      id: null,
      smtpAddress: null,
    };

    return {
      entity: Entities.mailboxConfiguration,
      data,
      completeDataResponse: true,
      operationName: 'GetMailboxes',
    };
  }, []);

  const mailboxesResponse =
    useDataProvider<MailboxConfiguration>(mailboxesRequest);

  const fromMailbox = useMemo<MailboxConfiguration>(() => {
    let result;
    if (senderAddress) {
      result = mailboxesResponse.data?.find(
        (mb) => mb.smtpAddress === senderAddress
      );
    }
    if (!result) {
      result = mailboxesResponse.data?.find(
        (mb) => mb.smtpAddress === user.email
      );
    }
    return result;
  }, [mailboxesResponse.data, senderAddress, user.email]);

  const requestEMailData = useMemo<DataRequest>(() => {
    const filter = [{ id: { $eq: mask.id } }];
    const data = EMailRequestData;
    return {
      entity: Entities.eMail,
      data,
      filter,
      vars,
    };
  }, [mask.id]);

  const requestPreviousEMailData = useMemo<DataRequest>(() => {
    const filter = [{ id: { $eq: previousEmailId } }];
    const data = EMailRequestData;
    return {
      entity: Entities.eMail,
      data,
      filter,
      vars,
    };
  }, [previousEmailId]);

  const currentEMail = useDataProvider<EMail>(
    requestEMailData,
    mask.isCreateMode
  );

  const { saveSearchItemFromEnityData } = useSearchHistory();
  const [upsert] = useDataMutation<EMail, EMode.upsert, InputEMailRelation>({
    entity: mask.entity,
    mutationType: EMode.upsert,
    responseData: EMailRequestData as unknown as EMail<EMode.entity>,
    onCompleted: (data) => {
      if (mask.isCreateMode) {
        saveSearchItemFromEnityData(data);
      }
    },
  });

  const previousEMail = useDataProvider<EMail>(
    requestPreviousEMailData,
    previousEmailId === null
  );

  const template = useAssignableTemplateEntity(mask);
  const activeTicketContext: Ticket = useMemo(() => {
    if (params[ticketTemplateContextAccessor]) {
      return JSON.parse(params[ticketTemplateContextAccessor]);
    }
    return null;
  }, [params]);

  const newEntityData = useMemo(() => {
    if (!previousEmailId) {
      return createEmail({
        user: {
          benutzerCode: user.benutzerCode,
        },
        template,
        receivers,
      });
    } else {
      const previousEMailData = previousEMail?.data?.[0];
      const EMPTYVALUE = '';
      const from =
        mailboxesResponse.data.find(
          (mb) => mb.smtpAddress === previousEMailData.from
        )?.smtpAddress ?? user.email;

      const collectiveAttrs: EMail = {
        kind: EMailKind.ENTWURF_HTML,
        bcc: EMPTYVALUE,
        topicMarkList: previousEMailData?.topicMarkList || [],
        project: previousEMailData?.project ?? null,
        projectId: previousEMailData?.projectId || 0,
        businessPartner: previousEMailData?.businessPartner || null,
        businessPartnerId: previousEMailData?.businessPartnerId || 0,
        businessPartnerType:
          previousEMailData?.businessPartnerType || SdObjType.LIEFERANT,

        contact: previousEMailData?.contact || null,
        contactId: previousEMailData?.contactId || 0,
        userId: user.benutzerCode,
      };

      const filterOwnEmailAddress = (value: string) =>
        value
          ?.split(';')
          .map((x) => x.toLowerCase().trim())
          .filter((y) => y.toLowerCase() !== user.email.toLowerCase())
          .filter((y) => Boolean(y))
          .join('; ') || '';

      switch (actionMode) {
        case EMAILMODES.reply:
          return {
            ...collectiveAttrs,
            to: previousEMailData?.from,
            subject: prefixIfNeeded(
              t('MASK.AW') + ': ',
              previousEMailData?.subject
            ),
          };
        case EMAILMODES.replyAll:
          return {
            ...collectiveAttrs,
            to: [
              ...new Set(
                filterOwnEmailAddress(
                  (previousEMailData?.from?.concat('; ') || EMPTYVALUE).concat(
                    previousEMailData?.to || EMPTYVALUE
                  )
                ).split(';')
              ),
            ].join(';'),
            subject: prefixIfNeeded(
              t('MASK.AW') + ': ',
              previousEMailData?.subject
            ),
            cc: [
              ...new Set(
                filterOwnEmailAddress(previousEMailData?.cc).split(';')
              ),
            ].join(';'),
          };
        case EMAILMODES.forward:
          return {
            ...collectiveAttrs,
            subject: prefixIfNeeded(
              t('MASK.WG') + ': ',
              previousEMailData?.subject
            ),
            attachmentList: previousEMailData?.attachmentList,
          };
        case EMAILMODES.sendAgain:
          return {
            ...collectiveAttrs,
            from,
            to: previousEMailData?.to,
            subject: previousEMailData?.subject,
            cc: previousEMailData?.cc,
            attachmentList: previousEMailData?.attachmentList,
            priority: previousEMailData?.priority,
          };

        default:
          return {};
      }
    }

    // ToDo: RK check if this is correct, just disbaled to please linting
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    previousEmailId,
    user.email,
    user.benutzerCode,
    template,
    activeTicketContext?.title,
    previousEMail?.data,
    actionMode,
    t,
  ]);

  const dataRaw =
    (mask.isCreateMode ? newEntityData : currentEMail?.data?.[0]) ??
    newEntityData;

  const {
    customerApMailData,
    supplierApMailData,
    customerMailData,
    supplierMailData,
  } = useEmailContactData({ to: dataRaw?.to, cc: dataRaw?.cc });

  const data = useMemo<EmailMaskFormValue>(() => {
    const normalized = normalizeFormValue(dataRaw);
    return {
      ...normalized,
      toMailContactsInstance: parseMailContacts(
        normalized.toMailContacts,
        normalized.to
      ),
      ccMailContactsInstance: parseMailContacts(
        normalized.ccMailContacts,
        normalized.cc
      ),
      bccMailContactsInstance: parseMailContacts(
        normalized.bccMailContacts,
        normalized.bcc
      ),
      from: normalized.from ?? fromMailbox?.smtpAddress,
      intendedSenderAddress: senderAddress,
      project: normalized?.project ?? activeTicketContext?.project,
      projectId: normalized?.projectId ?? activeTicketContext?.projectId,
    };

    function parseMailContacts(
      contactString: string,
      legacyString: string
    ): MailSearchContactsResultItem[] | null {
      if (!contactString) {
        if (!legacyString) return [];
        return legacyString
          .split(';')
          .filter(Boolean)
          .filter((x) => x.trim().length > 0)
          .map((email): MailSearchContactsResultItem => {
            const customerAp = customerApMailData?.data?.find(
              (x) => x.eMail.toLowerCase().trim() === email.toLowerCase().trim()
            );

            const supplierAp = supplierApMailData?.data?.find(
              (x) => x.eMail.toLowerCase().trim() === email.toLowerCase().trim()
            );

            const customer = customerMailData?.data?.find(
              (x) => x.eMail.toLowerCase().trim() === email.toLowerCase().trim()
            );

            const supplier = supplierMailData?.data?.find(
              (x) => x.eMail.toLowerCase().trim() === email.toLowerCase().trim()
            );

            const id = customerAp
              ? `KundeAp_${customerAp.id}`
              : supplierAp
              ? `LieferantAp_${supplierAp.id}`
              : customer
              ? `Kunde_${customer.id}`
              : supplier
              ? `Lieferant_${supplier.id}`
              : `Email_${email.toLowerCase().trim()}`;

            return {
              id: id,
              mailAddress: email,
              type: customerAp
                ? ContactType.KUNDE_AP
                : supplierAp
                ? ContactType.LIEFERANT_AP
                : ContactType.BLANK_ADDRESS,
            };
          });
      }

      const parsedMailContacts: MailSearchContactsResultItem[] =
        JSON.parse(contactString);

      const uniqMailContacts: MailSearchContactsResultItem[] = [];
      parsedMailContacts.forEach((mail) => {
        const mailFound = uniqMailContacts.find(
          (_mail) => _mail.mailAddress === mail.mailAddress
        );

        if (mailFound) return;

        uniqMailContacts.push(mail);
      });

      return uniqMailContacts;
    }
  }, [
    dataRaw,
    fromMailbox?.smtpAddress,
    senderAddress,
    activeTicketContext?.project,
    activeTicketContext?.projectId,
    customerApMailData?.data,
    supplierApMailData?.data,
    customerMailData?.data,
    supplierMailData?.data,
  ]);

  const customRules = useCallback(
    (data: EmailMaskFormValue) => {
      if (!clickedOnSend.current) {
        return true;
      }
      if (!data.from) {
        return {
          from: {
            message: t('ERROR.NO_EMAIL_SENDER'),
            type: 'customValidation',
          },
        };
      }
      const recipient = data.to;
      if (recipient == null || recipient.length === 0) {
        return {
          to: {
            message: t('ERROR.MIN_ONE_RECIPIENT'),
            type: 'customValidation',
          },
        };
      }
      return true;
    },
    [t]
  );

  const schema = useEntityJsonSchema(mask.entity);
  const resolver = useJSONSchemaResolver(schema, customRules);

  const form = useForm<EmailMaskFormValue>({
    resolver,
    mode: 'onChange',
    defaultValues: data,
    shouldFocusError: false,
    context: {
      schema,
    },
  });

  useLock(
    {
      subEntityType: Entities.eMail,
      subEntityIds: data.id ? [data.id.toString()] : [],
    },
    [data]
  );

  const { handleSubmit, reset, formState, getValues, resetField } = form;

  useEffect(() => {
    if (!isTemplateInitialized) {
      reset(data);
    } else {
      Object.entries(data).forEach((x) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        resetField(x[0] as any, { defaultValue: x[1] });
      });
    }
  }, [reset, data, isTemplateInitialized, resetField]);

  useSyncEmailStatus({
    disabled: mask.isCreateMode,
    shouldSync({ id }) {
      // If it's the currently opened email and the send job is either in
      // progress or failed.
      return id === data.id && data.saveSendMailJob != null;
    },
  });

  const isDirty = Object.keys(formState.dirtyFields).length > 0;

  useConfirmBeforeCloseMask(isDirty);

  const updateContacts = (
    update: 'to' | 'cc' | 'bcc',
    contacts: MailSearchContactsResultItem[]
  ) => {
    const validContacts = contacts?.filter(
      (x) => x.mailAddress?.trim().length > 0
    );

    const shouldUpdate = validContacts?.length > 0;
    const newValue = validContacts.map((el) => el.mailAddress).join(';');
    const newValueContacts = JSON.stringify(validContacts);

    return {
      [update]: shouldUpdate ? newValue : '',
      [`${update}MailContacts`]: shouldUpdate ? newValueContacts : '',
    };
  };

  useFormUpdate(
    {
      'businessPartner.data': (businessPartner: Customer | Supplier) => {
        const businessPartnerId = businessPartner?.id ?? 0;
        const businessPartnerType = getBusinessPartnerType(businessPartner);
        const contact = businessPartner?.mainContact ?? getValues('contact');

        return { businessPartnerId, businessPartnerType, contact };
      },
      contact: (contact: Contact) => {
        const contactId = contact?.id ?? 0;
        return { contactId };
      },
      toMailContactsInstance: (contacts: MailSearchContactsResultItem[]) => {
        return updateContacts('to', contacts);
      },
      ccMailContactsInstance: (contacts: MailSearchContactsResultItem[]) => {
        return updateContacts('cc', contacts);
      },
      bccMailContactsInstance: (contacts: MailSearchContactsResultItem[]) => {
        return updateContacts('bcc', contacts);
      },
      project: (project: Project) => {
        const projectId = project?.id ?? 0;
        const businessPartnerField = getValues('businessPartner.data');

        if (businessPartnerField !== null) {
          return { projectId };
        } else {
          const businessPartner =
            project?.customer ?? project?.supplier ?? null;

          return {
            projectId,
            'businessPartner.data': businessPartner,
          };
        }
      },
      signature: (value: EMailSignature) => {
        return {
          signatureId: value?.id || EMPTY_UID,
        };
      },
    },
    form
  );

  const entityName = t(`COMMON.${mask.entity.toUpperCase()}`);

  usePageTitle(formatPageTitle([entityName, data?.subject]), {
    priority: 1,
  });

  const isDraft = useMemo(() => {
    return (
      data?.kind === EMailKind.ENTWURF || data?.kind === EMailKind.ENTWURF_HTML
    );
  }, [data?.kind]);

  const handleDeleteEntitiesClick = useStandardDeleteEntityHandler(mask);

  // All existing attachments should be copied when using "Send again" or
  // "Forward".
  const [shouldCopyAttachments, setShouldCopyAttachments] = useState(
    actionMode === EMAILMODES.sendAgain || actionMode === EMAILMODES.forward
  );

  const cleanedPersistantAttachmentList = useMemo(() => {
    if (shouldCopyAttachments) return [];

    return [
      ...(data?.attachmentList?.map((x) => ({
        ...x,
        fileName: x.displayFilename,
        __typename: undefined,
      })) || []),
    ];
  }, [data?.attachmentList, shouldCopyAttachments]);

  const tempFileManager = useTempFileManager(cleanedPersistantAttachmentList, {
    maxAttachmentTotalSize: 50 * 1024 * 1024,
  });

  const currentTopicMarkList = useWatch({
    name: 'topicMarkList',
    control: form.control,
  });

  useEffect(() => {
    // The mask can only be initialized once. If init is not null, we don't need
    // to do anything.

    if (basedon === 'transfer' && bt.transferData) {
      const files = bt.transferData;

      if (!(files?.length > 0)) {
        console.warn('No files were found in the BinaryTransfer');
        return;
      }

      tempFileManager.resetChanges();
      tempFileManager.uploadFiles(files);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Copy existing attachments.
  const { convertAttachmentsEntity, convertAttachmentsIds } = useMemo(() => {
    if (
      data?.attachmentList &&
      data.attachmentList.length > 0 &&
      shouldCopyAttachments
    ) {
      return {
        convertAttachmentsEntity: Entities.eMail,
        convertAttachmentsIds: data.attachmentList.map((attachment) =>
          attachment.id.toString()
        ),
      };
    }

    if (template?.entity === Entities.document && template?.data) {
      const childDocuments =
        template?.data?.childDocuments?.map((doc) => doc.id.toString()) ?? [];

      return {
        convertAttachmentsEntity: Entities.document,
        convertAttachmentsIds: [template.data.id.toString(), ...childDocuments],
      };
    }

    if (template?.entity === Entities.letter && template?.data) {
      return {
        convertAttachmentsEntity: Entities.letter,
        convertAttachmentsIds: [template.data.id.toString()],
      };
    }

    return { convertAttachmentsEntity: null, convertAttachmentsIds: [] };
  }, [data?.attachmentList, shouldCopyAttachments, template]);

  const convertedAttachments = useConvertAttachments(
    convertAttachmentsEntity,
    convertAttachmentsIds,
    !shouldCopyAttachments
  );

  useEffect(() => {
    const forwardedTempFiles = tempFileAttachements
      ? (JSON.parse(tempFileAttachements) as IAttachmentEntity[])
      : [];
    if (forwardedTempFiles.length)
      tempFileManager.setTemporaryFileUploads(forwardedTempFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempFileAttachements]);

  useEffect(() => {
    const forwardedTempFiles = tempFileAttachements
      ? (JSON.parse(tempFileAttachements) as IAttachmentEntity[])
      : [];
    if (forwardedTempFiles.length)
      tempFileManager.setTemporaryFileUploads([
        ...forwardedTempFiles,
        ...convertedAttachments,
      ]);
    else tempFileManager.setTemporaryFileUploads(convertedAttachments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convertedAttachments]);

  const relations = useMemo(() => {
    //email attachments can not be updated (e.g. renamed) just be added or removed
    let reassignedAttachemnts = [];
    if (mask.isCreateMode && shouldCopyAttachments) {
      /**
       * in case of forward email attachments are "copied" too and need to result in another relation param
       * therefore find the ones that are acutial email attachments and not tempfiles already
       */
      reassignedAttachemnts = tempFileManager.fileList?.filter((el) => {
        return el.__typename === 'EMailAnhang';
      });
    }

    const hasAttachmentToAdd =
      tempFileManager.temporaryFileUploads.length > 0 ||
      reassignedAttachemnts.length > 0;
    const hasAttachmentToRemove = tempFileManager.fileIdsToDelete.length > 0;

    const attachements: InputEMailAnhangAttachementsRelation = {
      add: hasAttachmentToAdd
        ? [
            ...tempFileManager.temporaryFileUploads.map((x) => ({
              tempFileId: x.id as string,
              name: x.fileName,
            })),
            ...reassignedAttachemnts.map((x) => ({
              eMailAttachementCode: x.id,
            })),
          ]
        : undefined,
      remove: hasAttachmentToRemove
        ? (tempFileManager.fileIdsToDelete as number[])
        : undefined,
    };
    const topic: InputTopicMarkRelation = {
      set: currentTopicMarkList ? currentTopicMarkList[0]?.id : 0,
    };

    const result = { attachements, topic };
    return result;
  }, [
    currentTopicMarkList,
    mask.isCreateMode,
    shouldCopyAttachments,
    tempFileManager.fileIdsToDelete,
    tempFileManager.fileList,
    tempFileManager.temporaryFileUploads,
  ]);

  const afterSaveOrSend = useCallback(async () => {
    props.onAfterSave?.(data);
  }, [data, props]);

  const onSubmit = async (_data: EmailMaskFormValue) => {
    const data = {
      ..._data,
    };

    data.date = new Date().toISOString();

    if (mask.isCreateMode && activeTicketContext) {
      //enhance email body with hidden fields to keep ticket context
      //todo should we send the context of all template based emails? if so activeTicketContext > template
      data.bodyHtml += `
      <div style="display:none;" id="w4a-ticket${user.kundennummer}|${
        activeTicketContext.id
      }">&nbsp;</div>
      ${
        activeTicketContext.project &&
        activeTicketContext.project.id !== undefined
          ? `<div style="display:none;" id="w4a-project${user.kundennummer}|${activeTicketContext.project?.id}">&nbsp;</div>`
          : ''
      }
      `;
    }

    const update = mask.isCreateMode
      ? data
      : pick(data, ['id', ...Object.keys(formState.dirtyFields)]);

    if (!update.userId) {
      update.userId = user.benutzerCode;
    }

    if (update.bodyHtml != null) {
      update.bodyHtml = removeHtmlComments(update.bodyHtml);
      update.bodyHtml = htmlParser.applyInlineStyles(update.bodyHtml);
    }
    const saved = await upsert(update, relations ? { relations } : undefined);

    if (!saved) {
      throw new Error('upsertEmail failed');
    }

    if (clickedOnSend.current) {
      startJobSaveSendMail({
        variables: {
          mail: { code: saved.id },
          senderMailAddress: data?.from,
          jobAction: SaveSendMailJobMode.SAVE_AND_SEND,
          attachToEntityType: activeTicketContext?.__typename,
          attachToEntityPK: activeTicketContext?.id,
        },
      });
    }

    emitEntityEvent({
      type: 'upsert',
      entity: Entities.eMail,
      data: undefined,
    });

    afterSaveOrSend();
  };

  const entityRights = useMemo(
    () => ({
      create: canAddEmail(user),
      read: false, //todo needed at all?
      update: canEditEmail(user, data as unknown as EMail),
      delete: canDeleteEmail(user, data as unknown as EMail),
    }),
    [data, user]
  );

  const commonActions = (
    <>
      <MaskOverlayFullscreenToggleButton />

      {mask.isEditMode && (
        <MaskOverlayMenuWrapper>
          <MaskOverlayDeleteMenuItem
            disabled={mask.wip || !entityRights.delete}
            onClick={handleDeleteEntitiesClick}
          />
        </MaskOverlayMenuWrapper>
      )}
    </>
  );

  const maskContext = useMaskContextValue({ ...mask, data });

  const draftDisabled =
    !entityRights.create || //user cant create a draft
    !entityRights.update ||
    mask.wip; //user cant update this draft (may not be their own)
  const draftDisabledReason = draftDisabled ? t('RIGHTS.MISSING') : undefined;

  const draftActions = (
    <>
      <AttachmentsUploadButton
        disabled={draftDisabled}
        disabledReason={draftDisabledReason}
      />

      <MaskActionButton
        type="submit"
        labelVisibility={LABEL_VISIBILITY.HIDE}
        label={t('MASK.SAVE_AS_DRAFT')}
        icon={<SaveIcon />}
        color="primary"
        onClick={() => (clickedOnSend.current = false)}
        disabledReason={draftDisabledReason}
        disabled={
          draftDisabled ||
          formState.isSubmitting ||
          tempFileManager.currentUploadingFiles > 0 ||
          (!formState.isDirty && !tempFileManager.fileListDirty)
        }
      />

      <MaskActionButton
        labelVisibility={LABEL_VISIBILITY.SHOW}
        disabledReason={draftDisabledReason}
        type="submit"
        variant="contained"
        color="primary"
        label={t('MASK.SEND')}
        icon={<SendIcon />}
        onClick={() => {
          clickedOnSend.current = true;
        }}
        disabled={
          draftDisabled ||
          formState.isSubmitting ||
          tempFileManager.currentUploadingFiles > 0 ||
          (data.kind !== EMailKind.ENTWURF &&
            data.kind !== EMailKind.ENTWURF_HTML &&
            !formState.isDirty) ||
          (!formState.isValid && formState.isSubmitted)
        }
      />

      {commonActions}
    </>
  );

  const editActions = (
    <>
      {!formState.isSubmitting && (
        <EmailActions
          hasRight={entityRights.create}
          onOpenMask={onOpenMask}
          id={data?.id}
          outbound={data?.kind === 'AUSGEHEND' ? true : false}
        />
      )}

      <MaskOverlaySubmitButton
        disableReason={!entityRights.update ? t('RIGHTS.MISSING') : undefined}
        disabled={
          !entityRights.update ||
          mask.wip ||
          formState.isSubmitting ||
          !formState.isDirty ||
          !formState.isValid
        }
      />

      {commonActions}
    </>
  );

  const emailTemplaterDefaults = useMemo(() => {
    let additionalTemplateVariables = processedMailTemplateArgs
      ? JSON.parse(processedMailTemplateArgs)
      : undefined;

    if (basedon !== 'EmailTemplate') return { ...additionalTemplateVariables };

    if (activeTicketContext) {
      additionalTemplateVariables = {
        ticketId: activeTicketContext?.id,
        ...additionalTemplateVariables,
      };
    }

    return {
      additionalTemplateVariables,
      initialTemplate: params?.[emailTemplateAccessor]
        ? JSON.parse(params[emailTemplateAccessor])
        : null,
      initialSignature: params?.[emailTemplateAccessor]
        ? (JSON.parse(params[emailTemplateAccessor]) as EMailTemplate)
            ?.signature
        : null,
    };

    //newEntityData previousEMail need to be a dependencies to avoid occasional empty bodyHtml
  }, [activeTicketContext, basedon, processedMailTemplateArgs, params]);

  const preloadDone = !(
    currentEMail.pending ||
    customerApMailData.pending ||
    customerMailData.pending ||
    supplierApMailData.pending ||
    supplierMailData.pending ||
    previousEMail.pending ||
    template.pending
  );

  const [isCopyAttachmentsDialogOpen, setIsCopyAttachmentsDialogOpen] =
    useState(false);

  useEffect(() => {
    setIsCopyAttachmentsDialogOpen(
      convertAttachmentsEntity === Entities.document ||
        convertAttachmentsEntity === Entities.letter
    );
  }, [convertAttachmentsEntity]);

  return (
    <MaskContextProvider value={maskContext}>
      <EntityRightsContext.Provider value={entityRights}>
        <FormProvider {...form}>
          <TempFileManagerContext.Provider value={tempFileManager}>
            <EmailTemplater
              forceNoTemplate={forceNoTemplate === 'true'}
              disabled={!isDraft && !mask.isCreateMode}
              isCreateMode={mask.isCreateMode}
              isDraftMode={isDraft}
              eMailMode={actionMode}
              previousEMail={previousEMail?.data?.[0]}
              preloadDone={preloadDone}
              onInitialized={() => setTemplateInitialized(true)}
              {...emailTemplaterDefaults}
            >
              <MaskTabContext defaultValue={mask.params?.tab ?? 'general'}>
                <Form
                  className={styles.maskForm}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <MaskOverlayHeader
                    title={entityName}
                    actions={isDraft ? draftActions : editActions}
                    tabs={<EmailTabs isCreateMode={mask.isCreateMode} />}
                  />

                  <CopyAttachmentsDialog
                    open={isCopyAttachmentsDialogOpen}
                    onConfirm={() => {
                      setShouldCopyAttachments(true);
                      setIsCopyAttachmentsDialogOpen(false);
                    }}
                    onCancel={() => {
                      setIsCopyAttachmentsDialogOpen(false);
                    }}
                  />

                  {data.saveSendMailJob != null && (
                    <EmailStatus
                      kind={data.kind}
                      saveSendMailJob={data.saveSendMailJob}
                      options={{
                        iconOnly: false,
                        errorAction: () => {
                          clickedOnSend.current = true;
                          handleSubmit(onSubmit)();
                        },
                      }}
                    />
                  )}

                  <Content />
                </Form>
              </MaskTabContext>
            </EmailTemplater>
          </TempFileManagerContext.Provider>
        </FormProvider>
      </EntityRightsContext.Provider>
    </MaskContextProvider>
  );
};

const EmailTabs = memo(function EmailTabs({
  isCreateMode,
}: {
  isCreateMode: boolean;
}) {
  const { t } = useTranslation();

  return (
    <MaskTabs>
      <MaskTab value="general" label={t('MASK.GENERAL')}></MaskTab>

      <MaskTab
        value="history"
        label={t('MASK.HISTORY')}
        disabled={isCreateMode}
      ></MaskTab>
    </MaskTabs>
  );
});

const Content = memo(function EmailTabPanels() {
  return (
    <MaskContent>
      <MaskTabPanel value="general">
        <GeneralTabPanel />
      </MaskTabPanel>

      <MaskTabPanel value="history">
        <HistoryTabPanel />
      </MaskTabPanel>
    </MaskContent>
  );
});

type ICreateEmailOptions = {
  template: AssignableEntityResult;
  user: Pick<IUser, 'email' | 'benutzerCode'>;
  bodyHtml?: string;
  subject?: string;
  receivers?: string;
};

function createEmail(params: ICreateEmailOptions): EMail {
  const { template, user, subject = '', receivers } = params;
  const email: EMail = {
    userId: user.benutzerCode,
    kind: EMailKind.ENTWURF_HTML,
    topicMarkList: null,
    subject,
    attachmentList: [],
    businessPartner: null,
    businessPartnerType: SdObjType.LIEFERANT,
    businessPartnerId: 0,
    contact: null,
    contactId: 0,
    project: null,
    projectId: 0,
    to: '',
    toMailContacts: receivers ? receivers : '',
    bcc: '',
    bccMailContacts: '',
    cc: '',
    ccMailContacts: '',
    bodyHtml: '',
  };

  if (template.enabled) {
    if (!template.data) {
      return null;
    }

    const data: EMail = {
      ...email,
      businessPartner: {
        id: template.data.businessPartner?.data?.id ?? 0,
        data: template.data.businessPartner?.data
          ? { ...template.data.businessPartner?.data, mainContact: null }
          : null,
      },
      businessPartnerId: template.data.businessPartner?.data?.id ?? 0,
      businessPartnerType:
        template.data.businessPartner?.businessPartnerType ??
        template.data.businessPartnerType ??
        SdObjType.LIEFERANT,
      contact: template.data.contact,
      contactId: template.data.contact?.id ?? 0,
      project: template.data.project,
      projectId: template.data.project?.id ?? 0,
      to: template.data.contact?.eMail ?? null,
      topicMarkList: template.data.topicMarkList,
    };

    const toMailContacts = calculateToMailContacts(template);
    if (toMailContacts) {
      if (receivers) {
        data.toMailContacts = JSON.stringify([
          ...JSON.parse(toMailContacts),
          ...JSON.parse(receivers),
        ]);
      } else data.toMailContacts = toMailContacts;
    } else if (receivers) data.toMailContacts = receivers;

    return data;
  }

  return email;
}

function getBusinessPartnerType(
  businessPartner: Customer | Supplier
): SdObjType {
  if (businessPartner) {
    const typename: string = businessPartner.__typename;

    if (typename === 'Kunde') {
      return SdObjType.KUNDE;
    }
  }

  return SdObjType.LIEFERANT;
}
