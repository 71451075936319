import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { PriceGroup } from '@work4all/models/lib/Classes/PriceGroup.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ITableSchema } from '@work4all/models/lib/table-schema/table-schema';

const priceGroupRequest = {
  entity: Entities.priceGroup,
  data: {
    id: null,
    name: null,
  } as PriceGroup,
  completeDataResponse: true,
};

export const useTransformedArticleTableSchema = (props: { schema }) => {
  const { schema } = props;

  const priceGroups = useDataProvider<PriceGroup>(priceGroupRequest);

  const transformedSchema = useMemo(() => {
    return {
      ...schema,
      entity: schema.entity as Entities,
      columns: [
        ...schema.columns,
        ...(priceGroups.data?.map((pG) => {
          return {
            id: 'priceGroup-' + pG.id,
            title: pG.name,
            accessor: 'id',
            sortable: false,
            filterable: false,
            defaultHidden: true,
            quickSearchable: false,
            disableFooterSum: true,
            width: 100,
            cell: {
              type: 'ArticleSinglePrice',
              params: {
                hideUnit: true,
                priceGroupId: pG.id,
              },
            },
            path: ['FILTER.groups.sale'],
          };
        }) || []),
      ],
    };
  }, [priceGroups.data, schema]);

  return transformedSchema as ITableSchema<never>;
};
