import styles from './DebugInfoDialog.module.scss';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Box, IconButton, Tooltip } from '@mui/material';
import Button from '@mui/material/Button';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CopyButton } from '@work4all/components/lib/components/CopyButton/CopyButton';
import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';
import {
  Dialog,
  DialogActions,
  DialogContent,
} from '@work4all/components/lib/dialog';
import { CheckboxRadioItem } from '@work4all/components/lib/input/checkbox-radio-item';

import { useUser } from '@work4all/data';

import {
  DebugInfoPart,
  getDebugInfoParts,
} from '@work4all/utils/lib/getDebugInfoParts';

import { WORK4ALL_CUSTOMER_ID } from '../../../../config';
import { useApiVersionContext } from '../../../../containers/app-updates/api-version-context';
import { settings, useSetting } from '../../../../settings';

export interface DebugInfoDialogProps {
  open: boolean;
  onClose: () => void;
}

export function DebugInfoDialog(props: DebugInfoDialogProps) {
  const { open, onClose } = props;

  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      closeButton={false}
      title={t('DEBUG_INFO.TITLE')}
    >
      <DebugInfoDialogContent onClose={onClose} />
    </Dialog>
  );
}

function DebugInfoDialogContent({ onClose }: { onClose: () => void }) {
  const { t } = useTranslation();

  const user = useUser();

  const customerNumber = user.kundennummer;

  const title = t('DEBUG_INFO.TITLE');

  const { version } = useApiVersionContext();
  const info = useMemo<DebugInfoPart[]>(() => {
    return getDebugInfoParts({
      customerNumber,
      apiVersion: version,
      baseUrl: user.baseUrl,
    });
  }, [customerNumber, user.baseUrl, version]);

  const infoTranslated = useMemo<DebugInfoPartTranslated[]>(() => {
    return info.map((part) => {
      const key = `DEBUG_INFO.KEYS.${part.key}`;
      return { ...part, name: t(key) };
    });
  }, [info, t]);

  const infoAsString = useMemo<string>(() => {
    let result = `${title}\n\n`;

    for (const part of infoTranslated) {
      result += `${part.name}: ${part.value}\n`;
    }

    return result;
  }, [title, infoTranslated]);

  const [versionClickCounter, setVersionClickCounter] = useState(0);
  const showBatchToggle = versionClickCounter >= 5;

  const batchModeEnabled = !window.dev.batchModeDisabled;

  const handleOnVersionClick = useCallback(() => {
    setVersionClickCounter(versionClickCounter + 1);
  }, [versionClickCounter]);

  return (
    <>
      <DialogContent>
        <div className={styles.grid}>
          {infoTranslated.map((part) => {
            return (
              <Fragment key={part.key}>
                <div
                  className={styles.name}
                  onClick={
                    part.key === 'version' ? handleOnVersionClick : undefined
                  }
                >
                  {part.name}
                </div>
                <div
                  className={styles.value}
                  onClick={
                    part.key === 'version' ? handleOnVersionClick : undefined
                  }
                >
                  {part.valueShort ?? part.value}

                  {part.canCopy && (
                    <CopyButton value={part.value}>
                      {({ copy, copied }) => {
                        return (
                          <Tooltip
                            open={copied}
                            title={t('COMMON.COPIED')}
                            placement="top"
                          >
                            <IconButton
                              size="small"
                              sx={{ p: 0, ml: 1 }}
                              onClick={copy}
                            >
                              <ContentCopyIcon fontSize={'inherit'} />
                            </IconButton>
                          </Tooltip>
                        );
                      }}
                    </CopyButton>
                  )}
                </div>
              </Fragment>
            );
          })}
        </div>

        {(showBatchToggle || !batchModeEnabled) && (
          <Box paddingBottom="1rem">
            <Divider />
            <CheckboxRadioItem
              label={t('BATCH_MODE_TOGGLE.LABEL')}
              defaultChecked={batchModeEnabled}
              onChange={() => window.dev.toggleBatchMode()}
            />
          </Box>
        )}
        <DemoModeButton />
      </DialogContent>

      <DialogActions>
        <Button size="large" color="primary" onClick={onClose}>
          {t('ALERTS.CLOSE')}
        </Button>

        <CopyButton value={infoAsString}>
          {({ copy, copied }) => {
            return (
              <Button size="large" color="primary" onClick={copy}>
                {copied ? t('COMMON.COPIED') : t('COMMON.COPY')}
              </Button>
            );
          }}
        </CopyButton>
      </DialogActions>
    </>
  );
}

type DebugInfoPartTranslated = DebugInfoPart & {
  name: string;
};

const DemoModeButton = () => {
  const { t } = useTranslation();
  const user = useUser();

  const { value, set } = useSetting(settings.isInDemoMode());

  if (user.kundennummer !== WORK4ALL_CUSTOMER_ID) return null;

  return (
    <Button
      size="small"
      color="primary"
      className={styles.demoModeButton}
      onClick={() => {
        set(!value);
      }}
    >
      {value
        ? t('DEBUG_INFO.DEMO_MODE_DISABLE')
        : t('DEBUG_INFO.DEMO_MODE_ENABLE')}
    </Button>
  );
};
