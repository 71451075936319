import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IconButton, Tab as MuiTab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { TAB_LABEL } from '../constants';

interface HeaderProps {
  selectedTab: Entities;
  onTabChange: (value: Entities) => void;
  isCollapsed: boolean;
  onCollapsedChange: (value: boolean) => void;
  tabs: Entities[];
}

export const Header = ({
  isCollapsed,
  onCollapsedChange,
  selectedTab,
  onTabChange,
  tabs,
}: HeaderProps) => {
  const { t } = useTranslation();

  return (
    <div style={{ display: 'flex', padding: '12px' }}>
      <IconButton
        onClick={() => onCollapsedChange(!isCollapsed)}
        style={{ marginRight: '12px' }}
      >
        {isCollapsed ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </IconButton>

      <Tabs
        value={selectedTab}
        onChange={(_, value) => {
          onTabChange(value);
        }}
      >
        {tabs.map((tab, index) => {
          const label = TAB_LABEL[tab];

          if (!label) {
            throw new Error("The tab label isn't defined");
          }

          return <MuiTab key={index} value={tab} label={t(label)} />;
        })}
      </Tabs>
    </div>
  );
};
