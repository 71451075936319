import { IconButton } from '@mui/material';
import React, { useState } from 'react';

import { ReactComponent as SettingsIcon } from '@work4all/assets/icons/settings3.svg';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { TableSettingsOverlay } from '../../../../../../../../../../components/data-tables/table/components/toolbar/components/TableSettingsOverlay';

export const PositionsSettings = () => {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <IconButton color="primary" onClick={() => setOpen(true)}>
        <SettingsIcon />
      </IconButton>
      <TableSettingsOverlay
        settingsKey={'ERP'}
        entityType={Entities.position}
        isOpen={open}
        close={() => setOpen(false)}
      />
    </React.Fragment>
  );
};
