import { gql, useQuery } from '@apollo/client';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { useMemo, useRef } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@work4all/components/lib/components/tooltip/Tooltip';

import { useDataProvider } from '@work4all/data';

import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { replaceSpecialCharacters, replaceUmlauts } from '@work4all/utils';
import { reactRefSetter } from '@work4all/utils';

import { Autocomplete } from '../../containers/mask-overlays/locked-inputs';

const CssTextField = styled(TextField)((props) => ({
  '& .MuiFilledInput-root': {
    paddingTop: '1rem',
    paddingBottom: '0.25rem !important',
    borderRadius: '0.25rem',
    paddingLeft: '0.25rem',
    backgroundColor: 'var(--ui02)',
    border: '1px solid var(--ui04)',
    borderLeft:
      props.color === 'warning' ? '0.25rem solid var(--brand02)' : undefined,
  },
  '& .MuiFilledInput-root:before, .MuiFilledInput-root:after': {
    display: 'none',
  },
  '& .MuiInputLabel-root': {
    transform: 'translate(7px, 11px) scale(1)',
  },
  '& .Mui-focused.MuiInputLabel-root, .MuiFormLabel-filled.MuiInputLabel-root':
    {
      transform: 'translate(7px, 4px) scale(0.75)',
      fontSize: '0.85rem',
      fontWeight: '500',
    },
}));

const getEmailVariants = (
  firstName: string,
  lastName: string,
  domain: string
) => {
  const fn = firstName
    ? replaceSpecialCharacters(replaceUmlauts(firstName.toLowerCase()))
    : '';
  const ln = lastName
    ? replaceSpecialCharacters(replaceUmlauts(lastName.toLowerCase()))
    : '';
  const result = [];

  if (fn) {
    result.push(fn + '@' + domain);
  }
  if (fn && ln) {
    result.push(fn?.charAt(0) + '.' + ln + '@' + domain);
    result.push(fn + '.' + ln + '@' + domain);
    result.push(fn?.charAt(0) + ln + '@' + domain);
    result.push(fn?.charAt(0) + ln?.charAt(0) + '@' + domain);
  }
  if (ln) {
    result.push(ln + '@' + domain);
  }

  return result;
};

export interface EMailSuggestionPickerFieldProps {
  value: string | null;
  businesspartnerType?: SdObjType;
  onChange: (value: string) => void;
  businessPartnerId?: number;
  firstName: string;
  lastName: string;
  color?: 'error' | 'primary' | 'secondary' | 'info' | 'success' | 'warning';
}

export const EMailSuggestionPickerField = React.forwardRef<
  HTMLDivElement,
  EMailSuggestionPickerFieldProps
>(function EMailSuggestionPickerField(props, ref) {
  const {
    value,
    onChange,
    businesspartnerType,
    businessPartnerId,
    firstName: firstname,
    lastName: lastname,
    color,
  } = props;

  const { t } = useTranslation();

  const { options } = useEMailSuggestions({
    businesspartnerType,
    businessPartnerId,
    firstname,
    lastname,
  });

  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <Tooltip
      title={t('EMAIL_SUGGESTION.TOOLTIP')}
      disableHoverListener={color !== 'warning'}
      placement="top"
    >
      <div ref={reactRefSetter(wrapperRef, ref)}>
        <Autocomplete
          freeSolo
          ListboxProps={{ style: { maxHeight: '20rem' } }}
          getOptionLabel={(option) => option}
          options={options}
          onInputChange={(e, value) => {
            onChange(value);
          }}
          inputValue={value || ''}
          defaultValue={value}
          renderInput={(params) => {
            return (
              <CssTextField
                {...params}
                label={t('COMMON.EMAIL')}
                variant="filled"
                size="small"
                color={color}
              />
            );
          }}
          onChange={(e, value) => {
            onChange(value);
          }}
          renderOption={(props, option) => {
            return (
              <li {...props} style={{ textAlign: 'left' }}>
                <div>{option}</div>
              </li>
            );
          }}
        />
      </div>
    </Tooltip>
  );
});

const GET_EMAIL_SUGGESTIONS = gql`
  query suggestContactEMailAddress(
    $businesspartnerType: SdObjType
    $businessPartnerId: Int
    $firstname: String
    $lastname: String
  ) {
    suggestContactEMailAddress(
      sdObjType: $businesspartnerType
      sdObjMemberCode: $businessPartnerId
      firstname: $firstname
      lastname: $lastname
    )
  }
`;

export function useEMailSuggestions(variables: {
  businesspartnerType?: SdObjType;
  businessPartnerId?: number;
  firstname?: string;
  lastname?: string;
  skip?: boolean;
}): {
  hasDistinctEmailSuggestion: boolean;
  options: string[];
  distinctEmailSuggestion: string;
} {
  const {
    firstname,
    lastname,
    businesspartnerType: sdObjType,
    businessPartnerId: sdObjMemberCode,
    skip,
  } = variables;

  const request = useMemo(() => {
    const data: Customer | Supplier = {
      id: null,
      eMail: null,
      contactList: [
        {
          id: null,
          eMail: null,
        },
      ],
    };

    return {
      entity:
        sdObjType === SdObjType.KUNDE ? Entities.customer : Entities.supplier,
      data,
      filter: [{ id: { $eq: sdObjMemberCode } }],
    };
  }, [sdObjMemberCode, sdObjType]);

  const {
    data: [businessPartner],
  } = useDataProvider<Customer | Supplier>(request, skip);

  const transformedVariables = {
    ...(variables || {}),
    firstname: variables?.firstname
      ? replaceUmlauts(replaceSpecialCharacters(variables?.firstname?.trim()))
      : '',
    lastname: variables?.lastname
      ? replaceUmlauts(replaceSpecialCharacters(variables?.lastname?.trim()))
      : '',
  };

  const apiGeneratedSuggestion = useQuery(GET_EMAIL_SUGGESTIONS, {
    variables: transformedVariables,
    skip: skip || !businessPartner?.contactList?.find((x) => x.eMail !== null),
  });

  const options: string[] = useMemo(() => {
    const domain = businessPartner?.eMail?.split('@')?.[1];
    const defaultOptions = domain
      ? getEmailVariants(firstname, lastname, domain)
      : [];

    const suggestedAddresses = apiGeneratedSuggestion?.data
      ?.suggestContactEMailAddress
      ? [
          replaceUmlauts(
            apiGeneratedSuggestion?.data?.suggestContactEMailAddress
          ),
        ]
      : [];

    return [...new Set([...suggestedAddresses, ...defaultOptions])];
  }, [
    firstname,
    lastname,
    businessPartner,
    apiGeneratedSuggestion?.data?.suggestContactEMailAddress,
  ]);

  return {
    options,
    hasDistinctEmailSuggestion:
      apiGeneratedSuggestion?.data?.suggestContactEMailAddress !== undefined,
    distinctEmailSuggestion:
      apiGeneratedSuggestion?.data?.suggestContactEMailAddress &&
      replaceUmlauts(apiGeneratedSuggestion?.data?.suggestContactEMailAddress),
  };
}
