import styles from './ControlViewBottom.module.scss';

import React from 'react';

export interface ControlViewLeftProps {
  children: React.ReactNode;
}

export const ControlViewBottom: React.FC<ControlViewLeftProps> = ({
  children,
}) => {
  return <div className={styles.control}>{children}</div>;
};
