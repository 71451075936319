import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { BzObjType } from '../Enums/BzObjType.enum';
import { EMode } from '../Enums/EMode.enum';

export class BzObjectReleaseInformation<T extends EMode = EMode.entity> {
  /** Alias: bzObjType */
  bzObjType?: BzObjType;
  /** Alias: bzObjMemberCode */
  bzObjMemberCode?: number;
  /** Alias: releaseNeeded */
  releaseNeeded?: boolean;

  __typename?: string;
}

const fields: FieldDefinitions<BzObjectReleaseInformation> = {
  bzObjType: {
    alias: 'bzObjType',
  },
  bzObjMemberCode: {
    alias: 'bzObjMemberCode',
  },
  releaseNeeded: {
    alias: 'releaseNeeded',
  },
  __typename: {
    alias: '__typename',
  },
};

export const bzObjectReleaseInformationEntityDefinition: EntitiyDefinition<BzObjectReleaseInformation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'BzObjectReleaseInformation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
