import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BeforeContentElement as BeforeContentElementComp } from '../../components/before-content-element/BeforeContentElement';
import { CircularProgress as CircularProgressComp } from '../../components/circular-progress/CircularProgress';
import { UsePreviewInputHelpersProps } from '../../types';

export const usePreviewInputHelpers = <TValue,>({
  labelTranslationKey,
  isLoading,
  activeInputAccessor,
  accessor,
  previewEntity,
  inputEntity,
  value,
}: UsePreviewInputHelpersProps<TValue>) => {
  const { t } = useTranslation();

  const isActive = useMemo(
    () => activeInputAccessor === accessor,
    [accessor, activeInputAccessor]
  );

  const lable = useMemo(() => {
    if (!labelTranslationKey) {
      return '';
    }

    return t(labelTranslationKey);
  }, [labelTranslationKey, t]);

  const CircularProgress = useMemo(() => {
    return () => (
      <CircularProgressComp isLoading={isLoading} isActive={isActive} />
    );
  }, [isActive, isLoading]);

  const BeforeContentElement = useMemo(() => {
    return () => (
      <BeforeContentElementComp
        previewEntity={previewEntity}
        inputEntity={inputEntity}
        accessor={accessor}
        value={value}
      />
    );
  }, [accessor, inputEntity, previewEntity, value]);

  return { lable, isActive, CircularProgress, BeforeContentElement };
};
