import styles from './AppointmentPopup.module.scss';

import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  Box,
  Dialog,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { OpenFileIconButton } from '@work4all/components/lib/components/entity-preview/components';
import { useContactActions } from '@work4all/components/lib/hooks/use-contact-actions';

import {
  prefixToWithTenant,
  useCloneEntityMutation,
  useUser,
} from '@work4all/data';
import { useTenant } from '@work4all/data/lib/hooks/routing/TenantProvider';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { AppointmentAttendee } from '@work4all/models/lib/Classes/AppointmentAttendee.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import {
  DatePeriodPresets,
  formatDateString,
} from '@work4all/utils/lib/date-utils/formatDateString';
import { filterAttendeesList } from '@work4all/utils/lib/filterAttendeesList';
import { canAddAppointment } from '@work4all/utils/lib/permissions';

export interface AppointmentPopupProps {
  open: boolean;
  appointment: Appointment;
  onClose: () => void;
  onEditClick: () => void;
}

export function AppointmentPopup(props: AppointmentPopupProps) {
  const { appointment, open, onClose, onEditClick } = props;

  const { t } = useTranslation();

  const user = useUser();
  const appointmentIsEditable = useMemo(() => {
    if (
      (appointment?.privat &&
        appointment?.appointmentAttendeeList
          .filter((attendee) => attendee.user)
          .findIndex((x) => x.user.id === user.benutzerCode) === -1) ||
      (appointment?.privat && appointment?.user?.id !== user.benutzerCode)
    ) {
      return false;
    } else {
      return true;
    }
  }, [
    appointment?.appointmentAttendeeList,
    appointment?.privat,
    appointment?.user?.id,
    user.benutzerCode,
  ]);

  const filteredAttendeesList: AppointmentAttendee[] = useMemo(() => {
    return filterAttendeesList(appointment);
  }, [appointment]);

  const canClone = canAddAppointment(user);
  const cloneEntity = useCloneEntityMutation();

  const { actions: contactActions } = useContactActions(
    {
      ...appointment?.contact,
    },
    {
      displayEMail: false,
      noButtonPadding: true,
    }
  );

  const tenant = useTenant();

  const handleBzObjectOpen = useCallback(
    (props: { bzEntity: Entities; bzEntityId: number }) => {
      const { bzEntity, bzEntityId } = props;
      const bzObjUrl = prefixToWithTenant(
        `/more/entity/${bzEntity}/details/${bzEntityId}`,
        tenant.activeTenant
      );

      window.open(bzObjUrl.toString(), '_blank').focus();
    },
    [tenant.activeTenant]
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      {appointment && (
        <div>
          <Stack direction="row" alignItems="center">
            <IconButton size="large" onClick={onClose}>
              <CloseIcon />
            </IconButton>

            <Typography variant="h4" flex={1}>
              {appointment.title}
            </Typography>

            <div>
              <IconButton
                size="large"
                color="primary"
                onClick={() => {
                  cloneEntity({
                    id: appointment.id,
                    entity: Entities.appointment,
                  });
                }}
                disabled={!canClone}
              >
                <ContentCopyIcon />
              </IconButton>

              <IconButton
                size="large"
                color="primary"
                onClick={onEditClick}
                disabled={!appointmentIsEditable}
              >
                <EditIcon />
              </IconButton>
            </div>
          </Stack>

          <Divider />

          <div className={styles.grid}>
            <Typography className={styles.label}>
              {t('COMMON.TIMESPAN')}:
            </Typography>
            <div></div>
            <Typography>
              {formatDateString(
                appointment.startDate,
                DatePeriodPresets.PERIOD,
                {
                  periodEnd: appointment.endDate,
                  isWholeDay: appointment.isWholeDay,
                }
              )}
            </Typography>

            <Typography className={styles.label}>
              {t('COMMON.BUSINESSPARTNER', {
                context: appointment.businessPartner?.businessPartnerType,
              })}
              :
            </Typography>
            <div>
              {appointment.businessPartner && (
                <OpenFileIconButton data={appointment.businessPartner?.data} />
              )}
            </div>
            <Typography variant="body1" noWrap>
              {appointment.businessPartner?.data.name || '-'}
            </Typography>

            <Typography className={styles.label}>
              {t('COMMON.CONTACT')}:
            </Typography>
            <div>{contactActions}</div>
            <Typography noWrap>{appointment?.contact?.displayName}</Typography>

            <Typography className={styles.label}>
              {t('COMMON.PROJECT')}:
            </Typography>
            <div>
              {appointment.project && (
                <OpenFileIconButton data={appointment.project} />
              )}
            </div>
            <Typography variant="body1" noWrap>
              {appointment.project?.name || '-'}
            </Typography>

            <Typography className={styles.label}>
              {t('COMMON.DELIVERYNOTE')}:
            </Typography>
            <div>
              {appointment?.deliveryNote ? (
                <IconButton
                  sx={{ p: 0 }}
                  onClick={() =>
                    handleBzObjectOpen({
                      bzEntity: Entities.deliveryNote,
                      bzEntityId: appointment?.deliveryNoteId,
                    })
                  }
                >
                  <OpenInNewIcon color="primary" />
                </IconButton>
              ) : null}
            </div>
            <Typography variant="body1" noWrap>
              {appointment?.deliveryNote
                ? `${appointment?.deliveryNote?.number} | ${appointment?.deliveryNote?.note}`
                : '-'}
            </Typography>

            <Typography className={styles.label}>
              {t('COMMON.CONTRACT')}:
            </Typography>
            <div>
              {appointment?.contract ? (
                <IconButton
                  sx={{ p: 0 }}
                  onClick={() =>
                    handleBzObjectOpen({
                      bzEntity: Entities.contract,
                      bzEntityId: appointment?.contractId,
                    })
                  }
                >
                  <OpenInNewIcon color="primary" />
                </IconButton>
              ) : null}
            </div>
            <Typography variant="body1" noWrap>
              {appointment?.contract
                ? `${appointment?.contract?.contractNumber} | ${appointment?.contract?.note}`
                : '-'}
            </Typography>

            <Typography className={styles.label}>
              {t('COMMON.PARTICIPANT')}:
            </Typography>
            <div></div>
            <Typography variant="body1" noWrap>
              {filteredAttendeesList
                .map(
                  (el) =>
                    el.user?.displayName ??
                    el.contact?.displayName ??
                    el.businessPartner?.data.name ??
                    el.ressource?.displayName
                )
                .join(', ')}
            </Typography>
          </div>

          {appointment.note ? (
            <>
              <Divider />
              <div className={styles.noteContainer}>
                <Typography className={styles.label}>
                  {t('COMMON.NOTE')}:
                </Typography>
                <Typography>
                  <Box
                    whiteSpace="pre-line"
                    style={{ wordBreak: 'break-word' }}
                  >
                    {appointment.note}
                  </Box>
                </Typography>
              </div>
            </>
          ) : null}
        </div>
      )}
    </Dialog>
  );
}
