import { useDataProvider } from '@work4all/data';
import { nameof } from '@work4all/data/lib/helper/nameof';
import { useEntityChanges } from '@work4all/data/lib/hooks/use-entity-changed';

import { Notification } from '@work4all/models/lib/Classes/Notification.entity';
import { DataRequest, SortDirection } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ObjectType } from '@work4all/models/lib/Enums/ObjectType.enum';

import { filterNotifications } from '../utils';

const PARENT_OBJECT_DATA = {
  id: null,
};

const data: Notification<EMode.query> = {
  id: null,
  notificationType: null,
  object: {
    mention: {
      parentObject: {
        ticket: PARENT_OBJECT_DATA,
        note: PARENT_OBJECT_DATA,
        task: PARENT_OBJECT_DATA,
        appointment: PARENT_OBJECT_DATA,
        checkList: PARENT_OBJECT_DATA,
        salesOpportunities: PARENT_OBJECT_DATA,
        callMemo: PARENT_OBJECT_DATA,
        visitationReport: PARENT_OBJECT_DATA,
        letter: PARENT_OBJECT_DATA,
        document: PARENT_OBJECT_DATA,
        eMail: PARENT_OBJECT_DATA,
        deliveryNote: PARENT_OBJECT_DATA,
        inboundDeliveryNote: PARENT_OBJECT_DATA,
        calculation: PARENT_OBJECT_DATA,
        invoice: PARENT_OBJECT_DATA,
        contract: PARENT_OBJECT_DATA,
        offer: PARENT_OBJECT_DATA,
        order: PARENT_OBJECT_DATA,
        demand: PARENT_OBJECT_DATA,
        productionContract: PARENT_OBJECT_DATA,
        inboundInvoice: PARENT_OBJECT_DATA,
        customer: PARENT_OBJECT_DATA,
        supplier: PARENT_OBJECT_DATA,
        project: PARENT_OBJECT_DATA,
        article: PARENT_OBJECT_DATA,
      },
    },
  },
};

const request: DataRequest = {
  operationName: 'GetUnreadNotifications',
  entity: Entities.notification,
  data,
  filter: [{ isRead: { $eq: false } }],
  sort: [
    {
      field: nameof<Notification>('insertTime'),
      direction: SortDirection.DESCENDING,
    },
  ],
};

export function useUnreadNotifications(): { total: number } {
  const query = useDataProvider<Notification>(request, false, 0);

  const filterdNotifications = filterNotifications(query.data);

  // Refetch unread notifications whenever a notification is created/updated.
  useEntityChanges({
    entity: Entities.mention,
    objectsType: [ObjectType.UNREAD_NOTIFICATION],
    onEntityChanged() {
      query.refetch();
    },
  });

  return { total: filterdNotifications.length };
}
