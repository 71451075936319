import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { InboundInvoice } from '@work4all/models/lib/Classes/InboundInvoice.entity';
import { Ra } from '@work4all/models/lib/Classes/Ra.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { CONFIGS_BY_ENTITY } from '../constants';
import { TableBottomViewProps } from '../interfaces';

export const useTabData = (
  entity: Entities,
  subEntity: Entities,
  selectedEntityId: TableBottomViewProps['selectedEntityId']
) => {
  const configs = CONFIGS_BY_ENTITY[entity];

  if (!configs) {
    throw new Error(`Configs for ${entity} entity isn't defined`);
  }

  const request = useMemo(() => {
    return {
      entity,
      data: configs.dataBySubEntity[subEntity],
      filter: [{ id: { $eq: selectedEntityId } }],
    };
  }, [configs.dataBySubEntity, entity, selectedEntityId, subEntity]);

  const { data: requestData } = useDataProvider<InboundInvoice | Ra>(request);

  return requestData?.[0]?.[configs.keyBySubEntity[subEntity]] ?? [];
};
