import { PropsWithChildren, useMemo, useState } from 'react';

import {
  PdfEditPopoverState,
  PdfOverlayContext,
} from '@work4all/components/lib/components/entity-preview/erp-preview/use-pdf-overlay';
import { IStackItem } from '@work4all/components/lib/navigation/history-stack';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { PdfEditorOverlayController } from './PdfEditorOverlayController';

interface PdfOverlayMaskProviderProps {}

export const PdfOverlayMaskProvider = (
  props: PropsWithChildren<PdfOverlayMaskProviderProps>
) => {
  const { children } = props;

  const [pdfEditPopoverState, setPdfEditPopoverState] = useState<
    Partial<PdfEditPopoverState>
  >({});

  const { onClose, entityId, entityType, docId } = pdfEditPopoverState;
  const [pdfEditPopoverOpen, setPdfEditPopoverOpen] = useState<boolean>(false);

  const editPdfView = useMemo<IStackItem>(() => {
    return {
      view: (
        <PdfEditorOverlayController
          docId={entityType === Entities.inboundInvoice ? docId : entityId}
          entityId={entityId}
          entityType={entityType}
          onClose={() => {
            setPdfEditPopoverOpen(false);
            onClose?.();
          }}
        />
      ),
    };
  }, [docId, entityId, entityType, onClose]);

  const component = useMemo(() => {
    return (
      <NavigationOverlay
        open={pdfEditPopoverOpen}
        initialView={editPdfView}
        withBreadcrumbs={true}
        close={() => {
          setPdfEditPopoverOpen(false);
        }}
      />
    );
  }, [editPdfView, pdfEditPopoverOpen]);

  const value = useMemo(() => {
    const open = () => {
      setPdfEditPopoverOpen(true);
    };
    const close = () => {
      setPdfEditPopoverOpen(false);
    };
    return { open, close, component, setPdfState: setPdfEditPopoverState };
  }, [component]);

  return (
    <PdfOverlayContext.Provider value={value}>
      {children}
    </PdfOverlayContext.Provider>
  );
};
