import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { TableBottomView } from '@work4all/components/lib/components/entity-bottom-view/table-bottom-view';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';

import { InboundInvoice } from '@work4all/models/lib/Classes/InboundInvoice.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import {
  PaymentMask,
  PaymentMaskProps,
} from '../../../containers/mask-overlays/mask-overlay/views/payment';
import { AccountingStatusCell } from '../AccountingStatusCell';
import { DueDateClassifiedCell } from '../DueDateClassifiedCell';
import { EntityTable, IEntityTable } from '../entity-table/EntityTable';
import { useEntityDataTable } from '../entity-table/use-entity-data-table';
import { InvoiceKindCell } from '../InvoiceKindCell';
import { PaymentStatusCell } from '../PaymentStatusCell';
import { PermitStatusCell } from '../PermitStatusCell';
import schema from '../schemata/inboundInvoice-table-schema.json';
import { useDeleteEntitiesToolbarConfig } from '../use-delete-entities-toolbar-config';
import { useErpHandlersTemplate } from '../use-erp-handlers-template';
import { ERP_PREVIEW_FEATURE } from '../use-table-handlers';

const defaultSort = [
  { field: 'invoiceDate', direction: SortDirection.DESCENDING },
];

const DISABLED_COLUMNS = ['unused'];

export const InboundInvoicesTable = React.forwardRef<
  TableInstance,
  IEntityTable
>(function InboundInvoicesTable(_props, ref) {
  const entityType = Entities.inboundInvoice;

  const { prepareRowDisplayModifiers, ...dataTable } = useEntityDataTable<
    InboundInvoice,
    never
  >({
    schema: schema as never,
    defaultSort,
    enableFooter: true,
    cells: {
      PaymentStatus: PaymentStatusCell,
      AccountingStatus: AccountingStatusCell,
      PermitStatus: PermitStatusCell,
      InvoiceKind: InvoiceKindCell,
      DueDateClassified: DueDateClassifiedCell,
    },
  });

  const template = useErpHandlersTemplate('supplier.id', Entities.supplier);

  const rowModifiers = useCallback(
    (value: InboundInvoice) => {
      const modifiers = prepareRowDisplayModifiers(value);
      return {
        ...modifiers,
        isFaded: value.paid,
      };
    },
    [prepareRowDisplayModifiers]
  );

  const { t } = useTranslation();

  const [paymentMask, setPaymentMask] = useState<PaymentMaskProps>();
  const deleteConfig = useDeleteEntitiesToolbarConfig({
    entityType,
  });

  return (
    <>
      {paymentMask && (
        <NavigationOverlay
          open={true}
          initialView={{
            view: <PaymentMask {...paymentMask} />,
          }}
          close={() => {
            setPaymentMask(undefined);
          }}
        />
      )}

      <EntityTable
        displayFooter
        ref={ref}
        template={template}
        areas={{
          bottom: dataTable.selectedEntity
            ? {
                content: (
                  <TableBottomView
                    entity={Entities.inboundInvoice}
                    selectedEntityId={dataTable.selectedEntity.id}
                    tabs={[
                      Entities.rELedgerAccountSplit,
                      Entities.inboundInvoicePayment,
                    ]}
                  />
                ),
              }
            : undefined,
        }}
        actions={{
          remove: {
            ...deleteConfig,
            disableReason:
              deleteConfig.disabled && ERP_PREVIEW_FEATURE.includes(entityType)
                ? t('MASK.ERP.PREVIEW')
                : undefined,
            canDeleteEntity: (id) => {
              const entity = dataTable.selectedEntities.find(
                (item) => item.id === Number(id)
              );
              const value =
                entity.paid ||
                entity.payedSum > 0 ||
                entity?.payments?.length > 0
                  ? false
                  : true;

              return {
                value,
                preventMessage: value
                  ? null
                  : t('ALERTS.INCOMING_INVOICE_PAYMENTS_EXIST', {
                      number: entity?.rNumber,
                    }),
              };
            },
          },
          openPaymentMask: dataTable.selectedEntity
            ? {
                handler: () =>
                  setPaymentMask({
                    id: dataTable.selectedEntity.id,
                    entity: Entities.inboundInvoice,
                  }),
                disabled: true,
              }
            : null,
        }}
        {...dataTable}
        prepareRowDisplayModifiers={rowModifiers}
        entityType={entityType}
        disabledColumns={DISABLED_COLUMNS}
      />
    </>
  );
});
