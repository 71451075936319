import { useCallback, useEffect, useState } from 'react';

import { components } from '../../apiModels';
import { useAuthHeaders } from '../auth';
import { useHttpClient } from '../http-client/hooks';
import { UploadFileParams } from '../interfaces';
import { getBlobAuthed } from '../utils/authedFileDownload';

import { useUser } from './useUser';

export function useCompanyPictureSimpleModeFile() {
  const user = useUser();

  const { baseUrl } = user ?? {};

  const [logoURL, setLogoURL] = useState('');
  const [logoBase64, setLogoBase64] = useState('');
  const [uploading, setUploading] = useState(false);

  const httpClient = useHttpClient();

  const headers = useAuthHeaders();

  const getCompanyPictureSimpleModeFile = useCallback(async () => {
    try {
      return getBlobAuthed(
        user.baseUrl +
          '/api/file/download?type=CompanyPictureSimpleMode&download=true',
        headers
      );
    } catch (err) {
      return null;
    }
  }, [headers, user.baseUrl]);

  const createLogo = useCallback(async () => {
    try {
      const res = await getCompanyPictureSimpleModeFile();
      if (!res || res.status === 404) {
        setUploading(false);
        return;
      }

      setLogoURL(URL.createObjectURL(res.data as Blob));

      const reader = new FileReader();
      reader.readAsDataURL(res.data as Blob);
      reader.onloadend = function () {
        setLogoBase64(reader.result as string);
        setUploading(false);
      };
    } catch (err) {
      console.warn(err);
    }
  }, [getCompanyPictureSimpleModeFile]);

  const uploadCompanyPictureSimpleModeFile = useCallback(
    async (file: File): Promise<components['schemas']['UploadResult']> => {
      if (baseUrl === undefined) {
        throw new Error();
      }

      setUploading(true);

      const formData = new FormData();
      formData.append('myFile', file, file.name);

      const response = await httpClient.post<
        components['schemas']['UploadResult'],
        FormData,
        UploadFileParams
      >({
        url: `${baseUrl}/api/file`,
        params: {
          type: 'CompanyPictureSimpleMode',
        },
        body: formData,
      });
      createLogo();
      return response.data;
    },
    [baseUrl, httpClient, createLogo]
  );

  useEffect(() => {
    createLogo();
  }, [createLogo]);

  return {
    uploadCompanyPictureSimpleModeFile,
    getCompanyPictureSimpleModeFile,
    logoURL,
    logoBase64,
    uploading,
  };
}
