import styles from './EditableDisabledCell.module.scss';

import React from 'react';

import { EditModeCell } from '@work4all/components/lib/dataDisplay/basic-table/plugins/useEditMode';

export const EditableDisabledCell = (props) => {
  return (
    editableDisabledCellWrapper((innerProps) => {
      return innerProps.cell.value;
    })(props) ?? null
  );
};

export const editableDisabledCellWrapper = (render) => {
  return (props) => {
    const isRowInEditMode = props.cell.row.cells.some(
      (cell) => (cell as unknown as EditModeCell).isEditMode
    );
    if (!isRowInEditMode || !props.cell.value) return render(props);

    const rendered = render(props);

    if (typeof rendered !== 'object') {
      return <div className={styles.container}>{rendered}</div>;
    }
    const component = rendered
      ? React.cloneElement(rendered, {
          style: {
            alignItems: 'center',
          },
        })
      : rendered;
    return <div className={styles.container}>{component}</div>;
  };
};
