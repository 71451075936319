import styles from './BookingsTable.module.scss';

import { useEventCallback } from '@mui/material/utils';
import clsx from 'clsx';
import { isEqual, noop } from 'lodash';
import { useSnackbar } from 'notistack';
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  CellProps,
  Column,
  ColumnInstance,
  HeaderGroup,
  Row,
  TableInstance,
  useColumnOrder,
  useFlexLayout,
  useResizeColumns,
  useRowSelect,
  useTable,
} from 'react-table';

import { ColumnAdditionalData } from '@work4all/components';
import { TableHeader } from '@work4all/components/lib/dataDisplay/basic-table/components/table-header/TableHeader';
import {
  EditModeTableInstance,
  useEditMode,
} from '@work4all/components/lib/dataDisplay/basic-table/plugins/useEditMode';

import { LedgerAccount } from '@work4all/models/lib/Classes/LedgerAccount.entity';
import { Position } from '@work4all/models/lib/Classes/Position.entity';
import { RELedgerAccountSplit } from '@work4all/models/lib/Classes/RELedgerAccountSplit.entity';

import { parseAsFloat } from '@work4all/utils';
import { formatNumberAsCurrency } from '@work4all/utils';
import { useDeepMemo } from '@work4all/utils/lib/hooks/use-deep-memo';

import {
  settings,
  useSetting,
} from '../../../../../../../../../../../../settings';
import { CostCenterCell } from '../../../../../../../../components/table-cells/CostCenterCell';
import { CostGroupCell } from '../../../../../../../../components/table-cells/CostGroupCell';
import { LedgerAccountCell } from '../../../../../../../../components/table-cells/LedgerAccountCell';
import { NumberInputCell } from '../../../../../../../../components/table-cells/NumberInputCell';
import { ProjectCell } from '../../../../../../../../components/table-cells/ProjectCell';
import { TextInputCell } from '../../../../../../../../components/table-cells/TextInputCell';
import { useEditableColumnsSettings } from '../../../../../../../../erp/components/tab-panels/positions/components/edit-table/hooks/use-editable-columns-settings';

declare module 'react-table' {
  interface ColumnInterface {
    align?: 'left' | 'right';
  }
}

const NUMBER_INPUT_FORBIDDEN_PATTERN_REGEXP = /[^-?0-9,.]/g;
const WHOLE_NUMBER_INPUT_FORBIDDEN_PATTERN_REGEXP = /[^-?0-9]/g;

export type IBookingsTableProps = {
  disabled?: boolean;
  defaultLedgerAccount: LedgerAccount;
  bookings: RELedgerAccountSplit[];
  onSelectedPositionIdsChange: (selectedPositionIds: number[]) => void;
  onAddBooking: (booking: RELedgerAccountSplit) => void;
  onEditBooking: (booking: RELedgerAccountSplit) => void;
};

const defaultColumn = {
  Cell: ({ value }) => {
    return <span>{value}</span>;
  },
};

const getUniqueKey = (cell) => `${cell.column.id}_${cell.row.id}`;

function parseNumberAsCurrency(value: string): number {
  return parseAsFloat(value);
}

export const BookingsTable = (props: IBookingsTableProps) => {
  const {
    disabled = false,
    defaultLedgerAccount,
    bookings,
    onAddBooking,
    onEditBooking,
    onSelectedPositionIdsChange,
  } = props;

  const { t } = useTranslation();

  const totals = useMemo(() => {
    const { net, vat } = (bookings ?? []).reduce<{ net: number; vat: number }>(
      (acc, cur) => {
        acc.net += cur.valueNet ?? 0;
        acc.vat += cur.vatAmount ?? 0;

        return acc;
      },
      { net: 0, vat: 0 }
    );

    const gross = net + vat;

    return { net, vat, gross };
  }, [bookings]);

  const placeholderBooking = useMemo((): RELedgerAccountSplit => {
    return {
      id: -1,
      konto: defaultLedgerAccount,
      costCenter: null,
      project: null,
      costGroup: null,
      note: '',
      taxKey: defaultLedgerAccount?.taxKey ?? 0,
      vat: 0,
      vatAmount: 0,
      valueNet: 0,
      proportionDM: 0,
    };
  }, [defaultLedgerAccount]);

  const bookingsWithExtraLine = useMemo(() => {
    if (disabled) {
      return bookings;
    }

    return [...bookings, placeholderBooking];
  }, [bookings, placeholderBooking, disabled]);

  const editableKeyToFocus = useRef(null);

  // handle case when there is placholder selected
  useEffect(() => {
    if (editableKeyToFocus.current) {
      const parts = editableKeyToFocus.current.split('_');
      if (parts[1] === placeholderBooking.id.toString()) {
        editableKeyToFocus.current = `${parts[0]}_${
          bookings[bookings.length - 1].id
        }`;
        const parentEl = document.getElementById(editableKeyToFocus.current);
        const inputEl = parentEl.querySelector('input');

        if (!inputEl) {
          const buttonEl = parentEl.querySelector('button');
          buttonEl?.focus();
          return;
        }
        // 1st make focus immediately to not jump between inputs
        inputEl.focus();
        // 2d select just after - couldn't find event which resets selection range
        setTimeout(() => {
          inputEl.select?.();
        }, 10);
      }
    }
  }, [bookings, placeholderBooking.id]);

  const rowSelectHandles = useCallback(
    (cell: PropsWithChildren<CellProps<object>>) => {
      return {
        onFocus: () => {
          editableKeyToFocus.current = getUniqueKey(cell);
          cell.toggleAllRowsSelected(false);
          cell.toggleRowSelected(cell.row.id, true);
        },
      };
    },
    []
  );

  const handleEdit = useEventCallback(
    (original: RELedgerAccountSplit, changes: RELedgerAccountSplit) => {
      if (original === placeholderBooking) {
        onAddBooking({ konto: original.konto, ...changes });
      } else {
        onEditBooking({ ...changes, id: original.id });
      }
    }
  );

  const { enqueueSnackbar } = useSnackbar();

  const columnSettings = useSetting(settings.incomingInvoicePositionsColumn());
  const width = useDeepMemo(
    () => columnSettings.value.width,
    [columnSettings.value.width]
  );

  const columns = useMemo(() => {
    const columns: Column<RELedgerAccountSplit>[] = [
      {
        Header: t('BOOKINGS_TABLE.COLUMNS.LEDGER_ACCOUNT'),
        accessor: 'konto',
        width: 180,
        Cell: (cell) => {
          return (
            <LedgerAccountCell
              autoFocus={getUniqueKey(cell) === editableKeyToFocus.current}
              disabled={disabled}
              value={cell.value}
              data={{ taxKey: null }}
              onChange={(ledgerAccount) => {
                editableKeyToFocus.current = getUniqueKey(cell);
                handleEdit(cell.row.original, {
                  konto: ledgerAccount,
                });
              }}
            />
          );
        },
      },
      {
        Header: t('BOOKINGS_TABLE.COLUMNS.COST_CENTER'),
        accessor: 'costCenter',
        width: 130,
        Cell: (cell) => {
          return (
            <CostCenterCell
              autoFocus={getUniqueKey(cell) === editableKeyToFocus.current}
              disabled={disabled}
              value={cell.value}
              onChange={(costCenter) => {
                editableKeyToFocus.current = getUniqueKey(cell);
                handleEdit(cell.row.original, { costCenter });
              }}
            />
          );
        },
      },
      {
        Header: t('BOOKINGS_TABLE.COLUMNS.PROJECT'),
        accessor: 'project',
        width: 140,
        Cell: (cell) => {
          return (
            <ProjectCell
              autoFocus={getUniqueKey(cell) === editableKeyToFocus.current}
              disabled={disabled}
              value={cell.value}
              onChange={(project) => {
                if (project.lockExternalServices) {
                  enqueueSnackbar(
                    t(
                      'VALIDATION.INBOUND_INVOICES.PROJECT_LOCKED_EXTERNAL_SERVICES'
                    )
                  );
                  return;
                }
                if (project.projectStatus?.closedStatus) {
                  enqueueSnackbar(
                    t('VALIDATION.INBOUND_INVOICES.PROJECT_CLOSED')
                  );
                  return;
                }

                editableKeyToFocus.current = getUniqueKey(cell);
                handleEdit(cell.row.original, { project });
              }}
            />
          );
        },
      },
      {
        Header: t('BOOKINGS_TABLE.COLUMNS.COST_GROUP'),
        accessor: 'costGroup',
        width: 140,
        Cell: (cell) => {
          return (
            <CostGroupCell
              autoFocus={getUniqueKey(cell) === editableKeyToFocus.current}
              disabled={disabled}
              value={cell.value}
              onChange={(costGroup) => {
                editableKeyToFocus.current = getUniqueKey(cell);
                handleEdit(cell.row.original, { costGroup });
              }}
            />
          );
        },
      },
      {
        Header: t('BOOKINGS_TABLE.COLUMNS.NOTE'),
        accessor: 'note',
        width: 140,
        Cell: (cell) => {
          return (
            <TextInputCell
              autoFocus={getUniqueKey(cell) === editableKeyToFocus.current}
              disabled={disabled}
              value={cell.value}
              onChange={(note) => {
                if (note !== cell.value) {
                  handleEdit(cell.row.original, { note });
                }
              }}
            />
          );
        },
      },
      {
        Header: t('BOOKINGS_TABLE.COLUMNS.TAX_KEY'),
        accessor: 'taxKey',
        width: 50,
        align: 'right',
        Cell: (cell) => {
          return (
            <NumberInputCell
              autoFocus={getUniqueKey(cell) === editableKeyToFocus.current}
              disabled={disabled}
              value={cell.value}
              onChange={(taxKey) => {
                if (taxKey !== cell.value) {
                  handleEdit(cell.row.original, { taxKey });
                }
              }}
              parseValue={(value) => parseInt(value, 10) || 0}
              forbiddenPattern={WHOLE_NUMBER_INPUT_FORBIDDEN_PATTERN_REGEXP}
              {...rowSelectHandles(cell)}
            />
          );
        },
      },
      {
        Header: t('BOOKINGS_TABLE.COLUMNS.VAT'),
        accessor: 'vat',
        width: 60,
        align: 'right',
        Cell: (cell) => {
          return (
            <NumberInputCell
              autoFocus={getUniqueKey(cell) === editableKeyToFocus.current}
              disabled={disabled}
              value={cell.value}
              onChange={(vat) => {
                if (vat !== cell.value) {
                  handleEdit(cell.row.original, { vat });
                }
              }}
              formatValue={formatNumberAsCurrency}
              parseValue={parseNumberAsCurrency}
              max={29}
              forbiddenPattern={NUMBER_INPUT_FORBIDDEN_PATTERN_REGEXP}
              {...rowSelectHandles(cell)}
            />
          );
        },
      },
      {
        Header: t('BOOKINGS_TABLE.COLUMNS.TOTAL_NET'),
        id: 'netto',
        accessor: 'valueNet',
        width: 80,
        align: 'right',
        Cell: (cell) => {
          return (
            <NumberInputCell
              autoFocus={getUniqueKey(cell) === editableKeyToFocus.current}
              disabled={disabled}
              value={cell.value}
              onChange={(valueNet) => {
                if (valueNet !== cell.value) {
                  handleEdit(cell.row.original, { valueNet });
                }
              }}
              formatValue={formatNumberAsCurrency}
              parseValue={parseNumberAsCurrency}
              forbiddenPattern={NUMBER_INPUT_FORBIDDEN_PATTERN_REGEXP}
              {...rowSelectHandles(cell)}
            />
          );
        },
        Footer: (
          <div className={styles.amount}>
            {formatNumberAsCurrency(totals.net)}
          </div>
        ),
      },
      {
        Header: t('BOOKINGS_TABLE.COLUMNS.TOTAL_VAT'),
        accessor: 'vatAmount',
        width: 80,
        align: 'right',
        Cell: (x) => {
          return (
            <NumberInputCell
              disabled
              value={x.value}
              onChange={noop}
              formatValue={formatNumberAsCurrency}
              parseValue={parseNumberAsCurrency}
              forbiddenPattern={NUMBER_INPUT_FORBIDDEN_PATTERN_REGEXP}
            />
          );
        },
        Footer: (
          <div className={styles.amount}>
            {formatNumberAsCurrency(totals.vat)}
          </div>
        ),
      },
      {
        Header: t('BOOKINGS_TABLE.COLUMNS.TOTAL_GROSS'),
        accessor: 'proportionDM',
        width: 80,
        align: 'right',
        Cell: (cell) => {
          return (
            <NumberInputCell
              autoFocus={getUniqueKey(cell) === editableKeyToFocus.current}
              disabled={disabled}
              value={cell.value}
              onChange={(proportionDM) => {
                if (proportionDM !== cell.value) {
                  handleEdit(cell.row.original, { proportionDM });
                }
              }}
              formatValue={formatNumberAsCurrency}
              parseValue={parseNumberAsCurrency}
              forbiddenPattern={NUMBER_INPUT_FORBIDDEN_PATTERN_REGEXP}
              {...rowSelectHandles(cell)}
            />
          );
        },
        Footer: (
          <div className={styles.amount}>
            {formatNumberAsCurrency(totals.gross)}
          </div>
        ),
      },
    ];
    Object.entries(width).forEach((colWidth) => {
      const [prop, value] = colWidth;
      const colIdx = columns.findIndex((col) => col.accessor === prop);
      if (columns[colIdx]) {
        columns[colIdx].width = value;
      }
    });

    return columns;
  }, [
    t,
    totals.net,
    totals.vat,
    totals.gross,
    width,
    disabled,
    handleEdit,
    enqueueSnackbar,
    rowSelectHandles,
  ]);

  function getRowId(booking: RELedgerAccountSplit) {
    return String(booking.id);
  }

  const tableInstance = useTable<RELedgerAccountSplit>(
    {
      columns,
      defaultColumn,
      data: bookingsWithExtraLine,
      getRowId,
      onCellEdit: noop,
    },
    useFlexLayout,
    useColumnOrder,
    useRowSelect,
    useResizeColumns,
    useEditMode
  ) as TableInstance<Position> & EditModeTableInstance;

  const {
    headerGroups,
    getTableBodyProps,
    getTableProps,
    prepareRow,
    rows,
    setColumnOrder,
    visibleColumns,
    state: { selectedRowIds, groupBy },
  } = tableInstance;

  useEditableColumnsSettings({ columnSettings, tableInstance });

  const lastEmittedSelectedPositionIds = useRef<number[] | null>(null);

  useEffect(() => {
    const selectedPositionIds = Object.entries(selectedRowIds)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_id, selected]) => selected)
      .map(([id]) => Number(id));

    if (!isEqual(selectedPositionIds, lastEmittedSelectedPositionIds.current)) {
      lastEmittedSelectedPositionIds.current = selectedPositionIds;
      onSelectedPositionIdsChange?.(selectedPositionIds);
    }
  }, [
    lastEmittedSelectedPositionIds,
    onSelectedPositionIdsChange,
    selectedRowIds,
  ]);

  const tablesLayoutBorders = useSetting(
    settings.tablesLayoutBorders('INCOMING_INVOICE')
  );

  const renderRow = (row: Row<Position>) => {
    prepareRow(row);

    const isPlaceholderBooking = row.original === placeholderBooking;
    return (
      <div
        key={row.id}
        {...row.getRowProps({
          className: clsx(
            styles.tableRow,
            isPlaceholderBooking && styles.placeholderRow,
            {
              [styles.borderHorizontal]: tablesLayoutBorders.value.horizontal,
              [styles.borderRow]: tablesLayoutBorders.value.horizontal,
            }
          ),
        })}
      >
        {row.cells.map((cell) => (
          <div
            id={getUniqueKey(cell)}
            {...cell.getCellProps({
              className: clsx(
                styles.tableCell,
                cell.column.align === 'right' && styles.alignRight,
                {
                  [styles.borderVertical]: tablesLayoutBorders.value.vertical,
                }
              ),
            })}
          >
            {cell.render('Cell')}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div
      className={clsx(styles.table, disabled && styles.disabled)}
      {...getTableProps()}
    >
      <div className={styles.thead}>
        <TableHeader
          flatColumns={visibleColumns as unknown as ColumnInstance[]}
          headerGroups={
            headerGroups as unknown as HeaderGroup<ColumnAdditionalData>[]
          }
          setColumnOrder={setColumnOrder}
          reordableColumns={true}
          resizableColumns={true}
          noSeperator={false}
          groupBy={groupBy}
          classes={{
            headerRow: styles.headerRow,
            headerWrapper: styles.headerWrapper,
          }}
        />
      </div>
      <div className={styles.tbody} {...getTableBodyProps()}>
        {rows.map(renderRow)}
      </div>

      <div className={styles.tfoot}>
        {headerGroups.map((headerGroup) => (
          <div
            {...headerGroup.getFooterGroupProps({
              className: clsx(styles.tableRow, {
                [styles.borderHorizontal]: tablesLayoutBorders.value.horizontal,
                [styles.borderRow]: tablesLayoutBorders.value.horizontal,
              }),
            })}
          >
            {headerGroup.headers.map((column) => {
              return (
                <div
                  {...column.getFooterProps({
                    className: clsx(styles.tableCell, {
                      [styles.borderVertical]:
                        tablesLayoutBorders.value.vertical,
                    }),
                  })}
                >
                  {column.render('Footer')}
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};
