import styles from './Collapse.module.scss';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  ButtonBase,
  Collapse as MUICollapse,
  CollapseClassKey,
  CollapseProps,
  IconButton,
} from '@mui/material';
import clsx from 'clsx';
import React, { PropsWithChildren, useEffect, useState } from 'react';

import {
  EditorToolbarProvider,
  useEditorToolbar,
} from '../../input/format-text/use-editor-toolbar';
import { Heading4 } from '../../typography/heading4/Heading4';

interface ICollapseProps extends Pick<CollapseProps, 'timeout'> {
  defaultOpen: boolean;
  title?: string | React.ReactNode;
  className?: string;
  classes?: Partial<Record<CollapseClassKey, string>>;
  grid?: boolean;
  collapseContainerStyles?: string;
  headerBar?: JSX.Element;
  headerClassName?: string;
  bottomIndent?: 'sm' | 'md' | 'lg' | false;
  topIndent?: boolean;
  rightIndent?: boolean;
  withToggleButton?: boolean;
  onToggled?: (open: boolean) => void;
  froalaToolbarAction?: boolean;
}

export const Collapse = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<ICollapseProps>
>((props, ref) => {
  const {
    withToggleButton = true,
    topIndent = false,
    bottomIndent = 'md',
    rightIndent = false,
    headerClassName,
    froalaToolbarAction = false,
    timeout = 'auto',
  } = props;
  const [open, setOpen] = useState(props.defaultOpen);

  // TODO Why is this done in an effect instead of the click handler?
  const onToggled = props.onToggled;
  useEffect(() => {
    onToggled?.(open);
  }, [open, onToggled]);

  useEffect(() => {
    setOpen(props.defaultOpen);
  }, [props.defaultOpen]);

  const handleClick = () => setOpen(!open);

  const { actions, hideToolbar } = useEditorToolbar(froalaToolbarAction);

  function renderHeader(children: React.ReactNode) {
    const left = withToggleButton ? (
      <ButtonBase className={styles.button} onClick={handleClick}>
        <ExpandMoreIcon
          className={clsx(styles.icon, { [styles.iconCollapsed]: !open })}
        />
        <Heading4 className={styles.heading}>{props.title}</Heading4>
      </ButtonBase>
    ) : (
      <Heading4 className={clsx(styles.heading, styles.noButton)}>
        {props.title}
      </Heading4>
    );

    const right = withToggleButton ? (
      <IconButton className={styles.iconButton} onClick={handleClick}>
        <ExpandMoreIcon
          className={clsx(styles.icon, { [styles.iconCollapsed]: !open })}
        />
      </IconButton>
    ) : null;

    return (
      <div className={clsx(styles.headerWrapper, headerClassName)}>
        {left}
        {froalaToolbarAction ? actions : null}
        <div className={styles.headerContent}>{children}</div>
        {right}
      </div>
    );
  }

  const content = (
    <div
      ref={ref}
      className={clsx(
        styles.root,
        {
          [styles.grid]: props.grid,
          [styles[`bottomIndent--${bottomIndent}`]]: bottomIndent,
          [styles.rightIndent]: rightIndent,
          [styles.topIndent]: topIndent,
        },
        props.className
      )}
    >
      {(props.title || props.headerBar || froalaToolbarAction) &&
        renderHeader(props.headerBar)}
      <MUICollapse
        className={clsx(
          styles.collapseContainer,
          props.collapseContainerStyles
        )}
        classes={props.classes}
        in={open}
        timeout={timeout}
      >
        {props.children}
      </MUICollapse>
    </div>
  );

  if (froalaToolbarAction)
    return (
      <EditorToolbarProvider hideToolbar={hideToolbar}>
        {content}
      </EditorToolbarProvider>
    );

  return content;
});
