import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';

export function isAppointment(object: object): object is Appointment {
  return (
    typeof object === 'object' &&
    object != null &&
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (object as any).__typename === 'Termin'
  );
}
