import React from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  IVacationKindPickerProps,
  VacationKindPicker,
} from '@work4all/components/lib/components/entity-picker/vacation-kind-picker/VacationKindPicker';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

interface VacationKindPickerFieldProps
  extends Omit<IVacationKindPickerProps<false>, 'multiple'> {
  error?: string;
  disabled?: boolean;
  isSickness?: boolean;
}

export const VacationKindPickerField = React.forwardRef<
  HTMLDivElement,
  VacationKindPickerFieldProps
>(function VacationKindPickerField(props, ref) {
  const { error, disabled = false, isSickness, ...pickerProps } = props;
  const { value, clearable = true, onChange } = pickerProps;

  const { t } = useTranslation();

  const field = (
    <PickerTargetButton
      ref={ref}
      value={
        value
          ? value.name
          : isSickness
          ? t('COMMON.SICKNESS')
          : t('COMMON.VACATION_KIND_NULL_LABEL')
      }
      label={t('COMMON.VACATION_KIND')}
      error={error}
      onClear={
        disabled || !clearable
          ? undefined
          : () => {
              onChange(null);
            }
      }
    />
  );

  if (disabled) {
    return field;
  }

  return (
    <EntityPickerPopover
      picker={<VacationKindPicker {...pickerProps} multiple={false} />}
    >
      {field}
    </EntityPickerPopover>
  );
});
