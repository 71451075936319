import styles from './styles.module.scss';

import { Paper } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Body1 } from '../../../typography/body1/Body1';
import { Body2 } from '../../../typography/body2/Body2';

export const DocumentCopyBanderole: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Paper className={styles.root}>
      <Body2 className={styles.body2}>{t('MASK.DOCUMENT_COPY')}</Body2>
      <span className={styles.seperator} />
      <Body1>{t('COMMON.DOCUMENT_COPY_EDIT_RIGHTS')}</Body1>
    </Paper>
  );
};
