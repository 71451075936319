import { MbscCalendarEvent } from '@mobiscroll/react';
import { DateTime } from 'luxon';

import { decimalToDuration } from '@work4all/components';

import { TimeTracking } from '@work4all/models/lib/Classes/TimeTracking.entity';

export const calculateDuration = (event: MbscCalendarEvent) => {
  const start = DateTime.fromJSDate(event.start as unknown as Date).startOf(
    'minutes'
  );
  const end = DateTime.fromJSDate(event.end as unknown as Date)
    .startOf('minutes')
    .set({
      year: start.year,
      month: start.month,
      day: start.day,
    });
  const amount = (event.original as TimeTracking)?.amount;

  const duration = event.allDay
    ? calculateDurationFromHours()
    : calculateDurationFromStartEnd();

  function calculateDurationFromHours() {
    return decimalToDuration(amount);
  }

  function calculateDurationFromStartEnd() {
    return end.diff(start, ['hours', 'minutes']);
  }

  return duration;
};
