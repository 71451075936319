import styles from './SignatureDialog.module.scss';

import { Box, Stack } from '@mui/material';
import { DateTime } from 'luxon';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as SignatureIcon } from '@work4all/assets/icons/signature.svg';

import { useLock } from '@work4all/components/lib/hooks';
import { IconButtonWithTooltip } from '@work4all/components/lib/input/actions/IconButtonWithTooltip';
import { Caption } from '@work4all/components/lib/typography/caption/Caption';

import {
  useConvertTempFile,
  useDataProvider,
  useDeleteEntity,
  useUser,
} from '@work4all/data';
import { EMPTY_UID } from '@work4all/data/lib/utils/empty-uid';

import { IAttachmentEntity } from '@work4all/models';
import { DeliveryNote } from '@work4all/models/lib/Classes/DeliveryNote.entity';
import { File as FileEntity } from '@work4all/models/lib/Classes/File.entity';
import { FileLink } from '@work4all/models/lib/Classes/FileLink.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { PathsOf } from '@work4all/utils/lib/paths-of/paths-of';

import { useFormContextPlus } from '../../../../../form-plus/use-form-context-plus';
import { useShadowBzObjectApiContext } from '../../hooks/use-bz-shadow-object-api/use-shadow-bz-object-api-context';

import { SignatureContext } from './signature-provider';
import { SignatureDialogConfirmDialog } from './SignatureDialog';
import { useSignatureCanvas } from './use-signature-canvas';

interface SignatureInternalProps extends SignatureProps {
  signature: FileEntity;
  showSignature?: boolean;
}

const SignatureInternal = (props: SignatureInternalProps) => {
  const { t } = useTranslation();
  const { showSignature } = props;

  const { locked } = useLock();

  const saveAsOwn = useRef(false);
  const shadowBzObjectApi = useShadowBzObjectApiContext();

  const { uploadFiles, fileList, markFilesToDelete } =
    useContext(SignatureContext);

  const user = useUser();
  const signature = useMemo(() => {
    return fileList[fileList.length - 1];
  }, [fileList]);

  const signatureExist = !!signature;
  const [openSignature, setOpenSignature] = useState(false);

  const requestData = useMemo(
    () => ({
      entity: Entities.fileLink,
      data: {
        id: null,
        name: null,
        updateTime: null,
        link: null,
        fileLinkType: null,
        fileInfos: {
          previewUrl: null,
          downloadUrl: null,
        },
        userCode: null,
      },
      filter: [
        {
          userCode: {
            $eq: user.benutzerCode,
          },
        },
        {
          fileLinkType: {
            $eq: 'PersonalSignature',
          },
        },
      ],
    }),
    [user.benutzerCode]
  );

  const { data, refetch } = useDataProvider<FileLink>(requestData);
  const defalultSignature = data
    ? [...data].sort((a, b) => {
        return DateTime.fromISO(a.updateTime) < DateTime.fromISO(b.updateTime)
          ? 1
          : -1;
      })[0]
    : undefined;
  const convertToPersonalSignature = useConvertTempFile();
  const [deleteEntity] = useDeleteEntity(false);

  const setSignature = shadowBzObjectApi.setSignature;
  useEffect(() => {
    const lastId = signature?.id;
    if (lastId && typeof lastId === 'string') {
      setSignature(lastId);
      setOpenSignature(false);

      if (saveAsOwn.current) {
        saveAsOwn.current = false;
        convertToPersonalSignature({
          tempfileId: lastId,
          target: 'FileLink',
          fileLinkType: 'PersonalSignature',
        }).then(async () => {
          const ids = data.map((x) => x.id);
          if (ids.length)
            await deleteEntity({
              type: Entities.fileLink,
              fallbackType: 'FILE_LINK',
              ids,
            });
          refetch();
        });
      }
    }
  }, [
    setSignature,
    setOpenSignature,
    signature,
    convertToPersonalSignature,
    data,
    refetch,
    deleteEntity,
  ]);

  return (
    <React.Fragment>
      {showSignature ? (
        <Stack
          position="relative"
          onClick={() => !(locked || props.disabled) && setOpenSignature(true)}
        >
          <Caption color="text03">{t('COMMON.SIGNATURE')}</Caption>
          <SignatureCanvas
            key={signature?.fileInfos?.previewUrl || 'empty'}
            signature={signature}
          />
          <Box position="absolute" top={0} bottom={0} right={0} left={0}></Box>
        </Stack>
      ) : (
        <IconButtonWithTooltip
          color={signatureExist ? 'success' : 'primary'}
          style={{ padding: '0.75rem' }}
          tooltip={t('MASK.CREATE_SIGNATURE')}
          icon={<SignatureIcon />}
          disabled={locked || props.disabled}
          onClick={() => setOpenSignature(true)}
        />
      )}
      {openSignature && (
        <SignatureDialogConfirmDialog
          open={openSignature}
          signature={signature}
          defalultSignature={defalultSignature}
          onConfirm={(file: File, save: boolean) => {
            uploadFiles([file]);
            setOpenSignature(false);
            saveAsOwn.current = save;
          }}
          onDelete={(id: string | number) => {
            markFilesToDelete([id.toString()]);
            if (props.signature) {
              setSignature(EMPTY_UID);
            }
            setOpenSignature(false);
          }}
          onClose={() => setOpenSignature(false)}
        />
      )}
    </React.Fragment>
  );
};

interface SignatureProps {
  disabled?: boolean;
  showSignature?: boolean;
}

export const Signature = (props: SignatureProps) => {
  const { watch } = useFormContextPlus<PathsOf<DeliveryNote, 2>>();
  const signature = watch('signature');

  return <SignatureInternal signature={signature} {...props} />;
};

const SignatureCanvas = (props: { signature: IAttachmentEntity }) => {
  const { canvas } = useSignatureCanvas(props.signature?.fileInfos?.previewUrl);
  return <div className={styles['signature']}>{canvas}</div>;
};
