import { Theme, useMediaQuery } from '@mui/material';
import { useMemo, useRef, useState } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ConvertIcon } from '@work4all/assets/icons/convert.svg';

import { ConvertPopover } from '@work4all/components/lib/components/convert-popover/ConvertPopover';
import { CustomToolbarAction } from '@work4all/components/lib/dataDisplay/actions-bar/ToolbarTypes';

import { usePermissions } from '@work4all/data';
import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { Calculation } from '@work4all/models/lib/Classes/Calculation.entity';
import { Contract } from '@work4all/models/lib/Classes/Contract.entity';
import { DeliveryNote } from '@work4all/models/lib/Classes/DeliveryNote.entity';
import { Invoice } from '@work4all/models/lib/Classes/Invoice.entity';
import { Offer } from '@work4all/models/lib/Classes/Offer.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ConvertErpDialog, CopyErpDialog } from '../ErpDialog';

import { useConvertBzObjectMutation } from './use-convert-bz-object-mutation';

type ConvertEntity =
  | Entities.offer
  | Entities.contract
  | Entities.calculation
  | Entities.deliveryNote
  | Entities.invoice;

type EntityByConvertConfigs = {
  [key in ConvertEntity]: {
    allowedEntities: Entities[];
    blockedEntities?: Entities[];
  };
};
export const ENTITY_BY_CONVERT_CONFIGS: EntityByConvertConfigs = {
  [Entities.offer]: {
    allowedEntities: [
      Entities.contract,
      Entities.calculation,
      Entities.invoice,
    ],
    blockedEntities: [
      Entities.order,
      Entities.demand,
      Entities.inboundDeliveryNote,
    ],
  },

  [Entities.contract]: {
    allowedEntities: [Entities.offer, Entities.calculation, Entities.invoice],
  },

  [Entities.calculation]: {
    allowedEntities: [Entities.offer, Entities.deliveryNote, Entities.invoice],
  },

  [Entities.deliveryNote]: {
    allowedEntities: [Entities.offer, Entities.calculation, Entities.invoice],
  },

  [Entities.invoice]: {
    allowedEntities: [
      Entities.offer,
      Entities.calculation,
      Entities.deliveryNote,
    ],
  },
};

interface UseCloneConvertErpOptions {
  data?: (Offer | Invoice | Contract | Calculation | DeliveryNote)[];
  entity: ConvertEntity;
}

export const useCloneConvertErp = (props: UseCloneConvertErpOptions) => {
  const { data = [], entity } = props;

  const isSmUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));

  const businessPartner = data.length
    ? data[0]?.businessPartnerContactCombined?.businessPartner?.data
    : undefined;

  const { t } = useTranslation();
  const [targetEntity, setTargetEntity] = useState<Entities>(null);

  const { untypedPermissions } = usePermissions();
  const canUserConvertRecord = untypedPermissions(entity).canConvert();
  const isPreview = untypedPermissions(entity).isPreview();

  const { mutate } = useConvertBzObjectMutation({ targetEntity });
  const convertRootRef = useRef<HTMLButtonElement>();
  // On mobile, the button is displayed at the bottom of the screen, so the menu
  // should open above it. On tablet and up, flit it around.
  const convertPopoverState = usePopoverState(isSmUp ? 'bottom' : 'top');
  const actions: CustomToolbarAction[] = useMemo((): CustomToolbarAction[] => {
    return [
      {
        actionKey: 'cloneOrConvert',
        IconComponent: ConvertIcon,
        title: t('MASK.CONVERT'),
        disabled: !canUserConvertRecord,
        handler: (_id, event) => {
          convertPopoverState.handleClick(
            undefined,
            (event?.target as HTMLElement) ?? convertRootRef.current
          );
        },
        rootRef: convertRootRef,
        mode: 'Edit',
        disableReason: canUserConvertRecord
          ? undefined
          : isPreview
          ? t('MASK.ERP.PREVIEW')
          : t('RIGHTS.MISSING'),
      },
    ];
  }, [t, canUserConvertRecord, isPreview, convertPopoverState]);

  const onClose = () => {
    setTargetEntity(null);
  };

  const onConfirm = async () => {
    await mutate({
      sourceEntity: entity,
      sourceIds: data.map((x) => x.id),
      targetEntity,
    });
    setTargetEntity(null);
  };

  const dialogProps = {
    onClose,
    onConfirm,
  };

  const convertOnClick = (entity: Entities) => {
    convertPopoverState.onClose();
    setTargetEntity(entity);
  };

  const convertTypes = useMemo(
    () => ENTITY_BY_CONVERT_CONFIGS?.[entity]?.allowedEntities ?? [],
    [entity]
  );

  const component = (
    <React.Fragment>
      {targetEntity === entity ? (
        <CopyErpDialog
          {...dialogProps}
          open
          sourceEntity={entity}
          targetEntity={entity}
          businessPartner={businessPartner}
        />
      ) : null}
      {targetEntity && targetEntity !== entity ? (
        <ConvertErpDialog
          {...dialogProps}
          open
          sourceEntity={entity}
          targetEntity={targetEntity}
          businessPartner={businessPartner}
        />
      ) : null}

      <ConvertPopover
        copyTypes={[entity]}
        convertTypes={convertTypes}
        onClick={convertOnClick}
        popoverState={convertPopoverState}
        convertTitle={t('COMMON.CONVERT_TO')}
        copyTitle={t('COMMON.COPY_CREATE')}
      />
    </React.Fragment>
  );

  return { actions, component };
};
