import styles from './NoUsersAlert.module.scss';

import { Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export function NoUsersAlert() {
  const { t } = useTranslation();
  return (
    <Paper className={styles.root}>
      <Typography className={styles.body2}>
        {t('CALENDAR.NO_USERS_SELECTED_ALERT')}
      </Typography>
    </Paper>
  );
}
