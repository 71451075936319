import styles from './EntityPickerPopover.module.scss';

import { Dialog, Popover, PopoverActions } from '@mui/material';
import React, {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { IPickerProps } from '../types';

type IEntityPickerPopoverRenderTargetProps = {
  /**
   * Ref muse be passed to the target element. It is used to position the popover.
   *
   * Alternatively you can provide `anchorEl` to the `EntityPickerPopover` which then will
   * be used instead of the target. This can be useful if you want to have one element
   * trigger the popover, but have it overlay some other container.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: React.Ref<any>;
  /**
   * A callback to trigger
   */
  onClick: () => void;
};

export type IEntityPickerPopoverProps = {
  /**
   * The popover width set directly to popover element.
   */
  width?: number | 'content';
  /**
   * The popover won't be shown while components is disabled
   */
  disabled?: boolean;
  /**
   * The function to be called when the popover is opened.
   */
  onOpen?: () => void;
  /**
   * The function to be called when the popover is closed.
   */
  onClose?: () => void;
  /**
   * The function to be called when the popover is clicked.
   */
  onTargetClick?: () => void;
  /**
   * The entity picker to be rendered inside the popover.
   */
  picker: React.ReactElement<IPickerProps<unknown, boolean>>;
  /**
   * By default the popover will be positioned over the target element
   * (passed as `children` or `renderTarget`). This can be used to set
   * any other element as popover target.
   */
  anchorEl?: Element | React.RefObject<Element>;
  /**
   * Styles that should be applied to the popover's `paper` element.
   */
  style?: React.CSSProperties;
  /**
   * Class that should be applied to the popover's `paper` element.
   */
  className?: string;
  /**
   * The popover will automatically close after the wrapped picker's value
   * changes if the picker is in single-selection mode.
   * This behavior can be overridden with this prop.
   */
  autoclose?: boolean;
  footer?: React.ReactNode;
  children:
    | JSX.Element
    | ((props: IEntityPickerPopoverRenderTargetProps) => React.ReactNode);
  fullscreen?: boolean;
};

export type EntityPickerPopover = {
  open: () => void;
  close: () => void;
};

/**
 * Creates a popover that will open when you click on the `children` element.
 *
 * Position of the popover can be customized with `anchorEl`. If it is supplied,
 * the popover will be positioned on top of the specified element.
 * By default positions the popover on top of `children`.
 *
 * `children` can be either an element or a render prop. If you pass an element,
 * make sure it accepts `ref` and `onClick` callback.
 *
 * By default the popover will try to be the same width as the anchor element
 * (either the one passed with `anchorEl` or the child element) but it will
 * not shrink below `min-content`. This behavior can be customized with
 * `className` and `style` props.
 *
 * _Important:_ The calculated `width` is set using inline styles. Prefer
 * using `min-width` and `max-width` properties to limit the popover size.
 */
export const EntityPickerPopover = forwardRef(function EntityPickerPopover(
  props: IEntityPickerPopoverProps,
  ref: ForwardedRef<EntityPickerPopover>
) {
  const {
    picker,
    anchorEl,
    className,
    style,
    onTargetClick,
    onOpen,
    onClose,
    disabled = false,
    children,
    width,
    fullscreen,
  } = props;

  const [targetEl, setTargetEl] = useState<Element>(null);

  const [open, setOpen] = useState(false);

  const _onClose = useCallback(() => {
    onClose?.();
    if (fullscreen) {
      window.history.back();
    }
  }, [fullscreen, onClose]);

  useImperativeHandle(
    ref,
    () => ({
      open: () => {
        setOpen(true);
        onOpen?.();
      },
      close: () => {
        setOpen(false);
        _onClose?.();
      },
    }),
    [onOpen, _onClose]
  );

  const popoverActions = useRef<PopoverActions>();

  const handleChange = (...args) => {
    picker.props.onChange.apply(undefined, args);

    // If the wrapped picker is in single-selection mode, close the popover
    // after the picker's value changes.

    const autoclose = props.autoclose ?? !picker.props.multiple;

    if (autoclose) {
      setOpen(false);
      _onClose?.();
    }
  };

  const handleResize = () => {
    popoverActions.current?.updatePosition();
  };
  const openRef = useRef(open);
  useEffect(() => {
    openRef.current = open;
  }, [open]);

  function getTarget() {
    const openClick = () => {
      onTargetClick && onTargetClick();
      if (!disabled) {
        setOpen(true);
        onOpen?.();
      }
    };
    const targetProps: IEntityPickerPopoverRenderTargetProps = {
      ref: setTargetEl,
      onClick: openClick,
    };

    const target =
      typeof children === 'function'
        ? children(targetProps)
        : React.cloneElement(children, {
            ...children.props,
            ...targetProps,
          });
    return target;
  }

  const target = getTarget();

  const resolvedAnchorEl = anchorEl
    ? 'current' in anchorEl
      ? anchorEl.current
      : anchorEl
    : targetEl;

  // Measure the width of anchorEl and set the width of popover accordingly to cover the target
  const [popoverWidth, setPopoverWidth] = useState<number | undefined>();

  useLayoutEffect(() => {
    if (width === 'content') {
      setPopoverWidth(undefined);
      return;
    }
    if (width) {
      setPopoverWidth(width);
      return;
    }
    if (open) {
      setPopoverWidth(resolvedAnchorEl?.clientWidth);
    }
  }, [open, resolvedAnchorEl, width]);

  useEffect(() => {
    if (fullscreen && open) {
      window.history.pushState({}, 'enter search');
      window.addEventListener('popstate', () => {
        setOpen(false);
        onClose?.();
      });
      return () =>
        window.addEventListener('popstate', () => {
          setOpen(false);
          onClose?.();
        });
    }
  }, [fullscreen, open, onClose]);

  return (
    <>
      {target}

      {fullscreen ? (
        <Dialog
          data-test-id="picker-container"
          fullScreen
          fullWidth
          open={open}
          classes={{ paper: styles['dialog-paper-root'] }}
          onClose={() => {
            setOpen(false);
            _onClose?.();
          }}
        >
          {React.cloneElement(picker, {
            ...picker.props,
            onChange: handleChange,
            onResize: handleResize,
            onClose: _onClose,
            fullscreen: true,
          })}
          {props.footer}
        </Dialog>
      ) : (
        <Popover
          data-test-id="picker-container"
          action={popoverActions}
          open={open}
          anchorReference="anchorEl"
          anchorEl={resolvedAnchorEl}
          anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
          onClose={() => {
            setOpen(false);
            _onClose?.();
          }}
          classes={{ paper: styles['popover-paper-root'] }}
          PaperProps={{
            style: { width: popoverWidth, ...style },
            className: className,
          }}
        >
          {React.cloneElement(picker, {
            ...picker.props,
            onChange: handleChange,
            onResize: handleResize,
          })}
          {props.footer}
        </Popover>
      )}
    </>
  );
});
