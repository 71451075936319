import styles from './BodyScroll.module.scss';

import { PropsWithChildren, SyntheticEvent, useCallback } from 'react';
import { AutoSizer } from 'react-virtualized';

import { useTableStateBag } from '../useTableStateBag';

interface HandleBodyScrollContextState {
  onScroll: (input: {
    scrollLeft: number;
    scrollWidth: number;
    clientWidth: number;
  }) => void;
}

export const BodyScroll = (
  props: PropsWithChildren<HandleBodyScrollContextState>
) => {
  const { children, onScroll } = props;

  const { tableInstance } = useTableStateBag();

  const scrollableWidth = (tableInstance?.visibleColumns ?? []).reduce(
    (prev, cur) => (typeof cur.width === 'number' ? cur.width + prev : 0),
    0
  );

  const handleScroll = useCallback(
    (event: SyntheticEvent<HTMLDivElement>) => {
      const { scrollLeft, scrollWidth, clientWidth } =
        event.currentTarget as HTMLDivElement;
      onScroll?.({
        scrollLeft,
        scrollWidth,
        clientWidth,
      });
    },
    [onScroll]
  );

  function renderContent({ width, height }: { height: number; width: number }) {
    return (
      <div
        onScroll={handleScroll}
        className={styles.root}
        style={{ width, height }}
      >
        <div className={styles.row} style={{ width: scrollableWidth }}>
          {children}
        </div>
      </div>
    );
  }

  return <AutoSizer>{renderContent}</AutoSizer>;
};
