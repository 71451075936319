import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import UndoIcon from '@mui/icons-material/Undo';
import { Fab, Theme, useMediaQuery } from '@mui/material';
import { useEventCallback } from '@mui/material/utils';
import deepmerge from 'deepmerge';
import produce from 'immer';
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { ReactComponent as TicketIcon } from '@work4all/assets/icons/ticket.svg';

import {
  ITableStateBag,
  lazyNamed,
  TableRowDisplayModifiers,
  useDialogs,
  usePatchedDefaultData,
  useTableStateBag,
} from '@work4all/components';
import type {
  CustomToolbar,
  CustomToolbarAction,
} from '@work4all/components/lib/dataDisplay/actions-bar/ToolbarTypes';
import { GroupedItems } from '@work4all/components/lib/dataDisplay/basic-table/hooks/query-table-data/types';
import { PrepareTableRowModifiers } from '@work4all/components/lib/dataDisplay/basic-table/plugins/useRowDisplayModifiers';
import { relativeDateToDate } from '@work4all/components/lib/dataDisplay/basic-table/utils/filters/filterConversion';

import { useDataProvider, useDeleteEntity } from '@work4all/data';
import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Mailbox } from '@work4all/models/lib/Classes/Mailbox.entity';
import { MailboxContent } from '@work4all/models/lib/Classes/MailboxContent.entity';
import { MailboxFolder } from '@work4all/models/lib/Classes/MailboxFolder.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { DataRequest, SortDirection } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { MailAssignStatus } from '@work4all/models/lib/Enums/MailAssignStatus.enum';
import { MailboxFolderType } from '@work4all/models/lib/Enums/MailboxFolderType.enum';
import { ITableSchema } from '@work4all/models/lib/table-schema/table-schema';

import { RelativeDateFilter } from '@work4all/utils/lib/date-utils/RelativeDateFilter.enum';
import { useOnWindowFocus } from '@work4all/utils/lib/hooks/use-on-window-focus';

import { SelectedMailboxFolder, settings, useSetting } from '../../../settings';
import { IEntityTable } from '../entity-table/EntityTable';
import { PreviewMobileWrapper } from '../PreviewMobileWrapper';
import _schema from '../schemata/mailbox-content-table-schema.json';
import { Table } from '../table/Table';
import { TableLayoutContext, useTableLayoutState } from '../table-layout';
import { TableNoRowsPlaceholder } from '../TableNoRowsPlaceholder';
import { useSelectedTableRows } from '../use-selected-table-rows';
import { useTableConfig } from '../use-table-config';
import { useUserColumnConfigs } from '../use-user-column-configs';

import { AssignToTicketDialog } from './AssignToTicketDialog';
import { BlockSenderPopover } from './BlockSenderPopover/BlockSenderPopover';
import {
  MailboxContentAssignButtonCell,
  MailboxContentBodyCell,
  MailboxContentContactCell,
  MailboxContentProjectCell,
  MailboxContentStatusCell,
  MailboxContentStatusChangeButtonCell,
} from './cells';
import {
  isBusinessPartner,
  isContact,
} from './cells/contact-or-business-partner';
import { createTicketFromMail } from './create-ticket-from-mail';
import { extendMailboxFolders } from './extend-mailbox-folders';
import { filterOutInvalidSenders } from './filter-out-invalid-senders';
import { MAILBOX_CONTENT_REQUIRED_FIELDS } from './mailbox-content-graphql-fields';
import { useMailboxContentManager } from './mailbox-content-manager';
import { MailboxContentManagerContextProvider } from './mailbox-content-manager-context';
import { MailboxContentPreview } from './MailboxContentPreview';
import {
  MailboxContentTableQuickFilters,
  MailboxContentTableQuickFiltersProps,
} from './MailboxContentTableQuickFilters';
import { MobileFolderSidebar } from './MobileFolderSidebar';
import { ExtendedMailbox, ExtendedMailboxFolder } from './types';

export const MailboxContentTable = React.forwardRef<
  TableInstance,
  IEntityTable
>(function MailboxContentTable(props, ref) {
  const schema: ITableSchema<never> = _schema as ITableSchema<never>;

  if (!schema) return null;
  return <MailboxContentTableInner {...props} ref={ref} schema={schema} />;
});

export const PAGE_SIZE = 30;

export const defaultSort = [
  { field: 'date', direction: SortDirection.ASCENDING },
];

export const MailboxContentTableInner = React.forwardRef<
  TableInstance,
  IEntityTable
>((props, ref) => {
  const { schema: schemaOriginal } = props;
  const { entity: entityType } = schemaOriginal;

  const { t } = useTranslation();

  const dialogs = useDialogs();

  const isMdUp = useMediaQuery<Theme>((t) => t.breakpoints.up('md'));

  const tableStateBag = useTableStateBag();

  const layoutState = useTableLayoutState();
  const [layout] = layoutState;

  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'));

  const mailboxesResponse = useMailboxesData();

  useOnWindowFocus(mailboxesResponse.refetch);

  const mailboxes = useExtendedMailboxes(mailboxesResponse.data);

  const { value: selectedFolderSaved, set: setSelectedFolder } = useSetting(
    settings.selectedMailboxFolder()
  );

  // Try to select the first inbox automatically.
  useEffect(() => {
    if (mailboxes.length === 0) return;

    if (
      selectedFolderSaved == null ||
      !findSelectedFolder(mailboxes, selectedFolderSaved)
    ) {
      const folder = findInboxFolder(mailboxes) ?? findAnyFolder(mailboxes);

      // Do not call with `null` if `selectedFolder` is already `null`.
      if (folder !== selectedFolderSaved) {
        setSelectedFolder(folder);
      }
    }
  }, [mailboxes, selectedFolderSaved, setSelectedFolder]);

  const { selectedFolderReal, selectedFolderObject } = useMemo(() => {
    const found = findSelectedFolder(mailboxes, selectedFolderSaved);

    if (!found)
      return {
        selectedFolderReal: null,
        selectedFolderObject: null,
      };

    return {
      selectedFolderReal: selectedFolderSaved,
      selectedFolderObject: found,
    };
  }, [mailboxes, selectedFolderSaved]);

  // Replace From column with To if the Sent Items folder is selected.
  const schemaPatched = useMemo(() => {
    if (selectedFolderObject?.folderType === MailboxFolderType.SENT_ITEMS) {
      const FROM_COLUMN_ID = 'from';

      return produce(schemaOriginal, (draft) => {
        const fromColumn = draft.columns.find(
          (column) => column.id === FROM_COLUMN_ID
        );

        if (fromColumn) {
          fromColumn.accessor = 'to';
          fromColumn.title = ['MASK.TO'];
        }
      });
    }

    return schemaOriginal;
  }, [schemaOriginal, selectedFolderObject]);

  const {
    columnConfigs,
    fields: extractedFields,
    cardConfig,
  } = useTableConfig({
    layout,
    schema: schemaPatched,
    cells: {
      MailboxContentStatus: MailboxContentStatusCell,
      MailboxContentAssignButton: MailboxContentAssignButtonCell,
      MailboxContentProject: MailboxContentProjectCell,
      MailboxContentContact: MailboxContentContactCell,
      MailboxContentBody: MailboxContentBodyCell,
      MailboxContentStatusChangeButton: MailboxContentStatusChangeButtonCell,
    },
  });

  const prepareRowDisplayModifiers = useCallback<
    PrepareTableRowModifiers<MailboxContent>
  >((row) => {
    const isAssigned =
      row.assignStatus === MailAssignStatus.ALREADY_ASSIGNED ||
      row.assignStatus === MailAssignStatus.ASSIGNED_TO_OBJECT ||
      row.assignStatus === MailAssignStatus.ASSIGNED_TO_OBJECT_AND_MAIL;
    const isIgnored = row.assignStatus === MailAssignStatus.ITEM_IGNORED;
    const isFaded = isIgnored;
    const isShade1 = isAssigned;

    const isBold = row.isRead === false;

    const modifiers: TableRowDisplayModifiers = { isShade1, isFaded, isBold };

    return modifiers;
  }, []);

  const fields = useMemo(() => {
    return deepmerge(extractedFields, MAILBOX_CONTENT_REQUIRED_FIELDS);
  }, [extractedFields]);

  const [userConfig] = useUserColumnConfigs({
    layout,
    entityType,
    columnConfigs,
  });

  const [deleteEntity] = useDeleteEntity(false);

  const { startDate, endDate, filterStatus } = useFilters(tableStateBag);

  const request = useMemo<DataRequest>(() => {
    return {
      operationName: 'GetMailboxContents',
      entity: entityType,
      skip: !selectedFolderReal,
      data: fields,
      sort: defaultSort,
      vars: {
        mailboxId: selectedFolderReal?.mailbox,
        folderId: selectedFolderReal?.folder,
        dateFrom: startDate,
        dateTo: endDate,
        filterStatus,
      },
    };
  }, [
    entityType,
    selectedFolderReal,
    fields,
    startDate,
    endDate,
    filterStatus,
  ]);

  const responseRaw = useDataProvider<MailboxContent>(
    request,
    false,
    PAGE_SIZE
  );

  const response = usePatchedDefaultData(responseRaw, PAGE_SIZE);

  const { data: rawData, fetchMore, pending, total } = response;

  const data = useMemo(() => {
    // Filter out suggested contacts of type User as they are not supported.
    const _data = filterOutInvalidSenders(rawData);
    // Remove from the list results whose status does not match the statuses
    // selected in the filters. This is a workaround to improve the user experience
    // when the list of results with updated item statuses is reloaded in the background.
    if (!filterStatus || !filterStatus.length) return _data;
    return _data?.filter((d) => filterStatus?.includes(d?.assignStatus));
  }, [filterStatus, rawData]);

  const { selectedEntity, selectedEntities } =
    useSelectedTableRows<MailboxContent>({
      data: data as GroupedItems,
      tableStateBag,
    });

  const selectedIds = useMemo(() => {
    const selectedRowIds = tableStateBag?.tableState?.selectedRowIds;

    if (!selectedRowIds) return [];

    return Object.keys(selectedRowIds);
  }, [tableStateBag?.tableState?.selectedRowIds]);

  const manager = useMailboxContentManager(
    {
      items: data,
      selectedIds,
      folder: selectedFolderReal,
      refetch: response.refetch,
    },
    useFilters(tableStateBag)
  );

  const {
    getSelected,
    canAssignSelected,
    canAssignSelectedToTicket,
    canIgnoreSelected,
    canResetSelected,
    canDeleteSelected,
    assignSelected,
    ignoreSelected,
    resetSelected,
    deleteSelected,
    markRead,
    loading,
  } = manager;

  const handleDeleteSelected = useEventCallback(async () => {
    const confirmed = await dialogs.confirm({
      title: t('ASSIGN_INCOMING_EMAILS.DELETE_ALERT.TITLE'),
      description: t('ASSIGN_INCOMING_EMAILS.DELETE_ALERT.DESCRIPTION'),
      confirmLabel: t('ALERTS.BTN_DELETE'),
      cancelLabel: t('ALERTS.BTN_ABORT'),
    });

    if (confirmed) {
      await deleteSelected();
      await response.refetch();
    }
  });

  const handleStatusActionChange = useCallback(
    async (action: () => Promise<void>) => {
      await action();
      await response.refetch();
    },
    [response]
  );

  const previousSelectedRef = useRef<MailboxContent | null>(null);

  const handleSelectedChange = useEventCallback(() => {
    const currentSelected =
      selectedEntities != null && selectedEntities.length === 1
        ? selectedEntities[0]
        : null;

    const previousSelected = previousSelectedRef.current;

    if (previousSelected != null) {
      const shouldMarkAsRead =
        previousSelected.isRead === false &&
        selectedEntities != null &&
        (selectedEntities.length === 0 ||
          (selectedEntities.length === 1 &&
            previousSelected.id !== selectedEntities[0].id));

      if (shouldMarkAsRead) {
        markRead([previousSelected.id]);
      }
    }

    previousSelectedRef.current = currentSelected;
  });

  useEffect(() => {
    handleSelectedChange();
  }, [handleSelectedChange, selectedEntities]);

  const canAssign = canAssignSelected();
  const canAssignToTicket = canAssignSelectedToTicket();
  const canIgnore = canIgnoreSelected();
  const canReset = canResetSelected();
  const canDelete = canDeleteSelected();
  const canBlockSender = getSelected().length === 1;

  const [isAssignToTicketDialogOpen, setAssignToTicketDialogOpen] = useState<{
    open: boolean;
    loading: boolean;
    error: boolean;
    businessPartner?: Customer | Supplier;
  }>({ open: false, loading: false, error: false, businessPartner: null });

  const handleAssignToTicketClick = useCallback(() => {
    const [selected] = getSelected();

    if (!selected) {
      return;
    }

    const contact = selected.contact;

    let businessPartner: Customer | Supplier | null = null;

    if (isBusinessPartner(contact)) {
      businessPartner = contact;
    }

    if (isContact(contact)) {
      businessPartner = contact.businessPartner?.data;
    }

    setAssignToTicketDialogOpen({
      open: true,
      loading: false,
      error: false,
      businessPartner: businessPartner,
    });
  }, [getSelected]);

  const handleAssignToTicket = useCallback(
    async (ticketId: string) => {
      setAssignToTicketDialogOpen((state) => {
        return { ...state, loading: true };
      });

      try {
        const ids = manager.state.selectedIds;

        if (ids.length === 0) {
          return;
        }

        const id = ids[0];

        await manager.assignToTicket(id, ticketId);

        setAssignToTicketDialogOpen((state) => {
          return { ...state, open: false };
        });
      } catch {
        setAssignToTicketDialogOpen({
          open: true,
          error: true,
          loading: false,
        });
      }
    },
    [manager]
  );

  function handleCloseAssignToTicketDialog(): void {
    setAssignToTicketDialogOpen((state) => {
      return { ...state, open: false };
    });
  }

  const [blockSenderPopoverAddress, setBlockSenderPopoverAddress] =
    useState<string>('');

  const blockSenderPopoverState = usePopoverState();

  const handleBlockSenderClick = useEventCallback(
    (_value: unknown, event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      const [selected] = getSelected();

      if (!selected) {
        return;
      }

      const address = selected.data.from;

      setBlockSenderPopoverAddress(address);
      blockSenderPopoverState.handleClick(event);
    }
  );

  const customToolbar = useMemo<CustomToolbar>(() => {
    const actions: CustomToolbarAction[] = [];

    if (canAssign) {
      actions.push({
        actionKey: 'assign',
        title: t('ASSIGN_INCOMING_EMAILS.ASSIGN'),
        IconComponent: CheckCircleOutlineIcon,
        handler: () => handleStatusActionChange(assignSelected),
        mode: 'Add',
      });
    }

    if (canIgnore) {
      actions.push({
        actionKey: 'ignore',
        title: t('ASSIGN_INCOMING_EMAILS.IGNORE'),
        IconComponent: CloseIcon,
        handler: () => handleStatusActionChange(ignoreSelected),
        mode: 'Add',
      });
    }

    if (canAssignToTicket) {
      actions.push({
        actionKey: 'ticket',
        title: t('ASSIGN_INCOMING_EMAILS.TICKET'),
        IconComponent: TicketIcon,
        handler: handleAssignToTicketClick,
        mode: 'Add',
      });
    }

    if (canReset) {
      actions.push({
        actionKey: 'undo',
        title: 'Undo',
        IconComponent: UndoIcon,
        handler: () => handleStatusActionChange(resetSelected),
        mode: 'Add',
      });
    }

    if (canDelete) {
      actions.push({
        actionKey: 'delete',
        title: t('ASSIGN_INCOMING_EMAILS.DELETE'),
        IconComponent: DeleteIcon,
        handler: handleDeleteSelected,
        mode: 'Delete',
      });
    }

    if (canBlockSender) {
      actions.push({
        actionKey: 'block-sender',
        title: t('ASSIGN_INCOMING_EMAILS.BLOCK_SENDER'),
        IconComponent: PersonOffIcon,
        handler: handleBlockSenderClick,
        mode: 'Add',
      });
    }

    const toolbar: CustomToolbar = {
      hideDivider: true,
      left: actions,
    };

    return toolbar;
  }, [
    canAssign,
    canIgnore,
    canAssignToTicket,
    canReset,
    canDelete,
    canBlockSender,
    t,
    handleStatusActionChange,
    assignSelected,
    ignoreSelected,
    handleAssignToTicketClick,
    resetSelected,
    handleDeleteSelected,
    handleBlockSenderClick,
  ]);

  const mobileActions = useMemo(() => {
    return {
      additionalActionsLeft: (
        <>
          {canAssignToTicket && (
            <Fab size="medium" onClick={handleAssignToTicketClick}>
              <TicketIcon />
            </Fab>
          )}

          {canReset && (
            <Fab
              size="medium"
              onClick={() => handleStatusActionChange(resetSelected)}
            >
              <UndoIcon />
            </Fab>
          )}

          {canIgnore && (
            <Fab
              size="medium"
              onClick={() => handleStatusActionChange(ignoreSelected)}
            >
              <CloseIcon />
            </Fab>
          )}

          {canAssign && (
            <Fab
              size="medium"
              color="primary"
              onClick={() => handleStatusActionChange(assignSelected)}
            >
              <CheckCircleOutlineIcon />
            </Fab>
          )}
        </>
      ),
    };
  }, [
    canAssignToTicket,
    handleAssignToTicketClick,
    canReset,
    resetSelected,
    canIgnore,
    canAssign,
    handleStatusActionChange,
    ignoreSelected,
    assignSelected,
  ]);

  const [ticketMaskState, setTicketMaskState] = useState<{
    open: boolean;
    params: { presetFields?: string };
  }>({ open: false, params: {} });

  const handleTicketCloseMask = useCallback(() => {
    setTicketMaskState((state) => {
      return { ...state, open: false };
    });
  }, []);

  const handleTicketAfterSave = useCallback(
    (ticket: Ticket) => {
      setTicketMaskState((state) => {
        return { ...state, open: false };
      });

      handleAssignToTicket(ticket.id);
    },
    [handleAssignToTicket]
  );

  const handleCreateTicketClick = () => {
    const [selected] = getSelected();

    if (!selected) {
      return;
    }

    const preset = createTicketFromMail(selected);

    setTicketMaskState({
      open: true,
      params: { presetFields: JSON.stringify(preset) },
    });
  };

  const handleDeleteMailbox = async (mailbox: Mailbox) => {
    await deleteEntity({
      type: Entities.mailbox,
      ids: [mailbox.id],
    });

    mailboxesResponse.refetch();
  };

  const entityIds = useMemo(() => {
    return selectedEntities?.map((x) => x.id) ?? [];
  }, [selectedEntities]);

  if (!userConfig) return null;

  const folderSidebarProps: MailboxContentTableQuickFiltersProps = {
    selectedFolder: selectedFolderSaved,
    onSelectedFolderChange: setSelectedFolder,
    mailboxes: mailboxes,
    onDeleteMailbox: handleDeleteMailbox,
    onAfterBlockedSendersChange() {
      response.refetch();
    },
    loading: mailboxesResponse.loading,
  };

  const customVisibleColumns = ['possibleSenders', 'possibleProjects'];

  return (
    <MailboxContentManagerContextProvider value={manager}>
      {isMdUp ? null : <MobileFolderSidebar {...folderSidebarProps} />}
      <BlockSenderPopover
        address={blockSenderPopoverAddress}
        popoverState={blockSenderPopoverState}
        onAfterBlockedSendersChange={() => {
          response.refetch();
        }}
      />
      <AssignToTicketDialog
        open={isAssignToTicketDialogOpen.open}
        loading={isAssignToTicketDialogOpen.loading}
        error={isAssignToTicketDialogOpen.error}
        businessPartner={isAssignToTicketDialogOpen.businessPartner}
        onClose={handleCloseAssignToTicketDialog}
        onAssign={handleAssignToTicket}
        onCreateTicket={handleCreateTicketClick}
      />
      <Suspense fallback={null}>
        <MaskModal
          entity={Entities.ticket}
          id={null}
          open={ticketMaskState.open}
          params={ticketMaskState.params}
          onClose={handleTicketCloseMask}
          onAfterSave={handleTicketAfterSave}
        />
      </Suspense>
      <TableLayoutContext value={layoutState}>
        <Table
          pending={pending || loading}
          noRowsRenderer={() => <TableNoRowsPlaceholder />}
          ref={ref}
          layout={layout}
          cardConfig={cardConfig}
          areas={{
            left: {
              content: (
                <MailboxContentTableQuickFilters {...folderSidebarProps} />
              ),
              resizable: true,
            },
            right: selectedEntity !== null && {
              content: (
                <PreviewMobileWrapper
                  active={!isDesktop}
                  actions={mobileActions}
                  entityIds={entityIds}
                >
                  <MailboxContentPreview
                    mailboxContent={selectedEntity}
                    onCloseClicked={() => {
                      tableStateBag.tableInstance.toggleAllRowsSelected(false);
                    }}
                  />
                </PreviewMobileWrapper>
              ),
            },
          }}
          actions={{
            custom: customToolbar,
          }}
          columnConfigs={userConfig}
          customVisibleColumns={customVisibleColumns}
          loadMoreItems={fetchMore}
          prepareRowDisplayModifiers={prepareRowDisplayModifiers}
          data={data}
          total={total}
          noLockableEntity
          onRowDoubleClick={(_id) => {
            return true;
          }}
        />
      </TableLayoutContext>
    </MailboxContentManagerContextProvider>
  );
});

// Hack: I use lazy import here because without it TypeScript doesn't compile.
//
// I don't know why, and I don't have time to figure it out. We'll deal with
// this later together with the rest of TypeScript issues.
//
// The component is not actually lazy loaded, but this resolves the error due to
// changed order of imports, I guess.
const MaskModal = lazyNamed(
  () => import('../../entity-picker/MaskModal'),
  'MaskModal'
);

// Helpers

function useMailboxesData() {
  const mailboxesRequest = useMemo<DataRequest>(() => {
    const data: Mailbox<EMode.query> = {
      id: null,
      mailboxPrimaryAddress: null,
      loadErrorType: null,
      loadErrorMessage: null,
      folder: [
        {
          id: null,
          name: null,
          folderType: null,
          unreadItemsCount: null,
          parentId: null,
        },
      ],
    };

    return {
      entity: Entities.mailbox,
      data,
      completeDataResponse: true,
      operationName: 'GetMailboxes',
    };
  }, []);

  return useDataProvider<Mailbox>(mailboxesRequest);
}

function isInboxFolder(folder: MailboxFolder) {
  return folder.folderType === MailboxFolderType.INBOX;
}

function findInboxFolder(mailboxes: Mailbox[]): SelectedMailboxFolder | null {
  const firstInbox = mailboxes.find((mailbox) => {
    return mailbox.folder?.some(isInboxFolder);
  });

  if (!firstInbox) return null;

  const firstFolder = firstInbox.folder.find(isInboxFolder);

  return {
    mailbox: firstInbox.id,
    folder: firstFolder.id,
  };
}

function findAnyFolder(mailboxes: Mailbox[]): SelectedMailboxFolder | null {
  const firstInbox = mailboxes.find((mailbox) => {
    return mailbox.folder?.length > 0;
  });

  if (!firstInbox) return null;

  const firstFolder = firstInbox.folder[0];

  return {
    mailbox: firstInbox.id,
    folder: firstFolder.id,
  };
}

function findSelectedFolder(
  mailboxes: ExtendedMailbox[],
  selected: SelectedMailboxFolder
): ExtendedMailboxFolder | null {
  const mailbox = mailboxes.find((mailbox) => {
    return mailbox.id === selected?.mailbox;
  });

  if (!mailbox) return null;

  const folder = mailbox.folder?.find((folder) => {
    return folder.id === selected?.folder && folder.isVisible;
  });

  if (!folder) return null;

  return folder;
}

function useExtendedMailboxes(mailboxes: Mailbox[]) {
  const setting = useSetting(settings.mailboxFolderVisibility());

  // Remove ignored folders, mark hidden folders and don't process mailboxes
  // with errors, as these won't have the folders info.
  const extendedMailboxes = useMemo(() => {
    return extendMailboxFolders(mailboxes, setting.value);
  }, [mailboxes, setting.value]);

  return extendedMailboxes;
}

export interface MailboxContentFilters {
  startDate: string | null;
  endDate: string | null;
  filterStatus: string[] | null;
}

function useFilters(tableStateBag: ITableStateBag): MailboxContentFilters {
  const { startDate, endDate } = useMemo<{
    startDate: string | null;
    endDate: string | null;
  }>(() => {
    const dateFilter = tableStateBag.tableState?.filters?.find((col) => {
      return col.id === 'date';
    });

    if (!dateFilter) {
      return { startDate: null, endDate: null };
    }

    const {
      startDate = null,
      endDate = null,
    }: { startDate: Date | RelativeDateFilter; endDate: Date } =
      dateFilter.value?.value ?? {};

    const date = relativeDateToDate(startDate, endDate);

    return {
      startDate: !date?.startDate ? null : date.startDate.toISODate(),
      endDate: !date?.endDate ? null : date.endDate.toISODate(),
    };
  }, [tableStateBag.tableState?.filters]);

  const { filterStatus } = useMemo<{
    filterStatus: string[] | null;
  }>(() => {
    const statusFilter = tableStateBag.tableState?.filters?.find((col) => {
      return col.id === 'status';
    });

    if (!statusFilter) {
      return { filterStatus: null };
    }

    const ids = statusFilter.value.value.map((item) => item.id) ?? null;

    return { filterStatus: ids };
  }, [tableStateBag.tableState?.filters]);

  return {
    startDate,
    endDate,
    filterStatus,
  };
}
