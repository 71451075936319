import { useTranslation } from 'react-i18next';

import { REBelegart } from '@work4all/models/lib/Enums/REBelegart.enum';

type Props = {
  value: REBelegart;
};

export const InvoiceKindCell = ({ value }: Props) => {
  const { t } = useTranslation();

  return t(`INVOICE_KINDS.${value}`);
};
