import styles from './BomVariantExample.module.scss';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, IconButton, Typography } from '@mui/material';
import clsx from 'clsx';
import React, { useState } from 'react';

import { InsertBomStyle } from '@work4all/models/lib/Enums/InsertBomStyle.enum';

import { BomVariant } from './BomVariantPicker';

interface BomVariantExampleProps {
  variant: BomVariant;
}

interface RowProps {
  number: string | JSX.Element;
  name: string | JSX.Element;
  currency: string;
  index?: number;
  expandable?: boolean;
}

const sampleHeader: RowProps = {
  number: '1',
  name: 'Desktop PC',
  currency: '2.000,00€',
};

const sampleData: RowProps[] = [
  { number: '1.1', name: 'Mainboard', currency: '350,00€' },
  { number: '1.2', name: 'Grafikkarte', currency: '1.500,00€' },
  { number: '1.3', name: 'RAM', currency: '150,00€' },
];

export const BomVariantExample = (props: BomVariantExampleProps) => {
  const { variant } = props;
  const Header = ({ expandable, number, name, currency }: RowProps) => (
    <React.Fragment>
      <Typography
        variant="body2"
        className={clsx(styles.row, styles.first, {
          [styles.header]: !expandable,
        })}
      >
        {number}
        {expandable && <div className={styles.longLine} />}
      </Typography>
      <Typography
        variant="body2"
        className={clsx(styles.row, {
          [styles.header]: !expandable,
          [styles.double]: !expandable,
        })}
      >
        {name}
      </Typography>
      {expandable && (
        <Typography
          variant="body2"
          className={clsx(styles.row, styles.last, styles.gray)}
        >
          {currency}
        </Typography>
      )}
    </React.Fragment>
  );

  const Row = ({ number, name, currency, index, expandable }: RowProps) => (
    <React.Fragment>
      {expandable ? (
        <div className={clsx(styles.first)}>
          <div
            className={clsx(styles.line, {
              [styles.firstLine]: index === 0,
            })}
          />
        </div>
      ) : (
        <Typography variant="body2" className={clsx(styles.row, styles.first)}>
          {number}
        </Typography>
      )}

      <Typography variant="body2" className={styles.row}>
        {name}
      </Typography>
      <Typography variant="body2" className={clsx(styles.row, styles.last)}>
        {currency}
      </Typography>
    </React.Fragment>
  );

  const [open, setOpen] = useState(true);

  switch (variant.id) {
    case InsertBomStyle.BOM_STYLE_1:
      return (
        <div className={styles.container}>
          <Header {...sampleHeader} />
          {sampleData.map((x) => (
            <Row {...x} />
          ))}
        </div>
      );
    case InsertBomStyle.BOM_STYLE_2:
      return (
        <div className={styles.container}>
          {sampleData.map((x) => (
            <Row {...x} />
          ))}
        </div>
      );
    case InsertBomStyle.BOM_STYLE_3:
      return (
        <div className={styles.container}>
          <Row {...sampleHeader} />
        </div>
      );
    case InsertBomStyle.BOM_STYLE_4:
      return (
        <div className={styles.container}>
          <Row
            {...sampleHeader}
            name={
              <React.Fragment>
                {sampleHeader.name}
                <ul className={styles.list}>
                  {sampleData.map((x) => (
                    <li>{x.name}</li>
                  ))}
                </ul>
              </React.Fragment>
            }
          />
        </div>
      );
    case InsertBomStyle.BOM_STYLE_6:
      return (
        <div className={styles.container}>
          <Header
            expandable
            {...sampleHeader}
            number={
              <Box position="relative">
                <IconButton
                  className={clsx(styles.icon)}
                  onClick={() => setOpen((x) => !x)}
                >
                  <ExpandMoreIcon
                    className={clsx({
                      [styles.iconCollapsed]: !open,
                    })}
                  />
                </IconButton>

                {sampleHeader.number}
              </Box>
            }
          />
          {open &&
            sampleData.map((x, idx) => <Row {...x} index={idx} expandable />)}
        </div>
      );
    case InsertBomStyle.BOM_STYLE_5:
      return (
        <div className={styles.container}>
          <Header
            expandable
            {...sampleHeader}
            name={
              <React.Fragment>
                {sampleHeader.name}
                <ul className={styles.list}>
                  {sampleData.map((x) => (
                    <li>{x.name}</li>
                  ))}
                </ul>
              </React.Fragment>
            }
            number={
              <Box position="relative">
                <IconButton
                  className={clsx(styles.icon)}
                  onClick={() => setOpen((x) => !x)}
                >
                  <ExpandMoreIcon
                    className={clsx({
                      [styles.iconCollapsed]: !open,
                    })}
                  />
                </IconButton>

                {sampleHeader.number}
              </Box>
            }
          />
          {open &&
            sampleData.map((x, idx) => <Row {...x} index={idx} expandable />)}
        </div>
      );
    default:
      return (
        <div className={styles.container}>
          <Header {...sampleHeader} />
          {sampleData.map((x) => (
            <Row {...x} />
          ))}
        </div>
      );
  }
};
