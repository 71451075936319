import styles from './ArticleSinglePriceCell.module.scss';

import { useTranslation } from 'react-i18next';

import { Article } from '@work4all/models/lib/Classes/Article.entity';

import { Tooltip } from '../../../../components/tooltip/Tooltip';

import { createFooterCell } from './create-footer-cell';
import { CurrencyCell } from './currencyCell';

export type IArticleSinglePriceCellProps = {
  children?: React.ReactNode;
  value: string | number;
  column?: {
    id: string;
    cellParams: {
      priceGroupId: number;
      currencyField?: string;
      courseField?: string;
      hideUnit?: boolean;
    };
  };
  row?: {
    original: unknown;
  };
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  hideUnit?: boolean;
  className?: string;
  onEdit?: () => void;
  currency?: string;
};

export const ArticleSinglePriceCell: React.FC<IArticleSinglePriceCellProps> = (
  props
) => {
  const { t } = useTranslation();

  const {
    row,
    column: {
      cellParams: { priceGroupId },
    },
  } = props;

  let value = 0;
  const article = row.original as Article;

  if (article.hasBulkPrices) {
    const bulkPrice = article.bulkPriceList
      ?.filter((x) => x.priceGroupId === priceGroupId && !x.sdObjMemberCode)
      .sort((a, b) =>
        a.bulkPriceTierItem?.edgeValue1 > b.bulkPriceTierItem?.edgeValue1
          ? 1
          : -1
      );
    if (bulkPrice?.length) {
      const table2 = (
        <table cellPadding={'4rem'} cellSpacing={0}>
          <tr>
            <td>{t('COMMON.TIER')}</td>
            {bulkPrice.map((x) => {
              return <td align="center">{x.bulkPriceTierItem?.name}</td>;
            })}
          </tr>
          <tr>
            <td>{t('COMMON.PRICE')}</td>
            {bulkPrice.map((x) => {
              return (
                <td align="center">
                  <CurrencyCell value={x.price} hideUnit />
                </td>
              );
            })}
          </tr>
        </table>
      );

      return (
        <div style={{ textAlign: 'right', paddingRight: '.5rem' }}>
          <Tooltip
            color=""
            title={table2}
            placement="bottom"
            classes={{
              tooltip: styles.tooltip,
            }}
          >
            <span className={styles.scale}>{t('COMMON.PRICE_SCALE')}</span>
          </Tooltip>
        </div>
      );
    }
    return '';
  }

  if (priceGroupId <= 2) {
    value = article['netPrice' + (priceGroupId !== 0 ? priceGroupId : '')] || 0;
  } else {
    const singlePrices = article?.articlePrices?.find((x) =>
      x.singlePriceList?.find((y) => y.priceGroupId === priceGroupId)
    )?.singlePriceList;
    value =
      singlePrices?.find((y) => y.priceGroupId === priceGroupId)?.price || 0;
  }

  return <CurrencyCell {...props} value={value} />;
};

export const ArticleSinglePriceFooterCell = createFooterCell(
  ArticleSinglePriceCell,
  0
);
