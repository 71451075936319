import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { AddressConnection } from './AddressConnection.entity';
import { Appearance } from './Appearance.entity';
import { Appointment } from './Appointment.entity';
import { AppointmentAttachment } from './AppointmentAttachment.entity';
import { BzObjectConversionResult } from './BzObjectConversionResult.entity';
import { CallMemo } from './CallMemo.entity';
import { CallMemoAttachment } from './CallMemoAttachment.entity';
import { CheckList } from './CheckList.entity';
import { CheckListPosition } from './CheckListPosition.entity';
import { CommentEvent } from './CommentEvent.entity';
import { Contact } from './Contact.entity';
import { ConvertTempFileResult } from './ConvertTempFileResult.entity';
import { Customer } from './Customer.entity';
import { DeleteEntityResult } from './DeleteEntityResult.entity';
import { Document } from './Document.entity';
import { DuplicateResponse } from './DuplicateResponse.entity';
import { EMail } from './EMail.entity';
import { EMailSignature } from './EMailSignature.entity';
import { EMailTemplate } from './EMailTemplate.entity';
import { EMailTemplateGroup } from './EMailTemplateGroup.entity';
import { InboundInvoice } from './InboundInvoice.entity';
import { IncomingMailBlockedSender } from './IncomingMailBlockedSender.entity';
import { Layout } from './Layout.entity';
import { Letter } from './Letter.entity';
import { MailboxContent } from './MailboxContent.entity';
import { ModifyShadowBzObjectResult } from './ModifyShadowBzObjectResult.entity';
import { ModifyShadowREResult } from './ModifyShadowREResult.entity';
import { Note } from './Note.entity';
import { NoteAttachment } from './NoteAttachment.entity';
import { ObjectLockResult } from './ObjectLockResult.entity';
import { OcrInvoiceData } from './OcrInvoiceData.entity';
import { ProcessWordTemplateResult } from './ProcessWordTemplateResult.entity';
import { Project } from './Project.entity';
import { ProjectAccessGroup } from './ProjectAccessGroup.entity';
import { ProjectAccessRights } from './ProjectAccessRights.entity';
import { ProjectDirectoryDefinition } from './ProjectDirectoryDefinition.entity';
import { Ra } from './Ra.entity';
import { SalesOpportunityRatingStatus } from './SalesOpportunityRatingStatus.entity';
import { SavedListFilter } from './SavedListFilter.entity';
import { SaveSendMailJob } from './SaveSendMailJob.entity';
import { SendEMailResult } from './SendEMailResult.entity';
import { SetMfaModeResponse } from './SetMfaModeResponse.entity';
import { SetUserPresenceResult } from './SetUserPresenceResult.entity';
import { Sickness } from './Sickness.entity';
import { Supplier } from './Supplier.entity';
import { Task } from './Task.entity';
import { TaskAttachment } from './TaskAttachment.entity';
import { TempFile } from './TempFile.entity';
import { TextBuildingBlock } from './TextBuildingBlock.entity';
import { Ticket } from './Ticket.entity';
import { TicketFilter } from './TicketFilter.entity';
import { TimeTracking } from './TimeTracking.entity';
import { User } from './User.entity';
import { UserCollection } from './UserCollection.entity';
import { UserPresenceTimeInfoItem } from './UserPresenceTimeInfoItem.entity';
import { Vacation } from './Vacation.entity';
import { ValidateSetMfaModeResponse } from './ValidateSetMfaModeResponse.entity';
import { VaultSettingItem } from './VaultSettingItem.entity';
import { VisitationReport } from './VisitationReport.entity';
import { WordDocumentTemplate } from './WordDocumentTemplate.entity';
import { WordLetterTemplate } from './WordLetterTemplate.entity';

export class Mutation<T extends EMode = EMode.entity> {
  /** Alias: deleteSetting */
  deleteSetting?: boolean;
  /** Alias: setSetting */
  setSetting?: VaultSettingItem<T>;
  /** Alias: upsertAufgabe */
  upsertTask?: Task<T>;
  /** Alias: upsertBrief */
  upsertLetter?: Letter<T>;
  /** Alias: upsertDokument */
  upsertDocument?: Document<T>;
  /** Alias: upsertKunde */
  upsertCustomer?: Customer<T>;
  /** Alias: upsertLayout */
  upsertLayout?: Layout<T>;
  /** Alias: upsertLieferant */
  upsertSupplier?: Supplier<T>;
  /** Alias: upsertNotiz */
  upsertNote?: Note<T>;
  /** Alias: upsertProjekt */
  upsertProject?: Project<T>;
  /** Alias: upsertTelefonat */
  upsertPhoneCall?: CallMemo<T>;
  /** Alias: upsertTermin */
  upsertAppointment?: Appointment<T>;
  /** Alias: createShadowBzObject */
  createShadowBzObject?: ModifyShadowBzObjectResult<T>;
  /** Alias: deleteShadowBzObject */
  deleteShadowBzObject?: boolean;
  /** Alias: modifyShadowBzObject */
  modifyShadowBzObject?: ModifyShadowBzObjectResult<T>;
  /** Alias: persistShadowBzObject */
  persistShadowBzObject?: ModifyShadowBzObjectResult<T>;
  /** Alias: setObjectLock */
  setObjectLock?: ObjectLockResult<T>;
  /** Alias: removeObjectLock */
  removeObjectLock?: boolean;
  /** Alias: upsertZeiterfassung */
  upsertZeiterfassung?: TimeTracking<T>;
  /** Alias: upsertCheckliste */
  upsertCheckList?: CheckList<T>;
  /** Alias: upsertChecklistePosition */
  upsertCheckListPosition?: CheckListPosition<T>;
  /** Alias: upsertAnsprechpartner */
  upsertContact?: Contact<T>;
  /** Alias: upsertAdressVerknuepfung */
  upsertAddressConnection?: AddressConnection<T>;
  /** Alias: deleteEntity */
  deleteEntity?: DeleteEntityResult<T>;
  /** Alias: modifyShadowBzObjectAddPosition */
  modifyShadowBzObjectAddPosition?: ModifyShadowBzObjectResult<T>;
  /** Alias: modifyShadowBzObjectRemovePosition */
  modifyShadowBzObjectRemovePosition?: ModifyShadowBzObjectResult<T>;
  /** Alias: modifyShadowBzObjectMovePosition */
  modifyShadowBzObjectMovePosition?: ModifyShadowBzObjectResult<T>;
  /** Alias: upsertEMail */
  upsertEMail?: EMail<T>;
  /** Alias: upsertTelefonatAnhang */
  upsertCallMemoAttachment?: CallMemoAttachment<T>;
  /** Alias: upsertTerminAnhang */
  upsertAppointmentAttachment?: AppointmentAttachment<T>;
  /** Alias: upsertNotizAnhang */
  upsertNoteAttachment?: NoteAttachment<T>;
  /** Alias: upsertAufgabeAnhang */
  upsertTaskAttachment?: TaskAttachment<T>;
  /** Alias: sendMail */
  sendMail?: SendEMailResult<T>;
  /** Alias: upsertEMailVorlage */
  upsertEMailTemplate?: EMailTemplate<T>;
  /** Alias: upsertEMailSignatur */
  upsertEMailSignature?: EMailSignature<T>;
  /** Alias: modifyShadowBzObjectModifyPosition */
  modifyShadowBzObjectModifyPosition?: ModifyShadowBzObjectResult<T>;
  /** Alias: upsertTicket */
  upsertTicket?: Ticket<T>;
  /** Alias: upsertBesuchsbericht */
  upsertBesuchsbericht?: VisitationReport<T>;
  /** Alias: interpretTempFileAsIncommingInvoice */
  interpretedTempFileAsIncommingInvoice?: OcrInvoiceData<T>;
  /** Alias: processWordTemplate */
  processWordTemplate?: ProcessWordTemplateResult<T>;
  /** Alias: setUserPresenceTimestamp */
  setUserPresenceTimestamp?: SetUserPresenceResult<T>;
  /** Alias: modifyAppearance */
  modifyAppearance?: Appearance<T>;
  /** Alias: modifyUserAppearanceConfiguration */
  modifyUserAppearanceConfiguration?: Appearance<T>;
  /** Alias: createShadowRe */
  createShadowRe?: ModifyShadowREResult<T>;
  /** Alias: deleteShadowRe */
  deleteShadowRe?: boolean;
  /** Alias: modifyShadowREModifyLine */
  modifyShadowREModifyLine?: ModifyShadowREResult<T>;
  /** Alias: modifyShadowReAddBuchung */
  modifyShadowReAddBuchung?: ModifyShadowREResult<T>;
  /** Alias: modifyShadowReRemoveBuchung */
  modifyShadowReRemoveBuchung?: ModifyShadowREResult<T>;
  /** Alias: persistShadowRe */
  persistShadowRe?: ModifyShadowREResult<T>;
  /** Alias: upsertUrlaub */
  upsertVacation?: Vacation<T>;
  /** Alias: upsertKrankheit */
  upsertSickness?: Sickness<T>;
  /** Alias: addComment */
  addComment?: CommentEvent<T>;
  /** Alias: startJobSaveSendMail */
  startJobSaveSendMail?: SaveSendMailJob<T>;
  /** Alias: upsertDokumentForPreview */
  upsertDocumentForPreview?: string;
  /** Alias: upsertBriefForPreview */
  upsertLetterForPreview?: string;
  /** Alias: upsertVacationRequest */
  upsertVacationRequest?: string;
  /** Alias: upsertKommtGehtZeitstempel */
  upsertKommtGehtZeitstempel?: UserPresenceTimeInfoItem<T>;
  /** Alias: upsertUserCollection */
  upsertUserCollection?: UserCollection<T>;
  /** Alias: upsertEMailTemplateGroup */
  upsertEMailTemplateGroup?: EMailTemplateGroup<T>;
  /** Alias: modifyShadowRe */
  modifyShadowRe?: ModifyShadowREResult<T>;
  /** Alias: upsertTextBuildingBlock */
  upsertTextBuildingBlock?: TextBuildingBlock<T>;
  /** Alias: upsertTicketFilter */
  upsertTicketFilter?: TicketFilter<T>;
  /** Alias: createTempFile */
  createTempFile?: TempFile<T>;
  /** Alias: upsertSavedListFilter */
  upsertSavedListFilter?: SavedListFilter<T>;
  /** Alias: upsertVerkaufschance */
  upsertVerkaufschance?: string;
  /** Alias: assignMail */
  assignMail?: MailboxContent<T>;
  /** Alias: ignoreMail */
  ignoreMail?: boolean;
  /** Alias: setMailState */
  setMailState?: boolean;
  /** Alias: setProjectAccessRights */
  setProjectAccessRights?: ProjectAccessRights<T>[];
  /** Alias: unignoreMail */
  unignoreMail?: boolean;
  /** Alias: setNotificationRead */
  setNotificationRead?: boolean;
  /** Alias: updateBenutzer */
  updateBenutzer?: User<T>;
  /** Alias: upsertSalesOpportunityRatingStatus */
  upsertSalesOpportunityRatingStatus?: SalesOpportunityRatingStatus<T>;
  /** Alias: upsertProjectAccessGroup */
  upsertProjectAccessGroup?: ProjectAccessGroup<T>;
  /** Alias: archiveMail */
  archiveMail?: boolean;
  /** Alias: deleteMail */
  deleteMail?: boolean;
  /** Alias: convertBzObject */
  convertBzObject?: BzObjectConversionResult<T>;
  /** Alias: convertTempFile */
  convertTempFile?: ConvertTempFileResult<T>[];
  /** Alias: convertMailToTempfile */
  convertMailToTempfile?: TempFile<T>;
  /** Alias: assignMailToObject */
  assignMailToObject?: MailboxContent<T>;
  /** Alias: duplicateEntity */
  duplicateEntity?: DuplicateResponse<T>;
  /** Alias: upsertOutgoingInvoiceLedger */
  upsertOutgoingInvoiceLedger?: Ra<T>;
  /** Alias: upsertIncomingInvoiceLedger */
  upsertIncomingInvoiceLedger?: InboundInvoice<T>;
  /** Alias: upsertLetterTemplate */
  upsertLetterTemplate?: WordLetterTemplate<T>;
  /** Alias: upsertSimpleReceipt */
  upsertSimpleReceipt?: string;
  /** Alias: upsertDocumentTemplate */
  upsertDocumentTemplate?: WordDocumentTemplate<T>;
  /** Alias: upsertIncomingMailBlockedSender */
  upsertIncomingMailBlockedSender?: IncomingMailBlockedSender<T>;
  /** Alias: updateUserMfaMode */
  updateUserMfaMode?: SetMfaModeResponse<T>;
  /** Alias: validateUserMfaMode */
  validateUserMfaMode?: ValidateSetMfaModeResponse<T>;
  /** Alias: editComment */
  editComment?: CommentEvent<T>;
  /** Alias: upsertProjectDirectoryDefinition */
  upsertProjectDirectoryDefinition?: ProjectDirectoryDefinition<T>;

  __typename?: string;
}

const fields: FieldDefinitions<Mutation> = {
  deleteSetting: {
    alias: 'deleteSetting',
  },
  setSetting: {
    alias: 'setSetting',
    entity: Entities.vaultSettingItem,
  },
  upsertTask: {
    alias: 'upsertAufgabe',
    entity: Entities.task,
  },
  upsertLetter: {
    alias: 'upsertBrief',
    entity: Entities.letter,
  },
  upsertDocument: {
    alias: 'upsertDokument',
    entity: Entities.document,
  },
  upsertCustomer: {
    alias: 'upsertKunde',
    entity: Entities.customer,
  },
  upsertLayout: {
    alias: 'upsertLayout',
    entity: Entities.layout,
  },
  upsertSupplier: {
    alias: 'upsertLieferant',
    entity: Entities.supplier,
  },
  upsertNote: {
    alias: 'upsertNotiz',
    entity: Entities.note,
  },
  upsertProject: {
    alias: 'upsertProjekt',
    entity: Entities.project,
  },
  upsertPhoneCall: {
    alias: 'upsertTelefonat',
    entity: Entities.callMemo,
  },
  upsertAppointment: {
    alias: 'upsertTermin',
    entity: Entities.appointment,
  },
  createShadowBzObject: {
    alias: 'createShadowBzObject',
    entity: Entities.modifyShadowBzObjectResult,
  },
  deleteShadowBzObject: {
    alias: 'deleteShadowBzObject',
  },
  modifyShadowBzObject: {
    alias: 'modifyShadowBzObject',
    entity: Entities.modifyShadowBzObjectResult,
  },
  persistShadowBzObject: {
    alias: 'persistShadowBzObject',
    entity: Entities.modifyShadowBzObjectResult,
  },
  setObjectLock: {
    alias: 'setObjectLock',
    entity: Entities.objectLockResult,
  },
  removeObjectLock: {
    alias: 'removeObjectLock',
  },
  upsertZeiterfassung: {
    alias: 'upsertZeiterfassung',
    entity: Entities.timeTracking,
  },
  upsertCheckList: {
    alias: 'upsertCheckliste',
    entity: Entities.checkList,
  },
  upsertCheckListPosition: {
    alias: 'upsertChecklistePosition',
    entity: Entities.checkListPosition,
  },
  upsertContact: {
    alias: 'upsertAnsprechpartner',
    entity: Entities.contact,
  },
  upsertAddressConnection: {
    alias: 'upsertAdressVerknuepfung',
    entity: Entities.addressConnection,
  },
  deleteEntity: {
    alias: 'deleteEntity',
    entity: Entities.deleteEntityResult,
  },
  modifyShadowBzObjectAddPosition: {
    alias: 'modifyShadowBzObjectAddPosition',
    entity: Entities.modifyShadowBzObjectResult,
  },
  modifyShadowBzObjectRemovePosition: {
    alias: 'modifyShadowBzObjectRemovePosition',
    entity: Entities.modifyShadowBzObjectResult,
  },
  modifyShadowBzObjectMovePosition: {
    alias: 'modifyShadowBzObjectMovePosition',
    entity: Entities.modifyShadowBzObjectResult,
  },
  upsertEMail: {
    alias: 'upsertEMail',
    entity: Entities.eMail,
  },
  upsertCallMemoAttachment: {
    alias: 'upsertTelefonatAnhang',
    entity: Entities.callMemoAttachment,
  },
  upsertAppointmentAttachment: {
    alias: 'upsertTerminAnhang',
    entity: Entities.appointmentAttachment,
  },
  upsertNoteAttachment: {
    alias: 'upsertNotizAnhang',
    entity: Entities.noteAttachment,
  },
  upsertTaskAttachment: {
    alias: 'upsertAufgabeAnhang',
    entity: Entities.taskAttachment,
  },
  sendMail: {
    alias: 'sendMail',
    entity: Entities.sendEMailResult,
  },
  upsertEMailTemplate: {
    alias: 'upsertEMailVorlage',
    entity: Entities.eMailTemplate,
  },
  upsertEMailSignature: {
    alias: 'upsertEMailSignatur',
    entity: Entities.eMailSignature,
  },
  modifyShadowBzObjectModifyPosition: {
    alias: 'modifyShadowBzObjectModifyPosition',
    entity: Entities.modifyShadowBzObjectResult,
  },
  upsertTicket: {
    alias: 'upsertTicket',
    entity: Entities.ticket,
  },
  upsertBesuchsbericht: {
    alias: 'upsertBesuchsbericht',
    entity: Entities.visitationReport,
  },
  interpretedTempFileAsIncommingInvoice: {
    alias: 'interpretTempFileAsIncommingInvoice',
    entity: Entities.ocrInvoiceData,
  },
  processWordTemplate: {
    alias: 'processWordTemplate',
    entity: Entities.processWordTemplateResult,
  },
  setUserPresenceTimestamp: {
    alias: 'setUserPresenceTimestamp',
    entity: Entities.setUserPresenceResult,
  },
  modifyAppearance: {
    alias: 'modifyAppearance',
    entity: Entities.appearance,
  },
  modifyUserAppearanceConfiguration: {
    alias: 'modifyUserAppearanceConfiguration',
    entity: Entities.appearance,
  },
  createShadowRe: {
    alias: 'createShadowRe',
    entity: Entities.modifyShadowREResult,
  },
  deleteShadowRe: {
    alias: 'deleteShadowRe',
  },
  modifyShadowREModifyLine: {
    alias: 'modifyShadowREModifyLine',
    entity: Entities.modifyShadowREResult,
  },
  modifyShadowReAddBuchung: {
    alias: 'modifyShadowReAddBuchung',
    entity: Entities.modifyShadowREResult,
  },
  modifyShadowReRemoveBuchung: {
    alias: 'modifyShadowReRemoveBuchung',
    entity: Entities.modifyShadowREResult,
  },
  persistShadowRe: {
    alias: 'persistShadowRe',
    entity: Entities.modifyShadowREResult,
  },
  upsertVacation: {
    alias: 'upsertUrlaub',
    entity: Entities.vacation,
  },
  upsertSickness: {
    alias: 'upsertKrankheit',
    entity: Entities.sickness,
  },
  addComment: {
    alias: 'addComment',
    entity: Entities.commentEvent,
  },
  startJobSaveSendMail: {
    alias: 'startJobSaveSendMail',
    entity: Entities.saveSendMailJob,
  },
  upsertDocumentForPreview: {
    alias: 'upsertDokumentForPreview',
  },
  upsertLetterForPreview: {
    alias: 'upsertBriefForPreview',
  },
  upsertVacationRequest: {
    alias: 'upsertVacationRequest',
  },
  upsertKommtGehtZeitstempel: {
    alias: 'upsertKommtGehtZeitstempel',
    entity: Entities.userPresenceTimeInfoItem,
  },
  upsertUserCollection: {
    alias: 'upsertUserCollection',
    entity: Entities.userCollection,
  },
  upsertEMailTemplateGroup: {
    alias: 'upsertEMailTemplateGroup',
    entity: Entities.eMailTemplateGroup,
  },
  modifyShadowRe: {
    alias: 'modifyShadowRe',
    entity: Entities.modifyShadowREResult,
  },
  upsertTextBuildingBlock: {
    alias: 'upsertTextBuildingBlock',
    entity: Entities.textBuildingBlock,
  },
  upsertTicketFilter: {
    alias: 'upsertTicketFilter',
    entity: Entities.ticketFilter,
  },
  createTempFile: {
    alias: 'createTempFile',
    entity: Entities.tempFile,
  },
  upsertSavedListFilter: {
    alias: 'upsertSavedListFilter',
    entity: Entities.savedListFilter,
  },
  upsertVerkaufschance: {
    alias: 'upsertVerkaufschance',
  },
  assignMail: {
    alias: 'assignMail',
    entity: Entities.mailboxContent,
  },
  ignoreMail: {
    alias: 'ignoreMail',
  },
  setMailState: {
    alias: 'setMailState',
  },
  setProjectAccessRights: {
    alias: 'setProjectAccessRights',
    entity: Entities.projectAccessRights,
  },
  unignoreMail: {
    alias: 'unignoreMail',
  },
  setNotificationRead: {
    alias: 'setNotificationRead',
  },
  updateBenutzer: {
    alias: 'updateBenutzer',
    entity: Entities.user,
  },
  upsertSalesOpportunityRatingStatus: {
    alias: 'upsertSalesOpportunityRatingStatus',
    entity: Entities.salesOpportunityRatingStatus,
  },
  upsertProjectAccessGroup: {
    alias: 'upsertProjectAccessGroup',
    entity: Entities.projectAccessGroup,
  },
  archiveMail: {
    alias: 'archiveMail',
  },
  deleteMail: {
    alias: 'deleteMail',
  },
  convertBzObject: {
    alias: 'convertBzObject',
    entity: Entities.bzObjectConversionResult,
  },
  convertTempFile: {
    alias: 'convertTempFile',
    entity: Entities.convertTempFileResult,
  },
  convertMailToTempfile: {
    alias: 'convertMailToTempfile',
    entity: Entities.tempFile,
  },
  assignMailToObject: {
    alias: 'assignMailToObject',
    entity: Entities.mailboxContent,
  },
  duplicateEntity: {
    alias: 'duplicateEntity',
    entity: Entities.duplicateResponse,
  },
  upsertOutgoingInvoiceLedger: {
    alias: 'upsertOutgoingInvoiceLedger',
    entity: Entities.ra,
  },
  upsertIncomingInvoiceLedger: {
    alias: 'upsertIncomingInvoiceLedger',
    entity: Entities.inboundInvoice,
  },
  upsertLetterTemplate: {
    alias: 'upsertLetterTemplate',
    entity: Entities.wordLetterTemplate,
  },
  upsertSimpleReceipt: {
    alias: 'upsertSimpleReceipt',
  },
  upsertDocumentTemplate: {
    alias: 'upsertDocumentTemplate',
    entity: Entities.wordDocumentTemplate,
  },
  upsertIncomingMailBlockedSender: {
    alias: 'upsertIncomingMailBlockedSender',
    entity: Entities.incomingMailBlockedSender,
  },
  updateUserMfaMode: {
    alias: 'updateUserMfaMode',
    entity: Entities.setMfaModeResponse,
  },
  validateUserMfaMode: {
    alias: 'validateUserMfaMode',
    entity: Entities.validateSetMfaModeResponse,
  },
  editComment: {
    alias: 'editComment',
    entity: Entities.commentEvent,
  },
  upsertProjectDirectoryDefinition: {
    alias: 'upsertProjectDirectoryDefinition',
    entity: Entities.projectDirectoryDefinition,
  },
  __typename: {
    alias: '__typename',
  },
};

export const mutationEntityDefinition: EntitiyDefinition<Mutation> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'Mutation',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
