import { IResponse } from '@work4all/data';

import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { ContactUnionWrapper } from '@work4all/models/lib/Classes/ContactUnionWrapper.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { DataRequest, SortDirection } from '@work4all/models/lib/DataProvider';
import { ContactKind } from '@work4all/models/lib/Enums/ContactKind.enum';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { abbreviate } from '../../contactpicker/ContactRessourcePicker';

export const CONTACT_FIELDS: ContactUnionWrapper<EMode.query> = {
  id: null,
  contactKind: null,
  data: {
    customer: {
      id: null,
      eMail: null,
      name: null,
      website: null,
    },
    supplier: {
      id: null,
      eMail: null,
      name: null,
      website: null,
    },
    user: {
      id: null,
      displayName: null,
      lastName: null,
      firstName: null,
      eMail: null,
      userKind: null,
      loginName: null,
    },
    contact: {
      id: null,
      displayName: null,
      firstName: null,
      name: null,
      eMail: null,
      layedOff: null,
      businessPartnerType: null,
      businessPartnerId: null,
      businessPartner: {
        id: null,
        data: {
          customer: {
            id: null,
            name: null,
            website: null,
          },
          supplier: {
            id: null,
            name: null,
            website: null,
          },
        },
      },
    },
  },
};

export const INTERNAL_CONTACT_TYPES = [
  ContactKind.BENUTZER,
  ContactKind.MITARBEITER,
];
export const EXTERNAL_CONTACT_TYPES = [
  ...INTERNAL_CONTACT_TYPES,
  ContactKind.KUNDE,
  ContactKind.LIEFERANT,
  ContactKind.KUNDENANSPRECHPARTNER,
  ContactKind.LIEFERANTENANSPRECHPARTNER,
];

const prepareRequest = (kind?: ContactKind[]) => {
  return ({ search }: { search: string }): DataRequest => {
    const result: DataRequest = {
      entity: Entities.contactUnionWrapper,
      data: CONTACT_FIELDS,
      completeDataResponse: true,
      vars: {
        searchValue: '%' + search.split(' ').join('%') + '%',
        maxRows: 700,
        contactTypes: kind,
      },
    };

    return result;
  };
};

export const prepareRequestInternal = prepareRequest(INTERNAL_CONTACT_TYPES);
export const prepareRequestAll = prepareRequest(EXTERNAL_CONTACT_TYPES);

export const prepareRequestForPartner = (bp?: Customer | Supplier | null) => {
  return ({ search }) => {
    const result: DataRequest = {
      entity: Entities.contact,
      data: CONTACT_FIELDS.data.contact,
      sort: [{ field: 'displayName', direction: SortDirection.ASCENDING }],
      filter: [
        // { businessPartnerType: { $eq: bp.typ  contact?.businessPartnerType ?? SdObjType[item.contactKind]; } },
        { businessPartnerId: { $eq: bp.id } },
        { layedOff: { $eq: false } },
        {
          displayName: {
            $eq: '%' + search.split(' ').join('%') + '%',
          },
        },
      ],
    };

    return result;
  };
};

export function transformResponse(
  response: IResponse<ContactUnionWrapper>
): IResponse<ContactUnionWrapper> {
  const users = response.data
    .filter((it) => {
      const user = it.data as User;

      return user && !user.hasLeft;
    })
    .sort((a, b) => {
      const uUser = a.data as User;
      const bUser = b.data as User;

      const aValue = getRessourceNameByData(
        uUser as User & Contact & Customer & Supplier,
        ContactKind.UNKNOWN
      );
      const bValue = getRessourceNameByData(
        bUser as User & Contact & Customer & Supplier,
        ContactKind.UNKNOWN
      );
      return aValue.localeCompare(bValue);
    });

  return {
    ...response,
    data: users,
    total: users.length,
  };
}

export const getRessourceNameByData = (
  value: User & Contact & Customer & Supplier,
  contactKind: ContactKind
) => {
  let title = value?.displayName || value?.name || value?.loginName;
  if (
    [
      ContactKind.BENUTZER,
      ContactKind.KUNDENANSPRECHPARTNER,
      ContactKind.LIEFERANTENANSPRECHPARTNER,
      ContactKind.MITARBEITER,
    ].includes(contactKind)
  ) {
    title =
      (value.lastName || value.name || '') + ' ' + (value.firstName || '');
  }
  return title || '';
};

export const getRessourceName = (resource: ContactUnionWrapper) => {
  return getRessourceNameByData(
    resource.data as User & Contact & Customer & Supplier,
    resource.contactKind
  );
};

export const renderChipContent = (resource: ContactUnionWrapper): string => {
  const value = resource.data as User & Contact & Customer & Supplier;

  const title = abbreviate(getRessourceName(resource), 25);
  const subtitle = abbreviate(value?.businessPartner?.data?.name, 20);

  return `${subtitle ? `${subtitle} | ` : ''}${title} `;
};

export type BusinessPartnerDetails = {
  type: SdObjType;
  id: number;
  name: string;
};

export function filterUniqueBusinessPartners(
  businessPartner: Customer | Supplier | null | undefined,
  contacts: ContactUnionWrapper[]
): BusinessPartnerDetails[] {
  function toKey(type: SdObjType, id: number): string {
    return `${type}:${id}`;
  }

  const map = new Map<string, BusinessPartnerDetails>();

  if (businessPartner) {
    const type =
      businessPartner.__typename === 'Kunde'
        ? SdObjType.KUNDE
        : SdObjType.LIEFERANT;
    const { id, name } = businessPartner;

    map.set(toKey(type, id), { type, id, name });
  }

  for (const item of contacts) {
    if (
      item.contactKind === ContactKind.KUNDE ||
      item.contactKind === ContactKind.LIEFERANT ||
      item.contactKind === ContactKind.KUNDENANSPRECHPARTNER ||
      item.contactKind === ContactKind.LIEFERANTENANSPRECHPARTNER
    ) {
      const contact = item.data as Contact;

      const type = contact?.businessPartnerType ?? SdObjType[item.contactKind];
      const id = contact?.businessPartnerId ?? contact?.id;
      const name = contact?.businessPartner?.data?.name ?? contact?.name;

      map.set(toKey(type, id), { type, id, name });
    }
  }

  return [...map.values()];
}
