import { DateTime } from 'luxon';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';

import { throwInDev } from '../../throw-in-dev';

/**
 * Fix the `startDate` and `endDate` properties for all day appointments.
 *
 * Some whole day appointments have incorrectly set start and end date. If
 * `fromAbsolute` and `toAbsolute` are set, we can use these to calculate the
 * correct start and end date.
 *
 * If it's not a whole day appointment, if `fromAbsolute` and `toAbsolute` are
 * not set, or if the dates are already correct, the original appointment is
 * returned.
 *
 * @param appointment The appointment to fix.
 *
 * @returns The fixed appointment.
 */
export function fixAppointmentStartEndDates(
  appointment: Appointment
): Appointment {
  if (appointment.isWholeDay) {
    if (appointment.fromAbsolute && appointment.toAbsolute) {
      const from = DateTime.fromISO(appointment.fromAbsolute);
      const to = DateTime.fromISO(appointment.toAbsolute);

      const startDate = from.startOf('day');
      const endDate = from.equals(to)
        ? to.endOf('day')
        : to.endOf('day').minus({ days: 1 });

      const startDateISO = startDate.toISO();
      const endDateISO = endDate.toISO();

      if (
        startDateISO !== appointment.startDate &&
        endDateISO !== appointment.endDate
      ) {
        return { ...appointment, startDate: startDateISO, endDate: endDateISO };
      }
    } else {
      throwInDev(
        'Unable to fix appointment dates. The "fromAbsolute" and "toAbsolute" properties are missing.'
      );
    }
  }

  // No changes
  return appointment;
}
