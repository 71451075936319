import React from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { LabeledCurrencyInput } from '@work4all/components/lib/input/labeled-currency-input';
import {
  MultiStepControls,
  Step,
} from '@work4all/components/lib/input/multi-step-controls';

import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { PathsOf } from '@work4all/utils/lib/paths-of/paths-of';

import { PartialInvoiceCalculationLogicPickerField } from '../../../../../../../../../../components/entity-picker/PartialInvoiceCalculationPickerField';
import { PaymentKindPickerField } from '../../../../../../../../../../components/entity-picker/PaymentKindPickerField';
import { SupplierPickerField } from '../../../../../../../../../../components/entity-picker/SupplierPickerField';
import { ControllerPlus } from '../../../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import { ControlWrapper } from '../../../../../../../components/control-wrapper';
import { useMaskContext } from '../../../../../../../hooks/mask-context';
import { BusinessPartners } from '../../../../../BusinessPartnerOverlayController';
import { BPMaskFormValue } from '../../../../../types';

export const TermsOfPayment: React.FC = () => {
  const { t } = useTranslation();

  const { register, control, watch } =
    useFormContextPlus<PathsOf<BPMaskFormValue, 1>>();

  const mask = useMaskContext<BusinessPartners>();

  const currentCommisionar = watch('commisionar');

  const data = mask.data;
  return (
    <Collapse defaultOpen={true} title={t('MASK.TERMS_OF_PAYMENT')}>
      <ControlWrapper>
        <ControllerPlus
          name="paymentKind"
          control={control}
          render={({ field, fieldState }) => {
            return (
              <PaymentKindPickerField
                {...field}
                error={fieldState?.error?.message}
              />
            );
          }}
        />
        {mask.entity === Entities.customer && (
          <ControllerPlus
            name="partialInvoiceLogic"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <PartialInvoiceCalculationLogicPickerField
                  {...field}
                  error={fieldState?.error?.message}
                />
              );
            }}
          />
        )}
        <LabeledCurrencyInput
          {...register('paymentDeadline')}
          defaultValue={(data as Customer).paymentDeadline}
          label={t('INPUTS.NET_PAYMENT_DUE_AFTER')}
          unit={t('INPUTS.DAYS')}
        />
        {mask.entity === Entities.supplier && (
          <LabeledCurrencyInput
            {...register('dunningTolerance')}
            defaultValue={data.dunningTolerance}
            label={t('INPUTS.DUNNING_TOLERANCE')}
            unit={t('INPUTS.DAYS')}
          />
        )}
      </ControlWrapper>
      <ControlWrapper>
        <MultiStepControls>
          <Step active={true} index={0}>
            <LabeledCurrencyInput
              {...register('skontoDurationDays')}
              defaultValue={data.skontoDurationDays}
              label={t('INPUTS.DISCOUNT')}
              unit={t('INPUTS.DAYS')}
            />
          </Step>
          <Step active={true} index={1}>
            <LabeledCurrencyInput
              {...register('skonto')}
              defaultValue={data.skonto}
              label={t('INPUTS.DISCOUNT_VALUE')}
              unit="%"
            />
          </Step>
        </MultiStepControls>
        <MultiStepControls>
          <Step active={true} index={0}>
            <LabeledCurrencyInput
              {...register('skonto2DurationDays')}
              defaultValue={data.skonto2DurationDays}
              label={`${t('INPUTS.DISCOUNT')} 2`}
              unit={t('INPUTS.DAYS')}
            />
          </Step>
          <Step active={true} index={1}>
            <LabeledCurrencyInput
              {...register('skonto2')}
              defaultValue={data.skonto2}
              label={t('INPUTS.DISCOUNT_VALUE')}
              unit="%"
            />
          </Step>
        </MultiStepControls>
        <div>
          {mask.entity === Entities.customer && (
            <MultiStepControls>
              <Step active={true} index={0}>
                <ControllerPlus
                  name="commisionar"
                  control={control}
                  render={({ field, fieldState }) => {
                    return (
                      <SupplierPickerField
                        {...field}
                        error={fieldState?.error?.message}
                        label={t('INPUTS.COMISSIONER')}
                      />
                    );
                  }}
                />
              </Step>
              <Step active={!!currentCommisionar} index={1}>
                <LabeledCurrencyInput
                  label={t('INPUTS.COMMISSION')}
                  defaultValue={(data as Customer).commission}
                  unit="%"
                  {...register('commission', {
                    value: (data as Customer).commission,
                  })}
                />
              </Step>
            </MultiStepControls>
          )}
        </div>
      </ControlWrapper>
    </Collapse>
  );
};
