import React from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  ITourPickerProps,
  TourPicker,
} from '@work4all/components/lib/components/entity-picker/tour-picker/TourPicker';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

interface Props extends Omit<ITourPickerProps<false>, 'multiple'> {
  error?: string;
}

export const TourPickerField = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { t } = useTranslation();
    const { error, ...pickerProps } = props;
    const { value, onChange } = pickerProps;

    return (
      <EntityPickerPopover
        picker={<TourPicker {...pickerProps} multiple={false} />}
      >
        <PickerTargetButton
          ref={ref}
          onClear={() => {
            onChange(null);
          }}
          label={t('COMMON.TOUR')}
          error={error}
          value={value?.name || ''}
        />
      </EntityPickerPopover>
    );
  }
);
