import { Stack } from '@mui/material';
import { t } from 'i18next';
import { ContentColumns } from 'pdfmake/interfaces';

import { LabeledInput } from '@work4all/components/lib/input/labeled-input';

export const DynamicContentCreator = (props: {
  content: ContentColumns;
  onChange: (content: ContentColumns) => void;
}) => {
  const { content, onChange } = props;
  return (
    <Stack gap="1rem">
      <LabeledInput
        multiline
        defaultValue={content.text || ''}
        label={t('INPUTS.TEXT')}
        onChange={(e) =>
          onChange({
            ...content,
            text: e.target.value,
          } as ContentColumns)
        }
      />
    </Stack>
  );
};
