import { useMemo } from 'react';

import { useUser } from '@work4all/data';

import {
  checkUserRight,
  UserRights,
} from '@work4all/models/lib/additionalEnums/UserRights';

interface Props {
  documentUserId: number;
  datevDate: string;
  fileName: string;
}
export const useInboundInvoicePdfEditRights = (props: Props) => {
  const { documentUserId, datevDate, fileName } = props;

  const { benutzerRechte: userRights, benutzerCode: userId } = useUser();

  const inboundInvoiceIsPDF = useMemo(() => {
    const segments = fileName?.split('.');
    return (
      segments && segments[segments.length - 1].toLocaleLowerCase() === 'pdf'
    );
  }, [fileName]);

  const inboundInvoicePdfEditAllowed = useMemo(() => {
    if (!inboundInvoiceIsPDF) {
      return false;
    }
    if (datevDate) {
      return false;
    }
    if (
      checkUserRight(UserRights.RechnungseingangErstellenAendern, userRights)
    ) {
      return true;
    }
    if (checkUserRight(UserRights.FreigabeEingangsrechnungen, userRights)) {
      return true;
    }
    if (
      checkUserRight(
        UserRights.FreigabeEingangsrechnungenNurEigene,
        userRights
      ) &&
      documentUserId === userId
    ) {
      return true;
    }
    return false;
  }, [inboundInvoiceIsPDF, datevDate, documentUserId, userRights, userId]);

  return inboundInvoicePdfEditAllowed;
};
