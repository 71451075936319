import { Typography } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import sanitize from 'sanitize-html';

import { remToPx } from '@work4all/data/lib/hooks/useRemToPx';

import { TextEditor } from '../../../input/format-text/TextEditor';

import { AttachmentRenameContext } from './attachment-rename-context';

export interface EditableAttachmentFileNameProps {
  fileId: string;
}

export function EditableAttachmentNote(props: EditableAttachmentFileNameProps) {
  const { fileId } = props;

  const renameContext = useContext(AttachmentRenameContext);

  const { t } = useTranslation();

  const disabled = renameContext.noteDisabled || fileId == null;

  const note = renameContext.getNote(fileId);

  if (disabled) {
    return (
      <Typography variant="body1" noWrap textAlign="center">
        <div
          dangerouslySetInnerHTML={{ __html: sanitize(note) }}
          style={{
            wordBreak: 'break-all',
            whiteSpace: 'normal',
            padding: '1rem',
            maxHeight: '10rem',
            overflowY: 'auto',
            textAlign: 'left',
            display: 'inline-block',
          }}
        ></div>
      </Typography>
    );
  }

  return (
    <TextEditor
      value={note}
      onChange={(value) => {
        renameContext.setNote(fileId, value, true);
      }}
      hideToolbar
      config={{
        heightMin: remToPx(2),
        heightMax: remToPx(10),
        height: remToPx(2),
        placeholderText: t('COMMON.NOTE_OPTIONAL'),
      }}
    />
  );
}
