export enum ModuleAccessRightType {
  WIDGET = 'WIDGET',
  FEATURE_PROJECT_TIME_TRACKING = 'FEATURE_PROJECT_TIME_TRACKING',
  FEATURE_WORK_TIME_TRACKING = 'FEATURE_WORK_TIME_TRACKING',
  FEATURE_VACATIONS = 'FEATURE_VACATIONS',
  FEATURE_FILE_TAB_CUSTOMER = 'FEATURE_FILE_TAB_CUSTOMER',
  FEATURE_FILE_TAB_SUPPLIER = 'FEATURE_FILE_TAB_SUPPLIER',
  FEATURE_FILE_TAB_PROJECT = 'FEATURE_FILE_TAB_PROJECT',
  FEATURE_FILE_TAB_CONTROL_CENTER = 'FEATURE_FILE_TAB_CONTROL_CENTER',
  FEATURE_TICKETS = 'FEATURE_TICKETS',
  FEATURE_ACCESS_PRODUCTION_CONTRACT = 'FEATURE_ACCESS_PRODUCTION_CONTRACT',
  FEATURE_WORK_4_ALL_WEB = 'FEATURE_WORK_4_ALL_WEB',
  FEATURE_WORK_4_ALL_WEB_FULL = 'FEATURE_WORK_4_ALL_WEB_FULL',
  FEATURE_CRM_LIGHT = 'FEATURE_CRM_LIGHT',
  FEATURE_ERP_LIGHT = 'FEATURE_ERP_LIGHT',
  FEATURE_PROJECT_LIGHT = 'FEATURE_PROJECT_LIGHT',
  FEATURE_WORK_TIME_TRACKING_LIGHT = 'FEATURE_WORK_TIME_TRACKING_LIGHT',
  FEATURE_PROJECT_TIME_TRACKING_LIGHT = 'FEATURE_PROJECT_TIME_TRACKING_LIGHT',
  FEATURE_RECEIPTS_TRAVEL_LIGHT = 'FEATURE_RECEIPTS_TRAVEL_LIGHT',
  FEATURE_TICKETS_LIGHT = 'FEATURE_TICKETS_LIGHT',
  FEATURE_MAINTENANCE_CONTRACT_MODULE = 'FEATURE_MAINTENANCE_CONTRACT_MODULE',
  FEATURE_ORDER_MANAGEMENT_MODULE = 'FEATURE_ORDER_MANAGEMENT_MODULE',
  FEATURE_RECEIPTS_TRAVEL = 'FEATURE_RECEIPTS_TRAVEL',
  FEATURE_STATISTICS = 'FEATURE_STATISTICS',
  FEATURE_INCOMING_INVOICE_LEDGER = 'FEATURE_INCOMING_INVOICE_LEDGER',
  FEATURE_OUTGOING_INVOICE_LEDGER = 'FEATURE_OUTGOING_INVOICE_LEDGER',
  FEATURE_CALENDAR = 'FEATURE_CALENDAR',
  FEATURE_BIRTHDAYS = 'FEATURE_BIRTHDAYS',
  FEATURE_ITEM_LIST = 'FEATURE_ITEM_LIST',
  FEATURE_WORK_TIME_TRACKING_TIME_CARD = 'FEATURE_WORK_TIME_TRACKING_TIME_CARD',
  FEATURE_HOME_SCREEN = 'FEATURE_HOME_SCREEN',
  FEATURE_WORK_4_ALL_PREVIEW_FEATURES = 'FEATURE_WORK_4_ALL_PREVIEW_FEATURES',
}
