import styles from './EntityPickerTab.module.scss';

import { Badge, Button } from '@mui/material';
import clsx from 'clsx';
import React, { useContext } from 'react';

import { EntityPickerTabBarContext } from './entity-picker-tab-bar-context';

export type IEntityPickerTabProps<T extends string | number> = {
  value: T;
  children: React.ReactNode;
  disabled?: boolean;
  errors?: number;
};

export function EntityPickerTab<T extends string | number>(
  props: IEntityPickerTabProps<T>
) {
  const { value, children, disabled, errors } = props;

  const context = useContext<EntityPickerTabBarContext>(
    EntityPickerTabBarContext
  );

  if (!context) {
    throw new Error(
      '<EntityPickerTab> must be used inside <EntityPickerTabBar>'
    );
  }

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (value !== context.value) {
      context.onChange(value);
    }
  };

  return (
    <Badge
      color="error"
      badgeContent={errors}
      classes={{
        badge: styles.badge,
      }}
    >
      <Button
        variant="text"
        className={clsx(styles['root'], {
          [styles['active']]: value === context.value,
          [styles.error]: errors > 0,
        })}
        onClick={handleClick}
        disabled={disabled}
      >
        {children}
      </Button>
    </Badge>
  );
}
