import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';

export const useTotalPrice = () => {
  const { watch } = useFormContext();
  const total = watch('value');

  const [totalPrice, setTotalPrice] = useState<number>(0);

  useEffect(() => {
    if (totalPrice !== total && !Number.isNaN(Number(total))) {
      setTotalPrice(total);
    }
  }, [total, totalPrice]);
  return useMemo(() => {
    return { totalPrice, setTotalPrice };
  }, [totalPrice, setTotalPrice]);
};
