import React from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import { PickerTargetButton } from '@work4all/components/lib/input/picker-target-button';

import {
  MailboxEncryptionPicker,
  MailboxEncryptionPickerProps,
} from './MailboxEncryptionPicker';

interface Props extends MailboxEncryptionPickerProps {
  error?: string;
  label?: string;
  required?: boolean;
}

export const MailboxEncryptionPickerField = React.forwardRef<
  HTMLDivElement,
  Props
>(function MailboxEncryptionPickerField(props, ref) {
  const { error, label, required, ...pickerProps } = props;
  const { value } = pickerProps;

  const { t } = useTranslation();

  const displayValue: string = value
    ? t(`LINK_MAILBOX.MAILBOX_ENCRYPTION.${value}`)
    : '';

  return (
    <EntityPickerPopover picker={<MailboxEncryptionPicker {...pickerProps} />}>
      <PickerTargetButton
        ref={ref}
        label={label || t('LINK_MAILBOX.INPUTS.ENCRYPTION')}
        error={error}
        required={required}
        value={displayValue}
      />
    </EntityPickerPopover>
  );
});
