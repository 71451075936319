import styles from './PDFTextmarks.module.scss';

import { Close, NorthWest } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import clsx from 'clsx';
import type { FC, ReactNode } from 'react';
import { useDrag } from 'react-dnd';

import { DndTypes } from '@work4all/utils/lib/variables';

export interface BoxProps {
  id: string;
  left: number;
  top: number;
  hideSourceOnDrag?: boolean;
  children?: ReactNode;
  onDelete?: () => void;
  disabled?: boolean;
}

export const DragBox: FC<BoxProps> = ({
  id,
  left,
  top,
  hideSourceOnDrag = true,
  children,
  onDelete,
  disabled,
}) => {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: DndTypes.BOX,
      item: { id, left, top },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [id, left, top]
  );

  if (isDragging && hideSourceOnDrag) {
    return <div ref={drag} />;
  }
  return (
    <div
      className={clsx('box', styles.dragBox)}
      ref={disabled ? undefined : drag}
      style={{ left, top }}
      data-testid="box"
    >
      <NorthWest sx={{ fontSize: '0.75rem' }} />
      <div className={styles.textContainer}>
        {children}
        {!disabled && (
          <IconButton
            onClick={onDelete}
            size="small"
            className={styles.deleteButton}
          >
            <Close />
          </IconButton>
        )}
      </div>
    </div>
  );
};
