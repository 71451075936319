export enum GeneralLedgerAccountType {
  UNKNOWN = 'UNKNOWN',
  INVESTMENT_AND_CAPITAL_ACCOUNTS = 'INVESTMENT_AND_CAPITAL_ACCOUNTS',
  FINANCIAL_AND_PERSONAL_ACCOUNTS = 'FINANCIAL_AND_PERSONAL_ACCOUNTS',
  ACCRUAL_ACCOUNTS = 'ACCRUAL_ACCOUNTS',
  OPERATING_EXPENSES = 'OPERATING_EXPENSES',
  STOCKS_OF_PRODUCTS = 'STOCKS_OF_PRODUCTS',
  REVENUE_ACCOUNTS = 'REVENUE_ACCOUNTS',
  LECTURE_ACCOUNTS_STATISTICAL_ACCOUNTS = 'LECTURE_ACCOUNTS_STATISTICAL_ACCOUNTS',
  CASH_DISCOUNT_INCOMING_ACCOUNT = 'CASH_DISCOUNT_INCOMING_ACCOUNT',
  CASH_DISCOUNT_OUTGOING_ACCOUNT = 'CASH_DISCOUNT_OUTGOING_ACCOUNT',
  GENERAL_LEDGER_ACCOUNT = 'GENERAL_LEDGER_ACCOUNT',
}
