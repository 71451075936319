import styles from './EventLayout.module.scss';

import Typography from '@mui/material/Typography';
import { DateTime } from 'luxon';

import { Event } from '@work4all/models/lib/Classes/Event.entity';

import { UserIcon } from '../../../../../user-icon/UserIcon';
import { HookedUserIcon } from '../../../../../user-icon/useUserIconRegister';
import { Option } from '../types';

import { OptionsPopoverButton } from './options-popover/OptionsPopoverButton';

export interface EventLayoutProps {
  event: Event;
  children?: React.ReactNode;
  options?: Option[];
}

export function EventLayout(props: EventLayoutProps) {
  const { event, children, options } = props;
  const dateTime = DateTime.fromISO(event?.creationDate).toJSDate();
  const formatter = new Intl.DateTimeFormat('de', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  });
  const dateString = event?.creationDate ? formatter.format(dateTime) : '';

  const userId = event.user?.id;

  return (
    <div className={styles.root}>
      {/* Sometimes the user object is missing from event. */}
      {event.user ? (
        <HookedUserIcon userId={userId} size="l" />
      ) : (
        <UserIcon size="l" shortName="?" bgColor="black" fontColor="white" />
      )}

      <div className={styles.content}>
        <div className={styles.header}>
          <Typography
            variant="body2"
            color="text.primary"
            sx={{ fontWeight: 700 }}
          >
            {event.user ? event.user.displayName : 'Unknown'}
          </Typography>

          <Typography variant="body2" color="text.tertiary">
            {dateString}
          </Typography>
        </div>

        {children}
      </div>

      <OptionsPopoverButton className={styles.options} options={options} />
    </div>
  );
}
