import styles from '../../EditTable.module.scss';

import clsx from 'clsx';
import React from 'react';
import { TableInstance } from 'react-table';
import { VariableSizeList } from 'react-window';

import {
  EditModeCell,
  EditModeConfig,
  EditModeTableInstance,
} from '@work4all/components/lib/dataDisplay/basic-table/plugins/useEditMode';

import { UseSettingReturn } from '@work4all/data/lib/settings';

import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

import { DeepRequired } from '@work4all/utils/lib/deep-required/DeepRequired';

import { TableCell } from '../../components';
import { genericMemo } from '../../generic-memo';
import { useEditRowSeleciton } from '../../hooks/use-edit-row-selection';
import { InfiniteRow, useInifiteList } from '../../InfiniteList';
import {
  CellDefinition,
  EditTableEntry,
  EditTableProps,
  NO_BORDER_KIND,
} from '../../types';

interface TableCellContainerProps<T extends EditTableEntry>
  extends Pick<
    EditTableProps<T>,
    | 'cellDefinition'
    | 'showBorders'
    | 'disabled'
    | 'loadingRows'
    | 'noIdEditableCells'
  > {
  row: InfiniteRow<T>;
  tablesLayoutBorders: UseSettingReturn<
    DeepRequired<{
      vertical?: boolean;
      horizontal?: boolean;
    }>
  >;
  rowInEditModeValues: React.MutableRefObject<T>;
  editableCols: string[];
  setEditModeConfig: (config: EditModeConfig) => void;
  onRowEdit: () => void;
  skeletonFirstRowAffectedIndex: number;
  index: number;
  tableInstance: TableInstance<T> & EditModeTableInstance;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  listRef: React.MutableRefObject<VariableSizeList<any>>;
  // TODO: move on higher level of row calculates
  isRowVisible: (index: number) => boolean;
  onRowOut?: (row: T) => void;
  singleLine: boolean;
}

function TableCellContainerInternal<T extends EditTableEntry>(
  props: TableCellContainerProps<T>
) {
  const {
    row,
    cellDefinition,
    showBorders,
    editableCols,
    tablesLayoutBorders,
    rowInEditModeValues,
    disabled,
    setEditModeConfig,
    onRowEdit,
    loadingRows,
    skeletonFirstRowAffectedIndex,
    noIdEditableCells,
    index,
    tableInstance,
    isRowVisible,
    onRowOut,
    singleLine,
  } = props;

  const { onSelectionChange, selectionRangeRef } = useEditRowSeleciton();
  const selectionRange = selectionRangeRef.current;
  const { getMaxSize } = useInifiteList();

  return (
    <React.Fragment>
      {row.cells.map((cell) => {
        const definition: CellDefinition<T> = cellDefinition[
          cell.column.id as string
        ] ?? { type: 'number' };

        const render = definition.render;
        const shouldRender = definition.shouldRender;
        const cellClassStyle = definition.style;
        const baseStyle = {
          display: 'flex',
          alignItems: 'flex-start',
        };
        const { style, ...otherCellProps } = cell.getCellProps({
          style:
            tablesLayoutBorders.value.vertical && showBorders
              ? {
                  borderLeft: NO_BORDER_KIND.includes(row.original.positionKind)
                    ? undefined
                    : '1px solid var(--ui04)',
                  ...baseStyle,
                }
              : baseStyle,
        });

        const isEditMode = (cell as unknown as EditModeCell).isEditMode;

        const cellType = isEditMode ? 'EditableCell' : 'Cell';

        const isEditableCell = editableCols.includes(cell.column.id);
        const onClick = editableCols.includes(cell.column.id)
          ? (_e: React.MouseEvent<HTMLDivElement>) => {
              const nestedInput = _e.currentTarget.querySelector(
                'textarea, input'
              ) as HTMLTextAreaElement | HTMLInputElement;
              if (nestedInput)
                onSelectionChange(
                  nestedInput.selectionStart,
                  nestedInput.selectionEnd,
                  cell.row.id,
                  cell.column.id
                );
              if (disabled) {
                return;
              }

              if (cell.row.original.id === rowInEditModeValues.current?.id)
                return;

              if (rowInEditModeValues.current) onRowEdit();
              rowInEditModeValues.current = {
                id: row.original.id,
              } as T;

              setEditModeConfig({
                row: cell.row.id,
                columns: editableCols,
                autoFocus: cell.column.id,
              });
            }
          : undefined;
        const stopEdit = () => {
          onRowEdit();
          rowInEditModeValues.current = null;

          onSelectionChange();
          return setEditModeConfig({
            row: cell.row.id,
            columns: [],
            autoFocus: cell.column.id,
          });
        };

        const showSkeleton =
          (loadingRows.includes(row.original.id) ||
            (loadingRows.length &&
              skeletonFirstRowAffectedIndex < index &&
              [
                ErpPositionsKind.ZWISCHENSUMME,
                ErpPositionsKind.TITELSUMME,
              ].includes(row.original.positionKind))) &&
          definition.skeleton;

        const isValueVisble = Boolean(
          row.original.id ||
            noIdEditableCells?.includes(cell.column.id) ||
            !(row.original.posId && cell.column.id === 'amount')
        );

        const autoFocus = selectionRange
          ? selectionRange.cellId === cell.column.id
          : undefined;

        const renderedCell =
          render && (shouldRender ? shouldRender?.(row.original) : true)
            ? render(cell, { isEditMode })
            : cell.render(cellType, {
                onEdit: onClick,
                ...definition,
                textarea: definition.type === 'text',
                disabled: !isValueVisble,
                isValueVisble: isValueVisble,
                onSubmit: stopEdit,
                onExit: stopEdit,
                onSelectionChange: (start: number, end: number) =>
                  onSelectionChange(start, end, cell.row.id, cell.column.id),
                selectinRange: autoFocus ? selectionRange : undefined,
                autoFocus,
                onNext: (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
                  const sortedCols =
                    tableInstance.state.columnOrder.length ===
                    tableInstance.visibleColumns.length
                      ? tableInstance.state.columnOrder
                      : tableInstance.visibleColumns.map((x) => x.id);
                  const currentColumns = sortedCols.filter((x) =>
                    editableCols.includes(x as string)
                  );
                  const idx = currentColumns.findIndex(
                    (x) => cell.column.id === x
                  );

                  const next = currentColumns[idx + 1];

                  if (!next) {
                    // prevent default tab to next column
                    event.preventDefault();
                    stopEdit();
                    onRowOut?.(row.original);
                  }
                },
                className: clsx('inside-row', {
                  [styles['right-oriented']]: definition.type === 'number',
                }),
                maxHeight: getMaxSize(index),
                isVisible: isRowVisible(cell.row.index),
              });

        const renderCell = (ref?: (instance: HTMLElement) => void) => (
          <TableCell
            innerRef={ref}
            {...otherCellProps}
            onClick={onClick}
            className={clsx(styles['table-cell'], {
              [styles.skeleton]: showSkeleton,
              [styles.blurValue]: !isValueVisble,
              [styles.parent]: !isEditableCell,
              [styles['edit-mode']]:
                tableInstance.state.editModeConfig?.row === row.id,
              [styles.fitContent]: cell.column.id !== 'longtext',
            })}
            style={{
              ...style,
              ...cellClassStyle,
            }}
            id={cell.column.id}
            key={cell.column.id}
            kind={row.original.positionKind}
            shouldWrap={
              definition.type !== 'number' && definition.type !== 'picker'
            }
            singleLine={singleLine && !isEditMode}
          >
            {renderedCell}
          </TableCell>
        );

        return renderCell();
      })}
    </React.Fragment>
  );
}

export const TableCellContainer = genericMemo(TableCellContainerInternal);
