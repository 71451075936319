import { useMemo } from 'react';

import { GroupedItem } from '@work4all/components/lib/dataDisplay/basic-table/hooks/query-table-data/types';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';

import { fixAppointmentStartEndDates } from '@work4all/utils';

import { isAppointment } from '../utils/isAppointment';

export function useFixedAppointmentsData(
  data: (Appointment | GroupedItem)[]
): (Appointment | GroupedItem)[] {
  const fixed = useMemo(() => {
    if (!data) return data;

    function fixRowRecursively(
      row: Appointment | GroupedItem
    ): Appointment | GroupedItem {
      if (isAppointment(row)) return fixAppointmentStartEndDates(row);

      if (row == null || row.skeleton) return row;

      if (row.isGrouped) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return { ...row, subRows: row.subRows.map(fixRowRecursively) as any };
      }
    }

    return data.map(fixRowRecursively);
  }, [data]);

  return fixed;
}
