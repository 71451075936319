import { useMemo } from 'react';

import { translateField } from '@work4all/components/lib/dataDisplay/basic-table/hooks/query-table-data/hooks/translate-utils';

import { ITempFileManagerContext } from '@work4all/data/lib/hooks/data-provider/useTempFileManager';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

const useAttachementsRelation = <T = unknown>(
  tempFileManager: ITempFileManagerContext,
  modifyAttachmentEntityType: Entities,
  idField: string
) => {
  return useMemo(() => {
    const hasAttachmentToModify = tempFileManager.filesToUpdate.length > 0;
    const hasAttachmentToAdd = tempFileManager.temporaryFileUploads.length > 0;
    const hasAttachmentToRemove = tempFileManager.fileIdsToDelete.length > 0;
    const attachements = {
      modify: hasAttachmentToModify
        ? tempFileManager.filesToUpdate.map((x) => ({
            [translateField(idField, modifyAttachmentEntityType)]:
              x.id as number,
            name: x.fileName,
          }))
        : undefined,
      add: hasAttachmentToAdd
        ? tempFileManager.temporaryFileUploads.map((x) => ({
            tempFileId: x.id as string,
            name: x.fileName,
          }))
        : undefined,
      remove: hasAttachmentToRemove
        ? (tempFileManager.fileIdsToDelete as number[])
        : undefined,
    } as T;

    return hasAttachmentToModify || hasAttachmentToAdd || hasAttachmentToRemove
      ? { attachements, isDirty: true }
      : undefined;
  }, [
    idField,
    modifyAttachmentEntityType,
    tempFileManager.fileIdsToDelete,
    tempFileManager.filesToUpdate,
    tempFileManager.temporaryFileUploads,
  ]);
};

export default useAttachementsRelation;
