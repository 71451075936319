import { useCallback, useMemo, useState } from 'react';

import { AttachmentRenameContextValue } from './attachment-rename-context';

export function useAttachmentRenameContextState({
  nameDisabled,
  noteDisabled,
}: {
  nameDisabled: boolean;
  noteDisabled: boolean;
}): AttachmentRenameContextValue {
  const [names, setNames] = useState<{ [key: string]: string }>({});
  const [notes, setNotes] = useState<{ [key: string]: string }>({});
  const [isDirty, setIsDirty] = useState(false);

  const getName = useCallback(
    (id: string | number) => {
      return names[id] ?? null;
    },
    [names]
  );

  const setName = useCallback(
    (id: string | number, name: string, shouldDirty?: boolean) => {
      const newName = name.replace(/([/\\])/g, '');
      if (shouldDirty && names[id] !== newName) {
        setIsDirty(true);
      }
      setNames((prevNames) => ({
        ...prevNames,
        [id]: newName,
      }));
    },
    [names]
  );

  const getNote = useCallback(
    (id: string | number) => {
      return notes[id] ?? null;
    },
    [notes]
  );

  const setNote = useCallback(
    (id: string | number, note: string, shouldDirty?: boolean) => {
      if (shouldDirty) {
        setIsDirty(true);
      }
      setNotes((prevNotes) => ({ ...prevNotes, [id]: note }));
    },
    []
  );

  const state = useMemo<AttachmentRenameContextValue>(
    () => ({
      nameDisabled,
      noteDisabled,
      getName,
      setName,
      getNote,
      setNote,
      isDirty,
    }),
    [nameDisabled, noteDisabled, getName, getNote, setName, setNote, isDirty]
  );

  return state;
}
