import React from 'react';
import { TableInstance } from 'react-table';

import { nameof } from '@work4all/data/lib/helper/nameof';

import { VisitationReport } from '@work4all/models/lib/Classes/VisitationReport.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';

import { OnOpenMask } from '../../containers/mask-overlays/mask-overlay';

import { EntityTable } from './entity-table/EntityTable';
import { useEntityDataTable } from './entity-table/use-entity-data-table';
import schema from './schemata/visitationReport-table-schema.json';

interface Props {
  prefilter?: unknown[];
  onOpenMask: OnOpenMask;
}

const defaultSort = [
  {
    field: nameof<VisitationReport>('startTime'),
    direction: SortDirection.DESCENDING,
  },
];

export const VisitationReportsTable = React.forwardRef<TableInstance, Props>(
  function VisitationReportsTable(_props, ref) {
    const dataTable = useEntityDataTable<VisitationReport, never>({
      schema: schema as never,
      defaultSort,
    });

    return <EntityTable ref={ref} {...dataTable} />;
  }
);
