import styles from './Header.module.scss';

import clsx from 'clsx';
import React from 'react';

import { useCustomBackgroundsContext } from '@work4all/data';

import { DesktopNavigation } from '../../navigation/desktop-navigation/DesktopNavigation';
import { FixedBackground } from '../FixedBackground';

import { Logo } from './components/logo/Logo';

export const Header: React.FC<{
  children?: React.ReactNode;
  favoriteLinks: React.ReactNode;
}> = (props) => {
  const { activeBackground } = useCustomBackgroundsContext();

  return (
    <div className={clsx(styles['header'], 'mui-fixed')}>
      <FixedBackground src={activeBackground?.url} blend="auto" />
      <div className={styles['header__left']}>
        <Logo />
        <DesktopNavigation />
        {props.favoriteLinks}
      </div>
      <div className={styles['header__right']}>
        {/*todo this needs to be refactored, this is way to deep in the component lib*/}
        {/* {pathname !== '/' && pathname !== '/more' && <HeaderSearchBar />} */}
        {props.children}
      </div>
    </div>
  );
};
