import styles from './CompanyLogoFileController.module.scss';

import { UploadFile } from '@mui/icons-material';
import { LinearProgress } from '@mui/material';
import { ChangeEventHandler, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { BaseActionButton } from '@work4all/components/lib/input/base-action-button/BaseActionButton';

import { SimpleReportContext } from '../../SimpleReportContext';

export const CompanyLogoFileController = () => {
  const { uploadCompanyPictureSimpleModeFile, logoURL, uploadingLogo } =
    useContext(SimpleReportContext);

  const handleFileChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    async (e) => {
      await uploadCompanyPictureSimpleModeFile(e.target.files[0]);
    },
    [uploadCompanyPictureSimpleModeFile]
  );

  const { t } = useTranslation();

  return (
    <div className={styles.wrap}>
      {uploadingLogo && (
        <LinearProgress
          style={{
            width: '100%',
            position: 'absolute',
          }}
        />
      )}
      {logoURL ? (
        <img src={logoURL} className={styles.img} alt="Company Logo" />
      ) : (
        <BaseActionButton icon={<UploadFile />}>
          {t('SIMPLE_REPORT.UPLOAD_COMPANY_LOGO')}
        </BaseActionButton>
      )}
      <input
        type="file"
        name="logo"
        multiple={false}
        onChange={handleFileChange}
        className={styles.input}
      />
    </div>
  );
};
