import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
  EventType,
  sendAmplitudeData,
} from '@work4all/components/lib/utils/amplitude/amplitude';

import { useUser } from '@work4all/data';

import { NavigationOverlayHeader } from '../../../../../../components/navigation-overlay-header/NavigationOverlayHeader';
import { NoAdminRightsBanderole } from '../../../../components/banderoles/NoAdminRightsBanderole';
import {
  MaskTab,
  MaskTabContext,
  MaskTabPanel,
  MaskTabs,
} from '../../../../mask-tabs';
import { MaskContent } from '../../../components/MaskContent/MaskContent';

import {
  LayoutSection,
  LayoutSectionProps,
} from './components/layout-section/LayoutSection';

type TableSettingsProps = {
  amplitudeEntryPoint: string;
} & LayoutSectionProps;

export function TableSettings(props: TableSettingsProps) {
  const { amplitudeEntryPoint, ...rest } = props;

  const { t } = useTranslation();

  useEffect(() => {
    sendAmplitudeData(EventType.OpenTableSettings, {
      entryPoint: amplitudeEntryPoint,
    });
  }, [amplitudeEntryPoint]);

  return (
    <MaskTabContext defaultValue="personal">
      <NavigationOverlayHeader
        title={t('SETTINGS.SETTINGS')}
        subTitle={t('SETTINGS.TABLES')}
        breadcrumbsChildren={<div></div>}
        tabs={<SettingsTabs />}
      />
      <Content {...rest} />
    </MaskTabContext>
  );
}

const SettingsTabs = memo(function Tabs() {
  const { t } = useTranslation();

  return (
    <MaskTabs>
      <MaskTab value="personal" label={t('COMMON.PERSONAL')}></MaskTab>
      <MaskTab value="general" label={t('COMMON.GENERAL')} disabled={true} />
    </MaskTabs>
  );
});

const Content = memo(function TabPanels(props: LayoutSectionProps) {
  const user = useUser();
  return (
    <MaskContent>
      <MaskTabPanel value="personal">
        {!user.isMaster && <NoAdminRightsBanderole />}
        <LayoutSection {...props} />
      </MaskTabPanel>
      <MaskTabPanel value="general" />
    </MaskContent>
  );
});
