import { useMemo } from 'react';

import {
  BirthdaysData,
  UseBirthdaysFormatterData,
  UseBirthdaysFormatterReturn,
} from '../types';
import { birthdayFormatter } from '../utils';

export const useBirthdaysFormatter = (
  birthdays: UseBirthdaysFormatterData
): UseBirthdaysFormatterReturn => {
  return useMemo(() => {
    const result: UseBirthdaysFormatterReturn = {
      data: {},
      total: birthdays.length ?? 0,
      todayTotal: 0,
    };

    if (result.total !== 0) {
      birthdays.forEach((birthday) => {
        const { type, date, data, isToday } = birthdayFormatter(birthday);

        result.data[date] ??= [];
        result.data[date].push({ type, data });

        if (isToday) {
          result.todayTotal += 1;
        }
      });

      result.data = sortBirthdays(result.data);
    }

    return result;
  }, [birthdays]);
};

const sortBirthdays = (birthdays: BirthdaysData): BirthdaysData => {
  return Object.keys(birthdays)
    .sort()
    .reduce((data, date) => {
      data[date] = birthdays[date];
      return data;
    }, {});
};
