import { ReactElement } from 'react';

import { useInaccessibleFields } from '@work4all/data';

import { usePreviewInputsContext } from '../hooks';
import { EntityPreviewPickerInputProps } from '../types';

interface Render<TValue> {
  (props: EntityPreviewPickerInputProps<TValue>): ReactElement;
}

export const withExtras = <TValue,>(render: Render<TValue>) => {
  const Extras = (props: EntityPreviewPickerInputProps<TValue>) => {
    const { entity } = usePreviewInputsContext();
    const { isInaccessible } = useInaccessibleFields();

    return isInaccessible(entity, props.accessor) ? null : render(props);
  };

  return Extras;
};
