import styles from './CardHeader.module.scss';

import AddIcon from '@mui/icons-material/Add';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Theme, useMediaQuery } from '@mui/material';
import { CardHeader as MUICardHeader, IconButton } from '@mui/material';
import { Tooltip } from '@mui/material';
import clsx from 'clsx';
import { MouseEventHandler } from 'react';

import { Link } from '@work4all/data';

import { withStopPropogation } from '@work4all/utils/lib/withStopPropogation';

import { Heading4 } from '../../../../typography/heading4/Heading4';
import { DisabledParams } from '../../../actions-bar/ToolbarTypes';
import { CountIndicator } from '../../../count-indicator/CountIndicator';

interface ICardHeaderProps<T> {
  title: string;
  collapsed: boolean;
  onCollapsed?: MouseEventHandler;
  collapsible: boolean;
  className?: string;
  totalCount?: number;
  listHref: string;
  newHref: string;
  totalRows: number;
  moreHref: string;
  disabledAdd?: DisabledParams;

  additionalActions?: React.ReactNode;

  getItemHref: (item: T) => string;
}

export function CardHeader<T>(props: ICardHeaderProps<T>) {
  const isViewportDesktop = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.up('md')
  );

  return (
    <MUICardHeader
      className={clsx(styles.header, props.className)}
      classes={{
        action: styles.action,
      }}
      title={
        <div className={styles.titleContainer}>
          <Heading4 className={clsx(styles.titleAlign)}>
            <Link
              to={props.listHref}
              className={isViewportDesktop ? styles.title : styles.titleMd}
            >
              {props.title}
            </Link>
            <div className={clsx('drag-handle', styles.dragHandle)}></div>
          </Heading4>

          {((props.collapsed && props.totalCount > 0) ||
            props.totalCount > 4 ||
            (!isViewportDesktop && props.totalRows > 0)) && (
            <CountIndicator variant="secondary" value={props.totalRows} />
          )}
        </div>
      }
      action={
        <div className={styles.actionsWrapper} onClick={withStopPropogation()}>
          {isViewportDesktop &&
            (!props.disabledAdd?.disabled || props.disabledAdd?.visible) && (
              <Tooltip title={props.disabledAdd?.disableReason}>
                <div>
                  <IconButton
                    component={Link}
                    to={props.newHref}
                    size="medium"
                    disabled={props.disabledAdd?.disabled}
                  >
                    <AddIcon
                      className={
                        props.disabledAdd?.disabled
                          ? styles.iconDisabled
                          : styles.iconEnabled
                      }
                    />
                  </IconButton>
                </div>
              </Tooltip>
            )}

          {props.additionalActions ? props.additionalActions : null}

          {props.collapsible && (
            <IconButton onClick={props.onCollapsed} size="medium">
              {props.collapsed ? (
                <ExpandMoreIcon className={styles.iconEnabled} />
              ) : (
                <ExpandLessIcon className={styles.iconEnabled} />
              )}
            </IconButton>
          )}
        </div>
      }
    />
  );
}
