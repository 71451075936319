import styles from './StepsWrapper.module.scss';

import clsx from 'clsx';
import React from 'react';

export const StepsWrapper: React.FC<{
  children?: React.ReactNode;
  isColumnDirection?: boolean;
}> = (props) => {
  return (
    <div
      className={clsx(
        { [styles.columnDirection]: props.isColumnDirection },
        styles.wrapper
      )}
    >
      {props.children}
    </div>
  );
};
