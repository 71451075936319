import { Tooltip, Typography } from '@mui/material';
import React from 'react';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import { CostCenterPicker } from '@work4all/components/lib/components/entity-picker/costCenter-Picker/CostCenterPicker';

import { CostCenter } from '@work4all/models/lib/Classes/CostCenter.entity';

import { PickerTargetButton } from './PickerTargetButton';

export function CostCenterCell(props: {
  disabled?: boolean;
  value: CostCenter;
  onChange: (value: CostCenter | null) => void;
  label?: (value: CostCenter | null) => React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  hover?: boolean;
  autoFocus?: boolean;
}) {
  const {
    disabled = false,
    value,
    onChange,
    label,
    style,
    className,
    hover,
    autoFocus,
  } = props;

  const resultingLabel = label ? (
    <div style={{ color: 'var(--text01)' }}>{label(value)}</div>
  ) : value ? (
    <>
      <Typography component="span" variant="body2" color="text.tertiary">
        {value.number}
        {value.number && value.name ? ' | ' : ''}
      </Typography>
      <Typography component="span" variant="body2" color={'var(--text01)'}>
        {value.name}
      </Typography>
    </>
  ) : null;

  return (
    <EntityPickerPopover
      picker={
        <CostCenterPicker value={value} onChange={onChange} multiple={false} />
      }
    >
      <Tooltip title={value?.name ?? ''}>
        <PickerTargetButton
          disabled={disabled}
          hover={hover}
          style={style}
          className={className}
          autoFocus={autoFocus}
        >
          {resultingLabel}
        </PickerTargetButton>
      </Tooltip>
    </EntityPickerPopover>
  );
}
