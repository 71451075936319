import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { BzObjType } from '../Enums/BzObjType.enum';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { ErpPositionCalculationsLogic } from '../Enums/ErpPositionCalculationsLogic.enum';
import { ErpPositionsKind } from '../Enums/ErpPositionsKind.enum';
import { ErpPositionSlMode } from '../Enums/ErpPositionSlMode.enum';
import { ErpPositionStatusKind } from '../Enums/ErpPositionStatusKind.enum';
import { ErpPositionXmlImportKind } from '../Enums/ErpPositionXmlImportKind.enum';

import { Article } from './Article.entity';
import { BzObject } from './BzObject.entity';
import { Calculation } from './Calculation.entity';
import { Contract } from './Contract.entity';
import { CostCenter } from './CostCenter.entity';
import { CustomField } from './CustomField.entity';
import { DeliveryNote } from './DeliveryNote.entity';
import { Demand } from './Demand.entity';
import { InboundDeliveryNote } from './InboundDeliveryNote.entity';
import { Invoice } from './Invoice.entity';
import { LedgerAccount } from './LedgerAccount.entity';
import { Offer } from './Offer.entity';
import { Order } from './Order.entity';
import { Project } from './Project.entity';
import { Supplier } from './Supplier.entity';
import { User } from './User.entity';

export class Position<T extends EMode = EMode.entity> {
  /** Alias: anzahl */
  amount?: number;
  /** Alias: anzahlungsrechnungCode */
  depositInvoiceId?: number;
  /** Alias: artikelCode */
  articleId?: number;
  /** Alias: artikelNummer */
  articleNumber?: string;
  /** Alias: aufmass */
  measurement?: string;
  /** Alias: aufwand */
  expenditure?: number;
  /** Alias: autoEkProzent */
  autoPurchasePricePercent?: number;
  /** Alias: bearbeiterBenutzerCode */
  editorUserId?: number;
  /** Alias: benutzerCode */
  userId?: number;
  /** Alias: bestellmenge */
  orderAmount?: number;
  /** Alias: breite */
  width?: number;
  /** Alias: bzObjMemberCode */
  bzObjMemberId?: number;
  /** Alias: bzObjType */
  bzObjType?: BzObjType;
  /** Alias: code */
  id?: number;
  /** Alias: creationDate */
  creationDate?: string;
  /** Alias: dispositionBegin */
  dispositionStart?: string;
  /** Alias: dispositionEnde */
  dispositionEnd?: string;
  /** Alias: editBenutzerCode */
  editUserId?: number;
  /** Alias: editDate */
  editDate?: string;
  /** Alias: eigeneArtikelnummer */
  ownArticleNumber?: string;
  /** Alias: einheit */
  unit?: string;
  /** Alias: einzelgewicht */
  singleWeight?: number;
  /** Alias: einzelpreis */
  singlePriceNet?: number;
  /** Alias: einzelpreisBrutto */
  singlePriceGross?: number;
  /** Alias: ekPreis */
  purchasePrice?: number;
  /** Alias: ekZuschlag */
  purchasePriceSurcharge?: number;
  /** Alias: faktor */
  factor?: number;
  /** Alias: fertigmeldung */
  isFinished?: boolean;
  /** Alias: fertigmeldungDatum */
  finishedDate?: string;
  /** Alias: fertigmeldungDurchBCode */
  finishedByUserId?: number;
  /** Alias: fertigmeldungNotiz */
  finishedNote?: string;
  /** Alias: formatierungsArt */
  formattingKind?: string;
  /** Alias: frei1 */
  free1?: number;
  /** Alias: frei4 */
  free4?: string;
  /** Alias: geliefert */
  isDelivered?: number;
  /** Alias: gesamtpreis */
  totalPriceNet?: number;
  /** Alias: gesamtpreisBrutto */
  totalPriceGross?: number;
  /** Alias: gewicht */
  weight?: number;
  /** Alias: herstellernummer */
  manufacturerNumber?: string;
  /** Alias: index */
  index?: number;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: internerText */
  internalText?: string;
  /** Alias: kalkulationslogik */
  calculationLogicId?: ErpPositionCalculationsLogic;
  /** Alias: kalkulationslogikFaktor */
  calculationLogicFactor?: number;
  /** Alias: kartonFaktorMultiplikator */
  cartonFactorMultiplicator?: number;
  /** Alias: kostenkonto */
  costAccount?: number;
  /** Alias: kostenstelle2Nummer */
  costCenter2Number?: number;
  /** Alias: kostenstelleNummer */
  costCenter1Number?: number;
  /** Alias: kurztext */
  shortText?: string;
  /** Alias: laenge */
  length?: number;
  /** Alias: lagerMobilFertig */
  lagerMobilFinished?: boolean;
  /** Alias: lagerort */
  storageName?: string;
  /** Alias: lagerortCode */
  storageId?: number;
  /** Alias: langtext */
  longtext?: string;
  /** Alias: lieferantenCode */
  supplierId?: number;
  /** Alias: liefertermin */
  deliveryDate?: string;
  /** Alias: listenpreis */
  listPrice?: number;
  /** Alias: mengeKarton */
  amountCarton?: number;
  /** Alias: mengePaletten */
  amoundPalettes?: number;
  /** Alias: mengeVe */
  amountVe?: number;
  /** Alias: minutenpreis */
  minutePrice?: number;
  /** Alias: mwst */
  vat?: number;
  /** Alias: nummer */
  number?: string;
  /** Alias: objekteParentCode */
  objectParentId?: number;
  /** Alias: originalCode */
  originalId?: number;
  /** Alias: palettenFaktorMultiplikator */
  paletteFactorMultiplicator?: number;
  /** Alias: posCode */
  posId?: number;
  /** Alias: positionsArt */
  positionKind?: ErpPositionsKind;
  /** Alias: produktionschargenCode */
  produktionChargeId?: number;
  /** Alias: projektCode */
  projectId?: number;
  /** Alias: provisionProzent */
  commissionPercent?: number;
  /** Alias: provisionWerte */
  commissionValues?: number;
  /** Alias: provisionaerCode */
  commissionarId?: number;
  /** Alias: rabatt */
  discount?: number;
  /** Alias: rabatt2 */
  discount2?: number;
  /** Alias: rabatt3 */
  discount3?: number;
  /** Alias: rabatt4 */
  discount4?: number;
  /** Alias: reserviert */
  reserved?: number;
  /** Alias: rueckstand */
  backlog?: number;
  /** Alias: sLArtCode */
  partsListKindId?: number;
  /** Alias: sLType */
  partsListType?: ErpPositionSlMode;
  /** Alias: sachkontoNummer */
  ledgerAccountNumber?: number;
  /** Alias: slAnzahl */
  partsListAmount?: number;
  /** Alias: slMode */
  partsListMode?: number;
  /** Alias: stattGPreis */
  insteadOfTotalPrice?: string;
  /** Alias: status */
  status?: ErpPositionStatusKind;
  /** Alias: tatsaechlicherLiefertermin */
  actualDeliveryDate?: string;
  /** Alias: uhrzeitBeginn */
  startTime?: string;
  /** Alias: uhrzeitEnde */
  endTime?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: veFaktorMultiplikator */
  veFactorMultiplicator?: number;
  /** Alias: volumen */
  volume?: number;
  /** Alias: vorgang */
  process?: string;
  /** Alias: xmlImport */
  xmlImport?: ErpPositionXmlImportKind;
  /** Alias: zeiterfassungCode */
  timeTrackingId?: number;
  /** Alias: artikel */
  article?: Article<T>;
  /** Alias: sachkonto */
  ledgerAccount?: LedgerAccount<T>;
  /** Alias: kostenstelle1 */
  costCenter1?: CostCenter<T>;
  /** Alias: kostenstelle2 */
  costCenter2?: CostCenter<T>;
  /** Alias: bzObjekt */
  bzObject?: T extends EMode.query
    ? BzObject<T>
    :
        | Calculation<T>
        | Offer<T>
        | Contract<T>
        | Invoice<T>
        | DeliveryNote<T>
        | Demand<T>
        | Order<T>
        | InboundDeliveryNote<T>;
  /** Alias: buchungsmenge */
  buchungsmenge?: number;
  /** Alias: demandOfTime */
  demandOfTime?: number;
  /** Alias: totalVolume */
  totalVolume?: number;
  /** Alias: supplier */
  supplier?: Supplier<T>;
  /** Alias: responsibleUser */
  responsibleUser?: User<T>;
  /** Alias: project */
  project?: Project<T>;
  /** Alias: customFields */
  customFieldList?: CustomField<T>[];
  /** Alias: altId */
  localId?: string;
  /** Alias: rohertrag */
  rohertrag?: string;
  /** Alias: kostenkontoEntity */
  costAccountEntity?: LedgerAccount<T>;

  __typename?: string;
}

const fields: FieldDefinitions<Position> = {
  amount: {
    alias: 'anzahl',
  },
  depositInvoiceId: {
    alias: 'anzahlungsrechnungCode',
  },
  articleId: {
    alias: 'artikelCode',
  },
  articleNumber: {
    alias: 'artikelNummer',
  },
  measurement: {
    alias: 'aufmass',
  },
  expenditure: {
    alias: 'aufwand',
  },
  autoPurchasePricePercent: {
    alias: 'autoEkProzent',
  },
  editorUserId: {
    alias: 'bearbeiterBenutzerCode',
  },
  userId: {
    alias: 'benutzerCode',
  },
  orderAmount: {
    alias: 'bestellmenge',
  },
  width: {
    alias: 'breite',
  },
  bzObjMemberId: {
    alias: 'bzObjMemberCode',
  },
  bzObjType: {
    alias: 'bzObjType',
  },
  id: {
    alias: 'code',
  },
  creationDate: {
    alias: 'creationDate',
  },
  dispositionStart: {
    alias: 'dispositionBegin',
  },
  dispositionEnd: {
    alias: 'dispositionEnde',
  },
  editUserId: {
    alias: 'editBenutzerCode',
  },
  editDate: {
    alias: 'editDate',
  },
  ownArticleNumber: {
    alias: 'eigeneArtikelnummer',
  },
  unit: {
    alias: 'einheit',
  },
  singleWeight: {
    alias: 'einzelgewicht',
  },
  singlePriceNet: {
    alias: 'einzelpreis',
  },
  singlePriceGross: {
    alias: 'einzelpreisBrutto',
  },
  purchasePrice: {
    alias: 'ekPreis',
  },
  purchasePriceSurcharge: {
    alias: 'ekZuschlag',
  },
  factor: {
    alias: 'faktor',
  },
  isFinished: {
    alias: 'fertigmeldung',
  },
  finishedDate: {
    alias: 'fertigmeldungDatum',
  },
  finishedByUserId: {
    alias: 'fertigmeldungDurchBCode',
  },
  finishedNote: {
    alias: 'fertigmeldungNotiz',
  },
  formattingKind: {
    alias: 'formatierungsArt',
  },
  free1: {
    alias: 'frei1',
  },
  free4: {
    alias: 'frei4',
  },
  isDelivered: {
    alias: 'geliefert',
  },
  totalPriceNet: {
    alias: 'gesamtpreis',
  },
  totalPriceGross: {
    alias: 'gesamtpreisBrutto',
  },
  weight: {
    alias: 'gewicht',
  },
  manufacturerNumber: {
    alias: 'herstellernummer',
  },
  index: {
    alias: 'index',
  },
  insertTime: {
    alias: 'insertTime',
  },
  internalText: {
    alias: 'internerText',
  },
  calculationLogicId: {
    alias: 'kalkulationslogik',
  },
  calculationLogicFactor: {
    alias: 'kalkulationslogikFaktor',
  },
  cartonFactorMultiplicator: {
    alias: 'kartonFaktorMultiplikator',
  },
  costAccount: {
    alias: 'kostenkonto',
  },
  costCenter2Number: {
    alias: 'kostenstelle2Nummer',
  },
  costCenter1Number: {
    alias: 'kostenstelleNummer',
  },
  shortText: {
    alias: 'kurztext',
  },
  length: {
    alias: 'laenge',
  },
  lagerMobilFinished: {
    alias: 'lagerMobilFertig',
  },
  storageName: {
    alias: 'lagerort',
  },
  storageId: {
    alias: 'lagerortCode',
  },
  longtext: {
    alias: 'langtext',
  },
  supplierId: {
    alias: 'lieferantenCode',
  },
  deliveryDate: {
    alias: 'liefertermin',
  },
  listPrice: {
    alias: 'listenpreis',
  },
  amountCarton: {
    alias: 'mengeKarton',
  },
  amoundPalettes: {
    alias: 'mengePaletten',
  },
  amountVe: {
    alias: 'mengeVe',
  },
  minutePrice: {
    alias: 'minutenpreis',
  },
  vat: {
    alias: 'mwst',
  },
  number: {
    alias: 'nummer',
  },
  objectParentId: {
    alias: 'objekteParentCode',
  },
  originalId: {
    alias: 'originalCode',
  },
  paletteFactorMultiplicator: {
    alias: 'palettenFaktorMultiplikator',
  },
  posId: {
    alias: 'posCode',
  },
  positionKind: {
    alias: 'positionsArt',
  },
  produktionChargeId: {
    alias: 'produktionschargenCode',
  },
  projectId: {
    alias: 'projektCode',
  },
  commissionPercent: {
    alias: 'provisionProzent',
  },
  commissionValues: {
    alias: 'provisionWerte',
  },
  commissionarId: {
    alias: 'provisionaerCode',
  },
  discount: {
    alias: 'rabatt',
  },
  discount2: {
    alias: 'rabatt2',
  },
  discount3: {
    alias: 'rabatt3',
  },
  discount4: {
    alias: 'rabatt4',
  },
  reserved: {
    alias: 'reserviert',
  },
  backlog: {
    alias: 'rueckstand',
  },
  partsListKindId: {
    alias: 'sLArtCode',
  },
  partsListType: {
    alias: 'sLType',
  },
  ledgerAccountNumber: {
    alias: 'sachkontoNummer',
  },
  partsListAmount: {
    alias: 'slAnzahl',
  },
  partsListMode: {
    alias: 'slMode',
  },
  insteadOfTotalPrice: {
    alias: 'stattGPreis',
  },
  status: {
    alias: 'status',
  },
  actualDeliveryDate: {
    alias: 'tatsaechlicherLiefertermin',
  },
  startTime: {
    alias: 'uhrzeitBeginn',
  },
  endTime: {
    alias: 'uhrzeitEnde',
  },
  updateTime: {
    alias: 'updateTime',
  },
  veFactorMultiplicator: {
    alias: 'veFaktorMultiplikator',
  },
  volume: {
    alias: 'volumen',
  },
  process: {
    alias: 'vorgang',
  },
  xmlImport: {
    alias: 'xmlImport',
  },
  timeTrackingId: {
    alias: 'zeiterfassungCode',
  },
  article: {
    alias: 'artikel',
    entity: Entities.article,
  },
  ledgerAccount: {
    alias: 'sachkonto',
    entity: Entities.ledgerAccount,
  },
  costCenter1: {
    alias: 'kostenstelle1',
    entity: Entities.costCenter,
  },
  costCenter2: {
    alias: 'kostenstelle2',
    entity: Entities.costCenter,
  },
  bzObject: {
    alias: 'bzObjekt',
    entity: [
      Entities.calculation,
      Entities.offer,
      Entities.contract,
      Entities.invoice,
      Entities.deliveryNote,
      Entities.demand,
      Entities.order,
      Entities.inboundDeliveryNote,
    ],
  },
  buchungsmenge: {
    alias: 'buchungsmenge',
  },
  demandOfTime: {
    alias: 'demandOfTime',
  },
  totalVolume: {
    alias: 'totalVolume',
  },
  supplier: {
    alias: 'supplier',
    entity: Entities.supplier,
  },
  responsibleUser: {
    alias: 'responsibleUser',
    entity: Entities.user,
  },
  project: {
    alias: 'project',
    entity: Entities.project,
  },
  customFieldList: {
    alias: 'customFields',
    entity: Entities.customField,
  },
  localId: {
    alias: 'altId',
  },
  rohertrag: {
    alias: 'rohertrag',
  },
  costAccountEntity: {
    alias: 'kostenkontoEntity',
    entity: Entities.ledgerAccount,
  },
  __typename: {
    alias: '__typename',
  },
};

export const positionEntityDefinition: EntitiyDefinition<Position> = {
  local: {},
  remote: {
    queryName: 'getPositionen',
    fragmentName: 'Position',
    withPaginationWrapper: true,
    params: [
      { name: 'querySize', type: 'Int' },
      { name: 'queryPage', type: 'Int' },
      { name: 'querySortOrder', type: 'SortOrderStyle' },
      { name: 'querySortBy', type: 'String' },
      { name: 'bzObjTypes', type: '[BzObjType]' },
      { name: 'includeKomponenten', type: 'Boolean' },
      { name: 'filter', type: 'String' },
    ],
  },
  fieldDefinitions: fields,
};
