import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputReisekostenabrechnungBeleg<T extends EMode = EMode.entity> {
  /** Alias: abrechnungBZObjMemberCode */
  abrechnungBZObjMemberCode?: number;
  /** Alias: abrechnungKundeErfolgt */
  abrechnungKundeErfolgt?: number;
  /** Alias: abrechnungKundeGeplant */
  abrechnungKundeGeplant?: number;
  /** Alias: anzahl */
  anzahl?: any;
  /** Alias: ausgabe */
  ausgabe?: any;
  /** Alias: belegart */
  belegart?: number;
  /** Alias: belegartFahrtkosten */
  belegartFahrtkosten?: number;
  /** Alias: belegnummer */
  belegnummer?: number;
  /** Alias: benutzercode */
  benutzercode?: number;
  /** Alias: betragBrutto1 */
  betragBrutto1?: any;
  /** Alias: betragBrutto2 */
  betragBrutto2?: any;
  /** Alias: bezeichnung */
  bezeichnung?: any;
  /** Alias: code */
  code?: number;
  /** Alias: datum */
  datum?: any;
  /** Alias: einnahme */
  einnahme?: any;
  /** Alias: enumBelegart */
  enumBelegart?: any;
  /** Alias: enumBelegartFahrtkosten */
  enumBelegartFahrtkosten?: any;
  /** Alias: enumZahlungsart */
  enumZahlungsart?: any;
  /** Alias: ePreis */
  ePreis?: any;
  /** Alias: kategorie1 */
  kategorie1?: any;
  /** Alias: kategorie2 */
  kategorie2?: any;
  /** Alias: kostenst */
  kostenst?: number;
  /** Alias: kurs */
  kurs?: any;
  /** Alias: lohnkonto1 */
  lohnkonto1?: number;
  /** Alias: lohnkonto2 */
  lohnkonto2?: number;
  /** Alias: mwst */
  mwst?: any;
  /** Alias: mwst1 */
  mwst1?: any;
  /** Alias: mwst2 */
  mwst2?: any;
  /** Alias: notiz */
  notiz?: any;
  /** Alias: projektCode */
  projektCode?: number;
  /** Alias: reisekostenabrechnungcode */
  reisekostenabrechnungcode?: number;
  /** Alias: sachkonto */
  sachkonto?: number;
  /** Alias: sachkonto1 */
  sachkonto1?: number;
  /** Alias: sachkonto2 */
  sachkonto2?: number;
  /** Alias: steuerschluessel1 */
  steuerschluessel1?: number;
  /** Alias: steuerschluessel2 */
  steuerschluessel2?: number;
  /** Alias: waehrungscode */
  waehrungscode?: number;
  /** Alias: zahlungsart */
  zahlungsart?: number;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];
  /** Alias: betragBrutto3 */
  betragBrutto3?: number;
  /** Alias: mwst3 */
  mwst3?: number;
  /** Alias: belegartCode */
  belegartCode?: number;
  /** Alias: belegartFahrtkostenCode */
  belegartFahrtkostenCode?: number;

  __typename?: string;
}

const fields: FieldDefinitions<InputReisekostenabrechnungBeleg> = {
  abrechnungBZObjMemberCode: {
    alias: 'abrechnungBZObjMemberCode',
  },
  abrechnungKundeErfolgt: {
    alias: 'abrechnungKundeErfolgt',
  },
  abrechnungKundeGeplant: {
    alias: 'abrechnungKundeGeplant',
  },
  anzahl: {
    alias: 'anzahl',
  },
  ausgabe: {
    alias: 'ausgabe',
  },
  belegart: {
    alias: 'belegart',
  },
  belegartFahrtkosten: {
    alias: 'belegartFahrtkosten',
  },
  belegnummer: {
    alias: 'belegnummer',
  },
  benutzercode: {
    alias: 'benutzercode',
  },
  betragBrutto1: {
    alias: 'betragBrutto1',
  },
  betragBrutto2: {
    alias: 'betragBrutto2',
  },
  bezeichnung: {
    alias: 'bezeichnung',
  },
  code: {
    alias: 'code',
  },
  datum: {
    alias: 'datum',
  },
  einnahme: {
    alias: 'einnahme',
  },
  enumBelegart: {
    alias: 'enumBelegart',
  },
  enumBelegartFahrtkosten: {
    alias: 'enumBelegartFahrtkosten',
  },
  enumZahlungsart: {
    alias: 'enumZahlungsart',
  },
  ePreis: {
    alias: 'ePreis',
  },
  kategorie1: {
    alias: 'kategorie1',
  },
  kategorie2: {
    alias: 'kategorie2',
  },
  kostenst: {
    alias: 'kostenst',
  },
  kurs: {
    alias: 'kurs',
  },
  lohnkonto1: {
    alias: 'lohnkonto1',
  },
  lohnkonto2: {
    alias: 'lohnkonto2',
  },
  mwst: {
    alias: 'mwst',
  },
  mwst1: {
    alias: 'mwst1',
  },
  mwst2: {
    alias: 'mwst2',
  },
  notiz: {
    alias: 'notiz',
  },
  projektCode: {
    alias: 'projektCode',
  },
  reisekostenabrechnungcode: {
    alias: 'reisekostenabrechnungcode',
  },
  sachkonto: {
    alias: 'sachkonto',
  },
  sachkonto1: {
    alias: 'sachkonto1',
  },
  sachkonto2: {
    alias: 'sachkonto2',
  },
  steuerschluessel1: {
    alias: 'steuerschluessel1',
  },
  steuerschluessel2: {
    alias: 'steuerschluessel2',
  },
  waehrungscode: {
    alias: 'waehrungscode',
  },
  zahlungsart: {
    alias: 'zahlungsart',
  },
  customFields: {
    alias: 'customFields',
  },
  betragBrutto3: {
    alias: 'betragBrutto3',
  },
  mwst3: {
    alias: 'mwst3',
  },
  belegartCode: {
    alias: 'belegartCode',
  },
  belegartFahrtkostenCode: {
    alias: 'belegartFahrtkostenCode',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputReisekostenabrechnungBelegEntityDefinition: EntitiyDefinition<InputReisekostenabrechnungBeleg> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputReisekostenabrechnungBeleg',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
