import { MouseEventHandler } from 'react';

import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { LockProvider } from '../../../hooks';
import { IConvertPopoverProps } from '../../convert-popover/ConvertPopover';
import { useEntityPreviewData } from '../hooks/use-entity-preview-data';

import { ArticlePreview } from './ArticlePreview';

export type IArticlePreviewContainerProps = {
  articleId: number;
  onCloseClicked?: MouseEventHandler<HTMLButtonElement>;
  onEditClicked?: MouseEventHandler<HTMLButtonElement>;
  onShareClicked?: () => void;
  convertProps?: Pick<IConvertPopoverProps, 'exclude' | 'onClick'>;
};

export const articlePreviewFields: Article<EMode.query> = {
  id: null,
  name: null,
  number: null,
  unit: {
    id: null,
    name: null,
  },
  longtext: null,
  entryPrice: null,
  articleDocumentList: {
    file: {
      id: null,
      fileInfos: {
        previewUrl: null,
        previewMimeType: null,
        fileServiceProviderInfos: {
          id: null,
          exists: null,
        },
      },
    },
    articleImage: {
      id: null,
      fileInfos: {
        previewUrl: null,
        previewMimeType: null,
        fileServiceProviderInfos: {
          id: null,
          exists: null,
        },
      },
    },
  },
  standardImageId: null,
};

export const ArticlePreviewContainer = (
  props: IArticlePreviewContainerProps
) => {
  const {
    articleId,
    onCloseClicked,
    onEditClicked,
    onShareClicked,
    convertProps,
  } = props;

  const articles = useEntityPreviewData<Article>(
    [articleId],
    Entities.article,
    articlePreviewFields
  );

  if (!articles) {
    return null;
  }

  return (
    <LockProvider>
      <ArticlePreview
        onCloseClicked={onCloseClicked}
        onEditClicked={onEditClicked}
        articles={articles}
        onShareClicked={onShareClicked}
        convertProps={convertProps}
      />
    </LockProvider>
  );
};
