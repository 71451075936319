import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  EMailTemplateKindPicker,
  IEMailTemplateKindPickerProps,
} from '@work4all/components/lib/components/entity-picker/email-template-kind-picker/EMailTemplateKindPicker';

import { EMailTemplateKind } from '@work4all/models/lib/Enums/EMailTemplateKind.enum';

import { reactRefSetter } from '@work4all/utils';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

interface Props
  extends Omit<IEMailTemplateKindPickerProps<false>, 'onChange' | 'value'> {
  error?: string;
  disabled?: boolean;
  value: EMailTemplateKind;
  onChange: (value: string) => void;
}

export const EMailTemplateKindPickerField = React.forwardRef<
  HTMLDivElement,
  Props
>((props, ref) => {
  const { error, disabled, ...pickerProps } = props;
  const { value, onChange: pickerOnChange } = pickerProps;

  const { t } = useTranslation();

  const wrapperRef = useRef();
  return (
    <div ref={reactRefSetter(wrapperRef, ref)}>
      <EntityPickerPopover
        anchorEl={wrapperRef}
        picker={
          <EMailTemplateKindPicker
            {...pickerProps}
            value={{
              id: value,
              name: t(`E_MAIL_TEMPLATE_KIND.${value}`),
            }}
            onChange={(value) => pickerOnChange(value?.id)}
          />
        }
      >
        <PickerTargetButton
          disabled={disabled}
          label={t('INPUTS.CONTEXT')}
          error={error}
          value={value ? t(`E_MAIL_TEMPLATE_KIND.${value}`) : ''}
        />
      </EntityPickerPopover>
    </div>
  );
});
