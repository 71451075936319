import { Add } from '@mui/icons-material';
import { Theme, useMediaQuery } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  IMailboxPickerProps,
  MailboxPicker,
} from '@work4all/components/lib/components/entity-picker/mailbox-picker/MailboxPicker';
import { BaseActionButton } from '@work4all/components/lib/input/base-action-button/BaseActionButton';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';
import { LinkMailboxDialog } from '../data-tables/LinkMailboxDialog';

interface MailboxPickerFieldProps
  extends Omit<IMailboxPickerProps<false>, 'multiple'> {
  error?: string;
  name?: string;
  label?: string;
  required?: boolean;
}

export const MailboxPickerField = React.forwardRef<
  HTMLDivElement,
  MailboxPickerFieldProps
>(function MailboxPickerField(props, ref) {
  const {
    error,
    favorites = true,
    label,
    prefilter,
    required,
    ...pickerProps
  } = props;
  const { value, onChange } = pickerProps;

  const { t } = useTranslation();

  const popoverRef = useRef<EntityPickerPopover>(null);

  const resultingLabel = label || t('COMMON.MAILBOX');

  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );

  const [isLinkMailboxDialogOpen, setLinkMailboxDialogOpen] = useState(false);

  return (
    <>
      <LinkMailboxDialog
        open={isLinkMailboxDialogOpen}
        onClose={() => {
          setLinkMailboxDialogOpen(false);
          popoverRef.current.close();
        }}
      />
      <EntityPickerPopover
        fullscreen={isMobile}
        ref={popoverRef}
        footer={
          <BaseActionButton
            icon={<Add />}
            onClick={() => setLinkMailboxDialogOpen(true)}
          >
            {t('COMMON.ADD', { name: t('COMMON.MAILBOX') })}
          </BaseActionButton>
        }
        picker={
          <MailboxPicker
            multiple={false}
            prefilter={prefilter}
            favorites={favorites}
            {...pickerProps}
          />
        }
      >
        <PickerTargetButton
          ref={ref}
          value={value ? value?.mailboxPrimaryAddress : ''}
          name={props.name}
          label={resultingLabel}
          error={error}
          onClear={() => {
            onChange(null);
          }}
          required={required}
        />
      </EntityPickerPopover>
    </>
  );
});
