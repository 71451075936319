import { Send } from '@mui/icons-material';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TextEditorConfig } from '../../../../input/format-text/TextEditor/types';

import { CommentEditor } from './CommentEditor';

interface CommentEditorWithAddButtonProps {
  disabled?: boolean;
  value: string;
  setValue: (value: string) => void;
  onSubmit: () => void | Promise<void>;
  canAddOrInsertImage: boolean;
  editorConf?: TextEditorConfig;
  hideToolbar?: boolean;
  fullCommentEditor?: boolean;
}

export const CommentEditorWithAddButton = ({
  disabled,
  value,
  setValue,
  onSubmit,
  canAddOrInsertImage,
  editorConf,
  hideToolbar,
  fullCommentEditor,
}: CommentEditorWithAddButtonProps) => {
  const { t } = useTranslation();
  const isValueNotEmpty = value.trim().length !== 0;
  const showButton =
    !fullCommentEditor || (fullCommentEditor && isValueNotEmpty);

  return (
    <>
      <CommentEditor
        disabled={disabled}
        value={value}
        setValue={setValue}
        canAddOrInsertImage={canAddOrInsertImage}
        editorConf={editorConf}
        hideToolbar={hideToolbar}
      />
      {showButton && (
        <Button
          onClick={onSubmit}
          disabled={disabled || (!fullCommentEditor && !isValueNotEmpty)}
          sx={{ position: 'absolute', padding: '1.5rem 0rem' }}
        >
          <Send style={{ marginRight: 9 }} />
          <span>{t('MASK.ADD_COMMENT')}</span>
        </Button>
      )}
    </>
  );
};
