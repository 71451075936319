export const replaceUmlauts = (word: string): string => {
  // Umlaut characters and their replacements
  const umlautMap: { [key: string]: string } = {
    ä: 'ae',
    ö: 'oe',
    ü: 'ue',
    ß: 'ss',
    Ä: 'Ae',
    Ö: 'Oe',
    Ü: 'Ue',
  };

  return word.replace(/[äöüßÄÖÜ]/g, (match) => umlautMap[match] || match);
};

export const replaceSpecialCharacters = (word: string): string => {
  return word.replace(/[., ]/g, '');
};
