import styles from './ListItem2.module.scss';

import MuiListItemButton, {
  ListItemButtonProps as MuiListItemButtonProps,
} from '@mui/material/ListItemButton';
import clsx from 'clsx';
import React, { forwardRef } from 'react';

export const ListItem2: React.FC<Omit<MuiListItemButtonProps, 'button'>> =
  forwardRef(function ListItem2(props, ref) {
    return (
      <MuiListItemButton
        ref={ref}
        className={clsx(styles.listItem, props.className)}
        divider={props.divider}
        onClick={props.onClick}
        onContextMenu={props.onContextMenu}
      >
        {props.children}
      </MuiListItemButton>
    );
  }) as React.FC<Omit<MuiListItemButtonProps, 'button'>>;
