import { Theme, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { CheckboxRadioItem } from '@work4all/components/lib/input/checkbox-radio-item';

import { useSetting } from '@work4all/data/lib/settings/use-setting';

import { settings } from '../../../../../../../../settings';
import { TablesLayoutBordersSettingsKey } from '../../../../../../../../settings/settings';

export interface LayoutSectionProps {
  settingsKey?: TablesLayoutBordersSettingsKey;
}

export function LayoutSection(props: LayoutSectionProps) {
  const { t } = useTranslation();

  const tablesLayoutBorders = useSetting(
    settings.tablesLayoutBorders(props.settingsKey)
  );

  const isPhone = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));

  return (
    <Collapse title={t('SETTINGS.TABLES.LAYOUT')} defaultOpen>
      {!isPhone && (
        <CheckboxRadioItem
          label={t('SETTINGS.TABLES.LAYOUT.BORDERS.VERTICAL')}
          checked={tablesLayoutBorders.value.vertical}
          onChange={(e) =>
            tablesLayoutBorders.set({
              ...tablesLayoutBorders.value,
              vertical: e.target.checked,
            })
          }
        />
      )}
      <CheckboxRadioItem
        label={t('SETTINGS.TABLES.LAYOUT.BORDERS.HORIZONTAL')}
        checked={tablesLayoutBorders.value.horizontal}
        onChange={(e) =>
          tablesLayoutBorders.set({
            ...tablesLayoutBorders.value,
            horizontal: e.target.checked,
          })
        }
      />
    </Collapse>
  );
}
