import { gql, useMutation } from '@apollo/client';
import { Error } from '@mui/icons-material';
import { Alert, Box, Divider, Stack, Typography } from '@mui/material';
import { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DialogContent } from '@work4all/components';
import { Dialog } from '@work4all/components/lib/dialog/Dialog';
import { FixedLengthInput } from '@work4all/components/lib/input/fixed-length-input/FixedLengthInput';

import { useUser } from '@work4all/data';

import { InputValidateSetMfaMode } from '@work4all/models/lib/Classes/InputValidateSetMfaMode.entity';
import { ValidateSetMfaModeResponse } from '@work4all/models/lib/Classes/ValidateSetMfaModeResponse.entity';
import { MfaMode } from '@work4all/models/lib/Enums/MfaMode.enum';

const VALIDATE_USER_MFA_MODE = gql`
  mutation validateMfaAuth($input: InputValidateSetMfaMode!) {
    validateUserMfaMode(input: $input) {
      ok
      errorMessage
      errorCode
    }
  }
`;

const codeLength = 6;

export const MfaConfirmDialog = (props: {
  mfaMode: MfaMode;
  qrCode?: string;
  secret?: string;
  open: boolean;
  onClose?: (success?: boolean) => void;
}) => {
  const { open, onClose, mfaMode, qrCode, secret } = props;

  const [error, setError] = useState(null);

  const handleOnClose = useCallback(
    (value?: boolean) => {
      setError(null);
      onClose?.(value);
    },
    [onClose]
  );

  const [mutate] = useMutation<
    { validateUserMfaMode: ValidateSetMfaModeResponse },
    { input: InputValidateSetMfaMode }
  >(VALIDATE_USER_MFA_MODE);
  const user = useUser();

  const onChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const target = e.target;
      if (target.value.length >= codeLength) {
        target.setSelectionRange(0, 0);
        target.blur();
        target.disabled = true;

        const res = await mutate({
          variables: {
            input: {
              mfaCode: target.value,
              mfaMode: mfaMode,
              userCode: user.benutzerCode,
            },
          },
        });

        if (!res.data.validateUserMfaMode.ok) {
          target.value = '';
          target.disabled = false;
          target.focus();
          setError('MFA.VALIDATION.ERROR.WRONG_CODE');
          return;
        }

        handleOnClose(res.data.validateUserMfaMode.ok);
      }
    },
    [mutate, handleOnClose, mfaMode, user.benutzerCode]
  );

  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={(e, reason) => reason !== 'backdropClick' && handleOnClose()}
      title={t(`MFA.${mfaMode === MfaMode.MAIL ? 'EMAIL' : 'AUTH_APP'}.TITLE`)}
    >
      <DialogContent>
        <Stack gap="1rem">
          {mfaMode === MfaMode.TOKEN ? (
            <>
              <Divider title={t('MFA.STEP') + ' 1'} />
              <Typography>{t('MFA.AUTH_APP.STEP_1_DESC')}</Typography>
              <img
                src={'data:image/jpeg;base64, ' + qrCode}
                alt={secret}
                style={{
                  width: '18rem',
                  margin: 'auto',
                }}
              />
              <Alert color="info" icon={false}>
                <Box
                  component="small"
                  style={{
                    textAlign: 'center',
                    wordBreak: 'break-all',
                    display: 'block',
                  }}
                >
                  {secret}
                </Box>
              </Alert>

              <Divider title={t('MFA.STEP') + ' 2'} />
              <Typography>{t('MFA.AUTH_APP.STEP_2_DESC')}</Typography>
            </>
          ) : (
            <Typography>{t('MFA.EMAIL.DESC')}</Typography>
          )}
          <Stack alignItems="center">
            <FixedLengthInput
              length={codeLength}
              onChange={onChange}
              autoFocus
            />
          </Stack>

          {error && (
            <Alert color="error" icon={<Error />}>
              <Typography>{t(error)}</Typography>
            </Alert>
          )}
          <Alert severity="warning">{t('MFA.AUTH_APP.STEP_2_INFO')}</Alert>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
