import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { CustomerPicker } from '../../../../../entity-picker/customer-picker/CustomerPicker';
import { withExtras } from '../../utils';

import { PreviewPickerInput } from './PreviewPickerInput';

export const CustomerPreviewPickerInput = withExtras<Customer>(
  ({ value, onChange, ...rest }) => {
    return (
      <PreviewPickerInput<Customer>
        {...rest}
        value={value}
        formattedValue={value?.name}
        picker={
          <CustomerPicker value={value} onChange={onChange} multiple={false} />
        }
        onChange={onChange}
        entity={Entities.customer}
      />
    );
  }
);
