import { useCallback, useMemo } from 'react';

import { useUser } from '@work4all/data';

import { WordDocumentTemplate } from '@work4all/models/lib/Classes/WordDocumentTemplate.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { DndTypes } from '@work4all/utils/lib/variables';

import { PDFTextmarkConf } from '../../../preview/pdf-textmarks/PDFTextmarkContainer';
import { MIME_TYPES } from '../../../preview/Preview';
import { TextmarkPickerResult } from '../../entity-picker/textmark-picker';
import { IDocumentPreviewContainerProps } from '../document-preview';
import { DocumentPreview } from '../document-preview/DocumentPreview';
import { useEntityPreviewData } from '../hooks/use-entity-preview-data';

import { TextmarkPickerButton } from './TextmarkPickerButton';
import { useDocumentTemplateSettings } from './use-document-template-settings';

export interface IDocumentTemplatePreviewContainerProps
  extends Omit<IDocumentPreviewContainerProps, 'documentId'> {
  wordDocumentTemplateId: number;
}

const requestedWordDocumentTemplateData: WordDocumentTemplate<EMode.query> = {
  id: null,
  templateFilename: null,
  fileInfos: {
    previewUrl: null,
    previewMimeType: null,
    fileServiceProviderInfos: {
      id: null,
      fileName: null,
      mimeType: null,
      fspUrl: null,
    },
  },
  title: null,
};

export function WordDocumentTemplatePreviewContainer(
  props: IDocumentTemplatePreviewContainerProps
) {
  const { wordDocumentTemplateId, onCloseClicked, onEditClicked } = props;

  const wordDocumentTemplates = useEntityPreviewData<WordDocumentTemplate>(
    [wordDocumentTemplateId],
    Entities.wordDocumentTemplate,
    requestedWordDocumentTemplateData
  );

  const user = useUser();

  const { settings, update: updateSettings } = useDocumentTemplateSettings(
    wordDocumentTemplateId,
    Entities.wordDocumentTemplate
  );

  const isPDF = useMemo(() => {
    const segments = wordDocumentTemplates?.[0]?.templateFilename.split('.');
    return segments && segments[segments.length - 1].toLowerCase() === 'pdf';
  }, [wordDocumentTemplates]);

  const handleTextmarkPickerChange = useCallback(
    async (value: TextmarkPickerResult) => {
      if (!value) {
        return;
      }

      updateSettings({
        pdfTextmarkItems: [
          ...settings.pdfTextmarkItems,
          {
            id: value.textmark.id,
            left: 0.5,
            top: 0.25,
            title: value.textmark.name,
            type: DndTypes.BOX,
            accessor: value.textmark.accessor,
            pageNumber: 1,
          },
        ],
      });
    },
    [settings.pdfTextmarkItems, updateSettings]
  );

  const pdfTextmarkConf = useMemo<PDFTextmarkConf>(() => {
    return {
      pdfTextMarkItems: settings.pdfTextmarkItems,
      onItemsChange: (items) => updateSettings({ pdfTextmarkItems: items }),
      disabled: !user.isMaster,
    };
  }, [settings.pdfTextmarkItems, updateSettings, user.isMaster]);

  const selectedRowsIdsList = useMemo(() => {
    return wordDocumentTemplates?.map((x) => x.id) ?? [];
  }, [wordDocumentTemplates]);

  if (!wordDocumentTemplates) return null;

  return (
    <DocumentPreview
      entries={wordDocumentTemplates}
      entity={Entities.wordDocumentTemplate}
      entityData={requestedWordDocumentTemplateData}
      titleKeyField="title"
      title={
        wordDocumentTemplates[0].title ||
        wordDocumentTemplates[0].fileInfos?.fileServiceProviderInfos
          ?.fileName ||
        ''
      }
      url={`${wordDocumentTemplates[0].fileInfos.previewUrl}`}
      mimeType={
        wordDocumentTemplates[0].fileInfos?.previewMimeType as MIME_TYPES
      }
      noPreviewUrl={
        wordDocumentTemplates[0].fileInfos?.fileServiceProviderInfos?.fspUrl ||
        wordDocumentTemplates[0].fileInfos?.downloadUrl
      }
      onCloseClicked={onCloseClicked}
      onEditClicked={onEditClicked}
      pdfTextmarkConf={isPDF && pdfTextmarkConf}
      additionalTitleItems={
        user.isMaster &&
        isPDF && (
          <TextmarkPickerButton
            onChange={handleTextmarkPickerChange}
            type="document"
          />
        )
      }
      selectedRowsIdsList={selectedRowsIdsList}
    />
  );
}
