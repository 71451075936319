import { Capacitor } from '@capacitor/core';
import { AppUpdate } from '@capawesome/capacitor-app-update';
import { useEventCallback } from '@mui/material/utils';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useDispatch } from 'react-redux';

import { useUser } from '@work4all/data';
import { logoutUser } from '@work4all/data/lib/actions/user-actions';

import { ApiVersionContextProvider } from './api-version-context';
import {
  ApiVersionLinkContextProvider,
  ApiVersionLinkContextValue,
} from './api-version-link-context';
import { ApiUpdateRequiredAlert } from './components/ApiUpdateRequiredAlert';
import { AppUpdateAvailableMobileAlert } from './components/AppUpdateAvailableMobileAlert';
import { AppUpdateAvailableWebAlert } from './components/AppUpdateAvailableWebAlert';
import { ErrorFallback } from './components/ErrorFallback';
import { checkApiVersion } from './utils/check-api-version';
import { checkForUpdates } from './utils/check-for-updates';
import { createApiVersionLink } from './utils/create-api-version-link';
import {
  didShowUpdateNotificationRecently,
  saveLastNotificationDate,
} from './utils/did-shown-update-notification-recently';

export interface VersionCheckerProps {
  children?: React.ReactNode;
}

export function VersionChecker(props: VersionCheckerProps) {
  const { children } = props;

  const user = useUser();

  const isUserLoggedIn = user != null;

  const dispatch = useDispatch();

  const [isApiUpdateRequired, setIsApiUpdateRequired] = useState(false);
  const [isAppUpdateAvailableWeb, setIsAppUpdateAvailableWeb] = useState(false);
  const [isAppUpdateAvailableMobile, setIsAppUpdateAvailableMobile] =
    useState(false);

  const lastCheckedVersion = useRef<string | null>(null);

  // Reset the alerts UI state when the user is logged out.
  useEffect(() => {
    if (!isUserLoggedIn) {
      lastCheckedVersion.current = null;
      setIsApiUpdateRequired(false);
      setIsAppUpdateAvailableWeb(false);
      setIsAppUpdateAvailableMobile(false);
    }
  }, [isUserLoggedIn]);

  const [version, setVersion] = useState('');
  const handleVersion = useEventCallback((version: string) => {
    setVersion(version);

    // Don't show any alerts if a user is not logged in.
    if (!isUserLoggedIn) {
      return;
    }

    // Don't check the same version on every request. Since the app's version
    // can't change without a reload there is no reason to check it again.
    if (lastCheckedVersion.current === version) return;

    const result = checkApiVersion(version);
    lastCheckedVersion.current = version;
    setIsApiUpdateRequired(!result.satisfies);

    // If the real API version is not larger than the required one, don't check
    // for app updates.
    if (!result.isGreater) {
      return;
    }

    checkForUpdates({
      baseUrl: user.baseUrl,
      token: user.token,
    }).then(
      ({ isUpdateAvailable }) => {
        if (!isUpdateAvailable) return;
        const platform = Capacitor.getPlatform();
        switch (platform) {
          case 'web':
            setIsAppUpdateAvailableWeb(true);
            return;

          case 'android':
          case 'ios':
            if (!didShowUpdateNotificationRecently()) {
              setIsAppUpdateAvailableMobile(true);
            }
            return;

          default:
            throw new Error(`Unknown platform ${platform}`);
        }
      },
      (reason) => {
        console.error(reason);
      }
    );
  });

  const handleUpdateRequiredConfirm = () => {
    dispatch(logoutUser());
  };

  const handleUpdateAvailableWebConfirm = () => {
    window.location.reload();
  };

  const handleUpdateAvailableMobileConfirm = () => {
    AppUpdate.openAppStore();
  };

  const handleUpdateAvailableMobileDismiss = () => {
    saveLastNotificationDate();
    setIsAppUpdateAvailableMobile(false);
  };

  const context = useMemo<ApiVersionLinkContextValue>(() => {
    const link = createApiVersionLink({ onVersion: handleVersion });
    return { link };
  }, [handleVersion]);

  // Add an error boundary here in case the app crashes because of API changes.
  // This way we can still show the update notification.
  return (
    <ApiVersionLinkContextProvider value={context}>
      <ApiVersionContextProvider value={{ version }}>
        <ApiUpdateRequiredAlert
          open={isApiUpdateRequired}
          onConfirm={handleUpdateRequiredConfirm}
        />

        <AppUpdateAvailableWebAlert
          open={isAppUpdateAvailableWeb}
          onConfirm={handleUpdateAvailableWebConfirm}
        />

        <AppUpdateAvailableMobileAlert
          open={isAppUpdateAvailableMobile}
          onConfirm={handleUpdateAvailableMobileConfirm}
          onDismiss={handleUpdateAvailableMobileDismiss}
        />

        <ErrorBoundary FallbackComponent={ErrorFallback}>
          {children}
        </ErrorBoundary>
      </ApiVersionContextProvider>
    </ApiVersionLinkContextProvider>
  );
}
