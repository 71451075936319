import styles from '../appointmentview/AppointmentView.module.scss';

import { Notifications } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { Card } from '@work4all/components/lib/dataDisplay/card';
import { BaseActionButton } from '@work4all/components/lib/input/base-action-button/BaseActionButton';

import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import {
  CheckboxRadioItem,
  DateTimeInputPicker,
} from '../../../../../../../../locked-inputs';
import { TabPanelSectionProps } from '../../../../../../../types';
import { AppointmentMaskFormValue } from '../../../../../types';

const collapseClasses = {
  root: styles.collapseContainer,
  wrapperInner: styles.collapseWrapperInner,
};

export const Period: React.FC<TabPanelSectionProps> = () => {
  const { t } = useTranslation();

  const { control, register, setValue, watch } =
    useFormContextPlus<AppointmentMaskFormValue>();

  const isWholeDay = watch('isWholeDay');
  const remind = watch('remind');

  return (
    <Collapse
      classes={collapseClasses}
      title={t('INPUTS.PERIOD')}
      className={styles.collapse}
      defaultOpen={true}
    >
      <Card>
        <Grid container spacing="1rem">
          <Grid item xs={12} md={6} lg={3}>
            <Controller
              name="startDate"
              control={control}
              render={({ field }) => (
                <DateTimeInputPicker
                  {...field}
                  dateLabel={t('COMMON.STARTDATE')}
                  data-testid="start-appointment"
                  withTime={!isWholeDay}
                  clearable={false}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Controller
              name="endDate"
              control={control}
              render={({ field }) => (
                <DateTimeInputPicker
                  {...field}
                  dateLabel={t('COMMON.ENDDATE')}
                  data-testid="end-appointment"
                  withTime={!isWholeDay}
                  clearable={false}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} md={6} lg={2}>
            <CheckboxRadioItem
              data-testid="enabled-whole-day-mode"
              label={t('COMMON.WHOLEDAYEVENT')}
              defaultChecked={isWholeDay}
              {...register('isWholeDay')}
            />
          </Grid>
          <Grid item xs={remind ? 12 : 6} md={6} lg={4}>
            {remind ? (
              <Controller
                name="remindDate"
                control={control}
                render={({ field }) => (
                  <DateTimeInputPicker
                    {...field}
                    dateLabel={t('COMMON.REMINDER')}
                    timeLabel={t('COMMON.TIME')}
                    clearable={false}
                  />
                )}
              />
            ) : (
              <BaseActionButton
                onClick={() => setValue('remind', true, { shouldDirty: true })}
                icon={<Notifications />}
              >
                {t('COMMON.ADD_REMINDER')}
              </BaseActionButton>
            )}
          </Grid>
        </Grid>
      </Card>
    </Collapse>
  );
};
