import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { FC, MouseEventHandler, useContext } from 'react';

import { IConvertPopoverProps } from '@work4all/components/lib/components/convert-popover/ConvertPopover';
import { AppointmentPreviewContainer } from '@work4all/components/lib/components/entity-preview/appointment-preview';
import { ArticlePreviewContainer } from '@work4all/components/lib/components/entity-preview/article-preview';
import { CallMemoPreviewContainer } from '@work4all/components/lib/components/entity-preview/call-memo-preview';
import { ChecklistPreviewContainer } from '@work4all/components/lib/components/entity-preview/checklist-preview/ChecklistPreviewContainer';
import { DocumentPreviewContainer } from '@work4all/components/lib/components/entity-preview/document-preview';
import { EmailPreviewContainer } from '@work4all/components/lib/components/entity-preview/email-preview';
import { EmailSignaturePreviewContainer } from '@work4all/components/lib/components/entity-preview/email-signature-preview';
import { EmailTemplatePreviewContainer } from '@work4all/components/lib/components/entity-preview/email-template-preview';
import { ErpPreviewContainer } from '@work4all/components/lib/components/entity-preview/erp-preview/ErpPreviewContainer';
import { LetterPreviewContainer } from '@work4all/components/lib/components/entity-preview/letter-preview';
import { NotePreviewContainer } from '@work4all/components/lib/components/entity-preview/note-preview';
import { TaskPreviewContainer } from '@work4all/components/lib/components/entity-preview/task-preview';
import { WordDocumentTemplatePreviewContainer } from '@work4all/components/lib/components/entity-preview/template-preview/WordDocumentTemplatePreviewContainer';
import { WordLetterTemplatePreviewContainer } from '@work4all/components/lib/components/entity-preview/template-preview/WordLetterTemplatePreviewContainer';
import { TextBuildingBlockPreviewContainer } from '@work4all/components/lib/components/entity-preview/text-building-block-preview';
import { TicketPreviewContainer } from '@work4all/components/lib/components/entity-preview/ticket-preview/TicketPreviewContainer';
import { VisitationReportPreviewContainer } from '@work4all/components/lib/components/entity-preview/visitation-report-preview';
import { UserIconRegisterContext } from '@work4all/components/lib/components/user-icon/useUserIconRegister';
import {
  CustomToolbar,
  CustomToolbarAction,
} from '@work4all/components/lib/dataDisplay/actions-bar/ToolbarTypes';
import { Caption } from '@work4all/components/lib/typography/caption/Caption';

import { EntitiesOfErpGroups } from '@work4all/data';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { SalesOpportunitiesContainer } from '../../../../../components/data-tables/sales-opportunities-table/sales-opportunities-preview/SalesOpportunitiesContainer';
import { PdfOverlayMaskProvider } from '../../../../mask-overlays/mask-overlay/views/pdf-editor/PdfOverlayMaskProvider';
import { CustomerFileInfoPanelContainer } from '../file-info-panel/customer-file-info-panel-container/CustomerFileInfoPanelContainer';
import { ProjectFileInfoPanelContainer } from '../file-info-panel/project-file-info-panel-container/ProjectFileInfoPanelContainer';
import { SupplierFileInfoPanelContainer } from '../file-info-panel/supplier-file-info-panel-container copy/SupplierFileInfoPanelContainer';

export type IFilePreviewPanelProps = {
  entityIds: (number | string)[];
  entityType: Entities;
  onCloseClicked: MouseEventHandler<HTMLButtonElement>;
  onEditClicked: MouseEventHandler<HTMLButtonElement>;
  openInFullscreen?: boolean;
  onShareClicked?: () => void;
  convertProps?: Pick<IConvertPopoverProps, 'exclude' | 'onClick'>;
  customActions?: CustomToolbar<CustomToolbarAction>;
  openMaskTab?: (tab: string) => void;
};

export const FilePreviewPanel: FC<IFilePreviewPanelProps> = ({
  entityIds: nullableEntitiesId,
  entityType,
  openInFullscreen = false,
  ...commonProps
}) => {
  const entityIds = nullableEntitiesId.filter(Boolean);

  const userIconContext = useContext(UserIconRegisterContext);

  const isErpType = EntitiesOfErpGroups.includes(entityType);
  if (isErpType) {
    return (
      <PdfOverlayMaskProvider>
        <ErpPreviewContainer
          {...commonProps}
          openInFullscreen={openInFullscreen}
          entityId={entityIds[0] as number}
          entity={entityType}
        />
      </PdfOverlayMaskProvider>
    );
  }

  switch (entityType) {
    case Entities.supplier:
      return (
        <SupplierFileInfoPanelContainer
          {...commonProps}
          id={entityIds[0] as number}
        />
      );
    case Entities.customer:
      return (
        <CustomerFileInfoPanelContainer
          {...commonProps}
          id={entityIds[0] as number}
        />
      );
    case Entities.eMail:
      return (
        <EmailPreviewContainer
          {...commonProps}
          emailId={entityIds[0] as number}
        />
      );
    case Entities.visitationReport:
      return (
        <VisitationReportPreviewContainer
          {...commonProps}
          visitationReportIds={entityIds as number[]}
          userIconContext={userIconContext}
        />
      );

    case Entities.note:
      return (
        <NotePreviewContainer
          {...commonProps}
          noteIds={entityIds as number[]}
        />
      );
    case Entities.appointment:
      return (
        <AppointmentPreviewContainer
          {...commonProps}
          appointmentIds={entityIds as number[]}
        />
      );

    case Entities.project:
      return (
        <ProjectFileInfoPanelContainer
          {...commonProps}
          projectId={entityIds[0] as number}
        />
      );
    case Entities.callMemo:
      return (
        <CallMemoPreviewContainer
          {...commonProps}
          callMemoIds={entityIds as number[]}
        />
      );
    case Entities.task:
      return (
        <TaskPreviewContainer
          {...commonProps}
          taskIds={entityIds as number[]}
        />
      );
    case Entities.document:
      return (
        <PdfOverlayMaskProvider>
          <DocumentPreviewContainer
            {...commonProps}
            documentId={entityIds[0] as number}
          />
        </PdfOverlayMaskProvider>
      );
    case Entities.letter:
      return (
        <PdfOverlayMaskProvider>
          <LetterPreviewContainer
            {...commonProps}
            letterId={entityIds[0] as number}
          />
        </PdfOverlayMaskProvider>
      );
    case Entities.checkList:
      return (
        <ChecklistPreviewContainer
          {...commonProps}
          checklistIds={entityIds as number[]}
        />
      );
    case Entities.ticket:
      return (
        <TicketPreviewContainer
          {...commonProps}
          ticketIds={entityIds as string[]}
        />
      );
    case Entities.salesOpportunities:
      return (
        <SalesOpportunitiesContainer
          {...commonProps}
          ids={entityIds as number[]}
        />
      );

    // TODO: unify all ids to single property - best would be if they are arrays
    case Entities.article:
      return (
        <ArticlePreviewContainer
          {...commonProps}
          articleId={entityIds[0] as number}
        />
      );

    case Entities.wordLetterTemplate:
      return (
        <WordLetterTemplatePreviewContainer
          {...commonProps}
          wordLetterTemplateId={entityIds[0] as number}
        />
      );

    case Entities.wordDocumentTemplate:
      return (
        <WordDocumentTemplatePreviewContainer
          {...commonProps}
          wordDocumentTemplateId={entityIds[0] as number}
        />
      );
    case Entities.textBuildingBlock:
      return (
        <TextBuildingBlockPreviewContainer
          {...commonProps}
          textBuildingBlockId={entityIds[0] as number}
        />
      );
    case Entities.eMailTemplate:
      return (
        <EmailTemplatePreviewContainer
          {...commonProps}
          emailTemplateId={entityIds[0] as string}
        />
      );
    case Entities.eMailSignature:
      return (
        <EmailSignaturePreviewContainer
          {...commonProps}
          emailSignatureId={entityIds[0] as string}
        />
      );

    default:
      return (
        <div style={{ padding: '1rem' }}>
          <IconButton
            onClick={(e) => {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              commonProps.onCloseClicked(e as any);
            }}
            size="small"
          >
            <CloseIcon />
          </IconButton>
          <Caption>ToDo: not yet implemented</Caption>
        </div>
      );
  }
};
