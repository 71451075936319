import Typography from '@mui/material/Typography';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  IInfoCardProps,
  InfoCard,
} from '@work4all/components/lib/components/entity-preview/components';

import { formatNumberAsCurrency } from '@work4all/utils';
import {
  DateFormatPreset,
  formatDateString,
} from '@work4all/utils/lib/date-utils/formatDateString';

import { ControlWrapper } from '../../../../components';

export const GeneralData = () => {
  const { t } = useTranslation();
  const { getValues } = useFormContext();
  const currency = getValues('currency.name');

  return (
    <>
      <ControlWrapper columns={0}>
        <DataCard
          label={t('COMMON.INBOUNDINVOICE')}
          children={`${getValues('id')} ${
            getValues('note') ? `| ${getValues('note')}` : ''
          }`}
        />

        <DataCard
          label={`${t('COMMON.SUPPLIER')} ${getValues('supplier.number')}`}
          children={
            <div>
              <Typography>{getValues('supplier.name')}</Typography>
              <Typography>{getValues('supplier.street')}</Typography>
              <Typography>
                {getValues('supplier.postalCode')} {getValues('supplier.city')}
              </Typography>
            </div>
          }
        />
      </ControlWrapper>

      <ControlWrapper columns={0}>
        <ControlWrapper columns={3}>
          <DataCard
            label={t('COMMON.DATE')}
            children={formatDateString(
              getValues('date'),
              DateFormatPreset.DATE_SIMPLE_2YEAR
            )}
          />
          <DataCard
            label={t('COMMON.INBOUND_INVOICE.DUE')}
            children={formatDateString(
              getValues('dueDate'),
              DateFormatPreset.DATE_SIMPLE_2YEAR
            )}
          />
        </ControlWrapper>

        <ControlWrapper columns={3}>
          <DataCard
            label={t('COMMON.GROSS')}
            children={formatNumberAsCurrency(getValues('grossAmount'))}
          />
          <DataCard
            label="Rest"
            children={formatNumberAsCurrency(getValues('remainingAmount'))}
            color={getValues('remainingAmount') !== 0 ? 'red' : undefined}
          />
        </ControlWrapper>

        {currency && currency !== 'EUR' && (
          <ControlWrapper columns={3}>
            <DataCard
              label={`${t('COMMON.GROSS')} [${currency}]`}
              children={formatNumberAsCurrency(
                getValues('grossAmount') * getValues('course')
              )}
            />
            <DataCard
              label={`Rest [${currency}]`}
              children={formatNumberAsCurrency(
                getValues('remainingAmount') * getValues('course')
              )}
            />
            <DataCard
              label={t('COMMON.ERP.FACTOR')}
              children={getValues('course')}
            />
          </ControlWrapper>
        )}
      </ControlWrapper>
    </>
  );
};

const DataCard = (props: IInfoCardProps) => (
  <InfoCard staticField truncate {...props} />
);
