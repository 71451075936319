import { Box } from '@mui/material';
import { useContext, useRef, useState } from 'react';
import sanitizeHtml from 'sanitize-html';

import { LockContext } from '@work4all/components/lib/hooks';
import { TextEditor } from '@work4all/components/lib/input/format-text/TextEditor';

import { EditWrap } from '../../../../edit-wrap/EditWrap';

const sanitizeOpt = {
  allowedTags: [
    'img',
    'p',
    'div',
    'span',
    'table',
    'tr',
    'td',
    'th',
    'strong',
    'u',
    'em',
    'i',
    's',
    'li',
    'ol',
    'ul',
    'a',
  ],
  allowedAttributes: {
    img: ['src', 'style'],
    a: ['href'],
    '*': ['style'],
  },
};

interface Props {
  text: string;
  onChange: (value: string) => void;
}

export const ErpTextEditor = (props: Props) => {
  const { text, onChange } = props;

  const textContainerRef = useRef<HTMLDivElement>(null);
  const [editMode, setEditMode] = useState(false);

  const { locked } = useContext(LockContext);

  return (
    <EditWrap onEditModeToggle={(e) => setEditMode(e)} editMode={editMode}>
      {editMode && !locked ? (
        <TextEditor value={text} onChange={onChange} />
      ) : (
        <Box
          ref={textContainerRef}
          dangerouslySetInnerHTML={{
            __html: sanitizeHtml(text, sanitizeOpt),
          }}
          minHeight="2rem"
        />
      )}
    </EditWrap>
  );
};
