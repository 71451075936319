import styles from './ServiceContractTable.module.scss';

import { Paper, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { IEntityTable } from './entity-table/EntityTable';

export const ServiceContractTable = React.forwardRef<
  TableInstance,
  IEntityTable
>(() => {
  const { t } = useTranslation();

  return (
    <Paper className={styles.root}>
      <Typography className={styles.body2}>{t('MASK.WIP_WARNING')}</Typography>
    </Paper>
  );
});
