import styles from './DateTimeInputPicker.module.scss';

import clsx from 'clsx';
import { DateTime } from 'luxon';
import React, {
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { W4ADateFormat } from '@work4all/models/lib/additionalEnums/DateFormat.enum';

import { dateTimeFromString } from '@work4all/utils/lib/date-utils/dateTimeFromString';
import { validDate } from '@work4all/utils/lib/date-utils/validDate';
import { withNavigatorLocale } from '@work4all/utils/lib/date-utils/withNavigatorLocale';
import { useForceUpdate } from '@work4all/utils/lib/hooks/use-force-update';

import { Caption } from '../../typography/caption/Caption';
import { LabeledDateInputWithDropdown } from '../labeled-date-input';
import { LabeledTimeInputWithDropdown } from '../labeled-time-input';
import { MultiStepControls, Step } from '../multi-step-controls';
import { timeUtils } from '../time-input-picker/timeUtils';

import { useInputState } from './hooks/useInputState';
import { triggerChangeEvent } from './hooks/useOnChangeTrigger';
import { usePickerVisibilityState } from './hooks/usePickerVisibilityState';
import { IDateTimePickerProps } from './types';

export const validateDateString = (dateString?: string) => {
  if (!dateString) {
    return false;
  }
  // We decided to use this custom Format
  const dt = dateTimeFromString(dateString, 'dd.MM.yyyy');
  return dt.isValid ? dt : false;
};

const dateToString = (date?: DateTime) => {
  if (!date) {
    return '';
  }

  return date.toFormat('dd.MM.yyyy');
};

const parseDateString = (
  dateString: string | Date,
  dateFromCustomString: (str: string, customFormat?: string) => DateTime,
  customFormat?: string
) => {
  let value: Date | DateTime | string;
  if (typeof dateString === 'string') {
    value = customFormat
      ? dateFromCustomString(dateString, customFormat)
      : DateTime.fromISO(dateString);
  } else {
    value = DateTime.fromJSDate(dateString);
  }

  return value && value.isValid && validDate(value.toJSDate())
    ? withNavigatorLocale(value)
    : null;
};

const EnhancedDateInput: React.FC<
  IDateTimePickerProps & {
    hiddenInputField: HTMLInputElement;
    anchorEl: HTMLDivElement;
  }
> = (props) => {
  const {
    withTime = true,
    dateLabel,
    timeLabel,
    customFormat = W4ADateFormat.DEFAULT,
    required = false,
    anchorCenterElRef,
    anchorEl,
    hiddenInputField,
    clearable = true,
    disabled,
    disabledDate,
  } = props;

  const { t } = useTranslation();
  const [activeValue, setActiveValue] = useState<string>();

  const activeRef = useRef<string>();
  activeRef.current = activeValue;

  const {
    showPicker: showDatePicker,
    onShowPicker: onShowDatePicker,
    onHidePicker: onHideDatePicker,
  } = usePickerVisibilityState();

  const {
    showPicker: showTimePicker,
    onShowPicker: onShowTimePicker,
    onHidePicker: onHideTimePicker,
  } = usePickerVisibilityState();

  const { defaultDate, defaultTime, defaultValue } = useMemo(() => {
    return {
      defaultDate: parseDateString(
        activeValue,
        dateTimeFromString,
        customFormat
      ),
      defaultTime: parseDateString(
        activeValue,
        dateTimeFromString,
        customFormat
      ),
      defaultValue: parseDateString(
        activeValue,
        dateTimeFromString,
        customFormat
      ),
    };
  }, [activeValue, customFormat]);

  const { date, setDate, dateString, ...dateInputStateProps } = useInputState(
    validateDateString,
    dateToString,
    defaultDate,
    (date) => emitChange({ date })
  );

  const {
    date: time,
    setDate: setTime,
    dateString: timeString,
    ...timeInputProps
  } = useInputState(
    timeUtils.validateTimeString,
    timeUtils.timeToString,
    defaultTime,
    (time) => emitChange({ time })
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    //this needs to be performed always as a sideeffect to be evaluated in the next renderframe
    if (hiddenInputField?.value !== activeRef.current) {
      setActiveValue(hiddenInputField?.value);
    }
  });

  // Emit change event on the input when date/time is changed.
  function emitChange({
    date: newDate,
    time: newTime,
  }: {
    date?: DateTime;
    time?: DateTime;
  }) {
    triggerChangeEvent(
      defaultValue,
      hiddenInputField,
      newDate ?? date,
      newTime ?? time,
      customFormat
    );
  }

  return (
    <MultiStepControls>
      <Step active={true} index={0}>
        <LabeledDateInputWithDropdown
          disabled={disabled || disabledDate}
          anchorEl={anchorEl}
          anchorCenterEl={anchorCenterElRef?.current}
          required={required}
          inputProps={{
            'data-testid': props['data-testid']
              ? `${props['data-testid']}-date`
              : undefined,
          }}
          value={dateString}
          label={
            dateLabel ||
            (date
              ? t('COMMON.DATE')
              : `${t('COMMON.DATE')} / ${t('COMMON.TIME')}`)
          }
          showDropdown={showDatePicker}
          onHideDropdown={onHideDatePicker}
          onShowDropdown={(e) => {
            e.preventDefault();
            onShowDatePicker();
          }}
          onDateSelect={(date) => {
            setDate(date);
            emitChange({ date });
          }}
          onClear={
            clearable === true
              ? () => {
                  setDate(null);
                  emitChange({ date: null });
                }
              : undefined
          }
          onIconClick={onShowDatePicker}
          {...dateInputStateProps}
          {...props.dateInputProps}
          style={disabledDate ? { cursor: 'default' } : null}
        />
      </Step>
      <Step
        active={
          /*date needs to be set first to activate timepicker*/ Boolean(date) &&
          withTime
        }
        index={1}
      >
        <LabeledTimeInputWithDropdown
          disabled={disabled}
          anchorEl={anchorEl}
          anchorCenterEl={anchorCenterElRef?.current}
          required={required}
          inputProps={{
            'data-testid': props['data-testid']
              ? `${props['data-testid']}-time`
              : undefined,
          }}
          value={timeString}
          label={timeLabel || t('COMMON.TIME')}
          placeholder="hh:mm"
          showDropdown={showTimePicker}
          onHideDropdown={onHideTimePicker}
          onShowDropdown={onShowTimePicker}
          onTimeSelect={(time) => {
            setTime(time);
            emitChange({ time });
          }}
          {...props.timeInputProps}
          {...timeInputProps}
        />
      </Step>
    </MultiStepControls>
  );
};

export const DateTimeInputPicker = React.forwardRef<
  HTMLInputElement,
  IDateTimePickerProps
>(function DateTimeInputPicker(props, ref) {
  const {
    value,
    customFormat = W4ADateFormat.DEFAULT,
    error,
    disabled,
    defaultValue,
    disabledDate: _disabledDate,
    dateLabel: _dateLabel,
    withTime: _withTime,
    timeLabel: _timeLabel,
    clearable: _clearable,
    ...inputProps
  } = props;
  const inputRef = useRef<HTMLInputElement>();
  const anchorElRef = useRef<HTMLDivElement>();
  const [mounted, setMounted] = useState(false);

  //we only do render the enhanced markup after we have the ref to our inputfield so that we can safley determine if the initial value is the default value or stems from the field
  useEffect(() => {
    setMounted(true);
  }, []);

  const forceUpdate = useForceUpdate();

  // Force the component to render when something (form) changes the value via the ref.
  useImperativeHandle(
    ref,
    () => {
      return {
        get value() {
          return inputRef.current.value;
        },

        set value(newValue) {
          inputRef.current.value = newValue;
          forceUpdate();
        },

        focus() {
          inputRef.current.focus();
        },

        // Add other properties if needed.
      } as HTMLInputElement;
    },
    [forceUpdate]
  );

  const inputValue = toInputValue(value, customFormat);
  const inputDefaultValue = toInputValue(defaultValue, customFormat);

  return (
    <div
      ref={anchorElRef}
      className={clsx(styles.wrapper, {
        [styles.error]: error,
      })}
    >
      {error !== undefined && (
        <div className={styles.errorMessage}>
          <Caption color="error">{error}</Caption>
        </div>
      )}
      {/** input holds string (a custom dateformat or ISO by default) thats created by merging "date" and "time" objects */}
      <input
        ref={inputRef}
        {...inputProps}
        hidden
        value={inputValue}
        defaultValue={inputDefaultValue}
      />
      {mounted && (
        <EnhancedDateInput
          {...props}
          disabled={disabled}
          anchorEl={anchorElRef.current}
          hiddenInputField={inputRef.current}
        />
      )}
    </div>
  );
});

function toInputValue(date: string | Date, customFormat: string): string {
  if (!date) {
    // null or undefined (can't be inferred because of no strictNullChecks)
    return date as string;
  }

  if (typeof date === 'string') {
    return date;
  }

  return DateTime.fromJSDate(date).toFormat(customFormat);
}
