import { useEntityEvents } from '@work4all/data/lib/entity-events/use-entity-events';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useOnOpenMaskContext } from '../../../../../mask-overlays/mask-overlay/components/OpenMaskProvider';
import { useSupplier } from '../../queries/use-supplier';
import { FilePreviewPanelBaseProps } from '../FilePreviewPanel';
import { SupplierFileInfoPanel } from '../SupplierFileInfoPanel';

export default interface SupplierFileInfoPanelContainerProps
  extends Pick<FilePreviewPanelBaseProps, 'onCloseClicked' | 'onEdit'> {
  id: number;
}

export const SupplierFileInfoPanelContainer = (
  props: SupplierFileInfoPanelContainerProps
) => {
  const { id } = props;
  const {
    supplier: fullyLoadedSupplier,
    refetch,
    pending,
  } = useSupplier(id.toString());

  useEntityEvents((event) => {
    if (event.entity === Entities.contact) {
      refetch();
    }
  });

  const { onOpenMask } = useOnOpenMaskContext();

  return (
    <SupplierFileInfoPanel
      supplier={fullyLoadedSupplier}
      loading={pending}
      onAddContact={() => {
        onOpenMask().handler({
          entity: Entities.contact,
          template: {
            entity: Entities.supplier,
            id: String(id),
          },
        });
      }}
      onEditContact={(contactId) => {
        onOpenMask().handler({
          entity: Entities.contact,
          id: String(contactId),
          template: {
            entity: Entities.supplier,
            id: String(id),
          },
        });
      }}
      {...props}
    />
  );
};
