import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { LetterTemplateType } from '../Enums/LetterTemplateType.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputWordLetterTemplate<T extends EMode = EMode.entity> {
  /** Alias: code */
  code?: number;
  /** Alias: templateFilename */
  templateFilename?: any;
  /** Alias: body */
  body?: any;
  /** Alias: title */
  title?: any;
  /** Alias: serialLetter */
  serialLetter?: boolean;
  /** Alias: templateType */
  templateType?: LetterTemplateType;
  /** Alias: groupCode */
  groupCode?: number;
  /** Alias: folder */
  folder?: any;
  /** Alias: documentSubClass */
  documentSubClass?: any;
  /** Alias: settings */
  settings?: any;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<InputWordLetterTemplate> = {
  code: {
    alias: 'code',
  },
  templateFilename: {
    alias: 'templateFilename',
  },
  body: {
    alias: 'body',
  },
  title: {
    alias: 'title',
  },
  serialLetter: {
    alias: 'serialLetter',
  },
  templateType: {
    alias: 'templateType',
  },
  groupCode: {
    alias: 'groupCode',
  },
  folder: {
    alias: 'folder',
  },
  documentSubClass: {
    alias: 'documentSubClass',
  },
  settings: {
    alias: 'settings',
  },
  customFields: {
    alias: 'customFields',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputWordLetterTemplateEntityDefinition: EntitiyDefinition<InputWordLetterTemplate> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputWordLetterTemplate',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
