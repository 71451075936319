import Typography from '@mui/material/Typography';
import { useMemo } from 'react';

import { CostCenter } from '@work4all/models/lib/Classes/CostCenter.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Tooltip } from '../../tooltip/Tooltip';
import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '../types';

export type ICostCenterProps<TMultiple extends boolean> = IPickerProps<
  CostCenter,
  TMultiple
>;

export function CostCenterPicker<TMultiple extends boolean>(
  props: ICostCenterProps<TMultiple>
) {
  const { data, ...rest } = props;

  const allFields = useMemo(() => {
    return { ...COSTCENTER_FIELDS, ...data };
  }, [data]);

  return (
    <ListEntityPicker
      entity={Entities.costCenter}
      data={allFields}
      filterBy={['name', 'number']}
      sortBy="name"
      {...rest}
      renderItemContent={(costCenter) => (
        <Tooltip title={costCenter.name}>
          <Typography variant="body2" noWrap>
            {costCenter?.number} | {costCenter?.name}
          </Typography>
        </Tooltip>
      )}
    />
  );
}

const COSTCENTER_FIELDS: CostCenter = {
  id: null,
  number: null,
  name: null,
};
