import { useCallback, useMemo, useRef } from 'react';

import { EntityEvent } from './entity-events';
import {
  EntityEventsContextProvider,
  EntityEventsContextValue,
} from './entity-events-context';

export interface EntityEventsProps {
  children?: React.ReactNode;
}

export function EntityEvents(props: EntityEventsProps) {
  const { children } = props;

  const subscribersRef = useRef<((event: EntityEvent) => void)[]>([]);

  const emit = useCallback((event: EntityEvent) => {
    for (const subscriber of subscribersRef.current) {
      subscriber(event);
    }
  }, []);

  const subscribe = useCallback((callback: (event: EntityEvent) => void) => {
    subscribersRef.current.push(callback);

    return () => {
      subscribersRef.current = subscribersRef.current.filter(
        (sub) => sub !== callback
      );
    };
  }, []);

  const value = useMemo<EntityEventsContextValue>(() => {
    return { emit, subscribe };
  }, [emit, subscribe]);

  return (
    <EntityEventsContextProvider value={value}>
      {children}
    </EntityEventsContextProvider>
  );
}
