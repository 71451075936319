import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { CheckboxRadioItem } from '@work4all/components/lib/input/checkbox-radio-item';

import { AppParts, useCanView, useHiddenEntities } from '@work4all/data';
import { useSetting } from '@work4all/data/lib/settings/use-setting';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { settings } from '../../../../../../../settings';
import { DEFAULT_RELEVANT_WIDGET_VIEW } from '../../../../../../../settings/settings';

export function Personal() {
  const { t } = useTranslation();
  const { isHidden } = useHiddenEntities();
  const canViewProjectTimeTracking = useCanView(AppParts.PROJECTTIMETRACKING);
  const canViewWorkTimeTracking = useCanView(AppParts.WORKTIMETRACKING);

  const relevantWidgetView = useSetting(settings.relevantWidgetVisibleViews());

  const handleCheck = (view: Entities, setVisible: boolean) => {
    if (setVisible) {
      relevantWidgetView.set([view, ...relevantWidgetView.value]);
    } else {
      relevantWidgetView.set(
        relevantWidgetView.value.filter((setting) => setting !== view)
      );
    }
  };

  const isSettingVisible = (setting: Entities) => {
    return relevantWidgetView.value.includes(setting);
  };

  const userEnabledShowingBirthdays = useSetting(
    settings.userEnabledShowingBirthdays()
  );

  const adminEnabledShowingBirthdays = useSetting(
    settings.adminEnabledShowingBirthdays()
  );

  return (
    <Collapse title={t('RELEVENT.SETTINGS.SHOW')} defaultOpen>
      {DEFAULT_RELEVANT_WIDGET_VIEW.filter((el) => {
        if (el === Entities.vacation) return canViewWorkTimeTracking;
        if (el === Entities.timeTracking) return canViewProjectTimeTracking;
        return !isHidden(el);
      }).map((entity) => {
        return (
          <CheckboxRadioItem
            key={entity}
            checked={isSettingVisible(entity)}
            onChange={(e) => {
              handleCheck(entity, e.target.checked);
            }}
            label={t(`COMMON.${entity.toUpperCase()}`)}
          />
        );
      })}

      {adminEnabledShowingBirthdays.value && (
        <CheckboxRadioItem
          checked={userEnabledShowingBirthdays.value}
          label={t('COMMON.BIRTHDAY_PLURAL')}
          onChange={(e) => {
            userEnabledShowingBirthdays.set(e.target.checked);
          }}
        />
      )}
    </Collapse>
  );
}
