import React from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  PostalCodePicker,
  PostalCodePickerProps,
} from '@work4all/components/lib/components/entity-picker/postal-code-picker/PostalCodePicker';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

interface PostalCodePickerFieldProps
  extends Omit<PostalCodePickerProps, 'multiple'> {
  error?: string;
  label?: string;
}

export const PostalCodePickerField = React.forwardRef<
  HTMLDivElement,
  PostalCodePickerFieldProps
>(function PostalCodePickerField(props, ref) {
  const { error, ...pickerProps } = props;
  const { label, value, onChange } = pickerProps;

  const { t } = useTranslation();

  return (
    <EntityPickerPopover picker={<PostalCodePicker {...pickerProps} />}>
      <PickerTargetButton
        ref={ref}
        value={value?.postalCode ?? ''}
        label={label || t('INPUTS.POSTAL_CODE')}
        error={error}
        onClear={() => {
          onChange(null);
        }}
      />
    </EntityPickerPopover>
  );
});
