import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { actions, Row, TableInstance } from 'react-table';

import { GROUP_ROW_MARKER } from '../components/row-render/components/grouped/Grouped';
import { EditModeTableInstance } from '../plugins/useEditMode';
import {
  SELECT_BOX_MARKER,
  SELECTION_COLUMN_ID,
} from '../utils/makeRowsSelectable';

import { ColumnVisibilityContext } from './useColumnVisibility';
import { ITableStateBag } from './useTableStateBag';

interface UseRowClickProps<T extends object> {
  tableInstance: TableInstance<T> & EditModeTableInstance;
  tableBodyRef: React.MutableRefObject<HTMLDivElement>;
  tableStateBag?: ITableStateBag;
  selectableRows?: boolean;
  selectableMultiple?: boolean;
  underPressSelect?: boolean;
  setUnderPressSelect?: (input: boolean) => void;
  onSelectedRowsChange?: (selectedRows: Row<T>[]) => void;
  forceSelection?: boolean;
  ctrlOnly?: boolean;
  isSelectable?: (row: Row<T>) => boolean;
}

export function useSelectionRowClick<
  T extends object = object,
  TRow extends Row<T> = Row<T>
>(props: UseRowClickProps<T>) {
  const {
    tableBodyRef,
    tableStateBag,
    tableInstance,
    selectableMultiple,
    underPressSelect,
    setUnderPressSelect,
    onSelectedRowsChange,
    ctrlOnly = false,
    isSelectable,
  } = props;

  const selectedFlatRows = tableInstance.selectedFlatRows;

  const [lastInteraction, setLastInteraction] = useState<{
    rowId: string;
    selected: boolean;
  } | null>(null);

  // Reset selected rows whenever the dataset changes.
  // We are using row indexes as row ids, so we need to reset selection
  // every time the order might change.
  // Otherwise there might be issues with selected rows changing unpredictably.
  useEffect(() => {
    tableInstance.dispatch({ type: actions.resetSelectedRows });
    setLastInteraction(null);
  }, [
    tableInstance,
    tableInstance.state.groupBy,
    tableInstance.state.filters,
    tableInstance.state.sortBy,
  ]);

  const onRowClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>, row: TRow) => {
      // Check if the click event happened inside the table or if it's coming from a portal.
      if (
        !(e.target instanceof Node && tableBodyRef.current?.contains(e.target))
      ) {
        // Event originated in a portal. Ignore.
        return;
      }
      const ctrlKey = e.ctrlKey || e.metaKey;
      if (props.selectableRows) {
        const element = e.target as Element;
        // If clicked on a group row (except the checkbox)
        if (element.closest(`[data-${GROUP_ROW_MARKER}=true]`)) {
          if (!element.closest(`[data-${SELECT_BOX_MARKER}=true]`)) return;
        }

        // When in single-selection mode, select only the clicked row.
        if (!selectableMultiple) {
          tableInstance.toggleAllRowsSelected(false);
          row.toggleRowSelected(true);
          setLastInteraction({ rowId: row.id, selected: true });
          return;
        }

        const shiftKey = e.shiftKey;
        const clickedCheckbox = element.closest(
          `[data-${SELECT_BOX_MARKER}=true]`
        );

        if (shiftKey) {
          if (ctrlKey && !lastInteraction) {
            return;
          }

          if (!ctrlKey) {
            tableInstance.toggleAllRowsSelected(false);
          }

          const start =
            lastInteraction !== null
              ? tableInstance.flatRows.findIndex(
                  (r) => r.id === lastInteraction.rowId
                )
              : 0;
          const end = tableInstance.flatRows.findIndex((r) => r.id === row.id);

          if (start === -1 || end === -1) return;

          const setSelected = !ctrlKey
            ? true
            : lastInteraction?.selected ?? false;

          for (let i = Math.min(start, end); i <= Math.max(start, end); i++) {
            if (isSelectable && !isSelectable(tableInstance.flatRows[i])) break;
            tableInstance.toggleRowSelected(
              tableInstance.flatRows[i].id,
              setSelected
            );
          }
        } else {
          let setSelected = !row.isSelected;

          if (!ctrlKey && !clickedCheckbox && !underPressSelect) {
            setSelected = true;
            if (ctrlOnly && row.isSelected === setSelected) return;
            tableInstance.toggleAllRowsSelected(false);
          }
          if (ctrlOnly) {
            if (row.isSelected === setSelected) return;
            if (!ctrlKey && row.isSelected) {
              return;
            }
          }
          row.toggleRowSelected(setSelected);
          setLastInteraction({ rowId: row.id, selected: setSelected });
        }
      }
    },
    [
      tableBodyRef,
      props.selectableRows,
      selectableMultiple,
      tableInstance,
      lastInteraction,
      isSelectable,
      underPressSelect,
      ctrlOnly,
    ]
  );

  const columnVisibilityContext = useContext(ColumnVisibilityContext);
  const toggleColumnVisibility =
    columnVisibilityContext?.toggleColumnVisibility;

  const tableStateBagRef = useRef<ITableStateBag>();
  tableStateBagRef.current = tableStateBag;

  const forceSelection =
    props.forceSelection === undefined
      ? !!tableStateBag?.initialState?.forceSelection
      : props.forceSelection;
  // This will fire right away after mount,
  // so technically it does not behave like "onChange".
  // If this is critical, we can add a check to skip the first change.
  // I don't think it matters at the moment.
  useEffect(() => {
    if (onSelectedRowsChange) {
      onSelectedRowsChange(selectedFlatRows);
    }

    if (selectedFlatRows.length === 0) {
      setUnderPressSelect?.(false);
    }

    const column = tableInstance.allColumns.find(
      (column) => column.id === SELECTION_COLUMN_ID
    );

    const groupBy = tableStateBagRef.current?.tableState?.groupBy;
    const isGrouped = groupBy != null && groupBy.length > 0;
    if (isGrouped) return;

    if (forceSelection) {
      if (!column?.isVisible)
        toggleColumnVisibility && toggleColumnVisibility(SELECTION_COLUMN_ID);
      return;
    } else if (
      (selectedFlatRows.length < 2 && column?.isVisible) ||
      (selectedFlatRows.length > 1 && !column?.isVisible)
    ) {
      toggleColumnVisibility && toggleColumnVisibility(SELECTION_COLUMN_ID);
    }
  }, [
    tableInstance.allColumns,
    onSelectedRowsChange,
    selectedFlatRows,
    setUnderPressSelect,
    toggleColumnVisibility,
    forceSelection,
  ]);

  return onRowClick;
}
