import styles from './PositionMask.module.scss';

import { ArrowBack, Close, Delete } from '@mui/icons-material';
import { Grid, IconButton, Theme, useMediaQuery } from '@mui/material';
import { clone, omit } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EndAdornment } from '@work4all/components/lib/input/labeled-input/components/end-adornment/EndAdornment';

import { useCustomFieldsConfig } from '@work4all/data/lib/custom-fields';

import { Currency } from '@work4all/models/lib/Classes/Currency.entity';
import { Position } from '@work4all/models/lib/Classes/Position.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

import { formatCurrency } from '@work4all/utils';

import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import { LabeledInput } from '../../../../../../../../locked-inputs';
import { Collapse } from '../../../../../../../components';
import { CustomFieldsWrapper } from '../../../../../../../components/custom-fields/CustomFieldsWrapper';
import { LockOverride } from '../../../../../../../components/LockOverride';
import { useMaskContext } from '../../../../../../../hooks/mask-context';
import { ErpData } from '../../../../../ErpData';
import { useEditTable } from '../edit-table/components/edit-table-provider/EditTableProvider';
import { EditTableEntry, EditTableMask } from '../edit-table/types';

export type IPositionMaskProps = {
  currency: Currency;
  editableCols: string[];
} & EditTableMask<Position & EditTableEntry>;

export function PositionMask(props: IPositionMaskProps) {
  const { item: position, onCloseClicked, editableCols = [] } = props;

  const { onRemove: onRemovePosition, onEdit: onEditPosition } = useEditTable();
  const { watch } = useFormContextPlus<ErpData>();
  const currency = watch('currency');

  const disabled = position?.cacheOnly;
  const [resultedPosition, setResultedPosition] = useState(position);

  const mask = useMaskContext();
  const hidePrices = mask.entity === Entities.deliveryNote;

  useEffect(() => {
    setResultedPosition(position);
  }, [position]);

  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('md')
  );
  const { t } = useTranslation();

  const onChange = useCallback(
    (props: { id: string; value: number | string | boolean }) => {
      const { id, value } = props;
      const newPosition = resultedPosition;
      const paths = id.split('.');
      if (paths.includes('customFieldList')) {
        const key = parseInt(paths[1]);
        const customFieldList = clone(newPosition.customFieldList);
        const customFieldIndex = customFieldList.findIndex(
          (x) => x.key === key
        );
        if (customFieldIndex === -1)
          customFieldList.push({
            key,
            value: JSON.stringify(value),
          });
        else customFieldList[customFieldIndex].value = JSON.stringify(value);
        setResultedPosition({ ...newPosition, customFieldList });
      } else {
        setResultedPosition({ ...newPosition, [id]: value });
      }
    },
    [resultedPosition]
  );

  const onBlur = useCallback(
    (props: { id: string; value: number | string | boolean }) => {
      const { id, value } = props;

      const newPosition = position;
      const paths = id.split('.');
      if (paths.includes('customFieldList')) {
        const key = parseInt(paths[1]);
        const customFieldList = newPosition.customFieldList.map((x) =>
          omit(x, '__typename')
        );
        const customFieldIndex = customFieldList.findIndex(
          (x) => x.key === key
        );
        if (customFieldIndex === -1)
          customFieldList.push({
            key,
            value: JSON.stringify(value),
          });
        else customFieldList[customFieldIndex].value = JSON.stringify(value);

        onEditPosition({
          position: {
            id: position.id,
            customFieldList,
          },
        });
      } else {
        onEditPosition({
          position: {
            id: position.id,
            [id]: value,
          },
        });
      }
    },
    [onEditPosition, position]
  );

  const customFields = useCustomFieldsConfig({ entity: Entities.position });

  if (!resultedPosition) {
    return null;
  }

  return (
    <LockOverride forceLock={disabled}>
      <div className={styles.outerWrap}>
        <div className={styles.header}>
          <IconButton onClick={onCloseClicked}>
            {isMobile ? <ArrowBack /> : <Close />}
          </IconButton>
          <div className={styles.titleContainer}>{t('COMMON.POSITION')}</div>
          <IconButton
            color="primary"
            onClick={(e) => {
              onRemovePosition([position?.id]);
              onCloseClicked(e);
            }}
          >
            <Delete />
          </IconButton>
        </div>
        <div className={styles.scrollContainer}>
          <Collapse
            defaultOpen={true}
            title={t('COMMON.DESCRIPTION')}
            className={styles.topSection}
          >
            <LabeledInput
              value={resultedPosition?.longtext}
              multiline={true}
              style={{ minHeight: '12rem' }}
              disabled={!editableCols.includes('longtext')}
              onBlur={(e) => {
                onBlur({ id: 'longtext', value: e.target.value });
              }}
              onChange={(e) => {
                onChange({ id: 'longtext', value: e.target.value });
              }}
              label={t('FIELDS.longtext')}
            />
          </Collapse>
          {[
            ErpPositionsKind.STANDARD,
            ErpPositionsKind.STUECKLISTE,
            ErpPositionsKind.TEXTZEILE,
          ].includes(position?.positionKind) && (
            <>
              <Collapse
                defaultOpen={true}
                title={`${t('COMMON.ERP.CALCULATION')} / ${t(
                  'COMMON.ERP.PRICING'
                )}`}
              >
                <Grid container spacing="1rem">
                  <Grid item xs={4}>
                    <LabeledInput
                      value={resultedPosition?.amount}
                      type="number"
                      disabled={!editableCols.includes('amount')}
                      onBlur={(e) => {
                        onBlur({
                          id: 'amount',
                          value: parseFloat(e.target.value),
                        });
                      }}
                      onChange={(e) => {
                        onChange({
                          id: 'amount',
                          value: parseFloat(e.target.value),
                        });
                      }}
                      label={t('FIELDS.amount')}
                      selectOnFocus
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <LabeledInput
                      disabled={!editableCols.includes('unit')}
                      value={resultedPosition?.unit}
                      type="text"
                      onBlur={(e) => {
                        onBlur({ id: 'unit', value: e.target.value });
                      }}
                      onChange={(e) => {
                        onChange({ id: 'unit', value: e.target.value });
                      }}
                      label={t('FIELDS.unit')}
                      selectOnFocus
                    />
                  </Grid>
                  {!hidePrices && (
                    <>
                      <Grid item xs={4}>
                        <LabeledInput
                          value={resultedPosition?.discount}
                          disabled={!editableCols.includes('discount')}
                          type="number"
                          onBlur={(e) => {
                            onBlur({
                              id: 'discount',
                              value: parseFloat(e.target.value),
                            });
                          }}
                          onChange={(e) => {
                            onChange({
                              id: 'discount',
                              value: parseFloat(e.target.value),
                            });
                          }}
                          label={t('FIELDS.discount')}
                          endAdornment={<EndAdornment>%</EndAdornment>}
                          selectOnFocus
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <LabeledInput
                          value={resultedPosition?.singlePriceNet}
                          disabled={!editableCols.includes('singlePriceNet')}
                          type="number"
                          onBlur={(e) => {
                            onBlur({
                              id: 'singlePriceNet',
                              value: parseFloat(e.target.value),
                            });
                          }}
                          onChange={(e) => {
                            onChange({
                              id: 'singlePriceNet',
                              value: parseFloat(e.target.value),
                            });
                          }}
                          label={t('FIELDS.singlePriceNet')}
                          endAdornment={
                            <EndAdornment>
                              {formatCurrency(currency, t)}
                            </EndAdornment>
                          }
                          selectOnFocus
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <LabeledInput
                          value={resultedPosition?.totalPriceNet}
                          type="number"
                          disabled={!editableCols.includes('totalPriceNet')}
                          label={t('FIELDS.totalPriceNet')}
                          endAdornment={
                            <EndAdornment>
                              {formatCurrency(currency, t)}
                            </EndAdornment>
                          }
                          selectOnFocus
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <LabeledInput
                          value={resultedPosition?.insteadOfTotalPrice}
                          type="text"
                          disabled={
                            !editableCols.includes('insteadOfTotalPrice')
                          }
                          onBlur={(e) => {
                            onBlur({
                              id: 'insteadOfTotalPrice',
                              value: e.target.value,
                            });
                          }}
                          onChange={(e) => {
                            onChange({
                              id: 'insteadOfTotalPrice',
                              value: e.target.value,
                            });
                          }}
                          label={t('FIELDS.insteadOfTotalPrice')}
                          selectOnFocus
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Collapse>

              <CustomFieldsWrapper
                entity={resultedPosition}
                customFields={customFields}
                onChange={onChange}
                onBlur={onBlur}
              />
            </>
          )}
        </div>
      </div>
    </LockOverride>
  );
}
