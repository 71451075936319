import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  BaseDataLanguagePicker,
  IBaseDataLanguagePickerProps,
} from '@work4all/components/lib/components/entity-picker/base-data-language-picker/BaseDataLanguagePicker';
import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

interface Props extends Omit<IBaseDataLanguagePickerProps<false>, 'multiple'> {
  error?: string;
}

export const BaseDataLanguagePickerField: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const { error, ...pickerProps } = props;
  const { value, onChange } = pickerProps;

  return (
    <EntityPickerPopover
      picker={<BaseDataLanguagePicker {...pickerProps} multiple={false} />}
    >
      <PickerTargetButton
        onClear={() => {
          onChange(null);
        }}
        label={t('COMMON.LANGUAGE')}
        error={error}
        value={value?.name || ''}
      />
    </EntityPickerPopover>
  );
};
