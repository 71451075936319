import React from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  EMailTemplateGroupPicker,
  IEMailTemplateGroupProps,
} from '@work4all/components/lib/components/entity-picker/email-template-group-picker/EmailTemplateGroupPicker';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

interface EMailTemplateGroupPickerFieldProps
  extends Omit<IEMailTemplateGroupProps<false>, 'multiple'> {
  error?: string;
  disabled?: boolean;
  excludeTerminatedEMailTemplateGroups?: boolean;
}

export const EMailTemplateGroupPickerField = React.forwardRef<
  HTMLDivElement,
  EMailTemplateGroupPickerFieldProps
>(function EMailTemplateGroupPickerField(props, ref) {
  const { error, disabled, ...pickerProps } = props;
  const { value, onChange } = pickerProps;

  const { t } = useTranslation();

  return (
    <EntityPickerPopover
      picker={<EMailTemplateGroupPicker {...pickerProps} multiple={false} />}
    >
      <PickerTargetButton
        ref={ref}
        disabled={disabled}
        value={value ? value?.name : ''}
        label={t('COMMON.GROUP')}
        error={error}
        onClear={() => {
          onChange(null);
        }}
      />
    </EntityPickerPopover>
  );
});
