import { CircularProgress as MuiCircularProgress } from '@mui/material';

interface Props {
  isLoading: boolean;
  isActive: boolean;
}

export const CircularProgress = ({ isLoading, isActive }: Props) => {
  if (isLoading && isActive) {
    return <MuiCircularProgress size="1rem" color="secondary" />;
  }

  return null;
};
