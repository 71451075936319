import styles from './BaseActionButton.module.scss';

import { ButtonBase } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import { ITypographyProps } from '../../typography';
import { Body1 } from '../../typography/body1/Body1';
import { Body2 } from '../../typography/body2/Body2';
import { ButtonText } from '../../typography/button-text/ButtonText';

import { getLinkData } from './utils';

export type IBaseActionButtonProps = {
  children?: React.ReactNode;
  icon?: JSX.Element;
  title?: string;
  className?: string;
  color?: 'primary' | 'error' | 'text01' | 'text03' | 'textInverse';
  component?: 'body1' | 'body2' | 'buttonText';
  alignment?: 'left' | 'center' | 'right';
  horizontalPadding?: 'xs' | 's' | false;
  disabled?: boolean;
  classes?: {
    title?: string;
  };
  autoFocus?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
  href?: string;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  rootRef?:
    | React.MutableRefObject<HTMLButtonElement>
    | React.MutableRefObject<HTMLAnchorElement>;
  disableReason?: string;
};

export const BaseActionButton: React.FC<IBaseActionButtonProps> = (props) => {
  const {
    icon,
    title,
    className,
    color = 'primary',
    alignment,
    disabled,
    href,
    horizontalPadding = 'xs',
    component = 'body1',
    classes,
    children,
    type,
    onClick,
    autoFocus = false,
    rootRef,
  } = props;

  let TitleComponent: React.FC<ITypographyProps>;
  if (component === 'body1') {
    TitleComponent = Body1;
  } else if (component === 'body2') {
    TitleComponent = Body2;
  } else {
    TitleComponent = ButtonText;
  }

  const { isLink, linkProps } = getLinkData(href);
  if (isLink) {
    const btnProps = {
      classname: clsx(
        styles.action,
        styles[color],
        {
          [styles[alignment]]: alignment,
          [styles[`horizontalPadding--${horizontalPadding}`]]:
            horizontalPadding,
        },
        styles.verticalPadding,
        className
      ),
      disabled,
      onClick,
      autoFocus,
      ...linkProps,
    };

    if (linkProps.href)
      return (
        <ButtonBase
          ref={rootRef as React.MutableRefObject<HTMLAnchorElement>}
          href={linkProps.href}
          {...btnProps}
        />
      );

    return (
      <ButtonBase
        ref={rootRef as React.MutableRefObject<HTMLButtonElement>}
        {...btnProps}
      >
        {icon && <div className={styles.iconWrapper}>{icon}</div>}
        <TitleComponent className={clsx(styles.title, classes?.title)}>
          {title}
        </TitleComponent>
        {children}
      </ButtonBase>
    );
  }

  return (
    <ButtonBase
      ref={rootRef as React.MutableRefObject<HTMLButtonElement>}
      disabled={disabled}
      type={type}
      className={clsx(
        styles.action,
        styles[color],
        {
          [styles[alignment]]: alignment,
          [styles[`horizontalPadding--${horizontalPadding}`]]:
            horizontalPadding,
        },
        styles.verticalPadding,
        className
      )}
      onClick={onClick}
      autoFocus={autoFocus}
    >
      {icon && <div className={styles.iconWrapper}>{icon}</div>}
      {title && (
        <TitleComponent className={clsx(styles.title, classes?.title)}>
          {title}
        </TitleComponent>
      )}
      {children}
    </ButtonBase>
  );
};
