import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';

import { PickerTargetButton } from '../../../../../../../../locked-inputs';

import { BomVariantPicker, IBomVariantPickerProps } from './BomVariantPicker';

interface BomVariantPickerFieldProps
  extends Omit<IBomVariantPickerProps<false>, 'multiple'> {
  error?: string;
  name?: string;
}
export const BomVariantPickerField = React.forwardRef<
  HTMLDivElement,
  BomVariantPickerFieldProps
>(function BomVariantPickerField(props, ref) {
  const { error, ...pickerProps } = props;
  const { value } = pickerProps;
  const { t } = useTranslation();
  const popoverRef = useRef<EntityPickerPopover>(null);

  return (
    <EntityPickerPopover
      ref={popoverRef}
      picker={<BomVariantPicker {...pickerProps} multiple={false} />}
    >
      <PickerTargetButton
        ref={ref}
        value={value ? value?.name : ''}
        name={props.name}
        label={t('FIELDS.BILL_OF_MATERIALS')}
        error={error}
      />
    </EntityPickerPopover>
  );
});
