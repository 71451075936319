import styles from './PickerTargetButton.module.scss';

import {
  Button,
  ButtonBase,
  FormControl,
  InputLabel,
  Link,
} from '@mui/material';
import clsx from 'clsx';
import React, {
  CSSProperties,
  FocusEventHandler,
  isValidElement,
  MouseEventHandler,
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { ReactComponent as ClearIcon } from '@work4all/assets/icons/outline-close-24-2.svg';

import { TestDataAttributes } from '@work4all/utils';
import { reactRefSetter } from '@work4all/utils/lib/reactRefSetter';
import { withStopPropogation } from '@work4all/utils/lib/withStopPropogation';

import { Caption } from '../../typography/caption/Caption';
import { Required } from '../labeled-input/components/required/Required';
import { Action } from '../multi-step-search/types';

export interface PickerTargetButtonProps extends TestDataAttributes {
  children?: React.ReactNode;
  label?: string | JSX.Element;
  value?: string | number | JSX.Element;
  required?: boolean;
  name?: string;
  onBlur?: FocusEventHandler<HTMLButtonElement>;
  onFocus?: React.FocusEventHandler<HTMLButtonElement>;
  onClear?: (buttonRef: MutableRefObject<HTMLButtonElement>) => void;
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLDivElement>;
  startAdornment?: React.ReactNode;
  iconRight?: JSX.Element;
  placeholder?: string;
  className?: string;
  buttonClassName?: string;
  style?: CSSProperties;
  actions?: Action[];
  error?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  selectOnFocus?: boolean;
  multiline?: boolean;
}

export const PickerTargetButton = React.forwardRef<
  HTMLButtonElement | HTMLDivElement,
  PickerTargetButtonProps
>(function PickerTargetButton(props, ref) {
  const { value, error } = props;

  const buttonRef = useRef<HTMLButtonElement>();
  const [forceFocused, setForcedFocused] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const isFocusedStart =
      value && value !== ''
        ? true
        : buttonRef.current?.value !== ''
        ? true
        : false;
    setForcedFocused(isFocusedStart);
  });

  const commonClassNames = clsx(styles.fontStyles, styles['text01'], {
    [styles.noMargin]: !props.label,
  });

  const refSetter = useMemo(
    () => reactRefSetter(buttonRef, ref),
    [ref, buttonRef]
  );

  return (
    <FormControl
      ref={refSetter}
      data-test-id={props['data-test-id']}
      focused={forceFocused}
      disabled={props.disabled}
      className={clsx(
        styles.wrapper,
        props.className,
        props.style,
        {
          [styles.disabled]: props.disabled,
        },
        {
          [styles.error]: !!error,
        },
        {
          [styles.focused]: isFocused,
        }
      )}
    >
      {error && (
        <div className={styles.errorMessage}>
          <Caption color="error">{error}</Caption>
        </div>
      )}

      <InputLabel
        className={clsx(styles.label, {
          [styles.labelInvis]: !props.label,
        })}
        classes={{
          shrink: styles['label--shrink'],
        }}
        shrink={value && value !== '' ? true : false}
      >
        {props.label} {props.required && <Required />}
      </InputLabel>

      <Button
        className={clsx(styles.button, commonClassNames, {
          [styles.placeholderButton]: !props.label && !value,
          [styles.multiline]: props.multiline,
        })}
        name={props.name}
        disabled={props.disabled}
        disableElevation
        disableRipple
        disableFocusRipple
        autoFocus={props?.autoFocus}
        onClick={props.onClick}
        onFocus={(e) => {
          if (props.onFocus) {
            props.onFocus(e);
          }
          if (props.selectOnFocus) {
            e.target.focus();
          }
          setIsFocused(true);
        }}
        onBlur={(e) => {
          props.onBlur?.(e);
          const value = e.currentTarget.children;
          if (!value) {
            setForcedFocused(false);
          }
          setIsFocused(false);
        }}
      >
        <div
          className={clsx(props.buttonClassName, {
            [styles.buttonLeftContent]: !isValidElement(value),
            [styles.buttonLeftContentOverlapped]: isValidElement(value),
            [styles.placeholderContent]: !props.label && !value,
          })}
        >
          {value && props.startAdornment && (
            <div className={styles.startAdornment}>{props.startAdornment}</div>
          )}

          <span
            data-test-id="picker-target-button-value"
            className={clsx({
              [styles.buttonValue]: !isValidElement(value),
              [styles.placeholder]: !value,
            })}
          >
            {value ?? props.placeholder}
          </span>

          {props.children && (
            <div onClick={withStopPropogation()}>{props.children}</div>
          )}
        </div>

        <div className={styles.buttonRightContent}>
          {(props?.actions || props.iconRight || props.onClear) && (
            <div className={styles.actionsWrapper}>
              {props?.actions || (props?.onClear && props.value) ? (
                <>
                  {!props.disabled &&
                    props?.actions?.map(({ href, icon, onClick }, idx) => (
                      <Link
                        key={idx}
                        onClick={withStopPropogation(() => {
                          if (onClick) onClick();
                        })}
                        color="inherit"
                        href={href}
                        className={styles.actionBtn}
                        underline="hover"
                      >
                        {icon}
                      </Link>
                    ))}

                  {props.onClear ? (
                    <ButtonBase
                      disabled={props.disabled}
                      className={styles.close}
                      onClick={withStopPropogation(() =>
                        props.onClear(buttonRef)
                      )}
                    >
                      <ClearIcon />
                    </ButtonBase>
                  ) : null}
                </>
              ) : (
                props.iconRight
              )}
            </div>
          )}
        </div>
      </Button>
    </FormControl>
  );
});
