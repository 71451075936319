import { Box, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { compact } from 'lodash';
import { PropsWithChildren, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { FileData } from '@work4all/models/lib/File';

import { SearchResultItem } from '../../input/search-result-item/SearchResultItem';

import { prepareSuggestionEls } from './prepareSuggestionEls';

interface Props {
  customer?: Customer<EMode.entity>[];
  customerContact?: Contact<EMode.entity>[];
  supplier?: Supplier<EMode.entity>[];
  supplierContact?: Contact<EMode.entity>[];
  project?: Project<EMode.entity>[];
  onSelected: (el: FileData, type: Entities) => void;
  pathMapping: Partial<Record<Entities, string>>;
  defaultCustomer?: FileData[];
  defaultProject?: FileData[];
  defaultSupplier?: FileData[];
  showDefaultResults: boolean;
}

export const ResultList: React.FC<Props> = ({
  customer,
  customerContact = [],
  supplier,
  supplierContact = [],
  project,
  onSelected,
  pathMapping,
  defaultCustomer = [],
  defaultProject = [],
  defaultSupplier = [],
  showDefaultResults,
}) => {
  const { t } = useTranslation();
  const showTitles =
    [customer, supplier, project].filter((el) => el !== undefined).length > 1 ||
    [defaultCustomer, defaultProject, defaultSupplier].filter(
      (el) => el.length > 0
    ).length > 1;
  const customerList = useMemo(() => {
    const cleandEntityList = compact(customer);
    const cleanedContactList = compact(customerContact);
    return prepareSuggestionEls({
      businessPartnerList: cleandEntityList,
      relatedContactsList: cleanedContactList,
      pathname: pathMapping[Entities.customer],
      elementSelected: (data) => onSelected(data, Entities.customer),
    });
  }, [customer, customerContact, onSelected, pathMapping]);

  const supplierList = useMemo(() => {
    const cleandEntityList = compact(supplier);
    const cleanedContactList = compact(supplierContact);
    return prepareSuggestionEls({
      businessPartnerList: cleandEntityList,
      relatedContactsList: cleanedContactList,
      pathname: pathMapping[Entities.supplier],
      elementSelected: (data) => onSelected(data, Entities.supplier),
    });
  }, [onSelected, pathMapping, supplier, supplierContact]);

  const projectList = useMemo(() => {
    const cleandEntityList = compact(project);
    return cleandEntityList.map((item, index) =>
      scopeResultItem(
        {
          id: item.id + '',
          number: item.number,
          name: item.name,
        },
        pathMapping[Entities.project],
        (data) => onSelected(data, Entities.project),
        index
      )
    );
  }, [onSelected, pathMapping, project]);

  const defaultLastUsed = useMemo(() => {
    return {
      [Entities.customer]: defaultCustomer.map((el, index) =>
        scopeResultItem(
          el,
          pathMapping[Entities.customer],
          (data) => onSelected(data, Entities.customer),
          index
        )
      ),
      [Entities.supplier]: defaultSupplier.map((el, index) =>
        scopeResultItem(
          el,
          pathMapping[Entities.supplier],
          (data) => onSelected(data, Entities.supplier),
          index
        )
      ),

      [Entities.project]: defaultProject.map((el, index) =>
        scopeResultItem(
          el,
          pathMapping[Entities.project],
          (data) => onSelected(data, Entities.project),
          index
        )
      ),
    };
  }, []);

  const showCustomersStack =
    (customerList.length > 0 && !showDefaultResults) ||
    (defaultLastUsed[Entities.customer].length > 0 && showDefaultResults);

  const showSuppliersStack =
    (supplierList.length > 0 && !showDefaultResults) ||
    (defaultLastUsed[Entities.supplier].length > 0 && showDefaultResults);

  const showProjectsStack =
    (projectList.length > 0 && !showDefaultResults) ||
    (defaultLastUsed[Entities.project].length > 0 && showDefaultResults);

  return (
    <Stack direction="column" alignItems={'stretch'}>
      <Box sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {showCustomersStack && (
          <Stack direction="column">
            {showTitles && (
              <Title>
                {t('COMMON.CUSTOMER', { count: customerList.length })}
              </Title>
            )}
            {showDefaultResults
              ? defaultLastUsed[Entities.customer]
              : customerList}
          </Stack>
        )}
        {showSuppliersStack && (
          <Stack direction="column">
            {showTitles && (
              <Title>
                {t('COMMON.SUPPLIER', { count: supplierList.length })}
              </Title>
            )}
            {showDefaultResults
              ? defaultLastUsed[Entities.supplier]
              : supplierList}
          </Stack>
        )}
        {showProjectsStack && (
          <Stack direction="column">
            {showTitles && (
              <Title>
                {t('COMMON.PROJECT', { count: projectList.length })}
              </Title>
            )}
            {showDefaultResults
              ? defaultLastUsed[Entities.project]
              : projectList}
          </Stack>
        )}
      </Box>
    </Stack>
  );
};

const scopeResultItem = (
  item: FileData,
  path: string,
  onSelected: (data: FileData) => void,
  index: number
) => {
  return (
    <SearchResultItem
      key={`${item.id}-${index}`}
      id={item.id?.toString()}
      number={item.number?.toString()}
      href={`${path}/${item.id}`}
      title={item.name || ''}
      onClick={() =>
        onSelected({
          id: item.id + '',
          number: item.number,
          name: item.name,
        })
      }
    />
  );
};

const Title: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const theme = useTheme();
  return (
    <Stack
      direction={'row'}
      alignItems="center"
      gap=".25rem"
      padding={'.5rem 1rem'}
    >
      <Typography variant="caption">{children}</Typography>
      <Box
        flex="1"
        sx={{ backgroundColor: theme.palette.ui3.main, height: '1px' }}
      ></Box>
    </Stack>
  );
};
