import { useTranslation } from 'react-i18next';

import { ReDueDateClassified } from '@work4all/models/lib/Enums/ReDueDateClassified.enum';

type Props = {
  value: ReDueDateClassified;
};

export const DueDateClassifiedCell = ({ value }: Props) => {
  const { t } = useTranslation();

  if (value === ReDueDateClassified.NULL) return null;
  return t(`DUE_DATE_CLASSIFIEDS.${value}`);
};
