import { isEqual } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Path, PathValue, UseFormReturn } from 'react-hook-form';

type Updater<T, F extends Path<T>> = (
  value: PathValue<T, F>,
  prevValue: PathValue<T, F>
) => Partial<T>;

type FormUpdateConfig<T> = { [K in Path<T>]?: Updater<T, K> };
/**
 * Use this hook to run some update logic whenever some of the form values
 * change. Like if you need to keep some form values in sync and update them
 * whenever the related field is changed.
 *
 * @param config Object that maps field name to updater functions.
 * The object should be of shape `{ 'form-field-name': updaterFunciton }`.
 *
 * If you return an object from the updater function, it will be used to update
 * form values.
 * If you wan't to run you update logic manually, you can return `null` to
 * disable the default behavior.
 * @param form The object returned from the `useForm` hook.
 * @param deps Here you should provide a list of dependencies that cause the
 * form value to change. This list will be used in `useEffect` to ignore the
 * next form value change. This way your updater functions don't run as a result
 * of resetting form value after, for example, submitting a form.
 */
export function useFormUpdate<T>(
  config: FormUpdateConfig<T>,
  form: UseFormReturn<T>
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  type TValues = any;

  const [initialConfig] = useState(config);

  const savedValuesRef = useRef<TValues>(null);

  useEffect(() => {
    const subscription = form.watch((_value, info) => {
      const savedValues = savedValuesRef.current;
      const newValues: TValues = {};

      // Get all new form values.
      Object.keys(initialConfig).forEach((name: Path<T>) => {
        const value = form.getValues(name);
        newValues[name] = value;
      });

      // Update saved form values.
      savedValuesRef.current = newValues;

      // Updates caused by calling `form.reset` will have an empty `name`
      // property. These should be skipped.
      if (info.name === undefined) return;

      const updater = initialConfig[info.name];

      if (!updater) return;

      const savedValue = savedValues?.[info.name];
      const newValue = newValues[info.name];

      if (isEqual(savedValue, newValue)) return;

      const changes = updater(newValue, savedValue);

      if (changes != null) {
        // Apply all changes.
        Object.entries(changes).forEach(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ([name, value]: [Path<T>, any]) => {
            form.setValue(name, value, {
              shouldDirty: true,
              shouldValidate: true,
            });
          }
        );
      }

      return () => {
        subscription.unsubscribe();
      };
    });
  }, [initialConfig, form]);
}
