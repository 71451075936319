import { cloneDeep, isEqual, omit } from 'lodash';
import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { uuid } from 'short-uuid';

import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

import { EditTableEntry, IdType, OnEditPosition } from '../types';

import { arrayMove } from './arrayMove';

interface EditableActions<T extends EditTableEntry<TId>, TId, TContext> {
  onAddPosition: (context?: TContext) => void;
  onRemovePosition: (positionId: TId[]) => void;
  onMovePosition: (positionId: TId, index: number, localIndex?: number) => void;
  onEditPosition: (result: OnEditPosition<T>) => void;
}

export interface UseEditableStateProps<
  T extends EditTableEntry<TId>,
  TId,
  TContext
> extends EditableActions<T, TId, TContext> {
  positions: T[];
  mapAddLocal?: (context: TContext) => T;
  mapRemoveLocal?: (positionId: TId[]) => TId[];
  mutateState?: (inputs: T[]) => EditTableResultEntry<T>[];
  canAddRemote?: (context: TContext) => boolean;
}

export interface UseEditableStateResult<
  T extends EditTableEntry<TId>,
  TId,
  TContext
> extends UseEditableStateProps<T, TId, TContext> {
  onCollapsePosition: (position: T) => void;
  allPositions: T[];
}

interface AddAction<T extends EditTableEntry<TId>, TId> {
  type: 'add';
  newEntry: T;
  index?: number;
}

interface RemoveAction<TId> {
  type: 'remove';
  positionId: TId[];
}

interface MergeAction<T extends EditTableEntry<TId>, TId> {
  type: 'merge';
  positions: T[];
}

interface EditAction<T extends EditTableEntry<TId>, TId> {
  type: 'edit';
  result: OnEditPosition<T>;
}

interface MoveAction<TId> {
  type: 'move';
  positionId: TId;
  index: number;
}

type PositionStateAction<T extends EditTableEntry<TId>, TId> =
  | AddAction<T, TId>
  | RemoveAction<TId>
  | MergeAction<T, TId>
  | EditAction<T, TId>
  | MoveAction<TId>;

const createDataFetchReducer =
  <T extends EditTableEntry<TId>, TId>(
    mutateState: UseEditableStateProps<T, unknown, unknown>['mutateState']
  ) =>
  (
    state: EditTableResultEntry<T>[],
    action: PositionStateAction<T, TId>
  ): EditTableResultEntry<T>[] => {
    let newState: EditTableResultEntry<T>[] = [];
    switch (action.type) {
      case 'add': {
        // For now we support only single cache row
        const cacheOnlyIndex = state.findIndex((x) => x.cacheOnly);
        const oldState = [...state].filter((x) => !x.cacheOnly);
        newState = [...oldState, action.newEntry];

        let to = action.index;
        if (to !== undefined) {
          const from = newState.length - 1;
          // if we filtered out cache row we need to move index
          if (cacheOnlyIndex > 0 && cacheOnlyIndex < to) to--;
          const p = [...newState];
          arrayMove(p, from, to);
          newState = p;
        }
        break;
      }
      case 'move': {
        const { index: to, positionId } = action;
        const from = state.findIndex((x) => x.id === positionId);
        const p = [...state];
        arrayMove(p, from, to);
        newState = p;
        break;
      }
      case 'merge': {
        const finalState: T[] = [];
        const serverState = action.positions || [];
        serverState.forEach((pos, idx) => {
          const clonedPos = cloneDeep(pos);
          const localPos = state.find((x) => x.localId === pos.localId);

          // only if no local state there could be undefined
          if (
            state.length &&
            localPos?.dirtyFields &&
            Object.entries(localPos.dirtyFields).length
          ) {
            Object.entries(localPos.dirtyFields).forEach(
              (x) => (clonedPos[x[0]] = x[1])
            );
          }

          if (!clonedPos.localId) clonedPos.localId = `${clonedPos.id}`;

          clonedPos.index = idx;
          finalState.push(clonedPos);
        });

        newState = finalState;
        break;
      }
      case 'remove':
        newState = state.filter((x) => !action.positionId.includes(x.id));
        break;
      case 'edit': {
        const { position } = action.result;

        newState = state.map((pos) => {
          delete pos.dirtyFields;
          if (pos.id !== position.id) return pos;
          const val = cloneDeep(pos);

          Object.entries(position).forEach((x) => {
            const [field, value] = x;
            val[field] = value;
          });

          return val;
        });
        break;
      }
      default:
        newState = state;
    }
    return mutateState ? mutateState(newState) : newState;
  };

export type EditTableResultEntry<T> = T & {
  relation?: 'parent' | 'child' | 'none';
  collapsed?: boolean;
};

interface BaseStateContext {
  index?: number;
  localId?: string;
}

export type EditStateContext = Record<string, unknown> & BaseStateContext;
export function useEditableState<
  T extends EditTableEntry<TId>,
  TId = IdType,
  TContext extends BaseStateContext = EditStateContext
>(
  props: UseEditableStateProps<T, TId, TContext>
): UseEditableStateResult<T, TId, TContext> {
  const {
    positions,
    onAddPosition: onAddPositionOnServer,
    onRemovePosition: onRemovePositionOnServer,
    onEditPosition: onEditPositionOnServer,
    onMovePosition: onMovePositionOnServer,
    mapAddLocal,
    mapRemoveLocal,
    mutateState,
    canAddRemote,
  } = props;

  const reducer = createDataFetchReducer<T, TId>(mutateState);
  const [state, dispatch] = useReducer(reducer, []);

  const onRemovePosition = useCallback(
    (positionId: TId[]) => {
      if (mapRemoveLocal)
        dispatch({ type: 'remove', positionId: mapRemoveLocal(positionId) });
      else dispatch({ type: 'remove', positionId });
      if (positionId.filter(Boolean).length)
        onRemovePositionOnServer(positionId);
    },
    [onRemovePositionOnServer, mapRemoveLocal]
  );

  const delayedUpdates = useRef<T[]>([]);
  useEffect(() => {
    const pendingChanges = delayedUpdates.current
      .map((change) => {
        const persisted = positions.find(
          (y) => y.localId === change.localId && y.id
        );
        return {
          persisted,
          change,
        };
      })
      .filter((x) => x.persisted);
    pendingChanges.forEach(({ persisted, change }) => {
      const position = {
        position: { ...omit(change, 'localId'), id: persisted.id } as T,
      };
      onEditPositionOnServer(position);
      delayedUpdates.current = delayedUpdates.current.filter(
        (x) => x.localId !== change.localId
      );
    });
  }, [positions, onEditPositionOnServer, state]);

  const onEditPosition = useCallback(
    (result: OnEditPosition<T>) => {
      const entry = positions.find(
        (x) =>
          x.localId === result.position.localId ||
          (result.position?.id && x.id === result.position?.id)
      );
      if (!entry?.id) {
        delayedUpdates.current.push(result.position);
        return;
      }
      const finalChange = Object.entries(result.position).filter((x) => {
        const [field, value] = x;
        if (typeof value === 'object') return true;
        return !isEqual(entry[field], value);
      });

      const position: T = finalChange.reduce((prev, [field, value]) => {
        prev[field] = value;
        return prev;
      }, {} as T);
      position.id = entry.id;

      if (!finalChange.length) {
        return;
      }
      dispatch({ type: 'edit', result: { position } });
      onEditPositionOnServer({ position: omit(position, 'localId') as T });
    },
    [onEditPositionOnServer, positions]
  );

  const lastAdded = useRef<string>();
  const onAddPosition = useCallback(
    function (result: TContext) {
      if (mapAddLocal) {
        result.localId = uuid();
        lastAdded.current = result.localId;
        dispatch({
          type: 'add',
          newEntry: mapAddLocal(result),
          index: result.index,
        });
      }

      if (canAddRemote?.(result) ?? true) onAddPositionOnServer(result);
    },
    [onAddPositionOnServer, mapAddLocal, canAddRemote]
  );

  const onMovePosition = useCallback(
    function (positionId: TId, index: number, localIndex?: number) {
      dispatch({ type: 'move', positionId, index: localIndex ?? index });
      onMovePositionOnServer(positionId, index);
    },
    [onMovePositionOnServer]
  );

  useEffect(() => {
    dispatch({ type: 'merge', positions });
  }, [positions]);

  const collapsedHeads = useRef<T[]>(
    positions.filter((x) => x.positionKind === ErpPositionsKind.STUECKLISTE)
  );

  const [computedCollapsedHeads, setCollapsedHeads] = useState<T[]>(
    positions.filter((x) => x.positionKind === ErpPositionsKind.STUECKLISTE)
  );
  const onCollapsePosition = useCallback((position: T) => {
    const prev = collapsedHeads.current;

    const exist = prev.find((x) => x.id === position.id);
    if (exist)
      collapsedHeads.current = prev.filter((x) => x.id !== position.id);
    else collapsedHeads.current = [...prev, position];
    setCollapsedHeads(collapsedHeads.current);
  }, []);

  const resultState = useMemo(() => {
    // newly added bom need to be collapsed
    const bomAdded = state
      .filter((x) => x.positionKind === ErpPositionsKind.STUECKLISTE)
      .find((x) => x.localId === lastAdded.current);

    if (bomAdded && bomAdded.id) {
      lastAdded.current = '';
      collapsedHeads.current.push(bomAdded);
    }
    const headsIds = collapsedHeads.current.map((x) => x.id);
    return state
      .filter((x) => !headsIds.includes(x.posId))
      .map((x) => {
        if (
          headsIds.includes(x.id) ||
          (x.localId === lastAdded.current && bomAdded)
        )
          return { ...x, collapsed: true };
        return x;
      });
    // we need to enforce this by setting collapse heads, but we need to modify alsow without rerender when new item is added
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, computedCollapsedHeads]);

  return useMemo(
    () => ({
      positions: resultState,
      allPositions: state,
      onAddPosition,
      onRemovePosition,
      onMovePosition,
      onEditPosition,
      onCollapsePosition,
    }),
    [
      onAddPosition,
      onCollapsePosition,
      onEditPosition,
      onMovePosition,
      onRemovePosition,
      resultState,
      state,
    ]
  );
}
