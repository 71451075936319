import styles from './TableRow.module.scss';

import clsx from 'clsx';
import produce from 'immer';
import React, { forwardRef, useEffect } from 'react';
import { ReactNode, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { TableCommonProps } from 'react-table';

import { remToPx } from '@work4all/data/lib/hooks/useRemToPx';

import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

import { reactRefSetter } from '@work4all/utils';

import { useInifiteList } from '../../InfiniteList';
import { IdType } from '../../types';
import { useEditTable } from '../edit-table-provider/EditTableProvider';
import { TableCell } from '../TableCell/TableCell';

import {
  DRAG_HANDLER_COLUMN_WIDTH,
  TABLE_ROW_DND_ITEM_TYPE,
} from './constants';
import { DragHandlerIconButton } from './DragHandlerIconButton';
import { useResizable } from './use-resizable';

type ITableRowDndItem = {
  id: IdType;
  index: number;
};

export type ITableRowProps = TableCommonProps & {
  disabled?: boolean;
  id: IdType;
  index: number;
  onMoveRow?: (id: IdType, index: number) => void;
  children?: ReactNode;
  kind?: ErpPositionsKind;
  onClickOutside?: (id: IdType) => void;
  isNormal?: boolean;
  canDrop: (input: { id: IdType; index: number }) => number;
  hasBorder?: boolean;
  isSimpleMode?: boolean;
  isMenuVisible?: boolean;
  onClick?: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >['onClick'];
};
let dndItemRef = undefined;

const paddingsOfParents = remToPx(0.5);

const TableRow = (
  props: ITableRowProps,
  forwardedRef: React.ForwardedRef<HTMLDivElement>
) => {
  const {
    disabled = false,
    id,
    index,
    onMoveRow: moveRowDone,
    className,
    style,
    children,
    kind,
    canDrop,
    onClickOutside,
    isNormal = true,
    hasBorder = true,
    isSimpleMode,
    isMenuVisible,
    ...rest
  } = props;

  const dropRef = useRef<HTMLDivElement>(null);
  const dragRef = useRef<HTMLDivElement>(null);

  const [{ isOver, direction }, drop] = useDrop({
    accept: TABLE_ROW_DND_ITEM_TYPE,
    canDrop: (item, monitor) => {
      const canDropValue = canDrop(monitor.getItem());
      return Boolean(canDropValue);
    },
    collect: (monitor) => {
      const canDropValue = canDrop(monitor.getItem());
      return {
        isOver: monitor.isOver() && canDropValue,
        direction: canDropValue > 0 ? 'up' : 'down',
      };
    },
    hover(item: ITableRowDndItem) {
      if (!dropRef.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      dndItemRef = { id: item.id, index: hoverIndex };
    },
  });

  const [{ isDragging }, drag, preview] = useDrag<
    ITableRowDndItem,
    unknown,
    { isDragging: boolean }
  >({
    type: TABLE_ROW_DND_ITEM_TYPE,
    item: { id, index },
    options: {
      dropEffect: 'move',
    },
    collect(monitor) {
      return {
        isDragging: monitor.isDragging(),
      };
    },
    end() {
      if (dndItemRef) {
        moveRowDone?.(dndItemRef.id, dndItemRef.index);
      }
    },
  });

  preview(drop(dropRef));
  drag(dragRef);

  const modifiedStyle = produce(style, (draft) => {
    draft.width =
      (typeof draft.width === 'number' ? draft.width : parseInt(draft.width)) +
      DRAG_HANDLER_COLUMN_WIDTH;
    draft.opacity = isDragging ? 0 : 1;
    draft.height =
      kind === ErpPositionsKind.SEITENUMBRUCH &&
      typeof draft.height === 'number'
        ? draft.height + 1
        : draft.height;
  });

  const orientation = direction === 'up' ? '0 4px 0px 0px' : '0 -4px 0px 0px';
  const overStyle: React.CSSProperties = isOver
    ? {
        boxShadow: orientation + ' inset var(--brand01)',
      }
    : {};

  const border: React.CSSProperties = {
    borderBottomStyle: 'solid',
    borderColor: 'var(--ui04)',
    borderWidth:
      kind === ErpPositionsKind.SEITENUMBRUCH ? 2 : hasBorder ? 1 : 0,
    marginTop: kind === ErpPositionsKind.SEITENUMBRUCH ? -1 : 0,
    width: isSimpleMode ? 'fit-content' : '100%',
    ...overStyle,
  };

  const elId = `position_row_${id}`;

  useEffect(() => {
    const onClick = (evt) => {
      const flyoutEl = document.getElementById(`${elId}`);
      let targetEl = evt.target; // clicked element

      do {
        if (targetEl === flyoutEl || targetEl.id === elId) {
          return;
        }
        targetEl = targetEl.parentNode;
      } while (targetEl);
      onClickOutside?.(id);
    };

    document.addEventListener('click', onClick);
    return () => {
      document.removeEventListener('click', onClick);
    };
  }, [id, elId, onClickOutside]);

  const { onAdd, onRemove } = useEditTable();
  const handleAdd = (offset: number = 0) => {
    onAdd({
      positionType: ErpPositionsKind.TEXTZEILE,
      article: { id: 0 },
      index: index + offset,
      autofocus: true,
    });
  };

  const {
    handleElement,
    resizableRef,
    style: resizableStyle,
    height,
  } = useResizable();

  const { measureMax } = useInifiteList();

  useEffect(() => {
    // remove static offset of paddings
    if (height) measureMax(index, height - paddingsOfParents);
  }, [index, measureMax, height]);

  return (
    <div
      id={elId}
      key={elId}
      ref={reactRefSetter(forwardedRef, dropRef, resizableRef)}
      className={clsx(styles['table-row'], className)}
      style={{
        ...modifiedStyle,
        ...border,
        ...resizableStyle,
        minHeight: 'calc(2.25rem + 1px)',
      }}
      {...rest}
    >
      {!isMenuVisible ? null : disabled || !moveRowDone ? (
        <TableCell className={styles['drag-empty']} />
      ) : (
        <TableCell
          ref={dragRef}
          role="cell"
          className={clsx(styles['drag-handler'], {
            [styles['drag-handler-simple']]: isSimpleMode,
          })}
          kind={kind}
        >
          <DragHandlerIconButton
            onRemove={() => onRemove([id])}
            onAddAfter={
              isNormal
                ? () => {
                    handleAdd(1);
                  }
                : undefined
            }
            onAddBefore={
              isNormal
                ? () => {
                    handleAdd();
                  }
                : undefined
            }
          />
        </TableCell>
      )}

      {children}
      {handleElement}
    </div>
  );
};

const ForwardRefTableRow = forwardRef(TableRow);

export { ForwardRefTableRow as TableRow };
