import { useTranslation } from 'react-i18next';

import { Dialog, DialogActions, DialogContent } from '../../../dialog';
import { DeleteEntitiesConfirmDialog } from '../../../dialogs/DeleteEntitiesConfirmDialog';
import { BaseActionButton } from '../../../input/base-action-button/BaseActionButton';
import { DeleteDialogProps } from '../interfaces';

export const DeleteDialog = ({ dialogConfig, handlers }: DeleteDialogProps) => {
  const { t } = useTranslation();

  if (!dialogConfig) return null;

  if (dialogConfig.type === 'confirm') {
    return (
      <DeleteEntitiesConfirmDialog
        open
        count={dialogConfig.ids.length}
        onClose={handlers?.onClose}
        onConfirm={handlers?.onDelete}
      />
    );
  }

  if (dialogConfig.type === 'prevent') {
    return (
      <Dialog open title={t('ALERTS.HINT')} onClose={handlers?.onClose}>
        <DialogContent>{dialogConfig?.preventMessage}</DialogContent>

        <DialogActions>
          <BaseActionButton
            title={t('ALERTS.OK')}
            component="body2"
            color="primary"
            onClick={handlers?.onClose}
          />
        </DialogActions>
      </Dialog>
    );
  }
};
