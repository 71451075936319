import styles from '../StatisticPage.module.scss';

import { LinearProgress } from '@mui/material';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StatisticSalesVolumeYearTable } from '@work4all/components/lib/dataDisplay/basic-table/reporting/StatisticSalesVolumeYearTable';

import { useDataProvider } from '@work4all/data';

import { CostCenter } from '@work4all/models/lib/Classes/CostCenter.entity';
import { StatisticSalesVolumeYear } from '@work4all/models/lib/Classes/StatisticSalesVolumeYear.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { CostCenterPickerField } from '../../../components/entity-picker/CostCenterPickerField';
import { settings, useSetting } from '../../../settings';
import { useTableBorders } from '../../../utils/use-table-borders/use-table-borders';
import { generateYearList } from '../util/generate-year-list';
import { GeneralSetting, useGeneralSetting } from '../util/use-general-setting';
import { useOldestInvoice } from '../util/use-oldest-invoice';

export function StatisticSalesVolumeYearContainer() {
  const oldestInvoice = useOldestInvoice();
  const startBusinessYearSetting = useGeneralSetting({
    name: GeneralSetting.startBusinessYear,
  });
  const [currentCostCenter, setCurrenCostCenter] = useState<CostCenter>(null);
  const statisticConsiderInvoiceLedger = useSetting(
    settings.statisticConsiderInvoiceLedger()
  );

  const { t } = useTranslation();

  const yearList: number[] = useMemo(() => {
    const currentDate = DateTime.now();
    const startBusinessYear = startBusinessYearSetting?.data?.[0]?.value || 1;
    let date = currentDate.toJSDate();
    if (startBusinessYear > 1) {
      date = currentDate
        .minus({ years: currentDate.month < startBusinessYear ? 1 : 0 })
        .set({ month: startBusinessYear })
        .startOf('month')
        .toJSDate();
    }

    const result = generateYearList(
      new Date(oldestInvoice?.data?.[0]?.date),
      date
    );

    return result;
  }, [oldestInvoice?.data, startBusinessYearSetting?.data]);

  const requestData: DataRequest = useMemo(() => {
    const data: StatisticSalesVolumeYear = {
      items: [
        {
          amount: null,
          month: null,
        },
      ],
      start: null,
      end: null,
    };

    return {
      entity: Entities.statisticSalesVolumeYear,
      data: data,
      vars: {
        onlyRaInvoices: statisticConsiderInvoiceLedger.value,
        years: yearList,
        costCenterFilter: currentCostCenter?.id,
      },
      completeDataResponse: true,
    };
  }, [currentCostCenter?.id, yearList, statisticConsiderInvoiceLedger.value]);

  const data = useDataProvider(requestData);

  const isLoading = data.loading || data.pending;

  const tableBorders = useTableBorders();

  return (
    <div className={styles.tableControlWrap}>
      {isLoading && (
        <LinearProgress
          style={{ position: 'absolute', width: '100%', zIndex: 200 }}
        />
      )}
      <div className={styles.controlBar}>
        <div className={styles.picker}>
          <CostCenterPickerField
            label={`${t('COMMON.FILTER_BY')} ${t('COMMON.COST_CENTER')}`}
            value={currentCostCenter}
            onChange={(value) => {
              setCurrenCostCenter(value);
            }}
          />
        </div>
      </div>
      <div className={styles.tableWrapper}>
        <StatisticSalesVolumeYearTable
          data={data.data}
          tableClasses={tableBorders}
        />
      </div>
    </div>
  );
}
