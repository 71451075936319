import styles from './SalesOpportunitiesEvaluationPicker.module.scss';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect } from 'react';
import {
  Controller,
  ControllerRenderProps,
  FormProvider,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IPickerProps } from '@work4all/components/lib/components/entity-picker/types';
import { LabeledCurrencyInput } from '@work4all/components/lib/input/labeled-currency-input/LabeledCurrencyInput';
import { LabeledInput } from '@work4all/components/lib/input/labeled-input/LabeledInput';

import { EMPTY_UID } from '@work4all/data/lib/utils/empty-uid';

import { SalesOpportunities } from '@work4all/models/lib/Classes/SalesOpportunities.entity';
import { SalesOpportunityRatingStatus } from '@work4all/models/lib/Classes/SalesOpportunityRatingStatus.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SalesOpportunityStatus } from '@work4all/models/lib/Enums/SalesOpportunityStatus.enum';

import { formatCurrency } from '@work4all/utils/lib/format-currency';

import { MaskContextProvider } from '../../../../containers/mask-overlays/mask-overlay/hooks/mask-context';
import { SalesOpportunityRatingStatusPickerField } from '../../../entity-picker/sales-opportunity-rating-picker-field/SalesOpportunityRatingStatusPickerField';
import { SalesOpportunitiesStatusPickerField } from '../../../entity-picker/SalesOpportunitiesStatusPickerField';

import {
  getRatingValue,
  getValuesOnProbabilityChange,
  getValuesOnRatingStatusChange,
  getValuesOnStatusChange,
  roundPercent,
} from './utils';

export type SalesOpportunitiesEvaluationPickerProps = IPickerProps & {
  salesOpportunity: SalesOpportunities<EMode.entity>;
};

export type FormValue = {
  probabilityPercent?: number;
  ratingStatus?: SalesOpportunityRatingStatus;
  ratingStatusId?: string;
  status?: SalesOpportunityStatus;
  comment?: string;
};

const FORM_ID = 'sales-opportunities-evaluation-picker-form';

export const SalesOpportunitiesEvaluationPicker = (
  props: SalesOpportunitiesEvaluationPickerProps
) => {
  const { salesOpportunity, onChange } = props;
  const { t } = useTranslation();

  const defaultValues: FormValue = {
    probabilityPercent: salesOpportunity.probabilityPercent ?? 0,
    ratingStatus: salesOpportunity.ratingStatus ?? null,
    ratingStatusId: salesOpportunity.ratingStatusId ?? null,
    status: salesOpportunity.status ?? null,
  };

  const form = useForm<FormValue>({
    defaultValues,
  });
  const { control, handleSubmit, register, setValue, watch } = form;

  const probabilityPercent = watch('probabilityPercent');
  const status = watch('status');
  const evaluatedValue =
    (roundPercent(probabilityPercent) * salesOpportunity.value) / 100;
  const currency = formatCurrency(salesOpportunity.currency, t) || '€';

  const setValues = useCallback(
    (values: FormValue) => {
      Object.keys(values).forEach((value) =>
        setValue(value as keyof FormValue, values[value])
      );
    },
    [setValue]
  );

  const handleRatingStatusChange = (
    event: SalesOpportunityRatingStatus<EMode.entity>,
    relField: ControllerRenderProps<FormValue, 'ratingStatus'>
  ) => {
    relField.onChange(event);

    if (!event?.id) {
      setValue('ratingStatusId', EMPTY_UID);
    } else {
      const values = getValuesOnRatingStatusChange(
        event.id,
        event.ratingValue,
        status
      );
      setValues(values);
    }
  };

  const handleProbablityPercentBlur = (
    field: ControllerRenderProps<FormValue, 'probabilityPercent'>
  ) => {
    setValue('ratingStatusId', EMPTY_UID);
    field.onBlur();

    if (!field.value) return;
    const percent = roundPercent(field.value);
    field.onChange(percent);
  };

  const handleStatusChange = (
    event: SalesOpportunityStatus,
    field: ControllerRenderProps<FormValue, 'status'>
  ) => {
    field.onChange(event);

    const newValue = getValuesOnStatusChange(event);
    if (newValue) {
      setValues(newValue);
    }
  };

  const onSubmit = (values: FormValue) => {
    onChange(values);
  };

  const onAbort = () => {
    onChange(null);
  };

  useEffect(() => {
    const newValues = getValuesOnProbabilityChange(status, probabilityPercent);
    setValues(newValues);
  }, [probabilityPercent, setValues, status]);

  return (
    <FormProvider {...form}>
      <MaskContextProvider
        value={{
          entity: Entities.salesOpportunities,
          isCreateMode: false,
          isEditMode: true,
          mode: 'edit',
          params: null,
          wip: false,
        }}
      >
        <form id={FORM_ID} onSubmit={handleSubmit(onSubmit)}>
          <Stack p={2}>
            <Typography className={styles.header}>
              {t('COMMON.NEW_EVALUATION')}
            </Typography>

            <div className={styles.card}>
              <div className={styles.controllerWrapper}>
                <Controller
                  control={control}
                  name="probabilityPercent"
                  render={({ field }) => (
                    <Controller
                      control={control}
                      name="ratingStatus"
                      render={({ field: relField }) => (
                        <SalesOpportunityRatingStatusPickerField
                          value={getRatingValue(field, relField)}
                          onChange={(event) =>
                            handleRatingStatusChange(event, relField)
                          }
                        />
                      )}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name="probabilityPercent"
                  render={({ field }) => (
                    <LabeledCurrencyInput
                      label={t('COMMON.EVALUATION')}
                      type="number"
                      unit="%"
                      value={probabilityPercent}
                      {...field}
                      onBlur={() => handleProbablityPercentBlur(field)}
                      inputProps={{
                        min: 0,
                        max: 100,
                        step: 10,
                        type: 'number',
                      }}
                    />
                  )}
                />
              </div>
              <div className={styles.controllerWrapper}>
                <Controller
                  control={control}
                  name="status"
                  render={({ field }) => (
                    <SalesOpportunitiesStatusPickerField
                      {...field}
                      onChange={(event) => handleStatusChange(event, field)}
                    />
                  )}
                />

                <LabeledCurrencyInput
                  disabled
                  label={t('COMMON.RATED')}
                  value={evaluatedValue}
                  type="number"
                  unit={currency}
                />
              </div>

              <LabeledInput
                multiline
                minRows={3}
                label={t('COMMON.COMMENT')}
                {...register('comment')}
              />
            </div>
            <Stack mt={2} flexDirection="row" justifyContent="flex-end">
              <Button size="large" color="primary" onClick={onAbort}>
                {t('COMMON.ABORT')}
              </Button>
              <Button size="large" color="primary" type="submit" form={FORM_ID}>
                {t('COMMON.OK')}
              </Button>
            </Stack>
          </Stack>
        </form>
      </MaskContextProvider>
    </FormProvider>
  );
};
