import { useCallback } from 'react';

import { useDataMutation, UseDataMutationOptions } from '@work4all/data';

import { InboundInvoice } from '@work4all/models/lib/Classes/InboundInvoice.entity';
import { InputRaZahlung } from '@work4all/models/lib/Classes/InputRaZahlung.entity';
import { InputReRelation } from '@work4all/models/lib/Classes/InputReRelation.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { NEW_ROW_ID_PREFIX } from '../constants';
import { Payment } from '../interfaces';
import { paymentToInputPayment } from '../utils';

interface Props {
  incomingInvoiceId: number;
  onCompleted?: UseDataMutationOptions<
    InboundInvoice,
    EMode.upsert
  >['onCompleted'];
}

export const usePaymentMutations = ({
  incomingInvoiceId,
  onCompleted,
}: Props) => {
  const [mutate] = useDataMutation<
    InboundInvoice,
    EMode.upsert,
    InputReRelation
  >({
    entity: Entities.inboundInvoice,
    mutationType: EMode.upsert,
    responseData: { id: null },
    translateRelationInput: false,
    onCompleted: onCompleted,
  });

  const addDirectly = useCallback(
    (data: InputRaZahlung[]) => {
      mutate(
        { id: incomingInvoiceId },
        {
          relations: {
            payments: {
              add: data,
            },
          },
        }
      );
    },
    [incomingInvoiceId, mutate]
  );

  const addAndModify = useCallback(
    (data: Payment[]) => {
      if (data.length === 0) return;

      const add: InputRaZahlung[] = [];
      const modify: InputRaZahlung[] = [];

      data.forEach((payment) => {
        if (
          typeof payment.id === 'string' &&
          payment.id.startsWith(NEW_ROW_ID_PREFIX)
        ) {
          delete payment['id'];
          add.push(paymentToInputPayment(payment));
        } else {
          delete payment['__typename'];
          modify.push(paymentToInputPayment(payment));
        }
      });

      mutate(
        { id: incomingInvoiceId },
        { relations: { payments: { add, modify } } }
      );
    },
    [incomingInvoiceId, mutate]
  );

  const remove = useCallback(
    (paymentId: number) => {
      mutate(
        { id: incomingInvoiceId },
        { relations: { payments: { delete: [paymentId] } } }
      );
    },
    [incomingInvoiceId, mutate]
  );

  return { addAndModify, remove, addDirectly };
};
