import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';
import {
  createSearchParams,
  URLSearchParamsInit,
  useSearchParams,
} from 'react-router-dom';

import { useCanGoBack } from '@work4all/components/lib/hooks';
import { LockObjectProvider } from '@work4all/components/lib/hooks/object-lock-subscription/useLockObject';
import {
  EventType,
  sendAmplitudeData,
} from '@work4all/components/lib/utils/amplitude/amplitude';

import { useNavigate } from '@work4all/data';
import { CustomFieldsConfigProvider } from '@work4all/data/lib/custom-fields';
import { useDocumentClasses } from '@work4all/data/lib/hooks/document-classes';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { CalculationsTable } from '../..//components/data-tables/CalculationsTable';
import { AppointmentsTable } from '../../components/data-tables/appointments-table/AppointmentsTable';
import { ArticleTable } from '../../components/data-tables/article-table/ArticleTable';
import { CallMemoTable } from '../../components/data-tables/CallMemoTable';
import { ChecklistsTable } from '../../components/data-tables/ChecklistsTable';
import { ContractsTable } from '../../components/data-tables/ContractsTable';
import { CustomersTable } from '../../components/data-tables/CustomersTable';
import { DeliveryNotesTable } from '../../components/data-tables/DeliveryNotesTable';
import { DemandsTable } from '../../components/data-tables/DemandsTable';
import { DocumentsTable } from '../../components/data-tables/DocumentsTable';
import { EMailSignatureTable } from '../../components/data-tables/EmailSignatureTable';
import { EmailsTable } from '../../components/data-tables/EmailsTable';
import { EmailTemplateTable } from '../../components/data-tables/EmailTemplateTable';
import { InboundInvoicesTable } from '../../components/data-tables/inbound-invoices-table/InboundInvoicesTable';
import { InboundDeliveryNotesTable } from '../../components/data-tables/InboundDeliveryNotesTable';
import { InvoicesTable } from '../../components/data-tables/InvoicesTable';
import { LettersTable } from '../../components/data-tables/LettersTable';
import { MailboxContentTable } from '../../components/data-tables/MailboxContentTable/MailboxContentTable';
import { NotesTable } from '../../components/data-tables/NotesTable';
import { OffersTable } from '../../components/data-tables/offers-table/OffersTable';
import { OrdersTable } from '../../components/data-tables/OrdersTable';
import { OutgoingInvoicesTable } from '../../components/data-tables/outgoing-invoices-table/OutgoingInvoicesTable';
import { ProductionContractsTable } from '../../components/data-tables/ProductionContractsTable';
import { ProjectTable } from '../../components/data-tables/project-table/ProjectTable';
import { SalesOpportunitiesTable } from '../../components/data-tables/SalesOpportunitiesTable';
import { SuppliersTable } from '../../components/data-tables/SuppliersTable';
import { TasksTable } from '../../components/data-tables/TasksTable';
import { TextBuildingBlockTable } from '../../components/data-tables/TextBuildingBlockTable';
import { TicketsTable } from '../../components/data-tables/TicketsTable';
import { TravelExpensesTable } from '../../components/data-tables/TravelExpensesTable';
import { TravelReceiptsTable } from '../../components/data-tables/TravelReceiptsTable';
import { VisitationReportsTable } from '../../components/data-tables/VisitationReportsTable';
import { WordDocumentTemplateTable } from '../../components/data-tables/WordDocumentTemplateTable';
import { WordLetterTemplateTable } from '../../components/data-tables/WordLetterTemplateTable';
import { ListPage } from '../../components/list-page/ListPage';
import { usePageTitle } from '../../hooks/use-page-title';
import { MaskConfig, OnOpenMask } from '../mask-overlays/mask-overlay';
import { OpenMaskProvider } from '../mask-overlays/mask-overlay/components/OpenMaskProvider';
import { NEW_ENTITY_ID } from '../mask-overlays/mask-overlay/mask-metadata';

import { ContentWrapper } from './components/contentWrapper/ContentWrapper';
import { TablePrefilterProvider } from './components/TablePrefilterProvider';
import { ListPageContext, ListPageContextValue } from './list-page-context';
import { useApendCustomListSettings } from './useApendCustomListSettings';

const EntityToTable = {
  [Entities.visitationReport]: VisitationReportsTable,
  [Entities.checkList]: ChecklistsTable,
  [Entities.calculation]: CalculationsTable,
  [Entities.offer]: OffersTable,
  [Entities.ticket]: TicketsTable,
  [Entities.deliveryNote]: DeliveryNotesTable,
  [Entities.inboundDeliveryNote]: InboundDeliveryNotesTable,
  [Entities.invoice]: InvoicesTable,
  [Entities.document]: DocumentsTable,
  [Entities.letter]: LettersTable,
  [Entities.order]: OrdersTable,
  [Entities.inboundInvoice]: InboundInvoicesTable,
  [Entities.demand]: DemandsTable,
  [Entities.task]: TasksTable,
  [Entities.note]: NotesTable,
  [Entities.callMemo]: CallMemoTable,
  [Entities.eMail]: EmailsTable,
  [Entities.contract]: ContractsTable,
  [Entities.travelExpenses]: TravelExpensesTable,
  [Entities.travelReceipts]: TravelReceiptsTable,
  [Entities.appointment]: AppointmentsTable,
  [Entities.project]: ProjectTable,
  [Entities.customer]: CustomersTable,
  [Entities.supplier]: SuppliersTable,
  [Entities.productionContract]: ProductionContractsTable,
  [Entities.article]: ArticleTable,
  [Entities.wordLetterTemplate]: WordLetterTemplateTable,
  [Entities.wordDocumentTemplate]: WordDocumentTemplateTable,
  [Entities.eMailTemplate]: EmailTemplateTable,
  [Entities.eMailSignature]: EMailSignatureTable,
  [Entities.textBuildingBlock]: TextBuildingBlockTable,
  [Entities.salesOpportunities]: SalesOpportunitiesTable,
  [Entities.mailboxContent]: MailboxContentTable,
  [Entities.ra]: OutgoingInvoicesTable,
};

interface TableViewProps {
  entityType: Entities;
  prefilter: unknown[];
}
const CONST_ARRAY = [];
const TableView = ({ entityType, prefilter = CONST_ARRAY }: TableViewProps) => {
  const navigate = useNavigate();

  const listPageContextValue = useMemo<ListPageContextValue | null>(() => {
    if (!entityType) return null;
    return { entityType };
  }, [entityType]);

  const onOpenMask: OnOpenMask = useCallback(() => {
    const getNavigationPropeties = ({
      entity,
      id,
      template,
      openTab,
      params = {},
    }: MaskConfig) => {
      const pathname =
        entity === entityType
          ? `details/${id ?? NEW_ENTITY_ID}`
          : `details/${entity}/${id ?? NEW_ENTITY_ID}`;

      const searchInit: URLSearchParamsInit = {};

      if (template) {
        searchInit.template = `${template.entity}:${template.id}`;
      }

      if (openTab) {
        searchInit.openTab = openTab;
      }

      for (const [key, value] of Object.entries(params)) {
        if (value != null) {
          searchInit[key] = value;
        }
      }
      return {
        pathname,
        search: `${createSearchParams(searchInit)}`,
      };
    };

    return {
      getHref: ({ entity, id, template, openTab, params }) => {
        const { pathname, search } = getNavigationPropeties({
          entity,
          id,
          template,
          openTab,
          params,
        });
        return search ? `${pathname}?${search}` : pathname;
      },
      handler: ({ entity, id, template, openTab, params }) => {
        const navigationProps = getNavigationPropeties({
          entity,
          id,
          template,
          openTab,
          params,
        });
        navigate(navigationProps);
      },
    };
  }, [entityType, navigate]);

  const openMask = useMemo(() => ({ onOpenMask }), [onOpenMask]);

  if (!entityType) {
    return <div>TODO</div>;
  }

  const TableComp = EntityToTable[entityType];
  // Add `key` to <CustomFieldsConfigProvider> to unmount the component tree
  // when navigating from one list page to another. This is required because of
  // the current table state management implementation.
  return (
    <TablePrefilterProvider value={{ prefilter }}>
      <OpenMaskProvider value={openMask}>
        <LockObjectProvider entity={entityType}>
          <ListPageContext.Provider value={listPageContextValue}>
            <CustomFieldsConfigProvider key={entityType} entity={entityType}>
              <ListPage
                renderTable={(tableApiRef) => {
                  return <TableComp ref={tableApiRef} prefilter={prefilter} />;
                }}
              />
            </CustomFieldsConfigProvider>
          </ListPageContext.Provider>
        </LockObjectProvider>
      </OpenMaskProvider>
    </TablePrefilterProvider>
  );
};

export function resolveEntityVariant(entityTypeParam: string): {
  entityType: Entities;
  entityVariant: string | null;
  prefilter: unknown[] | null;
} {
  const regex = /^(?<entityName>.+?)\((?<entityVariant>.*)\)$/;

  const result = regex.exec(entityTypeParam);

  // This does not look like a virtual entity. Just treat it as normal entity
  // and return full string.
  if (result === null) {
    if (entityTypeParam === Entities.document) {
      return {
        entityType: Entities.document,
        entityVariant: '',
        prefilter: [{ infoWindowName: { $eq: '' } }],
      };
    }

    return {
      entityType: entityTypeParam as Entities,
      entityVariant: null,
      prefilter: null,
    };
  }

  const { entityName, entityVariant } = result.groups;

  return {
    entityType: entityName as Entities,
    entityVariant,
    prefilter: [{ infoWindowName: { $eq: entityVariant } }],
  };
}

export const FileEntitiesListsController: React.FC<{
  amplitudeEntryPoint?: string;
}> = (props) => {
  const { entityType: entityTypeParam, fileType } = useParams<{
    entityType: Entities;
    fileType: Entities;
  }>();

  const { amplitudeEntryPoint = fileType } = props;

  const resolved = resolveEntityVariant(entityTypeParam);
  const { entityType, entityVariant, prefilter } = resolved;

  const documentClasses = useDocumentClasses();

  const isEntityTypeRecognized = useMemo(() => {
    if (!(entityType in Entities)) {
      return false;
    }

    if (entityType !== Entities.document) {
      return true;
    }

    return (
      entityVariant === '' ||
      documentClasses.some((documentClass) => {
        return documentClass.name === entityVariant;
      })
    );
  }, [entityType, entityVariant, documentClasses]);

  useApendCustomListSettings(entityType);

  useEffect(() => {
    sendAmplitudeData(EventType.OpenList, {
      name: entityType,
      entryPoint: amplitudeEntryPoint,
    });
  }, [amplitudeEntryPoint, entityType]);

  const [searchParams] = useSearchParams();

  const { t, i18n } = useTranslation();

  let pageTitle =
    entityVariant ||
    t(`LIST.${entityType.toUpperCase()}.TITLE`, { defaultValue: '' }) ||
    t(`COMMON.${entityType.toUpperCase()}`, { count: 2 });
  const customizedTitle = searchParams.get('customizedTitle');
  if (customizedTitle) {
    pageTitle = i18n.exists(customizedTitle)
      ? t(customizedTitle)
      : customizedTitle;
  }

  usePageTitle(pageTitle);

  const location = useLocation();
  const canGoBack = useCanGoBack();

  const closeUrl = location.pathname.replace(/\/entity.*/, '');

  const TableComp = EntityToTable[entityType];

  return (
    <ContentWrapper
      backAction={canGoBack ? 'back' : 'close'}
      closeUrl={closeUrl}
      entity={entityType}
      amplitudeEntryPoint={amplitudeEntryPoint}
      title={isEntityTypeRecognized && TableComp ? pageTitle : '404 Not Found'}
      data-test-id="list-page-wrapper"
    >
      {isEntityTypeRecognized && TableComp && (
        <TableView entityType={entityType} prefilter={prefilter} />
      )}
    </ContentWrapper>
  );
};
