import { useCallback } from 'react';

import { components } from '../../apiModels';
import { useHttpClient } from '../http-client/hooks';
import { SetArchivePdfParams } from '../interfaces';

import { useUser } from './useUser';

export function useSetArchivePdf() {
  const user = useUser();

  const { baseUrl } = user ?? {};

  const httpClient = useHttpClient();

  return useCallback(
    async function setArchivePdf(
      params: SetArchivePdfParams
    ): Promise<components['schemas']['SetArchivePdfResult']> {
      try {
        if (baseUrl === undefined) {
          throw new Error();
        }

        const response = await httpClient.put<
          components['schemas']['SetArchivePdfResult'],
          SetArchivePdfParams
        >({
          url: `${baseUrl}/api/file/converttempfile`,
          body: {
            target: 'ArchivPdf',
            ...params,
          },
        });

        return response.data;
      } catch (err) {
        console.error(err);
        return null;
      }
    },
    [baseUrl, httpClient]
  );
}
