import { memoize } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { Holiday } from '@work4all/models/lib/Classes/Holiday.entity';
import { Vacation } from '@work4all/models/lib/Classes/Vacation.entity';

import { useDeepMemo } from '@work4all/utils/lib/hooks/use-deep-memo';

import { useAbsences } from './use-absences';
import { usePublicHolidays } from './use-public-holidays';
import { isVacationApproved } from './utils';

type DateKey = string & { readonly __brand__: unique symbol };

function getDateKey(date: Date): DateKey {
  return DateTime.fromJSDate(date).toISODate() as DateKey;
}

function useVacationRequestData({
  missingHolidays,
  addHolidays,
  missingVacations,
  addVacations,
}: {
  missingHolidays: number[];
  addHolidays: (year: number, holidays: Holiday[]) => void;
  missingVacations: number[];
  addVacations: (year: number, vacations: Vacation[]) => void;
}) {
  const loadingHolidaysYear = missingHolidays[0] ?? null;
  const loadingVacationsYear = missingVacations[0] ?? null;

  const usePublicHolidaysOptions = useMemo(() => {
    if (loadingHolidaysYear === null) {
      return { from: null, to: null, skip: true };
    }

    const from = new Date(loadingHolidaysYear, 0, 1);
    const to = DateTime.fromJSDate(from).endOf('year').toJSDate();

    return { from, to, skip: false };
  }, [loadingHolidaysYear]);

  const useVacationsOptions = useMemo(() => {
    if (loadingVacationsYear === null) {
      return { from: null, to: null, skip: true };
    }

    const from = new Date(loadingVacationsYear, 0, 1);
    const to = DateTime.fromJSDate(from).plus({ years: 1 }).toJSDate();

    return { from, to, skip: false };
  }, [loadingVacationsYear]);

  const holidays = usePublicHolidays(usePublicHolidaysOptions);

  const { vacations } = useAbsences(useVacationsOptions);

  useEffect(() => {
    if (!holidays.loading && loadingHolidaysYear !== null) {
      addHolidays(loadingHolidaysYear, holidays.data);
    }
  }, [addHolidays, loadingHolidaysYear, holidays.data, holidays.loading]);

  useEffect(() => {
    if (!vacations.loading && loadingVacationsYear !== null) {
      addVacations(loadingVacationsYear, vacations.data);
    }
  }, [addVacations, loadingVacationsYear, vacations.data, vacations.loading]);
}

interface UseVacationRequestStateOptions {
  /**
   * Normally the currently selected date range will be filtered to exclude all
   * holidays and already requested vacation. This will cause the selected day
   * being also filtered out when opening the mask for an already existing
   * vacation request to view it. Use mode "view" to disable filtering entirely.
   * The data will be displayed as is.
   *
   * @default "request"
   */
  mode?: 'request' | 'view';
  range: [Date | null, Date | null];
  viewRange: [Date, Date] | null;
  userId: number;
  options: {
    firstHalf: boolean;
    secondHalf: boolean;
  };
}

export function useVacationRequestState({
  mode = 'request',
  range,
  viewRange,
  userId,
  options,
}: UseVacationRequestStateOptions) {
  const normalizedRange = useMemo(() => {
    const [_start, _end] = range;

    if (_start === null) {
      return [null, null];
    }

    const start = DateTime.fromJSDate(_start).startOf('day');
    const end =
      _end === null ? start : DateTime.fromJSDate(_end).startOf('day');

    return [start.toJSDate(), end.toJSDate()];
  }, [range]);

  const [selectedStart, selectedEnd] = normalizedRange;

  const visibleRange = (() => {
    if (viewRange === null) {
      return normalizedRange;
    }

    const [viewStart, viewEnd] = viewRange;

    const start = selectedStart < viewStart ? selectedStart : viewStart;
    const end = selectedEnd > viewEnd ? selectedEnd : viewEnd;

    return [start, end] as [Date, Date];
  })();

  const [visibleStart, visibleEnd] = visibleRange;

  // TODO In certain situations the calendar might not display the full
  // information about holidays / vacations for some days. It can happen if you
  // open a calendar at the end / start of the last / first month of the year
  // and the next / previous year's month is visible in the calendar. If you
  // actually select a date in this month or navigate back / forward in the
  // calendar, the data will be loaded. So I don't think it's of high priority
  // to fix this now.

  const requiredYears = useMemo<number[]>(() => {
    if (visibleStart === null || visibleEnd === null) {
      return [];
    }

    const startYear = visibleStart.getFullYear();
    const endYear = visibleEnd.getFullYear();

    const requiredYears = Array.from(
      { length: endYear - startYear + 1 },
      (_, k) => {
        return startYear + k;
      }
    );

    return requiredYears;
  }, [visibleStart, visibleEnd]);

  interface HolidaysState {
    loaded: Set<number>;
    // date -> holiday
    data: Map<DateKey, Holiday>;
  }

  interface VacationsState {
    loaded: Set<number>;
    // date -> user -> vacation
    data: Map<DateKey, Map<number, Vacation>>;
  }

  const [holidays, setHolidays] = useState<HolidaysState>(() => {
    return { loaded: new Set(), data: new Map() };
  });

  const missingHolidays = requiredYears.filter((year) => {
    return !holidays.loaded.has(year);
  });

  const getHoliday = useCallback(
    (date: Date): Holiday | null => {
      return holidays.data.get(getDateKey(date)) ?? null;
    },
    [holidays]
  );

  const [vacations, setVacations] = useState<VacationsState>(() => {
    return { loaded: new Set(), data: new Map() };
  });

  const missingVacations = requiredYears.filter((year) => {
    return !vacations.loaded.has(year);
  });

  function _getVacation(date: Date): Map<number, Vacation> | null;
  function _getVacation(date: Date, userId: number): Vacation | null;
  function _getVacation(date: Date, userId?: number) {
    const vacationsByDay = vacations.data.get(getDateKey(date)) ?? null;

    if (userId === undefined) {
      return vacationsByDay;
    }

    return vacationsByDay?.get(userId) ?? null;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getVacation = useCallback(_getVacation, [vacations]);

  const addHolidays = useCallback(
    (year: number, data: Holiday[]): void => {
      const draft: HolidaysState = {
        loaded: new Set(holidays.loaded),
        data: new Map(holidays.data),
      };

      // Keep track of years added in this update.
      const added = new Set<number>();

      for (const holiday of data) {
        // const date = DateTime.fromISO(holiday.datum).startOf('day').toJSDate();
        const date = DateTime.fromISO(holiday.date).startOf('day').toJSDate();

        const year = date.getFullYear();

        if (draft.loaded.has(year)) {
          // Ignore the update if this year has already been loaded before.
          return;
        }

        draft.data.set(getDateKey(date), holiday);
        added.add(year);
      }

      // Mark the given year as loaded even if nothing was added.
      if (!draft.loaded.has(year)) {
        added.add(year);
      }

      if (added.size === 0) {
        // Nothing was added. The update can be ignored.
        return;
      }

      for (const year of added) {
        draft.loaded.add(year);
      }

      setHolidays(draft);
    },
    [holidays]
  );

  const addVacations = useCallback(
    (year: number, data: Vacation[]): void => {
      const draft: VacationsState = {
        loaded: new Set(vacations.loaded),
        data: new Map(
          [...vacations.data].map(([year, vacationsByUser]) => {
            return [year, new Map(vacationsByUser)];
          })
        ),
      };

      // Keep track of years added in this update.
      const added = new Set<number>();

      for (const vacation of data) {
        // Ignore vacations without a user.
        if (vacation.user == null) {
          continue;
        }

        const date = DateTime.fromISO(vacation.date).startOf('day').toJSDate();

        const year = date.getFullYear();

        if (draft.loaded.has(year)) {
          // Ignore the update if this year has already been loaded before.
          return;
        }

        const vacationsByUser: Map<number, Vacation> =
          draft.data.get(getDateKey(date)) ?? new Map();

        vacationsByUser.set(vacation.user.id, vacation);
        added.add(year);

        draft.data.set(getDateKey(date), vacationsByUser);
      }

      // Mark the given year as loaded even if nothing was added.
      if (!draft.loaded.has(year)) {
        added.add(year);
      }

      if (added.size === 0) {
        // Nothing was added. The update can be ignored.
        return;
      }

      for (const year of added) {
        draft.loaded.add(year);
      }

      setVacations(draft);
    },
    [vacations]
  );

  useVacationRequestData({
    missingHolidays,
    missingVacations,
    addHolidays,
    addVacations,
  });

  const myObject = useMemo(() => {
    type Actual = {
      start: Date;
      end: Date;
      exclude: Date[];
      duration: number;
      days: Array<{
        date: Date;
        slot: 'full-day' | 'first-half' | 'second-half';
      }>;
      isSingleDay: boolean;
      isMultipleDays: boolean;
    };

    if (selectedStart === null || selectedEnd === null) {
      const r: Actual = {
        start: null,
        end: null,
        exclude: [],
        duration: 0,
        days: [],
        isSingleDay: false,
        isMultipleDays: false,
      };

      return r;
    }

    const _start = DateTime.fromJSDate(selectedStart);
    const _end = DateTime.fromJSDate(selectedEnd);

    const days: Actual['days'] = [];
    const exclude: Date[] = [];

    for (let day = _start; day <= _end; day = day.plus({ days: 1 })) {
      const jsDate = day.toJSDate();

      // Do not filter out already requested vacation days when opening mask to
      // view an existing request. Just display everything as is.
      if (mode === 'view') {
        days.push({
          date: jsDate,
          slot: 'full-day',
        });
      } else {
        // If the day is a holiday or is already a part of another vacation
        // request, exclude it.

        const holiday = getHoliday(jsDate);
        const isWeekend = jsDate.getDay() === 0 || jsDate.getDay() === 6;
        const isPublicHoliday = holiday !== null;
        const isHoliday = isWeekend || isPublicHoliday;

        const vacation = getVacation(jsDate, userId);
        const isOwnVacation = vacation !== null;

        if (isHoliday || isOwnVacation) {
          exclude.push(jsDate);
        } else {
          days.push({
            date: jsDate,
            slot: 'full-day',
          });
        }
      }
    }

    if (days.length > 0) {
      if (days.length === 1) {
        if (options.secondHalf) {
          days[0].slot = 'second-half';
        } else if (options.firstHalf) {
          days[0].slot = 'first-half';
        }
      } else {
        if (options.secondHalf) {
          days[0].slot = 'second-half';
        }
        if (options.firstHalf) {
          days[days.length - 1].slot = 'first-half';
        }
      }
    }

    const duration = days.reduce((acc, day) => {
      if (day.slot === 'full-day') {
        return acc + 1;
      } else {
        return acc + 0.5;
      }
    }, 0);

    let start: Date | null = null;
    let end: Date | null = null;

    if (days.length > 0) {
      start = days[0].date;
      end = days[days.length - 1].date;
    }

    const r: Actual = {
      start,
      end,
      days,
      exclude,
      duration,
      isSingleDay: days.length === 1,
      isMultipleDays: days.length > 1,
    };

    return r;
  }, [
    mode,
    selectedStart,
    selectedEnd,
    getHoliday,
    getVacation,
    userId,
    options.firstHalf,
    options.secondHalf,
  ]);

  const actual = useDeepMemo(() => {
    return myObject;
  }, [myObject]);

  const getIsSelected = useCallback(
    (date: Date): boolean => {
      if (selectedStart === null || selectedEnd === null) {
        return false;
      }

      return selectedStart <= date && date <= selectedEnd;
    },
    [selectedStart, selectedEnd]
  );

  const getIsExcluded = useCallback(
    (date: Date): boolean => {
      return actual.exclude.some((d) => +d === +date);
    },
    [actual.exclude]
  );

  const getIsFirstHalf = useCallback(
    (date: Date): boolean => {
      return (
        actual.days.find((day) => {
          return +day.date === +date;
        })?.slot === 'first-half'
      );
    },
    [actual.days]
  );

  const getIsSecondHalf = useCallback(
    (date: Date): boolean => {
      return (
        actual.days.find((day) => {
          return +day.date === +date;
        })?.slot === 'second-half'
      );
    },
    [actual.days]
  );

  const getDayInfo = useMemo(() => {
    function getDayInfo(date: Date) {
      const isWeekend = date.getDay() === 0 || date.getDay() === 6;
      const isPublicHoliday = getHoliday(date) !== null;
      const isHoliday = isWeekend || isPublicHoliday;

      const vacation = getVacation(date, userId);
      const isApprovedVacation =
        vacation !== null && isVacationApproved(vacation);
      const isRequestedVacation =
        vacation !== null && !isVacationApproved(vacation);
      const isOwnVacation = isApprovedVacation || isRequestedVacation;

      const vacationsByUser = getVacation(date) ?? new Map<number, Vacation>();
      const usersIds = [...vacationsByUser.keys()];
      const colleaguesIds = usersIds.filter((id) => id !== userId);
      const colleaguesVacations = colleaguesIds.map((userId) => {
        return vacationsByUser.get(userId);
      });
      const colleagues = colleaguesVacations
        .map((vacation) => vacation.user)
        .sort((a, b) => a.displayName.localeCompare(b.displayName));

      const isSelected = getIsSelected(date);
      const isExcluded = getIsExcluded(date);

      const isFirstHalf = getIsFirstHalf(date);
      const isSecondHalf = getIsSecondHalf(date);

      return {
        isHoliday,
        isApprovedVacation,
        isRequestedVacation,
        isOwnVacation,
        isColleaguesVacation: colleaguesVacations.length > 0,
        colleaguesVacations,
        colleagues,
        isSelected,
        isExcluded,
        isFirstHalf,
        isSecondHalf,
      };
    }
    return memoize(getDayInfo, getDateKey);
  }, [
    userId,
    getHoliday,
    getVacation,
    getIsSelected,
    getIsExcluded,
    getIsFirstHalf,
    getIsSecondHalf,
  ]);

  return useMemo(
    () => ({
      ...actual,
      getDayInfo,
    }),
    [actual, getDayInfo]
  );
}
