import { htmlUtils } from './htmlUtils';

export const DEFAULT_HTML_FONT_SIZE = '12pt';
export const DEFAULT_HTML_FONT_FAMILY = 'Calibri, sans-serif';
const SIGNATURE_START = '<div class="signature">';
const SIGNATURE_END = '</div>';

function createElementFromHTML(htmlString: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');
  return doc.documentElement.querySelector('body');
}

function applyInlineStyles(input: string) {
  if (!input) return input;
  const masterDiv = createElementFromHTML(input);
  for (let i = 0; i < masterDiv.children.length; i++) {
    const child = masterDiv.children[i] as HTMLElement;
    if (!child.style?.fontSize) {
      child.style.fontSize = DEFAULT_HTML_FONT_SIZE;
    }
    if (!child.style?.fontFamily) {
      child.style.fontFamily = DEFAULT_HTML_FONT_FAMILY;
    }
  }
  return masterDiv.innerHTML;
}

function toApi(html: string) {
  const masterDiv = createElementFromHTML(html);
  const nodes = masterDiv.querySelectorAll('[fr-original-class*=textmarke]');
  nodes.forEach((node) => {
    let current = node.firstChild;

    if (htmlUtils.isText(current)) {
      const classNames =
        node.getAttribute('fr-original-class')?.split(' ') || [];
      classNames.forEach((className) => {
        if (!node.className.includes(className)) node.classList.add(className);
      });
      return;
    }

    // check if this has className textmarke if yes just remove it
    while (current) {
      current = current.firstChild;
      if (htmlUtils.isText(current)) {
        const span = document.createElement('span');
        span.innerHTML = current.textContent;
        span.classList.add('api-cleanable');

        const dataItem = node.getAttribute('data-item');
        const dataName = node.getAttribute('data-name');

        const hasTextMarkData = Boolean(dataItem) && Boolean(dataName);
        if (hasTextMarkData) span.classList.add('textmarke');

        if (dataItem) span.setAttribute('data-item', dataItem);
        if (dataName) span.setAttribute('data-name', dataName);
        current.parentElement.innerHTML = span.outerHTML;
      }
    }
  });

  const disabledNodes = masterDiv.querySelectorAll('[data-item-disabled]');
  disabledNodes.forEach((node) => {
    const current = node.firstChild;

    if (htmlUtils.isText(current)) {
      const dataItem = node.getAttribute('data-item');
      const dataName = node.getAttribute('data-name');
      const hasTextMarkData = Boolean(dataItem) && Boolean(dataName);
      if (!hasTextMarkData) {
        node.className = node.className.replace(/textmarke/, '');
      }
      return;
    }
  });

  return applyInlineStyles(masterDiv.innerHTML);
}

function toFroala(html: string) {
  const masterDiv = createElementFromHTML(html);
  const nodes = masterDiv.querySelectorAll('[class*=api-cleanable]');
  nodes.forEach((node) => {
    node.parentElement.innerHTML = node.innerHTML;
  });

  masterDiv.childNodes.forEach((x: HTMLElement) => {
    if (htmlUtils.hasNoText(x)) {
      x.remove();
    }
  });

  return applyInlineStyles(masterDiv.innerHTML);
}

function replaceSignature(input: string, newSignature: string) {
  const masterDiv = createElementFromHTML(input);
  const signature = masterDiv.getElementsByClassName('signature')?.[0];

  if (signature) {
    if (newSignature) signature.innerHTML = newSignature;
    else {
      signature.remove();
    }
  } else {
    const div = document.createElement('div');
    div.className = 'signature';
    div.innerHTML = newSignature;
    masterDiv.append(div);
  }

  return applyInlineStyles(masterDiv.innerHTML);
}

function asSignature(input: string) {
  return applyInlineStyles(`${SIGNATURE_START}${input ?? ''}${SIGNATURE_END}`);
}

function applyBody(initalBody: string, currentBody: string) {
  if (!currentBody) return applyInlineStyles(initalBody);
  const masterDiv = createElementFromHTML(currentBody);
  if (
    masterDiv.children.length === 1 &&
    masterDiv.children[0].className.includes('signature')
  ) {
    return applyInlineStyles(initalBody);
  }
  return applyInlineStyles(currentBody);
}

function disableTextmarks(input: string) {
  return input
    .replace('class="signature"', '')
    .replace(/data-item=/gm, 'data-item-disabled=')
    .replace(/data-name=/gm, 'data-name-disabled=');
}

export const htmlParser = {
  toApi,
  toFroala,
  asSignature,
  replaceSignature,
  applyBody,
  applyInlineStyles,
  disableTextmarks,
};
