import styles from './EntityPickerActions.module.scss';

import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  ContractPicker,
  IContractPickerProps,
} from '@work4all/components/lib/components/entity-picker/contract-picker/ContractPicker';
import { useEntityTemplate } from '@work4all/components/lib/components/entity-template-provider/EntityTemplateProvider';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

interface ContractPickerFieldProps
  extends Omit<IContractPickerProps<false>, 'multiple'> {
  error?: string;
  name?: string;
}

export const ContractPickerField = React.forwardRef<
  HTMLDivElement,
  ContractPickerFieldProps
>(function ContractPickerField(props, ref) {
  const { error, ...pickerProps } = props;
  const { value, onChange } = pickerProps;

  const { t } = useTranslation();

  const popoverRef = useRef<EntityPickerPopover>(null);
  const entityTemplate = useEntityTemplate();

  return (
    <EntityPickerPopover
      ref={popoverRef}
      picker={<ContractPicker {...pickerProps} multiple={false} />}
      footer={entityTemplate.renderPickerFooter({
        popoverRef,
        entity: Entities.contract,
        ...props,
      })}
    >
      <PickerTargetButton
        ref={ref}
        value={value ? value?.note : ''}
        name={props.name}
        label={
          <>
            {!value && t('COMMON.CONTRACT')}
            {value && (
              <>
                <span className={styles.primaryInfo}>
                  {t('COMMON.CONTRACT')} &nbsp;
                </span>
                <span className={styles.secondaryInfo}>
                  {value?.contractNumber}
                </span>
              </>
            )}
          </>
        }
        error={error}
        onClear={() => {
          onChange(null);
        }}
      />
    </EntityPickerPopover>
  );
});
