import { useLayoutEffect, useState } from 'react';

export interface MeasurerProps {
  children: MeasurerRender;
  onChange: (height: number) => void;
}

type MeasurerRenderProps = { measureRef: React.RefCallback<HTMLElement> };
type MeasurerRender = React.FC<MeasurerRenderProps>;

export function Measurer(props: MeasurerProps) {
  const { onChange, children } = props;

  const [element, setElement] = useState<HTMLElement>(null);

  useLayoutEffect(() => {
    if (element) {
      const rect = element.getBoundingClientRect();
      const height = rect.height;

      onChange(height);

      const observer = new ResizeObserver((entries: ResizeObserverEntry[]) => {
        const entry = entries[0];

        if (entry?.borderBoxSize) {
          const box = entry.borderBoxSize[0];
          if (box) {
            onChange(box.blockSize);
            return;
          }
        }

        const rect = element.getBoundingClientRect();
        const height = rect.height;

        onChange(height);
      });

      observer.observe(element);

      return () => {
        observer.unobserve(element);
      };
    }
  }, [element, onChange]);

  return children({ measureRef: setElement });
}
