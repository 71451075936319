import React from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  ReportType,
  ReportTypePicker,
  ReportTypePickerProps,
} from '@work4all/components/lib/components/entity-picker/report-type-picker/ReportTypePicker';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

interface Props
  extends Omit<
    ReportTypePickerProps<false>,
    'multiple' | 'value' | 'onChange'
  > {
  error?: string;
  value: ReportType;
  onChange: (value: ReportType) => void;
}

export const ReportTypePickerField = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { error, ...pickerProps } = props;
    const { value, onChange } = pickerProps;

    const { t } = useTranslation();

    const name = t('COMMON.REPORT_TYPE.' + value);

    const handleClear =
      pickerProps.clearable === false
        ? undefined
        : () => {
            onChange(null);
          };

    return (
      <EntityPickerPopover
        picker={
          <ReportTypePicker
            {...pickerProps}
            multiple={false}
            value={{ id: value, name: '' }}
            onChange={(e) => onChange(e.id)}
          />
        }
      >
        <PickerTargetButton
          ref={ref}
          onClear={handleClear}
          label={t('COMMON.KIND')}
          error={error}
          value={name || ''}
        />
      </EntityPickerPopover>
    );
  }
);
