import styles from './styles.module.scss';

import { ControlWrapper } from '../../../../components';

import { BookingAssistance } from './BookingAssistance';
import { GeneralData } from './GeneralData';

export const GeneralSection = () => {
  return (
    <ControlWrapper columns={3} className={styles.root}>
      <GeneralData />
      <BookingAssistance />
    </ControlWrapper>
  );
};
