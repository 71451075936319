import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class ConvertTempFileResult<T extends EMode = EMode.entity> {
  /** Alias: ok */
  ok?: boolean;
  /** Alias: targetId */
  targetId?: string;
  /** Alias: targetCode */
  targetCode?: number;
  /** Alias: errorMessage */
  errorMessage?: string;

  __typename?: string;
}

const fields: FieldDefinitions<ConvertTempFileResult> = {
  ok: {
    alias: 'ok',
  },
  targetId: {
    alias: 'targetId',
  },
  targetCode: {
    alias: 'targetCode',
  },
  errorMessage: {
    alias: 'errorMessage',
  },
  __typename: {
    alias: '__typename',
  },
};

export const convertTempFileResultEntityDefinition: EntitiyDefinition<ConvertTempFileResult> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'ConvertTempFileResult',
      withPaginationWrapper: false,
      mutations: {
        upsert: {
          mutationName: 'convertTempFile',
          args: [
            { name: 'tempFileIds', type: '[ID]!' },
            { name: 'targetType', type: 'ConvertTempfileTarget!' },
            { name: 'fileLinkType', type: 'FileLinkType' },
            { name: 'targetId', type: 'ID' },
            { name: 'targetCode', type: 'Int' },
          ],
        },
      },
    },
    fieldDefinitions: fields,
  };
