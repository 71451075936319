import styles from './Bookings.module.scss';

import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { IconButton, Tooltip } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useContext, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ReactComponent as SettingsIcon } from '@work4all/assets/icons/settings3.svg';

import { useDataProvider } from '@work4all/data';

import { Currency } from '@work4all/models/lib/Classes/Currency.entity';
import { LedgerAccount } from '@work4all/models/lib/Classes/LedgerAccount.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { formatCurrency } from '@work4all/utils';

import { TableSettingsOverlay } from '../../../../../../../../../../components/data-tables/table/components/toolbar/components/TableSettingsOverlay';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import { Collapse } from '../../../../../../../components';
import { useMaskContext } from '../../../../../../../hooks/mask-context';
import { withAutoDisable } from '../../../../../../../utils/with-auto-disable';
import { CurrencyExchangeInfoContext } from '../../../../../currency-exchange-info-context';
import { InboundInvoiceFormValue } from '../../../../../types';
import { useShadowReObjectApiContext } from '../../../../../use-re-shadow-object-api/use-shadow-re-object-api-context';

import { BookingsTable } from './components/BookingsTable/BookingsTable';

export function Bookings({ disabled = false }: { disabled?: boolean }) {
  const { t } = useTranslation();

  const { watch } = useFormContextPlus<InboundInvoiceFormValue>();

  const bookings = watch('buchungen');
  const defaultLedgerAccountNumber = watch('supplier.standardAccount');

  const shadowObjectApi = useShadowReObjectApiContext();

  const currency = watch('currency');

  const [selectedBookings, setSelectedBookings] = useState<number[]>([]);

  const defaultLedgerAccount = useDefaultLedgerAccount({
    ledgerAccountNumber: defaultLedgerAccountNumber,
  });
  const [showSettingsOverlay, setSettingsOverlay] = useState(false);

  const headerBar = (
    <Stack
      sx={{
        flexDirection: {
          xs: 'column',
          md: 'row',
        },
      }}
      justifyContent="space-between"
      alignItems="center"
      flex={1}
    >
      {!disabled && (
        <Stack direction="row" spacing={1}>
          <Tooltip title={t('MASK.REMOVE')}>
            <IconButton
              type="button"
              disabled={selectedBookings.length === 0}
              size="large"
              color="primary"
              onClick={() => {
                shadowObjectApi.removeBookings(
                  selectedBookings.filter((id) => id !== -1)
                );
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      )}

      <div className={styles.actionsWrapper}>
        <CurrencyConversionWarning currency={currency} />
        <IconButton onClick={() => setSettingsOverlay(true)}>
          <SettingsIcon />
        </IconButton>
      </div>
    </Stack>
  );

  return (
    <React.Fragment>
      <TableSettingsOverlay
        settingsKey={'INCOMING_INVOICE'}
        entityType={Entities.inboundInvoice}
        isOpen={showSettingsOverlay}
        close={() => setSettingsOverlay(false)}
      />
      <Collapse
        className={styles.bookings}
        title={t('MASK.BOOKINGS')}
        defaultOpen
        headerBar={headerBar}
      >
        <div className={styles.tableWrapper}>
          <BookingsTable
            disabled={disabled}
            defaultLedgerAccount={defaultLedgerAccount}
            bookings={bookings ?? []}
            onSelectedPositionIdsChange={setSelectedBookings}
            onAddBooking={shadowObjectApi.addBooking}
            onEditBooking={shadowObjectApi.editBooking}
          />
        </div>
      </Collapse>
    </React.Fragment>
  );
}

export const BookingsWithAutoDisable = withAutoDisable(Bookings);

interface ICurrencyConversionWarningProps {
  currency: Currency | null;
}

function CurrencyConversionWarning(props: ICurrencyConversionWarningProps) {
  const { currency } = props;

  const { t } = useTranslation();

  const exchangeInfo = useContext(CurrencyExchangeInfoContext);

  const mask = useMaskContext();

  if (!currency || !exchangeInfo) return null;

  const { defaultCurrency } = exchangeInfo;

  if (currency.id === defaultCurrency.id) return null;

  const formattedRate = exchangeInfo.getFormattedRate(currency.id);

  return (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      {mask.isCreateMode ? (
        <WarningIcon color="warning" fontSize="medium" />
      ) : (
        <InfoIcon fontSize="medium" color="primary" />
      )}

      <Typography variant="caption">
        <Trans
          t={t}
          i18nKey={`OTHER.CURRENCY_CONVERSION_${
            mask.isCreateMode ? 'WARNING' : 'INFO'
          }`}
          values={{
            original: formatCurrency(currency, t),
            converted: formatCurrency(defaultCurrency, t),
            rate: formattedRate,
          }}
          components={{
            1: <Typography variant="caption" fontWeight={900} />,
          }}
        />
      </Typography>
    </Stack>
  );
}

function useDefaultLedgerAccount({
  ledgerAccountNumber,
}: {
  ledgerAccountNumber: number;
}): LedgerAccount {
  const request = useMemo((): DataRequest => {
    return {
      entity: Entities.ledgerAccount,
      data: {
        id: null,
        name: null,
        number: null,
        taxKey: null,
      },
      // Filtering by the number doesn't do anything atm.
      // vars: { nummer: ledgerAccountNumber },
    };
  }, []);

  const query = useDataProvider<LedgerAccount>(request, false, 9999);

  const found = useMemo(() => {
    return query.data?.find(
      (account) => account.number === ledgerAccountNumber
    );
  }, [query.data, ledgerAccountNumber]);

  return found ?? null;
}
