import { AppointmentAttendee } from '@work4all/models/lib/Classes/AppointmentAttendee.entity';
import { MailSearchContactsResultItem } from '@work4all/models/lib/Classes/MailSearchContactsResultItem.entity';

import { calculateToMailContact } from './calculateToMailContacts';

export const participantToEmailContact = (
  participant: AppointmentAttendee
): MailSearchContactsResultItem => {
  const { user } = participant;
  if (user) {
    return {
      id: `Benutzer_${user.id}`,
      mailAddress: user.eMail,
    };
  }
  return calculateToMailContact(participant)?.[0];
};
