import React, { PropsWithChildren, useContext, useMemo, useState } from 'react';

interface IBinaryTransfer {
  transferData: File[] | null;
  setTransferData: (file: File[] | null) => void;
}

const BinaryTransfer = React.createContext<IBinaryTransfer>({
  setTransferData: () => {
    //
  },
  transferData: null,
});

export const useBinaryTransfer = () => {
  return useContext(BinaryTransfer);
};

export const BinaryTransferProvider: React.FC<PropsWithChildren<unknown>> = (
  props
) => {
  const [transferData, setTransferData] = useState<File[] | null>(null);
  const val = useMemo(
    () => ({
      transferData,
      setTransferData,
    }),
    [transferData]
  );
  return (
    <BinaryTransfer.Provider value={val}>
      {props.children}
    </BinaryTransfer.Provider>
  );
};
