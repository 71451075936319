import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  IWordLetterTemplatePickerProps,
  WordLetterTemplatePicker,
} from '@work4all/components/lib/components/entity-picker/word-letter-template-picker/WordLetterTemplatePicker';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

import { EntityPickerActions } from './EntityPickerActions';

interface WordLetterTemplatePickerFieldProps
  extends Omit<IWordLetterTemplatePickerProps<false>, 'multiple'> {
  error?: string;
}

export const WordLetterTemplatePickerField = React.forwardRef<
  HTMLDivElement,
  WordLetterTemplatePickerFieldProps
>(function WordLetterTemplatePickerField(props, ref) {
  const { error, ...pickerProps } = props;
  const { value, onChange } = pickerProps;

  const { t } = useTranslation();

  const popoverRef = useRef<EntityPickerPopover>(null);

  return (
    <EntityPickerPopover
      ref={popoverRef}
      picker={<WordLetterTemplatePicker {...pickerProps} multiple={false} />}
      footer={
        <EntityPickerActions
          entity={Entities.wordLetterTemplate}
          multiple={false}
          data={props.data}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(value: any) => {
            props.onChange(value);
            popoverRef.current?.close();
          }}
        />
      }
    >
      <PickerTargetButton
        ref={ref}
        value={value?.body ?? ''}
        label={t(`COMMON.WORDLETTERTEMPLATE`)}
        error={error}
        onClear={() => {
          onChange(null);
        }}
      />
    </EntityPickerPopover>
  );
});
