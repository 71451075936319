import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DateFormatPreset,
  formatDateString,
} from '@work4all/utils/lib/date-utils/formatDateString';

import { DatePicker } from '../../../../../../input/date-picker';
import { useMobiscrollLanguage } from '../../../../../../utils/use-mobiscroll-language/use-mobiscroll-language';
import { withExtras } from '../../utils';

import { PreviewPickerInput } from './PreviewPickerInput';

export const DatePreviewPickerInput = withExtras<string>(
  ({
    value,
    onChange,
    showTime,
    dateFormatPreset = DateFormatPreset.DATE_SIMPLE_2YEAR,
    ...rest
  }) => {
    const language = useMobiscrollLanguage();
    const { t } = useTranslation();

    const formattedValue = useMemo(() => {
      const date = formatDateString(value, dateFormatPreset);

      if (showTime) {
        return t('COMMON.DATE.TIME.VALUE', {
          date,
          time: formatDateString(value, DateFormatPreset.TIME_SIMPLE),
        });
      }

      return date;
    }, [showTime, t, value, dateFormatPreset]);

    return (
      <PreviewPickerInput<string>
        {...rest}
        value={value}
        formattedValue={formattedValue}
        picker={
          <DatePicker
            locale={language}
            controls={['calendar']}
            defaultValue={formatDateString(value, dateFormatPreset)}
            onChange={(val) => {
              onChange(val?.value);
            }}
          />
        }
        onChange={onChange}
      />
    );
  }
);
