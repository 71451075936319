import { DateTime } from 'luxon';

import { triggerOnChange } from '@work4all/utils/lib/triggerOnChange';

/**
 * set value to input and trigger onChange event.
 */
export function triggerChangeEvent(
  fieldValue: DateTime,
  fieldInstance: HTMLInputElement,
  date?: DateTime,
  time?: DateTime,
  customFormat?: string
) {
  let value = '';
  let dateObject = fieldValue?.toObject();
  if (date) {
    dateObject = {
      ...dateObject,
      day: date.day,
      month: date.month,
      year: date.year,
    };

    if (time) {
      dateObject = {
        ...dateObject,
        hour: time.hour,
        minute: time.minute,
      };
    }

    const dateTime = DateTime.fromObject(dateObject);
    if (customFormat) {
      value = dateTime.toFormat(customFormat);
    } else {
      value = dateTime.toISO();
    }
  }

  triggerOnChange(fieldInstance, value);
}
