import styles from './CommentEditor.module.scss';

import { useTranslation } from 'react-i18next';

import { useUser } from '@work4all/data';
import { useTenant } from '@work4all/data/lib/hooks/routing/TenantProvider';

import { TextEditor } from '../../../../input/format-text/TextEditor';
import { TextEditorConfig } from '../../../../input/format-text/TextEditor/types';

interface CommentEditorProps {
  value: string;
  setValue: (value: string) => void;
  canAddOrInsertImage: boolean;
  editorConf?: TextEditorConfig;
  hideToolbar?: boolean;
  disabled?: boolean;
}

export const CommentEditor = ({
  value,
  setValue,
  canAddOrInsertImage,
  editorConf,
  hideToolbar,
  disabled,
}: CommentEditorProps) => {
  const { activeTenant } = useTenant();
  const user = useUser();
  const generateFileUploadUrl = (baseUrl: string, authToken: string) => {
    return `${baseUrl}/api/file/inlinemailimage?auth=${authToken}`;
  };

  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <TextEditor
        disabled={disabled}
        value={value}
        onChange={setValue}
        enableMentions
        enableTextBuildingBlocks={true}
        config={{
          placeholderText: t('MASK.ADD_COMMENT'),
          requestHeaders: {
            'x-work4all-mandant': activeTenant,
          },
          imageUpload: true,
          imageUploadURL: generateFileUploadUrl(user.baseUrl, user.token),
          height: 43,
          heightMin: 150,
          heightMax: 300,
          ...editorConf,
        }}
        ignoreButtons={canAddOrInsertImage ? undefined : ['insertImage']}
        imagePaste={canAddOrInsertImage}
        hideToolbar={hideToolbar}
      />
    </div>
  );
};
