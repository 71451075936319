import './utils/setup-pdfjs-worker';

import 'pdfjs-dist/legacy/web/pdf_viewer.css';
import styles from './PDFPreview.module.scss';

import { LinearProgress } from '@mui/material';
import * as ReactSentry from '@sentry/react';
import { useGesture } from '@use-gesture/react';
import { useSnackbar } from 'notistack';
import * as pdfjs from 'pdfjs-dist/legacy/build/pdf';
import { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import ResizeObserver from 'resize-observer-polyfill';

import { useUser } from '@work4all/data';
import { useAuthHeaders } from '@work4all/data/lib/auth/use-auth-headers';

import { formatErrorDetails } from '@work4all/utils/lib/formatErrorDetails';
import { getDebugInfoParts } from '@work4all/utils/lib/getDebugInfoParts';

import { SentryActions } from '../../components/snackbar/actions';
import { PDFTextmarkContainer } from '../pdf-textmarks/PDFTextmarkContainer';

import { MAX_SCALE, SCALE_MODIFIER } from './constants';
import { IPDFPreviewProps } from './types';
import { usePDFViewerApi } from './use-pdf-viewer-api';

export function PDFPreview({
  url,
  enableTextLayer = false,
  register,
  scale,
  onScaleChange,
  initialScale = 1,
  initalTranslate = '',
  pdfTextmarkConf,
}: IPDFPreviewProps) {
  const user = useUser();
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [savedScale, setSavedScale] = useState(initialScale);
  const { api, minScale } = usePDFViewerApi({
    container: containerRef,
    viewer: contentRef,
    enableTextLayer,
  });

  const [pdf, setPdf] = useState<PDFDocumentProxy | null>(null);

  const customerNumber = user?.kundennummer;

  const debugInfoData = useMemo(() => {
    return getDebugInfoParts({ customerNumber });
  }, [customerNumber]);

  useEffect(() => {
    if (register && minScale !== undefined) {
      return register({
        minScale: minScale,
        maxScale: MAX_SCALE,
        scaleFactor: SCALE_MODIFIER,
      });
    }
  }, [register, minScale]);

  // Save the callback in a ref, so we don't have to add it
  // to dependencies when using hooks
  const onScaleChangeRef = useRef(onScaleChange);

  useEffect(() => {
    onScaleChangeRef.current = onScaleChange;
  }, [onScaleChange]);

  // If component is in controlled mode (scale prop is provided)
  // update the local state to match the prop value.
  useEffect(() => {
    if (api && scale !== undefined && savedScale !== scale) {
      if (scale === 'fit') {
        const resolvedScale = api.autosize();
        setSavedScale(resolvedScale);
        onScaleChangeRef.current?.(resolvedScale);
      } else {
        api.setScale(scale);
        setSavedScale(scale);
      }
    }
  }, [api, savedScale, scale]);

  const httpHeaders = useAuthHeaders();
  const httpHeadersRef = useRef(httpHeaders);

  useEffect(() => {
    httpHeadersRef.current = httpHeaders;
  }, [httpHeaders]);

  const [worker, setWorker] = useState<pdfjs.PDFWorker>(null);

  useEffect(() => {
    const worker = new pdfjs.PDFWorker();
    setWorker(worker);

    return () => {
      worker.port?.terminate();
    };
  }, []);

  useEffect(() => {
    if (worker && api) {
      let cancelled = false;

      const task = pdfjs.getDocument({
        worker,
        url,
        httpHeaders: httpHeadersRef.current,
      });

      setLoading(true);
      task.promise
        .then((pdf) => {
          if (!cancelled) {
            setPdf(pdf);
          }
          setLoading(false);
        })
        .catch((e) => {
          const eventId = ReactSentry.captureException(e);
          const errorDetails = formatErrorDetails(eventId, debugInfoData);
          enqueueSnackbar(t('PDF_PREVIEW.LOADING_ERROR'), {
            variant: 'error',
            autoHideDuration: 6000,
            action: (key) => (
              <SentryActions
                eventId={eventId}
                debugInfoData={errorDetails}
                onClose={() => closeSnackbar(key)}
              />
            ),
          });

          setLoading(false);
        });

      return () => {
        cancelled = true;
        api.viewer.setDocument(null);
      };
    }
  }, [worker, api, url, enqueueSnackbar, t, closeSnackbar, debugInfoData]);

  useEffect(() => {
    if (api) {
      let cancelled = false;

      api.viewer.setDocument(pdf);

      const { firstPagePromise } = api.viewer;

      firstPagePromise?.then(() => {
        if (!cancelled) {
          const newScale = api.autosize();
          setSavedScale(newScale);
          onScaleChangeRef.current?.(newScale);
        }
      });

      return () => {
        cancelled = true;
      };
    }
  }, [api, pdf]);

  useEffect(() => {
    if (api) {
      const observer = new ResizeObserver(function handleResize() {
        const newScale = api.autosize();
        setSavedScale(newScale);
        onScaleChangeRef.current?.(newScale);
      });
      observer.observe(wrapperRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [api]);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const [pinchState, setPinchState] = useState<PinchState | null>({
    origin: { x: 0, y: 0 },
    scale: initialScale,
  });

  useGesture(
    {
      onPinchStart: (state) => {
        if (state.type !== 'wheel') {
          const [originX, originY] = state.origin;

          const content = contentRef.current;

          const { left, top } = content.getBoundingClientRect();

          const x = originX - left;
          const y = originY - top;

          setPinchState({ origin: { x, y }, scale: 0.6 });
        }
      },
      onPinch: (state) => {
        function handleWheel(event: WheelEvent) {
          if (state.active) {
            const container = containerRef.current;

            const [originX, originY] = state.origin;

            const { left, top } = container.getBoundingClientRect();

            const x = originX - left;
            const y = originY - top;

            const { scrollLeft, scrollTop, scrollWidth, scrollHeight } =
              container;

            const distance = event.deltaY;

            // If distance > 0 we are scrolling down (zoom out)
            const newScale = distance > 0 ? api.zoomOut() : api.zoomIn();

            // Restore scroll to the original position to keep the mouse cursor
            // in the same spot in the document after changing scale
            container.scrollTop =
              (container.scrollHeight / scrollHeight) * (y + scrollTop) - y;
            container.scrollLeft =
              (container.scrollWidth / scrollWidth) * (x + scrollLeft) - x;

            ReactDOM.unstable_batchedUpdates(() => {
              setSavedScale(newScale);
              onScaleChangeRef.current?.(newScale);
            });
          }
        }

        function handleDefault() {
          const {
            movement: [scale],
          } = state;

          setPinchState((state) => ({ ...state, scale }));
        }

        if (state.event instanceof WheelEvent) {
          handleWheel(state.event);
        } else {
          handleDefault();
        }
      },
      onPinchEnd: (state) => {
        if (state.type !== 'wheel') {
          const {
            movement: [scale],
          } = state;

          const container = containerRef.current;

          // Restore scroll to the original position to keep the gesture origin
          // on the same place on the screen after changing scale

          const { x, y } = pinchState.origin;

          const positionX = x / container.scrollWidth;
          const positionY = y / container.scrollHeight;

          const offsetX = x - container.scrollLeft;
          const offsetY = y - container.scrollTop;

          setPinchState(null);

          const newScale = api.setScale(savedScale * scale);

          const scrollTop = container.scrollHeight * positionY - offsetY;
          const scrollLeft = container.scrollWidth * positionX - offsetX;

          container.scrollTop = scrollTop;
          container.scrollLeft = scrollLeft;

          ReactDOM.unstable_batchedUpdates(() => {
            setSavedScale(newScale);
            onScaleChangeRef.current?.(newScale);
          });
        }
      },
    },
    {
      eventOptions: { passive: false },
      target: containerRef,
    }
  );

  const { isDragging, onMouseDown } = useMouseDrag({
    onDrag: ({ movementX, movementY }) => {
      const container = containerRef.current;

      if (container) {
        container.scrollLeft -= movementX;
        container.scrollTop -= movementY;
      }
    },
  });

  return (
    <div ref={wrapperRef} className={styles.wrapper}>
      {loading && <LinearProgress />}
      <div ref={containerRef} className={styles['container']}>
        <div
          className={styles['viewer-wrapper']}
          onMouseDown={
            !pdfTextmarkConf?.pdfTextMarkItems ? onMouseDown : undefined
          }
          style={isDragging ? { userSelect: 'none', cursor: 'grab' } : null}
        >
          <div
            ref={contentRef}
            className={styles['viewer']}
            style={getWrapperStyle(pinchState, initalTranslate)}
          ></div>
          <div className={styles.disableInteractionWrap}></div>
          {pdfTextmarkConf?.pdfTextMarkItems ? (
            <PDFTextmarkContainer pdf={pdf} {...pdfTextmarkConf} />
          ) : undefined}
        </div>
      </div>
    </div>
  );

  type PinchState = {
    origin: {
      x: number;
      y: number;
    };
    scale: number;
  };

  function getWrapperStyle(
    state: PinchState,
    translate?: string
  ): React.CSSProperties {
    if (!state) {
      return null;
    }

    const {
      origin: { x, y },
      scale,
    } = state;

    return {
      transformOrigin: `${x}px ${y}px`,
      transform: `scale(${scale}) ${translate}`,
    };
  }
}

function useMouseDrag({
  onDrag,
}: {
  onDrag: (data: { movementX: number; movementY: number }) => void;
}) {
  const onDragRef = useRef(onDrag);

  const [active, setActive] = useState(false);

  useEffect(() => {
    onDragRef.current = onDrag;
  }, [onDrag]);

  function handleMouseDown(event: React.MouseEvent) {
    if (event.button === 0) {
      setActive(true);

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }

    function handleMouseMove(event: MouseEvent) {
      onDragRef.current?.({
        movementX: event.movementX,
        movementY: event.movementY,
      });
    }

    function handleMouseUp() {
      setActive(false);

      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    }
  }

  return { isDragging: active, onMouseDown: handleMouseDown };
}
