import { useTranslation } from 'react-i18next';

import { FixedDataPicker } from '../fixed-data-picker/FixedDataPicker';
import { IPickerProps } from '../types';

export enum ReportType {
  Simple = 'Simple',
  CrystalReports = 'CrystalReports',
}

export type ReportTypePickerProps<TMultiple extends boolean> = IPickerProps<
  { id: ReportType; name: string },
  TMultiple
>;

export function ReportTypePicker<TMultiple extends boolean>(
  props: ReportTypePickerProps<TMultiple>
) {
  const { ...rest } = props;

  const { t } = useTranslation();

  const dataSet = [
    {
      id: ReportType.Simple,
      name: t('COMMON.REPORT_TYPE.' + ReportType.Simple),
    },
    {
      id: ReportType.CrystalReports,
      name: t('COMMON.REPORT_TYPE.' + ReportType.CrystalReports),
    },
  ];

  return <FixedDataPicker dataSet={dataSet} {...rest} />;
}
