import React from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  IPartialInvoiceCalculationLogicPickerProps,
  PartialInvoiceCalculationLogicPicker,
} from '@work4all/components/lib/components/entity-picker/partial-invoice-calculation-logic-picker/PartialInvoiceCalculationLogicPicker';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

interface Props
  extends Omit<IPartialInvoiceCalculationLogicPickerProps<false>, 'multiple'> {
  error?: string;
}

export const PartialInvoiceCalculationLogicPickerField = React.forwardRef<
  HTMLDivElement,
  Props
>((props, ref) => {
  const { t } = useTranslation();
  const { error, ...pickerProps } = props;
  const { value, onChange } = pickerProps;

  return (
    <EntityPickerPopover
      picker={
        <PartialInvoiceCalculationLogicPicker
          {...pickerProps}
          multiple={false}
        />
      }
    >
      <PickerTargetButton
        ref={ref}
        onClear={() => {
          onChange(null);
        }}
        label={t('INPUTS.PARTIAL_INVOICE_CALCULATION_LOGIC')}
        error={error}
        value={value?.name || ''}
      />
    </EntityPickerPopover>
  );
});
