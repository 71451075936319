import styles from './PositionGroupsTree.module.scss';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  TreeItem,
  TreeItemContentProps,
  TreeView,
  useTreeItem,
} from '@mui/lab';
import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import {
  ForwardedRef,
  forwardRef,
  JSXElementConstructor,
  PropsWithoutRef,
  useMemo,
  useRef,
} from 'react';
import { useDrag } from 'react-dnd';

import { ReactComponent as DragIndicatorIcon } from '@work4all/assets/icons/drag-vertical.svg';

import { TABLE_ROW_DRAG_DROP_KEY } from '@work4all/components/lib/dataDisplay/basic-table/components/row-render/components/row/Row';

import { useDataProvider } from '@work4all/data';

import { Group } from '@work4all/models/lib/Classes/Group.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export type IPositionGroupsTreeProps = {
  onSelect?: (ids: number[]) => void;
  onNodeDoubleClick?: (e) => void;
  disabled: boolean;
};

export const PositionGroupsTree = (props: IPositionGroupsTreeProps) => {
  const { onSelect, onNodeDoubleClick, disabled } = props;

  const { data: groups } = useArticleGroups();
  const { tree, getIdsWithChildren } = useMemo(() => {
    const rootGroup = groups.find((group) => group.id === 0) ?? {
      id: 0,
      index: -1,
      name: 'All',
      parentId: -1,
    };

    const allGroups = groups.includes(rootGroup)
      ? groups
      : [rootGroup, ...groups];

    function mapByParentId(groups: Group[]): Map<number, Group[]> {
      const map = new Map<number, Group[]>();

      for (const group of groups) {
        let items = map.get(group.parentId);

        if (!items) {
          items = [];
          map.set(group.parentId, items);
        }

        items.push(group);
      }

      return map;
    }

    const groupsByParentId = mapByParentId(allGroups);

    function mapGroup(
      group: Group,
      parent: ArticleGroupTree | null
    ): ArticleGroupTree {
      const tree: ArticleGroupTree = {
        id: group.id,
        name: group.name,
        parent: parent,
        children: null,
      };

      const children = groupsByParentId.get(group.id);

      if (children) {
        tree.children = children.map((group) => mapGroup(group, tree));
      }

      return tree;
    }

    const tree = mapGroup(rootGroup, null);

    function getIdsWithChildren(id: number): number[] {
      const children = groupsByParentId.get(id);

      if (!children) {
        return [id];
      }

      return [id, ...children.flatMap((group) => getIdsWithChildren(group.id))];
    }

    return { tree, getIdsWithChildren };
  }, [groups]);

  const handleSelect = (_event: React.SyntheticEvent, nodeId: string) => {
    if (onSelect) {
      const idsWithChildren = getIdsWithChildren(Number(nodeId));
      onSelect(idsWithChildren);
    }
  };

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      defaultExpanded={['0']}
      defaultSelected={'0'}
      onNodeSelect={handleSelect}
      onDoubleClick={onNodeDoubleClick}
    >
      <PositionGroupsTreeItem group={tree} disabled={disabled} />
    </TreeView>
  );
};

const PositionGroupsTreeItem = ({
  group,
  disabled,
}: {
  group: ArticleGroupTree;
  disabled: boolean;
}) => {
  const dragPreviewRef = useRef<HTMLDivElement | null>(null);

  const [_, dragRef, preview] = useDrag(() => ({
    type: TABLE_ROW_DRAG_DROP_KEY,
    item: group,
  }));
  preview(dragPreviewRef);

  return (
    <TreeItem
      ref={dragPreviewRef}
      key={group.id}
      ContentComponent={
        GroupTreeItemContent as JSXElementConstructor<TreeItemContentProps>
      }
      classes={{
        root: styles.treeItem,
        content: styles.content,
        group: styles.group,
        iconContainer: styles.iconContainer,
        focused: styles.focused,
        selected: styles.selected,
        label: styles.label,
      }}
      onFocusCapture={(e) => e.stopPropagation()}
      nodeId={String(group.id)}
      label={
        <Box display="flex" justifyContent="space-between">
          <Typography>{group.name}</Typography>
          {disabled ? null : (
            <div ref={dragRef} className={styles['drag-handler']}>
              <DragIndicatorIcon />
            </div>
          )}
        </Box>
      }
    >
      {Array.isArray(group.children)
        ? group.children.map((node) => (
            <PositionGroupsTreeItem
              key={node.id}
              group={node}
              disabled={disabled}
            />
          ))
        : null}
    </TreeItem>
  );
};

const GroupTreeItemContent = forwardRef(function GroupTreeItemContent(
  props: PropsWithoutRef<TreeItemContentProps>,
  ref: ForwardedRef<HTMLDivElement>
) {
  const {
    classes,
    className,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
  } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
  } = useTreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;

  const handleSelectionClick: React.MouseEventHandler = (event) => {
    handleSelection(event);
  };

  const handleExpansionClick: React.MouseEventHandler = (event) => {
    handleExpansion(event);
    event.stopPropagation();
  };

  return (
    <div
      ref={ref}
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled,
      })}
      onClick={handleSelectionClick}
    >
      <div className={classes.iconContainer} onClick={handleExpansionClick}>
        {icon}
      </div>
      <Typography className={classes.label} component="div">
        {label}
      </Typography>
    </div>
  );
});

const ARTICLE_GROUP_FIELDS: Group = {
  id: null,
  name: null,
  parentId: null,
};

const ARTICLE_GROUPS_DATA_REQUEST: DataRequest = {
  entity: Entities.group,
  data: ARTICLE_GROUP_FIELDS,
  completeDataResponse: true,
};

function useArticleGroups() {
  return useDataProvider<Group>(ARTICLE_GROUPS_DATA_REQUEST);
}

export type ArticleGroupTree = {
  id: number;
  name: string;
  parent: ArticleGroupTree | null;
  children: ArticleGroupTree[] | null;
};
