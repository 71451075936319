import styles from './Row.module.scss';

import { Check } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { Fragment, memo, useMemo, useRef } from 'react';
import { useDrag } from 'react-dnd';
import { useTranslation } from 'react-i18next';

import { ReactComponent as DragIndicatorIcon } from '@work4all/assets/icons/drag-vertical.svg';

import { CardConfig } from '@work4all/models/lib/table-schema/card-config';

import { useTableStateBag } from '../../../../hooks/useTableStateBag';
import { EditModeCell } from '../../../../plugins/useEditMode';
import { BasicTableColumn, ICssClasses, TableRow } from '../../../../types';
import { TableCell } from '../../../table-cell/TableCell';
import { TableRow as TableRowComponent } from '../../../table-row/TableRow';
import { accesByString } from '../../../utils';
import { useDraggableRow } from '../../hooks/use-draggable-row';
import { Grouped } from '../grouped/Grouped';

import { reportMissingColumn } from './report-missing-column';

export const TABLE_ROW_DRAG_DROP_KEY = 'TABLE_ROW_DRAG_DROP';

interface IRowProps {
  cardsView: boolean;
  cardConfig: CardConfig | null;
  row: TableRow;
  className?: string;
  classes?: ICssClasses;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;

  [key: string]: unknown;
}

export const Row = memo(function Row(props: IRowProps) {
  const { row, classes, cardsView = false, cardConfig: card } = props;
  const cellClassName = classes?.cell;
  const { t } = useTranslation();

  const cellsById = useMemo(() => {
    return new Map(row.allCells?.map((cell) => [cell.column.id, cell]));
  }, [row.allCells]);

  const availableInfoRows = useMemo(() => {
    return (
      card?.info?.filter((info) => {
        const cell = row.cells.find((cell) => cell.column.id === info.key);

        return (
          !!cell?.column.isVisible &&
          row?.original &&
          ((!!info?.type &&
            info.columns.some((id) => cellsById.get(id)?.value)) ||
            info.columns.find((colId) => {
              const val = accesByString(row.original, colId);
              return val && val !== '';
            }))
        );
      }) || []
    );
  }, [card?.info, cellsById, row.cells, row.original]);

  const { underPressSelect, setUnderPressSelect } = useTableStateBag() ?? {};

  const { draggable } = useDraggableRow();

  const dragPreviewRef = useRef<HTMLDivElement>(null);

  const [_, dragRef, preview] = useDrag(() => ({
    type: TABLE_ROW_DRAG_DROP_KEY,
    item: row.original,
  }));

  preview(dragPreviewRef);

  if (row.isGrouped) {
    return (
      <Grouped cellClassName={cellClassName} depth={row.depth} row={row} />
    );
  }

  function cell(id?: string, userProps?: object): React.ReactNode {
    if (id === undefined) {
      return null;
    }

    const column = cellsById.get(id);

    if (!column) {
      reportMissingColumn(id);
    }

    return column?.render('Cell', userProps);
  }

  function cells(ids?: string[]): React.ReactNode {
    if (ids === undefined) {
      return null;
    }

    ids.forEach((id) => {
      if (!cellsById.has(id)) {
        reportMissingColumn(id);
      }
    });

    return ids
      .filter((id) => Boolean(cellsById.get(id)?.value))
      .map((id) => cell(id))
      .filter(Boolean)
      .flatMap((content, index) => {
        const node = (
          <Box
            key={index}
            component="div"
            style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
            sx={{ '&:empty::before': { content: '"-"' } }}
          >
            {content}
          </Box>
        );

        if (index === 0) {
          return [node];
        }

        return [<div key={`separator-${index}`}> | </div>, node];
      });
  }

  if (cardsView && card !== null) {
    return (
      <TableRowComponent
        onClick={props.onClick}
        className={props.classes?.row}
        isSelected={row.isSelected}
        displayModifiers={row.displayModifiers}
        cardsView={true}
      >
        <TableCell
          cardsView={cardsView}
          component="button"
          columnId="none"
          style={{ gridArea: 'icon', height: 'auto' }}
          onClick={(e) => {
            e.preventDefault();
            if (!underPressSelect && setUnderPressSelect)
              setUnderPressSelect(true);
            row.toggleRowSelected();
          }}
        >
          {underPressSelect && row.isSelected ? (
            <div className={styles.selectedIcon}>
              <Check />
            </div>
          ) : (
            <div
              style={{
                filter: underPressSelect ? 'grayscale(100%)' : 'none',
              }}
            >
              {cell(card.icon, { placeholder: true })}
            </div>
          )}
        </TableCell>

        <TableCell
          cardsView={cardsView}
          columnId="none"
          style={{ gridArea: 'title', minWidth: 0 }}
        >
          {cell(card.title)}
        </TableCell>

        {card.content && (
          <TableCell
            cardsView={true}
            columnId="none"
            style={{ gridArea: 'content', minWidth: 0, paddingTop: 0 }}
          >
            <Typography
              variant="caption"
              sx={{
                overflowWrap: 'anywhere',
                whiteSpace: 'normal',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                color: 'unset',
              }}
            >
              {cell(card.content)}
            </Typography>
          </TableCell>
        )}

        <TableCell
          cardsView={true}
          columnId="none"
          style={{
            gridArea: 'info',
            height: 'auto',
            minWidth: 0,
            paddingTop: 0,
          }}
        >
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'auto 1fr',
              gap: '0 0.5rem',
            }}
          >
            {availableInfoRows.map((info) => {
              return (
                <Fragment key={info.key}>
                  <Typography
                    key={`${info.key}_title`}
                    variant="caption"
                    component="div"
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: 'flex',
                      gap: '.25rem',
                      '&:empty::before': { content: '"-"' },
                    }}
                  >
                    {t((info.title as string).trim())}:
                  </Typography>

                  <Typography
                    key={`${info.key}_value`}
                    variant="caption"
                    component="div"
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: 'flex',
                      gap: '.25rem',
                      '&:empty::before': { content: '"-"' },
                    }}
                  >
                    {cells(info.columns)}
                  </Typography>
                </Fragment>
              );
            })}
          </div>
        </TableCell>

        {card.secondaryIcons.length > 0 && (
          <TableCell
            cardsView={true}
            columnId="none"
            style={{ gridArea: 'secondary-icons', height: 'auto' }}
          >
            <div className={styles.secondaryIcons}>
              {card.secondaryIcons.slice(0, 3).map((columnId) => {
                return <Fragment key={columnId}>{cell(columnId)}</Fragment>;
              })}
            </div>
          </TableCell>
        )}
      </TableRowComponent>
    );
  }

  return (
    <TableRowComponent
      ref={draggable ? dragPreviewRef : undefined}
      onClick={props.onClick}
      className={clsx(props.classes?.row, styles.row)}
      isSelected={row.isSelected}
      displayModifiers={row.displayModifiers}
    >
      {draggable && (
        <div
          ref={draggable ? dragRef : undefined}
          role="cell"
          className={styles['drag-handler']}
        >
          <DragIndicatorIcon />
        </div>
      )}

      {row.cells.map((cell, idx) => {
        const isGrouped = cell.isGrouped || cell.column.isGrouped;

        /**
         * Exclude grouped columns from result.
         * Data of grouped column should be shown in "grouped row" ui element
         */
        if (isGrouped) {
          return null;
        }

        const { dataType, alignment, noFlex } = cell.column as BasicTableColumn;

        const commonCellProps = {
          key: idx,
          className: cellClassName,
          dataType,
          columnId: cell.column.id,
          alignment,
          noFlex,
          ...cell.getCellProps(),
        };
        if (cell.isAggregated) {
          <TableCell {...commonCellProps}>
            {cell.render('Aggregated')}
          </TableCell>;
        }

        if (cell.isPlaceholder) {
          return <TableCell {...commonCellProps} />;
        }

        if ((cell as unknown as EditModeCell).isEditMode) {
          return (
            <TableCell {...commonCellProps}>
              {cell.render('EditableCell')}
            </TableCell>
          );
        }

        return (
          <TableCell {...commonCellProps}>{cell.render('Cell')}</TableCell>
        );
      })}
    </TableRowComponent>
  );
});
