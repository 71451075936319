import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { PromptResult } from '../Enums/PromptResult.enum';
import { PromptType } from '../Enums/PromptType.enum';

export class InputPromptResult<T extends EMode = EMode.entity> {
  /** Alias: type */
  type?: PromptType;
  /** Alias: result */
  result?: PromptResult;

  __typename?: string;
}

const fields: FieldDefinitions<InputPromptResult> = {
  type: {
    alias: 'type',
  },
  result: {
    alias: 'result',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputPromptResultEntityDefinition: EntitiyDefinition<InputPromptResult> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputPromptResult',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
