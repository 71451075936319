import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';

import { Tenant } from '@work4all/models/lib/Classes/Tenant.entity';

import { getTenantFromPath, useUser } from '../../..';
import { changeTenant } from '../../actions/user-actions';

const TenantContext = React.createContext<{
  activeTenant?: number;
  setActiveTenant?: (t: number) => void;
  tenants?: Tenant[];
  activeTenantData?: Tenant;
}>({});

export const TenantProvider: React.FC<PropsWithChildren<unknown>> = (props) => {
  const user = useUser();
  const dispatch = useDispatch();
  const [activeTenant, setActiveTenant] = useState<number>(null);

  useEffect(() => {
    dispatch(changeTenant(activeTenant));
  }, [dispatch, activeTenant]);

  useEffect(() => {
    if (!user) {
      //if we loose the user object reset the tenant, as the next user that logs in may have different tenants after all
      setActiveTenant(null);
    }
  }, [user]);

  useEffect(() => {
    //if no active tenant has been set yet, we use the one that was stored last on the userobject
    if (typeof activeTenant !== 'number') {
      const defaultToTenant = user?.mandanten[0].code ?? null;
      setActiveTenant(defaultToTenant);
      dispatch(changeTenant(defaultToTenant));
    }
  }, [activeTenant, dispatch, user?.mandanten]);

  const verifyActiveTenant = useCallback(
    (path: string) => {
      const urlBasedTenant = getTenantFromPath(path);
      if (
        user &&
        urlBasedTenant &&
        urlBasedTenant.tenant !== undefined &&
        activeTenant !== urlBasedTenant.tenant
      ) {
        setActiveTenant(urlBasedTenant.tenant);
      }
    },
    [activeTenant, dispatch, user]
  );

  useEffect(() => {
    //we get the tenant from the location
    //todo how to properly listen to locationchanges too
    verifyActiveTenant(window.location.pathname);
  }, [verifyActiveTenant]);

  const tenants = useMemo(() => {
    return (
      user?.mandanten.map((tenant) => {
        return {
          id: tenant.code,
          name: tenant.name,
          country: tenant.staat,
        };
      }) ?? []
    );
  }, [user?.mandanten]);

  const activeTenantData = useMemo(() => {
    return tenants.find((tenant) => tenant.id === activeTenant);
  }, [activeTenant, tenants]);

  return (
    <TenantContext.Provider
      value={{
        activeTenant,
        setActiveTenant,
        tenants,
        activeTenantData,
      }}
    >
      {props.children}
    </TenantContext.Provider>
  );
};
export const useTenant = () => {
  return useContext(TenantContext);
};
