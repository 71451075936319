export enum EMailTemplateKind {
  KEINE = 'KEINE',
  TICKET = 'TICKET',
  SALES_OPPORTUNITY = 'SALES_OPPORTUNITY',
  INVOICE_REMINDER = 'INVOICE_REMINDER',
  E_INVOICE_WARNING = 'E_INVOICE_WARNING',
  OFFER = 'OFFER',
  BOOKING = 'BOOKING',
  INVOICE = 'INVOICE',
  DELIVERY_NOTE = 'DELIVERY_NOTE',
  ORDER = 'ORDER',
  INQUIRY = 'INQUIRY',
  TICKET_FOLLOW_UP = 'TICKET_FOLLOW_UP',
  MAIL_NOTIFICATION = 'MAIL_NOTIFICATION',
  APPOINTMENT_NOTIFICATION = 'APPOINTMENT_NOTIFICATION',
  MFA_CODE = 'MFA_CODE',
  ERP_OBJECTS = 'ERP_OBJECTS',
}
