import styles from './AppointmentQuickCreateDialog.module.scss';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  Dialog,
  Grid,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Box } from '@mui/system';
import { partition } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useMemo, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ReactComponent as TeamsIcon } from '@work4all/assets/icons/teams.svg';

import { AppointmentStatePicker } from '@work4all/components/lib/components/entity-picker/appointmentStatePicker/AppointmentStatePicker';
import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';

import { useDataMutation, useUser } from '@work4all/data';
import { useEntityJsonSchema } from '@work4all/data/lib/json-schema/EntityJsonSchemasContext';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { AppointmentAttendee } from '@work4all/models/lib/Classes/AppointmentAttendee.entity';
import { AppointmentState } from '@work4all/models/lib/Classes/AppointmentState.entity';
import { ContactUnionWrapper } from '@work4all/models/lib/Classes/ContactUnionWrapper.entity';
import { InputTerminRelation } from '@work4all/models/lib/Classes/InputTerminRelation.entity';
import { ContactKind } from '@work4all/models/lib/Enums/ContactKind.enum';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useJSONSchemaResolver } from '@work4all/utils/lib/form-utils/jsonSchemaResolver';
import { PathsOf } from '@work4all/utils/lib/paths-of/paths-of';

import { useMsTeamsAvailable } from '../../../hooks';
import { settings, useSetting } from '../../../settings';
import { ControllerPlus } from '../../mask-overlays/form-plus/controller-plus';
import { useControllerPlus } from '../../mask-overlays/form-plus/use-controller-plus';
import { useFormContextPlus } from '../../mask-overlays/form-plus/use-form-context-plus';
import {
  Chip,
  DateTimeInputPicker,
  LabeledInput,
  PickerTargetButton,
} from '../../mask-overlays/locked-inputs';
import { CheckboxRadioItem } from '../../mask-overlays/locked-inputs';
import {
  ControlWrapper,
  Form,
} from '../../mask-overlays/mask-overlay/components';
import {
  MaskContextProvider,
  useMaskContextValue,
} from '../../mask-overlays/mask-overlay/hooks/mask-context';
import { useFormUpdate } from '../../mask-overlays/mask-overlay/utils/use-form-update';
import { sortAttendees } from '../../mask-overlays/mask-overlay/views/appointment/AppointmentOverlayController';
import {
  ContactRessourcePicker,
  renderChipContent,
} from '../../mask-overlays/mask-overlay/views/appointment/components/tab-panels/general/components/contactpicker/ContactRessourcePicker';
import { AttendeesPicker } from '../../mask-overlays/mask-overlay/views/appointment/components/tab-panels/general/components/participant/attendee-picker/AttendeePicker';
import {
  isAttendee,
  isResource,
  isTheSameAttendee,
  mapAttendee,
  mapAttendeeContact,
  mapResourceContact,
} from '../../mask-overlays/mask-overlay/views/appointment/components/tab-panels/general/components/participant/utils';

export type AppointmentTemplate = Pick<
  Appointment,
  'appointmentAttendeeList' | 'startDate' | 'endDate' | 'isWholeDay'
>;

export interface AppointmentQuickCreateDialogProps
  extends AppointmentQuickCreateFormProps {
  open: boolean;
}

type AppointmentFormValue = PathsOf<Appointment>;

export function AppointmentQuickCreateDialog(
  props: AppointmentQuickCreateDialogProps
) {
  const { open, onClose, template, onOpenMask } = props;

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <AppointmentQuickCreateForm
        template={template}
        onClose={onClose}
        onOpenMask={onOpenMask}
      />
    </Dialog>
  );
}

interface AppointmentQuickCreateFormProps {
  template: AppointmentTemplate;
  onOpenMask: (appointment: Appointment) => void;
  onClose: () => void;
}

function AppointmentQuickCreateForm(props: AppointmentQuickCreateFormProps) {
  const { template, onOpenMask, onClose } = props;

  const { t } = useTranslation();

  const user = useUser();

  const [mutate] = useDataMutation<
    Appointment,
    EMode.upsert,
    InputTerminRelation
  >({
    entity: Entities.appointment,
    mutationType: EMode.upsert,
    responseData: { id: null },
    onCompleted: onClose,
  });

  const customRules = useCallback(
    (data) => {
      const attendees: AppointmentAttendee[] =
        data.appointmentAttendeeList || [];
      const hasAtLeastOneUser = attendees.find((el) => el.user || el.ressource);
      if (!hasAtLeastOneUser) {
        return {
          appointmentAttendeeList: {
            message: t('ERROR.MIN_ONE_USER_PER_APPOINTMENT'),
            type: 'customValidation',
          },
        };
      }
      return true;
    },
    [t]
  );

  const schema = useEntityJsonSchema(Entities.appointment);
  const resolver = useJSONSchemaResolver(schema, customRules);

  const msTeamsAvailable = useMsTeamsAvailable();

  const form = useForm<AppointmentFormValue>({
    resolver,
    mode: 'onChange',
    defaultValues: makeDefaultValues(template, msTeamsAvailable),
    context: {
      schema,
    },
  });

  const getReminderDate = useCallback(() => {
    const { isWholeDay, startDate } = form.watch();

    const reminder = DateTime.fromISO(startDate);
    let resultingRemindDate = null;
    if (isWholeDay) {
      resultingRemindDate = reminder.startOf('day').minus({ day: 1 }).toISO();
    } else {
      resultingRemindDate = reminder.minus({ minutes: 15 }).toISO();
    }
    return resultingRemindDate;
  }, [form]);

  useFormUpdate(
    {
      appointmentAttendeeList: (
        appointmentAttendeeList: AppointmentAttendee[]
      ) => {
        return {
          exchangeMeeting:
            msTeamsAvailable && appointmentAttendeeList.length > 1,
        };
      },
      appointmentState: (state: AppointmentState) => {
        return { colorId: state?.id || 0 };
      },
      startDate: () => {
        return { remindDate: getReminderDate() };
      },
      isWholeDay: () => {
        return { remindDate: getReminderDate() };
      },
    },
    form
  );

  const onSubmit = (data: Appointment) => {
    const appointment = { ...data };

    const appointmentAttendeeList =
      appointment.appointmentAttendeeList.length > 0
        ? { add: appointment.appointmentAttendeeList }
        : null;

    const userIsAttendee = appointment.appointmentAttendeeList.find(
      (attendee) => attendee.userId === user.benutzerCode
    );

    if (userIsAttendee) {
      appointment.userId = userIsAttendee.userId;
    }

    const relations = appointmentAttendeeList
      ? { appointmentAttendeeList }
      : null;

    return mutate(appointment, { relations });
  };

  const maskContext = useMaskContextValue({
    entity: Entities.appointment,
    isCreateMode: true,
    isEditMode: false,
    mode: 'create',
    wip: false,
    params: {},
  });

  return (
    <MaskContextProvider value={maskContext}>
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h4" p="1rem">
            {t('APPOINTMENT.CREATE')}
          </Typography>

          <Button
            size="large"
            startIcon={<EditIcon />}
            onClick={() => {
              onOpenMask(form.getValues());
            }}
          >
            {t('CALENDAR.EDIT_DETAILS')}
          </Button>
        </Stack>

        <FormProvider {...form}>
          <Form onSubmit={form.handleSubmit(onSubmit)}>
            <FormContent onClose={onClose} />
          </Form>
        </FormProvider>
      </Box>
    </MaskContextProvider>
  );
}

function makeDefaultValues(
  template: AppointmentTemplate,
  msTeamsAvailable: boolean
): Appointment {
  return {
    title: '',
    userId: 0,
    appointmentState: null,
    startDate: null,
    endDate: null,
    appointmentAttendeeList: [],
    isRealAppointment: true,
    exchangeMeeting:
      msTeamsAvailable && template.appointmentAttendeeList.length > 1,
    ...template,
    remind: true,
    remindDate: DateTime.fromISO(template.startDate)
      .minus({ minutes: 30 })
      .toISO(),
  };
}

export function FormContent({ onClose }: { onClose: () => void }) {
  const { t } = useTranslation();

  const { control, register, formState } =
    useFormContextPlus<AppointmentFormValue>();

  const isWholeDay = useWatch({ control, name: 'isWholeDay' });

  const stateWrapperRef = useRef<HTMLElement>(null);

  const { value: teamsSettingValue } = useSetting(settings.msTeams());
  const msTeamsAvailable = useMsTeamsAvailable();

  return (
    <>
      <Box p="1rem">
        <ControlWrapper grid columns={4}>
          <ControlWrapper paddingBottom={false} gridColumn="1 / 4">
            <LabeledInput {...register('title')} label={t('COMMON.SUBJECT')} />
          </ControlWrapper>

          <ControlWrapper paddingBottom={false} ref={stateWrapperRef}>
            <ControllerPlus
              control={control}
              name="appointmentState"
              render={({ field: appointmentState }) => {
                return (
                  <EntityPickerPopover
                    anchorEl={stateWrapperRef}
                    picker={
                      <AppointmentStatePicker
                        multiple={false}
                        value={appointmentState?.value}
                        onChange={appointmentState.onChange}
                      />
                    }
                  >
                    <PickerTargetButton
                      onClear={null}
                      value={appointmentState.value?.name || ''}
                      label={t('COMMON.STATUS')}
                    />
                  </EntityPickerPopover>
                );
              }}
            />
          </ControlWrapper>
        </ControlWrapper>

        <Participants />

        <ControlWrapper>
          <ControlWrapper>
            <DateTimeInputPicker
              dateLabel={t('COMMON.STARTDATE')}
              clearable={false}
              withTime={!isWholeDay}
              {...register('startDate')}
            />
            <DateTimeInputPicker
              dateLabel={t('COMMON.ENDDATE')}
              clearable={false}
              withTime={!isWholeDay}
              {...register('endDate')}
            />
          </ControlWrapper>
        </ControlWrapper>

        <ControlWrapper>
          <CheckboxRadioItem
            label={t('COMMON.WHOLEDAYEVENT')}
            defaultChecked={isWholeDay}
            fitContent
            {...register('isWholeDay')}
          />
        </ControlWrapper>

        <ControlWrapper>
          <ControllerPlus
            control={control}
            name="exchangeMeeting"
            render={({ field }) => (
              <CheckboxRadioItem
                disabled={!teamsSettingValue || !msTeamsAvailable}
                defaultChecked={field.value}
                label={t('COMMON.TEAMS_CREATE')}
                icon={<TeamsIcon />}
                fitContent
                {...register('exchangeMeeting')}
              />
            )}
          />
        </ControlWrapper>
      </Box>

      <Stack direction="row">
        <Button
          type="button"
          size="large"
          sx={{ flex: 1 }}
          onClick={onClose}
          disabled={formState.isSubmitting}
        >
          {t('ALERTS.BTN_ABORT')}
        </Button>

        <Button
          type="submit"
          size="large"
          sx={{ flex: 1 }}
          disabled={formState.isSubmitting}
        >
          {formState.isSubmitting
            ? t('ALERTS.BTN_SAVING')
            : t('ALERTS.BTN_SAVE')}
        </Button>
      </Stack>
    </>
  );
}

const RESOURCE_CONTACT_TYPES = [ContactKind.RESSOURCE];

// For the most part copied from apps/work4all/src/containers/mask-overlays/mask-overlay/views/appointment/components/tab-panels/general/components/participant/Participant.tsx
export function Participants() {
  const { t } = useTranslation();

  const upMd = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));

  const pickerAttendeeWrapperRef = useRef<HTMLDivElement>();
  const pickerAttendeePopoverRef = useRef<EntityPickerPopover>();
  const pickerResourceWrapperRef = useRef<HTMLDivElement>();
  const pickerResourcePopoverRef = useRef<EntityPickerPopover>();

  const { control } = useFormContextPlus<AppointmentFormValue>();

  function renderChipInput({
    label,
    contacts,
    onDelete,
    forceLineBreak = false,
    error,
  }: {
    label: string;
    contacts: ContactUnionWrapper[];
    onDelete: (contact: ContactUnionWrapper) => void;
    forceLineBreak: boolean;
    error: string;
  }) {
    const [userGroup, contactGroup] = partition(contacts, (ctc) =>
      [ContactKind.BENUTZER, ContactKind.MITARBEITER].includes(ctc.contactKind)
    );

    const userGroupEls =
      userGroup.length > 0
        ? userGroup.map((item) => {
            return (
              <Chip
                key={item.id}
                withSeperator={true}
                maxWidth={false}
                label={renderChipContent(item)}
                handleDelete={() => {
                  onDelete(item);
                }}
              />
            );
          })
        : undefined;
    const contactGroupEls =
      contactGroup.length > 0
        ? contactGroup.map((item) => {
            return (
              <Chip
                key={item.id}
                withSeperator={true}
                maxWidth={false}
                label={renderChipContent(item)}
                handleDelete={() => {
                  onDelete(item);
                }}
              />
            );
          })
        : undefined;

    return (
      <LabeledInput
        multiline={true}
        error={error}
        value={
          (userGroup.length || contactGroup.length) && forceLineBreak ? (
            <div>
              {userGroupEls?.length && (
                <div className={styles.chipsContainer}>{userGroupEls}</div>
              )}
              {contactGroupEls?.length && (
                <div className={styles.chipsContainer}>{contactGroupEls}</div>
              )}
            </div>
          ) : (
            <div className={styles.chipsContainer}>
              {userGroupEls}
              {contactGroupEls}
            </div>
          )
        }
        label={label}
      />
    );
  }

  const { field } = useControllerPlus({
    control,
    name: 'appointmentAttendeeList',
  });

  const { value, onChange } = field;

  const {
    attendees,
    resources,
    attendeeContacts,
    resourceContacts,
    handleChange,
    handleDelete,
  } = useMemo(() => {
    const all = Array.isArray(value) ? value : [];

    const attendees = all.filter(isAttendee);
    const resources = all.filter(isResource);

    if (attendees.length + resources.length !== all.length) {
      throw new Error(
        "Couldn't map attendee list to resource and attendee contacts."
      );
    }

    const attendeeContacts = attendees.map(mapAttendeeContact);
    const resourceContacts = resources.map(mapResourceContact);

    const handleChange = (
      originalAttendees: AppointmentAttendee[],
      fixedItems: AppointmentAttendee[]
    ) => {
      return (selected: ContactUnionWrapper[]) => {
        const mapped = selected.map((contact) => {
          const found = originalAttendees.find((resource) => {
            return isTheSameAttendee(resource, contact);
          });

          if (found) {
            return found;
          }

          return mapAttendee(contact);
        });

        onChange(sortAttendees([...fixedItems, ...mapped]));
      };
    };

    const handleDelete = (contact: ContactUnionWrapper) => {
      onChange(
        all.filter((original) => {
          return !isTheSameAttendee(original, contact);
        })
      );
    };

    return {
      attendees,
      resources,
      attendeeContacts,
      resourceContacts,
      handleDelete,
      handleChange,
    };
  }, [value, onChange]);

  return (
    <>
      <ControlWrapper
        ref={pickerAttendeeWrapperRef}
        className={styles.container}
        paddingBottom={attendeeContacts.length > 0}
      >
        <EntityPickerPopover
          ref={pickerAttendeePopoverRef}
          anchorEl={pickerAttendeeWrapperRef}
          autoclose={false}
          picker={
            <AttendeesPicker
              value={attendeeContacts}
              onChange={handleChange(attendees, resources)}
            />
          }
        >
          {attendeeContacts.length > 0 ? (
            renderChipInput({
              label: t('COMMON.PARTICIPANT'),
              contacts: attendeeContacts,
              onDelete: handleDelete,
              forceLineBreak: upMd,
              error: field.error,
            })
          ) : (
            <span></span>
          )}
        </EntityPickerPopover>
      </ControlWrapper>
      <ControlWrapper
        ref={pickerResourceWrapperRef}
        className={styles.container}
        paddingBottom={resourceContacts.length > 0}
      >
        <EntityPickerPopover
          ref={pickerResourcePopoverRef}
          anchorEl={pickerResourceWrapperRef}
          picker={
            <ContactRessourcePicker
              multiple={true}
              value={resourceContacts}
              contactKindList={RESOURCE_CONTACT_TYPES}
              onChange={handleChange(resources, attendees)}
              renderChipContent={renderChipContent}
            />
          }
        >
          {resourceContacts.length > 0 ? (
            renderChipInput({
              label: t('COMMON.RESOURCES'),
              contacts: resourceContacts,
              onDelete: handleDelete,
              forceLineBreak: upMd,
              error: field.error,
            })
          ) : (
            <span></span>
          )}
        </EntityPickerPopover>
      </ControlWrapper>
      <Grid container spacing={2}>
        <Grid item xs={'auto'}>
          {attendeeContacts.length === 0 && (
            <Button
              onClick={() => pickerAttendeePopoverRef.current?.open()}
              startIcon={<AddIcon />}
              size="large"
              sx={{ justifyContent: 'flex-start' }}
            >
              {t('COMMON.ADD_PARTICIPANT')}
            </Button>
          )}
        </Grid>
        <Grid item xs={'auto'}>
          {resourceContacts.length === 0 && (
            <Button
              onClick={() => pickerResourcePopoverRef.current?.open()}
              startIcon={<AddIcon />}
              size="large"
              sx={{ justifyContent: 'flex-start' }}
            >
              {t('COMMON.ADD_RESOURCE')}
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
}
