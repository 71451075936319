import EmailIcon from '@mui/icons-material/Email';
import React, { useCallback, useMemo, useRef, useState } from 'react';

import { EmailTemplatesPopover } from '@work4all/components';
import { CustomToolbarAction } from '@work4all/components/lib/dataDisplay/actions-bar/ToolbarTypes';
import { useEmailTemplates } from '@work4all/components/lib/hooks';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';

import { EMailTemplate } from '@work4all/models/lib/Classes/EMailTemplate.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { EmailTemplateButtonContextProps } from '../../../../containers/mask-overlays/components/email-template-button/EmailTemplateButtonProvider';
import { MaskControllerProps } from '../../../../containers/mask-overlays/mask-overlay';
import { EmailOverlayController } from '../../../../containers/mask-overlays/mask-overlay/views/email/EmailOverlayController';
import { settings, useSetting } from '../../../../settings';

interface UseEmailTemplateProps extends EmailTemplateButtonContextProps {}

export const useEmailTemplate = (props: UseEmailTemplateProps) => {
  const { noTemplate, kind, getEmailParams } = props;
  const previewContext = useRef<unknown>();
  const emailTemplatesRef = useRef<HTMLButtonElement>();
  const templates = useEmailTemplates(kind);

  const { value: standardTemplates, set: setStandardTemplate } = useSetting(
    settings.standardEmailTemplates()
  );

  const [isEmailTemplatesPopoverOpen, setIsEmailTemplatesPopoverOpen] =
    useState(false);

  const [emailProps, setEmailProps] = useState<MaskControllerProps>();

  const openEmailMask = useCallback(
    async (template: EMailTemplate | null) => {
      const { entityTemplate, params } = await getEmailParams(
        template,
        previewContext.current
      );

      setEmailProps({
        entity: Entities.eMail,
        id: null,
        template: entityTemplate,
        params: {
          basedon: 'EmailTemplate',
          emailTemplate: template ? JSON.stringify(template) : undefined,
          ...params,
        },
        onAfterSave: () => {
          setEmailProps(undefined);
        },
      });
      setIsEmailTemplatesPopoverOpen(false);
    },
    [getEmailParams]
  );

  const component = useMemo(
    () => (
      <React.Fragment>
        {emailProps && (
          <NavigationOverlay
            open={true}
            initialView={{
              view: <EmailOverlayController {...emailProps} />,
            }}
            close={() => {
              setEmailProps(undefined);
            }}
          />
        )}

        {isEmailTemplatesPopoverOpen && (
          <EmailTemplatesPopover
            noTemplate={noTemplate}
            templates={templates}
            anchorEl={emailTemplatesRef.current}
            onClose={() => setIsEmailTemplatesPopoverOpen(false)}
            onTemplateClick={(template) => {
              setIsEmailTemplatesPopoverOpen(false);
              openEmailMask(template);
            }}
            onStandardSelect={(template: EMailTemplate) => {
              setStandardTemplate({
                ...standardTemplates,
                [template.eMailTemplateKind]:
                  standardTemplates[template.eMailTemplateKind] !== template.id
                    ? template.id
                    : null,
              });
            }}
          />
        )}
      </React.Fragment>
    ),
    [
      emailProps,
      isEmailTemplatesPopoverOpen,
      noTemplate,
      openEmailMask,
      setStandardTemplate,
      standardTemplates,
      templates,
    ]
  );

  const action: CustomToolbarAction = useMemo(() => {
    return {
      rootRef: emailTemplatesRef,
      actionKey: 'EMAIL_FROM_APPOINTMENT',
      mode: 'Edit',
      IconComponent: () => <EmailIcon style={{ color: 'var(--brand01)' }} />,
      handler: (_id, event, context) => {
        emailTemplatesRef.current = event.target as HTMLButtonElement;
        previewContext.current = context;
        if (templates.length > 0) {
          setIsEmailTemplatesPopoverOpen(true);
        } else {
          openEmailMask(templates[0]);
        }
      },
      sections: ['mobile', 'preview'],
    };
  }, [openEmailMask, templates]);

  return useMemo(() => {
    return {
      action,
      component,
    };
  }, [action, component]);
};
