import { Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DataType, IBasicTableProps } from '@work4all/components';

import { InboundInvoicePayment } from '@work4all/models/lib/Classes/InboundInvoicePayment.entity';
import { GeneralLedgerAccountType } from '@work4all/models/lib/Enums/GeneralLedgerAccountType.enum';

import { formatNumberAsCurrency } from '@work4all/utils';
import {
  DateFormatPreset,
  formatDateString,
} from '@work4all/utils/lib/date-utils/formatDateString';

import { LedgerAccountCell } from '../../components/table-cells/LedgerAccountCell';
import { TextInputCell } from '../../components/table-cells/TextInputCell';
import { UserPickerCell } from '../../components/table-cells/UserPickerCell';
import { UsePaymentOnEditHnadler } from '../interfaces';

const KindType = Object.keys(GeneralLedgerAccountType);

interface Props {
  onEdit: UsePaymentOnEditHnadler;
  data: Pick<InboundInvoicePayment, 'amount'>;
}

export const usePaymentColumns = ({ onEdit, data }: Props) => {
  const { t } = useTranslation();

  const renderAmountFooter = useCallback(() => {
    return (
      <Typography padding="4px" fontSize={13.5}>
        {formatNumberAsCurrency(data.amount)}
      </Typography>
    );
  }, [data.amount]);

  const columns = useMemo<IBasicTableProps['columns']>(() => {
    return [
      {
        Header: t('PAYMENTS_MASK.TABLE.COLUMNS.BOOKING_DATE'),
        accessor: 'bookingDate',
        width: 150,
        Cell: (cell) =>
          formatDateString(cell?.value, DateFormatPreset.DATE_SIMPLE_2YEAR),
      },
      {
        Header: t('PAYMENTS_MASK.TABLE.COLUMNS.FINANCIAL_ACCOUNT_TRANSFER'),
        accessor: 'datevDate',
        width: 150,
      },
      {
        Header: t('PAYMENTS_MASK.TABLE.COLUMNS.ACCOUNT'),
        accessor: 'amount',
        width: 150,
        Footer: renderAmountFooter(),
        Cell: (cell) => {
          return (
            <TextInputCell
              value={formatNumberAsCurrency(cell.value)}
              onChange={(value) => {
                const amount = Number(value?.replace(',', '.'));

                if (!isNaN(amount) && amount !== cell.value) {
                  onEdit(cell.row.original, { amount });
                }
              }}
              rightTextAlign
            />
          );
        },
        dataType: DataType.Number,
      },
      {
        Header: t('PAYMENTS_MASK.TABLE.COLUMNS.LEDGER_ACCOUNT_NUMBER'),
        accessor: 'ledgerAccount',
        width: 150,
        Cell: (cell) => {
          return (
            <LedgerAccountCell
              label={(value) => value?.number || ''}
              value={cell.value}
              onChange={(ledgerAccount) => {
                if (ledgerAccount?.id !== cell.value?.id) {
                  onEdit(cell.row.original, { ledgerAccount });
                }
              }}
              overridePrefilter={[
                {
                  kind: {
                    $in: [
                      /**
                       * From Sascha "As the class was generated years before, someone forgot to change the type
                       * to be a enum, it is also the same on work4all Desktop :disappointed: So I added a
                       * resolver with an enum for this integer but I can't change the type, so you can not really
                       * filter by the property BUT you can filter on field Art/Type."
                       *
                       * Full context: https://work4all.slack.com/archives/C03LXTT1RL4/p1714499438801219
                       */
                      KindType.indexOf(
                        GeneralLedgerAccountType.FINANCIAL_AND_PERSONAL_ACCOUNTS
                      ),
                      KindType.indexOf(
                        GeneralLedgerAccountType.CASH_DISCOUNT_INCOMING_ACCOUNT
                      ),
                    ],
                  },
                },
              ]}
              hideTooltip
            />
          );
        },
      },
      {
        Header: t('PAYMENTS_MASK.TABLE.COLUMNS.NOTE'),
        accessor: 'text',
        width: 150,
        Cell: (cell) => {
          return (
            <TextInputCell
              value={cell.value}
              onChange={(text) => {
                if (text !== cell.value) {
                  onEdit(cell.row.original, { text });
                }
              }}
            />
          );
        },
      },
      {
        Header: t('PAYMENTS_MASK.TABLE.COLUMNS.USER'),
        accessor: 'user',
        width: 250,
        Cell: (cell) => {
          return (
            <UserPickerCell
              disabled
              value={cell.value}
              onChange={(user) => {
                if (user?.id !== cell?.value?.id) {
                  onEdit(cell.row.original, { user });
                }
              }}
            />
          );
        },
      },
    ];
  }, [renderAmountFooter, onEdit, t]);

  return columns;
};
