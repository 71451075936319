import { CheckSharp } from '@mui/icons-material';
import { CellProps } from 'react-table';

import { IndividualFieldControlType } from '@work4all/models/lib/Enums/IndividualFieldControlType.enum';

import {
  DateFormatPreset,
  formatDateString,
} from '@work4all/utils/lib/date-utils/formatDateString';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function CustomFieldCell(props: CellProps<any, any>) {
  const { value } = props;

  const { fieldType }: { fieldType: IndividualFieldControlType } =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (props.column as any).cellParams;

  if (value == null) {
    return null;
  }

  return renderCustomField(fieldType, value);
}

export function renderCustomField(
  fieldType: IndividualFieldControlType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
) {
  switch (fieldType) {
    case IndividualFieldControlType.CHECK:
      return value === true ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CheckSharp />
        </div>
      ) : null;

    case IndividualFieldControlType.DATE_TYPE: {
      return formatDateString(value, DateFormatPreset.DATE_SIMPLE_2YEAR);
    }

    default:
      return value;
  }
}
