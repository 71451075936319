import { TextmarksList, TextmarkType } from '../../types';

import { DOCUMENT_TEXTMARKS } from './document-textmarks';
import { EMAIL_TEXTMARKS } from './email-textmarks';
import { ERP_EMAIL_TEXTMARKS } from './erp-textmarks';

export const TEXTMARKS_BY_TYPE: Record<TextmarkType, TextmarksList> = {
  email: EMAIL_TEXTMARKS,
  erp: ERP_EMAIL_TEXTMARKS,
  document: DOCUMENT_TEXTMARKS,
};
