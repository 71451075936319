import { DateTimeInputPicker } from '../../../../locked-inputs';
import { customDateParser } from '../utils/custom-date-parser';

import { SimpleCustomFieldProps } from './types';

export function SimpleCustomDateField(props: SimpleCustomFieldProps) {
  const { label, ...others } = props;

  const value = customDateParser.formatValue(others.value);

  return (
    <DateTimeInputPicker
      withTime={false}
      clearable={false}
      dateLabel={label}
      value={value}
      onChange={(e) => {
        const value = customDateParser.parseValue(e.currentTarget.value);
        others.onChange(value);
      }}
    />
  );
}
