import styles from './DateRangePicker.module.scss';

import { DateTime } from 'luxon';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { RelativeDateFilter } from '@work4all/utils/lib/date-utils/RelativeDateFilter.enum';

import { TimeUnitType } from '../../../../components/time-frame-switch/TimeFrameSwitch';
import { ToggleButton } from '../../../../components/toggle-button/ToggleButton';
import { DateTimeInputPicker } from '../../../../input/date-time-input-picker';
import { MultiStepControls, Step } from '../../../../input/multi-step-controls';
import { Divider } from '../../../divider/Divider';

interface IDateRangePickerProps {
  startDate?: Date | RelativeDateFilter;
  endDate?: Date;
  onChange: (startDate: Date | RelativeDateFilter, endDate: Date) => void;
}

export const getTimeSpanUnit = (
  startDate: Date | RelativeDateFilter,
  endDate: Date
): { startDate: TimeUnitType | null; endDate: TimeUnitType | null } => {
  let result: { startDate: TimeUnitType | null; endDate: TimeUnitType | null } =
    { startDate: null, endDate: null };
  if ((startDate as RelativeDateFilter) in RelativeDateFilter) {
    switch (startDate) {
      case RelativeDateFilter.currentMonth:
        result = { startDate: 'month', endDate: 'month' };
        break;
      case RelativeDateFilter.currentWeek:
        result = { startDate: 'week', endDate: 'week' };
        break;
      case RelativeDateFilter.currentYear:
        result = { startDate: 'year', endDate: 'year' };
        break;
      case RelativeDateFilter.today:
        result = { startDate: 'day', endDate: 'day' };
        break;
      case RelativeDateFilter.todayAndPast:
        result = { startDate: null, endDate: 'day' };
        break;
    }
  } else {
    const start = DateTime.fromJSDate(startDate as Date);
    const end = DateTime.fromJSDate(endDate);
    const today = DateTime.fromJSDate(new Date());
    if (
      today.hasSame(start, 'year') &&
      today.hasSame(end, 'year') &&
      start.hasSame(end.startOf('year'), 'day') &&
      end.hasSame(start.endOf('year'), 'day')
    ) {
      result = { startDate: 'year', endDate: 'year' };
    }

    if (
      today.hasSame(start, 'month') &&
      today.hasSame(end, 'month') &&
      start.hasSame(end.startOf('month'), 'day') &&
      end.hasSame(start.endOf('month'), 'day')
    ) {
      result = { startDate: 'month', endDate: 'month' };
    }

    if (
      today.hasSame(start, 'week') &&
      today.hasSame(end, 'week') &&
      start.hasSame(end.startOf('week'), 'day') &&
      end.hasSame(start.endOf('week'), 'day')
    ) {
      result = { startDate: 'week', endDate: 'week' };
    }

    if (today.hasSame(start, 'day') && start.hasSame(end, 'day')) {
      result = { startDate: 'day', endDate: 'day' };
    }

    if (startDate === null && today.hasSame(end, 'day')) {
      result = { startDate: null, endDate: 'day' };
    }
  }

  return result;
};

export const DateRangePicker: React.FC<IDateRangePickerProps> = (props) => {
  const { t } = useTranslation();
  const { startDate, endDate, onChange } = props;

  const checkActiveState = useCallback(
    (timeUnit: RelativeDateFilter): boolean => {
      return timeUnit === startDate;
    },
    [startDate, endDate]
  );

  return (
    <div className={styles.pickerWrap}>
      <MultiStepControls>
        <Step active={true} index={0}>
          <DateTimeInputPicker
            required={false}
            dateLabel={t('INPUTS.FROM')}
            withTime={false}
            value={
              startDate &&
              !((startDate as RelativeDateFilter) in RelativeDateFilter)
                ? startDate
                : ''
            }
            onChange={(e) => {
              onChange?.(
                e.currentTarget.value === '' &&
                  !((startDate as RelativeDateFilter) in RelativeDateFilter)
                  ? null
                  : DateTime.fromISO(e.currentTarget.value)
                      .startOf('day')
                      .toJSDate(),
                endDate
              );
            }}
          />
        </Step>

        <Step active={true} index={1}>
          <DateTimeInputPicker
            required={false}
            dateLabel={t('INPUTS.TO')}
            withTime={false}
            value={
              endDate &&
              !((startDate as RelativeDateFilter) in RelativeDateFilter)
                ? endDate
                : ''
            }
            onChange={(e) => {
              onChange?.(
                startDate,
                e.currentTarget.value === '' &&
                  !((startDate as RelativeDateFilter) in RelativeDateFilter)
                  ? null
                  : DateTime.fromISO(e.currentTarget.value)
                      .endOf('day')
                      .toJSDate()
              );
            }}
          />
        </Step>
      </MultiStepControls>

      <Divider style={{ padding: 0 }} />

      <div className={styles.shortcuts}>
        <ToggleButton
          title={t('COMMON.TODAY')}
          active={checkActiveState(RelativeDateFilter.today)}
          onChange={() => onChange(RelativeDateFilter.today, null)}
        />
        <ToggleButton
          title={t('COMMON.TODAY_AND_PAST')}
          active={checkActiveState(RelativeDateFilter.todayAndPast)}
          onChange={() => onChange(RelativeDateFilter.todayAndPast, null)}
        />
        <ToggleButton
          title={t('COMMON.THIS_WEEK')}
          active={checkActiveState(RelativeDateFilter.currentWeek)}
          onChange={() => onChange(RelativeDateFilter.currentWeek, null)}
        />
        <ToggleButton
          title={t('COMMON.THIS_MONTH')}
          active={checkActiveState(RelativeDateFilter.currentMonth)}
          onChange={() => onChange(RelativeDateFilter.currentMonth, null)}
        />
        <ToggleButton
          title={t('COMMON.THIS_YEAR')}
          active={checkActiveState(RelativeDateFilter.currentYear)}
          onChange={() => onChange(RelativeDateFilter.currentYear, null)}
        />
      </div>
    </div>
  );
};
