import { DateTime } from 'luxon';

function formatValue(value: string | null): string | null {
  const formatted =
    value == null
      ? null
      : DateTime.fromISO(value)
          .setZone('local', {
            keepLocalTime: true,
          })
          .toISO();

  return formatted;
}

function parseValue(value: string): string {
  const parsed = DateTime.fromISO(value)
    .setZone('utc', {
      keepLocalTime: true,
    })
    .toISODate();

  return parsed;
}
export const customDateParser = { parseValue, formatValue };
