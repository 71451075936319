import { PreviewInputsContext } from '../../contexts';
import { PreviewInputsProviderProps } from '../../types';

export const PreviewInputsProvider = ({
  children,
  ...contextValue
}: PreviewInputsProviderProps) => {
  return (
    <PreviewInputsContext.Provider value={contextValue}>
      {children}
    </PreviewInputsContext.Provider>
  );
};
