import styles from './Profile.module.scss';

import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import InfoIcon from '@mui/icons-material/Info';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { matchSorter } from 'match-sorter';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ReactComponent as NoteIcon } from '@work4all/assets/icons/note.svg';

import { HookedUserIcon } from '@work4all/components/lib/components/user-icon/useUserIconRegister';
import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';
import { MultiStepSearch } from '@work4all/components/lib/input/multi-step-search';
import { Breadcrumbs } from '@work4all/components/lib/navigation/breadcrumbs/Breadcrumbs';
import {
  StackProvider,
  useHistoryStack,
} from '@work4all/components/lib/navigation/history-stack';
import { Body2 } from '@work4all/components/lib/typography/body2/Body2';
import { Caption } from '@work4all/components/lib/typography/caption/Caption';

import { useNavigate, useUser } from '@work4all/data';
import {
  changeTenant,
  logoutUser,
} from '@work4all/data/lib/actions/user-actions';
import { useTenant } from '@work4all/data/lib/hooks/routing/TenantProvider';

import { useReleaseNotesDialogContext } from '../../../../contexts/ReleaseNotesDialogContext';
import { DebugInfoDialog } from '../../components/DebugInfoDialog/DebugInfoDialog';
import { Design } from '../Design';
import { DevTools } from '../DevTools/DevTools';
import { HomeScreen } from '../HomeScreen';
import { Security } from '../security/Security';

import { LatestNotifications } from './components/LatestNotifications/LatestNotifications';
import { ReferralBanner } from './components/ReferralBanner';

export const ProfilePage: React.FC = () => {
  const user = useUser();
  return (
    <StackProvider
      initialView={{
        view: <Profile />,
        title: '',
        breadcrumbTitle: user.shortName,
      }}
    >
      <ProfilePageInner />
    </StackProvider>
  );
};

export const ProfilePageInner: React.FC = () => {
  const { goBack, stack, current, currentStackIndex } = useHistoryStack();
  return (
    <div
      style={{ height: '100%', backgroundColor: 'var(--ui01)' }}
      className={styles['page']}
    >
      <Breadcrumbs
        stack={stack}
        goBack={goBack}
        current={current}
        currentIndex={currentStackIndex}
        forceStackItems
      />
      {current.view}
    </div>
  );
};

export const Profile: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { go, close } = useHistoryStack();
  const user = useUser();
  const dispatch = useDispatch();

  const { activeTenant, setActiveTenant, tenants } = useTenant();
  const navigate = useNavigate();

  const { tenantsDropdownItems, defaultValue } = tenants.reduce<{
    tenantsDropdownItems: { title: string; id: string }[];
    defaultValue: string;
  }>(
    (acc, tenant) => {
      if (tenant.id === activeTenant) {
        acc.defaultValue = tenant.name;
      }

      acc.tenantsDropdownItems.push({
        title: tenant.name,
        id: tenant.id.toString(),
      });

      return acc;
    },
    { tenantsDropdownItems: [], defaultValue: undefined }
  );

  const [isDebugInfoDialogOpen, setDebugInfoDialogOpen] = useState(false);
  const { releaseNotesFile, version, openReleaseNotesDialog } =
    useReleaseNotesDialogContext();

  return (
    <Stack direction="column" sx={{ overflow: 'auto', height: '100%' }}>
      <Stack
        direction="row"
        alignItems="center"
        gap="0.5rem"
        sx={{ m: '1rem 1rem 0' }}
      >
        <Stack direction="row" alignItems="center">
          <HookedUserIcon userId={user?.benutzerCode} size="xl" />
          <div className={styles.userInfo}>
            <Body2 semiBold>{`${user.firstName} ${user.lastName}`}</Body2>
            <Caption color="text03">{user.email}</Caption>
          </div>
        </Stack>

        <Button
          className={styles.buttonPadding}
          size="large"
          color="primary"
          startIcon={<LogoutIcon />}
          onClick={() => dispatch(logoutUser())}
          sx={{ ml: 'auto' }}
        >
          {t('USER.SIGN_OUT')}
        </Button>
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        gap="0.5rem"
        sx={{ m: '1rem' }}
      >
        <Box sx={{ flex: '1' }}>
          <MultiStepSearch
            searchValueAsActiveInputValue={false}
            onItemSelect={(name, item) => {
              //update the scope of the current tab
              const newTenantId = parseInt(item.id, 10);
              setActiveTenant(newTenantId);
              dispatch(changeTenant(newTenantId));
              //default to home, as the currentview might not even exist in the new tenant
              navigate('/home', { tenant: newTenantId });
            }}
            onSearch={(name, value, items) => {
              if (value.length === 0) {
                return items;
              }
              const d = matchSorter(items, value, {
                keys: ['title'],
              });

              return d;
            }}
            inputs={[
              {
                label: t('INPUTS.CURRENT_CLIENT'),
                name: 'currentClient',
                items: tenantsDropdownItems,
                defaultValue,
                withUnclear: false,
              },
            ]}
          />
        </Box>

        <ToggleButtonGroup
          value={i18n.language}
          exclusive
          aria-label="Language"
          sx={{ flex: 'none' }}
        >
          <ToggleButton
            className={styles.buttonPadding}
            value="en"
            onClick={() => i18n.changeLanguage('en')}
          >
            {t('USER.LANGUAGE.EN')}
          </ToggleButton>
          <ToggleButton
            className={styles.buttonPadding}
            value="de"
            onClick={() => i18n.changeLanguage('de')}
          >
            {t('USER.LANGUAGE.DE')}
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>

      <LatestNotifications onClose={close} />

      <List>
        <Divider style={{ padding: '1rem 1rem 1rem 0' }}>
          {t('USER_MENU_SECTION.SETTINGS')}
        </Divider>

        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              go({
                title: t('USER.DESIGN'),
                view: <Design />,
              });
            }}
          >
            <ListItemText primary={t('USER.DESIGN')} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              go({
                title: t('USER.HOME_SCREEN'),
                view: <HomeScreen />,
              });
            }}
          >
            <ListItemText primary={t('USER.HOME_SCREEN')} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              go({
                title: t('USER.SECURITY'),
                view: <Security />,
              });
            }}
          >
            <ListItemText primary={t('USER.SECURITY')} />
          </ListItemButton>
        </ListItem>

        <Divider style={{ padding: '1rem 1rem 1rem 0' }}>
          {t('USER_MENU_SECTION.SUPPORT')}
        </Divider>

        <ListItem disablePadding>
          <ListItemButton
            disabled={!releaseNotesFile || !releaseNotesFile?.length}
            onClick={() => {
              openReleaseNotesDialog();
            }}
          >
            <ListItemIcon>
              <NoteIcon />
            </ListItemIcon>
            <ListItemText primary={t('RELEASE_NOTES.title', { version })} />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              setDebugInfoDialogOpen(true);
            }}
          >
            <ListItemIcon>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText primary={t('DEBUG_INFO.TITLE')} />
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton
            onClick={() => go({ title: 'DevTools', view: <DevTools /> })}
          >
            <ListItemIcon>
              <DeveloperModeIcon />
            </ListItemIcon>
            <ListItemText primary={'DevTools'} />
          </ListItemButton>
        </ListItem>

        <DebugInfoDialog
          open={isDebugInfoDialogOpen}
          onClose={() => {
            setDebugInfoDialogOpen(false);
          }}
        />
      </List>

      <div className={styles.bottomWrapper}>
        <ReferralBanner />
      </div>
    </Stack>
  );
};
