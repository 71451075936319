import styles from './EntityPickerActions.module.scss';

import { Theme, useMediaQuery } from '@mui/material';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  ITicketPickerProps,
  TicketPicker,
} from '@work4all/components/lib/components/entity-picker/ticket-picker/TicketPicker';
import { useEntityTemplate } from '@work4all/components/lib/components/entity-template-provider/EntityTemplateProvider';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

interface TicketPickerFieldProps
  extends Omit<ITicketPickerProps<false>, 'multiple'> {
  error?: string;
  name?: string;
}

export const TicketPickerField = React.forwardRef<
  HTMLDivElement,
  TicketPickerFieldProps
>(function TicketPickerField(props, ref) {
  const { error, ...pickerProps } = props;
  const { value, onChange } = pickerProps;

  const { t } = useTranslation();

  const popoverRef = useRef<EntityPickerPopover>(null);
  const entityTemplate = useEntityTemplate();

  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );

  return (
    <EntityPickerPopover
      fullscreen={isMobile}
      ref={popoverRef}
      picker={<TicketPicker {...pickerProps} multiple={false} />}
      footer={entityTemplate.renderPickerFooter({
        popoverRef,
        entity: Entities.ticket,
        ...props,
      })}
    >
      <PickerTargetButton
        ref={ref}
        value={value ? value?.title : ''}
        name={props.name}
        label={
          <>
            {!value && t('COMMON.TICKET')}
            {value && (
              <>
                <span className={styles.primaryInfo}>
                  {t('COMMON.TICKET')} &nbsp;
                </span>
                <span className={styles.secondaryInfo}>{value?.number}</span>
              </>
            )}
          </>
        }
        error={error}
        onClear={() => {
          onChange(null);
        }}
      />
    </EntityPickerPopover>
  );
});
