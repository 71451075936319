import { useCompanyPictureSimpleModeFile } from '@work4all/data/lib/hooks/use-upload-company-picture-simple-file';

import { SimpleReportContext } from './SimpleReportContext';

interface SimpleReportProviderProps {
  children: React.ReactNode;
}

export const SimpleReportProvider = ({
  children,
}: SimpleReportProviderProps) => {
  const {
    logoBase64,
    logoURL,
    uploadCompanyPictureSimpleModeFile,
    uploading: uploadingLogo,
  } = useCompanyPictureSimpleModeFile();

  return (
    <SimpleReportContext.Provider
      value={{
        logoBase64,
        logoURL,
        uploadingLogo,
        uploadCompanyPictureSimpleModeFile,
      }}
    >
      {children}
    </SimpleReportContext.Provider>
  );
};
