export enum RePaymentStatus {
  ZERO_INVOICE = 'ZERO_INVOICE',
  OPEN_CREDIT = 'OPEN_CREDIT',
  PAYED_CREDIT = 'PAYED_CREDIT',
  PARTIAL_PAYED_CREDIT = 'PARTIAL_PAYED_CREDIT',
  PAYED = 'PAYED',
  PARTIAL_PAYED = 'PARTIAL_PAYED',
  OPEN = 'OPEN',
  DUE = 'DUE',
}
