import styles from './Table.module.scss';

import clsx from 'clsx';
import React, { KeyboardEventHandler } from 'react';
import { TableProps as ReactTableProps } from 'react-table';

interface ITableProps extends ReactTableProps {
  children?: React.ReactNode;
  className?: string;
  onKeyDown?: KeyboardEventHandler<HTMLDivElement>;
}

export const Table: React.FC<ITableProps> = (props) => {
  const { children, className, ...rest } = props;
  return (
    <div className={clsx(styles.table, className)} tabIndex={-1} {...rest}>
      {children}
    </div>
  );
};
