import { Typography } from '@mui/material';
import { forwardRef } from 'react';

import {
  ContactOrBusinessPartner,
  isContact,
} from './contact-or-business-partner';

export interface ContactTextProps extends React.HTMLAttributes<HTMLDivElement> {
  contact: ContactOrBusinessPartner;
  variant?: 'caption' | 'body2';
}

export const ContactText = forwardRef<HTMLDivElement, ContactTextProps>(
  function ContactText(props, ref) {
    const { contact, variant = 'body2', ...otherProps } = props;

    if (!contact) {
      return null;
    }

    const renderContent = () => {
      if (isContact(contact)) {
        return (
          <>
            {contact.displayName}

            <Typography
              component="span"
              variant={variant}
              color="text.tertiary"
            >
              {' | '} {contact.businessPartner?.data.name}
            </Typography>
          </>
        );
      } else {
        return contact.name;
      }
    };

    return (
      <Typography
        ref={ref}
        component="div"
        variant={variant}
        noWrap
        {...otherProps}
      >
        {renderContent()}
      </Typography>
    );
  }
);

export function contactAsString(contact: ContactOrBusinessPartner): string {
  if (!contact) return '';

  if (isContact(contact)) {
    return [contact?.displayName, contact?.businessPartner?.data.name]
      .filter(Boolean)
      .join(' | ');
  } else {
    return contact.name;
  }
}
