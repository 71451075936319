export interface MailboxContentRequests {
  refetch: () => Promise<void>;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface MailboxContentRequestsOptions extends MailboxContentRequests {}

export function useMailboxContentRequests(
  options: MailboxContentRequestsOptions
): MailboxContentRequests {
  const { refetch } = options;

  return {
    refetch,
  };
}
