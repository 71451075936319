import { Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { formatNumberAsCurrency } from '@work4all/utils';
import {
  DateFormatPreset,
  formatDateString,
} from '@work4all/utils/lib/date-utils/formatDateString';

import { IBasicTableProps } from '../../../../dataDisplay/basic-table';
import { HookedUserIcon } from '../../../user-icon/useUserIconRegister';

export const useTabColumns = (entity: Entities) => {
  const { t } = useTranslation();
  const columns = useMemo<IBasicTableProps['columns']>(() => {
    if (entity === Entities.rELedgerAccountSplit) {
      return [
        {
          Header: t('BOOKINGS_TABLE.COLUMNS.TOTAL_NET'),
          accessor: 'valueNet',
          width: 120,
        },
        {
          Header: t('BOOKINGS_TABLE.COLUMNS.VAT'),
          accessor: 'vat',
          width: 120,
        },
        {
          Header: t('BOOKINGS_TABLE.COLUMNS.TOTAL_VAT'),
          accessor: 'vatAmount',
          width: 120,
        },
        {
          Header: t('BOOKINGS_TABLE.COLUMNS.TOTAL_GROSS'),
          accessor: 'proportionDM',
          width: 120,
          Cell: (cell) =>
            cell?.value === 0 ? '' : formatNumberAsCurrency(cell?.value),
        },
        {
          Header: t('BOOKINGS_TABLE.COLUMNS.TAX_KEY'),
          accessor: 'taxKey',
          width: 120,
        },
        {
          Header: t('BOOKINGS_TABLE.COLUMNS.KST'),
          accessor: 'costCenter.name',
          width: 120,
        },
        {
          Header: t('BOOKINGS_TABLE.COLUMNS.ACCOUNT_DESCRIPTION'),
          accessor: 'konto.name',
          width: 120,
        },
        {
          Header: t('BOOKINGS_TABLE.COLUMNS.COST_ACCOUNT'),
          accessor: 'konto.number',
          width: 120,
        },
        {
          Header: t('COMMON.PROJECTNUMBER'),
          accessor: 'project.id',
          width: 120,
        },
        {
          Header: t('COMMON.PROJECT'),
          accessor: 'project.name',
          width: 120,
        },
        {
          Header: t('BOOKINGS_TABLE.COLUMNS.CONTRA_ACCOUNT'),
          accessor: 'contraAccount',
          width: 120,
        },
        {
          Header: t('COMMON.NOTE'),
          accessor: 'note',
          width: 120,
        },
      ];
    }

    if (entity === Entities.inboundInvoicePayment) {
      return [
        {
          Header: t('PAYMENTS_TABLE.COLUMNS.BOOKING_DATE'),
          accessor: 'bookingDate',
          width: 120,
          Cell: (cell) =>
            formatDateString(cell?.value, DateFormatPreset.DATE_SIMPLE_2YEAR),
        },
        {
          Header: t('PAYMENTS_TABLE.COLUMNS.FINANCIAL_ACCOUNT_TRANSFER'),
          accessor: 'datevDate',
          width: 120,
        },
        {
          Header: t('PAYMENTS_TABLE.COLUMNS.ACCOUNT'),
          accessor: 'amount',
          width: 120,
          Cell: (cell) => formatNumberAsCurrency(cell?.value),
        },
        {
          Header: t('PAYMENTS_TABLE.COLUMNS.LEDGER_ACCOUNT_NUMBER'),
          accessor: 'ledgerAccountNumber',
          width: 120,
        },
        {
          Header: t('PAYMENTS_TABLE.COLUMNS.NOTE'),
          accessor: 'text',
          width: 120,
        },
        {
          Header: t('PAYMENTS_TABLE.COLUMNS.USER'),
          accessor: 'user',
          width: 250,
          Cell: (cell) => {
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <HookedUserIcon userId={cell?.value?.id} />
                <Typography style={{ marginLeft: '12px' }}>
                  {cell?.value?.displayName}
                </Typography>
              </div>
            );
          },
        },
      ];
    }

    if (entity === Entities.raLedgerAccountSplit) {
      return [
        {
          Header: t('OUTGOING_INVOICE_BOOKINGS_TABLE.COLUMNS.TOTAL_NET'),
          accessor: 'netto',
          width: 120,
          Cell: (cell) =>
            cell?.value === 0 ? '' : formatNumberAsCurrency(cell?.value),
        },
        {
          Header: t('OUTGOING_INVOICE_BOOKINGS_TABLE.COLUMNS.VAT'),
          accessor: 'mwstSatz',
          width: 120,
        },
        {
          Header: t('OUTGOING_INVOICE_BOOKINGS_TABLE.COLUMNS.TOTAL_VAT'),
          accessor: 'mwstBetrag',
          width: 120,
          Cell: (cell) =>
            cell?.value === 0 ? '' : formatNumberAsCurrency(cell?.value),
        },
        {
          Header: t('OUTGOING_INVOICE_BOOKINGS_TABLE.COLUMNS.TOTAL_GROSS'),
          accessor: 'anteilProzent',
          width: 120,
          Cell: (cell) =>
            cell?.value === 0 ? '' : formatNumberAsCurrency(cell?.value),
        },
        {
          Header: t('OUTGOING_INVOICE_BOOKINGS_TABLE.COLUMNS.TAX_KEY'),
          accessor: 'steuerschluessel',
          width: 120,
          Cell: (cell) => (cell?.value === 0 ? '' : cell?.value),
        },
        {
          Header: t('OUTGOING_INVOICE_BOOKINGS_TABLE.COLUMNS.KST'),
          accessor: 'kostenstelleItem.name',
          width: 120,
        },
        {
          Header: t(
            'OUTGOING_INVOICE_BOOKINGS_TABLE.COLUMNS.ACCOUNT_DESCRIPTION'
          ),
          accessor: 'konto.name',
          width: 120,
        },
        {
          Header: t('OUTGOING_INVOICE_BOOKINGS_TABLE.COLUMNS.COST_ACCOUNT'),
          accessor: 'konto.number',
          width: 120,
        },
        {
          Header: t('OUTGOING_INVOICE_BOOKINGS_TABLE.COLUMNS.NOTE'),
          accessor: 'note',
          width: 120,
        },
      ];
    }

    if (entity === Entities.raPayment) {
      return [
        {
          Header: t(
            'OUTGOING_INVOICE_PAYMENTS_TABLE.COLUMNS.LEDGER_ACCOUNT_NUMBER'
          ),
          accessor: 'ledgerAccount.number',
          width: 120,
        },
        {
          Header: t('OUTGOING_INVOICE_PAYMENTS_TABLE.COLUMNS.NOTE'),
          accessor: 'note',
          width: 120,
        },
        {
          Header: t('OUTGOING_INVOICE_PAYMENTS_TABLE.COLUMNS.USER'),
          accessor: 'createdByUser',
          width: 250,
          Cell: (cell) => {
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <HookedUserIcon userId={cell?.value?.id} />
                <Typography style={{ marginLeft: '12px' }}>
                  {cell?.value?.displayName}
                </Typography>
              </div>
            );
          },
        },
      ];
    }

    throw new Error(`Columns for ${entity} entity isn't defined`);
  }, [t, entity]);

  return columns;
};
