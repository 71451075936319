import React from 'react';

import { StackIndexContext } from '../stack-index-context/StackIndexContext';

export const StackIndexProvider: React.FC<{
  children?: React.ReactNode;
  index: number;
}> = (props) => {
  return (
    <StackIndexContext.Provider value={props.index}>
      {props.children}
    </StackIndexContext.Provider>
  );
};
