import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { InboundDeliveryNote } from '@work4all/models/lib/Classes/InboundDeliveryNote.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { EntityTable, IEntityTable } from './entity-table/EntityTable';
import { useEntityDataTable } from './entity-table/use-entity-data-table';
import schema from './schemata/inboundDeliveryNote-table-schema.json';
import { useDeleteEntitiesToolbarConfig } from './use-delete-entities-toolbar-config';
import { useErpHandlersTemplate } from './use-erp-handlers-template';

type Props = IEntityTable;

const defaultSort = [{ field: 'date', direction: SortDirection.DESCENDING }];
const FORCE_REQUEST_FIELDS: InboundDeliveryNote<EMode.query> = {
  positionList: [{ id: null, originalId: null }],
};

export const InboundDeliveryNotesTable = React.forwardRef<TableInstance, Props>(
  function InboundDeliveryNotesTable(props, ref) {
    const { ...rest } = props;

    const { t } = useTranslation();
    const template = useErpHandlersTemplate();
    const { prepareRowDisplayModifiers, ...dataTable } = useEntityDataTable<
      InboundDeliveryNote,
      never
    >({
      schema: schema as never,
      defaultSort,
      forceRequestFields: FORCE_REQUEST_FIELDS,
    });

    const rowModifiers = useCallback(
      (value: InboundDeliveryNote) => {
        const modifiers = prepareRowDisplayModifiers(value);
        return {
          ...modifiers,
          isFaded: value.isClosed && value.frozen,
          isShade1: !value.isClosed && value.frozen,
        };
      },
      [prepareRowDisplayModifiers]
    );

    const deleteConfig = useDeleteEntitiesToolbarConfig({
      entityType: Entities.inboundDeliveryNote,
    });

    return (
      <EntityTable
        ref={ref}
        template={template}
        {...dataTable}
        prepareRowDisplayModifiers={rowModifiers}
        actions={{
          remove: {
            ...deleteConfig,
            canDeleteEntity: (id) => {
              const data = dataTable.selectedEntities.find(
                (item) => item.id === Number(id)
              );

              const positions = data?.positionList ?? [];

              return {
                value:
                  positions.length === 0 ||
                  Boolean(
                    positions.find((position) => {
                      return position.originalId === 0;
                    })
                  ),
                preventMessage: t('ALERTS.ERP_PREVENT_DELETE_MESSAGE', {
                  entity: t(`COMMON.${Entities.deliveryNote.toUpperCase()}`),
                }),
              };
            },
          },
        }}
        {...rest}
      />
    );
  }
);
