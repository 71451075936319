import styles from './VisitationReportsPreview.module.scss';

import { Typography } from '@mui/material';
import i18next from 'i18next';
import { MouseEventHandler, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useDataMutation,
  useInaccessibleFields,
  useUser,
} from '@work4all/data';

import { TalkingPoint } from '@work4all/models/lib/Classes/TalkingPoint.entity';
import { VisitationReport } from '@work4all/models/lib/Classes/VisitationReport.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { DateFormatPreset } from '@work4all/utils/lib/date-utils/formatDateString';

import {
  BasicTable,
  IBasicTableProps,
  TableStateBagProvider,
} from '../../../dataDisplay/basic-table';
import { MIME_TYPES, Preview } from '../../../preview/Preview';
import { IUserIconRegisterContext } from '../../user-icon/types';
import {
  InfoCard,
  InfoCards,
  PreviewEditMessage,
  PreviewWrapper,
} from '../components';
import {
  CustomerPreviewPickerInput,
  DatePreviewPickerInput,
  PreviewInputsProvider,
  PreviewTitleInput,
  ProjectPreviewPickerInput,
  UserPreviewPickerInput,
} from '../components/preview-input';
import { useEntityPreview } from '../hooks/use-entity-preview';

import { visitationReportPreviewFields } from './VisitationReportPreviewContainer';

export type IVisitationReportPreviewProps = {
  userIconContext: IUserIconRegisterContext;
  noPreviewUrl?: string;
  visitationReports: VisitationReport[];
  onCloseClicked?: MouseEventHandler<HTMLButtonElement>;
  onEditClicked?: MouseEventHandler<HTMLButtonElement>;
  onShareClicked?: () => void;
};

export const VisitationReportPreview = (
  props: IVisitationReportPreviewProps
) => {
  const {
    visitationReports,
    onCloseClicked,
    onEditClicked,
    noPreviewUrl,
    onShareClicked,
  } = props;
  const { t } = useTranslation();

  const user = useUser();
  const { isInaccessible } = useInaccessibleFields();

  const fileInfosIsAccessible = !isInaccessible(
    Entities.visitationReport,
    'fileInfos'
  );

  const attendeeListIsAccessible = !isInaccessible(
    Entities.visitationReport,
    'attendeeList'
  );
  const talkingPointListIsAccessible = !isInaccessible(
    Entities.visitationReport,
    'talkingPointList'
  );

  const [detailInFullsceen, setFullscreen] = useState(false);

  const prepareRowDisplayModifiers = (value) => {
    return { isBold: (value as TalkingPoint).kind === 1 };
  };

  const columns = useMemo(() => {
    const columns: IBasicTableProps['columns'] = [
      {
        Header: t('COMMON.NUMBER_SHORT'),
        accessor: 'pozNr',
        width: 60,
      },
      {
        Header: t('COMMON.TOPIC'),
        accessor: 'thema',
        width: 220,
      },
      {
        Header: t('COMMON.CONTENTS'),
        accessor: 'erledigung',
        width: 260,
      },
    ];

    return columns;
  }, [t]);

  const [mutate] = useDataMutation<VisitationReport, EMode.upsert>({
    entity: Entities.visitationReport,
    mutationType: EMode.upsert,
    responseData: visitationReportPreviewFields as unknown as VisitationReport,
  });

  const {
    loading,
    isMultiselect,
    commonFields,
    activePicker,
    handleLock,
    onPopoverClose,
    onEdit,
    locked,
    lockedByUser,
    canEditAllSelected,
  } = useEntityPreview({
    user,
    subEntityType: Entities.visitationReport,
    entries: visitationReports,
    mutate,
  });

  const visitationReport = visitationReports[0];
  const sortedList = useMemo(() => {
    return [...visitationReport.talkingPointList].sort(
      (a, b) => a.nummer - b.nummer
    );
  }, [visitationReport.talkingPointList]);

  const renderTitle = useCallback(
    (withFullscreen?: boolean) => {
      return (
        <PreviewTitleInput
          onClose={(e) => {
            if (e.target.value !== visitationReport?.topic) {
              onEdit({ topic: e.target.value });
            }
            onPopoverClose();
          }}
          onCloseClicked={onCloseClicked}
          onEditClicked={onEditClicked}
          onShareClicked={onShareClicked}
          value={visitationReport?.topic}
          accessor="topic"
          onFullscreenClick={
            withFullscreen
              ? () => {
                  setFullscreen(!detailInFullsceen);
                }
              : undefined
          }
          disabled={isMultiselect}
        />
      );
    },
    [
      detailInFullsceen,
      isMultiselect,
      onCloseClicked,
      onEdit,
      onEditClicked,
      onPopoverClose,
      onShareClicked,
      visitationReport?.topic,
    ]
  );

  return (
    <PreviewInputsProvider
      isLoading={loading}
      activeInputAccessor={activePicker}
      handleLock={handleLock}
      commonInputs={commonFields}
      isMultiselect={isMultiselect}
      entity={Entities.salesOpportunities}
      isLocked={locked}
      lockedByUser={lockedByUser}
      numberOfSelectedRows={visitationReports.length}
      canEditAllSelected={canEditAllSelected}
    >
      <PreviewWrapper>
        {visitationReport.fileInfos.fileServiceProviderInfos ? (
          <>
            {renderTitle(true)}
            {isMultiselect && (
              <PreviewEditMessage entityId={String(visitationReport.id)} />
            )}

            {!isMultiselect && fileInfosIsAccessible ? (
              <Preview
                onFullscreenClose={() => setFullscreen(false)}
                openInFullscreen={detailInFullsceen}
                url={`${visitationReport.fileInfos?.previewUrl}`}
                mimeType={
                  visitationReport.fileInfos?.previewMimeType as MIME_TYPES
                }
                fileName={`${visitationReport.fileInfos?.fileServiceProviderInfos?.fileName}`}
                noPreview={i18next.t('MASK.NO_PREVIEW_FOR', {
                  type: MIME_TYPES,
                })}
                noPreviewUrl={noPreviewUrl}
              />
            ) : null}
          </>
        ) : (
          <>
            {renderTitle()}
            {isMultiselect && (
              <PreviewEditMessage entityId={String(visitationReport.id)} />
            )}
            <InfoCards columns={2}>
              <UserPreviewPickerInput
                labelTranslationKey="COMMON.EMPLOYEE"
                onClose={onPopoverClose}
                value={visitationReport?.user}
                onChange={(value) => {
                  onEdit({
                    userId: value.id,
                  });
                }}
                accessor="user"
              />

              <DatePreviewPickerInput
                labelTranslationKey="COMMON.DATE"
                onClose={onPopoverClose}
                value={visitationReport?.creationDate}
                onChange={(value) => {
                  onEdit({ creationDate: new Date(value).toISOString() });
                }}
                accessor="creationDate"
                dateFormatPreset={DateFormatPreset.DATE_SIMPLE_4YEAR}
              />

              <CustomerPreviewPickerInput
                labelTranslationKey="COMMON.CUSTOMER"
                onClose={onPopoverClose}
                value={visitationReport?.businessPartner?.data}
                onChange={(value) => {
                  onEdit({
                    businessPartner: value
                      ? {
                          data: value,
                          businessPartnerType: SdObjType.KUNDE,
                          id: value?.id,
                        }
                      : null,
                    businessPartnerId: value ? value?.id : 0,
                    businessPartnerType: SdObjType.KUNDE,
                  });
                }}
                accessor="businessPartner"
                showFooter
              />

              <ProjectPreviewPickerInput
                labelTranslationKey="COMMON.PROJECT"
                onClose={onPopoverClose}
                value={visitationReport?.project}
                onChange={(value) => {
                  onEdit({
                    project: value,
                    projectId: value && !Array.isArray(value) ? value.id : 0,
                  });
                }}
                accessor="project"
                showFooter
              />
            </InfoCards>

            {attendeeListIsAccessible && (
              <InfoCards columns={1}>
                <InfoCard
                  staticField={true}
                  truncate
                  label={t('COMMON.PARTICIPANT')}
                >
                  {visitationReport.attendeeList.map((attendee) => {
                    return (
                      <div>
                        <Typography component="span">
                          {attendee.name}

                          {attendee.businessPartnerId !== 0 ? (
                            <Typography component="span" color="secondary">
                              {' '}
                              | {attendee.contact?.businessPartner?.data?.name}
                            </Typography>
                          ) : null}
                        </Typography>
                      </div>
                    );
                  })}
                </InfoCard>
              </InfoCards>
            )}

            {!isMultiselect && talkingPointListIsAccessible ? (
              <div style={{ display: 'grid', height: '100%' }}>
                {/* Add new empty context so that this table does not overwrite the context in ListPage. */}
                <TableStateBagProvider tableInstance={null}>
                  <BasicTable
                    className={styles.transparentTable}
                    mode="client"
                    prepareRowDisplayModifiers={prepareRowDisplayModifiers}
                    reordableColumns={true}
                    resizableColumns={true}
                    data={sortedList}
                    columns={columns}
                    allItemsCount={
                      visitationReport.talkingPointList.length || 0
                    }
                    cardsView={false}
                    selectableMultiple={false}
                  />
                </TableStateBagProvider>
              </div>
            ) : null}
          </>
        )}
      </PreviewWrapper>
    </PreviewInputsProvider>
  );
};
