import { useContext, useMemo } from 'react';

import { SimpleReportSettings } from '../../../../../../../../settings/settings';
import { SimpleReportContext } from '../SimpleReportContext';

export const useDefaultHeader = (props: Partial<SimpleReportSettings>) => {
  const {
    headerMarginTop,
    headerMarginBottom,
    headerMarginLeft,
    headerMarginRight,
    logoWidth,
  } = props;

  const { logoBase64 } = useContext(SimpleReportContext);

  return useMemo(
    () => [
      {
        margin: [
          headerMarginLeft || 50,
          headerMarginTop || 30,
          headerMarginRight || 50,
          headerMarginBottom || 0,
        ],
        columns: [
          {
            width: '*',
            stack: [],
          },
          {
            width: 'auto',

            stack: [
              logoBase64
                ? {
                    image: logoBase64,
                    width: logoWidth || 150,
                  }
                : null,
            ].filter(Boolean),
          },
        ],
      },
    ],
    [
      headerMarginBottom,
      headerMarginLeft,
      headerMarginRight,
      headerMarginTop,
      logoBase64,
      logoWidth,
    ]
  );
};
