import styles from './CustomCell.module.scss';

import clsx from 'clsx';

import { EditModeCell } from '@work4all/components/lib/dataDisplay/basic-table/plugins/useEditMode';

import { ParsedCustomFieldConfig } from '@work4all/data/lib/custom-fields';

import { Position } from '@work4all/models/lib/Classes/Position.entity';

import { renderCustomField } from '../../../../../../../../../../../components/data-tables/cell-renderer/CustomFieldCell';

interface CustomCellProps {
  field: ParsedCustomFieldConfig;
  cellData: {
    cell: EditModeCell<Position>;
  };
}

export const CustomCell = (props: CustomCellProps) => {
  const { field, cellData } = props;
  if (cellData.cell.isEditMode) return <div>Hello</div>;
  const value = cellData.cell.row.original.customFieldList?.find(
    (x) => x.key.toString() === cellData.cell.column.id
  )?.value;
  if (value === undefined) return <div></div>;
  const parsedValue = JSON.parse(value);
  return (
    <div
      className={clsx(
        styles['custom-field'],
        styles[`custom-${field.fieldType}`]
      )}
    >
      {renderCustomField(field.fieldType, parsedValue)}
    </div>
  );
};
