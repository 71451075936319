import styles from './EntityPickerActions.module.scss';

import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  ContractDeliveryNotePicker,
  IContractDeliveryNotePickerProps,
} from '@work4all/components/lib/components/entity-picker/contract-delivery-note-picker/ContractDeliveryNotePicker';

import { Contract } from '@work4all/models/lib/Classes/Contract.entity';
import { DeliveryNote } from '@work4all/models/lib/Classes/DeliveryNote.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

interface ContractDeliveryNotePickerFieldProps
  extends Omit<
    IContractDeliveryNotePickerProps<false>,
    'multiple' | 'onChange'
  > {
  error?: string;
  label?: string;
  onChange: (value: Contract | DeliveryNote, entity: Entities) => void;
}

export const ContractDeliveryNotePickerField = React.forwardRef<
  HTMLDivElement,
  ContractDeliveryNotePickerFieldProps
>(function ContractDeliveryNotePickerField(props, ref) {
  const { error, ...pickerProps } = props;
  const { value, onChange } = pickerProps;

  const { t } = useTranslation();
  const [entity, setEntity] = useState(() => {
    return value ? getEntity(value) : Entities.contract;
  });

  const popoverRef = useRef<EntityPickerPopover>(null);

  const inputValue = value?.note ?? '';

  const label = value
    ? getEntity(value) === Entities.contract
      ? t('COMMON.CONTRACT')
      : t('COMMON.DELIVERYNOTE')
    : t('COMMON.CONTRACT_DELIVERYNOTE');

  return (
    <EntityPickerPopover
      ref={popoverRef}
      picker={
        <ContractDeliveryNotePicker
          {...pickerProps}
          value={value}
          multiple={false}
          onTabChange={setEntity}
          onChange={(value) => {
            onChange(value, entity);
          }}
        />
      }
    >
      <PickerTargetButton
        ref={ref}
        value={inputValue}
        data-test-id="contractDeliveryNote-picker-field"
        label={
          <>
            {!value && label}
            {value && (
              <>
                <span className={styles.primaryInfo}>{label} &nbsp;</span>
                <span className={styles.secondaryInfo}>
                  {getEntity(value) === Entities.contract
                    ? value?.contractNumber
                    : value?.number}
                </span>
              </>
            )}
          </>
        }
        error={error}
        onClear={() => {
          onChange(null, entity);
        }}
      />
    </EntityPickerPopover>
  );
});

function getEntity(value: Contract | DeliveryNote): Entities {
  if (value.__typename === 'Auftrag') {
    return Entities.contract;
  }
  return Entities.deliveryNote;
}
