import styles from './FixedLengthInput.module.scss';

import { ChangeEventHandler } from 'react';

export const FixedLengthInput = (props: {
  onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  length: number;
  autoFocus?: boolean;
}) => {
  const { length, onChange } = props;

  return (
    <input
      {...props}
      onChange={onChange}
      className={styles.input}
      style={{ width: 'calc(' + length * 3 + 'rem + 1px)' }}
    />
  );
};
