import { Add, Delete } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import { ContentColumns } from 'pdfmake/interfaces';
import { useTranslation } from 'react-i18next';

import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';
import { BaseActionButton } from '@work4all/components/lib/input/base-action-button/BaseActionButton';

import { DynamicContentCreator } from '../dynamic-content-creator/DynamicContentCreator';

export const ColumnsEditor = (props: {
  columns: ContentColumns[];
  onChange: (columns: ContentColumns[]) => void;
}) => {
  const { columns, onChange } = props;
  const { t } = useTranslation();
  return (
    <>
      {columns.map((col, colIdx) => {
        return (
          <>
            <Stack
              direction="row"
              gap="0rem"
              width="100%"
              alignContent="center"
            >
              <Divider
                title={t('COMMON.SORT_COLUMN_EMPTY') + ' ' + (colIdx + 1)}
                style={{ width: '100%', padding: 0 }}
              />
              <IconButton
                onClick={() => {
                  const result = [...columns];
                  result.splice(colIdx, 1);
                  onChange(result);
                }}
                size="small"
              >
                <Delete />
              </IconButton>
            </Stack>

            <DynamicContentCreator
              content={col}
              onChange={(changedCol) => {
                const result = [...columns];
                result[colIdx] = changedCol;
                onChange(result);
              }}
            />
          </>
        );
      })}
      <BaseActionButton
        icon={<Add />}
        title={t('COMMON.ADD', { name: t('COMMON.SORT_COLUMN_EMPTY') })}
        onClick={() => {
          const result = [...columns];
          result.push({
            text: '',
          } as ContentColumns);
          onChange(result);
        }}
      />
    </>
  );
};
