import { MenuItem } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useEmailTemplateButton } from './EmailTemplateButtonProvider';

export const EmailTemplateMenuItemButton = () => {
  const { t } = useTranslation();
  const { emailTempaltePickerFieldRef, onClick } = useEmailTemplateButton();
  return (
    <MenuItem key={'email'} ref={emailTempaltePickerFieldRef} onClick={onClick}>
      {t('TICKET.SEND_EMAIL')}
    </MenuItem>
  );
};
