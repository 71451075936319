import { DateTime } from 'luxon';
import { useCallback, useState } from 'react';

import { InputRaZahlung } from '@work4all/models/lib/Classes/InputRaZahlung.entity';

import { usePaymentMutations } from './use-payment-mutations';

interface Data {
  discount: number;
  discountAmount: number;
  savedPaymentAmount: number;
  paymentAmount: number;
}

interface Props {
  incomingInvoiceId: number;
  discount: number;
}

export const useBookingAssistance = ({
  incomingInvoiceId,
  discount,
}: Props) => {
  const { addDirectly } = usePaymentMutations({ incomingInvoiceId });
  const [data, setData] = useState<Data>({
    discount: discount || 0,
    discountAmount: 0,
    paymentAmount: 0,
    savedPaymentAmount: 0,
  });

  const canCreate = data.paymentAmount > 0 || data.discountAmount > 0;

  const reset = useCallback(() => {
    setData({
      discount: discount || 0,
      discountAmount: 0,
      paymentAmount: 0,
      savedPaymentAmount: 0,
    });
  }, [discount]);

  const create = useCallback(() => {
    if (canCreate) {
      const dataToAdd: InputRaZahlung[] = [];
      const buchungsDatum = DateTime.now().toISO();

      if (data.paymentAmount > 0) {
        dataToAdd.push({
          summe: data.paymentAmount,
          buchungsDatum,
        });
      }

      if (data.discountAmount > 0) {
        dataToAdd.push({
          summe: data.discountAmount,
          buchungsDatum,
        });
      }

      addDirectly(dataToAdd);
      reset();
    }
  }, [canCreate, data.paymentAmount, data.discountAmount, addDirectly, reset]);

  const edit = useCallback(
    (key: keyof Omit<Data, 'savedPaymentAmount'>, value: string | number) => {
      const editedData = { ...data, [key]: Number(value) || 0 };

      if (key === 'paymentAmount') {
        editedData.savedPaymentAmount = editedData.paymentAmount;
        editedData.discountAmount =
          editedData.paymentAmount * editedData.discount * 0.01;
      } else if (key === 'discountAmount') {
        const paymentAmountAfterDiscount =
          editedData.savedPaymentAmount - editedData.discountAmount;

        if (paymentAmountAfterDiscount > 0) {
          editedData.paymentAmount = paymentAmountAfterDiscount;
        }
      } else if (key === 'discount') {
        editedData.discountAmount =
          editedData.savedPaymentAmount * editedData.discount * 0.01;
        editedData.paymentAmount =
          editedData.savedPaymentAmount - editedData.discountAmount;
      }

      setData(editedData);
    },
    [data]
  );

  return { data, canCreate, create, edit };
};
