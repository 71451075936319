import { TicketKind } from '@work4all/models/lib/Classes/TicketKind.entity';

import { TicketKindPicker } from '../../../../../entity-picker/TicketKindPicker/TicketKindPicker';
import { withExtras } from '../../utils';

import { PreviewPickerInput } from './PreviewPickerInput';

export const TicketKindPreviewPickerInput = withExtras<TicketKind>(
  ({ value, onChange, ...rest }) => {
    return (
      <PreviewPickerInput<TicketKind>
        {...rest}
        picker={
          <TicketKindPicker
            value={value}
            onChange={(value) => {
              onChange(value);
            }}
            multiple={false}
          />
        }
        value={value}
        formattedValue={value?.name}
      />
    );
  }
);
