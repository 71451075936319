import styles from './OriginalModeContent.module.scss';

import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import { useState } from 'react';

import { useProcessHtml } from '../hooks';

import { ViewContentProps } from './ViewContent';

type OriginalModeContentProps = Pick<ViewContentProps, 'event'>;

export const OriginalModeContent = (props: OriginalModeContentProps) => {
  const { event } = props;

  const html = useProcessHtml(event.text);

  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const handleImageClick = (e) => {
    const target = e.target as HTMLImageElement;
    if (target.tagName === 'IMG' && target.src) {
      setSelectedImage(target.src);
    }
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  return (
    <>
      <Typography
        className={styles.comment}
        component="div"
        variant="body2"
        color="text.primary"
        dangerouslySetInnerHTML={{ __html: html }}
        onClick={handleImageClick}
      />

      <Modal
        open={!!selectedImage}
        onClose={handleClose}
        aria-labelledby="image-modal-title"
        aria-describedby="image-modal-description"
        closeAfterTransition
      >
        <Box className={styles.modal} onClick={handleClose}>
          <IconButton
            aria-label="close"
            className={styles.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <img
            src={selectedImage || ''}
            alt="Full-size"
            className={styles.fullSizeImage}
            onClick={(e) => e.stopPropagation()}
          />
        </Box>
      </Modal>
    </>
  );
};
