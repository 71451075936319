import { forwardRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { useTableStateBag } from '@work4all/components';
import { SupplierGroupPicker } from '@work4all/components/lib/components/entity-picker/entity-group-picker/EntityGroupPicker';

import { Category } from '@work4all/models/lib/Classes/Category.entity';
import { Group } from '@work4all/models/lib/Classes/Group.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { PersonalAndGeneralView } from '../filters/PersonalAndGeneralView';
import { SupplierCategoryPicker } from '../filters/SupplierCategoryPicker';

import { ClosedStatusQuickFilter } from './ClosedStatusQuickFilter';
import { EntityTable, IEntityTable } from './entity-table/EntityTable';
import { useEntityDataTable } from './entity-table/use-entity-data-table';
import { useFileActions } from './FileActions';
import schema from './schemata/supplier-table-schema.json';
import {
  TableQuickFilters,
  TableQuickFiltersSection,
  TableQuickFiltersSectionHeader,
} from './table-quick-filters';

type ISupplierTableCustomColumns = Record<string, never>;

const defaultSort = [{ field: 'name', direction: SortDirection.ASCENDING }];

export const SuppliersTable = forwardRef<TableInstance, IEntityTable>(
  function SuppliersTable(props, ref) {
    const { prepareRowDisplayModifiers, ...dataTable } = useEntityDataTable<
      Supplier,
      ISupplierTableCustomColumns
    >({
      schema: schema as never,
      defaultSort,
    });

    const rowModifiers = useCallback(
      (value) => {
        const modifiers = prepareRowDisplayModifiers(value);
        return { ...modifiers, isFaded: value.extinct === -1 };
      },
      [prepareRowDisplayModifiers]
    );

    const { fileActionDefinitions } = useFileActions({
      entity: Entities.supplier,
    });

    return (
      <EntityTable
        ref={ref}
        areas={{
          left: {
            content: <SuppliersTableQuickFilters />,
            resizable: true,
          },
        }}
        actions={{
          custom: {
            left: fileActionDefinitions,
          },
        }}
        prepareRowDisplayModifiers={rowModifiers}
        {...dataTable}
        {...props}
      />
    );
  }
);

function SuppliersTableQuickFilters() {
  const { t } = useTranslation();

  const tableStateBag = useTableStateBag();

  const columns = {
    group: tableStateBag.columnsById['groupId'],
    categories: tableStateBag.columnsById['categoryAssignmentList.categoryId'],
    closed: tableStateBag.columnsById['extinct'],
  };

  const groups = columns.group?.filterValue?.value ?? null;

  const handleGroupsChange = (groups: Group[]) => {
    const column = columns.group;

    if (!column) {
      return;
    }

    if (groups.length === 0) {
      column.setFilter(null);
    } else {
      column.setFilter({
        value: groups,
        filterType: column.filterType,
      });
    }
  };

  const categories = columns.categories?.filterValue?.value ?? null;

  const handleCategoriesChange = (categories: Category[]) => {
    const column = columns.categories;

    if (!column) {
      return;
    }

    if (categories.length === 0) {
      column.setFilter(null);
    } else {
      column.setFilter({
        value: categories,
        filterType: column.filterType,
      });
    }
  };

  return (
    <TableQuickFilters>
      <ClosedStatusQuickFilter
        entityType={Entities.supplier}
        column={columns.closed}
        label={t('SUPPLIER.CLOSED_STATUS.HIDE_CLOSED')}
      />

      <TableQuickFiltersSection>
        <TableQuickFiltersSectionHeader>
          {t('COMMON.GROUPS')}
        </TableQuickFiltersSectionHeader>
        <SupplierGroupPicker value={groups} onChange={handleGroupsChange} />
      </TableQuickFiltersSection>

      <TableQuickFiltersSection>
        <TableQuickFiltersSectionHeader>
          {t('COMMON.CATEGORIES')}
        </TableQuickFiltersSectionHeader>
        <SupplierCategoryPicker
          value={categories}
          onChange={handleCategoriesChange}
        />
      </TableQuickFiltersSection>

      <PersonalAndGeneralView
        value={groups ?? categories}
        entity={Entities.supplier}
      />
    </TableQuickFilters>
  );
}
