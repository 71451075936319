import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { ProjectDirectoryType } from '../Enums/ProjectDirectoryType.enum';

export class ProjectDirectoryDefinition<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: type */
  type?: ProjectDirectoryType;
  /** Alias: localPathRoot */
  localPathRoot?: string;
  /** Alias: remoteUrl */
  remoteUrl?: string;

  __typename?: string;
}

const fields: FieldDefinitions<ProjectDirectoryDefinition> = {
  id: {
    alias: 'id',
  },
  type: {
    alias: 'type',
  },
  localPathRoot: {
    alias: 'localPathRoot',
  },
  remoteUrl: {
    alias: 'remoteUrl',
  },
  __typename: {
    alias: '__typename',
  },
};

export const projectDirectoryDefinitionEntityDefinition: EntitiyDefinition<ProjectDirectoryDefinition> =
  {
    local: {},
    remote: {
      queryName: 'getProjectDirectoryDefinitions',
      fragmentName: 'ProjectDirectoryDefinition',
      withPaginationWrapper: false,
      mutations: {
        upsert: {
          mutationName: 'upsertProjectDirectoryDefinition',
          args: [
            { name: 'input', type: 'InputProjectDirectoryDefinition!' },
            { name: 'drySave', type: 'Boolean' },
          ],
        },
      },
    },
    fieldDefinitions: fields,
  };
