import React from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  CurrencyPicker,
  ICurrencyPickerProps,
} from '@work4all/components/lib/components/entity-picker/CurrencyPicker';

import { formatCurrency } from '@work4all/utils';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

interface Props extends Omit<ICurrencyPickerProps<false>, 'multiple'> {
  error?: string;
  disabled?: boolean;
}

export const CurrencyPickerField = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { t } = useTranslation();
    const { error, clearable, disabled, ...pickerProps } = props;
    const { value, onChange } = pickerProps;

    return (
      <EntityPickerPopover
        picker={<CurrencyPicker {...pickerProps} multiple={false} />}
      >
        <PickerTargetButton
          ref={ref}
          onClear={
            clearable
              ? () => {
                  onChange(null);
                }
              : undefined
          }
          label={t('INPUTS.CURRENCY')}
          error={error}
          value={(value && formatCurrency(value, t)) || ''}
          disabled={disabled}
        />
      </EntityPickerPopover>
    );
  }
);
