import { useCallback } from 'react';

import { useRowMeasurerContext } from './context';
import { Measurer, MeasurerProps } from './Measurer';

interface RowMeasurerProps extends Omit<MeasurerProps, 'onChange'> {
  index: number;
}

export function RowMeasurer(props: RowMeasurerProps) {
  const { index, children } = props;

  const context = useRowMeasurerContext();

  const { measure } = context;

  const handleOnChange = useCallback(
    (height: number) => {
      measure({ index, height });
    },
    [index, measure]
  );

  return <Measurer onChange={handleOnChange}>{children}</Measurer>;
}
