import styles from './NotePreview.module.scss';

import { Divider } from '@mui/material';
import { MouseEventHandler, useState } from 'react';

import {
  useDataMutation,
  useInaccessibleFields,
  useUser,
} from '@work4all/data';

import { CallMemo } from '@work4all/models/lib/Classes/CallMemo.entity';
import { Note } from '@work4all/models/lib/Classes/Note.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { typeNameToSdObjType } from '@work4all/utils';

import { IConvertPopoverProps } from '../../convert-popover/ConvertPopover';
import { callMemoPreviewFields } from '../call-memo-preview';
import {
  InfoCards,
  PreviewEditMessage,
  PreviewTextContent,
  PreviewWrapper,
} from '../components';
import {
  BusinessPartnerPreviewPickerInput,
  ContactPreviewPickerInput,
  DatePreviewPickerInput,
  PreviewInputsProvider,
  PreviewTitleInput,
  ProjectPreviewPickerInput,
  UserPreviewPickerInput,
} from '../components/preview-input';
import { EntityHistory } from '../EntityHistory';
import { useEntityPreview } from '../hooks/use-entity-preview';

import { notePreviewFields } from './NotePreviewContainer';

type EntityUntion = CallMemo | Note;

export type INotePreviewProps = {
  isCallMemo?: boolean;
  notes: EntityUntion[];
  onCloseClicked?: MouseEventHandler<HTMLButtonElement>;
  onEditClicked?: MouseEventHandler<HTMLButtonElement>;
  onShareClicked?: () => void;
  convertProps?: Pick<IConvertPopoverProps, 'exclude' | 'onClick'>;
};

export function NotePreview(props: INotePreviewProps) {
  const {
    notes,
    onCloseClicked,
    onEditClicked,
    isCallMemo,
    onShareClicked,
    convertProps,
  } = props;

  const user = useUser();
  const { isInaccessible } = useInaccessibleFields();
  const entityType = isCallMemo ? Entities.callMemo : Entities.note;

  const [newComment, setNewComment] = useState('');

  const titleIsAccessible = !isInaccessible(entityType, 'title');

  const [mutate] = useDataMutation<CallMemo | Note, EMode.upsert>({
    entity: isCallMemo ? Entities.callMemo : Entities.note,
    mutationType: EMode.upsert,
    responseData: isCallMemo
      ? (callMemoPreviewFields as never as CallMemo)
      : (notePreviewFields as never as Note),
  });

  const {
    loading,
    isMultiselect,
    commonFields,
    activePicker,
    handleLock,
    onPopoverClose,
    onEdit,
    locked,
    lockedByUser,
    canEditAllSelected,
  } = useEntityPreview({
    user,
    subEntityType: entityType,
    entries: notes,
    mutate,
  });

  const customerIsCommon = commonFields.includes('businessPartner');

  const note = notes[0];

  const dateFormatter = (value: string): Date => {
    const newDate = new Date(value);
    const prevDate = new Date(note?.date);
    newDate.setHours(prevDate.getHours());
    newDate.setMinutes(prevDate.getMinutes());
    return newDate;
  };

  return (
    <PreviewInputsProvider
      isLoading={loading}
      activeInputAccessor={activePicker}
      handleLock={handleLock}
      commonInputs={commonFields}
      isMultiselect={isMultiselect}
      entity={entityType}
      isLocked={locked}
      lockedByUser={lockedByUser}
      numberOfSelectedRows={notes.length}
      canEditAllSelected={canEditAllSelected}
    >
      <PreviewWrapper>
        <PreviewTitleInput
          onClose={(e) => {
            if (e.target.value !== note?.title) {
              onEdit({ title: e.target.value });
            }
            onPopoverClose();
          }}
          onCloseClicked={onCloseClicked}
          onEditClicked={onEditClicked}
          onShareClicked={onShareClicked}
          convertProps={convertProps}
          value={note?.title}
          accessor="title"
          disabled={isMultiselect || !titleIsAccessible}
        />
        {isMultiselect && <PreviewEditMessage entityId={String(note.id)} />}

        <InfoCards>
          <UserPreviewPickerInput
            labelTranslationKey="COMMON.EMPLOYEE"
            onClose={onPopoverClose}
            value={note?.user}
            onChange={(value) => {
              onEdit({
                userId: value.id,
              });
            }}
            accessor="user"
          />

          <DatePreviewPickerInput
            labelTranslationKey="COMMON.DATE.TIME"
            onClose={onPopoverClose}
            value={note?.date}
            onChange={(value) => {
              const newDate = dateFormatter(value);
              onEdit({ date: newDate.toISOString() });
            }}
            accessor="date"
            showTime
          />

          <BusinessPartnerPreviewPickerInput
            labelTranslationKey="COMMON.BUSINESS_PARTNER"
            onClose={onPopoverClose}
            value={note?.businessPartner?.data}
            onChange={(value) => {
              onEdit({
                businessPartner: value
                  ? {
                      data: value,
                      businessPartnerType: typeNameToSdObjType(
                        value.__typename
                      ),
                      id: value.id,
                    }
                  : null,
                businessPartnerId: value ? value.id : 0,
                businessPartnerType: typeNameToSdObjType(value?.__typename),
              });
            }}
            accessor="businessPartner"
            showFooter
          />

          <ContactPreviewPickerInput
            labelTranslationKey="COMMON.CONTACT"
            onClose={onPopoverClose}
            value={note?.contact}
            onChange={(value) => {
              onEdit({
                contact: value,
                contactId: value ? value.id : 0,
              });
            }}
            accessor="contact"
            prefilter={[
              {
                businessPartnerType: { $eq: SdObjType.KUNDE },
              },
              {
                businessPartnerId: {
                  $eq: note?.businessPartner?.data.id,
                },
              },
            ]}
            disabled={!customerIsCommon || !note?.businessPartner?.data}
          />

          <ProjectPreviewPickerInput
            labelTranslationKey="COMMON.PROJECT"
            onClose={onPopoverClose}
            value={note?.project}
            onChange={(value) => {
              onEdit({
                project: value,
                projectId: value && !Array.isArray(value) ? value.id : 0,
              });
            }}
            accessor="project"
            showFooter
          />
        </InfoCards>

        <Divider orientation="horizontal" />

        <PreviewTextContent>{note?.note}</PreviewTextContent>

        <Divider orientation="horizontal" />

        <EntityHistory
          fitContainer={false}
          entity={entityType}
          fullWidth
          id={note.id}
          className={styles.hist}
          breadcrumbTitle={note.title}
          newComment={newComment}
          onNewCommentChange={setNewComment}
        />
      </PreviewWrapper>
    </PreviewInputsProvider>
  );
}
