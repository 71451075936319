import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReDueDateClassified } from '@work4all/models/lib/Enums/ReDueDateClassified.enum';

import { FixedDataPicker } from '../fixed-data-picker/FixedDataPicker';
import { IPickerProps } from '../types';

type Props<TMultiple extends boolean> = IPickerProps<
  { id: string; name: string },
  TMultiple
>;

export function DueDateClassifiedPicker<TMultiple extends boolean>(
  props: Props<TMultiple>
) {
  const { t } = useTranslation();
  const data = useMemo(() => {
    return Object.keys(ReDueDateClassified).map((key) => {
      const id = ReDueDateClassified[key];

      return {
        id,
        name: t(`DUE_DATE_CLASSIFIEDS.${id}`),
      };
    });
  }, [t]);

  return <FixedDataPicker dataSet={data} {...props} />;
}
