import { Grid, Stack } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';

import { ParsedCustomFieldConfig } from '@work4all/data/lib/custom-fields';

import { IndividualFieldControlType } from '@work4all/models/lib/Enums/IndividualFieldControlType.enum';

import { useFormContextPlus } from '../../../form-plus/use-form-context-plus';
import { useMaskContext } from '../../hooks/mask-context';
import { ControlWrapper } from '../control-wrapper';

import { encodeCustomFieldName } from './custom-field-name';
import {
  CustomCheckboxField,
  CustomDateField,
  CustomNumberField,
  CustomPickerField,
  CustomRadioGroupField,
  CustomTextField,
} from './form-fields';
import { CustomFieldProps } from './form-fields/types';
import {
  CustomFieldGroup,
  CustomFieldSubgroup,
  groupCustomFields,
} from './group-custom-fields';
import { WithCustomFields } from './with-custom-fields';

type CustomFieldsProps = {
  /**
   * The number of columns in the grid container for custom fields.
   *
   * @default 12
   */
  columns?: number;
};

export function CustomFields({ columns }: CustomFieldsProps) {
  const { t } = useTranslation();

  const mask = useMaskContext<WithCustomFields<object>>();

  const groupedFields = useMemo(() => {
    return groupCustomFields(mask.customFields);
  }, [mask.customFields]);

  const form = useFormContextPlus<WithCustomFields<object>>();

  const { watch } = form;

  const renderGroup = (group: CustomFieldGroup) => {
    const title = group.isDefault
      ? t('INPUTS.ADDITIONAL_INFORMATION')
      : group.name;

    return (
      <Collapse key={`group:${group.id}`} title={title} defaultOpen={true}>
        <Stack direction="column" gap={'1rem'}>
          {group.subgroups.map(renderSubgroup)}
        </Stack>
      </Collapse>
    );
  };

  const renderSubgroup = (subgroup: CustomFieldSubgroup) => {
    return (
      <Grid
        key={`subgroup:${subgroup.id}`}
        container
        spacing={2}
        columns={columns}
      >
        {subgroup.fields.map((field) => {
          return (
            <Grid key={`field:${field.id}`} item xs={12} md={6} lg={3}>
              {renderField(field)}
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const renderField = (field: ParsedCustomFieldConfig) => {
    const name = `_customFields.${encodeCustomFieldName(field.id)}` as const;
    const { name: label, options, fieldType } = field;

    const exists = watch(name) !== undefined;
    if (!exists) return null;

    const CustomFieldComponent = getCustomFieldComponent(fieldType);
    if (!CustomFieldComponent) return null;

    return (
      <ControlWrapper>
        <CustomFieldComponent name={name} label={label} options={options} />
      </ControlWrapper>
    );
  };

  return groupedFields.groups.map(renderGroup) as unknown as JSX.Element;
}

const CUSTOM_FIELDS_MAP: Partial<
  Record<IndividualFieldControlType, React.FC<CustomFieldProps>>
> = {
  [IndividualFieldControlType.TEXT]: CustomTextField,
  [IndividualFieldControlType.NUMERIC]: CustomNumberField,
  [IndividualFieldControlType.DATE_TYPE]: CustomDateField,
  [IndividualFieldControlType.CHECK]: CustomCheckboxField,
  [IndividualFieldControlType.RADIO]: CustomRadioGroupField,
  [IndividualFieldControlType.COMBO]: CustomPickerField,
};

function getCustomFieldComponent(fieldType: IndividualFieldControlType) {
  return CUSTOM_FIELDS_MAP[fieldType] ?? null;
}
