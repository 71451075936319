import styles from './BreadCrumbItem.module.scss';

import { ButtonBase } from '@mui/material';
import React, { MouseEventHandler } from 'react';

import { Caption } from '../../../../typography/caption/Caption';

interface IBreadcrumbItemProps {
  children?: React.ReactNode;
  onClick?: MouseEventHandler<HTMLElement>;
}

export const BreadcrumbItem: React.FC<IBreadcrumbItemProps> = (props) => {
  return (
    <ButtonBase onClick={props.onClick}>
      <Caption className={styles.caption}>{props.children}</Caption>
    </ButtonBase>
  );
};
