import styles from './SelectableTree.module.scss';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  TreeItem,
  TreeItemContentProps,
  TreeItemProps,
  TreeView,
  useTreeItem,
} from '@mui/lab';
import { Checkbox, Typography } from '@mui/material';
import clsx from 'clsx';
import {
  forwardRef,
  JSXElementConstructor,
  PropsWithoutRef,
  useMemo,
} from 'react';

/**
 * Represent a node to be rendered in the tree.
 */
export interface TreeNode {
  /**
   * The unique identifier of the node.
   */
  id: string;

  /**
   * The tree node label.
   *
   * If the label is a string, it will also be used as the value for node's `title`.
   */
  label: React.ReactNode;

  /**
   * The list child nodes. If `null` or `undefined`, the node is a leaf and
   * represent a selectable value. Group nodes visually group other group or
   * leaf nodes and cannot be selected themselves.
   */
  children?: readonly TreeNode[] | null;
}

// TODO Rework SelectableTree to manage selection of leaf/group nodes
// internally.

interface SingleSelectSelectableTreeProps {
  /**
   * If `true`, multiple nodes can be selected and a checkbox will be added to
   * leaf items. If `selectable` is `'all'`, a checkbox will also be added to
   * group items, that can be used to select/deselect the entire group.
   *
   * @default false
   */
  multiple?: false;

  /**
   *
   * Determines whether or not group nodes can be selected.
   *
   * In single select mode, if `selectable` is `'all'`, a group node can be
   * selected by clicking on it. Otherwise, clicking a group node will
   * expand/collapse the group.
   *
   * In multi select mode nodes can only be selected by clicking the checkbox.
   * Clicking a group node will expand/collapse the group. If `selectable` is
   * `'all'`, a checkbox will be rendered in the group node that can be used to
   * select/deselect the entire group.
   *
   * @default 'all'
   */
  selectable?: 'all' | 'leaf';

  /**
   * A list of nodes to display in the tree. A node can be a leaf node or a
   * group node. A node is considered a leaf node if its `children` is
   * either `null` or `undefined`). Group nodes can have children, but can not
   * be selected.
   *
   * (Currently group nodes can and must be selected with `selected` prop for UI
   * to work properly. This requires a rework to fix and move all selection
   * management inside the component.)
   */
  data: TreeNode[];

  /**
   * Id of the selected node.
   */
  selected: string;

  /**
   * Callback called when tree nodes are selected/deselected.
   *
   * @param nodeId Id of the selected node.
   */
  onChange?: (nodeId: string) => void;
  onNodeToggle?: (event: React.SyntheticEvent, nodeIds: string[]) => void;
  expanded?: string[];
}

interface MultiSelectSelectableTreeProps {
  /**
   * If `true`, multiple nodes can be selected and a checkbox will be added to
   * leaf items. If `selectable` is `'all'`, a checkbox will also be added to
   * group items, that can be used to select/deselect the entire group.
   *
   * @default false
   */
  multiple: boolean;

  /**
   *
   * Determines whether or not group nodes can be selected.
   *
   * In single select mode, if `selectable` is `'all'`, a group node can be
   * selected by clicking on it. Otherwise, clicking a group node will
   * expand/collapse the group.
   *
   * In multi select mode nodes can only be selected by clicking the checkbox.
   * Clicking a group node will expand/collapse the group. If `selectable` is
   * `'all'`, a checkbox will be rendered in the group node that can be used to
   * select/deselect the entire group.
   *
   * @default 'all'
   */
  selectable?: 'all' | 'leaf';

  /**
   * A list of nodes to display in the tree. A node can be a leaf node or a
   * group node. A node is considered a leaf node if its `children` is
   * either `null` or `undefined`). Group nodes can have children, but can not
   * be selected.
   *
   * (Currently group nodes can and must be selected with `selected` prop for UI
   * to work properly. This requires a rework to fix and move all selection
   * management inside the component.)
   */
  data: TreeNode[];

  /**
   * Ids of the selected node.
   */
  selected: string[];

  /**
   * Callback called when tree nodes are selected/deselected.
   *
   * @param nodeId Ids of the selected node.
   */
  onChange?: (nodeIds: string[]) => void;
  onNodeToggle?: (event: React.SyntheticEvent, nodeIds: string[]) => void;
  expanded?: string[];
}

type SelectableTreeProps =
  | SingleSelectSelectableTreeProps
  | MultiSelectSelectableTreeProps;

interface CustomContentOurProps {
  multiple: boolean;
  selectable: 'all' | 'leaf';
  isLeaf: boolean;
  isFlat: boolean;
  getNodeSelectionState: GetNodeSelectionState;
}

interface CustomContentProps
  extends CustomContentOurProps,
    PropsWithoutRef<Omit<TreeItemContentProps, keyof CustomContentOurProps>> {}

const CustomContent = forwardRef<HTMLDivElement, CustomContentProps>(
  function CustomContent(props, ref) {
    const {
      classes,
      className,
      label,
      nodeId,
      icon: iconProp,
      expansionIcon,
      displayIcon,
      multiple,
      selectable,
      isLeaf,
      getNodeSelectionState,
    } = props;

    const {
      disabled,
      expanded,
      selected,
      focused,
      handleExpansion,
      handleSelection,
    } = useTreeItem(nodeId);

    const icon = iconProp || expansionIcon || displayIcon;

    const handleSelectionClick: React.MouseEventHandler = (event) => {
      // Simulate ctrl+click on to add(remove) the node to the current selection
      // instead of replacing it.
      event.ctrlKey = true;
      handleSelection(event);
    };

    const handleExpansionClick: React.MouseEventHandler = (event) => {
      event.stopPropagation();
      handleExpansion(event);
    };

    const handleCheckboxClick: React.MouseEventHandler = (event) => {
      // Prevent double handling of click events on checkboxes.
      event.stopPropagation();

      // Simulate ctrl+click on to add(remove) the node to the current selection
      // instead of replacing it.
      event.ctrlKey = true;
      handleSelectionClick(event);
    };

    const showCheckbox = multiple && (isLeaf || selectable === 'all');

    const { checked, indeterminate } = getNodeSelectionState(nodeId);

    const canClickSelectNode = isLeaf || selectable === 'all';

    return (
      <div
        ref={ref}
        className={clsx(className, classes.root, {
          [classes.expanded]: expanded,
          [classes.selected]: !multiple && selected,
          [classes.focused]: focused,
          [classes.disabled]: disabled,
        })}
        onClick={
          canClickSelectNode ? handleSelectionClick : handleExpansionClick
        }
      >
        <div className={classes.iconContainer} onClick={handleExpansionClick}>
          {icon}
        </div>

        {showCheckbox && (
          <Checkbox
            sx={{ padding: 1, marginY: -1 }}
            checked={checked}
            indeterminate={indeterminate}
            onClick={handleCheckboxClick}
          />
        )}

        <Typography
          className={classes.label}
          component="div"
          noWrap
          title={typeof label === 'string' ? label : undefined}
        >
          {label}
        </Typography>
      </div>
    );
  }
);

interface CustomTreeItemProps
  extends CustomContentOurProps,
    PropsWithoutRef<
      Omit<
        TreeItemProps,
        | keyof CustomContentOurProps
        | 'classes'
        | 'ContentComponent'
        | 'ContentProps'
      >
    > {}

const CustomTreeItem = (props: CustomTreeItemProps) => {
  const {
    multiple,
    selectable,
    isLeaf,
    isFlat,
    getNodeSelectionState,
    ...otherProps
  } = props;

  const customContentProps = {
    multiple,
    selectable,
    isLeaf,
    getNodeSelectionState,
  };

  return (
    <TreeItem
      classes={{
        root: styles.treeItem,
        content: styles.content,
        group: styles.group,
        iconContainer: isFlat
          ? styles['iconContainer--hidden']
          : styles.iconContainer,
        focused: styles.focused,
        selected: styles.selected,
        label: styles.label,
      }}
      ContentComponent={
        CustomContent as JSXElementConstructor<TreeItemContentProps>
      }
      // @ts-expect-error: MUI types seem to be wrong here
      ContentProps={customContentProps}
      {...otherProps}
    />
  );
};

interface NodeSelectionState {
  checked: boolean;
  indeterminate: boolean;
}

interface GetNodeSelectionState {
  (nodeId: string): NodeSelectionState;
}

/**
 * this picker is supposed to get a treelike dataset and can then single or multiselect nodes and or parents
 * @param props
 * @returns
 */
export const SelectableTree = (props: SelectableTreeProps) => {
  const {
    multiple = false,
    selectable = 'all',
    data,
    selected,
    onChange,
    onNodeToggle,
    expanded,
  } = props;

  const nodeById = useMemo(() => {
    const withChildrenFlat = (node: TreeNode) => {
      if (!node.children) {
        return [node];
      }

      return [node, ...node.children.flatMap(withChildrenFlat)];
    };

    const allNodesFlat = data.flatMap(withChildrenFlat);

    const nodeById = new Map<string, TreeNode>();

    for (const node of allNodesFlat) {
      nodeById.set(node.id, node);
    }

    return nodeById;
  }, [data]);

  const getNodeSelectionState = useMemo<GetNodeSelectionState>(() => {
    const selectedSet = new Set(selected);
    const cache = new Map<string, NodeSelectionState>();

    function getNodeSelectionState(nodeId: string): NodeSelectionState {
      if (cache.has(nodeId)) {
        return cache.get(nodeId);
      }

      const node = nodeById.get(nodeId);

      if (!node) {
        // This should never happen, unless there is an error somewhere in
        // CustomContent component.
        throw new Error(`Node with id ${nodeId} doesn't exist`);
      }

      // If a group node's id present in the provided selected array, just
      // render it as selected.

      const isSelected = selectedSet.has(nodeId);

      if (isSelected) {
        const result: NodeSelectionState = {
          checked: true,
          indeterminate: false,
        };

        cache.set(nodeId, result);

        return result;
      }

      // If it's a normal leaf node and it's not selected, render it as
      // unchecked.

      if (!node.children) {
        const result: NodeSelectionState = {
          checked: false,
          indeterminate: false,
        };

        cache.set(nodeId, result);

        return result;
      }

      // This is a group node and it has not been explicitly selected.
      // Derive its state from the children.

      const childrenStates = node.children.map((node) => {
        return getNodeSelectionState(node.id);
      });

      const checked = childrenStates.every((state) => state.checked);
      const indeterminate =
        !checked &&
        childrenStates.some((state) => state.checked || state.indeterminate);

      const result: NodeSelectionState = {
        checked,
        indeterminate,
      };

      cache.set(nodeId, result);

      return result;
    }

    return getNodeSelectionState;
  }, [selected, nodeById]);

  const isFlat = data.every((x) => !x.children);

  const renderTree = (nodes: TreeNode[]) => {
    return nodes.map((node) => {
      const { id, label, children } = node;

      return (
        <CustomTreeItem
          key={id}
          nodeId={id}
          label={label}
          multiple={multiple}
          selectable={selectable}
          isLeaf={children == null}
          isFlat={isFlat}
          getNodeSelectionState={getNodeSelectionState}
        >
          {Array.isArray(children) ? renderTree(children) : null}
        </CustomTreeItem>
      );
    });
  };

  const handleSelect = (
    _event: React.SyntheticEvent,
    nodeIds: string | string[]
  ) => {
    if (props.onChange) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange(nodeIds as any);
    }
  };

  return (
    // The typings should be enforced by our component props already, but there
    // is no way to correctly infer them here because of 2 different "multiple"
    // modes.

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <TreeView
      expanded={expanded}
      onNodeToggle={onNodeToggle}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      selected={selected}
      onNodeSelect={handleSelect}
      multiSelect={multiple}
    >
      {renderTree(data)}
    </TreeView>
  );
};
