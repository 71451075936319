import { useEffect, useMemo, useState } from 'react';

import { useLock } from '@work4all/components/lib/hooks/object-lock/useLock';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { NEW_ENTITY_ID } from '../mask-metadata';

interface UseMaskLockParams {
  entity: Entities;
  id?: string | number | null;
}

/**
 * Automatically manages the lock state within a mask.
 *
 * Will lock the entity on mount and unlock on unmount. Will ignore masks opened
 * in "create" mode.
 *
 * @example
 * // Just pass the object returned by useMaskConfig for simplicity.
 * const mask = useMaskConfig(props);
 * const lock = useMaskLock(mask);
 *
 * // Or only pass entity type and id explicitly.
 * const mask = useMaskConfig(props);
 *
 * const { entity, id } = mask;
 *
 * const lock = useMaskLock({ entity, id });
 */
export function useMaskLock(params: UseMaskLockParams) {
  const { entity, id } = params;

  const wrappedId = useMemo(() => {
    return id === NEW_ENTITY_ID || id == null ? [] : [id.toString()];
  }, [id]);

  const { lock, unlock, loading, locked } = useLock({
    subEntityType: entity,
    subEntityIds: wrappedId,
  });

  // The lock is initialized to {loading: false, locked: false} on the initial
  // render. Keep track of its initialized state so don't render the mask until
  // we know if the lock has been acquired successfully.
  const [isLockInitialized, setIsLockInitialized] = useState(false);

  const isLoading = isLockInitialized ? loading : true;
  const isLocked = locked;

  useEffect(() => {
    lock();
    setIsLockInitialized(true);

    return () => {
      unlock();
    };
  }, [id, lock, unlock]);

  return { isLoading, isLocked };
}
