import { useTranslation } from 'react-i18next';

import { RePaymentStatus } from '@work4all/models/lib/Enums/RePaymentStatus.enum';

type Props = {
  value: RePaymentStatus;
};

export const PaymentStatusCell = ({ value }: Props) => {
  const { t } = useTranslation();

  return t(`PAYMENT_STATUSES.${value}`);
};
