import { useTranslation } from 'react-i18next';

import { RePermitStatus } from '@work4all/models/lib/Enums/RePermitStatus.enum';

type Props = {
  value: RePermitStatus;
};

export const PermitStatusCell = ({ value }: Props) => {
  const { t } = useTranslation();

  if (value === RePermitStatus.EMPTY) return null;
  return t(`PERMIT_STATUSES.${value}`);
};
