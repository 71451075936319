import styles from '@work4all/components/lib/components/split-button/SplitButton.module.scss';

import EmailIcon from '@mui/icons-material/Email';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, ButtonGroup, Fab, Theme, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useEmailTemplateButton } from './EmailTemplateButtonProvider';

export const EmailTemplateSplitButton = () => {
  const { t } = useTranslation();
  const { emailTempaltePickerFieldRef, onClick, onArrowClick } =
    useEmailTemplateButton();
  const md = useMediaQuery<Theme>((t) => t.breakpoints.down('md'));

  return md ? (
    <Fab
      ref={emailTempaltePickerFieldRef}
      color="primary"
      size="medium"
      data-test-id={'more-icon-button'}
      onClick={onArrowClick}
    >
      <EmailIcon />
    </Fab>
  ) : (
    <ButtonGroup ref={emailTempaltePickerFieldRef} variant="contained">
      <Button
        style={{
          borderRightColor: 'white',
        }}
        className={clsx(styles['border'], styles['not-bold'])}
        onClick={onClick}
      >
        {t('COMMON.EMAIL')}
      </Button>
      <Button size="small" onClick={onArrowClick}>
        <KeyboardArrowDownIcon />
      </Button>
    </ButtonGroup>
  );
};
