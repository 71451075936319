import React from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  ITicketCategory1PickerProps,
  TicketCategory1Picker,
} from '@work4all/components/lib/components/entity-picker/TicketCategory1Picker';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

interface Props extends Omit<ITicketCategory1PickerProps<false>, 'multiple'> {
  error?: string;
}

export const TicketCategory1PickerField = React.forwardRef<
  HTMLDivElement,
  Props
>((props, ref) => {
  const { error, ...pickerProps } = props;
  const { value, onChange } = pickerProps;

  const { t } = useTranslation();

  return (
    <EntityPickerPopover
      picker={<TicketCategory1Picker {...pickerProps} multiple={false} />}
    >
      <PickerTargetButton
        ref={ref}
        onClear={() => {
          onChange(null);
        }}
        label={t('INPUTS.TICKET_CATEGORY')}
        error={error}
        value={value?.name || ''}
      />
    </EntityPickerPopover>
  );
});
