import styles from './Communication.module.scss';

import { Theme, useMediaQuery } from '@mui/material';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';

import { EMailSuggestionPickerField } from '../../../../../../../../../../components/entity-picker/EMailSuggestionPickerField';
import { ControllerPlus } from '../../../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import { LabeledInput } from '../../../../../../../../locked-inputs';
import { ControlWrapper } from '../../../../../../../components/control-wrapper';
import { EMailSuggestionContext } from '../../../../../ContactsOverlayController';
import { ContactMaskFormValue } from '../../../../../types';

export const Communication: React.FC = () => {
  const { t } = useTranslation();

  const { register, watch, setValue, control } =
    useFormContextPlus<ContactMaskFormValue>();

  const firstName = watch('firstName');
  const lastName = watch('name');
  const sdObjMemberId = watch('businessPartnerId');
  const sdObjMemberType = watch('businessPartnerType');
  const { enableEMailSuggestions, setEnableEMailSuggestions } = useContext(
    EMailSuggestionContext
  );

  const mobile = useMediaQuery<Theme>('(max-width:960px)');

  return (
    <Collapse defaultOpen={!mobile} title={t('MASK.COMMUNICATION')}>
      <ControlWrapper className={styles.mobileVert}>
        <ControllerPlus
          name={'eMail'}
          control={control}
          render={({ field }) => {
            return (
              <EMailSuggestionPickerField
                {...field}
                firstName={firstName}
                businesspartnerType={sdObjMemberType}
                lastName={lastName}
                businessPartnerId={sdObjMemberId}
                onChange={(value) => {
                  setEnableEMailSuggestions(false);
                  setValue('eMail', value, { shouldDirty: true });
                }}
                color={enableEMailSuggestions ? 'warning' : undefined}
              />
            );
          }}
        />
        <LabeledInput
          label={t('INPUTS.PHONE_AND_EXTENSION_NUMBERS')}
          {...register('phoneNumber')}
        />
        <LabeledInput
          label={t('INPUTS.MOBILE')}
          {...register('mobileNumber')}
        />
      </ControlWrapper>
      <ControlWrapper className={styles.mobileVert}>
        <LabeledInput label={t('INPUTS.FAX')} {...register('faxNumber')} />
        <LabeledInput
          label={t('INPUTS.ADDITIONAL')}
          {...register('phoneNumber2')}
        />
        <LabeledInput
          label={t('INPUTS.ADDITIONAL')}
          {...register('phoneNumber3')}
        />
      </ControlWrapper>
    </Collapse>
  );
};
