import styles from './table/Table.module.scss';

import AddIcon from '@mui/icons-material/Add';
import { IconButton, Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { useTableStateBag } from '@work4all/components';
import { Heading4 } from '@work4all/components/lib/typography/heading4/Heading4';

import { DocumentTemplateGroup } from '@work4all/models/lib/Classes/DocumentTemplateGroup.entity';
import { WordDocumentTemplate } from '@work4all/models/lib/Classes/WordDocumentTemplate.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';

import { WordDocumentTemplateGroupPicker } from '../filters/WordDocumentTemplateGroupPicker';

import { EntityTable, IEntityTable } from './entity-table/EntityTable';
import { useEntityDataTable } from './entity-table/use-entity-data-table';
import schema from './schemata/wordDocumentTemplate-table-schema.json';

type IDocumentTemplateCustomColumns = Record<string, never>;

const defaultSort = [{ field: 'body', direction: SortDirection.ASCENDING }];

export const WordDocumentTemplateTable = React.forwardRef<
  TableInstance,
  IEntityTable
>(function WordDocumentTemplateTable(props, ref) {
  const { forceRequestFields, ...rest } = props;

  const dataTable = useEntityDataTable<
    WordDocumentTemplate,
    IDocumentTemplateCustomColumns
  >({
    schema: schema as never,
    defaultSort,
    forceRequestFields,
  });

  return (
    <EntityTable
      ref={ref}
      {...dataTable}
      areas={{
        left: {
          content: <WordDocumentTemplateTableQuickFilters />,
        },
      }}
      {...rest}
    />
  );
});

function WordDocumentTemplateTableQuickFilters() {
  const { t } = useTranslation();

  const tableStateBag = useTableStateBag();

  const columns = {
    group: tableStateBag.columnsById['groupId'],
  };

  const groups = columns.group?.filterValue?.value ?? null;

  const handleGroupsChange = (groups: DocumentTemplateGroup[]) => {
    const column = columns.group;
    if (!column) {
      return;
    }

    if (groups.length === 0) {
      column.setFilter(null);
    } else {
      column.setFilter({
        value: groups,
        filterType: column.filterType,
      });
    }
  };

  return (
    <Stack height="100%">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <div className={styles.filterHeadline}>
          <Heading4>{t('FIELDS.folder')}</Heading4>
        </div>
        <IconButton color="primary" disabled>
          <AddIcon />
        </IconButton>
      </Stack>

      <div className="custom-scrollbar" style={{ flex: 1, overflow: 'auto' }}>
        <WordDocumentTemplateGroupPicker
          value={groups}
          onChange={handleGroupsChange}
        />
      </div>
    </Stack>
  );
}
