import styles from './Caption.module.scss';

import { Theme, Typography, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import React, { PropsWithChildren } from 'react';

export const Caption: React.FC<PropsWithChildren<unknown>> = (props) => {
  const upSm = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));

  return (
    <Typography
      component="span"
      className={clsx(
        styles.caption,
        (upSm && styles['caption--up-sm']) || styles['caption--sm']
      )}
    >
      {props.children}
    </Typography>
  );
};
