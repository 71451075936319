import React from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { TableBottomView } from '@work4all/components/lib/components/entity-bottom-view/table-bottom-view';

import { Ra } from '@work4all/models/lib/Classes/Ra.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { EntityTable, IEntityTable } from '../entity-table/EntityTable';
import { useEntityDataTable } from '../entity-table/use-entity-data-table';
import { useDeleteEntitiesToolbarConfig } from '../use-delete-entities-toolbar-config';
import { useErpHandlersTemplate } from '../use-erp-handlers-template';

import schema from './../schemata/outgoing-invoices-table-schema.json';

const defaultSort = [{ field: 'date', direction: SortDirection.DESCENDING }];

export const OutgoingInvoicesTable = React.forwardRef<
  TableInstance,
  IEntityTable
>(function OutgoingInvoicesTable(_props, ref) {
  const { entity: entityType } = schema as never;
  const dataTable = useEntityDataTable<Ra, never>({
    schema: schema as never,
    defaultSort,
  });

  const template = useErpHandlersTemplate('supplier.id', Entities.supplier);

  const { t } = useTranslation();
  const deleteConfig = useDeleteEntitiesToolbarConfig({
    entityType,
  });

  return (
    <EntityTable
      ref={ref}
      template={template}
      {...dataTable}
      actions={{
        remove: {
          ...deleteConfig,
          canDeleteEntity: (id) => {
            const entity = dataTable.selectedEntities.find(
              (item) => item.id === Number(id)
            );
            const value = entity?.payments?.length > 0 ? false : true;

            return {
              value,
              preventMessage: value
                ? null
                : t('ALERTS.OUTGOING_INVOICE_PAYMENTS_EXIST', {
                    number: entity?.rechnungNummer,
                  }),
            };
          },
        },
      }}
      areas={{
        bottom: dataTable.selectedEntity
          ? {
              content: (
                <TableBottomView
                  entity={Entities.ra}
                  selectedEntityId={dataTable.selectedEntity.id}
                  tabs={[Entities.raLedgerAccountSplit, Entities.raPayment]}
                />
              ),
            }
          : undefined,
      }}
    />
  );
});
