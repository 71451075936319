import { ChangeEventHandler } from 'react';

import { LabeledInput } from '../../../../locked-inputs';

import { SimpleCustomFieldProps } from './types';

export function SimpleCustomTextField(props: SimpleCustomFieldProps) {
  const { onChange, ...others } = props;
  return (
    <LabeledInput
      {...others}
      onChange={
        onChange as ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
      }
    />
  );
}
