import { QueryResult, QueryTuple } from '@apollo/client';
import { DocumentNode } from 'graphql';

import { entityDefinition } from '@work4all/models/lib/Classes/entityDefinitions';
import {
  DataRequest,
  FieldDefinitions,
  IFieldData,
  IGenerateParams,
} from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import {
  IListDataRecord,
  IListQueryVars,
} from '@work4all/models/lib/Lists/Lists';

import { invariant, isDev, throwInDev } from '@work4all/utils';
import { generateGraphQLQuery } from '@work4all/utils/lib/graphql-query-generation/generateGraphQLQuery';

import {
  ParsedCustomFieldConfig,
  prepareCustomFieldsDefinitions,
} from '../../../custom-fields';
import { generateLookupQuery } from '../../../data-retriever/hooks/generateLookupQuery';
import { generateUseFileQuery } from '../../../data-retriever/hooks/generateUseFileQuery';

import { fieldsToQueryFields } from './fieldsToQueryFields';

export const buildQuery = (
  requestData: DataRequest,
  pageSize: number,
  customFields?: ParsedCustomFieldConfig[],
  disableValidation?: boolean
) => {
  invariant(
    typeof requestData.entity === 'string',
    '`requestData.entity` must be a string'
  );

  const definition = entityDefinition[requestData.entity];
  invariant(
    definition !== undefined,
    `Could not find definition for entity *${requestData.entity}*`
  );

  if (!disableValidation) {
    validateQueryKeyFields(requestData.data, requestData.entity);
  }

  const queryName = definition.remote.queryName;

  const customFieldsDefinitions = prepareCustomFieldsDefinitions(customFields);
  const fields = {
    ...definition.fieldDefinitions,
    ...customFieldsDefinitions,
  };

  const fieldsToQuery: IFieldData[] = fieldsToQueryFields(
    requestData.data,
    requestData.entity
  );

  // When filtering suppliers or customers by categories, the categories must be
  // passed as a separate argument and not part of the regular filter.
  const { filter, categoryCodes } = extractCategoriesFilter(requestData);

  const transformedFilter = [];
  filter?.forEach((filter) => {
    const prepareFilterElement = (key, filter) => {
      const parts = key.split('.');

      const alias = [];
      let defToUse = fields;
      parts.forEach((el, idx) => {
        const fieldDef = defToUse[el];

        if (!fieldDef) {
          throwInDev(
            `There is missing definition of '${el}' in '${requestData.entity}' entity.`
          );
          return;
        }
        if (Array.isArray(fieldDef.entity)) {
          if (parts[idx + 1] !== undefined) {
            //this is a union type, check if next part is same type in all occurences
            //if so just filter for it, as filter do not reflect union selections such as queries do
            const unionType = entityDefinition[fieldDef.entity[0]];
            const typeWithoutSubField = (fieldDef.entity as string[]).find(
              (el) => !entityDefinition[el]?.fieldDefinitions?.[parts[idx + 1]]
            );
            if (typeWithoutSubField !== undefined) {
              throw new Error(
                'filtering on a union type sub field that not all union members have' +
                  [fieldDef.entity, typeWithoutSubField, parts[idx + 1]].join(
                    ', '
                  )
              );
            }
            defToUse = unionType.fieldDefinitions;
          }
        } else {
          alias.push(fieldDef.alias);
          if (fieldDef.entity) {
            defToUse = entityDefinition[fieldDef.entity].fieldDefinitions;
          }
        }
      });
      return { [alias.join('.')]: filter[key] };
    };

    const mapLogicalFilters = (filter) => {
      const keys = Object.keys(filter);
      if (keys[0] === '$and' || keys[0] === '$or') {
        const logicalFilter = { [keys[0]]: [] };
        filter[keys[0]].forEach((filter) => {
          logicalFilter[keys[0]].push(mapLogicalFilters(filter));
        });
        return logicalFilter;
      } else {
        const pf = prepareFilterElement(keys[0], filter);
        return pf;
      }
    };
    transformedFilter.push(mapLogicalFilters(filter));

    // const keys = Object.keys(filter);

    // if (keys[0] === '$and' || keys[0] === '$or') {
    //   const logicalFilter = { [keys[0]]: [] };
    //   filter[keys[0]].forEach((filter) => {
    //     const subKeys = Object.keys(filter);
    //     const pf = prepareFilterElement(subKeys[0], filter);
    //     logicalFilter[keys[0]].push(pf);
    //   });
    //   transformedFilter.push(logicalFilter);
    // } else {
    //   const pf = prepareFilterElement(keys[0], filter);
    //   transformedFilter.push(pf);
    // }
  });

  const transformedSort = [];

  requestData.sort?.forEach((sort) => {
    const parts = sort.field.split('.');

    const alias = [];
    let defToUse = fields;
    parts.forEach((el) => {
      const fieldDef = defToUse[el];
      const entity = Array.isArray(fieldDef.entity)
        ? fieldDef.entity[0]
        : fieldDef.entity;

      // we are ignoreing the data property
      // because we don't need it when sorting or filtering
      if (fieldDef.alias !== 'data') {
        alias.push(fieldDef.alias);
      }
      if (entity) {
        defToUse = entityDefinition[entity].fieldDefinitions;
      }
    });

    transformedSort.push({ ...sort, field: alias.join('.') });
  });

  const { withPaginationWrapper = true } = definition.remote;
  let query;
  const genParams: IGenerateParams = {
    operationName: requestData.operationName ?? queryName,
    rootField: {
      name: queryName,
      params: definition.remote.params,
    },
    fields: fieldsToQuery,
  };
  let gen: {
    query?: DocumentNode;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    useQuery?: (vars: IListQueryVars) => QueryResult<any, IListQueryVars>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    useLazyQuery?: (vars: IListQueryVars) => QueryTuple<any, IListQueryVars>;
    queryString?: string;
    queryInner?: string;
  };
  if (queryName === 'getLookups') {
    gen = generateLookupQuery(genParams);
    query = gen.query;
  } else if (withPaginationWrapper) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gen = generateUseFileQuery<IListDataRecord<any>>(genParams);
    query = gen.query;
  } else {
    gen = generateGraphQLQuery(genParams);
    query = gen.query;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const vars: Record<any, any> = {
    querySize: pageSize,
    querySortBy:
      transformedSort?.length > 0 ? transformedSort[0].field : undefined,
    querySortOrder:
      transformedSort?.length > 0 ? transformedSort[0].direction : undefined,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any;
  if (transformedFilter.length > 0) {
    vars.filter = JSON.stringify(transformedFilter);
  }

  if (categoryCodes) {
    vars.categoryCodes = categoryCodes;
  }

  if (requestData.vars) {
    Object.assign(vars, requestData.vars);
  }

  return { query, variables: vars, queryName, gen };
};

/**
 * A very simple implementation to extract the filter by categories to a
 * separate variable. Doesn't do anything if the entity type is not "customer"
 * or "supplier".
 *
 * Only works with filters like `[..., {'categoryAssignmentList.categoryId': {
 * ... } }, ...]`, and won't work with more complex conditions (multiple filters
 * in the same object, logical operators). But there are not required at the
 * moment.
 */
function extractCategoriesFilter(params: {
  entity: Entities;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filter?: any[];
}): {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filter?: any[];
  categoryCodes: number[] | null;
} {
  const { entity, filter } = params;

  if (
    !filter ||
    (entity !== Entities.customer &&
      entity !== Entities.supplier &&
      entity !== Entities.project)
  ) {
    return { filter, categoryCodes: null };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function isCategoryFilter(filter: any): boolean {
    return filter['categoryAssignmentList.categoryId'] !== undefined;
  }

  const categoriesFilter = filter.find(isCategoryFilter);

  if (!categoriesFilter) {
    return { filter, categoryCodes: null };
  }

  const categoryCodes = pickCategoriesFromFilter(categoriesFilter);
  const filterWithoutCategories = filter.filter((f) => !isCategoryFilter(f));

  return {
    filter: filterWithoutCategories,
    categoryCodes,
  };
}

function pickCategoriesFromFilter(filter: {
  'categoryAssignmentList.categoryId': { $eq?: number; $in?: number[] };
}): number[] {
  const operators = filter['categoryAssignmentList.categoryId'];

  if (operators.$in) {
    return operators.$in;
  }

  if (operators.$eq) {
    return [operators.$eq];
  }

  return null;
}

// validate whether the query contains required InMemoryCache keyFields
function validateQueryKeyFields(requestedData: unknown, entity: Entities) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fields = // eslint-disable-next-line @typescript-eslint/no-explicit-any
    entityDefinition[entity].fieldDefinitions as FieldDefinitions<any>;
  const isArray = Array.isArray(requestedData);

  // filter arrays
  const nonArrayData =
    isArray && requestedData.length ? requestedData[0] : requestedData;

  // filter objects
  const isObject =
    typeof nonArrayData === 'object' && !Array.isArray(nonArrayData);
  const data =
    isObject && Object.keys(nonArrayData).length !== 0 ? nonArrayData : null;

  if (fields.id && data) {
    throwKeyFieldError(data, entity);
  }

  if (data) {
    Object.entries(data).forEach(([key, value]) => {
      if (!value) return;

      const entities = fields[key]?.entity;

      // check if entites kind is array
      const isEntitesArray = Array.isArray(entities) && entities.length;
      const isArray = Array.isArray(value);
      const isObject = typeof value === 'object' && !isArray;

      if (isEntitesArray) {
        entities.forEach((entity) => {
          const currentValue = Object.keys(value).find((key) => key === entity);
          if (currentValue) {
            validateQueryKeyFields(currentValue, entity as Entities);
          }
        });
      } else if (isArray) {
        value.forEach((val) => {
          validateQueryKeyFields(val, entities as Entities);
        });
      } else if (entity && isObject) {
        validateQueryKeyFields(value, entities as Entities);
      }
    });
  }
}

// throw an error when query does not contain required InMemoryCache keyField
function throwKeyFieldError(data: object, entity: Entities) {
  const isArray = Array.isArray(data);
  const isObject = typeof data === 'object' && !isArray;
  const isObjectNotEmpty = isObject && Object.keys(data).length !== 0;

  if (isObjectNotEmpty && !('id' in data)) {
    if (isDev()) {
      throw new Error(
        `Missing key field in '${entity}' query: ${JSON.stringify(data)}}`
      );
    } else {
      console.warn(
        `Missing key field in '${entity}' query: ${JSON.stringify(data)}}`
      );
    }
  }
}
