import { Tooltip } from '@mui/material';
import { CellProps } from 'react-table';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';

import { MailboxContent } from '@work4all/models/lib/Classes/MailboxContent.entity';
import { MailAssignStatus } from '@work4all/models/lib/Enums/MailAssignStatus.enum';

import { useTableLayoutState } from '../../table-layout';
import { useMailboxContentManagerContext } from '../mailbox-content-manager-context';

import { PickerTargetButton } from './components/PickerTargetButton';
import { SuggestionsFoundText } from './components/SuggestionsFoundText';
import { ProjectPickerWithSuggestions } from './ProjectPickerWithSuggestions';
import { projectAsString, ProjectText } from './ProjectText';

export function MailboxContentProjectCell(
  props: CellProps<MailboxContent, MailAssignStatus>
) {
  const mailboxContent = props.row.original;
  const id = mailboxContent.id;

  const context = useMailboxContentManagerContext();

  const item = context.state.itemsById[id];

  const layoutState = useTableLayoutState();
  const [layout] = layoutState;

  const isEditable =
    mailboxContent.assignStatus === MailAssignStatus.NOT_YET_ASSIGNED;

  const value = isEditable
    ? item?.project
    : mailboxContent?.convertedMail?.project ?? null;

  const possibleProjects = mailboxContent.possibleProjects;

  const textVariant = layout === 'cards' ? 'caption' : 'body2';

  if (!isEditable) {
    return (
      <Tooltip title={projectAsString(value)}>
        <ProjectText project={value} variant={textVariant} />
      </Tooltip>
    );
  }

  return (
    <EntityPickerPopover
      picker={
        <ProjectPickerWithSuggestions
          value={value}
          onChange={(val) => {
            context.onProjectChange(id, val);
          }}
          suggestions={possibleProjects}
        />
      }
    >
      <Tooltip title={projectAsString(value)}>
        <PickerTargetButton
          disabled={!isEditable}
          layout={layout}
          style={{ padding: 0 }}
        >
          {value ? (
            <ProjectText project={value} variant={textVariant} />
          ) : (
            <SuggestionsFoundText
              suggestions={possibleProjects}
              layout={layout}
            />
          )}
        </PickerTargetButton>
      </Tooltip>
    </EntityPickerPopover>
  );
}
