import React from 'react';
import { TableInstance } from 'react-table';

import { useTableStateBag } from '@work4all/components';
import { TravelReceiptsPreviewContainer } from '@work4all/components/lib/components/entity-preview/travel-receipts-preview/TravelReceiptsPreviewContainer';

import { TravelExpenses } from '@work4all/models/lib/Classes/TravelExpenses.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';

import { Table } from '../../components/data-tables/table/Table';
import { OnOpenMask } from '../../containers/mask-overlays/mask-overlay';

import schema from './schemata/travelExpenses-table-schema.json';
import { TableLayoutContext, useTableLayoutState } from './table-layout';
import { useDataTable } from './use-data-table';
import { useDeleteEntitiesToolbarConfig } from './use-delete-entities-toolbar-config';
import { useTableHandlers } from './use-table-handlers';
import { useUserColumnConfigs } from './use-user-column-configs';

const defaultSort = [{ field: 'date', direction: SortDirection.DESCENDING }];

interface Props {
  prefilter?: unknown[];
  onOpenMask: OnOpenMask;
}

//TODO: this table is in progress  - there is another ticket better not touch now I will create a ticket for it
export const TravelExpensesTable = React.forwardRef<TableInstance, Props>(
  function TravelExpensesTable(props, ref) {
    const { prefilter, onOpenMask } = props;
    const { entity: entityType } = schema as never;

    const tableStateBag = useTableStateBag();

    const layoutState = useTableLayoutState();
    const [layout] = layoutState;

    const {
      columnConfigs,
      cardConfig,
      prepareRowDisplayModifiers,
      data,
      selectedEntities,
      fetchMore,
      total,
      pending,
      initialSortBy,
    } = useDataTable<TravelExpenses, never>({
      layout,
      schema: schema as never,
      tableStateBag,
      prefilter,
      defaultSort,
    });

    const tableHandlers = useTableHandlers(entityType, onOpenMask);

    const deleteConfig = useDeleteEntitiesToolbarConfig({
      entityType,
    });

    const [userConfig, userConfigMethods] = useUserColumnConfigs({
      layout,
      entityType,
      columnConfigs,
    });

    if (!userConfig) return null;

    return (
      <TableLayoutContext value={layoutState}>
        <Table
          pending={pending}
          ref={ref}
          layout={layout}
          actions={{
            add: tableHandlers.create,
            edit: tableHandlers.edit,
            remove: deleteConfig,
            resetColumns: userConfigMethods.remove,
            createWidget: true,
          }}
          areas={{
            right: selectedEntities &&
              selectedEntities.length > 0 && {
                content: (
                  <TravelReceiptsPreviewContainer
                    ids={selectedEntities.map((e) => e.id)}
                    onEditClicked={tableHandlers.edit.handler}
                  />
                ),
              },
          }}
          columnConfigs={userConfig}
          cardConfig={cardConfig}
          initialSortBy={initialSortBy}
          loadMoreItems={fetchMore}
          prepareRowDisplayModifiers={prepareRowDisplayModifiers}
          data={data}
          total={total}
        />
      </TableLayoutContext>
    );
  }
);
