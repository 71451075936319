import { gql, useApolloClient } from '@apollo/client';
import { useCallback } from 'react';

import { ProcessedEMailTemplateAttachment } from '@work4all/models/lib/Classes/ProcessedEMailTemplateAttachment.entity';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

const GET_PROCESSED_MAIL_QUERY = gql`
  query GetProcessedMailTemplateV2(
    $templateId: ID
    $templateContent: String
    $userId: Int
    $customerId: Int
    $supplierId: Int
    $projectId: Int
    $contactId: Int
    $businessPartnerType: SdObjType
    $ticketId: ID
    $emailContactIds: [String]
    $textFieldCustom: [StringKeyValue]
    $createTemplateAttachments: Boolean
  ) {
    getProcessedMailTemplateV2(
      templateId: $templateId
      templateContent: $templateContent
      textFieldUserCode: $userId
      textFieldCustomerCode: $customerId
      textFieldSupplierCode: $supplierId
      textFieldProjectCode: $projectId
      textFieldApCode: $contactId
      textFieldApSdObjType: $businessPartnerType
      textFieldTicketId: $ticketId
      textFieldMailContactIds: $emailContactIds
      textFieldCustom: $textFieldCustom
      createTemplateAttachments: $createTemplateAttachments
    ) {
      body
      subject
      createdAttachementTempfiles {
        id
        name
        size
      }
    }
  }
`;

export interface GetProcessedEmailTemplateVariables {
  templateId?: string | null;
  templateContent?: string | null;
  userId?: number | null;
  customerId?: number | null;
  supplierId?: number | null;
  projectId?: number | null;
  contactId?: number | null;
  businessPartnerType?: SdObjType | null;
  ticketId?: string | null;
  emailContactIds?: string[] | null;
  createTemplateAttachments?: boolean | null;
}

interface GetProcessedEmailTemplateResponse {
  getProcessedMailTemplateV2: {
    body: string | null;
    subject: string | null;
    createdAttachementTempfiles: ProcessedEMailTemplateAttachment[];
  };
}

export function useGetProcessedEmailTemplate() {
  const apollo = useApolloClient();

  const getProcessedEmailTemplate = useCallback(
    async (variables: GetProcessedEmailTemplateVariables) => {
      const response = await apollo.query<
        GetProcessedEmailTemplateResponse,
        GetProcessedEmailTemplateVariables
      >({
        query: GET_PROCESSED_MAIL_QUERY,
        variables,
        fetchPolicy: 'network-only',
      });

      if (response.error) throw response.error;

      return {
        ...response.data.getProcessedMailTemplateV2,
        /*
         * When using `getProcessedEmailTemplate` the
         * returned `body` value is an HTML and Froala
         * doesn't format the plain text inside the HTML
         * so we replace the return character and the
         * new line character with a `<br>` tag so we
         * have the same kind of formatting everywhere.
         *
         * Full context: https://work4all.slack.com/archives/C069Z332WG2/p1722354045199599
         */
        body: response.data.getProcessedMailTemplateV2.body.replace(
          /\r\n/g,
          '<br>'
        ),
      };
    },
    [apollo]
  );

  return getProcessedEmailTemplate;
}
