import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputRaZahlung } from './InputRaZahlung.entity';

export class InputRaPaymentRelation<T extends EMode = EMode.entity> {
  /** Alias: add */
  add?: InputRaZahlung<T>[];
  /** Alias: delete */
  delete?: number[];
  /** Alias: modify */
  modify?: InputRaZahlung<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<InputRaPaymentRelation> = {
  add: {
    alias: 'add',
  },
  delete: {
    alias: 'delete',
  },
  modify: {
    alias: 'modify',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputRaPaymentRelationEntityDefinition: EntitiyDefinition<InputRaPaymentRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputRaPaymentRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
