import React from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  IPriorityPickerProps,
  PriorityPicker,
  usePriority,
} from '@work4all/components/lib/components/entity-picker/PriorityPicker';

import { uppercaseFirstLetter } from '@work4all/utils/lib/uppercaseFirstLetter';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

interface Props
  extends Omit<IPriorityPickerProps<false>, 'onChange' | 'multiple' | 'value'> {
  error?: string;
  label?: string;
  onChange: (value: number) => void;
  value: number;
}

export const PriorityPickerField = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { error, label: _label, ...pickerProps } = props;
    const { value, onChange } = pickerProps;

    const { t } = useTranslation();
    const priorities = usePriority();

    return (
      <EntityPickerPopover
        picker={
          <PriorityPicker
            multiple={false}
            value={{ id: value, name: '' }}
            onChange={(value) => {
              onChange(value.id);
            }}
          />
        }
      >
        <PickerTargetButton
          ref={ref}
          label={uppercaseFirstLetter(t('INPUTS.PRIORITY'))}
          error={error}
          value={priorities.find((x) => x.id === value)?.name}
        />
      </EntityPickerPopover>
    );
  }
);
