import { useCallback, useMemo } from 'react';

import { useUsersContext } from '@work4all/data/lib/hooks/users';

import { ExtractMentionsResolveFn } from '../../../../../../input/format-text/TextEditor/plugins/mention/utils';
import { processHtml } from '../utils';

export const useProcessHtml = (html: string) => {
  const { usersById } = useUsersContext();

  const resolve = useCallback<ExtractMentionsResolveFn>(
    ({ id }) => {
      const user = usersById[id];

      if (user && user.displayName) {
        return { text: user.displayName };
      }
    },
    [usersById]
  );

  return useMemo(() => {
    return processHtml(html, resolve);
  }, [html, resolve]);
};
