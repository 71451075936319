import { cloneDeep } from 'lodash';
import { MutableRefObject, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { uuid } from 'short-uuid';

import { useDialogs } from '@work4all/components';

import { Position } from '@work4all/models/lib/Classes/Position.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

import { ShadowObjectAddPositionArgs } from '../../../../../hooks/use-bz-shadow-object-api/use-shadow-bz-object-api';
import { useShadowBzObjectApiContext } from '../../../../../hooks/use-bz-shadow-object-api/use-shadow-bz-object-api-context';
import {
  useEditableState,
  UseEditableStateProps,
} from '../edit-table/hooks/use-editable-state';
import { EditTableEntry, IdType, IEditTable } from '../edit-table/types';

import { recalculatePositions } from './recalculatePositions';
import { useTotalPrice } from './use-total-price';

export type UsePostionState = UseEditableStateProps<
  Position,
  number,
  ShadowObjectAddPositionArgs
>;

interface UsePostionStateProps {
  editTableRef?: MutableRefObject<IEditTable>;
  entity: Entities;
}

export const usePositionState = (props: UsePostionStateProps) => {
  const { editTableRef, entity } = props;
  const shadowBzObjectApi = useShadowBzObjectApiContext();

  const positions = shadowBzObjectApi.positions;

  const { totalPrice, setTotalPrice } = useTotalPrice();

  const mapAddLocal = useCallback(
    (context: ShadowObjectAddPositionArgs) => {
      const { article, autofocus } = context;
      const cacheOnly =
        context.positionType === ErpPositionsKind.TEXTZEILE &&
        context.article?.id === 0;

      if (cacheOnly && autofocus && editTableRef.current)
        setTimeout(() => {
          editTableRef.current.setEditMode('undefined', 'longtext', [
            'longtext',
          ]);
        }, 100);

      const position: Position = {
        singlePriceNet: 0,
        singlePriceGross: 0,
        singleWeight: 0,
        totalPriceNet: 0,
        totalPriceGross: 0,
        purchasePrice: 0,
        vat: 0,
        amount: 1,
        unit: article?.unit?.name ?? '',
        longtext: context.defaultText || article?.name,
        shortText: article?.name,
        articleNumber: article?.number,
        ownArticleNumber: article?.number,
        positionKind: context.positionType,
        cacheOnly,
        index: context.index,
        customFieldList: [],
        localId: context.localId,
      } as Position;
      return position;
    },
    [editTableRef]
  );

  const mutateState = useCallback(
    (input: Position[]) => {
      const { total } = recalculatePositions(input);
      if (entity === Entities.inboundDeliveryNote) setTotalPrice(total);
      return input;
    },
    [entity, setTotalPrice]
  );

  useEffect(() => {
    if (totalPrice === undefined && entity === Entities.inboundDeliveryNote) {
      const { total } = recalculatePositions(cloneDeep(positions));
      setTotalPrice(total);
    }
  }, [entity, positions, setTotalPrice, totalPrice]);

  const canAddRemote = useCallback((pos: ShadowObjectAddPositionArgs) => {
    if (
      pos.positionType === ErpPositionsKind.TEXTZEILE &&
      pos.article?.id === 0
    )
      return false;
    return true;
  }, []);

  const mapRemoveLocal = useCallback(
    (ids: number[]) => {
      const toRemove = positions
        .filter(
          (pos) => pos && (ids.includes(pos.id) || ids.includes(pos.posId))
        )
        .map((x) => x.id);

      return toRemove;
    },
    [positions]
  );

  const output = useEditableState<
    Position,
    number,
    ShadowObjectAddPositionArgs
  >({
    positions,
    onAddPosition: shadowBzObjectApi.addPosition,
    onRemovePosition: shadowBzObjectApi.removePosition,
    onMovePosition: shadowBzObjectApi.movePosition,
    onEditPosition: shadowBzObjectApi.editPosition,
    mapAddLocal,
    mapRemoveLocal,
    mutateState,
    canAddRemote,
  });

  useEffect(() => {
    const positionIds = positions.map((pos) => pos.id);
    const diff = output.allPositions.filter(
      (pos) => !positionIds.includes(pos.id)
    );
    const currendDif = output.allPositions?.length - positions?.length;
    if (currendDif > 0) {
      const index = diff[diff.length - 1].index;
      editTableRef?.current?.scrollTo(index);
    }
  }, [editTableRef, output.allPositions, positions]);

  const returnPositions = useMemo(() => {
    if (output.positions.length === 0) {
      return [
        {
          localId: uuid(),
          cacheOnly: true,
          positionKind: ErpPositionsKind.TEXTZEILE,
        },
      ];
    }

    return output.positions;
  }, [output.positions]);

  // HANDLE confimration for bom type
  const dialogs = useDialogs();
  const { t } = useTranslation();
  const onRemovePosition = useCallback(
    async (localIds: IdType[]) => {
      const ids =
        typeof localIds?.[0] === 'string'
          ? output.positions
              .filter((p) => localIds.includes(p.localId))
              .map((x) => x.id)
          : (localIds as number[]);

      const toRemove = output.positions.filter(
        (pos) => pos && ids.includes(pos.id)
      );
      const headersIds = toRemove
        .filter((pos) => pos.positionKind === ErpPositionsKind.STUECKLISTE)
        .map((pos) => pos.id);
      const finalList = toRemove
        .filter((pos) => !(pos.posId && headersIds.includes(pos.posId)))
        .map((pos) => pos.id);

      const shouldConfirm = finalList.length > 1 || headersIds.length;
      let confirmed = true;
      if (shouldConfirm)
        confirmed = await dialogs.confirm({
          title: t('ERP.BOM.DELETE.TITLE'),
          description: t('ERP.BOM.DELETE.ALERT'),
          confirmLabel: t('ALERTS.BTN_DELETE'),
          cancelLabel: t('ALERTS.BTN_ABORT'),
        });
      if (confirmed) {
        output.onRemovePosition(finalList);
      }
    },
    [dialogs, output, t]
  );

  const onMovePosition = useCallback(
    (localId: IdType, toIndex: number) => {
      const positionId =
        typeof localId === 'string'
          ? output.positions.find((x) => x.localId === localId).id
          : localId;

      // map index to remote index with expanded columns
      const toItem = output.positions[toIndex] as EditTableEntry<Position>;
      const position = output.positions.find((x) => x.id === positionId);

      let remoteIndex = toItem.index;

      // When BOM is collapsed and we move after HEAD of the BOM we need to put dragging item
      // after WHOLE BOM including it's children
      if (
        toItem.positionKind === ErpPositionsKind.STUECKLISTE &&
        toItem.collapsed &&
        // This is valid only in down direction
        position.index < toItem.index
      ) {
        let pointer = toItem.index + 1;
        while (positions[pointer]?.posId === toItem.id) {
          pointer++;
        }
        remoteIndex = pointer;
        // offset for other then STUCKLISTE need to be one less
        if (position.positionKind !== ErpPositionsKind.STUECKLISTE)
          remoteIndex--;
      } else {
        // This is a hack to fix API issue with ordering
        // whenever API will fix order we will need to take a look on this again
        // for now STUECKLISTE when move down the list has to have offset +1
        if (
          position.positionKind === ErpPositionsKind.STUECKLISTE &&
          position.index < toItem.index
        )
          remoteIndex++;
      }

      return output.onMovePosition(positionId, remoteIndex, toItem.index);
    },
    [output, positions]
  );

  const onAddPosition = useCallback(
    (context: ShadowObjectAddPositionArgs) => {
      // map index to remote index with expanded columns
      context.index = output.positions[context.index]?.index;
      return output.onAddPosition(context);
    },
    [output]
  );

  return {
    ...output,
    onAddPosition,
    onMovePosition,
    onRemovePosition,
    positions: returnPositions as Position[],
    total: totalPrice,
  };
};
