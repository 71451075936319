import styles from './Content.module.scss';

import React from 'react';

import { Breadcrumbs } from '../../../breadcrumbs/Breadcrumbs';
import { useHistoryStack } from '../../../history-stack';
import { IFooterProps } from '../footer/Footer';

interface IContentProps extends IFooterProps {
  close: () => void;
}

export const Content: React.FC<IContentProps> = (props) => {
  const { stack, goBack, current, currentStackIndex } = useHistoryStack();
  const View = current?.view;
  return (
    <>
      <Breadcrumbs
        stack={stack}
        goBack={goBack}
        close={props.close}
        current={current}
        hideBreadcrumbs={true}
        currentIndex={currentStackIndex}
      />
      <div className={styles.root}>{View}</div>
    </>
  );
};
