import { useMemo } from 'react';

import { Payment } from '../interfaces';

import { usePaymentColumns } from './use-payment-columns';
import { usePaymentHnadlers } from './use-payment-handlers';

export const usePaymentTable = (payments: Payment[]) => {
  const { onEdit } = usePaymentHnadlers({ payments });

  const data = useMemo(() => {
    return {
      amount: payments.reduce(
        (accumulator, payment) =>
          (payment?.amount ? Number(payment.amount) : 0) + accumulator,
        0
      ),
    };
  }, [payments]);

  const columns = usePaymentColumns({ onEdit, data });

  return { columns, displayFooter: payments.length > 0 };
};
