import styles from './ManagementAndCosts.module.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components/EntityPickerPopover';
import { CostCenterPicker } from '@work4all/components/lib/components/entity-picker/costCenter-Picker/CostCenterPicker';
import { UserPicker } from '@work4all/components/lib/components/entity-picker/user-picker/UserPicker';

import { useProjectCaptions } from '../../../../../../../../../../hooks';
import { ControllerPlus } from '../../../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import {
  LabeledInput,
  LabeledInputWithUnit,
  PickerTargetButton,
} from '../../../../../../../../locked-inputs';
import { ControlWrapper } from '../../../../../../../components/control-wrapper';
import { ProjectMaskFormValue } from '../../../../../types';

export const ManagementAndCosts: React.FC = () => {
  const { t } = useTranslation();
  const { control, register } = useFormContextPlus<ProjectMaskFormValue>();

  const { internalProjectManagerCaption, externalProjectManagerCaption } =
    useProjectCaptions();

  return (
    <Collapse
      defaultOpen
      title={t('MASK.MANAGEMENT_AND_COSTS')}
      classes={{ wrapperInner: styles.wrapperInner }}
      className={styles.root}
    >
      <ControlWrapper>
        <ControllerPlus
          name="internalProjectManager"
          control={control}
          render={({ field }) => {
            return (
              <EntityPickerPopover
                picker={<UserPicker multiple={false} {...field} />}
              >
                <PickerTargetButton
                  onClear={() => {
                    field?.onChange(null);
                  }}
                  label={internalProjectManagerCaption}
                  value={field?.value?.displayName || ''}
                />
              </EntityPickerPopover>
            );
          }}
        />

        <LabeledInput
          label={externalProjectManagerCaption}
          {...register('externalProjectManager')}
        />

        <ControllerPlus
          name="costCenter"
          control={control}
          render={({ field }) => {
            return (
              <EntityPickerPopover
                picker={<CostCenterPicker {...field} multiple={false} />}
              >
                <PickerTargetButton
                  value={field?.value?.name ?? ''}
                  label={t('COMMON.COST_CENTER')}
                  onClear={() => {
                    field?.onChange(null);
                  }}
                />
              </EntityPickerPopover>
            );
          }}
        />
        <LabeledInputWithUnit
          unit="€"
          label={t('INPUTS.BUDGET')}
          {...register('budget')}
          type="number"
        />
      </ControlWrapper>
    </Collapse>
  );
};
