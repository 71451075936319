import { CommentEvent } from '@work4all/models/lib/Classes/CommentEvent.entity';

import { EditModeContent } from './EditModeContent';
import { OriginalModeContent } from './OriginalModeContent';

export type ViewContentProps = {
  event: CommentEvent;
  isEditMode: boolean;
  onEditFinished: () => void;
  canAddOrInsertImage: boolean;
};

export const ViewContent = ({ isEditMode, ...rest }: ViewContentProps) => {
  if (isEditMode) return <EditModeContent {...rest} />;

  return <OriginalModeContent {...rest} />;
};
