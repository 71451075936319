import { noop } from 'lodash';
import { createContext } from 'react';

export type AttachmentRenameContextValue = {
  /**
   * Renaming can be disabled if attachments are read-only. In this case the
   * name should not be editable and the actual filename should be used.
   */
  nameDisabled: boolean;
  noteDisabled: boolean;

  /**
   * Returns the name of a given attachment. Returns `null` if the name hasn't
   * been changed yet, or if renaming is disabled.
   */
  getName(id: string | number): string | null;

  /**
   * Sets the name of a given attachment. Has no effect if renaming is disabled.
   */
  setName(id: string | number, name: string, shouldDirty?: boolean): void;

  /**
   * Returns the note of a given attachment. Returns `null` if the note hasn't
   * been changed yet, or if renaming is disabled.
   */
  getNote(id: string | number): string | null;

  /**
   * Sets the note of a given attachment. Has no effect if renaming is disabled.
   */
  setNote(id: string | number, note: string, shouldDirty?: boolean): void;

  /**
   * Track if changes have been made
   */
  isDirty: boolean;
};

export const AttachmentRenameContext =
  createContext<AttachmentRenameContextValue>({
    nameDisabled: true,
    noteDisabled: true,
    getName: () => null,
    setName: noop,
    getNote: () => null,
    setNote: noop,
    isDirty: false,
  });
