export enum ReDueDateClassified {
  NULL = 'NULL',
  MORE_THEN_0 = 'MORE_THEN_0',
  MORE_THEN_10 = 'MORE_THEN_10',
  MORE_THEN_20 = 'MORE_THEN_20',
  MORE_THEN_30 = 'MORE_THEN_30',
  MORE_THEN_60 = 'MORE_THEN_60',
  MORE_THEN_90 = 'MORE_THEN_90',
  MORE_THEN_180 = 'MORE_THEN_180',
  MORE_THEN_360 = 'MORE_THEN_360',
  MORE_THEN_720 = 'MORE_THEN_720',
}
