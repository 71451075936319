import { useMemo } from 'react';

import { SimpleReportSettings } from '../../../../../../../../settings/settings';

export const useDefaultFooter = (props: Partial<SimpleReportSettings>) => {
  const {
    footerMarginLeft,
    footerMarginTop,
    footerMarginRight,
    footerMarginBottom,
    footerColumns,
  } = props;

  return useMemo(
    () => [
      {
        margin: [
          footerMarginLeft || 50,
          footerMarginTop || 30,
          footerMarginRight || 50,
          footerMarginBottom || 0,
        ],
        fontSize: 6,
        columns: footerColumns,
      },
    ],
    [
      footerColumns,
      footerMarginBottom,
      footerMarginLeft,
      footerMarginRight,
      footerMarginTop,
    ]
  );
};
