import React from 'react';
import { HeaderGroup } from 'react-table';

import { ColumnAdditionalData, ColumnInstance, ICssClasses } from '../../types';

import {
  FooterCell,
  IFooterCellProps,
} from './components/footer-cell/FooterCell';
import { FooterRow } from './components/footer-row/FooterRow';
import { FooterWrapper } from './components/footer-wrapper/FooterWrapper';

export interface IFooterProps {
  headerGroups: HeaderGroup<ColumnAdditionalData>[];
  flatColumns: ColumnInstance[];
  classes?: ICssClasses;
  groupBy: string[];
  elementRef?: React.ForwardedRef<HTMLDivElement>;
}

/*
 * do not use memo here.
 * in most cases header won't be updated.
 * it's very hard to maintain
 */
export const TableFooter: React.FC<IFooterProps> = (props) => {
  return (
    <FooterWrapper
      elementRef={props.elementRef}
      className={props.classes?.footerWrapper}
    >
      {props.headerGroups.map((group) => {
        return (
          <FooterRow
            className={props.classes?.footerRow}
            {...group.getHeaderGroupProps()}
          >
            {group.headers
              .filter((column) => !column.isGrouped && column.Footer)
              .map((col, index) => {
                const commonCellProps: IFooterCellProps = {
                  col,
                  classes: props.classes,
                };

                const view = <FooterCell {...commonCellProps} />;

                return <React.Fragment key={col.id}>{view}</React.Fragment>;
              })}
          </FooterRow>
        );
      })}
    </FooterWrapper>
  );
};
