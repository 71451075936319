import { TextmarksList } from '../../types';

export const ERP_EMAIL_TEXTMARKS: TextmarksList = {
  project: {
    id: 'TEXTMARK.PROJECT',
    name: 'TEXTMARK.PROJECT',
    subItems: [
      {
        id: 'TEXTMARK.PROJECT_DESCRIPTION',
        name: 'TEXTMARK.PROJECT_DESCRIPTION',
        accessor: 'projekt.name',
      },
      {
        id: 'TEXTMARK.PROJECT_NUMBER',
        name: 'TEXTMARK.PROJECT_NUMBER',
        accessor: 'projekt.nummer',
      },
    ],
  },
  user: {
    id: 'TEXTMARK.USER',
    name: 'TEXTMARK.USER',
    subItems: [
      {
        id: 'TEXTMARK.USER_DEPARTMENT',
        name: 'TEXTMARK.USER_DEPARTMENT',
        accessor: 'benutzer.abteilung',
      },
      {
        id: 'TEXTMARK.USER_EMAIL',
        name: 'TEXTMARK.USER_EMAIL',
        accessor: 'benutzer.eMail',
      },
      {
        id: 'TEXTMARK.USER_FUNCTION',
        name: 'TEXTMARK.USER_FUNCTION',
        accessor: 'benutzer.funktion',
      },
      {
        id: 'TEXTMARK.USER_MOBILE',
        name: 'TEXTMARK.USER_MOBILE',
        accessor: 'benutzer.mobil',
      },
      {
        id: 'TEXTMARK.USER_PHONE',
        name: 'TEXTMARK.USER_PHONE',
        accessor: 'benutzer.telefon',
      },
      {
        id: 'TEXTMARK.USER_FAX',
        name: 'TEXTMARK.USER_FAX',
        accessor: 'benutzer.fax',
      },
      {
        id: 'TEXTMARK.USER_TITLE',
        name: 'TEXTMARK.USER_TITLE',
        accessor: 'benutzer.titel',
      },
      {
        id: 'TEXTMARK.USER_FIRSTNAME',
        name: 'TEXTMARK.USER_FIRSTNAME',
        accessor: 'benutzer.vorname',
      },
      {
        id: 'TEXTMARK.USER_LASTNAME',
        name: 'TEXTMARK.USER_LASTNAME',
        accessor: 'benutzer.nachname',
      },
      {
        id: 'TEXTMARK.USER_SHORTNAME',
        name: 'TEXTMARK.USER_SHORTNAME',
        accessor: 'benutzer.zeichen',
      },
      {
        id: 'TEXTMARK.USER_DISPLAYNAME',
        name: 'TEXTMARK.USER_DISPLAYNAME',
        accessor: 'benutzer.anzeigename',
      },
    ],
  },
  contact: {
    id: 'TEXTMARK.CONTACT',
    name: 'TEXTMARK.CONTACT',
    subItems: [
      {
        id: 'TEXTMARK.CONTACT_SALUTATION',
        name: 'TEXTMARK.CONTACT_SALUTATION',
        accessor: 'Anrede',
      },
      {
        id: 'TEXTMARK.CONTACT_STREET',
        name: 'TEXTMARK.CONTACT_STREET',
        accessor: 'ansprechpartner.strasse',
      },
      {
        id: 'TEXTMARK.CONTACT_DISPLAYNAME',
        name: 'TEXTMARK.CONTACT_DISPLAYNAME',
        accessor: 'ansprechpartner.anzeigename',
      },
    ],
  },
};
