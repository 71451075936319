import styles from './RelationsTable.module.scss';

import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { useTableStateBag } from '@work4all/components';
import { useCtrlMouseClickLink } from '@work4all/components/lib/hooks/use-ctrl-mouse-click-link';

import { nameof } from '@work4all/data/lib/helper/nameof';
import { useTenant } from '@work4all/data/lib/hooks/routing/TenantProvider';

import { AddressConnection } from '@work4all/models/lib/Classes/AddressConnection.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';
import { ITableSchema } from '@work4all/models/lib/table-schema/table-schema';

import {
  ITableProps,
  Table,
} from '../../../../../../../../../../components/data-tables/table/Table';
import { useDataTable } from '../../../../../../../../../../components/data-tables/use-data-table';
import { useDeleteEntitiesToolbarConfig } from '../../../../../../../../../../components/data-tables/use-delete-entities-toolbar-config';
import { RelationsPopover } from '../../../../../../../../../../components/relations-popover';
import { RelationsPopoverProps } from '../../../../../../../../../../components/relations-popover/interfaces';
import { useMaskContext } from '../../../../../../../hooks/mask-context';
import { BusinessPartners } from '../../../../../../businessPartners/BusinessPartnerOverlayController';

type IRelationsTableCustomColumns = Record<string, never>;
type IRelationsTableSchema = ITableSchema<IRelationsTableCustomColumns>;
type IRelationsTableProps = Partial<ITableProps>;

const defaultSort = [{ field: 'id', direction: SortDirection.ASCENDING }];

export const RelationsTable = React.forwardRef<
  TableInstance,
  IRelationsTableProps
>((props: IRelationsTableProps, ref) => {
  const anchorRef = useRef(null);
  const { activeTenant } = useTenant();
  const tableStateBag = useTableStateBag();
  const { data: entityData, entity } = useMaskContext<BusinessPartners>();

  const { t } = useTranslation();

  const schema: IRelationsTableSchema = useMemo(
    () => ({
      entity: Entities.addressConnection,
      columns: [
        {
          title: t('COMMON.RELATION_TYPE'),
          accessor: 'connection.name',
          sortable: false,
          groupable: true,
          filterable: { type: 'Search' },
          width: 200,
        },
        {
          title: 'COMMON.BUSINESSPARTNER',
          accessor: 'businessPartnerB.data.name',
          sortable: false,
          groupable: false,
          cell: 'BusinessPartnerCell',
          filterable: false,
          width: 440,
        },
      ],
      rowModifiers: [],
    }),
    [t]
  );

  const prefilter = useMemo(() => {
    return [
      { [nameof<AddressConnection>('sdObjMemberIdA')]: { $eq: entityData.id } },
    ];
  }, [entityData?.id]);

  const {
    columnConfigs,
    prepareRowDisplayModifiers,
    data,
    fetchMore,
    total,
    selectedEntity,
  } = useDataTable<AddressConnection, Record<string, never>>({
    schema: schema as IRelationsTableSchema,
    layout: 'table',
    tableStateBag,
    prefilter,
    defaultSort,
    cellProcessors: {
      BusinessPartnerCell: (cfg) => {
        return [cfg.accessor, 'businessPartnerB.data.name'];
      },
    },
    cells: {
      BusinessPartnerCell: (cfg) => {
        const data: AddressConnection = cfg.row?.original;
        return (
          <div>
            {data.businessPartnerA?.data.name ||
              data.businessPartnerB?.data.name}
          </div>
        );
      },
    },
  });

  const { getLink } = useCtrlMouseClickLink();

  const deleteConfig = useDeleteEntitiesToolbarConfig({
    entityType: Entities.addressConnection,
  });
  const openInNewTab = useMemo(
    () => ({
      open: (relationId?: string) => {
        const relation = data.find((d) => d.id === Number(relationId));
        const businessPartner = relation.businessPartnerB?.data;
        let path = '';
        if (!businessPartner?.id || !businessPartner?.__typename) {
          const id = tableStateBag.tableInstance.selectedRowsIdsList[0];
          path = getLink({ entity, id });
          return null;
        }
        const businessPartnerType =
          businessPartner.__typename === 'Kunde'
            ? Entities.customer
            : Entities.supplier;
        path = `/t-${activeTenant}/${businessPartnerType}s/${businessPartner.id}`;
        window.open(path);
      },
    }),
    [
      activeTenant,
      data,
      entity,
      getLink,
      tableStateBag.tableInstance?.selectedRowsIdsList,
    ]
  );

  const [relationsPopover, setRelationsPopover] = useState<
    Pick<RelationsPopoverProps, 'open' | 'isCreateMode'>
  >({ open: false, isCreateMode: false });

  return (
    <div ref={anchorRef} className={styles.root}>
      <RelationsPopover
        fullScreenMode
        onClose={() => {
          setRelationsPopover({ open: false, isCreateMode: false });
        }}
        businessPartnerId={entityData.id}
        businessPartnerType={
          entityData.__typename === 'Kunde'
            ? SdObjType.KUNDE
            : SdObjType.LIEFERANT
        }
        anchorEl={anchorRef.current}
        relationId={selectedEntity?.id}
        {...relationsPopover}
      />

      <Table
        ref={ref}
        noLockableEntity={true}
        actions={{
          add: {
            handler: () => {
              setRelationsPopover({
                open: true,
                isCreateMode: true,
              });
            },
            disabled: false,
          },
          edit: {
            disabled: false,
            handler: () => {
              setRelationsPopover({
                open: true,
                isCreateMode: false,
              });
            },
          },
          openInNewTab,
          remove: deleteConfig,
        }}
        columnConfigs={columnConfigs}
        loadMoreItems={fetchMore}
        prepareRowDisplayModifiers={prepareRowDisplayModifiers}
        data={data}
        total={total}
        basicClasses={{ headerWrapper: styles.table }}
        {...props}
      />
    </div>
  );
});
