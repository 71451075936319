import styles from './MaskActions.module.scss';

export interface IMaskActionsProps {
  children?: React.ReactNode;
}

export function MaskActions(props: IMaskActionsProps) {
  const { children } = props;

  return (
    <div className={styles.root}>
      <div className={styles.buttons}>{children}</div>
    </div>
  );
}
