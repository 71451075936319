import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  IReportPickerProps,
  ReportPicker,
} from '@work4all/components/lib/components/entity-picker/report-picker/ReportPicker';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

interface ReportPickerFieldProps
  extends Omit<IReportPickerProps<false>, 'multiple'> {
  error?: string;
  name?: string;
}

export const ReportPickerField = React.forwardRef<
  HTMLDivElement,
  ReportPickerFieldProps
>(function ReportPickerField(props, ref) {
  const { error, ...pickerProps } = props;
  const { value, onChange } = pickerProps;

  const { t } = useTranslation();

  const popoverRef = useRef<EntityPickerPopover>(null);

  return (
    <EntityPickerPopover
      ref={popoverRef}
      picker={<ReportPicker {...pickerProps} multiple={false} />}
    >
      <PickerTargetButton
        ref={ref}
        value={value ? value?.note : ''}
        name={props.name}
        label={t('FIELDS.report')}
        error={error}
        onClear={() => {
          onChange(null);
        }}
      />
    </EntityPickerPopover>
  );
});
