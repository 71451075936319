import styles from './ContactPicker.module.scss';

import { TFunction } from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PersonIcon } from '@work4all/assets/icons/person2.svg';

import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Body2 } from '../../../typography/body2/Body2';
import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerWithTabsProps } from '../types';

export const renderContact =
  (mainContactId: number | null, t: TFunction) => (contact: Contact) =>
    (
      <div className={styles['list-item-content']}>
        <Body2 component="span" className={styles['honorific']}>
          {contact.salutation?.isFemale && t('COMMON.FEMALE')}
          {contact.salutation?.isMale && t('COMMON.MALE')}
        </Body2>

        <Body2
          component="span"
          className={styles['name']}
          title={contact.displayName}
        >
          {contact.displayName}
        </Body2>

        <Body2 component="span" className={styles['role']} title={contact.role}>
          {contact.role}
        </Body2>

        {mainContactId != null && mainContactId === contact.id && (
          <PersonIcon className={styles['primary-contact-icon']} />
        )}
      </div>
    );

export interface IContactPickerProps<TMultiple extends boolean = boolean>
  extends IPickerWithTabsProps<Contact, TMultiple> {
  /**
   * The contact with this id will be marked with a special icon.
   */
  mainContactId?: number | null;
  favorites?: boolean;
}

export function ContactPicker<TMultiple extends boolean = boolean>(
  props: IContactPickerProps<TMultiple>
) {
  const { data, prefilter, mainContactId, favorites = true, ...rest } = props;

  const allFields = useMemo(() => {
    return { ...CONTACT_FIELDS, ...data };
  }, [data]);

  const prefilters = useMemo(() => {
    return {
      active: [...(prefilter ?? []), { layedOff: { $eq: false } }],
      layedOff: [...(prefilter ?? []), { layedOff: { $eq: true } }],
    };
  }, [prefilter]);

  const { t } = useTranslation();
  const renderItemContent = renderContact(mainContactId, t);

  return (
    <ListEntityPicker
      {...rest}
      getTabIndex={getTabIndex}
      tabs={[
        {
          entity: Entities.contact,
          data: !props.filterConfig ? allFields : TABLE_FILTER_CONTACT_FIELDS,
          label: 'Aktive',
          placeholder: 'Suchen nach Vor-/Nachname',
          filterBy: ['name', 'firstName'],
          sortBy: 'name',
          prefilter: prefilters.active,
          renderItemContent,
          favorites,
        },
        {
          entity: Entities.contact,
          data: allFields,
          label: 'Ausgeschiedene',
          placeholder: 'Suchen nach Vor-/Nachname',
          filterBy: ['name', 'firstName'],
          sortBy: 'name',
          prefilter: prefilters.layedOff,
          renderItemContent,
          favorites,
        },
      ]}
    />
  );
}

export const CONTACT_FIELDS: Contact = {
  id: null,
  displayName: null,
  firstName: null,
  name: null,
  role: null,
  // TODO contact salutation is too deep - Backend will not return it
  salutation: {
    id: null,
    isFemale: null,
    isMale: null,
  },
  businessPartnerId: null,
  businessPartnerType: null,
  eMail: null,
  phoneNumber: null,
  phoneNumber2: null,
  phoneNumber3: null,
  mobileNumber: null,
  mobileNumberPrivate: null,
  faxNumber: null,
  phoneNumberPrivate: null,
};

export const TABLE_FILTER_CONTACT_FIELDS: Contact = {
  id: null,
  displayName: null,
  role: null,
};

function getTabIndex(contact: Contact): 0 | 1 {
  return contact.layedOff ? 1 : 0;
}
