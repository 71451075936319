import { DateTime } from 'luxon';
import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { Holiday } from '@work4all/models/lib/Classes/Holiday.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export interface UsePublicHolidaysOptions {
  from: Date;
  to: Date;
  skip?: boolean;
}

export function usePublicHolidays(options: UsePublicHolidaysOptions) {
  const { from, to, skip = false } = options;

  const vars = skip
    ? { from: '', to: '' }
    : {
        from: DateTime.fromJSDate(from).toISODate(),
        to: DateTime.fromJSDate(to).toISODate(),
      };

  const request = useMemo<DataRequest>(() => {
    const request: DataRequest = {
      operationName: 'GetPublicHolidays',
      entity: Entities.holiday,
      data: FIELDS,
      completeDataResponse: true,
      vars: {
        from: vars.from,
        to: vars.to,
      },
    };

    return request;
  }, [vars.from, vars.to]);

  const response = useDataProvider<Holiday>(request, skip);

  const result = useMemo(() => {
    return {
      data: response.data,
      loading: response.loading,
    };
  }, [response.data, response.loading]);

  return result;
}

const FIELDS: Holiday = {
  id: null,
  date: null,
  name: null,
};
