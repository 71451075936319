import { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack';

import { useEntityEvents } from '@work4all/data/lib/entity-events/use-entity-events';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import {
  Form,
  MaskOverlayHeader,
  MaskOverlayStandardSubmitButton,
} from '../../../components';
import {
  MaskContextProvider,
  useMaskConfig,
} from '../../../hooks/mask-context';
import { InboundInvoiceFormValue } from '../../inbound-invoice/types';
import { usePaymentData, usePaymentMutations } from '../hooks';
import { Payment, PaymentMaskProps } from '../interfaces';

import { GeneralSection } from './GeneralSection';
import { TableSection } from './TableSection';

export const PaymentMask = ({ id, entity }: PaymentMaskProps) => {
  const { t } = useTranslation();
  const { onClose } = useHistoryStack();
  const mask = useMaskConfig({ entity, id });
  const { data, refetch } = usePaymentData({ id, entity });

  const form = useForm<InboundInvoiceFormValue>({
    mode: 'onChange',
    defaultValues: data,
  });

  const { reset, handleSubmit } = form;

  useEffect(() => {
    reset(data);
  }, [data, reset]);

  const { addAndModify } = usePaymentMutations({
    incomingInvoiceId: data.id,
    onCompleted: () => {
      onClose();
    },
  });
  const onSubmit = useCallback(
    (data: InboundInvoiceFormValue) => {
      addAndModify(data.payments as Payment[]);
    },
    [addAndModify]
  );

  useEntityEvents((event) => {
    if (event.entity === Entities.inboundInvoice) {
      refetch();
    }
  });

  return (
    <FormProvider {...form}>
      <MaskContextProvider value={mask}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <MaskOverlayHeader
            title={t('INPUTS.OPEN_PAYMENT_MASK')}
            subTitle={`${id} ${data?.note ? `| ${data.note}` : ''}`}
            actions={<MaskOverlayStandardSubmitButton />}
          />
          <GeneralSection />
          <TableSection />
        </Form>
      </MaskContextProvider>
    </FormProvider>
  );
};
