import styles from './DocumentPreview.module.scss';

import { Delete, Fullscreen } from '@mui/icons-material';
import ShareIcon from '@mui/icons-material/Share';
import { IconButton } from '@mui/material';
import i18next from 'i18next';
import { MouseEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ConvertIcon } from '@work4all/assets/icons/convert.svg';
import { ReactComponent as DownloadIcon } from '@work4all/assets/icons/download.svg';
import { ReactComponent as EditIcon } from '@work4all/assets/icons/edit.svg';
import { ReactComponent as EditCopyIcon } from '@work4all/assets/icons/edit-pdf.svg';
import { ReactComponent as LinkIcon } from '@work4all/assets/icons/link.svg';

import { useAuthHeaders } from '@work4all/data/lib/auth/use-auth-headers';
import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';
import {
  downloadAuthed,
  parseNameAndExtension,
} from '@work4all/data/lib/utils';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useToolbarCustomActionsComponents } from '../../../dataDisplay/actions-bar/hooks/use-toolbar-custom-actions';
import { CustomToolbar } from '../../../dataDisplay/actions-bar/ToolbarTypes';
import { PDFTextmarkConf } from '../../../preview/pdf-textmarks/PDFTextmarkContainer';
import { IPreviewProps, MIME_TYPES, Preview } from '../../../preview/Preview';
import {
  ConvertPopover,
  IConvertPopoverProps,
} from '../../convert-popover/ConvertPopover';
import { Tooltip } from '../../tooltip/Tooltip';
import { PreviewTitle } from '../components';
import { PreviewTitleInput } from '../components/preview-input';

import { DocumentEditablePreview } from './DocumentEditablePreview';
import { EntityUntion } from './EntityUntion';

export interface IDocumentPreviewProps
  extends Pick<IPreviewProps, 'iconProps'> {
  title: string;
  url: string;
  filePath?: string;
  downloadUrl?: string;
  noPreviewUrl?: string;
  mimeType: MIME_TYPES;
  fspUrl?: string;
  exists?: boolean;
  onCloseClicked?: MouseEventHandler<HTMLButtonElement>;
  onEditClicked?: MouseEventHandler<HTMLButtonElement>;
  onPdfEditClicked?: MouseEventHandler<HTMLButtonElement>;
  navigation?: JSX.Element;
  onShareClicked?: () => void;
  onDeleteClicked?: MouseEventHandler<HTMLButtonElement>;
  openInFullscreen?: boolean;
  erpPreview?: boolean;
  showFullscreenButton?: boolean;
  customActions?: CustomToolbar;
  subtitleComp?: React.ReactNode;
  pdfTextmarkConf?: PDFTextmarkConf;
  additionalTitleItems?: React.ReactNode;
  convertProps?: Pick<IConvertPopoverProps, 'exclude' | 'onClick'>;
  selectedRowsIdsList: (string | number)[];
  entries?: EntityUntion[];
  entity?: Entities;
  entityData?: EntityUntion;
  titleKeyField?: string;
}

export function DocumentPreview(props: IDocumentPreviewProps) {
  const {
    title,
    url,
    noPreviewUrl,
    downloadUrl,
    filePath,
    mimeType,
    onCloseClicked,
    onEditClicked,
    onDeleteClicked,
    onPdfEditClicked,
    fspUrl,
    exists,
    navigation,
    iconProps,
    onShareClicked,
    openInFullscreen,
    erpPreview,
    showFullscreenButton = true,
    customActions: custom,
    subtitleComp,
    pdfTextmarkConf,
    additionalTitleItems,
    convertProps,
    selectedRowsIdsList,
    entries = [],
    entity,
    entityData,
    titleKeyField,
  } = props;
  const [detailInFullsceen, setFullscreen] = useState(false);

  const isLinkable =
    typeof filePath === 'string' &&
    (filePath.startsWith('https') ||
      filePath.startsWith('http') ||
      filePath.startsWith('www'));

  const mimeTypeValues = Object.values(MIME_TYPES);

  const canPreview =
    showFullscreenButton && mimeTypeValues.includes(mimeType) ? true : false;

  useEffect(() => {
    if (openInFullscreen) {
      setFullscreen(true);
    }
  }, [openInFullscreen]);

  const toolbar = useToolbarCustomActionsComponents({
    custom,
    skipMode: true,
    selectedRowsIdsList,
  });

  const { t } = useTranslation();
  const httpHeaders = useAuthHeaders();

  const convertPopoverState = usePopoverState('bottom');

  const actions = (
    <>
      {additionalTitleItems}
      {onPdfEditClicked ? (
        <Tooltip title={t('COMMON.EDIT_COPY')}>
          <IconButton
            color="primary"
            onClick={onPdfEditClicked}
            className={styles['fileheader-icon']}
            size="small"
          >
            <EditCopyIcon />
          </IconButton>
        </Tooltip>
      ) : null}
      {toolbar.left}
      {onShareClicked && (
        <IconButton size="small" color="primary" onClick={onShareClicked}>
          <ShareIcon />
        </IconButton>
      )}
      {onEditClicked ? (
        <Tooltip title={t('COMMON.EDIT')}>
          <IconButton
            color="primary"
            onClick={onEditClicked}
            className={styles['fileheader-icon']}
            size="small"
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      ) : null}
      {onDeleteClicked ? (
        <Tooltip title={t('COMMON.DELETE')}>
          <IconButton
            color="primary"
            onClick={onDeleteClicked}
            className={styles['fileheader-icon']}
            size="small"
          >
            <Delete />
          </IconButton>
        </Tooltip>
      ) : null}

      {convertProps ? (
        <IconButton
          size="small"
          color="primary"
          onClick={convertPopoverState.handleClick}
        >
          <ConvertIcon />
        </IconButton>
      ) : null}

      {canPreview && (
        <IconButton color="primary" onClick={() => setFullscreen(true)}>
          <Fullscreen />
        </IconButton>
      )}
      {!erpPreview ? (
        !canPreview &&
        exists && (
          <IconButton color="primary" href={fspUrl} download>
            <DownloadIcon />
          </IconButton>
        )
      ) : downloadUrl ? (
        <IconButton
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            downloadAuthed(
              downloadUrl,
              `${isLinkable ? filePath : title}.${
                parseNameAndExtension(filePath).extension
              }`,
              httpHeaders
            );
          }}
        >
          <DownloadIcon />
        </IconButton>
      ) : null}
      {iconProps?.showPreviewExternallyIcon !== false &&
      ((filePath && isLinkable) || fspUrl) ? (
        <IconButton
          color="primary"
          href={filePath && isLinkable ? filePath : fspUrl}
          target="_blank"
        >
          <LinkIcon />
        </IconButton>
      ) : null}
      {navigation ? navigation : null}
      {toolbar.right}
    </>
  );

  const previewTitle = entity ? (
    <DocumentEditablePreview
      entity={entity}
      entityData={entityData}
      entries={entries}
    >
      {({ onEdit, onPopoverClose }) => (
        <PreviewTitleInput
          onClose={(e) => {
            if (e.target.value !== title) {
              onEdit({ [titleKeyField]: e.target.value });
            }
            onPopoverClose();
          }}
          value={title}
          onCloseClicked={onCloseClicked}
          accessor={titleKeyField}
          leftActions={actions}
        />
      )}
    </DocumentEditablePreview>
  ) : (
    <PreviewTitle
      onCloseClicked={(e) => {
        setFullscreen(false);
        //eslint-disable-next-line
        //@ts-expect-error type not assignable to parameter 'MouseEvent<HTMLButtonElement, MouseEvent>'
        onCloseClicked(e);
      }}
      onShareClicked={onShareClicked}
      actions={actions}
    >
      {title}
    </PreviewTitle>
  );

  return (
    <div className={styles.wrapper}>
      {convertProps && (
        <ConvertPopover {...convertProps} popoverState={convertPopoverState} />
      )}
      {previewTitle}
      {subtitleComp}
      <Preview
        onFullscreenClose={() => {
          setFullscreen(false);
          if (openInFullscreen) onCloseClicked(null);
        }}
        openInFullscreen={detailInFullsceen}
        onDeleteClicked={onDeleteClicked}
        onEditClicked={onEditClicked}
        url={isLinkable ? filePath : url}
        mimeType={isLinkable ? MIME_TYPES.urllist : mimeType}
        fileName={isLinkable ? filePath : title}
        filePath={filePath}
        exists={exists}
        fspUrl={fspUrl}
        downloadProps={
          filePath || downloadUrl
            ? {
                url: downloadUrl,
                filePath: filePath,
              }
            : undefined
        }
        noPreview={
          !exists
            ? i18next.t('MASK.NO_PREVIEWFILE')
            : i18next.t('MASK.NO_PREVIEW_DOWNLOAD')
        }
        noPreviewUrl={noPreviewUrl}
        isLinkable={isLinkable}
        iconProps={iconProps}
        pdfTextmarkConf={pdfTextmarkConf}
      />
    </div>
  );
}
