import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconButtonWithTooltip } from '../IconButtonWithTooltip';
import { BaseActionProps } from '../types';

export const Columns: React.FC<BaseActionProps> = (props) => {
  const { t } = useTranslation();
  return (
    <IconButtonWithTooltip
      {...props}
      tooltip={t('MASK.COLUMNS')}
      icon={<ViewColumnIcon />}
    />
  );
};
