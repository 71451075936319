import { useEventCallback } from '@mui/material/utils';
import { isEqual } from 'lodash';
import { useDebugValue, useEffect, useMemo, useRef, useState } from 'react';
import { ColumnInstance } from 'react-table';

import { TableLayout, useTableStateBag } from '@work4all/components';
import { SELECTION_COLUMN_ID } from '@work4all/components/lib/dataDisplay/basic-table/utils/makeRowsSelectable';

import { throwInDev } from '@work4all/utils';

import { SavedTableConfig, settings, useSetting } from '../../settings';

import { DataTableColumnConfig } from './table/DataTableColumnConfig';
import { getColumnId } from './utils';

export interface IUseDataTableConfigWithUserPreferencesOptions<T> {
  layout: TableLayout;
  entityType: string;
  columnConfigs: DataTableColumnConfig<T>[];
}

export function useUserColumnConfigs<T>(
  options: IUseDataTableConfigWithUserPreferencesOptions<T>
) {
  const { layout, entityType, columnConfigs } = options;

  const savedTableConfig = useSetting(
    settings.tableConfig({ entityType, layout })
  );

  const { tableInstance, tableState } = useTableStateBag();

  const [userColumnConfigs, setUserColumnConfigs] = useState<
    DataTableColumnConfig[] | null
  >(null);

  // Keep track of the original `columnConfigs` to know if they changed.
  const lastColumnConfigsRef = useRef<DataTableColumnConfig<T>[] | null>();

  const handleUserPreferencesChange = useEventCallback(() => {
    // If new `columnConfigs` are passed, always reset the state. Otherwise, try
    // to figure out if something has actually changed to reduce the number of
    // re-renders.
    if (lastColumnConfigsRef.current === columnConfigs) {
      // If the table is already initialized, check if the config has changed
      // before applying it. Just blindly resetting table state here would
      // cancel all current user interactions (column resize, drag and drop, etc.)

      // Note: with this implementation there is still a chance that a new config
      // will be applied after it was just saved by this app instance. This can
      // happen if a user changes table state after a config is persisted but
      // before the app gets the new value from RxDB (because saving the config
      // is an async operation). This edge-case is not handled at the moment.
      if (tableInstance) {
        const allColumns = tableInstance.allColumns;
        const storedConfig = savedTableConfig.value;
        const currentConfig = extractUserConfig({
          columnConfigs,
          allColumns,
          storedConfig,
        });

        // If the config is the same (most likely because it was just saved by this
        // app instance), don't update the table state.
        if (isConfigEqual(currentConfig, storedConfig)) {
          return;
        }
      }
    }

    const mergedConfig = applyUserConfig(columnConfigs, savedTableConfig.value);

    setUserColumnConfigs(mergedConfig);
    lastColumnConfigsRef.current = columnConfigs;

    // If the table is already initialized, then we imperatively update
    // parts of the table state that don't update automatically
    // after changing the columns config.
    if (tableInstance) {
      tableInstance.setHiddenColumns(
        mergedConfig.filter((column) => column.defaultHidden).map(getColumnId)
      );

      tableInstance.setColumnOrder([
        // "Selection" column is not present in the columns array.
        // Need to add it manually, or it will be moved to the end automatically.
        SELECTION_COLUMN_ID,
        ...mergedConfig.map(getColumnId),
      ]);
    }
  });

  useEffect(handleUserPreferencesChange, [
    handleUserPreferencesChange,
    savedTableConfig,
    columnConfigs,
  ]);

  const handleTableStateChange = useEventCallback(() => {
    const allColumns = tableInstance?.allColumns;
    const storedConfig = savedTableConfig.value;

    if (!allColumns) return;

    const newConfig = extractUserConfig({
      columnConfigs,
      allColumns,
      storedConfig,
    });

    if (newConfig !== null && !isConfigEqual(newConfig, storedConfig)) {
      savedTableConfig.set(newConfig);
    }
  });

  // Whenever table state changes, save the new state to Settings if there are
  // any changes.
  useEffect(() => {
    const timeout = window.setTimeout(handleTableStateChange, 1000);

    return () => {
      window.clearTimeout(timeout);
    };
  }, [handleTableStateChange, tableState]);

  const handlers = useMemo(
    () => ({
      remove: savedTableConfig.delete,
    }),
    [savedTableConfig.delete]
  );

  useDebugValue(userColumnConfigs);

  return [userColumnConfigs, handlers] as const;
}

function extractSavedTableConfigFromInstance<T extends object>(
  columns: ColumnInstance<T>[]
): SavedTableConfig {
  const filteredColumns = columns.filter(
    (column) => column.id !== SELECTION_COLUMN_ID
  );

  return {
    order: filteredColumns.map((column) => column.id),
    columns: Object.fromEntries(
      filteredColumns.map((column) => {
        const hidden = !column.isVisible;
        const width =
          typeof column.width === 'number' ? column.width : undefined;

        return [column.id, { hidden, width }];
      })
    ),
  };
}

function extractSavedTableConfigFromSchema<T>(
  columns: DataTableColumnConfig<T>[]
): SavedTableConfig {
  return {
    order: columns.map(getColumnId),
    columns: columns.reduce<SavedTableConfig['columns']>((acc, cur) => {
      const columnId = getColumnId(cur);

      const hidden = cur.defaultHidden ?? false;
      const width = cur.width;

      if (width === undefined) {
        throwInDev(
          `Column "${columnId}" is missing the \`width\` value.` +
            ' This value is required to correctly compute changes' +
            ` to the table state to save to Settings.`
        );
      }

      acc[columnId] = { hidden, width };

      return acc;
    }, {}),
  };
}

function applyUserConfig<T>(
  config: DataTableColumnConfig<T>[],
  userConfig: SavedTableConfig | null
) {
  if (!userConfig) {
    // Spread the original column configs, so that the table can detect changes.
    // This is required for state to correctly update if you change the config
    // and then reset all changes in the same component instance, since in this
    // flow the column configs passed to the table component are never actually
    // updated.
    return [...config];
  }

  const { order, columns } = userConfig;

  const finalConfig = config.map((column) => {
    const columnId = getColumnId(column);

    const defaultHidden = columns[columnId]?.hidden ?? column.defaultHidden;
    const width = columns[columnId]?.width ?? column.width;

    return { ...column, defaultHidden, width };
  });

  if (!order) {
    return finalConfig;
  }

  const first = order
    .map((id) => finalConfig.find((column) => getColumnId(column) === id))
    .filter(Boolean);

  const last = finalConfig.filter((column) => !first.includes(column));

  return [...first, ...last];
}

function extractUserConfig<T extends object>(options: {
  columnConfigs: DataTableColumnConfig<T>[];
  allColumns: ColumnInstance<T>[];
  storedConfig: SavedTableConfig;
}) {
  const { columnConfigs, allColumns, storedConfig } = options;

  const defaultConfig = extractSavedTableConfigFromSchema(columnConfigs);
  const currentConfig = extractSavedTableConfigFromInstance(allColumns);

  const resolvedConfig: SavedTableConfig = {
    order:
      storedConfig?.order !== undefined ||
      !isEqual(currentConfig.order, defaultConfig.order)
        ? currentConfig.order
        : undefined,
    columns: Object.fromEntries(
      Object.entries(currentConfig.columns)
        .map(([id, config]) => {
          const savedWidth = storedConfig?.columns[id]?.width;
          const defaultWidth = defaultConfig.columns[id]?.width;

          const saveHidden = storedConfig?.columns[id]?.hidden;
          const defaultHidden = defaultConfig.columns[id]?.hidden;

          // If some value is already saved, just override it.
          // Otherwise compare the current value agains the default config
          // and only save it if it is different.

          const width =
            savedWidth !== undefined || config.width !== defaultWidth
              ? config.width
              : undefined;

          const hidden =
            saveHidden !== undefined || config.hidden !== defaultHidden
              ? config.hidden
              : undefined;

          // Do not save empty column configs
          if (width === undefined && hidden === undefined) {
            return null;
          }

          return [id, { width, hidden }];
        })
        .filter(Boolean)
    ),
  };

  const isConfigEmpty =
    !resolvedConfig.order && Object.keys(resolvedConfig.columns).length === 0;

  if (isConfigEmpty) {
    return null;
  }

  return resolvedConfig;
}

function isConfigEqual(a: SavedTableConfig, b: SavedTableConfig) {
  // This is just a simplest way to check if two objects will be serialized
  // to the same value. The config sometimes has `undefined` values which
  // are treated differently when using `isEqual`.
  // Probably can use some other comparison method, but this works fine.
  return JSON.stringify(a) === JSON.stringify(b);
}
