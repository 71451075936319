import { useMemo } from 'react';
import { useDragLayer } from 'react-dnd';
import { useTranslation } from 'react-i18next';

import { useDialogs } from '@work4all/components/lib/dialog-manager/use-dialogs';

import { usePermissions } from '@work4all/data';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { typeNameToEntity } from '@work4all/utils';

import { ENTITY_BY_CONVERT_CONFIGS } from '../../../../../../../components/data-tables/offers-table/hooks/use-clone-convert-erp';
import { useConvertBzObjectMutation } from '../../../../../../../components/data-tables/offers-table/hooks/use-convert-bz-object-mutation';

export const useCardDnd = (targetEntity: Entities) => {
  const { untypedPermissions } = usePermissions();
  const { mutate } = useConvertBzObjectMutation({ targetEntity });
  const { item } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
  }));

  const sourceEntity = useMemo(() => {
    return typeNameToEntity(item?.__typename);
  }, [item]);

  const dialogs = useDialogs();

  const { t } = useTranslation();

  const value = useMemo(() => {
    const canDrag = () => {
      const canUserConvertRecord =
        untypedPermissions(targetEntity).canConvert();

      /**
       * If the user has the right to convert & the entity was defined
       * inside `ENTITY_BY_CONVERT_CONFIGS` as a key, that means you
       * can convert from it to other entities so you can drag an item
       * from the entity list.
       */
      return canUserConvertRecord && !!ENTITY_BY_CONVERT_CONFIGS[targetEntity];
    };
    const canDrop = () => {
      if (targetEntity === sourceEntity) return true;

      const sourceEntityConfigs = ENTITY_BY_CONVERT_CONFIGS[sourceEntity];
      return (
        sourceEntityConfigs &&
        sourceEntityConfigs.allowedEntities.includes(targetEntity)
      );
    };
    const canSkip = () => {
      if (targetEntity === sourceEntity) return false;

      const sourceEntityConfigs = ENTITY_BY_CONVERT_CONFIGS[sourceEntity];
      const allowedEntities = sourceEntityConfigs?.allowedEntities ?? [];
      const blockedEntities = sourceEntityConfigs?.blockedEntities ?? [];

      return ![...allowedEntities, ...blockedEntities].includes(targetEntity);
    };

    const isEntitiesIdentical = sourceEntity === targetEntity;

    const onDrop = async (item: object) => {
      if (!('id' in item && typeof item.id === 'number'))
        throw new Error('Wrong dropped item in useCardDnd');

      const confirmed = await dialogs.confirm({
        title: t('DIALOG.CONVERT.TITLE'),
        description: t('DIALOG.CONVERT.MESSAGE'),
        cancelLabel: t('MFA.CANCEL'),
        confirmLabel: t('MASK.CONVERT'),
      });

      if (confirmed) {
        mutate({
          sourceEntity,
          sourceIds: [item.id],
          targetEntity,
        });
      }
    };

    return { canDrag, canDrop, canSkip, onDrop, isEntitiesIdentical };
  }, [sourceEntity, targetEntity, untypedPermissions, dialogs, t, mutate]);

  return value;
};
