import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';

import { EntityTable, IEntityTable } from '../entity-table/EntityTable';
import { useEntityDataTable } from '../entity-table/use-entity-data-table';
import schema from '../schemata/article-table-schema.json';

import { ArticleTableQuickFilters } from './ArticleTableQuickFilters';
import { useTransformedArticleTableSchema } from './use-transformed-article-table-schema';

const defaultSort = [
  { field: 'creationDate', direction: SortDirection.DESCENDING },
];

const forceRequestFields: Article = {
  groupId: null,
  netPrice: null,
  netPrice2: null,
  netPrice3: null,
  articlePrices: [
    {
      singlePriceList: [
        {
          id: null,
          price: null,
          priceGroupId: null,
        },
      ],
    },
  ],
  hasBulkPrices: null,
  bulkPriceList: [
    {
      id: null,
      sdObjMemberCode: null,
      price: null,
      bulkPriceTierItem: {
        id: null,
        name: null,
        edgeValue1: null,
        edgeValue2: null,
      },
      priceGroupId: null,
    },
  ],
};

export const ArticleTable = React.forwardRef<TableInstance, IEntityTable>(
  function ArticleTable(_props, ref) {
    const { t } = useTranslation();

    const transformedSchema = useTransformedArticleTableSchema({ schema });

    const dataTable = useEntityDataTable<Article, never>({
      schema: transformedSchema as never,
      defaultSort,
      forceRequestFields,
    });
    const prepareRowDisplayModifiers = dataTable.prepareRowDisplayModifiers;

    const rowModifiers = useCallback(
      (value: Article) => {
        const modifiers = prepareRowDisplayModifiers(value);
        return {
          ...modifiers,
          isFaded: value.isShutDown,
        };
      },
      [prepareRowDisplayModifiers]
    );

    return (
      <EntityTable
        ref={ref}
        areas={{
          left: {
            content: <ArticleTableQuickFilters />,
            resizable: true,
            collapseConfig: {
              title: t('COMMON.FILTER'),
            },
          },
        }}
        {...dataTable}
        prepareRowDisplayModifiers={rowModifiers}
      />
    );
  }
);
