import React, { useEffect, useMemo, useState } from 'react';
import { matchPath } from 'react-router-dom';

import {
  OverlayFullscreenProvider,
  useLocation,
  useNavigate,
} from '@work4all/data';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { removeDetailsFromLocation } from '@work4all/utils/lib/removeFromString';

import { useCanGoBack } from '../../hooks';
import { IStackItem, StackProvider } from '../history-stack';
import { INavigationOverlayProps } from '../navigation-overlay';
import { Content } from '../navigation-overlay/components/content/Content';

import { getMaskClasses } from './utils/getMaskClasses';

export interface IRouteConfig
  extends IStackItem,
    Pick<INavigationOverlayProps, 'decorator' | 'classes'> {
  route: string;
  withBreadcrumbs?: boolean;
}

interface IRouterNavigationOverlayProps {
  children?: React.ReactNode;
  routes: IRouteConfig[];
  onOverlayStateChanged?: (visible: boolean) => void;
}

export const RouterNavigationOverlay: React.FC<
  IRouterNavigationOverlayProps
> = (props) => {
  const { routes, onOverlayStateChanged } = props;
  const [open, setOpen] = useState(false);
  const [initialView, setInitialView] = useState<IRouteConfig | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const canGoBack = useCanGoBack();

  useEffect(() => {
    const view = [...routes].reverse().find((routeItem) => {
      return Boolean(matchPath(routeItem.route, location.pathname));
    });

    if (view) {
      setInitialView(view);
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [location.pathname, routes]);

  useEffect(() => {
    onOverlayStateChanged?.(open);
  }, [onOverlayStateChanged, open]);

  const close = () => {
    setOpen(false);
  };

  const onExited = () => {
    const newPathName = removeDetailsFromLocation(location.pathname);
    if (newPathName === location.pathname) return;

    if (canGoBack) {
      navigate(-1);
    } else {
      navigate(newPathName, { replace: true });
    }

    setInitialView(null);
  };

  const match = useMemo(() => {
    if (!initialView) return null;
    const path = initialView.route;
    return matchPath(path, location.pathname);
  }, [location, initialView]);

  const entityType = match?.params?.subEntityType as Entities | undefined;

  const wrapper = getMaskClasses(entityType);
  return (
    <OverlayFullscreenProvider entityType={entityType}>
      <StackProvider onClose={close} initialView={initialView}>
        {initialView && (
          <Content
            open={open}
            close={close}
            withBreadcrumbs={initialView.withBreadcrumbs}
            onExited={onExited}
            decorator={initialView.decorator}
            classes={{
              wrapper,
            }}
          />
        )}
        {props.children}
      </StackProvider>
    </OverlayFullscreenProvider>
  );
};
