import { useTableStateBag } from '@work4all/components';

import { Work4AllEntity } from '@work4all/models/lib/additionalEnums/Work4AllEntity.entity';
import { ICustomCellConfigBase } from '@work4all/models/lib/table-schema/table-schema';

import { useTablePrefilterContext } from '../../../containers/file-entities-lists/components/TablePrefilterProvider';
import { useTableLayoutState } from '../table-layout';
import { IUseDataTableOptions, useDataTable } from '../use-data-table';

export interface IUseEntityDataOptions<
  T extends Record<string, ICustomCellConfigBase>
> extends Omit<
      IUseDataTableOptions<T>,
      'prefilter' | 'layout' | 'tableStateBag'
    >,
    Partial<
      Pick<IUseDataTableOptions<T>, 'prefilter' | 'layout' | 'tableStateBag'>
    > {}

/**
 * Wrapper for Table entities
 * @param options Look useDataTable
 * @returns useDataTable result
 */
export function useEntityDataTable<
  Entity extends Work4AllEntity,
  CustomColumns extends Record<string, ICustomCellConfigBase>
>(options: IUseEntityDataOptions<CustomColumns>) {
  const { prefilter } = useTablePrefilterContext();
  const tableStateBag = useTableStateBag();
  const layoutState = useTableLayoutState();
  const [layout] = layoutState;

  return useDataTable<Entity, CustomColumns>({
    tableStateBag,
    layout,
    prefilter,
    ...options,
  });
}
