import { createContext } from 'react';

export const SimpleReportContext = createContext<{
  logoBase64: string;
  uploadCompanyPictureSimpleModeFile: (file: File) => Promise<{
    fileStored?: boolean;
    originalFilename?: string;
    generatedType?: string;
    generatedObject?: string;
    filename?: string;
    errorMessage?: string;
    downloadUrl?: string;
    downloadMimeType?: string;
    downloadUrlForPreview?: string;
    previewMimeType?: string;
  }>;
  logoURL: string;
  uploadingLogo: boolean;
}>(null);
