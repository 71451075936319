import { concat } from 'lodash';
import React, { useMemo } from 'react';
import { TableInstance } from 'react-table';

import { DOCUMENT_FILTER_EXCLUDE_CHILD_DOCUMENTS } from '@work4all/data/lib/prefilters/document';

import { Document } from '@work4all/models/lib/Classes/Document.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';

import { EntityTable, IEntityTable } from './entity-table/EntityTable';
import { useEntityDataTable } from './entity-table/use-entity-data-table';
import schema from './schemata/document-table-schema.json';

const defaultSort = [{ field: 'date', direction: SortDirection.DESCENDING }];

export const DocumentsTable = React.forwardRef<TableInstance, IEntityTable>(
  function DocumentsTable(props, ref) {
    const { prefilter: prefilterProp } = props;

    const prefilter = useMemo(() => {
      return concat(
        ...[prefilterProp, DOCUMENT_FILTER_EXCLUDE_CHILD_DOCUMENTS].filter(
          Boolean
        )
      );
    }, [prefilterProp]);

    const dataTable = useEntityDataTable<Document, never>({
      schema: schema as never,
      prefilter,
      defaultSort,
      forceRequestFields: {
        fileInfos: {
          fileServiceProviderInfos: {
            id: null,
            fspUrl: null,
          },
        },
        childDocuments: [{ id: null }],
      },
    });

    return (
      <EntityTable
        ref={ref}
        {...dataTable}
        onRowDoubleClick={() => {
          if (
            !dataTable.selectedEntity?.childDocuments?.length &&
            dataTable.selectedEntity?.fileInfos?.fileServiceProviderInfos
              ?.fspUrl
          ) {
            window.open(
              dataTable.selectedEntity?.fileInfos?.fileServiceProviderInfos
                ?.fspUrl,
              '_blank'
            );
            return true;
          } else {
            return false;
          }
        }}
      />
    );
  }
);
