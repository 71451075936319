import { Theme, useMediaQuery } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import React from 'react';

import { usePermissions } from '@work4all/data';

import { ERPTypes } from '@work4all/models/lib/Classes/ERPTypes.entity';
import { FileEntity } from '@work4all/models/lib/Classes/FileEntity.entity';
import { InboundInvoice } from '@work4all/models/lib/Classes/InboundInvoice.entity';
import { Ra } from '@work4all/models/lib/Classes/Ra.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { CustomToolbar } from '../../../dataDisplay/actions-bar/ToolbarTypes';
import { MIME_TYPES } from '../../../preview/Preview';
import { IConvertPopoverProps } from '../../convert-popover/ConvertPopover';
import {
  DocumentPreview,
  IDocumentPreviewContainerProps,
} from '../document-preview';
import { useEntityPreviewDataRequest } from '../hooks/use-entity-preview-data';

import { DocumentCopyBanderole } from './DocumentCopyBanderole';
import { useInboundInvoicePdfEditRights } from './use-inbound-invoice-pdf-edit-rights';
import { usePdfOverlay } from './use-pdf-overlay';
import { usePreviewNavigation } from './usePreviewNavigation';

export interface IErpPreviewContainerProps
  extends Omit<
    IDocumentPreviewContainerProps,
    'documentId' | 'onEditPdfClicked'
  > {
  entityId: number;
  entity: Entities;
  openInFullscreen?: boolean;
  customActions?: CustomToolbar;
  convertProps?: Pick<IConvertPopoverProps, 'exclude' | 'onClick'>;
}

export function ErpPreviewContainer(props: IErpPreviewContainerProps) {
  const {
    entityId,
    entity,
    onCloseClicked,
    onEditClicked,
    openInFullscreen = false,
    convertProps,
  } = props;

  const { untypedPermissions } = usePermissions();

  const requestEntityData = useMemo(() => {
    if (entity === Entities.inboundInvoice) {
      const inboundInvoiceData: InboundInvoice<EMode.query> = {
        id: null,
        userId: null,
        datevDate: null,
        note: null,
        belegList: [
          {
            id: null,
            parentId: null,
            fileInfos: {
              fileEntityFilename: null,
              downloadUrl: null,
              previewUrl: null,
              previewMimeType: null,
              downloadMimeType: null,
              fileServiceProviderInfos: {
                id: null,
                fileName: null,
                mimeType: null,
                fspUrl: null,
              },
            },
          },
        ],
      };
      return inboundInvoiceData;
    } else if (entity === Entities.ra) {
      const outgoingInvoiceData: Ra<EMode.query> = {
        id: null,
        note: null,
        invoice: {
          id: null,
          archivePdf: [
            {
              id: null,
              mimeType: null,
              fileInfos: {
                fileEntityFilename: null,
                downloadUrl: null,
                previewUrl: null,
                previewMimeType: null,
                downloadMimeType: null,
                fileServiceProviderInfos: {
                  id: null,
                  fileName: null,
                  mimeType: null,
                  fspUrl: null,
                },
              },
            },
          ],
        },
      };
      return outgoingInvoiceData;
    } else {
      const requestErpData: ERPTypes<EMode.query> = {
        id: null,
        note: null,
        archivePdf: [
          {
            id: null,
            downloadUrl: null,
            mimeType: null,
            xML: null,
            fileInfos: {
              fileEntityFilename: null,
              downloadUrl: null,
              previewUrl: null,
              previewMimeType: null,
              downloadMimeType: null,
              fileServiceProviderInfos: {
                id: null,
                fileName: null,
                mimeType: null,
                fspUrl: null,
              },
            },
          },
        ],
      };
      return requestErpData;
    }
  }, [entity]);

  const { data: entitiesData, refetch } = useEntityPreviewDataRequest<
    ERPTypes | InboundInvoice
  >([entityId], entity, requestEntityData);

  const fileList = useMemo<
    {
      fileInfos?: FileEntity;
      mimeType?: string;
      id?: number;
      parentId?: number;
    }[]
  >(() => {
    if (entity === Entities.inboundInvoice) {
      let belegList = (entitiesData?.[0] as InboundInvoice)?.belegList;
      if (belegList && belegList.length > 1) {
        belegList = [...belegList];
        belegList.sort((a, b) => {
          return !a.parentId && b.parentId
            ? -1
            : a.parentId && !b.parentId
            ? 1
            : 0;
        });
      }

      return belegList;
    }

    if (entity === Entities.ra) {
      return (entitiesData?.[0] as Ra)?.invoice?.archivePdf;
    }

    return (entitiesData?.[0] as ERPTypes)?.archivePdf;
  }, [entitiesData, entity]);

  const { file, component } = usePreviewNavigation(fileList || []);

  const fileInfos = file?.fileInfos;
  const mimeType = file?.mimeType;

  const isSmUp = useMediaQuery<Theme>((t) => t.breakpoints.up('sm'));

  const inboundInvoice =
    entity === Entities.inboundInvoice
      ? (entitiesData?.[0] as InboundInvoice)
      : undefined;

  const inboundInvoicePdfEditAllowed = useInboundInvoicePdfEditRights({
    fileName: file?.fileInfos?.fileEntityFilename,
    documentUserId: entitiesData?.[0]?.userId,
    datevDate: inboundInvoice?.datevDate,
  });

  const docId = useMemo(() => {
    const selectedEntity = inboundInvoice;

    const correctReceipt = selectedEntity?.belegList?.find(
      (x) => x.parentId === file?.id
    );

    return correctReceipt?.id || selectedEntity?.belegList?.[0]?.id;
  }, [file, inboundInvoice]);

  const [showPreview, setShowPreview] = useState(true);

  const reloadPreview = useCallback(async () => {
    await refetch();
    setShowPreview(false);
    setTimeout(() => setShowPreview(true));
  }, [refetch]);

  const { component: pdfOverlay, open: openPdfOverlay } = usePdfOverlay({
    entityId: inboundInvoice?.id,
    entityType: Entities.inboundInvoice,
    docId,
    onClose: reloadPreview,
  });

  const selectedRowsIdsList = useMemo(() => {
    return entitiesData?.map((x) => x.id) ?? [];
  }, [entitiesData]);

  const showEdit =
    isSmUp &&
    entitiesData?.length === 1 &&
    untypedPermissions(entity).canEdit(entitiesData?.[0]);

  const subtitleComp = useMemo(() => {
    if (entity === Entities.inboundInvoice && file?.parentId) {
      return <DocumentCopyBanderole />;
    }

    return null;
  }, [entity, file?.parentId]);

  return (
    <React.Fragment>
      {pdfOverlay}
      {showPreview && (
        <DocumentPreview
          entries={entitiesData}
          entity={entity}
          entityData={requestEntityData}
          titleKeyField="note"
          openInFullscreen={openInFullscreen}
          navigation={component}
          title={
            entitiesData?.[0]?.note ||
            fileInfos?.fileServiceProviderInfos?.fileName
          }
          url={fileInfos?.previewUrl}
          mimeType={(fileInfos?.previewMimeType || mimeType) as MIME_TYPES}
          noPreviewUrl={
            entity !== Entities.inboundInvoice
              ? fileInfos?.fileServiceProviderInfos?.fspUrl ||
                fileInfos?.downloadUrl
              : fileInfos?.downloadUrl
          }
          downloadUrl={fileInfos?.downloadUrl}
          onCloseClicked={onCloseClicked}
          onEditClicked={showEdit ? onEditClicked : undefined}
          fspUrl={fileInfos?.fileServiceProviderInfos?.fspUrl}
          erpPreview={true}
          filePath={fileInfos?.fileEntityFilename}
          showFullscreenButton={isSmUp}
          onPdfEditClicked={
            entity === Entities.inboundInvoice && inboundInvoicePdfEditAllowed
              ? () => openPdfOverlay()
              : undefined
          }
          customActions={props.customActions}
          subtitleComp={subtitleComp}
          convertProps={convertProps}
          selectedRowsIdsList={selectedRowsIdsList}
        />
      )}
    </React.Fragment>
  );
}
