import { gql, useMutation } from '@apollo/client';

import { prepareResponse } from '@work4all/data';

import { MailboxContent } from '@work4all/models/lib/Classes/MailboxContent.entity';
import { BatchableContext } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { CONVERTED_MAIL_FIELDS } from '../../mailbox-content-graphql-fields';

export const ASSIGN_MAIL_RESPONSE_FIELDS: MailboxContent<EMode.query> = {
  id: null,
  assignStatus: null,
  convertedMail: CONVERTED_MAIL_FIELDS,
};

const ASSIGN_MAIL_RESPONSE_GQL = prepareResponse(
  Entities.mailboxContent,
  ASSIGN_MAIL_RESPONSE_FIELDS
);

const ASSIGN_MAIL = gql`
  mutation assignMail(
    $mailboxId: String!
    $mailFolderId: String!
    $mailId: String!
    $businessPartnerId: Int
    $businessPartnerType: SdObjType
    $contactId: Int
    $projectId: Int
  ) {
    assignMail(
      mailboxId: $mailboxId
      mailId: $mailId
      mailFolderId: $mailFolderId
      sdObjMemberCode: $businessPartnerId
      sdObjType: $businessPartnerType
      apCode: $contactId
      projektCode: $projectId
    ) {
      ${ASSIGN_MAIL_RESPONSE_GQL}
    }
  }
`;

type AssignMailResponse = {
  assignMail: MailboxContent;
};

type AssignMailVars = {
  mailboxId: string;
  mailFolderId: string;
  mailId: string;
  businessPartnerId: number;
  businessPartnerType: SdObjType;
  contactId: number;
  projectId: number;
};

export function useAssignMailMutation() {
  return useMutation<AssignMailResponse, AssignMailVars, BatchableContext>(
    ASSIGN_MAIL,
    {
      context: { batch: false },
    }
  );
}
