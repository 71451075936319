import { DateTime } from 'luxon';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useDialogs } from '@work4all/components';

import { useUser } from '@work4all/data';

import { NEW_ROW_ID_PREFIX } from '../constants';
import { Payment, UsePaymentOnEditHnadler } from '../interfaces';

import { usePaymentMutations } from './use-payment-mutations';

interface Props {
  incomingInvoiceId?: number;
  payments: Payment[];
  selectedRows?: string[];
}

export const usePaymentHnadlers = ({
  incomingInvoiceId,
  payments,
  selectedRows,
}: Props) => {
  const user = useUser();
  const dialogs = useDialogs();
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const { remove } = usePaymentMutations({ incomingInvoiceId });

  const onAdd = useCallback(() => {
    const newPaymentsData: Payment[] = [
      ...payments,
      {
        id: `${NEW_ROW_ID_PREFIX}${payments.length}`,
        bookingDate: DateTime.now().toISO(),
        amount: 0,
        ledgerAccountNumber: 0,
        text: '',
        datevDate: '',
        user: {
          id: user.benutzerCode,
          displayName: user.displayName,
        },
      },
    ];

    setValue('payments', newPaymentsData, {
      shouldDirty: true,
    });
  }, [payments, setValue, user.benutzerCode, user.displayName]);

  const onEdit: UsePaymentOnEditHnadler = useCallback(
    (oldData: Payment, newData: Payment) => {
      const copyOfPayments = [...payments];
      const payment = copyOfPayments.find((item) => item.id === oldData.id);
      Object.keys(newData).forEach((key) => {
        payment[key] = newData[key];
      });

      setValue('payments', copyOfPayments, { shouldDirty: true });
    },
    [payments, setValue]
  );

  const onRemove = useCallback(async () => {
    const id = selectedRows[0];
    if (id.startsWith(NEW_ROW_ID_PREFIX)) {
      setValue(
        'payments',
        payments.filter((payment) => payment.id !== id)
      );
      return;
    }

    const confirmed = await dialogs.confirm({
      title: t('ALERTS.HINT'),
      description: t('ALERTS.DELETE.PAYMENTS'),
      confirmLabel: t('ALERTS.BTN_DELETE'),
      cancelLabel: t('ALERTS.BTN_ABORT'),
    });

    if (confirmed) {
      remove(Number(id));
    }
  }, [dialogs, payments, remove, selectedRows, setValue, t]);

  return { onAdd, onEdit, onRemove };
};
