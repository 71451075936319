import i18next from 'i18next';

import { DeliveryNote } from '@work4all/models/lib/Classes/DeliveryNote.entity';
import { InboundDeliveryNote } from '@work4all/models/lib/Classes/InboundDeliveryNote.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export type ShouldPreventEditingData = DeliveryNote | InboundDeliveryNote;

type Props = {
  entity: Entities;
  data: ShouldPreventEditingData;
};

type Return = {
  value: boolean;
  preventMessage?: string;
};

export const shouldPreventEditing = ({ entity, data }: Props): Return => {
  if (
    entity === Entities.deliveryNote ||
    entity === Entities.inboundDeliveryNote
  ) {
    const positions = data?.positionList ?? [];

    return {
      value:
        positions.length > 0 &&
        Boolean(
          positions.find((position) => {
            return position.originalId !== 0;
          })
        ),
      preventMessage: i18next.t('ALERTS.ERP_PREVENT_EDIT_MESSAGE', {
        entity: i18next.t(`COMMON.${Entities.deliveryNote.toUpperCase()}`),
      }),
    };
  }

  return {
    value: false,
  };
};
