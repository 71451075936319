import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

import { EditStateContext } from '../../hooks/use-editable-state';
import { IdType, OnEditPosition } from '../../types';

interface EditTableProviderState {
  onAdd: (props: EditStateContext) => void;
  onEdit: (result: OnEditPosition<unknown>) => void;
  onRemove: (positionId: IdType[]) => void;
}

const EditTableProviderContext = createContext<EditTableProviderState>(null);

interface EditTableProviderProps extends EditTableProviderState {}

export const EditTableProvider = (
  props: PropsWithChildren<EditTableProviderProps>
) => {
  const { onAdd, onEdit, onRemove } = props;
  const value = useMemo(() => {
    return {
      onAdd,
      onEdit,
      onRemove,
    };
  }, [onAdd, onEdit, onRemove]);

  return (
    <EditTableProviderContext.Provider value={value}>
      {props.children}
    </EditTableProviderContext.Provider>
  );
};

export const useEditTable = () => {
  const context = useContext(EditTableProviderContext);

  if (context === null)
    throw new Error('useEditTable must be used within a <EditTableProvider>');

  return context;
};
