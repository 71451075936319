import { capitalize } from '@mui/material/utils';

import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { AssignableEntityResult } from '../../../utils/use-assignable-template-entity';

export function calculateToMailContacts(
  template: AssignableEntityResult
): string | undefined {
  const data = template.data;
  return JSON.stringify(calculateToMailContact(data));
}

export function calculateToMailContact(data: AssignableEntityResult['data']) {
  function getEmailFromContact(contact: Contact) {
    const typeName = capitalize(
      (
        contact?.businessPartnerType ||
        data.businessPartnerType ||
        SdObjType.LIEFERANT
      ).toLowerCase()
    );

    if (contact) {
      const id = `${typeName}Ap_${contact.id}`;
      return contact?.eMail
        ? [
            {
              id,
              mailAddress: contact.eMail,
              contact: contact,
            },
          ]
        : [];
    }
  }

  const getEmailFromCompany = () => {
    const bp = data.businessPartner?.data;

    if (bp?.eMail && bp?.id) {
      const typeName = bp.__typename
        ? bp.__typename
        : getBusinessPartnerPrefix(
            data.businessPartnerType || SdObjType.LIEFERANT
          );

      const id = `${typeName}_${bp.id}`;
      return bp?.eMail
        ? [
            {
              id,
              mailAddress: bp?.eMail,
            },
          ]
        : [];
    }
  };

  if (data.contact) {
    if (data.contact?.eMail) {
      return getEmailFromContact(data.contact);
    } else {
      const result = getEmailFromCompany();
      return (
        result || getEmailFromContact(data.businessPartner?.data.mainContact)
      );
    }
  }
  if (data.businessPartner?.data.mainContact) {
    return getEmailFromContact(data.businessPartner?.data.mainContact);
  }
  return getEmailFromCompany();
}

const getBusinessPartnerPrefix = (businessPartnerType: SdObjType) => {
  if (businessPartnerType === SdObjType.LIEFERANT) return 'Lieferant';
  return 'Kunde';
};
