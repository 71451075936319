import React from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  DepartmentPicker,
  IDepartmentPickerProps,
} from '@work4all/components/lib/components/entity-picker/departmentPicker/department-picker';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

interface DepartmentPickerFieldProps
  extends Omit<IDepartmentPickerProps<false>, 'multiple'> {
  error?: string;
}

export const DepartmentPickerField = React.forwardRef<
  HTMLDivElement,
  DepartmentPickerFieldProps
>(function DepartmentPickerField(props, ref) {
  const { error, ...pickerProps } = props;
  const { value, onChange } = pickerProps;

  const { t } = useTranslation();

  return (
    <EntityPickerPopover
      picker={<DepartmentPicker {...pickerProps} multiple={false} />}
    >
      <PickerTargetButton
        ref={ref}
        value={value?.name ?? ''}
        label={t('INPUTS.DEPARTMENT')}
        error={error}
        onClear={() => {
          onChange(null);
        }}
      />
    </EntityPickerPopover>
  );
});
