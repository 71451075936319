import { useMemo, useState } from 'react';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { TableBottomViewProps } from '../interfaces';

import { Header } from './Header';
import { TabContent } from './TabContent';

export const TableBottomView = ({
  entity,
  selectedEntityId,
  tabs,
}: TableBottomViewProps) => {
  const [tab, setTab] = useState<Entities>(tabs[0]);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const headerProps = useMemo(() => {
    return {
      selectedTab: tab,
      onTabChange: (value) => setTab(value),
      isCollapsed: isCollapsed,
      onCollapsedChange: setIsCollapsed,
      tabs: tabs,
    };
  }, [isCollapsed, tab, tabs]);

  if (isCollapsed) {
    return <Header {...headerProps} />;
  }

  return (
    <div style={{ height: '300px' }}>
      <Header {...headerProps} />
      <TabContent
        entity={entity}
        tab={tab}
        selectedEntityId={selectedEntityId}
      />
    </div>
  );
};
