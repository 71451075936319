import { Theme, useMediaQuery } from '@mui/material';
import React, { useContext } from 'react';

import { Desktop } from './components/desktop/Desktop';
import { Mobile } from './components/mobile/Mobile';

interface ICollapsedApi {
  collapsed: boolean;
  setCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
}

const CollapsedContext = React.createContext<ICollapsedApi | null>(null);

export const useCollapsedApi = () => {
  return useContext(CollapsedContext);
};

export interface NavigationRoute {
  title: string;
  pathname: string;
  manualMatch?: boolean;
}
interface INavigationWrapperProps {
  children?: React.ReactNode;
  isLoggedIn: boolean;
  timeTracker?: React.ReactNode;
  calendar?: React.ReactNode;
  userMenu?: React.ReactNode;
  globalSearch?: React.ReactNode;
  favoriteLinks?: React.ReactNode;
  availableRoutes: NavigationRoute[];
}

export const AvailableRoutesContext = React.createContext<NavigationRoute[]>(
  []
);

export const NavigationWrapper: React.FC<INavigationWrapperProps> = React.memo(
  function NavigationWrapper(props) {
    const mobile = useMediaQuery<Theme>((theme) =>
      theme.breakpoints.down('lg')
    );

    const isUpSm = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));
    const isUpXl = useMediaQuery<Theme>((theme) => theme.breakpoints.up('xl'));
    const isUpMd = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));

    if (!props.isLoggedIn) {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <>{props.children}</>;
    }
    let content = null;
    if (isUpMd) {
      content = (
        <Desktop
          userMenu={props.userMenu}
          globalSearch={isUpXl && props.globalSearch}
          timeTracker={isUpSm && props.timeTracker}
          calendar={isUpSm && props.calendar}
          favoriteLinks={isUpSm && props.favoriteLinks}
        >
          {props.children}
        </Desktop>
      );
    } else if (mobile) {
      content = <Mobile>{props.children}</Mobile>;
    }

    return (
      <AvailableRoutesContext.Provider value={props.availableRoutes}>
        {content}
      </AvailableRoutesContext.Provider>
    );
  }
);
