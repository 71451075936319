import styles from './ProjectRightsGrid.module.scss';

import { Box } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useTableStateBag } from '@work4all/components';
import { ConfirmDialog } from '@work4all/components/lib/dialogs/ConfirmDialog';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';

import { ProjectAccessGroup } from '@work4all/models/lib/Classes/ProjectAccessGroup.entity';

import { useDeepMemo } from '@work4all/utils/lib/hooks/use-deep-memo';

import { CheckBoxGridCell } from '../../grid/cells/CheckBoxGridCell';
import { Grid, GridCellValue, GridProps } from '../../grid/Grid';
import { useGridState } from '../../grid/GridStateProvider';

import { ProjectGroupAccessRightOverlayController } from './ProjectGroupAccessRightOverlayController';

export type ProjectRightsGridProps = {
  groupRightId: number | null;
  groupRights: ProjectAccessGroup[];
  closeMask: () => void;
  addGroupRight: () => void;
  setGroupRightId: (input: number) => void;
  refetchGroupRights: () => void;
} & Pick<
  GridProps<GridCellValue<boolean>>,
  'columns' | 'grid' | 'onItemClicked' | 'scrollRef'
>;

export const ProjectRightsGrid = (props: ProjectRightsGridProps) => {
  const {
    groupRightId,
    closeMask,
    addGroupRight,
    groupRights,
    refetchGroupRights,
    setGroupRightId,
  } = props;
  const { t } = useTranslation();
  const tableStateBag = useTableStateBag();
  // for row which contains project use projectId
  const selectedRowsIds = tableStateBag.tableInstance?.selectedFlatRows.map(
    (x) => {
      if (x.isGrouped) return { id: x.id };
      const id = x.id.split('.');
      return { id: id[id.length - 1] };
    }
  );
  const selectedRows = useDeepMemo(() => {
    return selectedRowsIds;
  }, [selectedRowsIds]);

  const { selectedColumns, setSelectedColumns } = useGridState();
  const onColumnClicked = useCallback(
    (id: number | string) => {
      setSelectedColumns([{ id }]);
      if (typeof id === 'number') setGroupRightId(id);
    },
    [setSelectedColumns, setGroupRightId]
  );

  const [noGroupModal, setNoGroupModal] = useState(true);

  return (
    <React.Fragment>
      <Grid
        {...props}
        Cell={CheckBoxGridCell}
        selectedRows={selectedRows}
        selectedColumns={selectedColumns}
        onColumnClicked={onColumnClicked}
      />
      <Box position="absolute">
        <NavigationOverlay
          open={groupRightId !== null}
          initialView={{
            view: (
              <ProjectGroupAccessRightOverlayController
                groupId={groupRightId}
                groups={groupRights}
                refetchGroupRights={refetchGroupRights}
              />
            ),
            title: t('COMMON.RIGHTS_GROUP.TITLE'),
          }}
          close={closeMask}
          classes={{
            wrapperSmall: styles.wrapperSmall,
          }}
        />
        <ConfirmDialog
          open={noGroupModal && !groupRights.length}
          title={t('COMMON.RIGHTS_GROUP.TITLE')}
          description={t('COMMON.RIGHTS_GROUP.NONE')}
          confirmLabel={t('ALERTS.BTN_OK')}
          cancelLabel={t('ALERTS.BTN_ABORT')}
          onConfirm={() => {
            addGroupRight();
            setNoGroupModal(false);
          }}
          onCancel={() => setNoGroupModal(false)}
        />
      </Box>
    </React.Fragment>
  );
};
