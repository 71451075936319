import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { useEntityEvents } from '@work4all/data/lib/entity-events/use-entity-events';

import { EMailTemplate } from '@work4all/models/lib/Classes/EMailTemplate.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { EntityTable, IEntityTable } from './entity-table/EntityTable';
import { useEntityDataTable } from './entity-table/use-entity-data-table';
import schema from './schemata/eMailTemplate-table-schema.json';

const manualGroupBy = false;
const defaultSort = [{ field: 'name', direction: SortDirection.DESCENDING }];

export const EmailTemplateTable = React.forwardRef<TableInstance, IEntityTable>(
  function EmailTemplateTable(props, ref) {
    const { forceRequestFields, prefilter: originalPrefilter, ...rest } = props;

    const { t } = useTranslation();

    const prefilter = [
      { parentId: { $eq: null } },
      ...(originalPrefilter ? originalPrefilter : []),
    ];

    const { data, refetch, ...dataTable } = useEntityDataTable<
      EMailTemplate,
      Record<string, never>
    >({
      layout: 'table',
      prefilter,
      schema: schema as never,
      forceRequestFields,
      defaultSort,
      cells: {
        Context: (cell) => <>{t(`E_MAIL_TEMPLATE_KIND.${cell.value}`)}</>,
      },
    });

    useEntityEvents((event) => {
      if (event.entity === Entities.eMailTemplate) {
        refetch();
      }
    });

    const dataSorted = useMemo(() => {
      return [...data].sort((a, b) => a.name.localeCompare(b.name));
    }, [data]);

    return (
      <EntityTable
        ref={ref}
        {...dataTable}
        manualGroupBy={manualGroupBy}
        data={dataSorted}
        {...rest}
      />
    );
  }
);
