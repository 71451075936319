import { Theme, useMediaQuery } from '@mui/material';

import {
  CustomToolbar,
  CustomToolbarAction,
} from '@work4all/components/lib/dataDisplay/actions-bar/ToolbarTypes';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { FilePreviewPanel } from '../../../containers/files/detail/components/file-preview-panel/FilePreviewPanel';
import { useOnOpenMaskContext } from '../../../containers/mask-overlays/mask-overlay/components/OpenMaskProvider';
import { PreviewMobileWrapper } from '../PreviewMobileWrapper';
import { useDeleteEntitiesToolbarConfig } from '../use-delete-entities-toolbar-config';
import { useTableHandlers } from '../use-table-handlers';

interface EntityTablePreviewProps {
  entityIds: (number | string)[];
  entityType: Entities;
  onCloseClicked: () => void;
  openInFullscreen?: boolean;
  custom?: CustomToolbar<CustomToolbarAction>;
}

/**
 * Wrapper to handle previews with Table contexts.
 * Cannot be used as standablone preview yet detached from Table context.
 * It use useTableHandlers which is Table specific.
 */
export const EntityTablePreview = (props: EntityTablePreviewProps) => {
  const { entityIds, entityType, onCloseClicked, custom } = props;

  const { onOpenMask } = useOnOpenMaskContext();
  const tableHandlers = useTableHandlers(entityType, onOpenMask);
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));
  const deleteConfig = useDeleteEntitiesToolbarConfig({
    entityType,
  });

  return (
    <PreviewMobileWrapper
      active={!isDesktop}
      actions={{
        edit: tableHandlers.edit,
        remove: deleteConfig,
        custom,
      }}
      entityIds={entityIds}
    >
      <FilePreviewPanel
        onCloseClicked={onCloseClicked}
        onEditClicked={tableHandlers.edit?.handler}
        onShareClicked={tableHandlers.share}
        convertProps={
          tableHandlers.convert
            ? {
                exclude: entityType,
                onClick: tableHandlers.convert,
              }
            : undefined
        }
        customActions={isDesktop ? custom : undefined}
        entityIds={entityIds}
        entityType={entityType}
      />
    </PreviewMobileWrapper>
  );
};
