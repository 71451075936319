import { noop } from 'lodash';
import { createContext, FC, useCallback, useMemo } from 'react';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { settings, useSetting } from '../../settings';

export type IOverlayFullscreenContextProps = {
  fullscreen: boolean;
  setFullscreen: (fullscreen: boolean) => void;
};

export const OverlayFullscreenContext =
  createContext<IOverlayFullscreenContextProps>({
    fullscreen: false,
    setFullscreen: noop,
  });

export interface IOverlayFullscreenProviderProps {
  children?: React.ReactNode;
  entityType: Entities | undefined;
}

export const OverlayFullscreenProvider: FC<IOverlayFullscreenProviderProps> = (
  props
) => {
  const { entityType } = props;
  const { search } = window.location;

  const maskState = useSetting(settings.mask({ entityType }));
  const fullscreenSearchParam = JSON.parse(
    new URLSearchParams(search).get('fullscreen')
  );
  const fullscreen =
    fullscreenSearchParam ?? maskState.value.fullscreen ?? false;

  const setFullscreen = useCallback(
    (fullscreen: boolean) => {
      if (!entityType) return;

      maskState.set({ ...maskState.value, fullscreen });
    },
    [entityType, maskState]
  );

  const contextValue = useMemo<IOverlayFullscreenContextProps>(
    () => ({
      fullscreen,
      setFullscreen,
    }),
    [fullscreen, setFullscreen]
  );

  return (
    <OverlayFullscreenContext.Provider value={contextValue}>
      {props.children}
    </OverlayFullscreenContext.Provider>
  );
};
