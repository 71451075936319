import { useCallback, useMemo } from 'react';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components/EntityPickerPopover';
import { FixedDataPicker } from '@work4all/components/lib/components/entity-picker/fixed-data-picker/FixedDataPicker';

import { ControllerPlus } from '../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../form-plus/use-form-context-plus';
import { PickerTargetButton } from '../../../../locked-inputs';
import { WithCustomFields } from '../with-custom-fields';

import { CustomFieldProps } from './types';

const DEFAULT_OPTIONS = [];

export function CustomPickerField(props: CustomFieldProps) {
  const { name, label, options = DEFAULT_OPTIONS } = props;

  const { control } = useFormContextPlus<WithCustomFields<object>>();

  return (
    <ControllerPlus
      control={control}
      name={name}
      render={({ field: { ref: _ref, ...rest } }) => {
        return (
          <CustomPicker
            label={label}
            options={options}
            onClear={() => rest.onChange('')}
            {...rest}
          />
        );
      }}
    />
  );
}

type CustomPickerProps = {
  value: null | string;
  options: string[];
  error?: string;
  label: string;
  required?: boolean;
  onChange: (value: string) => void;
  onClear: () => void;
};

export const CustomPicker = (props: CustomPickerProps) => {
  const {
    value: valueProp,
    options: optionsProp,
    onChange: onChangeProp,
    onClear: onClearProp,
    required,
    label,
    error,
  } = props;

  const value = useMemo(() => {
    return valueProp ? { id: valueProp, name: valueProp } : null;
  }, [valueProp]);

  const options = useMemo(() => {
    return optionsProp.map((option) => {
      return { id: option, name: option };
    });
  }, [optionsProp]);

  const onChange = useCallback(
    (value: { id: string | null; name: string }) => {
      if (!value) {
        onClearProp();
      } else {
        onChangeProp(value.name);
      }
    },
    [onChangeProp, onClearProp]
  );

  return (
    <EntityPickerPopover
      picker={
        <FixedDataPicker
          value={value}
          dataSet={options}
          onChange={onChange}
          clearable={true}
          multiple={false}
        />
      }
    >
      <PickerTargetButton
        required={required}
        label={label}
        error={error}
        value={valueProp ?? ''}
        onClear={onClearProp}
      />
    </EntityPickerPopover>
  );
};
