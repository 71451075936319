import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useDialogs } from '@work4all/components';

import { PromptResult } from '@work4all/models/lib/Enums/PromptResult.enum';
import { PromptType } from '@work4all/models/lib/Enums/PromptType.enum';

import { PriceGroupPickerField } from '../../../../../../../../../../components/entity-picker/PriceGroupPickerField';
import { ControllerPlus } from '../../../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import { ErpPaymentData } from '../../../../../ErpData';
import { useShadowBzObjectApiContext } from '../../../../../hooks/use-bz-shadow-object-api/use-shadow-bz-object-api-context';

export const ErpPriceGroupPicker = (props: { disabled?: boolean }) => {
  const { disabled } = props;
  const { t } = useTranslation();
  const { control } = useFormContextPlus<ErpPaymentData>();

  const dialogs = useDialogs();

  const shadowBzObjectApi = useShadowBzObjectApiContext();
  const positions = shadowBzObjectApi.positions;
  const promptResult = shadowBzObjectApi.promptResult;

  return (
    <Box width="100px">
      <ControllerPlus
        name="priceGroup"
        control={control}
        render={({ field, fieldState }) => {
          return (
            <PriceGroupPickerField
              {...field}
              clearable={false}
              disabled={disabled}
              onChange={async (e) => {
                let confirmed = true;
                if (positions.length > 0) {
                  confirmed = await dialogs.confirm({
                    title: t('COMMON.CHANGE'),
                    description: t('ERP.GROUP_PICKER.CHANGE_QUESTION'),
                    confirmLabel: t('COMMON.YES'),
                    cancelLabel: t('ALERTS.BTN_ABORT'),
                  });
                }
                if (confirmed) {
                  promptResult.current.push({
                    result: PromptResult.YES,
                    type: PromptType.BZ_OBJECT_CHANGE_PRICE_GROUP_CHANGE_PRICE,
                  });
                  field.onChange(e);
                }
              }}
              error={fieldState?.error?.message}
            />
          );
        }}
      />
    </Box>
  );
};
