import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { useTableStateBag } from '@work4all/components';

import { DeliveryNote } from '@work4all/models/lib/Classes/DeliveryNote.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { EntityTable, IEntityTable } from './entity-table/EntityTable';
import { useEntityDataTable } from './entity-table/use-entity-data-table';
import { useCloneConvertErp } from './offers-table/hooks/use-clone-convert-erp';
import schema from './schemata/deliveryNote-table-schema.json';
import { useDeleteEntitiesToolbarConfig } from './use-delete-entities-toolbar-config';
import { useErpHandlersTemplate } from './use-erp-handlers-template';

const defaultSort = [{ field: 'date', direction: SortDirection.DESCENDING }];
const FORCE_REQUEST_FIELDS: DeliveryNote<EMode.query> = {
  positionList: [{ id: null, originalId: null }],
};

export const DeliveryNotesTable = React.forwardRef<TableInstance, IEntityTable>(
  function DeliveryNotesTable(_props, ref) {
    const { t } = useTranslation();
    const { prepareRowDisplayModifiers, ...dataTable } = useEntityDataTable<
      DeliveryNote,
      never
    >({
      schema: schema as never,
      defaultSort,
      forceRequestFields: FORCE_REQUEST_FIELDS,
    });

    const template = useErpHandlersTemplate();
    const rowModifiers = useCallback(
      (value: DeliveryNote) => {
        const modifiers = prepareRowDisplayModifiers(value);
        return {
          ...modifiers,
          isFaded: value.isClosed && value.frozen,
          isShade1: !value.isClosed && value.frozen,
        };
      },
      [prepareRowDisplayModifiers]
    );

    const deleteConfig = useDeleteEntitiesToolbarConfig({
      entityType: Entities.deliveryNote,
    });

    const tableStateBag = useTableStateBag();
    const { actions, component } = useCloneConvertErp({
      entity: Entities.deliveryNote,
      data: useMemo(
        () =>
          tableStateBag.tableInstance?.selectedFlatRows.map(
            (x) => x.original as DeliveryNote
          ),
        [tableStateBag]
      ),
    });

    const custom = useMemo(
      () => ({
        left: actions,
      }),
      [actions]
    );

    return (
      <>
        {component}
        <EntityTable
          ref={ref}
          template={template}
          {...dataTable}
          actions={{
            custom,
            remove: {
              ...deleteConfig,
              canDeleteEntity: (id) => {
                const data = dataTable.selectedEntities.find(
                  (item) => item.id === Number(id)
                );

                const positions = data?.positionList ?? [];

                return {
                  value:
                    positions.length === 0 ||
                    Boolean(
                      positions.find((position) => {
                        return position.originalId === 0;
                      })
                    ),
                  preventMessage: t('ALERTS.ERP_PREVENT_DELETE_MESSAGE', {
                    entity: t(`COMMON.${Entities.deliveryNote.toUpperCase()}`),
                  }),
                };
              },
            },
          }}
          prepareRowDisplayModifiers={rowModifiers}
        />
      </>
    );
  }
);
