import styles from './MobileWrapper.module.scss';

import React, { PropsWithChildren } from 'react';

import { useRenderMobileConfig } from '../mobile-navigation/useRenderMobileConfig';

export const MobileWrapper: React.FC<PropsWithChildren<unknown>> = (props) => {
  const renderMobileConfig = useRenderMobileConfig();

  if (!renderMobileConfig) {
    return (
      <div className={styles['mobile-wrapper-list-view']}>{props.children}</div>
    );
  }
  return <div className={styles['mobile-wrapper']}>{props.children}</div>;
};
