import { Stack } from '@mui/material';
import { useRef } from 'react';

import { EntityPickerPopover } from '../../../../../entity-picker/components';
import { useEntityTemplate } from '../../../../../entity-template-provider/EntityTemplateProvider';
import { InfoCard } from '../../../info-card/InfoCard';
import { usePreviewInput } from '../../hooks';
import { PreviewPickerInputProps } from '../../types';

export const PreviewPickerInput = <TValue,>({
  labelTranslationKey,
  value,
  accessor,
  formattedValue,
  onChange,
  entity,
  showFooter,
  disabled,
  disabledTooltip,
  actions,
  ...rest
}: PreviewPickerInputProps<TValue>) => {
  const ref = useRef<EntityPickerPopover>(null);
  const {
    lable,
    previewValue,
    onClick,
    CircularProgress,
    BeforeContentElement,
  } = usePreviewInput<TValue>({
    formattedValue,
    labelTranslationKey,
    accessor,
    ref,
    entity,
    value,
    disabled,
  });

  const entityTemplate = useEntityTemplate();

  return (
    <Stack direction="row">
      <div style={{ width: '100%' }}>
        <EntityPickerPopover
          {...rest}
          ref={ref}
          onTargetClick={onClick}
          disabled
          footer={
            showFooter
              ? entityTemplate.renderPickerFooter({
                  entity: entity,
                  popoverRef: ref,
                  data: value,
                  onChange: (value) => {
                    onChange(Array.isArray(value) ? null : value);
                  },
                })
              : undefined
          }
        >
          <InfoCard
            truncate
            label={lable}
            decorator={<CircularProgress />}
            decoratorPos="right"
            beforeContentElement={<BeforeContentElement />}
            disabled={disabled}
            tooltip={disabled ? disabledTooltip : undefined}
          >
            {previewValue}
          </InfoCard>
        </EntityPickerPopover>
      </div>

      {actions}
    </Stack>
  );
};
