import SaveIcon from '@mui/icons-material/Save';
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
import { Theme, useMediaQuery } from '@mui/material';
import Fab, { FabProps } from '@mui/material/Fab';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@work4all/components/lib/components/tooltip/Tooltip';

import { useMaskContext } from '../hooks/mask-context';

export const MaskOverlaySubmitButton: React.FC<
  Omit<LoadingButtonProps, 'href'> & { disableReason?: string }
> = ({ disableReason, ...props }) => {
  const { t } = useTranslation();

  const mask = useMaskContext();

  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('xl'));

  if (isDesktop) {
    const defaultProps: LoadingButtonProps = {
      variant: 'contained',
      children: t('INPUTS.SAVE'),
      type: 'submit',
      size: 'large',
      color: 'primary',
    };

    const propsWithDefault = {
      ...defaultProps,
      ...props,
      disabled: props.disabled || mask.wip,
    };

    return (
      <Tooltip title={disableReason} activateForDisabled={true}>
        <LoadingButton
          sx={{ color: 'white' }}
          startIcon={<SaveIcon />}
          {...propsWithDefault}
        />
      </Tooltip>
    );
  }

  const fabProps: FabProps = {
    type: props.type ?? 'submit',
    size: props.size ?? 'medium',
    color: props.color ?? 'primary',
    disabled: props.disabled || props.loading || mask.wip,
  };

  return (
    <Tooltip
      title={disableReason}
      activateForDisabled={{ activeateOnClick: true }}
    >
      <Fab {...fabProps} data-test-id={props['data-test-id']}>
        <SaveIcon style={{ fontSize: '1.5rem' }} />
      </Fab>
    </Tooltip>
  );
};
