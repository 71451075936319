import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { BusinessPartnerPicker } from '@work4all/components/lib/components/entity-picker/business-partner-picker/BusinessPartnerPicker';
import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import { PROJECT_DATA } from '@work4all/components/lib/components/entity-picker/project-picker/projectPickerPresets';
import { useEntityTemplate } from '@work4all/components/lib/components/entity-template-provider/EntityTemplateProvider';
import { Card } from '@work4all/components/lib/dataDisplay/card';
import {
  MultiStepControls,
  Step,
} from '@work4all/components/lib/input/multi-step-controls';

import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { MailSearchContactsResultItem } from '@work4all/models/lib/Classes/MailSearchContactsResultItem.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ContactPickerField } from '../../../../../../../../../components/entity-picker/ContactPickerField';
import { ProjectPickerField } from '../../../../../../../../../components/entity-picker/ProjectPickerField';
import { TopicPickerField } from '../../../../../../../../../components/entity-picker/TopicPickerField';
import { ControllerPlus } from '../../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../../form-plus/use-form-context-plus';
import { PickerTargetButton } from '../../../../../../../locked-inputs';
import { ControlWrapper } from '../../../../../../components/control-wrapper';
import { useMaskContext } from '../../../../../../hooks/mask-context';
import { EmailMaskFormValue } from '../../../../types';

export const Assignments = ({
  defaultOpen = true,
}: {
  defaultOpen?: boolean;
}) => {
  const { t } = useTranslation();

  const mask = useMaskContext();

  const form = useFormContextPlus<EmailMaskFormValue>();
  const { control, watch } = form;

  const businessPartnerWrapperRef = useRef<HTMLElement>();
  const projectWrapperRef = useRef<HTMLElement>();
  const topicWrapperRef = useRef<HTMLElement>();

  const businessPartnerType = watch('businessPartnerType');
  const businessPartnerId = watch('businessPartnerId');

  /**
   * Update the form's "to" field to the given contact's email address. Sets the
   * field to "" if the contact is `null` or does not have an email address.
   *
   * Will not do anything if the mask is in `"edit"` mode or if the field has been
   * manually changed before.
   */
  function maybeUpdateToField({
    contact,
    businessPartner,
  }: {
    contact: Contact;
    businessPartner: Customer | Supplier;
  }): void {
    if (
      mask.isEditMode ||
      form.getFieldState('toMailContactsInstance').isDirty
    ) {
      return;
    }

    function makeContactEmail(): MailSearchContactsResultItem | null {
      if (contact == null) {
        return null;
      }

      const id = `${businessPartner.__typename}Ap_${contact.id}`;

      return {
        __typename: 'MailSearchContactsResultItem',
        id,
        contact: {
          ...contact,
          businessPartner: {
            __typename: 'Geschaeftspartner',
            data: businessPartner,
          },
        },
        mailAddress: contact.eMail,
      };
    }

    const contactEmail = makeContactEmail();

    // The form field will not be marked as dirty, but it should be fine in
    // "create" mask mode. We need to do this, so we can know if the user has
    // manually changed the "to" field and we shouldn't auto-update it anymore.

    form.setValue(
      'toMailContactsInstance',
      contactEmail ? [contactEmail] : null,
      {
        shouldDirty: false,
      }
    );
  }

  const bpRef = useRef<EntityPickerPopover>(null);
  const entityTemplate = useEntityTemplate();
  const [selectedTab, setSelectedTab] = useState(Entities.customer);

  return (
    <Collapse
      title={t('MASK.INTERNAL_ASSIGNMENT')}
      defaultOpen={defaultOpen}
      topIndent={false}
    >
      <Card>
        <ControlWrapper ref={businessPartnerWrapperRef}>
          <ControllerPlus
            control={control}
            name="businessPartner.data"
            render={({ field: businessPartner }) => {
              return (
                <ControllerPlus
                  control={control}
                  name="contact"
                  render={({ field: contact }) => {
                    const onContactChange = (value: Contact | null): void => {
                      contact.onChange(value);
                      maybeUpdateToField({
                        contact: value,
                        businessPartner: businessPartner.value,
                      });
                    };

                    const onBusinessPartnerChange = (
                      value: Customer | Supplier
                    ): void => {
                      businessPartner.onChange(value);
                    };

                    return (
                      <MultiStepControls isColumnDirection={true}>
                        <Step active={true} index={0}>
                          <EntityPickerPopover
                            ref={bpRef}
                            anchorEl={businessPartnerWrapperRef}
                            footer={entityTemplate.renderPickerFooter({
                              popoverRef: bpRef,
                              entity: selectedTab,
                              onChange: onBusinessPartnerChange,
                              data: businessPartner.value,
                            })}
                            picker={
                              <BusinessPartnerPicker
                                multiple={false}
                                data={BUSINESS_PARTNER_PICKER_DATA}
                                value={businessPartner.value}
                                onChange={onBusinessPartnerChange}
                                onTabSelected={setSelectedTab}
                              />
                            }
                          >
                            <PickerTargetButton
                              onClear={() => {
                                onBusinessPartnerChange(null);
                              }}
                              value={businessPartner.value?.name || ''}
                              label={
                                !businessPartner.value?.name
                                  ? `${t('COMMON.CUSTOMER')}/${t(
                                      'COMMON.SUPPLIER'
                                    )}`
                                  : t(
                                      `COMMON.${
                                        getBusinessPartnerType(
                                          businessPartner.value
                                        ) === Entities.customer
                                          ? 'CUSTOMER'
                                          : 'SUPPLIER'
                                      }`
                                    )
                              }
                            />
                          </EntityPickerPopover>
                        </Step>

                        <Step active={businessPartner.value !== null} index={1}>
                          {businessPartner.value && (
                            <ContactPickerField
                              data={CONTACT_PICKER_DATA}
                              value={contact.value}
                              businessPartnerType={businessPartnerType}
                              businessPartnerId={businessPartnerId}
                              onChange={onContactChange}
                            />
                          )}
                        </Step>
                      </MultiStepControls>
                    );
                  }}
                />
              );
            }}
          />
        </ControlWrapper>

        <ControlWrapper ref={projectWrapperRef}>
          <ControllerPlus
            control={control}
            name="project"
            render={({ field: project }) => {
              return (
                <MultiStepControls isColumnDirection={false}>
                  <Step active={true} index={0}>
                    <ProjectPickerField {...project} data={PROJECT_DATA} />
                  </Step>
                </MultiStepControls>
              );
            }}
          />
        </ControlWrapper>

        <ControlWrapper ref={topicWrapperRef}>
          <ControlWrapper>
            <ControllerPlus
              control={control}
              name="topicMarkList"
              render={({ field }) => {
                return (
                  <TopicPickerField
                    type={Entities.eMail}
                    {...field}
                    onChange={(val) => {
                      field.onChange(val ? [val] : null);
                    }}
                    value={
                      field.value && field.value[0]?.id !== 0
                        ? field.value[0]
                        : null
                    }
                  />
                );
              }}
            />
          </ControlWrapper>
        </ControlWrapper>
      </Card>
    </Collapse>
  );
};

const BUSINESS_PARTNER_PICKER_DATA: Customer | Supplier = {
  mainContactId: null,
};

const CONTACT_PICKER_DATA: Contact = { eMail: null };

function getBusinessPartnerType(
  businessPartner: Customer | Supplier
): Entities {
  if (businessPartner) {
    const typename: string = businessPartner.__typename;

    if (typename === 'Kunde') {
      return Entities.customer;
    }
  }

  return Entities.supplier;
}
