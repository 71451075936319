import CheckCircleRounded from '@mui/icons-material/CheckCircleRounded';
import CircleTwoTone from '@mui/icons-material/CircleTwoTone';
import { Box, IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';

import { MailboxContent } from '@work4all/models/lib/Classes/MailboxContent.entity';
import { MailAssignStatus } from '@work4all/models/lib/Enums/MailAssignStatus.enum';

import { useMailboxContentManagerContext } from '../mailbox-content-manager-context';

export function MailboxContentAssignButtonCell(
  props: CellProps<MailboxContent, MailAssignStatus>
) {
  const status = props.value;
  const mailboxContent = props.row.original;
  const id = mailboxContent.id;

  const { t } = useTranslation();

  const context = useMailboxContentManagerContext();

  const canAssign = context.canAssign(id);
  const isAssigned =
    status === MailAssignStatus.ALREADY_ASSIGNED ||
    status === MailAssignStatus.ASSIGNED_TO_OBJECT ||
    status === MailAssignStatus.ASSIGNED_TO_OBJECT_AND_MAIL;

  const buttonProps = canAssign
    ? {
        component: 'button',
        onClick: async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.stopPropagation();
          await context.assign([id]);
          context.refetch();
        },
      }
    : { component: 'span' };

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: '8px',
      }}
    >
      <Box display="flex">
        {isAssigned || canAssign ? (
          <Tooltip
            title={
              canAssign
                ? t('LIST.MAILBOXCONTENT.ACTION.ASSIGN')
                : t('MAIL_ASSIGN_STATUS.ALREADY_ASSIGNED')
            }
            placement="right"
          >
            <IconButton
              color="primary"
              sx={{
                flex: 'none',
                padding: 0,
                marginRight: '6px',
                cursor: canAssign ? 'pointer' : 'default',
              }}
              {...buttonProps}
            >
              {canAssign ? (
                <CircleTwoTone
                  sx={{ color: 'var(--text03)', width: 30, height: 30 }}
                />
              ) : (
                <CheckCircleRounded
                  sx={{ color: 'var(--success)', width: 30, height: 30 }}
                />
              )}
            </IconButton>
          </Tooltip>
        ) : (
          <Box width="36px" />
        )}
      </Box>
    </Box>
  );
}
