import { CustomPicker } from '../form-fields/CustomPickerField';

import { SimpleCustomFieldProps } from './types';

const DEFAULT_OPTIONS = [];
export const SimpleCustomPickerField = (props: SimpleCustomFieldProps) => {
  const { label, options = DEFAULT_OPTIONS, value, onChange } = props;
  return (
    <CustomPicker
      label={label}
      options={options}
      onClear={() => onChange('')}
      value={value}
      onChange={onChange}
    />
  );
};
