import style from './TabContent.module.scss';

import { AutoSizer } from 'react-virtualized';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { TableStateBagProvider } from '../../../../dataDisplay/basic-table';
import { Table } from '../../../table/Table';
import { useTabColumns, useTabData } from '../hooks';
import { TableBottomViewProps } from '../interfaces';

interface TabContentProps
  extends Pick<TableBottomViewProps, 'selectedEntityId' | 'entity'> {
  tab: Entities;
}

export const TabContent = ({
  entity,
  tab,
  selectedEntityId,
}: TabContentProps) => {
  const columns = useTabColumns(tab);
  const data = useTabData(entity, tab, selectedEntityId);

  return (
    <AutoSizer>
      {({ width }) => {
        return (
          <div style={{ width }}>
            <TableStateBagProvider tableInstance={null}>
              <Table
                mode="client"
                columns={columns}
                data={data}
                allItemsCount={data.length}
                className={style.table}
                resizableColumns
                selectableRows={false}
                selectableMultiple={false}
                hideHeaderTooltip
              />
            </TableStateBagProvider>
          </div>
        );
      }}
    </AutoSizer>
  );
};
