import { MailboxContentFilters } from '../MailboxContentTable';

import {
  MailboxContentMutations,
  useMailboxContentMutations,
} from './use-mailbox-content-mutations';
import {
  MailboxContentRequests,
  MailboxContentRequestsOptions,
  useMailboxContentRequests,
} from './use-mailbox-content-requests';
import {
  MailboxContentStateHelpers,
  useMailboxContentStateHelpers,
} from './use-mailbox-content-state-helpers';
import {
  MailboxContentStateManager,
  useMailboxContentStateManager,
  UseMailboxContentStateManagerOptions,
} from './use-mailbox-content-state-manager';
import {
  MailboxContentStatuses,
  useMailboxContentStatuses,
} from './use-mailbox-content-statuses';

export interface MailboxContentManager
  extends MailboxContentStateManager,
    MailboxContentStateHelpers,
    MailboxContentStatuses,
    MailboxContentMutations,
    MailboxContentRequests {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UseMailboxContentManagerOptions
  extends UseMailboxContentStateManagerOptions,
    MailboxContentRequestsOptions {}

export interface UseMailboxContentManagerFilters
  extends MailboxContentFilters {}

export function useMailboxContentManager(
  options: UseMailboxContentManagerOptions,
  filters: UseMailboxContentManagerFilters
): MailboxContentManager {
  const { state, onContactChange, onProjectChange } =
    useMailboxContentStateManager(options);

  const helpers = useMailboxContentStateHelpers(state);

  const statuses = useMailboxContentStatuses(state);

  const mutations = useMailboxContentMutations(state, filters);

  const requests = useMailboxContentRequests(options);

  return {
    state,
    onContactChange,
    onProjectChange,
    ...helpers,
    ...statuses,
    ...mutations,
    ...requests,
  };
}
