import React from 'react';
import { useTranslation } from 'react-i18next';

import { IPriceGroupPickerProps } from '@work4all/components/lib/components/entity-picker/price-group-picker/PriceGroupPicker';

import { PriceGroup } from '@work4all/models/lib/Classes/PriceGroup.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { EntityPickerField } from './EntityPickerField';

interface Props extends Omit<IPriceGroupPickerProps<false>, 'multiple'> {
  error?: string;
  disabled?: boolean;
}

export const PriceGroupPickerField = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { t } = useTranslation();
    return (
      <EntityPickerField
        ref={ref}
        label={t('COMMON.PRICE_GROUP')}
        entity={Entities.priceGroup}
        data={PAYMENT_KIND_FIELDS}
        {...props}
      />
    );
  }
);

const PAYMENT_KIND_FIELDS: PriceGroup = {
  isGrossPrice: null,
};
