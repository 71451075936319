import { DateTime } from 'luxon';
import { FC, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { InlineData } from './InlineData';

type IFirstContactCardProps = {
  firstContactPersonName?: string;
  firstContactDate?: Date;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export const FirstContactCard: FC<IFirstContactCardProps> = ({
  firstContactPersonName,
  firstContactDate,
  onClick,
}) => {
  const { t } = useTranslation();

  const dateString = DateTime.fromJSDate(firstContactDate).isValid
    ? `${DateTime.fromJSDate(firstContactDate).toFormat('dd.MM.yyyy')} `
    : '-';

  const personString = firstContactPersonName
    ? `${t('COMMON.BY')} ${firstContactPersonName}`
    : '';

  return (
    <InlineData
      onClick={onClick}
      label={t('FILE_CONTACTS.FIRSTCONTACT')}
      content={`${dateString}${personString}`}
    />
  );
};
