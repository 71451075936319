import styles from './EmailSignaturePreview.module.scss';

import { usePageZoom } from '@work4all/data';

import { EMailSignature } from '@work4all/models/lib/Classes/EMailSignature.entity';

import { PreviewTitle, PreviewWrapper } from '../components';
import { setDefaultHeadStyles } from '../utils';

export type IEmailSignaturePreviewProps = {
  emailSignature: EMailSignature;
  onCloseClicked?: React.MouseEventHandler<HTMLButtonElement>;
  onEditClicked?: React.MouseEventHandler<HTMLButtonElement>;
};

export function EmailSignaturePreview(props: IEmailSignaturePreviewProps) {
  const { emailSignature, onCloseClicked, onEditClicked } = props;

  const { zoomLevel } = usePageZoom();

  return (
    <PreviewWrapper>
      <div className={styles.wrap}>
        <PreviewTitle
          onCloseClicked={onCloseClicked}
          onEditClicked={onEditClicked}
        >
          {emailSignature.name}
        </PreviewTitle>

        <iframe
          className={styles.content}
          title="Email signature Preview"
          srcDoc={
            setDefaultHeadStyles({
              value: emailSignature?.body || '',
              textmarkStyles: true,
              zoom: zoomLevel,
            }) || emailSignature.body
          }
        />
      </div>
    </PreviewWrapper>
  );
}
