import { Button } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog, DialogActions, DialogContent } from '../../dialog';

export type AlertDialogProps = {
  open: boolean;
  title: string;
  description: string;
  /**
   * @default "Ok"
   */
  closeLabel?: React.ReactNode;
  onClose: () => void;
};

export const AlertDialog: React.FC<AlertDialogProps> = (props) => {
  const { t } = useTranslation();

  const {
    open,
    title,
    description,
    closeLabel = t('ALERTS.BTN_OK'),
    onClose,
  } = props;

  return (
    <Dialog open={open} title={title} onClose={onClose}>
      <DialogContent>{description}</DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {closeLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
