import { Tooltip, Typography } from '@mui/material';
import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ListEntityPicker } from './components/list-entity-picker';
import { EntityLike, IPickerProps } from './types';

interface NamedEntity extends EntityLike {
  name?: string;
}

export interface IEntityPicker<TMultiple extends boolean>
  extends IPickerProps<NamedEntity, TMultiple> {
  sortBy?: string;
  filterBy?: string | string[];
  entity: Entities;
  operationName?: string;
}

/**
 * Entity picker to unify most of code in all pickers we have (at least the new ones)
 * For now it contains implementation of fixedDataSet.
 * TODO: https://work4all.atlassian.net/browse/WW-4012
 */
export function EntityPicker<TMultiple extends boolean>(
  props: IEntityPicker<TMultiple>
) {
  const {
    data,
    sortBy = 'name',
    filterBy = 'name',
    operationName,
    entity,
    ...rest
  } = props;
  const allFields = useMemo(() => {
    return { ...FIELDS, ...data };
  }, [data]);

  const request = useMemo<DataRequest>(() => {
    return {
      data: allFields,
      entity,
      completeDataResponse: true,
      operationName,
    };
  }, [allFields, entity, operationName]);

  const response = useDataProvider(request);

  // The data provider does not set the `total` property when
  // `completeDataResponse` is `true`.   So we have to set it manually.
  // Otherwise, the <ListEntityPicker /> will not be able to render the list.
  const patchedResponse = useMemo(() => {
    return { ...response, total: response.data.length };
  }, [response]);

  const renderItem = (priceGroup: NamedEntity) => {
    const label = priceGroup.name;

    return (
      <Tooltip title={label}>
        <Typography variant="body2" noWrap>
          {label}
        </Typography>
      </Tooltip>
    );
  };

  return (
    <ListEntityPicker
      entity={entity}
      data={allFields}
      filterBy={filterBy}
      sortBy={sortBy}
      {...rest}
      fixedDataSet={patchedResponse}
      renderItemContent={renderItem}
    />
  );
}

const FIELDS = {
  id: null,
  name: null,
};
