export enum ReportBzObjType {
  DOKUMENTE = 'DOKUMENTE',
  LIEFERANTEN_LIEFERSCHEIN = 'LIEFERANTEN_LIEFERSCHEIN',
  ANGEBOT_AUFTRAG = 'ANGEBOT_AUFTRAG',
  RECHNUNG = 'RECHNUNG',
  LIEFERSCHEIN = 'LIEFERSCHEIN',
  BESTELLUNG = 'BESTELLUNG',
  BEDARF = 'BEDARF',
  PRODUKTIONSAUFTRAG = 'PRODUKTIONSAUFTRAG',
  EINGANGSLIEFERSCHEIN = 'EINGANGSLIEFERSCHEIN',
  RUECKNAHME = 'RUECKNAHME',
  REISEKOSTEN = 'REISEKOSTEN',
  RUECKSTAND_LIEFERANTEN = 'RUECKSTAND_LIEFERANTEN',
  RUECKSTAND_KUNDEN = 'RUECKSTAND_KUNDEN',
  KONTOAUSZUG = 'KONTOAUSZUG',
  LISTE_LIEFERANTEN = 'LISTE_LIEFERANTEN',
  LISTE_KUNDEN = 'LISTE_KUNDEN',
  LISTE_PROJEKTE = 'LISTE_PROJEKTE',
  LISTE_TEXTBAUSTEINE = 'LISTE_TEXTBAUSTEINE',
  LISTE_ARTIKEL = 'LISTE_ARTIKEL',
  LISTE_MITARBEITER = 'LISTE_MITARBEITER',
  LISTE_STUECKLISTEN = 'LISTE_STUECKLISTEN',
  LISTE_VERTEILER = 'LISTE_VERTEILER',
  PROJEKT = 'PROJEKT',
  LISTE_OBJEKTE = 'LISTE_OBJEKTE',
  GERAETE = 'GERAETE',
  DOKUMENTENLISTE = 'DOKUMENTENLISTE',
  LISTE_LIEFERANTEN_LIEFERSCHEIN = 'LISTE_LIEFERANTEN_LIEFERSCHEIN',
  LISTE_ANGEBOTE_AUFTRAEGE = 'LISTE_ANGEBOTE_AUFTRAEGE',
  LISTE_RECHNUNGEN = 'LISTE_RECHNUNGEN',
  LISTE_LIEFERSCHEINE = 'LISTE_LIEFERSCHEINE',
  LISTE_BESTELLUNGEN = 'LISTE_BESTELLUNGEN',
  LISTE_BEDARF = 'LISTE_BEDARF',
  LISTE_PRODUKTIONSAUFTRAG = 'LISTE_PRODUKTIONSAUFTRAG',
  LISTE_EINGANGSLIEFERSCHEINE = 'LISTE_EINGANGSLIEFERSCHEINE',
  LISTE_RUECKNAHME = 'LISTE_RUECKNAHME',
  LISTE_REISEKOSTEN = 'LISTE_REISEKOSTEN',
  LISTE_RUECKSTAND_LIEFERANTEN = 'LISTE_RUECKSTAND_LIEFERANTEN',
  LISTE_RUECKSTAND_KUNDEN = 'LISTE_RUECKSTAND_KUNDEN',
  WARTUNGSOBJEKT = 'WARTUNGSOBJEKT',
  LISTE_WARTUNG = 'LISTE_WARTUNG',
  KUNDEN = 'KUNDEN',
  LIEFERANTEN = 'LIEFERANTEN',
  LISTE_RECHNUNGSAUSGAENGE = 'LISTE_RECHNUNGSAUSGAENGE',
  LISTE_RECHNUNGSEINGAENGE = 'LISTE_RECHNUNGSEINGAENGE',
  RECHNUNGSAUSGAENGE = 'RECHNUNGSAUSGAENGE',
  RECHNUNGSEINGAENGE = 'RECHNUNGSEINGAENGE',
  ANGEBOT = 'ANGEBOT',
  LISTE_PRODUKTIONSPROTOKOLL = 'LISTE_PRODUKTIONSPROTOKOLL',
  KALKULATION = 'KALKULATION',
  LISTE_ANGEBOTE = 'LISTE_ANGEBOTE',
  AUFTRAG = 'AUFTRAG',
  KASSE = 'KASSE',
  LISTE_AUFTRAEGE = 'LISTE_AUFTRAEGE',
  ROHDATENLISTE_STATISTIK = 'ROHDATENLISTE_STATISTIK',
  TERMINKALENDER = 'TERMINKALENDER',
  TICKET = 'TICKET',
  TICKET_LISTE = 'TICKET_LISTE',
  KONTAKTBERICHT = 'KONTAKTBERICHT',
  KONTAKTBERICHT_LISTE = 'KONTAKTBERICHT_LISTE',
  VERKAUFSCHANCE = 'VERKAUFSCHANCE',
  LISTE_VERKAUFSCHANCEN = 'LISTE_VERKAUFSCHANCEN',
  DESKTOP = 'DESKTOP',
}
