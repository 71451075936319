import { gql, useQuery } from '@apollo/client';

import {
  QueredBirthday,
  UseBirthdaysQueryProps,
  UseBirthdaysQueryReturn,
} from '../types';

export const useBirthdaysQuery = ({
  startDate,
  lookaheadDays,
  skip,
}: UseBirthdaysQueryProps): UseBirthdaysQueryReturn => {
  const query = useQuery<{ getUpcommingBirthdays: QueredBirthday[] }>(QUERY, {
    variables: {
      startDate,
      lookaheadDays,
      contactTypes: [
        'KUNDE',
        'KUNDE_AP',
        'LIEFERANT',
        'LIEFERANT_AP',
        'MITARBEITER',
        'BENUTZER',
        'BLANK_ADDRESS',
      ],
    },
    skip,
  });

  return {
    data: query?.data?.getUpcommingBirthdays ?? [],
    refetch: query.refetch,
  };
};

const QUERY = gql`
  query GetUpcommingBirthdays(
    $startDate: DateTimeEx!
    $lookaheadDays: Int
    $contactTypes: [ContactType]
  ) {
    getUpcommingBirthdays(
      startDate: $startDate
      lookaheadDays: $lookaheadDays
      contactTypes: $contactTypes
    ) {
      ... on Benutzer {
        code
        geburtsdatum
        telefon
        eMail
        anzeigename
      }
      ... on Ansprechpartner {
        code
        geburtsdatum
        telefon
        eMail
        anzeigename
        geschaeftspartner {
          code
          sdObjType
          data {
            ... on Kunde {
              code
              name
            }
            ... on Lieferant {
              code
              name
            }
          }
        }
      }
      ... on Kunde {
        code
        geburtsdatum
        telefon
        eMail
        name
      }
      ... on Lieferant {
        code
        geburtsdatum
        telefon
        eMail
        name
      }
    }
  }
`;
