import { createContext, useContext } from 'react';

import { OnOpenMask } from '../types';

interface OnOpenMaskState {
  onOpenMask: OnOpenMask;
}

const Context = createContext<OnOpenMaskState | null>(null);
export const OpenMaskProvider = Context.Provider;

export const useOnOpenMaskContext = () => {
  const context = useContext(Context);

  if (!context)
    throw new Error(
      'context is null. Make sure you wrapped your component with <OpenMaskProvider />'
    );
  return context;
};
