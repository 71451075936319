import { useContext } from 'react';

import { useLock } from '@work4all/components/lib/hooks';

import { useMaskContext } from '../../mask-overlay/hooks/mask-context';
import { EntityRightsContext } from '../../mask-overlay/hooks/use-entity-rights';

import { LockedBanderole } from './LockedBanderole';
import { NoEditRightBanderole } from './NoEditRightBanderole';
import { WorkInProgressBanderole } from './WorkInProgressBanderole';

export const AutoLockedBanderole = () => {
  const editRights = useContext(EntityRightsContext);
  const mask = useMaskContext();

  const { locked, user: lockedBy, lockReason } = useLock();

  if (
    (!editRights.update && mask.mode === 'edit') ||
    (!editRights.create && mask.mode === 'create')
  ) {
    return <NoEditRightBanderole />;
  }

  if (locked) {
    const props = lockReason ? { lockReason } : lockedBy;
    return <LockedBanderole {...props} />;
  }

  if (mask.wip) {
    return <WorkInProgressBanderole />;
  }

  return null;
};
