import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { ProgressCell } from '@work4all/components/lib/dataDisplay/basic-table/utils/cells/ProgressCell';

import { SalesOpportunities } from '@work4all/models/lib/Classes/SalesOpportunities.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { SalesOpportunityStatus } from '@work4all/models/lib/Enums/SalesOpportunityStatus.enum';

import { SalesOpportunityStatusCell } from './cell-renderer/SalesOpportunityStatusCell';
import { SalesRatedCell } from './cell-renderer/SalesRatedCell';
import { TendencyCell } from './cell-renderer/TendencyCell';
import { EntityTable, IEntityTable } from './entity-table/EntityTable';
import { useEntityDataTable } from './entity-table/use-entity-data-table';
import { SalesOpportunitiesQuickFilters } from './sales-opportunities-table/SalesOpportunitiesQuickFilters';
import schema from './schemata/salesOpportunities-table-schema.json';

const defaultSort = [
  { field: 'dateCreated', direction: SortDirection.DESCENDING },
];

export const SalesOpportunitiesTable = React.forwardRef<
  TableInstance,
  IEntityTable
>(function SalesOpportunitiesTable(props, ref) {
  const { forceRequestFields, ...rest } = props;

  const { prepareRowDisplayModifiers, ...dataTable } = useEntityDataTable<
    SalesOpportunities,
    Record<string, never>
  >({
    schema: schema as never,
    forceRequestFields,
    defaultSort,
    cells: {
      TendencyCell,
      ProgressCell,
      SalesOpportunityStatusCell,
      SalesRatedCell,
    },
    enableFooter: true,
  });

  const { t } = useTranslation();

  const rowModifiers = useCallback(
    (value: SalesOpportunities) => {
      const modifiers = prepareRowDisplayModifiers(value);
      return {
        ...modifiers,
        isFaded: [
          SalesOpportunityStatus.CONVERTED_TO_ORDER,
          SalesOpportunityStatus.CONVERTED_TO_INVOICE,
          SalesOpportunityStatus.CONVERTED_TO_DELIVERY_NOTE,
          SalesOpportunityStatus.CONVERTED_TO_OFFER,
          SalesOpportunityStatus.LOST,
          SalesOpportunityStatus.HIDE,
        ].includes(value.status),
      };
    },
    [prepareRowDisplayModifiers]
  );

  return (
    <EntityTable
      ref={ref}
      {...dataTable}
      areas={{
        left: {
          content: <SalesOpportunitiesQuickFilters />,
          resizable: true,
          collapseConfig: {
            title: t('COMMON.FILTER'),
          },
        },
      }}
      prepareRowDisplayModifiers={rowModifiers}
      displayFooter
      {...rest}
    />
  );
});
