import styles from './TableCell.module.scss';

import clsx from 'clsx';
import React, { CSSProperties, forwardRef } from 'react';
import { TableCommonProps } from 'react-table';

import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

export type ITableCellProps = TableCommonProps & {
  onDoubleClick?: React.MouseEventHandler<HTMLDivElement>;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  children?: React.ReactNode;
  id?: string;
  kind?: ErpPositionsKind;
  shouldWrap?: boolean;
  singleLine?: boolean;
  innerRef?: (instance: HTMLElement) => void;
};

export const TableCell = forwardRef<HTMLDivElement, ITableCellProps>(
  function TableCell(props, ref) {
    const {
      className,
      id,
      kind,
      style,
      shouldWrap,
      singleLine,
      innerRef,
      ...rest
    } = props;
    const css = renderCellByType({ id: id as Columns, kind });

    if (shouldWrap) {
      const lineClass = singleLine
        ? styles['single-line']
        : styles['multi-line'];
      return (
        <div
          ref={ref}
          style={{ ...style, ...css }}
          className={clsx(styles['table-cell'], className)}
          {...rest}
        >
          <div ref={innerRef} className={clsx('inside-row', lineClass)}>
            {rest.children}
          </div>
        </div>
      );
    }

    return (
      <div
        ref={ref}
        style={{ ...style, ...css }}
        className={clsx(styles['table-cell'], className)}
        {...rest}
      />
    );
  }
);

type Columns =
  | 'selection'
  | 'number'
  | 'longtext'
  | 'amount'
  | 'unit'
  | 'singlePriceNet'
  | 'discount'
  | 'insteadOfTotalPrice'
  | 'totalPriceNet';

interface RenderCellByTypeProps {
  id?: Columns;
  kind?: ErpPositionsKind;
}

interface CellDefinition {
  selection: CSSProperties;
  number: CSSProperties;
  longtext: CSSProperties;
  amount: CSSProperties;
  unit: CSSProperties;
  singlePriceNet: CSSProperties;
  discount: CSSProperties;
  insteadOfTotalPrice: CSSProperties;
  totalPriceNet: CSSProperties;
}

export function renderCellByType(
  props: RenderCellByTypeProps
): CSSProperties | undefined {
  const { kind, id } = props;

  if (!kind) return undefined;

  const standard = {};
  const title = {
    selection: { color: 'transparent' },
    number: { fontWeight: '700' },
    longtext: { fontWeight: '700' },
    amount: { color: 'transparent' },
    unit: { color: 'transparent' },
    singlePriceNet: { color: 'transparent' },
    discount: { color: 'transparent' },
    insteadOfTotalPrice: { color: 'transparent' },
    totalPriceNet: {
      fontWeight: '700',
    },
  };
  const sum = {
    ...title,
    number: { color: 'transparent' },
    longtext: { fontWeight: '700', color: 'var(--text03)' },
    totalPriceNet: {
      fontWeight: '700',
      color: 'var(--text03)',
    },
  };
  const definitions: Record<ErpPositionsKind, Partial<CellDefinition>> = {
    AUTO_EK_PROZENT: standard,
    BELEGRABATT: standard,
    BELEGZUSCHLAG: standard,
    BEZUGSKOSTEN: standard,
    EXCEL_ARTIKEL: standard,
    INTERNE_POSITION: standard,
    INTERNE_STUECKLISTE: standard,
    RABATTPOSITION: standard,
    SEITENUMBRUCH: standard,
    STANDARD: standard,
    STUECKLISTE: standard,
    STUECKLISTE_OHNE_BERECHNUNG: standard,
    TEILRECHNUNGSPOSITION: standard,
    TEXTZEILE: {
      ...title,
      longtext: undefined,
      totalPriceNet: { color: 'transparent' },
    },
    TITEL: {
      ...title,
      totalPriceNet: { color: 'transparent' },
    },
    TITELSUMME: sum,
    TITEL_INTERN: standard,
    TITEL_OHNE_NUMMER: standard,
    UMWANDLUNGSHISTORIE: standard,
    ZWISCHENSUMME: sum,
  };

  const def = definitions[kind];
  const cellDef = def[id];
  return cellDef;
}
