import styles from './Table.module.scss';

import clsx from 'clsx';
import React from 'react';
import { TableInstance } from 'react-table';
import { z } from 'zod';

import { BasicTable, IBasicTableProps } from '../../dataDisplay/basic-table';

export const tableLayoutZod = z.enum(['table', 'cards']);
export type TableLayout = z.infer<typeof tableLayoutZod>;

interface ITableProps extends IBasicTableProps {
  hiddenHeader?: boolean;
}

export const Table = React.forwardRef<TableInstance, ITableProps>(
  function Table(props, ref) {
    const { hiddenHeader = false, ...tableProps } = props;

    const classes = {
      headerWrapper: clsx(styles.headerWrapper, {
        [styles.hidden]: hiddenHeader,
      }),
      headerCellHeading: styles.headerCellHeading,
      headerSeperator: styles.headerSeperator,
      row: styles.row,
      cell: styles.cell,
      selection: styles.selection,
    };

    return (
      <BasicTable
        onSelectedRowsChange={props.onSelectedRowsChange}
        ref={ref}
        classes={classes}
        reordableColumns={false}
        selectableRows={true}
        rowHeightRem={styles.maskOverlayRowHeight}
        isVirtual={false}
        fitContentHeight
        onRowDoubleClick={props.onRowDoubleClick}
        {...tableProps}
      />
    );
  }
);
