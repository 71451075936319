import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class ReportOptionValue<T extends EMode = EMode.entity> {
  /** Alias: optionId */
  optionId?: number;
  /** Alias: value */
  value?: boolean;

  __typename?: string;
}

const fields: FieldDefinitions<ReportOptionValue> = {
  optionId: {
    alias: 'optionId',
  },
  value: {
    alias: 'value',
  },
  __typename: {
    alias: '__typename',
  },
};

export const reportOptionValueEntityDefinition: EntitiyDefinition<ReportOptionValue> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'ReportOptionValue',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
