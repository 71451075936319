import React, { Suspense } from 'react';

import { isDev } from '@work4all/utils';

export function loadable<Props>(
  Component: React.ComponentType<Props>,
  fallback: React.ReactNode
) {
  const ComponentWithSuspense: React.FC<Props> = (props: Props) => {
    return (
      <Suspense fallback={fallback}>
        <Component {...props} />
      </Suspense>
    );
  };

  if (isDev()) {
    const displayName = Component.displayName || Component.name || 'Anonymous';
    ComponentWithSuspense.displayName = `Loadable(${displayName})`;
  }

  return ComponentWithSuspense;
}
