import { ApolloCache, useApolloClient } from '@apollo/client';
import { Modifiers } from '@apollo/client/cache/core/types/common';
import { useEventCallback } from '@mui/material/utils';

import { MailAssignStatus } from '@work4all/models/lib/Enums/MailAssignStatus.enum';
import { ObjectType } from '@work4all/models/lib/Enums/ObjectType.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import {
  isContact,
  isCustomer,
  isSupplier,
} from '../cells/contact-or-business-partner';
import { MailboxContentFilters } from '../MailboxContentTable';

import {
  useAssignMailMutation,
  useAssignMailToObjectMutation,
  useAttachMailToTicketMutation,
  useConvertMailToTempFileMutation,
  useDeleteMailMutation,
  useIgnoreMailMutation,
  useMarkMailReadMutation,
  useUnignoreMailMutation,
} from './graphql';
import { MailboxContentState } from './types';

export interface MailboxContentMutations {
  loading: boolean;
  assign: (ids: string[]) => Promise<void>;
  assignToTicket: (id: string, ticketId: string) => Promise<void>;
  ignore: (ids: string[]) => Promise<void>;
  reset: (ids: string[]) => Promise<void>;
  assignSelected: () => Promise<void>;
  ignoreSelected: () => Promise<void>;
  resetSelected: () => Promise<void>;
  deleteSelected: () => Promise<void>;
  markRead: (ids: string[]) => Promise<void>;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UseMailboxContentMutationsOptions
  extends MailboxContentState {}

export interface UseMailboxContentMutationsFilters
  extends MailboxContentFilters {}

export function useMailboxContentMutations(
  options: UseMailboxContentMutationsOptions,
  filters: UseMailboxContentMutationsFilters
): MailboxContentMutations {
  const { itemsById, folder, selectedIds } = options;
  const { filterStatus } = filters;

  const [mutateAssignMail, assignMailResult] = useAssignMailMutation();
  const [mutateIgnoreMail, ignoreMailResult] = useIgnoreMailMutation();
  const [mutateUnignoreMail, unignoreMailResult] = useUnignoreMailMutation();
  const [mutateMarkMailRead] = useMarkMailReadMutation();
  const [mutateDeleteMail] = useDeleteMailMutation();
  const [mutateAssignMailToObject] = useAssignMailToObjectMutation();
  const [mutateConvertMailToTempFile] = useConvertMailToTempFileMutation();
  const [mutateAttachMailToTicket] = useAttachMailToTicketMutation();

  const apolloClient = useApolloClient();

  const assign = useEventCallback(async (ids: string[]) => {
    const requests = ids.map(async (id) => {
      const item = itemsById[id];

      if (!item) {
        throw new Error(`Item with id ${id} not found`);
      }

      const { contact, project } = item;

      let businessPartnerType: SdObjType | null = null;
      let businessPartnerId: number | null = null;
      let contactId: number | null = null;
      let projectId: number | null = null;

      if (contact != null) {
        if (isContact(contact)) {
          contactId = contact.id;
          businessPartnerType = isCustomer(contact.businessPartner.data)
            ? SdObjType.KUNDE
            : SdObjType.LIEFERANT;
          businessPartnerId = contact.businessPartner.data.id;
        } else if (isCustomer(contact)) {
          businessPartnerType = SdObjType.KUNDE;
          businessPartnerId = contact.id;
        } else if (isSupplier(contact)) {
          businessPartnerType = SdObjType.LIEFERANT;
          businessPartnerId = contact.id;
        }
      }

      if (project != null) {
        projectId = project.id;
      }

      const response = await mutateAssignMail({
        variables: {
          mailboxId: folder?.mailbox,
          mailFolderId: folder?.folder,
          mailId: id,
          businessPartnerType: businessPartnerType,
          businessPartnerId: businessPartnerId,
          contactId: contactId,
          projectId: projectId,
        },
      });

      const assignStatus = response.data.assignMail.assignStatus ?? null;

      return { id, assignStatus, filterStatus };
    });
    await Promise.allSettled(requests);
  });

  const ignore = useEventCallback(async (ids: string[]) => {
    const requests = ids.map(async (id) => {
      const response = await mutateIgnoreMail({
        variables: {
          mailboxId: folder?.mailbox,
          mailFolderId: folder?.folder,
          mailId: id,
        },
      });

      const assignStatus =
        response.data.ignoreMail === true
          ? MailAssignStatus.ITEM_IGNORED
          : null;

      return { id, assignStatus, filterStatus };
    });

    const requestsAll = await Promise.allSettled(requests);

    updateFulfilled({
      requests: requestsAll,
      cache: apolloClient.cache,
      updater: updateAssignStatus,
    });
  });

  const reset = useEventCallback(async (ids: string[]) => {
    const requests = ids.map(async (id) => {
      const response = await mutateUnignoreMail({
        variables: {
          mailboxId: folder?.mailbox,
          mailFolderId: folder?.folder,
          mailId: id,
        },
      });

      const assignStatus = response.data.unignoreMail
        ? MailAssignStatus.NOT_YET_ASSIGNED
        : null;

      return { id, assignStatus, filterStatus };
    });

    const requestsAll = await Promise.allSettled(requests);

    updateFulfilled({
      requests: requestsAll,
      cache: apolloClient.cache,
      updater: updateAssignStatus,
    });
  });

  const assignSelected = useEventCallback(() => {
    return assign(selectedIds);
  });

  const ignoreSelected = useEventCallback(() => {
    return ignore(selectedIds);
  });

  const resetSelected = useEventCallback(() => {
    return reset(selectedIds);
  });

  const deleteSelected = useEventCallback(async () => {
    await Promise.allSettled(
      selectedIds.map((id) => {
        return mutateDeleteMail({
          variables: {
            mailboxId: folder?.mailbox,
            mailFolderId: folder?.folder,
            mailId: id,
          },
        });
      })
    );
  });

  const markRead = useEventCallback(async (ids: string[]) => {
    const requests = ids.map(async (id) => {
      const response = await mutateMarkMailRead({
        variables: {
          mailboxId: folder?.mailbox,
          mailFolderId: folder?.folder,
          mailId: id,
        },
      });

      const isRead = !!response.data.setMailState;

      return { id, isRead };
    });

    const requestsAll = await Promise.allSettled(requests);

    updateFulfilled({
      requests: requestsAll,
      cache: apolloClient.cache,
      updater: updateIsRead,
    });
  });

  const assignToTicket = useEventCallback(
    async (id: string, ticketId: string) => {
      // Convert the mail into a TempFile, attach this TempFile to the ticket
      // and assign the mail to the ticket.

      const item = itemsById[id];

      if (!item) {
        throw new Error(`Item with id ${id} not found`);
      }

      const mailboxId = folder.mailbox;
      const mailFolderId = folder.folder;

      const convertMailToTempFileResponse = await mutateConvertMailToTempFile({
        variables: {
          mailboxId: mailboxId,
          mailFolderId: mailFolderId,
          mailId: id,
        },
      });

      const tempFile = convertMailToTempFileResponse.data.convertMailToTempfile;

      await mutateAttachMailToTicket(
        { id: ticketId },
        {
          relations: {
            attachements: {
              add: [{ tempFileId: tempFile.id, name: tempFile.fileName }],
            },
          },
        }
      );

      await mutateAssignMailToObject({
        variables: {
          mailboxId: mailboxId,
          mailFolderId: mailFolderId,
          mailId: id,
          objectType: ObjectType.TICKET,
          objectKey: ticketId,
        },
      });
    }
  );

  const loading =
    assignMailResult.loading ||
    ignoreMailResult.loading ||
    unignoreMailResult.loading;

  return {
    loading,
    assign,
    assignToTicket,
    ignore,
    reset,
    assignSelected,
    ignoreSelected,
    resetSelected,
    deleteSelected,
    markRead,
  };
}

function updateApolloCache({
  id,
  cache,
  updater,
}: {
  id: string;
  cache: ApolloCache<unknown>;
  updater: Modifiers;
}) {
  cache.modify({
    id: cache.identify({ id, __typename: 'MailboxContent' }),
    fields: updater,
  });
}

// Cache update functions

function updateFulfilled<
  T extends {
    id: string;
    assignStatus?: MailAssignStatus;
    filterStatus?: MailboxContentFilters['filterStatus'];
  }
>({
  requests,
  cache,
  updater,
}: {
  requests: PromiseSettledResult<T>[];
  cache: ApolloCache<unknown>;
  updater: Modifiers | ((change: T) => Modifiers);
}) {
  const fulfilled = requests.filter(
    (request): request is PromiseFulfilledResult<T> =>
      request.status === 'fulfilled'
  );
  const updates = fulfilled.map((request) => request.value);

  for (const update of updates) {
    const updaterParam =
      typeof updater === 'function' ? updater(update) : updater;

    updateApolloCache({
      cache,
      id: update.id,
      updater: updaterParam,
    });
  }
}

function updateAssignStatus(change: {
  id: string;
  assignStatus: MailAssignStatus;
}) {
  return {
    assignStatus(old: MailAssignStatus) {
      return change.assignStatus ?? old;
    },
  };
}

function updateIsRead(update: { id: string; isRead: boolean }) {
  return {
    isRead() {
      return update.isRead;
    },
  };
}
