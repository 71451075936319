import React, { useCallback } from 'react';
import { TableInstance } from 'react-table';

import { useTableStateBag } from '@work4all/components';
import { TravelReceiptsPreviewContainer } from '@work4all/components/lib/components/entity-preview/travel-receipts-preview/TravelReceiptsPreviewContainer';
import { useGetTravelReceiptsStatus } from '@work4all/components/lib/components/entity-preview/travel-receipts-preview/use-get-travel-receipts-status';

import { TravelReceipts } from '@work4all/models/lib/Classes/TravelReceipts.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';

import { useOnOpenMaskContext } from '../../containers/mask-overlays/mask-overlay/components/OpenMaskProvider';

import { AttachmentCell } from './AttachmentCell';
import { TravelStatusCell } from './cell-renderer/TravelStatusCell';
import { IEntityTable } from './entity-table/EntityTable';
import schema from './schemata/travelReceipts-table-schema.json';
import { Table } from './table/Table';
import { TableLayoutContext, useTableLayoutState } from './table-layout';
import { TableNoRowsPlaceholder } from './TableNoRowsPlaceholder';
import { useDataTable } from './use-data-table';
import { useDeleteEntitiesToolbarConfig } from './use-delete-entities-toolbar-config';
import { useTableHandlers } from './use-table-handlers';
import { useUserColumnConfigs } from './use-user-column-configs';

const forceRequestFields: TravelReceipts = {
  travelExpenses: {
    closedByuserid: null,
    insertionREDate: null,
  },
};

const defaultSort = [{ field: 'date', direction: SortDirection.DESCENDING }];
const manualGroupBy = true;

export const TravelReceiptsTable = React.forwardRef<
  TableInstance,
  IEntityTable
>(function TravelReceiptsTable(props, ref) {
  const { prefilter } = props;
  const { entity: entityType } = schema as never;

  const { onOpenMask } = useOnOpenMaskContext();

  const tableStateBag = useTableStateBag();

  const layoutState = useTableLayoutState();
  const [layout] = layoutState;

  const {
    columnConfigs,
    cardConfig,
    prepareRowDisplayModifiers,
    data,
    selectedEntities,
    fetchMore,
    total,
    pending,
    initialSortBy,
  } = useDataTable<TravelReceipts, never>({
    layout,
    schema: schema as never,
    tableStateBag,
    prefilter,
    defaultSort,
    cells: {
      Attachment: AttachmentCell,
      TravelStatusCell,
    },
    forceRequestFields,
  });

  const getTravelReceiptsStatus = useGetTravelReceiptsStatus();

  const rowModifiers = useCallback(
    (value: TravelReceipts) => {
      const modifiers = prepareRowDisplayModifiers(value);
      const { key: status } = getTravelReceiptsStatus(value.travelExpenses);
      return {
        ...modifiers,
        isFaded: status === 'booked',
        isShade1: status === 'closed' || status === 'booked',
      };
    },
    [prepareRowDisplayModifiers, getTravelReceiptsStatus]
  );

  const tableHandlers = useTableHandlers(entityType, onOpenMask);

  const deleteConfig = useDeleteEntitiesToolbarConfig({
    entityType,
  });

  const [userConfig, userConfigMethods] = useUserColumnConfigs({
    layout,
    entityType,
    columnConfigs,
  });

  if (!userConfig) return null;

  return (
    <TableLayoutContext value={layoutState}>
      <Table
        pending={pending}
        noRowsRenderer={() => <TableNoRowsPlaceholder />}
        ref={ref}
        layout={layout}
        actions={{
          add: tableHandlers.create,
          edit: tableHandlers.edit,
          remove: deleteConfig,
          resetColumns: userConfigMethods.remove,
          createWidget: true,
        }}
        areas={{
          right: selectedEntities &&
            selectedEntities.length > 0 && {
              content: (
                <TravelReceiptsPreviewContainer
                  ids={selectedEntities.map((e) => e.id)}
                  onEditClicked={tableHandlers.edit.handler}
                />
              ),
            },
        }}
        columnConfigs={userConfig}
        cardConfig={cardConfig}
        manualGroupBy={manualGroupBy}
        initialSortBy={initialSortBy}
        loadMoreItems={fetchMore}
        prepareRowDisplayModifiers={rowModifiers}
        data={data}
        total={total}
      />
    </TableLayoutContext>
  );
});
