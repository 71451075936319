import styles from './styles.module.scss';

import { MenuItem, MenuList } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import {
  ContextMenu,
  DISMISS_POPOVER,
  IContextMenuProps,
} from '@work4all/components/lib/components/context-menu/ContextMenu';

import { useTenant } from '@work4all/data/lib/hooks/routing/TenantProvider';

import {
  IFavoriteLink,
  useFavoriteLinks,
} from '../../../../containers/more/data/favorite-links';

export type IFavoriteLinkContextMenuProps = {
  link: IFavoriteLink;
  children: IContextMenuProps['children'];
};

export function FavoriteLinkContextMenu(props: IFavoriteLinkContextMenuProps) {
  const { link, children } = props;

  const { t } = useTranslation();

  const { removeFavoriteLink } = useFavoriteLinks();
  const tenant = useTenant();

  const onOpenLink = () => {
    if (link.href.match(/t-.*/)) {
      window.open(link.href);
    } else {
      const withTenant = `/t-${tenant.activeTenant}${link.href}`;
      window.open(withTenant);
    }
  };

  const onRemoveLink = () => {
    removeFavoriteLink(link.id);
  };

  return (
    <ContextMenu
      content={
        <MenuList className={styles.menuList}>
          <MenuItem
            className={clsx(styles.menuItem, DISMISS_POPOVER)}
            onClick={onOpenLink}
          >
            {t('FAV_LINKS.OPEN_IN_A_NEW_TAB')}
          </MenuItem>
          <MenuItem
            className={clsx(styles.menuItem, DISMISS_POPOVER)}
            onClick={onRemoveLink}
          >
            {t('FAV_LINKS.REMOVE')}
          </MenuItem>
        </MenuList>
      }
    >
      {children}
    </ContextMenu>
  );
}
