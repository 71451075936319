import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';

type State = {
  isUserEditingAComment: boolean;
  /**
   * The `id` of the comment being edited.
   */
  openedCommentId: string | undefined;
};

type ContextValue = {
  state: State;
  startEditingComment: (id: string) => void;
  stopEditingComment: () => void;
};

export const EventsViewStateContext = createContext<ContextValue>({
  state: {
    isUserEditingAComment: false,
    openedCommentId: undefined,
  },
  startEditingComment: () => {},
  stopEditingComment: () => {},
});

export const useEventsViewStateContext = () => {
  return useContext(EventsViewStateContext);
};

export const EventsViewStateProvider = ({ children }: PropsWithChildren) => {
  const [openedCommentId, setOpenedCommentId] = useState<string | undefined>();

  const value: ContextValue = useMemo(() => {
    return {
      state: {
        openedCommentId,
        isUserEditingAComment: Boolean(openedCommentId),
      },
      startEditingComment: (id: string) => {
        setOpenedCommentId(id);
      },
      stopEditingComment: () => {
        setOpenedCommentId(undefined);
      },
    };
  }, [openedCommentId]);

  return (
    <EventsViewStateContext.Provider value={value}>
      {children}
    </EventsViewStateContext.Provider>
  );
};
