import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

import { ArticlePickerField } from '../../../../../../../../../../components/entity-picker/ArticlePickerField';
import { ShadowObjectAddPositionArgs } from '../../../../../hooks/use-bz-shadow-object-api';
import { ARTICLE_POSITIONS_DATA } from '../constants';

export interface ArticlePickerCellProps {
  cell: {
    row: {
      index: number;
    };
  };
  isEditMode: boolean;
  onAddPosition?: (props: ShadowObjectAddPositionArgs) => void;
  tableInstanceRef: React.RefObject<TableInstance>;
}

export const ArticlePickerCell = (props: ArticlePickerCellProps) => {
  const { cell, isEditMode, onAddPosition } = props;

  const { t } = useTranslation();
  return (
    <ArticlePickerField
      value={undefined}
      data={ARTICLE_POSITIONS_DATA}
      showFunctions
      autoFocus={isEditMode}
      label={t('ERP.ADD_POSITION')}
      input={
        <Typography variant="body2" color="var(--text03)">
          {t('ERP.ADD_POSITION')}
        </Typography>
      }
      onChange={(value) => {
        if (!value) {
          return;
        }
        if ((value as ErpPositionsKind) in ErpPositionsKind) {
          onAddPosition({
            positionType: value as ErpPositionsKind,
            index: cell.row.index,
          });
        } else {
          const article = value as Article;
          onAddPosition({
            positionType: ErpPositionsKind.STANDARD,
            article: {
              ...article,
              id: article.id || 0,
            } as Article,
            defaultText: article.id ? undefined : article?.name,
            index: cell.row.index,
          });
        }
      }}
    />
  );
};
