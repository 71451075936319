import { useMemo } from 'react';

import { useTenant } from '../hooks/routing/TenantProvider';
import { useUser } from '../hooks/useUser';

export const useAuthHeaders = () => {
  const { activeTenant: tenant } = useTenant();
  const user = useUser();
  const bearerToken = user.token;
  const baseUrl = user.baseUrl;

  return useMemo(
    () => ({
      Authorization: `bearer ${bearerToken}`,
      mandantenCode: tenant.toString(),
      'x-work4all-apiurl': baseUrl,
    }),
    [bearerToken, tenant, baseUrl]
  );
};
