import { DateTime } from 'luxon';

import { UseBirthdaysProps, UseBirthdaysReturn } from '../types';

import { useBirthdaysFormatter } from './use-birthdays-formatter';
import { useBirthdaysQuery } from './use-birthdays-query';

export const useBirthdays = (
  props: UseBirthdaysProps = {}
): UseBirthdaysReturn => {
  const {
    startDate = DateTime.now().toISODate(),
    lookaheadDays = 7,
    skip = false,
  } = props;

  const { data, refetch } = useBirthdaysQuery({
    startDate,
    lookaheadDays,
    skip,
  });

  const formattedData = useBirthdaysFormatter(data);

  return { birthdays: formattedData, refetch };
};
