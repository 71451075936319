import React from 'react';
import { TableInstance } from 'react-table';

import { Task } from '@work4all/models/lib/Classes/Task.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';

import { EntityTable, IEntityTable } from './entity-table/EntityTable';
import { useEntityDataTable } from './entity-table/use-entity-data-table';
import schema from './schemata/task-table-schema.json';
import { TaskPriorityCell } from './TaskPriorityCell';
import { TaskStatusCell } from './TaskStatusCell';

export type ITaskPriorityCellConfig = {
  type: 'TaskPriority';
};

export type ITaskStatusCellConfig = {
  type: 'TaskStatus';
  params: {
    ownerIdField: string;
  };
};

type ITaskTableCustomColumns = {
  TaskStatus?: ITaskStatusCellConfig;
  TaskPriority?: ITaskPriorityCellConfig;
};

const defaultSort = [{ field: 'date', direction: SortDirection.DESCENDING }];

export const TasksTable = React.forwardRef<TableInstance, IEntityTable>(
  function TasksTable(_props, ref) {
    const { entity: entityType } = schema as never;

    const dataTable = useEntityDataTable<Task, ITaskTableCustomColumns>({
      schema: schema as never,
      cells: {
        TaskStatus: TaskStatusCell,
        TaskPriority: TaskPriorityCell,
      },
      cellProcessors: {
        TaskStatus(config) {
          return [config.accessor, 'user.id'];
        },
      },
      defaultSort,
    });

    return <EntityTable ref={ref} entityType={entityType} {...dataTable} />;
  }
);
