import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useRxDB } from 'rxdb-hooks';

import { USER_META_ID } from '../db';

export const createUseUser = <IUser extends Record<string, unknown>>() => {
  const UserContext = React.createContext<IUser | null>(null);

  const UserProvider: React.FC<PropsWithChildren<unknown>> = (props) => {
    const db = useRxDB();
    const dispatch = useDispatch();
    const [user, setUser] = useState<IUser | null>(null);
    const [initing, setIniting] = useState(true);

    useEffect(() => {
      (async () => {
        setIniting(true);
        const userMeta = await db.getLocal<{ user: IUser }>(USER_META_ID);
        if (userMeta) {
          userMeta.$.subscribe((doc) => {
            /**
             * tenants need to be present on the active user
             * we didnt always assume this so this check is for legacy client side data sets
             * */
            if (doc?.data?.user && doc?.data?.user?.mandanten) {
              setUser(doc.data.user);
            } else {
              //logout in this case
              setUser(null);
            }
            setIniting(false);
          });
        }
      })();
    }, [db, dispatch]);

    return (
      <UserContext.Provider value={user}>
        {!initing && props.children}
      </UserContext.Provider>
    );
  };

  const useUser = () => {
    return useContext(UserContext);
  };

  return { useUser, UserProvider };
};
