import { Box, Grid } from '@mui/material';

import { Panel } from '../../../../../components/panel/Panel';
import { Assignment } from '../general/components/assignment/Assignment';
import { Description } from '../general/components/description/Description';
import { ParticipantsAndAddresses } from '../general/components/participants-and-addresses/ParticipantsAndAddresses';

import { Payment } from './components/payment/Payment';
import { PerformancePeriod } from './components/performance-period/PerformancePeriod';

export const MiscellaneousTabPanel = () => {
  return (
    <Panel>
      <Box maxWidth="100rem" margin="auto">
        <Grid container>
          <Grid item xs={12}>
            <Description />
          </Grid>
          <Grid item xs={12}>
            <Assignment />
          </Grid>
        </Grid>

        <ParticipantsAndAddresses displayAsSection />
        <Payment />
        <PerformancePeriod />
      </Box>
    </Panel>
  );
};
