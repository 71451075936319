import { CheckboxRadioItem } from '../../../../locked-inputs';

import { SimpleCustomFieldProps } from './types';

export function SimpleCustomCheckboxField(props: SimpleCustomFieldProps) {
  const { label, ...othersProps } = props;

  return (
    <CheckboxRadioItem
      control="checkbox"
      label={label}
      checked={Boolean(othersProps.value)}
      onChange={(e) => {
        othersProps.onChange(e.target.checked);
      }}
    />
  );
}
