import React from 'react';
import { TableInstance } from 'react-table';

import { ProductionContract } from '@work4all/models/lib/Classes/ProductionContract.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';

import { EntityTable, IEntityTable } from './entity-table/EntityTable';
import { useEntityDataTable } from './entity-table/use-entity-data-table';
import schema from './schemata/productionContract-table-schema.json';

const defaultSort = [
  { field: 'creationDate', direction: SortDirection.DESCENDING },
];

export const ProductionContractsTable = React.forwardRef<
  TableInstance,
  IEntityTable
>(function ProductionContractsTable(_props, ref) {
  const dataTable = useEntityDataTable<ProductionContract, never>({
    schema: schema as never,
    defaultSort,
  });

  return <EntityTable ref={ref} {...dataTable} />;
});
