import { TEXTMARKS_BY_TYPE } from '../constants';
import { Textmark, TextmarkType } from '../types';

type Props = {
  type: TextmarkType;
};

export const useTextmarkData = ({ type }: Props): Textmark[] => {
  const textmarks = TEXTMARKS_BY_TYPE[type];

  if (!textmarks) {
    throw new Error(`Textmarks for type ${type} is not defined`);
  }

  return Object.keys(textmarks).map((key) => textmarks[key]);
};
