import { InboundInvoice } from '@work4all/models/lib/Classes/InboundInvoice.entity';
import { Ra } from '@work4all/models/lib/Classes/Ra.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ConfigsByEntity, DataBySubEntity, KeyBySubEntity } from '../types';

export const TAB_LABEL: { [key in Entities]?: string } = {
  [Entities.rELedgerAccountSplit]: 'COMMON.BOOKINGS',
  [Entities.inboundInvoicePayment]: 'COMMON.PAYMENTS',
  [Entities.raLedgerAccountSplit]: 'COMMON.BOOKINGS',
  [Entities.raPayment]: 'COMMON.PAYMENTS',
};

const INBOUND_INVOICE_BOOKINGS_DATA: InboundInvoice = {
  id: null,
  buchungen: [
    {
      id: null,
      valueNet: null,
      vat: null,
      vatAmount: null,
      proportionDM: null,
      taxKey: null,
      project: {
        id: null,
        name: null,
      },
      contraAccount: null,
      note: null,
      costCenter: {
        id: null,
        name: null,
        number: null,
      },
      konto: {
        id: null,
        name: null,
        number: null,
      },
    },
  ],
};

const INBOUND_INVOICE_PAYMNETS_DATA: InboundInvoice = {
  id: null,
  payments: [
    {
      id: null,
      inboundInvoiceCode: null,
      bookingDate: null,
      datevDate: null,
      amount: null,
      ledgerAccountNumber: null,
      text: null,
      user: {
        id: null,
        displayName: null,
      },
    },
  ],
};

const INBOUND_INVOICE_DATA_BY_SUB_ENTITY: DataBySubEntity = {
  [Entities.rELedgerAccountSplit]: INBOUND_INVOICE_BOOKINGS_DATA,
  [Entities.inboundInvoicePayment]: INBOUND_INVOICE_PAYMNETS_DATA,
};

const INBOUND_INVOICE_KEY_BY_SUB_ENTITY: KeyBySubEntity = {
  [Entities.rELedgerAccountSplit]: 'buchungen',
  [Entities.inboundInvoicePayment]: 'payments',
};

const OUTGOING_INVOICE_BOOKINGS_DATA: Ra = {
  id: null,
  splittings: [
    {
      id: null,
      netto: null,
      mwstSatz: null,
      mwstBetrag: null,
      anteilProzent: null,
      steuerschluessel: null,
      kostenstelleItem: {
        id: null,
        name: null,
      },
      konto: {
        id: null,
        name: null,
        number: null,
      },
      note: null,
    },
  ],
};

const OUTGOING_INVOICE_PAYMNETS_DATA: Ra = {
  id: null,
  payments: [
    {
      id: null,
      note: null,
      ledgerAccount: {
        id: null,
        number: null,
      },
      createdByUser: {
        id: null,
        displayName: null,
      },
    },
  ],
};

const OUTGOING_INVOICE_DATA_BY_SUB_ENTITY: DataBySubEntity = {
  [Entities.raLedgerAccountSplit]: OUTGOING_INVOICE_BOOKINGS_DATA,
  [Entities.raPayment]: OUTGOING_INVOICE_PAYMNETS_DATA,
};

const OUTGOING_INVOICE_KEY_BY_SUB_ENTITY: KeyBySubEntity = {
  [Entities.raLedgerAccountSplit]: 'splittings',
  [Entities.raPayment]: 'payments',
};

export const CONFIGS_BY_ENTITY: ConfigsByEntity = {
  [Entities.inboundInvoice]: {
    dataBySubEntity: INBOUND_INVOICE_DATA_BY_SUB_ENTITY,
    keyBySubEntity: INBOUND_INVOICE_KEY_BY_SUB_ENTITY,
  },

  [Entities.ra]: {
    dataBySubEntity: OUTGOING_INVOICE_DATA_BY_SUB_ENTITY,
    keyBySubEntity: OUTGOING_INVOICE_KEY_BY_SUB_ENTITY,
  },
};
