import React from 'react';
import { useTranslation } from 'react-i18next';

import { IPickerProps } from '@work4all/components/lib/components/entity-picker/types';

import { TaxGroup } from '@work4all/models/lib/Classes/TaxGroup.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { EntityPickerField } from './EntityPickerField';

export const TaxGroupPickerField = React.forwardRef<
  HTMLDivElement,
  Omit<IPickerProps<TaxGroup, false>, 'multiple'>
>((props, ref) => {
  const { t } = useTranslation();
  return (
    <EntityPickerField
      ref={ref}
      label={t('INPUTS.BASE_DATA_TAX')}
      entity={Entities.taxGroup}
      {...props}
    />
  );
});
