import { gql, MutationHookOptions, useMutation } from '@apollo/client';

import { ObjectType } from '@work4all/models/lib/Enums/ObjectType.enum';

const EDIT_COMMENT_MUTATION = gql`
  mutation EditComment(
    $codeOrId: String!
    $objectType: ObjectType!
    $comment: String!
  ) {
    editComment(
      codeOrId: $codeOrId
      objectType: $objectType
      comment: $comment
    ) {
      id
      text
    }
  }
`;

export interface EditCommentMutationResponse {
  editComment: {
    id: string;
    text: string;
  };
}

export interface EditCommentMutationVariables {
  codeOrId: string | number;
  objectType: ObjectType;
  comment: string;
}

export function useEditCommentMutation(
  options?: MutationHookOptions<
    EditCommentMutationResponse,
    EditCommentMutationVariables
  >
) {
  return useMutation<EditCommentMutationResponse, EditCommentMutationVariables>(
    EDIT_COMMENT_MUTATION,
    options
  );
}
