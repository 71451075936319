import { UserIcon } from '@work4all/components/lib/components/user-icon/UserIcon';
import { HookedUserIcon } from '@work4all/components/lib/components/user-icon/useUserIconRegister';

export type IUserIconCellProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  column: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    accessor: (row: any) => any;
    cellParams: {
      locked: boolean;
    };
  };

  row: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    original: any;
  };
  placeholder?: boolean;
};

export const UserIconCell: React.FC = (props: IUserIconCellProps) => {
  const { value, placeholder } = props;
  if (!value && placeholder) return <UserIcon placeholder />;

  return isNaN(value) && typeof value === 'string' ? (
    <UserIcon shortName={value.substring(0, 2)} />
  ) : (
    <HookedUserIcon userId={value} locked={props.column.cellParams?.locked} />
  );
};
