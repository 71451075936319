import { useTranslation } from 'react-i18next';

import { useDialogs } from '@work4all/components';

import { PromptResult } from '@work4all/models/lib/Enums/PromptResult.enum';
import { PromptType } from '@work4all/models/lib/Enums/PromptType.enum';

import { CostCenterPickerField } from '../../../../../../../../../../../components/entity-picker/CostCenterPickerField';
import { ControllerPlus } from '../../../../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../../../../form-plus/use-form-context-plus';
import { ErpData } from '../../../../../../ErpData';
import { useShadowBzObjectApiContext } from '../../../../../../hooks/use-bz-shadow-object-api/use-shadow-bz-object-api-context';

export const ErpCostCenter = () => {
  const { t } = useTranslation();

  const dialogs = useDialogs();
  const { control } = useFormContextPlus<ErpData>();

  const shadowBzObjectApi = useShadowBzObjectApiContext();
  const positions = shadowBzObjectApi.positions;
  const promptResult = shadowBzObjectApi.promptResult;

  return (
    <ControllerPlus
      name="costCenter"
      control={control}
      render={({ field }) => {
        return (
          <CostCenterPickerField
            label={t('COMMON.COST_CENTER')}
            {...field}
            onChange={async (value) => {
              if (positions.length > 0) {
                const hasCostCenter = positions.some((x) => x.costCenter1);
                let result = PromptResult.YES;
                if (hasCostCenter) {
                  const answer = await dialogs.confirm({
                    title: `${t('INPUTS.COST_CENTRE')} ${t(
                      'COMMON.CHANGE'
                    ).toLowerCase()}`,
                    description: t('ERP.COST_CENTER_CHANGE'),
                  });
                  if (!answer) {
                    result = PromptResult.NO;
                  }
                }
                promptResult.current.push({
                  result,
                  type: PromptType.BZ_OBJECT_CHANGE_POSITIONS_COST_CENTER,
                });
              }
              field.onChange(value);
            }}
          />
        );
      }}
    />
  );
};
