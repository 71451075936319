import { useMemo } from 'react';

import { IResponse, useDataProvider } from '@work4all/data';

import { InboundInvoice } from '@work4all/models/lib/Classes/InboundInvoice.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

interface Props {
  id: string | number;
  entity: Entities.inboundInvoice;
}

export const usePaymentData = ({ id, entity }: Props) => {
  const requestData = useMemo<DataRequest>(() => {
    return {
      entity,
      data: {
        id: null,
        note: null,
        date: null,
        dueDate: null,
        supplier: {
          id: null,
          number: null,
          name: null,
          street: null,
          postalCode: null,
          city: null,
        },
        currency: {
          id: null,
          name: null,
        },
        course: null,
        skontoPercent: null,
        skontoDate: null,
        payments: [
          {
            id: null,
            bookingDate: null,
            datevDate: null,
            amount: null,
            ledgerAccount: {
              id: null,
              name: null,
              number: null,
            },
            text: null,
            user: { id: null, displayName: null },
          },
        ],
        grossAmount: null,
        remainingAmount: null,
      } as InboundInvoice,
      filter: [{ id: { $eq: id } }],
    };
  }, [id, entity]);

  const response = useDataProvider(requestData);
  const data = prepareData(response, entity);

  return useMemo(() => {
    return { data, refetch: response.refetch };
  }, [data, response.refetch]);
};

const prepareData = (response: IResponse, entity: Entities) => {
  const data = response.data[0] ?? {};

  switch (entity) {
    case Entities.inboundInvoice:
      return data as InboundInvoice;
  }
};
