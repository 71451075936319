import { Capacitor } from '@capacitor/core';
import Bowser from 'bowser';
import i18next from 'i18next';
import { DateTime } from 'luxon';

export type DebugInfoPart = {
  key: string;
  value: string;
  valueShort?: string;
  canCopy?: boolean;
};

export function getDebugInfoParts(params: {
  customerNumber?: number;
  apiVersion?: string;
  baseUrl?: string;
}): DebugInfoPart[] {
  const version = process.env.APP_VERSION ?? '';
  const sha = process.env.APP_REVISION ?? '';
  const shaShort = sha.slice(0, 7);
  const buildDate = process.env.BUILD_DATE;
  const buildDateRelative = DateTime.fromISO(buildDate).toRelative({
    locale: i18next.language,
  });
  const customerNumber = String(params.customerNumber ?? '');
  const platform = Capacitor.getPlatform();

  const parts: DebugInfoPart[] = [
    { key: 'version', value: version },
    { key: 'revision', value: sha, valueShort: shaShort, canCopy: true },
    { key: 'build_date', value: buildDate, valueShort: buildDateRelative },
    { key: 'customer_number', value: customerNumber },
    { key: 'platform', value: platform },
  ];

  if (params.apiVersion !== undefined) {
    parts.push({ key: 'api_version', value: params.apiVersion, canCopy: true });
  }

  if (params.baseUrl) parts.push({ key: 'api_url', value: params.baseUrl });

  if (platform === 'web') {
    const bowser = Bowser.getParser(window.navigator.userAgent);

    const browser = bowser.getBrowserName();
    const browserVersion = bowser.getBrowserVersion();
    const os = bowser.getOSName();
    const osVersion = bowser.getOSVersion();

    bowser.getPlatformType();

    parts.push(
      { key: 'device_type', value: bowser.getPlatformType() },
      { key: 'browser', value: browser },
      { key: 'browser_version', value: browserVersion },
      { key: 'os', value: os },
      { key: 'os_version', value: osVersion }
    );
  }

  return parts;
}
