import styles from './CheckListPreview.module.scss';

import { Divider } from '@mui/material';
import { MouseEventHandler, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, TableInstance } from 'react-table';

import {
  useDataMutation,
  useInaccessibleFields,
  useUser,
} from '@work4all/data';

import { CheckList } from '@work4all/models/lib/Classes/CheckList.entity';
import { CheckListPosition } from '@work4all/models/lib/Classes/CheckListPosition.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import {
  DateFormatPreset,
  formatDateString,
} from '@work4all/utils/lib/date-utils/formatDateString';

import {
  BasicTable,
  IBasicTableProps,
  TableStateBagProvider,
} from '../../../dataDisplay/basic-table';
import { IConvertPopoverProps } from '../../convert-popover/ConvertPopover';
import { HookedUserIcon } from '../../user-icon/useUserIconRegister';
import { InfoCards, PreviewEditMessage, PreviewWrapper } from '../components';
import {
  ContactPreviewPickerInput,
  CustomerPreviewPickerInput,
  DatePreviewPickerInput,
  PreviewInputsProvider,
  PreviewTitleInput,
  ProjectPreviewPickerInput,
  UserPreviewPickerInput,
} from '../components/preview-input';
import { EntityHistory } from '../EntityHistory';
import { useEntityPreview } from '../hooks/use-entity-preview';

import { ChecklistPositionStatusCell } from './ChecklistPositionStatusCell';
import { checklistPreviewFields } from './ChecklistPreviewContainer';

export type IChecklistPreviewProps = {
  checklists: CheckList[];
  currentUserId: number;
  onCloseClicked?: MouseEventHandler<HTMLButtonElement>;
  onEditClicked?: MouseEventHandler<HTMLButtonElement>;
  onShareClicked?: () => void;
  convertProps?: Pick<IConvertPopoverProps, 'exclude' | 'onClick'>;
};

export function ChecklistPreview(props: IChecklistPreviewProps) {
  const {
    checklists,
    onCloseClicked,
    onEditClicked,
    currentUserId,
    onShareClicked,
    convertProps,
  } = props;
  const { t } = useTranslation();
  const [tableInstance, setTableInstance] = useState<TableInstance>(null);

  const user = useUser();
  const { isInaccessible } = useInaccessibleFields();

  const [newComment, setNewComment] = useState('');

  const titleIsAccessible = !isInaccessible(Entities.checkList, 'title');
  const positionListIsAccessible = !isInaccessible(
    Entities.checkList,
    'positionList'
  );

  const [mutate] = useDataMutation<CheckList, EMode.upsert>({
    entity: Entities.checkList,
    mutationType: EMode.upsert,
    responseData: checklistPreviewFields as unknown as CheckList,
  });

  const {
    loading,
    isMultiselect,
    commonFields,
    activePicker,
    handleLock,
    onPopoverClose,
    onEdit,
    locked,
    lockedByUser,
    canEditAllSelected,
  } = useEntityPreview({
    user,
    subEntityType: Entities.checkList,
    entries: checklists,
    mutate,
  });

  const customerIsCommon = commonFields.includes('businessPartner');

  const prepareRowDisplayModifiers = (value) => {
    return { isBold: (value as CheckListPosition).kind === 'HEADLINE' };
  };

  const checklist = checklists[0];

  const sortedList = useMemo(() => {
    return [...checklist.positionList].sort((a, b) => a.number - b.number);
  }, [checklist.positionList]);

  const columns = useMemo(() => {
    const columns: IBasicTableProps['columns'] = [
      {
        Header: t('COMMON.STATUS'),
        id: 'doneDate1',
        sticky: 'left',
        accessor: 'doneDate',
        Cell: ({ row }: { row: Row<CheckListPosition> }) => {
          if (row.original.kind === 'DEFAULT') {
            return (
              <ChecklistPositionStatusCell
                value={row.original.doneDate}
                row={row}
                currentUserId={currentUserId}
              />
            );
          }
          return '';
        },
        width: 55,
      },
      {
        Header: t('COMMON.POSITION_SHORT'),
        accessor: 'positionNumber',
        width: 55,
        sticky: 'left',
      },
      {
        Header: t('COMMON.CONTENTS'),
        accessor: 'name',
        width: 250,
      },
      {
        Header: t('COMMON.RESPONSIBLE_SHORT'),
        accessor: 'plannedByUserId',
        Cell: ({ row }: { row: Row<CheckListPosition> }) => {
          if (row.original.plannedByUserId === 0) {
            return '';
          }
          return <HookedUserIcon userId={row.original.doneByUserId} size="m" />;
        },
        width: 55,
      },
      {
        Header: t('COMMON.DONE'),
        id: 'doneDate2',
        accessor: 'doneDate',
        Cell: ({ row }: { row: Row<CheckListPosition> }) => {
          if (!row.original.doneDate) {
            return '';
          }

          return (
            <div className={styles['user-icon-wrapper']}>
              <HookedUserIcon userId={row.original.doneByUserId} size="m" />
              {formatDateString(
                row.original.doneDate,
                DateFormatPreset.DATE_SIMPLE_2YEAR
              )}
            </div>
          );
        },
        width: 200,
      },
    ];

    return columns;
  }, [t, currentUserId]);

  return (
    <PreviewInputsProvider
      isLoading={loading}
      activeInputAccessor={activePicker}
      handleLock={handleLock}
      commonInputs={commonFields}
      isMultiselect={isMultiselect}
      entity={Entities.checkList}
      isLocked={locked}
      lockedByUser={lockedByUser}
      numberOfSelectedRows={checklists.length}
      canEditAllSelected={canEditAllSelected}
    >
      <PreviewWrapper>
        <PreviewTitleInput
          onClose={(e) => {
            if (e.target.value !== checklist?.title) {
              onEdit({ title: e.target.value });
            }
            onPopoverClose();
          }}
          onCloseClicked={onCloseClicked}
          onEditClicked={onEditClicked}
          onShareClicked={onShareClicked}
          convertProps={convertProps}
          value={checklist?.title}
          accessor="title"
          disabled={isMultiselect || !titleIsAccessible}
        />

        {isMultiselect && (
          <PreviewEditMessage entityId={String(checklist.id)} />
        )}

        <InfoCards columns={2}>
          <UserPreviewPickerInput
            labelTranslationKey="COMMON.EMPLOYEE"
            onClose={onPopoverClose}
            value={checklist?.user}
            onChange={(value) => {
              onEdit({
                userId: value.id,
              });
            }}
            accessor="user"
          />

          <DatePreviewPickerInput
            labelTranslationKey="COMMON.DATE"
            onClose={onPopoverClose}
            value={checklist?.date}
            onChange={(value) => {
              onEdit({ date: new Date(value).toISOString() });
            }}
            accessor="date"
          />

          <CustomerPreviewPickerInput
            labelTranslationKey="COMMON.CUSTOMER"
            onClose={onPopoverClose}
            value={checklist?.businessPartner?.data}
            onChange={(value) => {
              onEdit({
                businessPartner: value
                  ? {
                      data: value,
                      businessPartnerType: SdObjType.KUNDE,
                      id: value?.id,
                    }
                  : null,
                businessPartnerId: value ? value?.id : 0,
                businessPartnerType: SdObjType.KUNDE,
              });
            }}
            accessor="businessPartner"
            showFooter
          />

          <ContactPreviewPickerInput
            labelTranslationKey="COMMON.CONTACT"
            onClose={onPopoverClose}
            value={checklist?.contact}
            onChange={(value) => {
              onEdit({
                contact: value,
                contactId: value ? value.id : 0,
              });
            }}
            accessor="contact"
            prefilter={[
              {
                businessPartnerType: { $eq: SdObjType.KUNDE },
              },
              {
                businessPartnerId: {
                  $eq: checklist?.businessPartner?.data.id,
                },
              },
            ]}
            disabled={!customerIsCommon || !checklist?.businessPartner?.data}
          />

          <ProjectPreviewPickerInput
            labelTranslationKey="COMMON.PROJECT"
            onClose={onPopoverClose}
            value={checklist?.project}
            onChange={(value) => {
              onEdit({
                project: value,
                projectId: value && !Array.isArray(value) ? value.id : 0,
              });
            }}
            accessor="project"
            showFooter
          />
        </InfoCards>
        {!isMultiselect && positionListIsAccessible ? (
          <div style={{ display: 'grid', height: '100%' }}>
            <TableStateBagProvider tableInstance={tableInstance}>
              <BasicTable
                className={styles.transparentTable}
                ref={setTableInstance}
                mode="client"
                prepareRowDisplayModifiers={prepareRowDisplayModifiers}
                reordableColumns={true}
                resizableColumns={false}
                data={sortedList}
                columns={columns}
                allItemsCount={checklist?.positionList.length || 0}
                cardsView={false}
                selectableMultiple={false}
              />
            </TableStateBagProvider>
          </div>
        ) : null}

        <Divider orientation="horizontal" />

        <EntityHistory
          fitContainer={false}
          entity={Entities.checkList}
          fullWidth
          id={checklist.id}
          className={styles.hist}
          breadcrumbTitle={checklist.title}
          newComment={newComment}
          onNewCommentChange={setNewComment}
        />
      </PreviewWrapper>
    </PreviewInputsProvider>
  );
}
