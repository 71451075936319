import styles from './use-table-borders.module.scss';

import clsx from 'clsx';

import { settings, useSetting } from '../../settings';

export const useTableBorders = () => {
  const tablesLayoutBorders = useSetting(
    settings.tablesLayoutBordersStatistics()
  );

  return {
    cell: clsx({
      [styles.borderVertical]: tablesLayoutBorders.value.vertical,
    }),
    row: clsx({
      [styles.borderHorizontal]: tablesLayoutBorders.value.horizontal,
    }),
  };
};
