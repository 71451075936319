import React from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  EInvoiceFormatPicker,
  IEInvoiceFormatPickerProps,
} from '@work4all/components/lib/components/entity-picker/e-invoice-format-picker/EInvoiceFormatPicker';

import { EInvoiceFormat } from '@work4all/models/lib/Enums/EInvoiceFormat.enum';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

interface Props
  extends Omit<
    IEInvoiceFormatPickerProps<false>,
    'multiple' | 'value' | 'onChange'
  > {
  error?: string;
  value: EInvoiceFormat;
  onChange: (value: EInvoiceFormat) => void;
}

export const EInvoiceFormatPickerField = React.forwardRef<
  HTMLDivElement,
  Props
>((props) => {
  const { t } = useTranslation();
  const { error, ...pickerProps } = props;
  const { value: eInvoiceFormat, onChange } = pickerProps;

  return (
    <EntityPickerPopover
      picker={
        <EInvoiceFormatPicker
          multiple={false}
          value={{
            id: eInvoiceFormat,
            name: t('E_INVOICE_FORMAT.' + eInvoiceFormat),
          }}
          onChange={(value) => {
            onChange(value.id as EInvoiceFormat);
          }}
        />
      }
    >
      <PickerTargetButton
        onClear={() => {
          onChange(null);
        }}
        label={t('INPUTS.E_INVOICE_FORMAT')}
        error={error}
        value={
          eInvoiceFormat
            ? (t('E_INVOICE_FORMAT.' + eInvoiceFormat) as string)
            : ''
        }
      />
    </EntityPickerPopover>
  );
});
