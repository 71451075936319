import styles from './../../MaskOverlay.module.scss';

import _, { partition } from 'lodash';
import { DateTime } from 'luxon';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useLock } from '@work4all/components/lib/hooks/object-lock';
import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack';
import { useHistoryStackTitleUpdate } from '@work4all/components/lib/navigation/hooks/use-history-stack-title-update';

import { useDataMutation, useUser } from '@work4all/data';
import { useCustomFieldsConfig } from '@work4all/data/lib/custom-fields';
import {
  TempFileManagerContext,
  useTempFileManager,
} from '@work4all/data/lib/hooks/data-provider/useTempFileManager';
import { useSearchHistory } from '@work4all/data/lib/hooks/use-search-history';
import { useEntityJsonSchema } from '@work4all/data/lib/json-schema/EntityJsonSchemasContext';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { AppointmentAttendee } from '@work4all/models/lib/Classes/AppointmentAttendee.entity';
import { AppointmentState } from '@work4all/models/lib/Classes/AppointmentState.entity';
import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { InputCrmAnhangAttachementsRelation } from '@work4all/models/lib/Classes/InputCrmAnhangAttachementsRelation.entity';
import { InputTerminRelation } from '@work4all/models/lib/Classes/InputTerminRelation.entity';
import { InputTopicMarkRelation } from '@work4all/models/lib/Classes/InputTopicMarkRelation.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { ProjectProcess } from '@work4all/models/lib/Classes/ProjectProcess.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { TopicSimple } from '@work4all/models/lib/Classes/TopicSimple.entity';
import { ContactKind } from '@work4all/models/lib/Enums/ContactKind.enum';
import { EMailTemplateKind } from '@work4all/models/lib/Enums/EMailTemplateKind.enum';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import {
  fixAppointmentStartEndDates,
  useJSONSchemaResolver,
} from '@work4all/utils';
import {
  canAddAppointment,
  canDeleteAppointment,
  canEditAppointment,
} from '@work4all/utils/lib/permissions';

import { useMsTeamsAvailable } from '../../../../../hooks';
import useAttachementsRelation from '../../../../../hooks/useAttachementsRelation';
import { getEdgeDate } from '../../../../calendar/hooks/use-calendar-hours-config';
import { EmailTemplateButtonProvider } from '../../../components/email-template-button/EmailTemplateButtonProvider';
import { EmailTemplateIconButton } from '../../../components/email-template-button/EmailTemplateIconButton';
import {
  MaskTab,
  MaskTabContext,
  MaskTabPanel,
  MaskTabs,
} from '../../../mask-tabs';
import { INDIVIDUAL_TAB_ID } from '../../components/custom-fields/contants';
import { CUSTOM_FIELDS_DATA } from '../../components/custom-fields/custom-fields-data';
import { IndividualTabPanel } from '../../components/custom-fields/IndividualTabPanel';
import { normalizeCustomFields } from '../../components/custom-fields/normalize-custom-fields';
import { prepareInputWithCustomFields } from '../../components/custom-fields/prepare-input-with-custom-fields';
import { Form } from '../../components/form';
import { MaskContent } from '../../components/MaskContent/MaskContent';
import { MaskOverlayDeleteMenuItem } from '../../components/MaskOverlayDeleteMenuItem';
import { MaskOverlayFullscreenToggleButton } from '../../components/MaskOverlayFullscreenToggleButton';
import { MaskOverlayHeader } from '../../components/MaskOverlayHeader/MaskOverlayHeader';
import { MaskOverlayMenuWrapper } from '../../components/MaskOverlayMenuWrapper';
import { MaskOverlayStandardSubmitButton } from '../../components/MaskOverlayStandardSubmitButton';
import { HistoryTabPanel } from '../../components/tab-panels/history/HistoryTabPanel';
import {
  MaskContextProvider,
  useMaskConfig,
  useMaskContextValue,
} from '../../hooks/mask-context';
import { useAfterSave } from '../../hooks/use-after-save';
import { useConfirmBeforeCloseMask } from '../../hooks/use-confrm-before-close-mask';
import { EntityRightsContext } from '../../hooks/use-entity-rights';
import { useStandardDeleteEntityHandler } from '../../hooks/use-standard-delete-entity-handler';
import { normalizeFormValue } from '../../hooks/useExtendedFormContext';
import { useInitialFormValue } from '../../hooks/useInitialFormValue';
import { MaskControllerProps } from '../../types';
import { pickUpdateFields } from '../../utils/pick-update-fields';
import {
  formatTopicMarkName,
  titleWithTopicMark,
} from '../../utils/titleWithTopicMark';
import { useFormUpdate } from '../../utils/use-form-update';

import { AttachmentsTabPanel } from './components/tab-panels/attachments/AttachmentsTabPanel';
import { createContactUnionWrapper } from './components/tab-panels/general/components/contactpicker/ContactRessourcePicker';
import { mapAttendee } from './components/tab-panels/general/components/participant/utils';
import { GeneralTabPanel } from './components/tab-panels/general/GeneralTabPanel';
import { useAppointmentDefaultData } from './create-appointment-default-data';
import { AppointmentMaskFormValue } from './types';
import { mapAppointmentToEmailParams } from './utils/mapAppointmentToEmailParams';

export const AppointmentOverlayController = (props: MaskControllerProps) => {
  const user = useUser();

  const { t } = useTranslation();

  const mask = useMaskConfig(props);

  const customFields = useCustomFieldsConfig({ entity: Entities.appointment });

  const customRules = useCallback(
    (data) => {
      const atendees: AppointmentAttendee[] =
        data.appointmentAttendeeList || [];
      const hasAtLeastOneUser = atendees.find((el) => el.user || el.ressource);
      if (!hasAtLeastOneUser) {
        return {
          appointmentAttendeeList: {
            message: t('ERROR.MIN_ONE_USER_PER_APPOINTMENT'),
            type: 'customValidation',
          },
        };
      }
      return true;
    },
    [t]
  );

  const requestData = useMemo(() => {
    const filter = [{ id: { $eq: mask.id } }];
    const entity = Entities.appointment;
    const data: Appointment<EMode.query> = {
      id: null,
      userId: null,
      title: null,
      city: null,
      privat: null,
      note: null,
      startDate: null,
      endDate: null,
      fromAbsolute: null,
      toAbsolute: null,
      isWholeDay: null,
      remindDate: null,
      projectId: null,
      remind: null,
      contactId: null,
      exchangeMeeting: null,
      meetingUrl: null,
      contact: {
        displayName: null,
        firstName: null,
        name: null,
        id: null,
        eMail: null,
        businessPartnerId: null,
        businessPartnerType: null,
      },
      businessPartnerId: null,
      businessPartnerType: null,
      businessPartner: {
        id: null,
        data: {
          customer: {
            id: null,
            name: null,
            number: null,
            website: null,
            eMail: null,
          },
          supplier: {
            id: null,
            name: null,
            number: null,
            website: null,
            eMail: null,
          },
        },
      },
      project: { id: null, name: null, number: null },
      projectProcess: { id: null, projectId: null, process: null },
      projectProcessId: null,
      topicMarkList: [
        {
          id: null,
          name: null,
        },
      ],
      deliveryNote: {
        id: null,
        note: null,
        date: null,
        isClosed: null,
        number: null,
      },
      contract: {
        id: null,
        note: null,
        contractNumber: null,
        contractDate: null,
        isClosed: null,
        number: null,
      },
      projectProcessDuration: null,
      appointmentState: { id: null, name: null },
      appointmentAttendeeList: [
        {
          id: null,
          userId: null,
          contactId: null,
          user: {
            id: null,
            displayName: null,
            firstName: null,
            lastName: null,
            eMail: null,
            userKind: null,
            loginName: null,
          },
          ressource: {
            id: null,
            displayName: null,
            firstName: null,
            lastName: null,
            eMail: null,
            userKind: null,
            loginName: null,
          },
          contact: {
            id: null,
            displayName: null,
            eMail: null,
            firstName: null,
            name: null,
            businessPartnerType: null,
            businessPartnerId: null,
            businessPartner: {
              id: null,
              data: {
                customer: {
                  id: null,
                  name: null,
                  website: null,
                },
                supplier: {
                  id: null,
                  name: null,
                  website: null,
                },
              },
            },
          },
          businessPartnerType: null,
          businessPartner: {
            id: null,
            data: {
              customer: {
                id: null,
                name: null,
                eMail: null,
                website: null,
              },
              supplier: {
                id: null,
                name: null,
                eMail: null,
                website: null,
              },
            },
          },
        },
      ],
      attachmentList: [
        {
          id: null,
          fileName: null,
          note: null,
          fileInfos: {
            fileSize: null,
            fileEntityFilename: null,
            previewUrl: null,
            downloadUrl: null,
            previewMimeType: null,
            downloadMimeType: null,
          },
          date: null,
          userId: null,
          user: {
            id: null,
            displayName: null,
          },
        },
      ],
      customFieldList: [CUSTOM_FIELDS_DATA],
    };
    return { filter, entity, data };
  }, [mask.id]);

  // TODO Similar changes must be made to the queries for "parent" or "template"
  // entities. Right now they use "live" queries that will get updated after
  // a mutation.
  //
  // Maybe there is a better way to implement this. What we need is fetch the
  // data once, re-fetch if the query changes, and be able to manually refresh
  // the query after a form submit. But ignore all updates caused by changes
  // to Apollo's cache.
  const initialFormValue = useInitialFormValue<Appointment>(
    requestData,
    mask.isCreateMode
  );

  //get default data if new appointment is being created
  const newEntityData = useAppointmentDefaultData(mask);
  const dataRaw = mask.isCreateMode
    ? newEntityData
    : initialFormValue.value ?? newEntityData;

  const data = useMemo(() => {
    const fixed = dataRaw ? fixAppointmentStartEndDates(dataRaw) : dataRaw;

    return normalizeCustomFields(normalizeFormValue(fixed), customFields);
  }, [dataRaw, customFields]);

  const tempFileManager = useTempFileManager(data?.attachmentList);
  const attachementsRelation =
    useAttachementsRelation<InputCrmAnhangAttachementsRelation>(
      tempFileManager,
      Entities.appointmentAttachment,
      'id'
    );

  const schema = useEntityJsonSchema(mask.entity);
  const resolver = useJSONSchemaResolver(schema, customRules);
  const form = useForm<AppointmentMaskFormValue>({
    resolver,
    mode: 'onChange',
    defaultValues: data,
    shouldFocusError: false,
    context: {
      schema,
    },
  });
  const {
    formState,
    handleSubmit,
    reset,
    getValues,
    watch,
    getFieldState,
    setValue,
  } = form;

  useLock(
    {
      subEntityType: Entities.appointment,
      subEntityIds: data.id ? [data.id.toString()] : [],
    },
    [data]
  );

  useEffect(() => {
    reset(data);
  }, [reset, data]);

  const onAfterSave = useAfterSave(
    props.amplitudeEntryPoint || Entities.appointment
  );

  const getReminderDate = useCallback(() => {
    const { isWholeDay, startDate } = watch();

    const reminder = DateTime.fromISO(startDate);
    let resultingRemindDate = null;
    if (isWholeDay) {
      resultingRemindDate = reminder
        .startOf('day')
        .minus({ day: 1 })
        .set({ hour: 13 })
        .toISO();
    } else {
      resultingRemindDate = reminder.minus({ minutes: 15 }).toISO();
    }
    return resultingRemindDate;
  }, [watch]);

  const { saveSearchItemFromEnityData } = useSearchHistory();
  const [mutate] = useDataMutation<
    Appointment,
    EMode.upsert,
    InputTerminRelation
  >({
    entity: mask.entity,
    mutationType: EMode.upsert,
    responseData: requestData.data as unknown as Appointment<EMode.entity>,
    onCompleted: (data) => {
      if (mask.isCreateMode) {
        saveSearchItemFromEnityData(data);
      }

      props.onAfterSave ? props.onAfterSave(data) : onAfterSave();
    },
  });

  const usedTopicMark = useRef<string>();
  useEffect(() => {
    usedTopicMark.current = data?.topicMarkList?.[0]
      ? formatTopicMarkName(data.topicMarkList[0].name)
      : undefined;
  }, [data]);

  const updateContractAndDeliveryNote = (
    entity: Entities.contract | Entities.deliveryNote
  ) => {
    const contract = getValues('contract');
    const deliveryNote = getValues('deliveryNote');
    const isContract = entity === Entities.contract;

    const values = {
      clearDeliveryNote: {
        deliveryNote: null,
        deliveryNoteId: 0,
      },
      setDeliveryNote: {
        deliveryNoteId: deliveryNote?.id ?? 0,
      },
      clearContract: {
        contract: null,
        contractId: 0,
      },
      setContract: {
        contractId: contract?.id ?? 0,
      },
    };

    let result = {};
    if (contract && deliveryNote) {
      result = isContract
        ? { ...values.setContract, ...values.clearDeliveryNote }
        : { ...values.setDeliveryNote, ...values.clearContract };
    } else {
      result = isContract ? values.setContract : values.setDeliveryNote;
    }

    return result;
  };

  const startDate = watch('startDate');
  const endDate = watch('endDate');
  const remindDate = watch('remindDate');

  const [localEndDate, setLocalEndDate] = useState(endDate);
  const [localRemindDate, setLocalRemindDate] = useState(remindDate);
  const [localStartDate, setLocalStartDate] = useState(startDate);

  const updateEndDate = (start: DateTime, end: DateTime) => {
    let newEnd = end;
    if (start > end) {
      newEnd = end.set({
        day: start.day,
        month: start.month,
        year: start.year,
      });
    }
    if (start > newEnd) {
      newEnd = start;
    }
    return newEnd.toISO();
  };

  const updateStartDate = (start: DateTime, end: DateTime) => {
    let newStart = start;
    if (end < start) {
      newStart = end.set({
        day: end.day,
        month: end.month,
        year: end.year,
      });
    }
    if (end < newStart) {
      newStart = end;
    }
    return newStart.toISO();
  };

  const updateRemindDate = (remind: boolean) => {
    if (remind) {
      const newRemindDate = getReminderDate();
      setValue('remindDate', newRemindDate, { shouldDirty: true });
      setLocalRemindDate(newRemindDate);
      return newRemindDate;
    }
  };

  useFormUpdate(
    {
      'businessPartner.data': (businessPartner: Customer | Supplier) => {
        const businessPartnerId = businessPartner?.id ?? 0;
        const businessPartnerType = getBusinessPartnerType(businessPartner);
        const contact = businessPartner?.mainContact ?? getValues('contact');

        return { businessPartnerId, businessPartnerType, contact };
      },
      contact: (contact: Contact) => {
        const contactId = contact?.id ?? 0;

        const currentAttendees: AppointmentAttendee[] = form.getValues(
          'appointmentAttendeeList'
        );
        const currentBuisnesspartner = form.getValues('businessPartner');
        const businessPartnerType = getBusinessPartnerType(
          currentBuisnesspartner?.data
        );

        if (mask.isCreateMode && contact) {
          //check if a contact of the businesspartner is already part of the appointment

          const hasAttendeeOfBusinessPartner = currentAttendees.find(
            (at) =>
              at.businessPartnerType === businessPartnerType &&
              at.businessPartnerId === currentBuisnesspartner?.data.id &&
              at.contactId === contactId
          );

          if (!hasAttendeeOfBusinessPartner) {
            const t = createContactUnionWrapper(
              {
                ...contact,
                businessPartnerId: currentBuisnesspartner?.data?.id,
                businessPartnerType: businessPartnerType,
                businessPartner: currentBuisnesspartner,
              },
              businessPartnerType === SdObjType.KUNDE
                ? ContactKind.KUNDENANSPRECHPARTNER
                : ContactKind.LIEFERANTENANSPRECHPARTNER
            );
            const appointmentAttendeeList = [
              ...currentAttendees,
              mapAttendee(t),
            ];
            return {
              contactId,
              appointmentAttendeeList,
            };
          }
        }

        return { contactId };
      },
      appointmentAttendeeList: (
        appointmentAttendeeList: AppointmentAttendee[]
      ) => {
        return {
          exchangeMeeting:
            msTeamsAvailable &&
            appointmentAttendeeList.length > 1 &&
            getValues('exchangeMeeting'),
        };
      },
      project: (project: Project) => {
        const projectId = project?.id ?? 0;
        const projectProcessField = getValues('projectProcess');
        const projectProcess =
          project?.id === projectProcessField?.projectId
            ? projectProcessField
            : null;
        const businessPartnerField = getValues('businessPartner.data');

        if (businessPartnerField === undefined) {
          const businessPartner =
            project?.customer ?? project?.supplier ?? null;

          return {
            projectId,
            projectProcess,
            'businessPartner.data': businessPartner,
          };
        } else {
          return { projectId, projectProcess };
        }
      },
      projectProcess: (process: ProjectProcess) => {
        const projectProcessId = process?.id ?? 0;
        return { projectProcessId };
      },
      appointmentState: (state: AppointmentState) => {
        return { colorId: state?.id || 0 };
      },
      isWholeDay: () => {
        const remind = watch('remind');
        if (remind) return { remindDate: getReminderDate() };
      },
      remind: (remind) => {
        if (remind) {
          const newRemindDate = updateRemindDate(remind);
          return { remindDate: newRemindDate };
        }
      },
      startDate: (startDate: string) => {
        const endDate = watch('endDate');
        const remind = watch('remind');

        const start = DateTime.fromISO(startDate);
        const end = DateTime.fromISO(endDate);

        const updatedEndDate = updateEndDate(start, end);

        setValue('endDate', updatedEndDate, { shouldDirty: true });
        setLocalEndDate(updatedEndDate);

        if (remind) {
          const newRemindDate = updateRemindDate(remind);
          return { endDate: updatedEndDate, remindDate: newRemindDate };
        }

        return { endDate: updatedEndDate };
      },
      endDate: (endDate: string) => {
        const startDate = watch('startDate');
        const remind = watch('remind');

        const start = DateTime.fromISO(startDate);
        const end = DateTime.fromISO(endDate);

        const updatedStartDate = updateStartDate(start, end);

        setValue('startDate', updatedStartDate, { shouldDirty: true });
        setLocalStartDate(updatedStartDate);

        if (remind) {
          const newRemindDate = updateRemindDate(remind);
          return { startDate: updatedStartDate, remindDate: newRemindDate };
        }

        return { startDate: updatedStartDate };
      },
      topicMarkList: (topicMarkList: TopicSimple[]) => {
        const { title, topicMark } = titleWithTopicMark(
          getValues('title'),
          topicMarkList,
          usedTopicMark.current,
          !getFieldState('topicMarkList').isDirty
        );

        usedTopicMark.current = topicMark;

        if (title) return { title };
      },
      contract: () => {
        return updateContractAndDeliveryNote(Entities.contract);
      },
      deliveryNote: () => {
        return updateContractAndDeliveryNote(Entities.deliveryNote);
      },
    },
    form
  );

  const { dirtyFields } = formState;

  const isDirty =
    Object.keys(dirtyFields).length > 0 || attachementsRelation?.isDirty;
  useConfirmBeforeCloseMask(isDirty);

  const handleDeleteEntitiesClick = useStandardDeleteEntityHandler(mask);

  const onSubmit = useCallback(
    async (
      data: AppointmentMaskFormValue,
      _event: unknown,
      skipOnComplete = false
    ) => {
      const appointment = { ...data };

      if (!appointment.remindDate) delete appointment.remindDate;

      const current = appointment.appointmentAttendeeList;
      const previous = initialFormValue.value?.appointmentAttendeeList || [];

      const prefilledKey = 'preset';
      const currentIds = new Set(previous.map(({ id }) => id || prefilledKey));
      //if current ids have been undefined than we started with a prefilled form of elements to add
      //this obviousl should only be considered as added not as removed
      currentIds.delete(prefilledKey);

      // When a new attendee is added using a picker, the `id` is null.
      const [remaining, added] = partition(current, ({ id }) => id != null);

      remaining.forEach(({ id }) => {
        currentIds.delete(id);
      });

      const removed = [...currentIds];

      const appointmentAttendeeList =
        removed.length > 0 || added.length > 0
          ? { remove: removed, add: added }
          : null;

      const userIsAttendee = current.find(
        (attendee) => attendee.userId === user.benutzerCode
      );

      const topic: InputTopicMarkRelation = {
        set: appointment?.topicMarkList?.[0]?.id || 0,
      };
      // TODO The type for `relations` argument is wrong. The
      // `appointmentAttendeeList` property is not translated correctly
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const relations: any = {
        attachements: attachementsRelation?.attachements,
      };

      if (appointmentAttendeeList) {
        relations.appointmentAttendeeList = appointmentAttendeeList;
      }
      if (topic) {
        relations.topic = topic;
      }

      const updateRaw = mask.isCreateMode
        ? { ...appointment, userId: userIsAttendee ? userIsAttendee.userId : 0 }
        : pickUpdateFields(appointment, formState.dirtyFields);

      let updateMapped = prepareInputWithCustomFields(updateRaw);

      if (appointment.isWholeDay) {
        updateMapped = {
          ...updateMapped,
          endDate: getEdgeDate('end', 'day', appointment.endDate).toISO(),
        };
      }
      return await mutate(updateMapped, relations ? { relations } : undefined, {
        skipOnComplete: skipOnComplete === true,
      });
    },
    [
      attachementsRelation,
      formState.dirtyFields,
      initialFormValue.value?.appointmentAttendeeList,
      mask.isCreateMode,
      mutate,
      user.benutzerCode,
    ]
  );

  const entityRights = useMemo(
    () => ({
      create: canAddAppointment(user),
      read: false,
      update: canEditAppointment(user, data),
      delete: canDeleteAppointment(user, data),
    }),
    [data, user]
  );

  const maskContext = useMaskContextValue({ ...mask, data, customFields });

  const shouldRenderIndividualTab = customFields && customFields.length > 0;

  const msTeamsAvailable = useMsTeamsAvailable();

  const historyStack = useHistoryStack();
  const { setObjectionListener } = historyStack;

  const updateMaskState = useHistoryStackTitleUpdate(mask.isCreateMode);
  const getEmailParams = useCallback(async () => {
    let inputData = getValues();
    if (isDirty) {
      inputData = (await onSubmit(inputData, null, true)) as Appointment;
      if (!inputData?.id) return;
      setObjectionListener(null);
      updateMaskState(inputData.id, inputData.title);
    }
    const entityTemplate = {
      entity: Entities.appointment,
      id: inputData.id,
    };

    return {
      entityTemplate,
      params: mapAppointmentToEmailParams(inputData, {
        hourKey: t('COMMON.CLOCK'),
        wholeDay: t('COMMON.WHOLEDAYEVENT'),
      }),
    };
  }, [getValues, isDirty, onSubmit, setObjectionListener, t, updateMaskState]);

  return (
    <EmailTemplateButtonProvider
      kind={[
        EMailTemplateKind.APPOINTMENT_NOTIFICATION,
        EMailTemplateKind.KEINE,
      ]}
      mainKind={EMailTemplateKind.APPOINTMENT_NOTIFICATION}
      noTemplate
      getEmailParams={getEmailParams}
    >
      <MaskContextProvider value={maskContext}>
        <EntityRightsContext.Provider value={entityRights}>
          <TempFileManagerContext.Provider value={tempFileManager}>
            <FormProvider {...form}>
              <MaskTabContext defaultValue={mask.params?.tab ?? 'general'}>
                <Form
                  className={styles.maskForm}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <MaskOverlayHeader
                    title={t(`COMMON.${mask.entity.toUpperCase()}`)}
                    subTitle={data?.title}
                    actions={
                      <>
                        <EmailTemplateIconButton />
                        <MaskOverlayStandardSubmitButton />
                        <MaskOverlayFullscreenToggleButton />
                        {mask.isEditMode && (
                          <MaskOverlayMenuWrapper>
                            <MaskOverlayDeleteMenuItem
                              disabled={mask.wip || !entityRights.delete}
                              onClick={handleDeleteEntitiesClick}
                            />
                          </MaskOverlayMenuWrapper>
                        )}
                      </>
                    }
                    tabs={
                      <AppointmentTabs
                        isCreateMode={mask.isCreateMode}
                        renderIndividualTab={shouldRenderIndividualTab}
                      />
                    }
                  />
                  <Content renderIndividualTab={shouldRenderIndividualTab} />
                </Form>
              </MaskTabContext>
            </FormProvider>
          </TempFileManagerContext.Provider>
        </EntityRightsContext.Provider>
      </MaskContextProvider>
    </EmailTemplateButtonProvider>
  );
};

const AppointmentTabs = memo(function AppointmentTabs({
  isCreateMode,
  renderIndividualTab,
}: {
  isCreateMode: boolean;
  renderIndividualTab: boolean;
}) {
  const { t } = useTranslation();

  return (
    <MaskTabs>
      <MaskTab value="general" label={t('MASK.GENERAL')}></MaskTab>
      <MaskTab value="attachments" label={t('MASK.ATTACHMENTS')}></MaskTab>
      <MaskTab
        value="history"
        label={t('MASK.HISTORY')}
        disabled={isCreateMode}
      ></MaskTab>
      {renderIndividualTab && (
        <MaskTab value={INDIVIDUAL_TAB_ID} label={t('MASK.INDIVIDUAL')} />
      )}
    </MaskTabs>
  );
});

const Content = memo(function AppointmentTabPanels({
  renderIndividualTab,
}: {
  renderIndividualTab: boolean;
}) {
  return (
    <MaskContent>
      <MaskTabPanel value="general">
        <GeneralTabPanel />
      </MaskTabPanel>

      <MaskTabPanel value="attachments">
        <AttachmentsTabPanel />
      </MaskTabPanel>

      <MaskTabPanel value="history">
        <HistoryTabPanel />
      </MaskTabPanel>

      {renderIndividualTab && (
        <MaskTabPanel value={INDIVIDUAL_TAB_ID}>
          <IndividualTabPanel />
        </MaskTabPanel>
      )}
    </MaskContent>
  );
});

export function sortAttendees(attendees: AppointmentAttendee<EMode.entity>[]) {
  /**
   * group them by businesspartner
   * then sort them by name
   * */
  const userGroupKey = 'work4all2.0_users';
  const businesspartnerGroups = _.groupBy(attendees, (attendee) => {
    return attendee.user
      ? userGroupKey
      : attendee.contact?.businessPartner?.data.name ||
          attendee.businessPartner?.data.name;
  });

  let result: AppointmentAttendee<EMode.entity>[] = [];
  Object.keys(businesspartnerGroups)
    .sort((a, b) => {
      if (a === userGroupKey) return -1;
      if (b === userGroupKey) return 1;
      return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
    })
    .forEach((groupKey) => {
      const sortedResult = [...businesspartnerGroups[groupKey]];
      sortedResult.sort((a, b) => {
        const aString: string =
          a.user?.lastName + a.user?.firstName ||
          a.contact?.businessPartner.data.name + a.contact?.name ||
          a.businessPartner?.data.name ||
          '';
        const bString: string =
          b.user?.lastName + b.user?.firstName ||
          b.contact?.businessPartner.data.name + b.contact?.name ||
          b.businessPartner?.data.name ||
          '';
        return aString.toLowerCase() < bString.toLowerCase() ? -1 : 1;
      });
      result = result.concat(sortedResult);
    });
  return result;
}

function getBusinessPartnerType(
  businessPartner: Customer | Supplier
): SdObjType {
  if (businessPartner) {
    const typename: string = (businessPartner as { __typename: string })
      .__typename;

    if (typename === 'Kunde') {
      return SdObjType.KUNDE;
    }
  }

  return SdObjType.LIEFERANT;
}
