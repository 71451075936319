import { useEventCallback } from '@mui/material/utils';
import { useCallback, useLayoutEffect, useMemo, useState } from 'react';

import { RowMeasurerContext } from './context';

interface RowMeasurerCacheProps {
  estimatedRowHeight?: number;
  onResize?: () => void;
  children: RowMeasurerCacheRender;
}

type RowMeasurerCacheRenderProps = {
  getRowHeight: (index: number) => number;
};
type RowMeasurerCacheRender = React.FC<RowMeasurerCacheRenderProps>;

export function RowMeasurerCache(props: RowMeasurerCacheProps) {
  const { onResize, estimatedRowHeight, children } = props;

  const [state, setState] = useState<Record<string, number>>({});

  const getRowHeight = useCallback(
    (index: number) => {
      const height = state[index] ?? estimatedRowHeight;
      return height;
    },
    [state, estimatedRowHeight]
  );

  const measure = useEventCallback(({ index, height }) => {
    setState((oldState) => {
      if (oldState[index] === height) {
        return oldState;
      }

      return { ...oldState, [index]: height };
    });
  });

  useLayoutEffect(() => {
    onResize?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const context = useMemo(
    () => ({ measure, getRowHeight }),
    [measure, getRowHeight]
  );

  return (
    <RowMeasurerContext.Provider value={context}>
      {children({ getRowHeight })}
    </RowMeasurerContext.Provider>
  );
}
