import styles from './ClosingText.module.scss';

import React, { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { LockContext } from '@work4all/components/lib/hooks';
import { TextEditor } from '@work4all/components/lib/input/format-text/TextEditor';
import { LabeledInput } from '@work4all/components/lib/input/labeled-input';

import { remToPx } from '@work4all/data/lib/hooks/useRemToPx';

import { settings, useSetting } from '../../../../../../../../../../settings';

export const ClosingText: React.FC = () => {
  const { t } = useTranslation();

  const { control } = useFormContext();

  const rtfTextsInERP = useSetting(settings.rtfTextsInERP());

  const { locked } = useContext(LockContext);

  return (
    <Collapse
      defaultOpen
      title={t('MASK.CLOSING_TEXT')}
      className={styles.root}
    >
      <Controller
        control={control}
        name="rtfFooterText"
        render={({ field }) => {
          const { value, onChange } = field;
          return rtfTextsInERP.value === true ? (
            <TextEditor
              key="rtfFooterText"
              value={value}
              onChange={onChange}
              rtf
              enableTextBuildingBlocks
              disabled={locked}
              heightMax={remToPx(15)}
            />
          ) : (
            <LabeledInput multiline {...field} />
          );
        }}
      />
    </Collapse>
  );
};
