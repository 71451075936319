import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ProjectPicker } from '../../../../../entity-picker/project-picker/ProjectPicker';
import { withExtras } from '../../utils';

import { PreviewPickerInput } from './PreviewPickerInput';

export const ProjectPreviewPickerInput = withExtras<Project>(
  ({ value, onChange, ...rest }) => {
    return (
      <PreviewPickerInput<Project>
        {...rest}
        value={value}
        formattedValue={value?.name}
        picker={
          <ProjectPicker value={value} onChange={onChange} multiple={false} />
        }
        onChange={onChange}
        entity={Entities.project}
      />
    );
  }
);
