import React from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  SalesOpportunitiesKindLookUp,
  SalesOpportunitiesKindPicker,
  SalesOpportunitiesKindPickerProps,
} from '@work4all/components/lib/components/entity-picker/sales-opportunities-kind-picker/SalesOpportunitiesKindPicker';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

interface Props
  extends Omit<SalesOpportunitiesKindPickerProps<false>, 'multiple' | 'value'> {
  error?: string;
  value: number;
}

export const SalesOpportunitiesKindPickerField = React.forwardRef<
  HTMLDivElement,
  Props
>((props, ref) => {
  const { error, ...pickerProps } = props;
  const { value, onChange } = pickerProps;

  const { t } = useTranslation();

  const id = value ?? 0;
  const name = t(SalesOpportunitiesKindLookUp[id]);

  return (
    <EntityPickerPopover
      picker={
        <SalesOpportunitiesKindPicker
          {...pickerProps}
          multiple={false}
          value={{
            id,
            name,
          }}
        />
      }
    >
      <PickerTargetButton
        ref={ref}
        onClear={() => {
          onChange(null);
        }}
        label={t('INPUTS.TICKET_KIND')}
        error={error}
        value={name || ''}
      />
    </EntityPickerPopover>
  );
});
