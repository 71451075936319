import { useMemo } from 'react';

import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { MaskContextProvider } from '../../../../../../containers/mask-overlays/mask-overlay/hooks/mask-context';
import { LayoutSectionProps } from '../../../../../../containers/mask-overlays/mask-overlay/views/settings/table-settings/components/layout-section/LayoutSection';
import { TableSettings } from '../../../../../../containers/mask-overlays/mask-overlay/views/settings/table-settings/TableSettings';

interface TableSettingsOverlayProps extends LayoutSectionProps {
  entityType: Entities;
  isOpen: boolean;
  close: () => void;
}

export const TableSettingsOverlay = (props: TableSettingsOverlayProps) => {
  const { isOpen, close, entityType, settingsKey } = props;
  const tableSettingsView = useMemo(() => {
    return {
      view: (
        <TableSettings
          amplitudeEntryPoint={entityType}
          settingsKey={settingsKey}
        />
      ),
    };
  }, [entityType, settingsKey]);

  if (!isOpen) return null;

  return (
    <MaskContextProvider
      value={{
        entity: entityType,
        isCreateMode: false,
        isEditMode: false,
        mode: 'edit',
        params: null,
        wip: false,
      }}
    >
      <NavigationOverlay
        open={isOpen}
        initialView={tableSettingsView}
        close={close}
      />
    </MaskContextProvider>
  );
};
