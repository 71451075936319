import { User } from '@work4all/models/lib/Classes/User.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { TaskStatus } from '@work4all/models/lib/Enums/TaskStatus.enum';
import { TicketStatus } from '@work4all/models/lib/Enums/TicketStatus.enum';

import { TaskStatusIndicator } from '../../../../../task-status-indicator';
import { TicketStatusIndicator } from '../../../../../ticket-status-indicator';
import { HookedUserIcon } from '../../../../../user-icon/useUserIconRegister';
import { OpenFileIconButton } from '../../../open-file-icon-button/OpenFileIconButton';
import { BeforeContentElementProps } from '../../types';

export const BeforeContentElement = ({
  previewEntity,
  inputEntity,
  value,
  accessor,
}: BeforeContentElementProps) => {
  if (
    inputEntity === Entities.customer ||
    inputEntity === Entities.supplier ||
    inputEntity === Entities.project
  ) {
    return <OpenFileIconButton data={value} />;
  }

  if (inputEntity === Entities.user) {
    const userId = Number((value as User)?.id);
    if (!userId) {
      return null;
    }

    return <HookedUserIcon userId={userId} size="l" />;
  }

  if (previewEntity === Entities.task && accessor === 'status') {
    return <TaskStatusIndicator taskStatus={value as TaskStatus} />;
  }

  if (previewEntity === Entities.ticket && accessor === 'status1') {
    return <TicketStatusIndicator ticketStatus={value as TicketStatus} />;
  }

  return null;
};
