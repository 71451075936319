import { createContext, useContext, useEffect } from 'react';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useDeepMemo } from '@work4all/utils/lib/hooks/use-deep-memo';

export interface PdfEditPopoverState {
  entityType: Entities;
  docId?: string | number;
  entityId: string | number;
  onClose?: () => void;
}

export interface PdfOverlayMaskProviderState {
  open: () => void;
  close: () => void;
  component: JSX.Element;
  setPdfState: (value: PdfEditPopoverState) => void;
}

export const PdfOverlayContext =
  createContext<PdfOverlayMaskProviderState | null>(null);

export const usePdfOverlay = (props: PdfEditPopoverState) => {
  const deepProps = useDeepMemo(() => props, [props]);
  const context = useContext(PdfOverlayContext);

  const setPdfState = context.setPdfState;
  useEffect(() => {
    if (setPdfState && deepProps) setPdfState(deepProps);
  }, [deepProps, setPdfState]);

  return context;
};
