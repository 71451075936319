import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { useDialogs, useTableStateBag } from '@work4all/components';
import { EmailTemplatesPopover } from '@work4all/components/lib/components/email-templates-popover/EmailTemplatesPopover';
import { TICKETACTIONS } from '@work4all/components/lib/components/entity-preview/ticket-preview/TicketPreview';
import { TicketPreviewContainer } from '@work4all/components/lib/components/entity-preview/ticket-preview/TicketPreviewContainer';
import { useEmailTemplates } from '@work4all/components/lib/hooks';
import { IStackItem } from '@work4all/components/lib/navigation/history-stack';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';

import { EMailTemplate } from '@work4all/models/lib/Classes/EMailTemplate.entity';
import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { EMailTemplateKind } from '@work4all/models/lib/Enums/EMailTemplateKind.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { TicketStatus } from '@work4all/models/lib/Enums/TicketStatus.enum';

import { useOnOpenMaskContext } from '../../containers/mask-overlays/mask-overlay/components/OpenMaskProvider';
import { EmailOverlayController } from '../../containers/mask-overlays/mask-overlay/views/email/EmailOverlayController';
import { ContactsPopover } from '../../containers/mask-overlays/mask-overlay/views/ticket/components/contacts-popover/ContactsPopover';
import { TicketTabKeys } from '../../containers/mask-overlays/mask-overlay/views/ticket/TicketOverlayController';
import { useTicketContactsPopoverData } from '../../hooks/useTicketContacts';
import { settings, useSetting } from '../../settings';

import { EntityTable, IEntityTable } from './entity-table/EntityTable';
import { useEntityDataTable } from './entity-table/use-entity-data-table';
import schema from './schemata/ticket-table-schema.json';
import { TicketPriorityCell } from './TicketPriorityCell';
import { TicketsTableQuickFilters } from './tickets-table-components/tickets-table-filters/TicketsTableQuickFilters';
import { TicketStatusCell } from './TicketStatusCell';
import { useTableHandlers } from './use-table-handlers';

const defaultSort = [
  { field: 'creationDate', direction: SortDirection.DESCENDING },
];

export const TicketsTable = React.forwardRef<TableInstance, IEntityTable>(
  function TicketsTable(props, ref) {
    const dialogs = useDialogs();

    const { entity: entityType } = schema as never;

    const { prepareRowDisplayModifiers, ...dataTable } = useEntityDataTable<
      Ticket,
      never
    >({
      schema: schema as never,
      cells: {
        TicketStatus: TicketStatusCell,
        TicketPriority: TicketPriorityCell,
      },
      defaultSort,
    });

    const quickFilters = useMemo(() => <TicketsTableQuickFilters />, []);
    const tableStateBag = useTableStateBag();

    const templates = useEmailTemplates(EMailTemplateKind.TICKET);
    const emailTemplatesRef = useRef();
    const contactsPopoverRef = useRef();

    const [isEmailTemplatesPopoverOpen, setIsEmailTemplatesPopoverOpen] =
      useState(false);

    const [isContactsPopoverOpend, setIsContactsPopoverOpend] = useState(false);

    const contactsPopoverData = useTicketContactsPopoverData(
      dataTable.selectedEntities?.[0]?.id
    );
    const defaultTicketSenderAddress = useSetting(
      settings.defaultTicketSenderAddress()
    );

    const openEmailMask = useCallback(
      (template: EMailTemplate | null) => {
        const ticket: Ticket =
          tableStateBag.tableInstance?.selectedFlatRows?.[0]?.original;

        const businessPartner = ticket.businessPartner?.data;
        const contact = ticket.contact;

        const entityTemplate = businessPartner
          ? {
              entity: contact ? Entities.contact : Entities.customer,
              id: contact
                ? `${contact.id}:customer:${businessPartner.id}`
                : businessPartner.id,
            }
          : undefined;

        const initialView: IStackItem = {
          view: (
            <EmailOverlayController
              entity={Entities.eMail}
              id={null}
              template={entityTemplate}
              onAfterSave={closeMaskOverlay}
              params={{
                basedon: 'EmailTemplate',
                emailTemplate: template ? JSON.stringify(template) : undefined,
                ticketTemplateContext: JSON.stringify(ticket),
                senderAddress: defaultTicketSenderAddress.value,
              }}
            />
          ),
        };

        const dialog = dialogs.open(NavigationOverlay, {
          initialView: initialView,
          close: closeMaskOverlay,
        });

        function closeMaskOverlay() {
          dialogs.close(dialog.id);
        }
      },
      [
        dialogs,
        defaultTicketSenderAddress.value,
        tableStateBag.tableInstance?.selectedFlatRows,
      ]
    );

    const rowModifiers = useCallback(
      (value) => {
        const modifiers = prepareRowDisplayModifiers(value);
        return {
          ...modifiers,
          isFaded: value.status1 === TicketStatus.ERLEDIGT,
        };
      },
      [prepareRowDisplayModifiers]
    );

    const { t } = useTranslation();
    const { onOpenMask } = useOnOpenMaskContext();
    const tableHandlers = useTableHandlers(entityType, onOpenMask);

    const { value: standardTemplates, set: setStandardTemplate } = useSetting(
      settings.standardEmailTemplates()
    );

    return (
      <>
        {isEmailTemplatesPopoverOpen && (
          <EmailTemplatesPopover
            mainTemplates={templates}
            templates={[]}
            anchorEl={emailTemplatesRef.current}
            onClose={() => setIsEmailTemplatesPopoverOpen(false)}
            onTemplateClick={(template) => {
              setIsEmailTemplatesPopoverOpen(false);
              openEmailMask(template);
            }}
            onStandardSelect={(template: EMailTemplate) => {
              setStandardTemplate({
                ...standardTemplates,
                [template.eMailTemplateKind]:
                  standardTemplates[template.eMailTemplateKind] !== template.id
                    ? template.id
                    : null,
              });
            }}
          />
        )}

        <ContactsPopover
          popoverConfig={{
            open: isContactsPopoverOpend,
            anchorEl: contactsPopoverRef.current,
            onClose: () => setIsContactsPopoverOpend(false),
          }}
          {...contactsPopoverData}
        />

        <EntityTable
          ref={ref}
          {...dataTable}
          areas={{
            left: {
              content: quickFilters,
              resizable: true,
              collapseConfig: {
                title: t('COMMON.FILTER'),
              },
            },
            // TODO: there is a ticket for refactor those actions on preview and skip this right element
            right: dataTable.selectedEntities &&
              dataTable.selectedEntities.length > 0 && {
                content: (
                  <TicketPreviewContainer
                    onCloseClicked={() =>
                      tableStateBag.tableInstance.toggleAllRowsSelected(false)
                    }
                    onEditClicked={tableHandlers.edit.handler}
                    onShareClicked={tableHandlers.share}
                    onActionElClicked={(e, action) => {
                      if (
                        action === TICKETACTIONS.addAttachments &&
                        dataTable.selectedEntities.length === 1
                      ) {
                        onOpenMask?.().handler({
                          entity: entityType,
                          id: dataTable.selectedEntities[0].id,
                          openTab: TicketTabKeys.attachments,
                        });
                      }
                    }}
                    onEmailClicked={() => {
                      if (templates.length > 0) {
                        setIsEmailTemplatesPopoverOpen(true);
                      } else {
                        openEmailMask(templates[0]);
                      }
                    }}
                    openMaskTab={tableHandlers.openMaskTab}
                    // TODO: this is not used
                    onEmailRef={emailTemplatesRef}
                    ticketIds={dataTable.selectedEntities.map((e) => e.id)}
                    onPhoneClick={() => setIsContactsPopoverOpend(true)}
                    phoneIconRef={contactsPopoverRef}
                  />
                ),
              },
          }}
          prepareRowDisplayModifiers={rowModifiers}
          {...props}
        />
      </>
    );
  }
);
