import {
  MouseEventHandler,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useUser } from '@work4all/data';

import { Document } from '@work4all/models/lib/Classes/Document.entity';
import { FileEntity } from '@work4all/models/lib/Classes/FileEntity.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { canEditDocument } from '@work4all/utils/lib/permissions';

import { PDFTextmarkConf } from '../../../preview/pdf-textmarks/PDFTextmarkContainer';
import { MIME_TYPES } from '../../../preview/Preview';
import { IConvertPopoverProps } from '../../convert-popover/ConvertPopover';
import { usePdfOverlay } from '../erp-preview/use-pdf-overlay';
import {
  FileAndThumbnail,
  FileListPreview,
  getDocumentFileList,
} from '../FileListPreview';
import { AttachmentRenameContext } from '../FileListPreview/attachment-rename-context';
import { useAttachmentRenameContextState } from '../FileListPreview/use-attachment-rename-context-state';
import { useEntityPreviewDataRequest } from '../hooks/use-entity-preview-data';

import { DocumentPreview } from './DocumentPreview';
import { useDocumentPdfEditRights } from './use-document-pdf-edit-rights';

export interface IDocumentPreviewContainerProps
  extends Omit<
    DocumentPreviewContainerInnerProps,
    'document' | 'onEditPdfClicked'
  > {
  documentId: number;
}

const FileInfoFragment: FileEntity<EMode.query> = {
  previewUrl: null,
  downloadUrl: null,
  fileRequestType: null,
  previewMimeType: null,
  downloadMimeType: null,
  fileServiceProviderInfos: {
    id: null,
    exists: null,
    fileName: null,
    mimeType: null,
    fspUrl: null,
  },

  // As the FileInfo type doesn't have an ID field, we need to also request
  // properties that are likely to be fetched by other components (the table)
  // so the Apollo cache is not invalidated.
  fileEntityFilename: null,
};

const DocumentFileFragment: Document<EMode.query> = {
  thumbnail: {
    id: null,
    filename: null,
    fileInfos: {
      ...FileInfoFragment,
    },
  },
  fileInfos: {
    ...FileInfoFragment,
  },
  noteHtml: null,
};

export const requestedDocumentData: Document<EMode.query> = {
  id: null,
  filePath: null,
  note: null,
  userId: null,
  ...DocumentFileFragment,
  childDocuments: [
    {
      id: null,
      ...DocumentFileFragment,
    },
  ],
};

export function DocumentPreviewContainer(
  props: IDocumentPreviewContainerProps
) {
  const {
    documentId,
    onCloseClicked,
    onShareClicked,
    onEditClicked,
    convertProps,
  } = props;

  const { data: documents, refetch } = useEntityPreviewDataRequest<Document>(
    [documentId],
    Entities.document,
    requestedDocumentData
  );

  const document = documents?.[0];

  const [selectedFileIdx, setSelectedFileIdx] = useState<number>(null);
  const selectedDocument =
    selectedFileIdx < 1
      ? document
      : document?.childDocuments?.[selectedFileIdx - 1];

  const pdfEditAllowed = useDocumentPdfEditRights({
    fileName: selectedDocument?.fileInfos?.fileEntityFilename,
    documentUserId: document?.userId,
  });

  const reloadPreview = useCallback(async () => {
    await refetch();
    /**
     * Workaround to reinitialize the <DocumentPreviewContainerInner> component
     * todo: implement a refetch inside <DocumentPreviewContainerInner>
     */
    setShowPreview(false);
    setTimeout(() => setShowPreview(true));
  }, [refetch]);

  const { component: pdfOverlay, open: openPdfOverlay } = usePdfOverlay({
    entityId: selectedDocument?.id,
    entityType: Entities.document,
    onClose: reloadPreview,
  });

  const attachmentRenameContextState = useAttachmentRenameContextState({
    nameDisabled: true,
    noteDisabled: true,
  });

  const [showPreview, setShowPreview] = useState(true);

  if (!document) return null;

  return (
    <AttachmentRenameContext.Provider value={attachmentRenameContextState}>
      {pdfOverlay}
      {showPreview && (
        <DocumentPreviewContainerInner
          document={document}
          onCloseClicked={onCloseClicked}
          onEditClicked={onEditClicked}
          onShareClicked={onShareClicked}
          onEditPdfClicked={pdfEditAllowed ? () => openPdfOverlay() : undefined}
          convertProps={convertProps}
          onSelectedIndexChange={setSelectedFileIdx}
        />
      )}
    </AttachmentRenameContext.Provider>
  );
}

interface DocumentPreviewContainerInnerProps {
  document: Document;
  onCloseClicked?: MouseEventHandler<HTMLButtonElement>;
  onEditClicked?: MouseEventHandler<HTMLButtonElement>;
  onEditPdfClicked?: MouseEventHandler<HTMLButtonElement>;
  onShareClicked?: () => void;
  pdfTextmarkConf?: PDFTextmarkConf;
  convertProps?: Pick<IConvertPopoverProps, 'exclude' | 'onClick'>;
  onSelectedIndexChange?: (index: number) => void;
}

function DocumentPreviewContainerInner(
  props: DocumentPreviewContainerInnerProps
) {
  const {
    document: doc,
    onCloseClicked,
    onEditClicked,
    onEditPdfClicked,
    onShareClicked,
    pdfTextmarkConf,
    convertProps,
    onSelectedIndexChange,
  } = props;

  const { setNote } = useContext(AttachmentRenameContext);

  const { id, childDocuments, noteHtml, note } = doc;

  useEffect(() => {
    if (id) {
      setNote(id.toString(), noteHtml);
      childDocuments.forEach((doc) => {
        const id = (doc as Document)?.id;
        setNote(id, doc?.noteHtml);
      });
    }
  }, [id, noteHtml, setNote, childDocuments]);

  const user = useUser();

  const files = useMemo<FileAndThumbnail[]>(() => {
    return getDocumentFileList(doc);
  }, [doc]);

  const isLinkable =
    typeof doc.filePath === 'string' &&
    ['www', 'http', 'https'].some((prefix) => doc.filePath.startsWith(prefix));

  const selectedRowsIdsList = useMemo(() => {
    return doc.id ? [doc.id] : [];
  }, [doc.id]);

  // If a document is created from a link, use the old preview. Otherwise use
  // the new preview component that only works file lists of files.
  if (isLinkable) {
    return (
      <DocumentPreview
        entries={[doc]}
        entity={Entities.document}
        entityData={requestedDocumentData}
        titleKeyField="note"
        title={doc.note}
        exists={doc.fileInfos?.fileServiceProviderInfos?.exists}
        url={doc.fileInfos?.previewUrl}
        mimeType={doc.fileInfos?.previewMimeType as MIME_TYPES}
        noPreviewUrl={
          doc.fileInfos?.fileServiceProviderInfos?.fspUrl ||
          doc.fileInfos?.downloadUrl
        }
        downloadUrl={doc.fileInfos?.downloadUrl}
        filePath={doc.filePath}
        onCloseClicked={onCloseClicked}
        onEditClicked={onEditClicked}
        onPdfEditClicked={onEditPdfClicked}
        fspUrl={doc.fileInfos?.fileServiceProviderInfos?.fspUrl}
        iconProps={{
          showPreviewExternallyIcon: canEditDocument(user, doc),
        }}
        onShareClicked={onShareClicked}
        pdfTextmarkConf={pdfTextmarkConf}
        convertProps={convertProps}
        selectedRowsIdsList={selectedRowsIdsList}
      />
    );
  }

  return (
    <FileListPreview
      title={note}
      files={files}
      document={[doc]}
      iconProps={{
        showPreviewExternallyIcon: canEditDocument(user, doc),
      }}
      onCloseClicked={onCloseClicked}
      onEditClicked={onEditClicked}
      onShareClicked={onShareClicked}
      onPdfEditClicked={onEditPdfClicked}
      convertProps={convertProps}
      onSelectedIndexChange={onSelectedIndexChange}
    />
  );
}
