import { Badge } from '@mui/material';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { HookedUserIcon } from '@work4all/components/lib/components/user-icon/useUserIconRegister';

import { Mention } from '@work4all/models/lib/Classes/Mention.entity';
import { Notification } from '@work4all/models/lib/Classes/Notification.entity';

import { MASCULINE_ENTITIES } from '../../constant';

import { NotificationDate } from './components/NotificationDate';
import { NotificationIcon } from './components/NotificationIcon';
import { NotificationLayout } from './components/NotificationLayout';
import { NotificationText } from './components/NotificationText';
import { NotificationTitle } from './components/NotificationTitle';
import { resolveNotificationData } from './utils/resolve-notification-data';

export interface NotificationListItemMentionProps {
  className?: string;
  style?: React.CSSProperties;
  notification: Notification;
  count?: number;
  onClick: () => void;
}

export function NotificationListItemMention(
  props: NotificationListItemMentionProps
) {
  const { className, style, notification, onClick, count = 1 } = props;

  const { t } = useTranslation();

  const mention = notification.object as Mention;
  const { parentObject } = mention;

  const {
    entity = null,
    title = 'Mention',
    supplier,
    customer,
    project,
  } = resolveNotificationData(parentObject) ?? {};

  return (
    <NotificationLayout
      className={className}
      style={style}
      unread={!notification.isRead}
      onClick={onClick}
    >
      <NotificationIcon>
        <Badge
          badgeContent={count.toString()}
          color={notification.isRead ? 'secondary' : 'primary'}
          invisible={count <= 1}
        >
          <HookedUserIcon userId={notification.fromUser.id} />
        </Badge>
      </NotificationIcon>
      <NotificationTitle unread={!notification.isRead}>
        {title}
      </NotificationTitle>
      <NotificationText>
        <Trans
          i18nKey={
            MASCULINE_ENTITIES.includes(entity)
              ? 'NOTIFICATION.USER_MENTIONED_TEXT'
              : 'NOTIFICATION.USER_MENTIONED_TEXT_FEMININE'
          }
          values={{
            user: notification.fromUser.displayName,
            entity:
              t(`COMMON.${(entity ?? 'unknown').toUpperCase()}`, {
                defaultValue: '',
              }) || t(`COMMON.MENTION`),
          }}
        />

        <div>{customer?.name}</div>
        <div>{supplier?.name}</div>
        <div>{project?.name}</div>
      </NotificationText>

      <NotificationDate date={notification.insertTime} />
    </NotificationLayout>
  );
}
