import { useEventCallback } from '@mui/material/utils';
import { MouseEventHandler, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDialogs } from '@work4all/components';
import { ContextMenu } from '@work4all/components/lib/components/context-menu/ContextMenu';
import { ButtonWithLink } from '@work4all/components/lib/dataDisplay/actions-bar/ToolbarTypes';
import {
  IBasicTableProps,
  useTableStateBag,
} from '@work4all/components/lib/dataDisplay/basic-table';
import { useCtrlMouseClickLink } from '@work4all/components/lib/hooks/use-ctrl-mouse-click-link';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ListPageContext } from '../../../../../containers/file-entities-lists/list-page-context';
import { useConfirmDeleteEntities } from '../../../../../containers/mask-overlays/mask-overlay/hooks/use-confirm-delete-entities';

import { TableContextMenuContent } from './TableContextMenuContent';

export interface TableContextMenuProps {
  actions?: {
    edit?: ButtonWithLink<string>;
    remove?: {
      disabled?: boolean;
      maxCount: number;
      handler: (ids: string[]) => void;
    } | null;
    convert?: {
      exclude: Entities;
      handler: (entity: Entities) => void;
    } | null;
    openInNewTab?: {
      getPath: (id: string) => string;
    };
  };
  children?: (props: {
    onRowContextMenu?: IBasicTableProps['onRowContextMenu'];
  }) => JSX.Element;
}

export function TableContextMenu(props: TableContextMenuProps) {
  const { actions = {}, children } = props;

  const { t } = useTranslation();

  const dialogs = useDialogs();
  const confirmDeleteEntities = useConfirmDeleteEntities();

  const { tableInstance } = useTableStateBag();
  const listPageContext = useContext(ListPageContext);
  const { getLink } = useCtrlMouseClickLink();

  const selectedRowsIds = tableInstance?.state.selectedRowIds;
  const selectedRowsIdsList = useMemo(() => {
    if (!selectedRowsIds) return [];

    return Object.entries(selectedRowsIds)
      .filter(([_id, isSelected]) => isSelected)
      .map(([id]) => id);
  }, [selectedRowsIds]);

  const mappedActions = useMemo(() => {
    const isSomethingSelected = selectedRowsIdsList.length > 0;
    const isExactlyOneSelected = selectedRowsIdsList.length === 1;

    const edit = actions.edit;
    const remove = actions.remove;
    const convert = actions.convert;
    const openInNewTab = actions.openInNewTab;

    const isEditEnabled = edit && edit.disabled !== true;

    const config = {
      edit:
        edit?.handler && isExactlyOneSelected
          ? {
              onClick: () => edit.handler(selectedRowsIdsList[0]),
              disabled: edit.disabled,
            }
          : null,

      remove:
        remove && isSomethingSelected
          ? {
              onClick: () => {
                if (selectedRowsIdsList.length > remove.maxCount) {
                  dialogs.alert({
                    title: t('ALERTS.DELETE.NOT_ALLOWED'),
                    description: t('ALERTS.DELETE.MAX_WARNING', {
                      count: remove.maxCount,
                    }),
                  });
                } else {
                  confirmDeleteEntities({
                    count: selectedRowsIdsList.length,
                  }).then((confirmed) => {
                    if (confirmed) {
                      remove.handler(selectedRowsIdsList);
                    }
                  });
                }
              },
              disabled: remove.disabled,
            }
          : null,

      convert:
        convert && isSomethingSelected
          ? {
              exclude: convert.exclude,
              onClick: (entity: Entities) => {
                convert.handler(entity);
              },
            }
          : null,

      openInNewTab:
        isExactlyOneSelected && (isEditEnabled || openInNewTab?.getPath)
          ? {
              onClick: () => {
                let path = openInNewTab?.getPath(selectedRowsIdsList[0]);
                if (openInNewTab?.getPath && !path) {
                  throw new Error(
                    `New tab path unavailable. Insufficient data.`
                  );
                }
                if (!path) {
                  const id = selectedRowsIdsList[0];
                  const entity = listPageContext?.entityType;
                  path = getLink({ entity, id });
                }
                window.open(path);
              },
            }
          : null,
    };

    return config;
  }, [
    selectedRowsIdsList,
    actions.edit,
    actions.remove,
    actions.convert,
    actions.openInNewTab,
    dialogs,
    t,
    confirmDeleteEntities,
    getLink,
    listPageContext?.entityType,
  ]);

  const skip =
    !actions.edit &&
    !actions.remove &&
    !actions.convert &&
    !actions.openInNewTab;

  if (skip) return children({});

  return (
    <ContextMenu content={<TableContextMenuContent actions={mappedActions} />}>
      {({ onContextMenu }) => {
        return (
          <ContextMenuInternal
            onContextMenu={onContextMenu}
            children={children}
          />
        );
      }}
    </ContextMenu>
  );
}

const ContextMenuInternal = ({
  onContextMenu,
  children,
}: {
  onContextMenu: MouseEventHandler<Element>;
  children: TableContextMenuProps['children'];
}) => {
  const { tableInstance } = useTableStateBag();
  const noTableInstance = !tableInstance;
  const skip = noTableInstance;

  const selectedRowIds = tableInstance?.state.selectedRowIds;
  const toggleAllRowsSelected = tableInstance?.toggleAllRowsSelected;
  const onRowContextMenu: IBasicTableProps['onRowContextMenu'] =
    useEventCallback((event, row) => {
      if (skip) {
        return;
      }

      // If the clicked row is not part of selection, unselect everything
      // and select this row before opening the context menu.
      if (!selectedRowIds[row.id]) {
        toggleAllRowsSelected(false);
        row.toggleRowSelected(true);
      }

      onContextMenu(event);
    });

  return children({ onRowContextMenu });
};
