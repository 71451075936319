import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { BusinessPartner } from '@work4all/models/lib/Classes/BusinessPartner.entity';
import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Document } from '@work4all/models/lib/Classes/Document.entity';
import { entityDefinition } from '@work4all/models/lib/Classes/entityDefinitions';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { ProjectProcess } from '@work4all/models/lib/Classes/ProjectProcess.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { TopicSimple } from '@work4all/models/lib/Classes/TopicSimple.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { areEntitiesInSameGroup, GroupEntities } from '@work4all/utils';

import { MaskConfig } from '../types';

/**
 * entities that can be related to project, customers or suppliers and the corresponding contacts
 */
export interface AssignableEntity<T extends EMode = EMode.entity> {
  id?: number;
  businessPartnerType?: SdObjType;
  businessPartner?: BusinessPartner<T>;
  project?: Project<T>;
  projectProcess?: ProjectProcess<T>;
  contact?: Contact<T>;
  topicMarkList?: TopicSimple<T>[];
  childDocuments?: Document<T>[];
}

export interface AssignableEntityResult {
  enabled: boolean;
  entity: Entities;
  data: AssignableEntity;
  pending: boolean;
}

function checkEntityTypeHasField(entity: Entities, field) {
  const fields = entityDefinition[entity].fieldDefinitions;
  return Object.prototype.hasOwnProperty.call(fields, field);
}

const BASE_CONTACT_FIELDS: Contact<EMode.query> = {
  id: null,
  eMail: null,
  name: null,
  firstName: null,
  displayName: null,
  layedOff: null,
  role: null,
  salutation: {
    id: null,
    isMale: null,
    isFemale: null,
    standardletterSalutation: null,
  },
};
const BP_FIELDS: Omit<Customer<EMode.query> | Supplier<EMode.query>, '_props'> =
  {
    id: null,
    name: null,
    mainContact: BASE_CONTACT_FIELDS,
    eMail: null,
  };

const PROJECT_FIELDS: Project<EMode.query> = {
  id: null,
  name: null,
};

const PROJECT_PROCESS_FIELDS: ProjectProcess<EMode.query> = {
  id: null,
  process: null,
  projectId: null,
};

const CONTACT_FIELDS = {
  ...BASE_CONTACT_FIELDS,
  businessPartnerType: null,
  businessPartner: {
    id: null,
    data: {
      customer: BP_FIELDS,
      supplier: BP_FIELDS,
    },
  },
};

const TOPIC_MARK_LIST_FIELDS = [{ id: null, name: null }];
const CHILD_DOCUMENTS_LIST_FIELDS = [{ id: null }];

const getDataEntityRequest = (entity: Entities, isSimilarGroup: boolean) => {
  const data: AssignableEntity<EMode.query> = {};
  if (checkEntityTypeHasField(entity, 'id')) {
    data.id = null;
  }
  if (checkEntityTypeHasField(entity, 'businessPartner')) {
    data.businessPartner = {
      id: null,
      businessPartnerType: null,
      data: {
        customer: BP_FIELDS,
        supplier: BP_FIELDS,
      },
    };
  }
  if (checkEntityTypeHasField(entity, 'project')) {
    data.project = PROJECT_FIELDS;
  }
  if (checkEntityTypeHasField(entity, 'contact')) {
    data.contact = CONTACT_FIELDS;
  }
  if (checkEntityTypeHasField(entity, 'projectProcess')) {
    data.projectProcess = PROJECT_PROCESS_FIELDS;
  }
  if (checkEntityTypeHasField(entity, 'topicMarkList') && isSimilarGroup) {
    data.topicMarkList = TOPIC_MARK_LIST_FIELDS;
  }
  if (checkEntityTypeHasField(entity, 'childDocuments')) {
    data.childDocuments = CHILD_DOCUMENTS_LIST_FIELDS;
  }

  return data;
};

export function parseTemplate(config: MaskConfig) {
  const templateString = config.template
    ? `${config.template.entity}:${config.template.id}`
    : null;

  const template =
    templateString !== null ? tryParseInit(templateString) : null;

  return template;
}

export function useAssignableTemplateEntity(config: MaskConfig) {
  // This is a simple workaround to keep using the existing to process the
  // template value. This can be later refactored to remove all the unneeded
  // conversions.
  const template = parseTemplate(config);
  // TODO At the moment this hook only works with CRM entities. If other
  // entities can be used as a template, `DataRequest` object must be updated
  // and the return value should also probably be normalized.

  const areTheGroupsEqual = areEntitiesInSameGroup(
    config.entity as GroupEntities,
    template?.entity as GroupEntities
  );
  const request = useMemo<DataRequest>(() => {
    if (!template?.entity || !template?.id) {
      return { skip: true, entity: null, data: null };
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filter: any = [{ id: { $eq: template.id } }];
    let data:
      | Customer<EMode.query>
      | Supplier<EMode.query>
      | Project<EMode.query>
      | AssignableEntity<EMode.query>;
    if ([Entities.customer, Entities.supplier].includes(template.entity)) {
      //these are proper assignables directly
      data = BP_FIELDS;
    } else if (template.entity === Entities.contact) {
      data = CONTACT_FIELDS;
      if (!template?.businessPartnerId || !template.businessPartnerType) {
        //businesspartners are not unique by id, they need to be destinguished by bp as well
        return { skip: true, entity: null, data: null };
      }
      filter.push({
        businessPartnerId: { $eq: template?.businessPartnerId },
        businessPartnerType: {
          $eq:
            template?.businessPartnerType === Entities.customer
              ? SdObjType.KUNDE
              : SdObjType.LIEFERANT,
        },
      });
    } else if (template.entity === Entities.project) {
      if (!template?.id) {
        //businesspartners are not unique by id, they need to be destinguished by bp as well
        return { skip: true, entity: null, data: null };
      }
      filter.push({
        id: { $eq: template?.id },
      });
      data = PROJECT_FIELDS;
    } else {
      //check what we can deliver
      data = getDataEntityRequest(template.entity, areTheGroupsEqual);
    }
    if (!data || Object.keys(data).length === 0) {
      console.warn(
        'trying to use a template entity that does not comply to an assignable interface',
        template.entity
      );
      return { skip: true, entity: null, data: null };
    }

    return {
      entity: template.entity,
      data,
      filter: filter,
    };
  }, [
    areTheGroupsEqual,
    template?.businessPartnerId,
    template?.businessPartnerType,
    template?.entity,
    template?.id,
  ]);

  const response = useDataProvider(request);

  const enabled = template !== null;

  const data = response.data[0] ?? null;
  return useMemo((): AssignableEntityResult => {
    let returnData: AssignableEntity = data;
    if (enabled && data) {
      if (
        [Entities.customer, Entities.supplier, Entities.project].includes(
          template.entity
        )
      ) {
        if (template.entity === Entities.project) {
          returnData = {
            project: data,
          };
        } else {
          const contact = data.mainContact;

          returnData = {
            businessPartnerType:
              template.entity === Entities.customer
                ? SdObjType.KUNDE
                : SdObjType.LIEFERANT,
            businessPartner: {
              id: null,
              data: data,
            },
            contact: contact,
          };
        }
      } else if (template.entity === Entities.contact) {
        returnData = {
          businessPartnerType: data?.businessPartnerType,
          businessPartner: data?.businessPartner,
          contact: data,
        };
      }
    }
    return {
      enabled,
      entity: template?.entity,
      data: returnData,
      pending: response.pending,
    };
  }, [enabled, data, template?.entity, response.pending]);
}

export function tryParseInit(rawValue: string): null | {
  entity: Entities;
  id: number;
  businessPartnerType?: Entities.customer | Entities.supplier;
  businessPartnerId?: number;
} {
  try {
    const regex =
      /^(?<entity>.+?):(?<id>\d+)(:(?<bpt>(customer|supplier)))?(:(?<businessPartnerId>\d+))?$/;

    const result = regex.exec(rawValue);

    if (result === null) {
      return null;
    }

    const { entity, id, bpt, businessPartnerId } = result.groups;

    if (!(entity in Entities)) {
      return null;
    }

    let businessPartnerType;
    if (bpt === 'customer') {
      businessPartnerType = Entities.customer;
    }
    if (bpt === 'supplier') {
      businessPartnerType = Entities.supplier;
    }

    const bpId = Number(businessPartnerId);
    return {
      entity: entity as Entities,
      id: Number(id),
      businessPartnerType: Number.isNaN(bpId) ? entity : businessPartnerType,
      businessPartnerId: Number.isNaN(bpId) ? Number(id) : bpId,
    };
  } catch {
    return null;
  }
}
