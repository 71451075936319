import { useTranslation } from 'react-i18next';

import { TicketStatus } from '@work4all/models/lib/Enums/TicketStatus.enum';

import { TicketStatusPicker } from '../../../../../entity-picker/ticket-status-picker/TicketStatusPicker';
import { withExtras } from '../../utils';

import { PreviewPickerInput } from './PreviewPickerInput';

export const TicketStatusPreviewPickerInput = withExtras<string>(
  ({ value, onChange, ...rest }) => {
    const { t } = useTranslation();

    return (
      <PreviewPickerInput<string>
        {...rest}
        picker={
          <TicketStatusPicker
            value={{ id: value, name: '' }}
            onChange={(value) => {
              onChange(value?.id);
            }}
            multiple={false}
            availableStatus={[
              TicketStatus.OFFEN,
              TicketStatus.ERLEDIGT,
              TicketStatus.IN_BEARBEITUNG,
              value as TicketStatus,
            ]}
          />
        }
        value={value}
        formattedValue={t(`TICKET_STATUS.${value}`)}
      />
    );
  }
);
