import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputCrmAnhangAttachementsRelation } from './InputCrmAnhangAttachementsRelation.entity';

export class InputReisekostenabrechnungBelegRelation<
  T extends EMode = EMode.entity
> {
  /** Alias: attachements */
  attachements?: InputCrmAnhangAttachementsRelation<T>;
  /** Alias: supplierCode */
  supplierCode?: number;

  __typename?: string;
}

const fields: FieldDefinitions<InputReisekostenabrechnungBelegRelation> = {
  attachements: {
    alias: 'attachements',
  },
  supplierCode: {
    alias: 'supplierCode',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputReisekostenabrechnungBelegRelationEntityDefinition: EntitiyDefinition<InputReisekostenabrechnungBelegRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputReisekostenabrechnungBelegRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
