import { arrayMove } from '../edit-table/hooks/arrayMove';

export const handleOrderChange = (columns: string[]) => {
  // lock BOM quantity movement
  const bomQuantity = columns.findIndex((x) => x === 'partsListAmount');
  const quantity = columns.findIndex((x) => x === 'amount');
  if (bomQuantity !== -1 && quantity !== -1) {
    // move bomQuantity to quanity -1
    if (bomQuantity > quantity)
      return arrayMove(columns, bomQuantity, quantity);
    else return arrayMove(columns, bomQuantity, quantity - 1);
  }
  return columns;
};
