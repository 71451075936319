import { Alert, Button, DialogActions, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { DialogContent } from '@work4all/components';
import { Dialog } from '@work4all/components/lib/dialog/Dialog';

export const DisableConfirmDialog = (props: {
  open: boolean;
  onClose?: (success?: boolean) => void;
}) => {
  const { open, onClose } = props;

  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      title={t('MFA.DEACTIVATE.TITLE')}
    >
      <DialogContent>
        <Stack gap="1rem">
          <Typography>{t('MFA.DEACTIVATE.DESC')}</Typography>
        </Stack>
        <Alert severity="warning">{t('MFA.AUTH_APP.STEP_2_INFO')}</Alert>
      </DialogContent>
      <DialogActions>
        <Button
          size="large"
          color="primary"
          type="button"
          onClick={() => onClose(false)}
        >
          {t('MFA.CANCEL')}
        </Button>
        <Button
          size="large"
          color="primary"
          type="button"
          onClick={() => onClose(true)}
        >
          {t('MFA.DEACTIVATE')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
