import { useTranslation } from 'react-i18next';

import { TaskStatus } from '@work4all/models/lib/Enums/TaskStatus.enum';

import { TaskStatusPicker } from '../../../../../entity-picker/task-status-picker/TaskStatusPicker';
import { withExtras } from '../../utils';

import { PreviewPickerInput } from './PreviewPickerInput';

export const TaskStatusPreviewPickerInput = withExtras<string>(
  ({ value, onChange, ...rest }) => {
    const { t } = useTranslation();

    return (
      <PreviewPickerInput<string>
        {...rest}
        picker={
          <TaskStatusPicker
            value={{ id: value, name: '' }}
            onChange={(value) => {
              onChange(value?.id);
            }}
            multiple={false}
            availableStatus={[
              TaskStatus.OFFEN,
              TaskStatus.ERLEDIGT,
              TaskStatus.IN_BEARBEITUNG,
              value as TaskStatus,
            ]}
          />
        }
        value={value}
        formattedValue={t(`TASK_STATUS.${value}`)}
      />
    );
  }
);
