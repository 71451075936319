import { Theme, useMediaQuery } from '@mui/material';
import React, { useMemo } from 'react';
import { TableInstance } from 'react-table';

import { useTableStateBag } from '@work4all/components';
import { EmailPreviewContainer } from '@work4all/components/lib/components/entity-preview/email-preview';

import { useEntityEvents } from '@work4all/data/lib/entity-events/use-entity-events';
import { usePermissions } from '@work4all/data/lib/hooks/use-permissions';
import { useSyncEmailStatus } from '@work4all/data/lib/hooks/use-sync-email-status';

import { EMail } from '@work4all/models/lib/Classes/EMail.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useOnOpenMaskContext } from '../../containers/mask-overlays/mask-overlay/components/OpenMaskProvider';
import {
  EmailActions,
  useEmailActions,
} from '../../containers/mask-overlays/mask-overlay/views/email/EmailActions';

import { AttachmentCell } from './AttachmentCell';
import { EmailKindCell } from './EmailKindCell';
import { EmailStatusCell } from './EmailStatusCell';
import { EntityTable, IEntityTable } from './entity-table/EntityTable';
import { useEntityDataTable } from './entity-table/use-entity-data-table';
import { PreviewMobileWrapper } from './PreviewMobileWrapper';
import schema from './schemata/eMail-table-schema.json';
import { useDeleteEntitiesToolbarConfig } from './use-delete-entities-toolbar-config';
import { useTableHandlers } from './use-table-handlers';

const defaultSort = [{ field: 'date', direction: SortDirection.DESCENDING }];

export type IEmailKindCellConfig = {
  type: 'EmailKind';
};

export type IAttachmentCellConfig = {
  type: 'EmailAttachment';
};

export type IEmailStatusCellConfig = {
  type: 'EmailStatus';
};

type IEmailTableCustomColumns = {
  EmailKind?: IEmailKindCellConfig;
  Attachment?: IAttachmentCellConfig;
  EmailStatus?: IEmailStatusCellConfig;
};

export const EmailsTable = React.forwardRef<TableInstance, IEntityTable>(
  function EmailsTable(_props, ref) {
    const { entity: entityType } = schema as never;

    const tableStateBag = useTableStateBag();

    const { permissions } = usePermissions();

    const dataTable = useEntityDataTable<EMail, IEmailTableCustomColumns>({
      schema: schema as never,
      cells: {
        EmailKind: EmailKindCell,
        Attachment: AttachmentCell,
        EmailStatus: EmailStatusCell,
      },
      cellProcessors: {
        EmailStatus: (config) => {
          return [config.accessor, 'saveSendMailJob.errorMessages'];
        },
      },
      defaultSort,
    });

    useSyncEmailStatus({
      shouldSync({ id }) {
        const emails = dataTable.data as EMail[];
        const found = emails.find((email) => email.id === id);
        return found !== undefined;
      },
    });

    const { onOpenMask } = useOnOpenMaskContext();

    const tableHandlers = useTableHandlers(entityType, onOpenMask);

    const deleteConfig = useDeleteEntitiesToolbarConfig({
      entityType,
    });

    const { emailActionDefinitions } = useEmailActions({
      onOpenMask,
      hasRight: permissions.eMail.canAdd(),
      outbound: dataTable.selectedEntity?.kind === 'AUSGEHEND' ? true : false,
    });

    const isDesktop = useMediaQuery<Theme>((theme) =>
      theme.breakpoints.up('sm')
    );

    const isOutbound = (kind: string): boolean => {
      return kind === 'AUSGEHEND' ? true : false;
    };

    useEntityEvents((event) => {
      if (event.entity === Entities.eMail) {
        dataTable.refetch();
      }
    });

    const entityIds = useMemo(() => {
      return dataTable.selectedEntity?.id ? [dataTable.selectedEntity.id] : [];
    }, [dataTable.selectedEntity]);

    return (
      <EntityTable
        ref={ref}
        {...dataTable}
        areas={{
          right: dataTable.selectedEntity !== null && {
            content: (
              <PreviewMobileWrapper
                active={!isDesktop}
                entityIds={entityIds}
                actions={{
                  edit: tableHandlers.edit,
                  remove: deleteConfig,
                  additionalActionsLeft: (
                    <EmailActions
                      hasRight
                      id={dataTable.selectedEntity.id}
                      onOpenMask={onOpenMask}
                      outbound={isOutbound(dataTable.selectedEntity.kind)}
                    />
                  ),
                }}
              >
                <EmailPreviewContainer
                  onCloseClicked={() =>
                    tableStateBag.tableInstance.toggleAllRowsSelected(false)
                  }
                  onEditClicked={tableHandlers.edit.handler}
                  emailId={dataTable.selectedEntity.id}
                  onShareClicked={tableHandlers.share}
                  convertProps={{
                    exclude: entityType,
                    onClick: tableHandlers.convert,
                  }}
                />
              </PreviewMobileWrapper>
            ),
          },
        }}
        actions={{
          custom: {
            left: emailActionDefinitions,
          },
        }}
      />
    );
  }
);
