import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { ReAccountingStatus } from '../Enums/ReAccountingStatus.enum';
import { REBelegart } from '../Enums/REBelegart.enum';
import { ReDueDateClassified } from '../Enums/ReDueDateClassified.enum';
import { RePaymentStatus } from '../Enums/RePaymentStatus.enum';
import { RePermitStatus } from '../Enums/RePermitStatus.enum';

import { Currency } from './Currency.entity';
import { PaymentKind } from './PaymentKind.entity';
import { Supplier } from './Supplier.entity';
import { User } from './User.entity';

export class ReViewModel<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: sdObjMemberCode */
  businessPartnerId?: number;
  /** Alias: exchangeRate */
  exchangeRate?: number;
  /** Alias: currencyCode */
  currencyCode?: number;
  /** Alias: deductionDiscount */
  deductionDiscount?: number;
  /** Alias: isPayed */
  isPayed?: boolean;
  /** Alias: amountGross */
  amountGross?: number;
  /** Alias: dateOfReceipt */
  dateOfReceipt?: string;
  /** Alias: permitByUserCode */
  permitByUserCode?: number;
  /** Alias: text */
  note?: string;
  /** Alias: netValue */
  netValue?: number;
  /** Alias: discountPerCent */
  discountPerCent?: number;
  /** Alias: discountDate */
  discountDate?: string;
  /** Alias: dueDate */
  dueDate?: string;
  /** Alias: originalCurrencyValue */
  originalCurrencyValue?: number;
  /** Alias: userCode */
  userCode?: number;
  /** Alias: bookingDate */
  bookingDate?: string;
  /** Alias: inAccounting */
  inAccounting?: boolean;
  /** Alias: originalInvoiceNumber */
  originalInvoiceNumber?: string;
  /** Alias: valueVat */
  valueVat?: number;
  /** Alias: reference */
  reference?: string;
  /** Alias: paymentStatus */
  paymentStatus?: RePaymentStatus;
  /** Alias: accountingStatus */
  accountingStatus?: ReAccountingStatus;
  /** Alias: currency */
  currency?: Currency<T>;
  /** Alias: permitByUser */
  permitByUser?: User<T>;
  /** Alias: user */
  user?: User<T>;
  /** Alias: supplier */
  supplier?: Supplier<T>;
  /** Alias: invoiceNumber */
  invoiceNumber?: number;
  /** Alias: invoiceDate */
  invoiceDate?: string;
  /** Alias: valueNotPayed */
  valueNotPayed?: number;
  /** Alias: invoiceType */
  invoiceKind?: REBelegart;
  /** Alias: paymentCode */
  paymentCode?: number;
  /** Alias: dueDays */
  dueDays?: number;
  /** Alias: dueClassified */
  dueClassified?: ReDueDateClassified;
  /** Alias: permitNote */
  permitNote?: string;
  /** Alias: permitState */
  permitStatus?: RePermitStatus;
  /** Alias: payment */
  paymentKind?: PaymentKind<T>;

  __typename?: string;
}

const fields: FieldDefinitions<ReViewModel> = {
  id: {
    alias: 'code',
  },
  businessPartnerId: {
    alias: 'sdObjMemberCode',
  },
  exchangeRate: {
    alias: 'exchangeRate',
  },
  currencyCode: {
    alias: 'currencyCode',
  },
  deductionDiscount: {
    alias: 'deductionDiscount',
  },
  isPayed: {
    alias: 'isPayed',
  },
  amountGross: {
    alias: 'amountGross',
  },
  dateOfReceipt: {
    alias: 'dateOfReceipt',
  },
  permitByUserCode: {
    alias: 'permitByUserCode',
  },
  note: {
    alias: 'text',
  },
  netValue: {
    alias: 'netValue',
  },
  discountPerCent: {
    alias: 'discountPerCent',
  },
  discountDate: {
    alias: 'discountDate',
  },
  dueDate: {
    alias: 'dueDate',
  },
  originalCurrencyValue: {
    alias: 'originalCurrencyValue',
  },
  userCode: {
    alias: 'userCode',
  },
  bookingDate: {
    alias: 'bookingDate',
  },
  inAccounting: {
    alias: 'inAccounting',
  },
  originalInvoiceNumber: {
    alias: 'originalInvoiceNumber',
  },
  valueVat: {
    alias: 'valueVat',
  },
  reference: {
    alias: 'reference',
  },
  paymentStatus: {
    alias: 'paymentStatus',
  },
  accountingStatus: {
    alias: 'accountingStatus',
  },
  currency: {
    alias: 'currency',
    entity: Entities.currency,
  },
  permitByUser: {
    alias: 'permitByUser',
    entity: Entities.user,
  },
  user: {
    alias: 'user',
    entity: Entities.user,
  },
  supplier: {
    alias: 'supplier',
    entity: Entities.supplier,
  },
  invoiceNumber: {
    alias: 'invoiceNumber',
  },
  invoiceDate: {
    alias: 'invoiceDate',
  },
  valueNotPayed: {
    alias: 'valueNotPayed',
  },
  invoiceKind: {
    alias: 'invoiceType',
  },
  paymentCode: {
    alias: 'paymentCode',
  },
  dueDays: {
    alias: 'dueDays',
  },
  dueClassified: {
    alias: 'dueClassified',
  },
  permitNote: {
    alias: 'permitNote',
  },
  permitStatus: {
    alias: 'permitState',
  },
  paymentKind: {
    alias: 'payment',
    entity: Entities.paymentKind,
  },
  __typename: {
    alias: '__typename',
  },
};

export const reViewModelEntityDefinition: EntitiyDefinition<ReViewModel> = {
  local: {},
  remote: {
    queryName: 'getIncomingInvoiceViewModel',
    fragmentName: 'ReViewModel',
    withPaginationWrapper: true,
    params: [
      { name: 'querySize', type: 'Int' },
      { name: 'queryPage', type: 'Int' },
      { name: 'querySortOrder', type: 'SortOrderStyle' },
      { name: 'querySortBy', type: 'String' },
      { name: 'filter', type: 'String' },
    ],
  },
  fieldDefinitions: fields,
};
