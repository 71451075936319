import { UsePreviewInputProps } from '../../types';
import { usePreviewInputHandlers } from '../use-preview-input-handlers/use-preview-input-handlers';
import { usePreviewInputHelpers } from '../use-preview-input-helpers/use-preview-input-helpers';
import { usePreviewInputValue } from '../use-preview-input-value/use-preview-input-value';
import { usePreviewInputsContext } from '../use-preview-inputs-context/use-preview-inputs-context';

export const usePreviewInput = <TValue,>({
  formattedValue,
  accessor,
  labelTranslationKey,
  renderPreviewValue,
  ref,
  entity: inputEntity,
  value,
  disabled,
}: UsePreviewInputProps<TValue>) => {
  const {
    isLoading,
    activeInputAccessor,
    handleLock,
    commonInputs,
    isMultiselect,
    isLocked,
    lockedByUser,
    numberOfSelectedRows,
    canEditAllSelected,
    entity: previewEntity,
  } = usePreviewInputsContext();

  const { setValue, editValueRef, previewValue } = usePreviewInputValue({
    renderPreviewValue,
    formattedValue,
    isMultiselect,
    numberOfSelectedRows,
    accessor,
    commonInputs,
  });

  const { onClick } = usePreviewInputHandlers({
    isLoading,
    isLocked,
    lockedByUser,
    ref,
    accessor,
    disabled,
    handleLock,
    canEditAllSelected,
  });

  const { isActive, lable, CircularProgress, BeforeContentElement } =
    usePreviewInputHelpers({
      labelTranslationKey,
      isLoading,
      activeInputAccessor,
      accessor,
      previewEntity,
      inputEntity,
      value,
    });

  return {
    handleLock,
    lable,
    previewValue,
    onClick,
    setValue,
    CircularProgress,
    BeforeContentElement,
    canEditAllSelected,
    isMultiselect,
    isActive,
    isLoading,
    editValueRef,
  };
};
