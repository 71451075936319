import styles from './BlockSenderPopover.module.scss';

import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Popover,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useDataMutation } from '@work4all/data';
import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { IncomingMailBlockedSender } from '@work4all/models/lib/Classes/IncomingMailBlockedSender.entity';
import { InputIncomingMailBlockedSender } from '@work4all/models/lib/Classes/InputIncomingMailBlockedSender.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export interface BlockSenderPopoverProps {
  address: string;
  popoverState: ReturnType<typeof usePopoverState>;
  onAfterBlockedSendersChange: () => void;
}

export function BlockSenderPopover(props: BlockSenderPopoverProps) {
  const { address, popoverState, onAfterBlockedSendersChange } = props;

  const { t } = useTranslation();

  const wildcardAddress = getDomainAddress(address);

  const [upsertHiddenAddress] = useDataMutation<
    IncomingMailBlockedSender,
    EMode.upsert
  >({
    entity: Entities.incomingMailBlockedSender,
    mutationType: EMode.upsert,
    responseData: { id: null },
    onCompleted: onAfterBlockedSendersChange,
    resetStore: false,
  });

  const handleClick = (address: string) => {
    upsertHiddenAddress<InputIncomingMailBlockedSender>({ address });
    popoverState.onClose();
  };

  const { handleClick: _, ...popoverProps } = popoverState;

  return (
    <Popover {...popoverProps} classes={{ paper: styles.popover }}>
      <Paper className={styles.paper}>
        <List>
          <Divider className={styles.divider} component="li">
            {t('ASSIGN_INCOMING_EMAILS.BLOCK_SENDER_ADDRESS')}
          </Divider>

          <ListItem disablePadding>
            <ListItemButton
              className={styles.listItemButton}
              title={address}
              onClick={() => handleClick(address)}
            >
              <ListItemText
                primaryTypographyProps={{ noWrap: true }}
                primary={address}
              />
            </ListItemButton>
          </ListItem>

          <Divider className={styles.divider} component="li">
            {t('ASSIGN_INCOMING_EMAILS.BLOCK_SENDER_DOMAIN')}
          </Divider>

          <ListItem disablePadding>
            <ListItemButton
              className={styles.listItemButton}
              title={wildcardAddress}
              onClick={() => handleClick(wildcardAddress)}
            >
              <ListItemText
                primaryTypographyProps={{ noWrap: true }}
                primary={wildcardAddress}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Paper>
    </Popover>
  );
}

function getDomainAddress(address: string): string {
  if (address.includes('@')) {
    return address.slice(address.indexOf('@'));
  }

  return address;
}
