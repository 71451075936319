import { useMemo } from 'react';

import { useUser } from '@work4all/data';

import {
  checkUserRight,
  UserRights,
} from '@work4all/models/lib/additionalEnums/UserRights';

interface Props {
  documentUserId: number;
  fileName: string;
}

export const useDocumentPdfEditRights = (props: Props) => {
  const { documentUserId, fileName } = props;
  const { benutzerRechte: userRights, benutzerCode: userId } = useUser();

  const docIsPDF = useMemo(() => {
    const segments = fileName?.split('.');
    return (
      segments && segments[segments.length - 1].toLocaleLowerCase() === 'pdf'
    );
  }, [fileName]);

  const docPdfEditAllowed = useMemo(() => {
    if (!docIsPDF) {
      return false;
    }
    if (checkUserRight(UserRights.KorrespondenzAnlegenAendern, userRights)) {
      return true;
    }
    if (
      checkUserRight(UserRights.KorrespondenzLoeschenEigene, userRights) &&
      documentUserId === userId
    ) {
      return true;
    }
    return false;
  }, [docIsPDF, userRights, documentUserId, userId]);

  return docPdfEditAllowed;
};
