import styles from './OverflowList.module.scss';

import clsx from 'clsx';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { useDeepMemo } from '@work4all/utils/lib/hooks/use-deep-memo';
import { useLatest } from '@work4all/utils/lib/hooks/use-latest';

import { useResizeObserver } from '../../hooks/use-resize-observer';

export type IOverflowListProps<T> = {
  /**
   * Whether to force the overflowRenderer to always be called, even if there are zero items
   * overflowing. This may be useful, for example, if your overflow renderer contains a Popover
   * which you do not want to close as the list is resized.
   *
   * @default false
   */
  alwaysRenderOverflow?: boolean;

  /**
   * All items to display in the list. Items that do not fit in the container
   * will be rendered in the overflow instead.
   */
  items: T[];

  /**
   * Callback invoked to render each visible item.
   * Remember to set a `key` on the rendered element!
   */
  visibleItemRenderer: (item: T, index: number) => React.ReactNode;

  /**
   * Callback invoked to render the overflowed items. Unlike
   * `visibleItemRenderer`, this prop is invoked once with all items that do
   * not fit in the container.
   *
   * Typical use cases for this prop will put overflowed items in a dropdown
   * menu or display a "+X items" label.
   */
  overflowRenderer: (overflowItems: T[]) => React.ReactNode;

  /**
   * A space-delimited list of class names to pass along to the root element.
   */
  className?: string;

  /** CSS properties to apply to the root element. */
  style?: React.CSSProperties;
};

enum OverflowDirection {
  None,
  Grow,
  Shrink,
}

interface IOverflowListState<T> {
  /**
   * Direction of current overflow operation.
   */
  direction: OverflowDirection;
  overflow: T[];
  visible: T[];
}

export function OverflowList<T>({
  alwaysRenderOverflow = false,
  items,
  visibleItemRenderer,
  overflowRenderer,
  className,
  style,
}: IOverflowListProps<T>) {
  const [state, setState] = useState<IOverflowListState<T>>({
    direction: OverflowDirection.None,
    overflow: [],
    visible: items,
  });

  const containerRef = useRef<HTMLDivElement>();
  const spacerRef = useRef<HTMLDivElement>();

  const previousWidth = useRef(0);

  function handleResize(entry: ResizeObserverEntry) {
    const growing = entry.contentRect.width > previousWidth.current;
    repartition.current(growing);
    previousWidth.current = entry.contentRect.width;
  }

  useResizeObserver(containerRef, handleResize);

  useEffect(() => {
    setState({
      direction: OverflowDirection.Grow,
      overflow: [],
      visible: items,
    });
  }, [alwaysRenderOverflow, items, visibleItemRenderer, overflowRenderer]);

  const repartition = useLatest(function repartition(growing: boolean) {
    const spacer = spacerRef.current;

    if (!spacer) {
      return;
    }

    if (growing) {
      setState({
        direction: OverflowDirection.Grow,
        overflow: [],
        visible: items,
      });
    } else if (spacer.getBoundingClientRect().width < 0.9) {
      // spacer has flex-shrink and width 1px so if it's much smaller then we know to shrink.
      setState((state) => {
        const visible = state.visible.slice();
        const next = visible.pop();

        if (next === undefined) {
          return state;
        }

        const overflow = [next, ...state.overflow];

        return {
          // set Shrink mode unless a Grow is already in progress.
          // Grow shows all items then shrinks until it settles, so we
          // preserve the fact that the original trigger was a Grow.
          ...state,
          direction:
            state.direction === OverflowDirection.None
              ? OverflowDirection.Shrink
              : state.direction,
          overflow,
          visible,
        };
      });
    } else {
      // repartition complete!
      setState((state) => ({ ...state, direction: OverflowDirection.None }));
    }
  });

  const deepMemo = useDeepMemo(() => state, [state]);

  useLayoutEffect(() => {
    repartition.current(false);
  }, [repartition, deepMemo]);

  function maybeRenderOverflow() {
    if (state.overflow.length === 0 && !alwaysRenderOverflow) {
      return null;
    }

    return overflowRenderer(state.overflow);
  }

  return (
    <div
      ref={containerRef}
      className={clsx(styles['overflow-list'], className)}
      style={style}
    >
      {state.visible.map(visibleItemRenderer)}
      {maybeRenderOverflow()}
      <div className={styles['overflow-list-spacer']} ref={spacerRef} />
    </div>
  );
}
