import styles from './MobileNavigation.module.scss';

import { AccessAlarm, DateRange } from '@mui/icons-material';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import clsx from 'clsx';
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';

import { ReactComponent as FilesIcon } from '@work4all/assets/icons/navigation/files.svg';
import { ReactComponent as HomeIcon } from '@work4all/assets/icons/navigation/home.svg';
import { ReactComponent as MoreIcon } from '@work4all/assets/icons/navigation/more.svg';

import { useModuleRights, useUser } from '@work4all/data';

import { HookedUserIcon } from '../../components/user-icon/useUserIconRegister';
import { isTimeTrackingUser } from '../../utils/isTimeTrackingUser';

import { MobileNavigationItem } from './MobileNavigationItem';
import { useMobileNavigation } from './useMobileNavigation';
import { useRenderMobileConfig } from './useRenderMobileConfig';

const MobileNavContext = React.createContext<{
  show?: () => void;
  hide?: () => void;
  visible: boolean;
}>({ visible: true });

export const MobileNavContextProvider: React.FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [visible, setVisible] = useState(true);
  const hide = useCallback(() => {
    setVisible(false);
  }, []);
  const show = useCallback(() => {
    setVisible(true);
  }, []);
  return (
    <MobileNavContext.Provider
      value={{
        show,
        hide,
        visible,
      }}
    >
      {children}
    </MobileNavContext.Provider>
  );
};

export const useMobileNav = () => {
  return useContext(MobileNavContext);
};

export const MobileNavigation: React.FC = () => {
  const { visible } = useMobileNav();
  const { home, more, files, calendar, vacation, profile, timetracking } =
    useMobileNavigation();

  const user = useUser();
  const { rights } = useModuleRights();

  const renderMobileConfig = useRenderMobileConfig();

  if (!renderMobileConfig) {
    return null;
  }

  return (
    <div className={clsx(styles['navigation'], { [styles.hidden]: !visible })}>
      <div className={styles['navigation__inner']}>
        {home && !isTimeTrackingUser(rights) && (
          <MobileNavigationItem navigation={home} icon={<HomeIcon />} />
        )}
        {files.length ? (
          <MobileNavigationItem
            navigation={{
              pathname: files.map((x) => x.pathname),
              title: 'NAVIGATION.FILES',
            }}
            icon={<FilesIcon />}
          />
        ) : null}
        {calendar ? (
          <MobileNavigationItem navigation={calendar} icon={<DateRange />} />
        ) : null}
        {more && <MobileNavigationItem navigation={more} icon={<MoreIcon />} />}
        {timetracking && (
          <MobileNavigationItem
            navigation={timetracking}
            icon={<AccessAlarm />}
          />
        )}
        {vacation && (
          <MobileNavigationItem
            navigation={vacation}
            icon={<FlightTakeoffIcon />}
          />
        )}
        {profile && (
          <MobileNavigationItem
            navigation={profile}
            icon={<HookedUserIcon size="l" userId={user.benutzerCode} />}
          />
        )}
      </div>
    </div>
  );
};
