import { IconButton } from '@mui/material';
import React, { useRef } from 'react';

import { ReactComponent as TextmarkIcon } from '@work4all/assets/icons/textmark.svg';

import { reactRefSetter } from '@work4all/utils';

import { EntityPickerPopover } from '../../entity-picker/components/EntityPickerPopover';
import {
  TextmarkPicker,
  TextmarkPickerProps,
} from '../../entity-picker/textmark-picker';

export const TextmarkPickerButton = React.forwardRef<
  HTMLDivElement,
  TextmarkPickerProps
>(function TextmarkPickerButton(props: TextmarkPickerProps, ref) {
  const wrapperRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={reactRefSetter(wrapperRef, ref)}>
      <EntityPickerPopover
        anchorEl={wrapperRef}
        picker={<TextmarkPicker {...props} />}
      >
        <IconButton size={props?.iconSize ?? 'large'}>
          <TextmarkIcon />
        </IconButton>
      </EntityPickerPopover>
    </div>
  );
});
