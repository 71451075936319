import { NavigationCategory } from '../types';

import schema from './control-center-schema.json';

export const useControlCenterSchema = () => {
  return {
    $schema: './navigation-links.schema.json',
    data: schema as NavigationCategory[],
  };
};
