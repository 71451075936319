import React, { useCallback, useMemo } from 'react';
import { TableInstance } from 'react-table';

import { useTableStateBag } from '@work4all/components';

import { Contract } from '@work4all/models/lib/Classes/Contract.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { EntityTable, IEntityTable } from './entity-table/EntityTable';
import { useEntityDataTable } from './entity-table/use-entity-data-table';
import { useCloneConvertErp } from './offers-table/hooks/use-clone-convert-erp';
import schema from './schemata/contract-table-schema.json';
import { useErpHandlersTemplate } from './use-erp-handlers-template';

type Props = IEntityTable;
const defaultSort = [
  { field: 'contractDate', direction: SortDirection.DESCENDING },
];

export const ContractsTable = React.forwardRef<TableInstance, Props>(
  (props, ref) => {
    const { ...rest } = props;

    const { prepareRowDisplayModifiers, ...dataTable } = useEntityDataTable<
      Contract,
      never
    >({
      schema: schema as never,
      defaultSort,
      enableFooter: true,
    });

    const template = useErpHandlersTemplate();

    const rowModifiers = useCallback(
      (value: Contract) => {
        const modifiers = prepareRowDisplayModifiers(value);
        return {
          ...modifiers,
          isFaded: value.isClosed && value.frozen,
          isShade1: !value.isClosed && value.frozen,
        };
      },
      [prepareRowDisplayModifiers]
    );

    const tableStateBag = useTableStateBag();
    const { actions, component } = useCloneConvertErp({
      entity: Entities.contract,
      data: useMemo(
        () =>
          tableStateBag.tableInstance?.selectedFlatRows.map(
            (x) => x.original as Contract
          ),
        [tableStateBag]
      ),
    });

    const custom = useMemo(
      () => ({
        left: actions,
      }),
      [actions]
    );

    return (
      <>
        {component}
        <EntityTable
          ref={ref}
          template={template}
          {...dataTable}
          actions={{
            custom,
          }}
          prepareRowDisplayModifiers={rowModifiers}
          displayFooter
          {...rest}
        />
      </>
    );
  }
);
