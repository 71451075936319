import styles from '../EntityPicker.module.scss';

import { Box, Typography } from '@mui/material';
import { concat } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSetting } from '@work4all/data/lib/settings';
import {
  EntityPickerSettings,
  sortPickerEntities,
} from '@work4all/data/lib/settings/settings';

import { BusinessPartner } from '@work4all/models/lib/Classes/BusinessPartner.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { Tooltip } from '../../tooltip/Tooltip';
import { WebsiteFavicon } from '../../website-favicon/WebsiteFavicon';
import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerWithTabsProps } from '../types';
import { Selection } from '../utils/selection-model';

export type IBusinessPartnerPickerProps<TMultiple extends boolean = boolean> =
  IPickerWithTabsProps<Customer | Supplier, TMultiple> & {
    onTabSelected?: (entity: Entities) => void;
    favorites?: boolean;
  };

const settings: EntityPickerSettings = {
  filter: {
    options: [
      {
        key: 'extinct',
        query: { $eq: 0 },
      },
    ],
  },
  sort: {
    options: ['name', 'number'],
  },
};

export function BusinessPartnerPicker<TMultiple extends boolean = boolean>(
  props: IBusinessPartnerPickerProps<TMultiple>
) {
  const {
    data,
    prefilter,
    onChange,
    filterConfig,
    onTabSelected,
    favorites = false,
    ...rest
  } = props;

  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(Entities.customer);

  useEffect(() => {
    onTabSelected?.(selectedTab);
  }, [selectedTab, onTabSelected]);

  const selectedTabType =
    selectedTab === Entities.customer ? 'Kunde' : 'Lieferant';

  const extendedFilterConfig = useMemo(() => {
    if (!filterConfig) return filterConfig;

    const businessPartnerTypes = {
      [Entities.customer]: SdObjType.KUNDE,
      [Entities.supplier]: SdObjType.LIEFERANT,
    };

    const typeFilter = {
      $or: [
        { businessPartnerType: { $eq: businessPartnerTypes[selectedTab] } },
      ],
    };

    const filter = concat(...[filterConfig.filter, typeFilter].filter(Boolean));

    return { ...filterConfig, filter };
  }, [filterConfig, selectedTab]);

  const allFields = useMemo(() => {
    return { ...FIELDS, ...data };
  }, [data]);

  const normalizeValue = useCallback(
    (
      item: Customer | Supplier | BusinessPartner,
      __typename: string
    ): Customer | Supplier => {
      if (!props.filterConfig) return item as Customer | Supplier;

      return 'data' in item
        ? ({ ...item.data, __typename } as Customer | Supplier)
        : ({ ...item, __typename: item.__typename || __typename } as
            | Customer
            | Supplier);
    },
    [props.filterConfig]
  );

  const mappedChange = (value) => {
    if (!value) return onChange(value);

    const normalizedValue = Array.isArray(value)
      ? value.map((x) => normalizeValue(x, selectedTabType))
      : normalizeValue(value, selectedTabType);

    onChange(normalizedValue as Selection<Customer | Supplier, TMultiple>);
  };

  const { value: sortedPickerEntities } = useSetting(
    sortPickerEntities({
      entityType: selectedTabType === 'Kunde' ? 'customer' : 'supplier',
      defaultValue: settings?.sort.options?.[0],
    })
  );

  const renderItem = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (item: any) => {
      const normalizedItem = normalizeValue(item, selectedTabType);
      const number = normalizedItem.number;
      const name = normalizedItem.name;
      const extinct = normalizedItem.extinct;
      return (
        <Tooltip title={name} placement="top">
          <Box display="flex" gap="0.5rem" alignItems="center">
            {item && <WebsiteFavicon website={item?.website} />}{' '}
            {sortedPickerEntities === 'name' ? (
              <Box
                display="flex"
                justifyContent="space-between"
                width="calc(100% - 24px)"
                className={extinct === -1 && styles.greyedStrikeThrough}
              >
                <Typography
                  variant="body2"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  flex={1}
                  mr={2}
                >
                  {name || t('COMMON.NONE')}
                </Typography>
                {number ? (
                  <Typography
                    variant="body2"
                    flexShrink={0}
                    className={styles.secondaryInfo}
                  >
                    {number}
                  </Typography>
                ) : null}
              </Box>
            ) : (
              <Box
                display="flex"
                width="calc(100% - 24px)"
                gap={0.75}
                className={extinct === -1 && styles.greyedStrikeThrough}
              >
                {number ? (
                  <Typography
                    variant="body2"
                    display="flex"
                    flexShrink={0}
                    className={styles.secondaryInfo}
                  >
                    {number} |
                  </Typography>
                ) : null}
                <Typography
                  variant="body2"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  flex={1}
                >
                  {name || t('COMMON.NONE')}
                </Typography>
              </Box>
            )}
          </Box>
        </Tooltip>
      );
    },
    [normalizeValue, selectedTabType, sortedPickerEntities, t]
  );

  const tabs = useMemo(() => {
    return [
      {
        entity: Entities.customer,
        data: allFields,
        label: t('COMMON.CUSTOMER'),
        placeholder: t('CUSTOMER.SEARCH_PLACEHOLDER'),
        filterBy: ['name', 'number'],
        sortBy: sortedPickerEntities, // ToDo: remove field when all pickers ready - default sort moved to 'settings'
        useSearchHistory: true,
        prefilter,
        renderItemContent: renderItem,
        favorites,
        settings,
      },
      {
        entity: Entities.supplier,
        data: allFields,
        label: t('COMMON.SUPPLIER'),
        placeholder: t('SUPPLIER.SEARCH_PLACEHOLDER'),
        filterBy: ['name', 'number'],
        sortBy: sortedPickerEntities, // ToDo: remove field when all pickers ready - default sort moved to 'settings'
        useSearchHistory: true,
        prefilter,
        renderItemContent: renderItem,
        favorites,
        settings,
      },
    ];
  }, [allFields, favorites, prefilter, renderItem, sortedPickerEntities, t]);

  useEffect(() => {
    props.onTabChange?.(selectedTab);
  }, [props, selectedTab]);

  return (
    <ListEntityPicker
      {...rest}
      onChange={mappedChange}
      getTabIndex={getTabIndex}
      onTabChange={setSelectedTab}
      tabs={tabs}
      filterConfig={extendedFilterConfig}
    />
  );
}

const FIELDS: Customer | Supplier = {
  id: null,
  name: null,
  note: null,
  number: null,
  extinct: null,
  website: null,
  mainContact: {
    id: null,
    name: null,
    firstName: null,
    displayName: null,
    eMail: null,
    phoneNumber: null,
    phoneNumber2: null,
    phoneNumber3: null,
    mobileNumber: null,
    mobileNumberPrivate: null,
    faxNumber: null,
    phoneNumberPrivate: null,
    businessPartnerId: null,
    businessPartnerType: null,
  },
};

function getTabIndex(value: ICustomerOrSupplierWithTypename): 0 | 1 {
  return value.__typename === 'Lieferant' ? 1 : 0;
}

type ICustomerOrSupplierWithTypename =
  | (Customer & { __typename: 'Kunde' })
  | (Supplier & { __typename: 'Lieferant' });
