import React from 'react';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  EntityPicker,
  IEntityPicker,
} from '@work4all/components/lib/components/entity-picker/EntityPicker';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

interface Props extends Omit<IEntityPicker<false>, 'multiple'> {
  label?: string;
  error?: string;
  disabled?: boolean;
}

/**
 * Generic Picker Field to reuse for simple pickers.
 * TODO: https://work4all.atlassian.net/browse/WW-4012
 */

export const EntityPickerField = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { label, error, disabled, ...pickerProps } = props;
    const { value, onChange } = pickerProps;

    return (
      <EntityPickerPopover
        picker={<EntityPicker {...pickerProps} multiple={false} />}
      >
        <PickerTargetButton
          ref={ref}
          disabled={disabled}
          onClear={
            props.clearable
              ? () => {
                  onChange(null);
                }
              : null
          }
          label={label}
          error={error}
          value={value?.name || ''}
        />
      </EntityPickerPopover>
    );
  }
);
