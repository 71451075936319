import { Typography } from '@mui/material';
import { useMemo } from 'react';

import { LedgerAccount } from '@work4all/models/lib/Classes/LedgerAccount.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Tooltip } from '../../tooltip/Tooltip';
import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '../types';

export type ILedgerAccountPickerProps<TMultiple extends boolean> = IPickerProps<
  LedgerAccount,
  TMultiple
>;

export function LedgerAccountPicker<TMultiple extends boolean>(
  props: ILedgerAccountPickerProps<TMultiple>
) {
  const { data, ...rest } = props;

  const allFields = useMemo(() => {
    return { ...LEDGER_ACCOUNT_FIELDS, ...data };
  }, [data]);

  const renderItem = (ledgerAccount: LedgerAccount) => {
    const label = `${ledgerAccount?.number} | ${ledgerAccount?.name}`;

    return (
      <Tooltip title={label}>
        <Typography variant="body2" noWrap>
          {label}
        </Typography>
      </Tooltip>
    );
  };

  return (
    <ListEntityPicker
      entity={Entities.ledgerAccount}
      data={allFields}
      filterBy={['name', 'number']}
      sortBy="number"
      sortByDirection={SortDirection.ASCENDING}
      {...rest}
      renderItemContent={renderItem}
    />
  );
}

const LEDGER_ACCOUNT_FIELDS: LedgerAccount = {
  id: null,
  name: null,
  number: null,
  short: null,
  disabled: null,
};
