/**
 * The backend team can't provide a way to filter the `mention` notifications
 * that belong to a deleted object at the moment, so we are filtering on our side.
 */

import { Mention } from '@work4all/models/lib/Classes/Mention.entity';
import { Notification } from '@work4all/models/lib/Classes/Notification.entity';
import { NotificationType } from '@work4all/models/lib/Enums/NotificationType.enum';

export const filterNotifications = (
  notifications: Notification[]
): Notification[] => {
  return notifications.filter(
    (notification) =>
      notification.notificationType !== NotificationType.MENTION ||
      (notification.object as Mention).parentObject !== null
  );
};
