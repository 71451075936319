import React from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  ITicketKindPickerProps,
  TicketKindPicker,
} from '@work4all/components/lib/components/entity-picker/TicketKindPicker';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

interface Props extends Omit<ITicketKindPickerProps<false>, 'multiple'> {
  error?: string;
}

export const TicketKindPickerField = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { error, ...pickerProps } = props;
    const { value, onChange } = pickerProps;

    const { t } = useTranslation();

    return (
      <EntityPickerPopover
        picker={<TicketKindPicker {...pickerProps} multiple={false} />}
      >
        <PickerTargetButton
          ref={ref}
          onClear={() => {
            onChange(null);
          }}
          label={t('INPUTS.TICKET_KIND')}
          error={error}
          value={value?.name || ''}
        />
      </EntityPickerPopover>
    );
  }
);
