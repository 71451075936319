import sanitizeHtml from 'sanitize-html';

import {
  extractMentions,
  ExtractMentionsResolveFn,
} from '../../../../../../input/format-text/TextEditor/plugins/mention/utils';

export const processHtml = (
  html: string,
  resolve: ExtractMentionsResolveFn
) => {
  const withMentions = extractMentions(html, resolve);
  const sanitized = sanitizeHtml(withMentions, {
    allowedTags: [
      'img',
      'p',
      'div',
      'span',
      'table',
      'tr',
      'td',
      'th',
      'strong',
      'u',
      'em',
      'i',
      's',
      'li',
      'ol',
      'ul',
      'a',
    ],
    allowedAttributes: {
      span: ['data-mention', 'data-mention-user', 'contenteditable', 'class'],
      img: ['src', 'style'],
      a: ['href'],
      '*': ['style'],
    },
  });

  return sanitized;
};
