import styles from './List.module.scss';

import { List as MuiList } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

export const List: React.FC<{
  children?: React.ReactNode;
  className?: string;
}> = (props) => {
  return (
    <MuiList className={clsx(styles.list, props.className)}>
      {props.children}
    </MuiList>
  );
};
