import { useContext } from 'react';

import { PreviewInputsContext } from '../../contexts';

export const usePreviewInputsContext = () => {
  const context = useContext(PreviewInputsContext);

  if (!context) {
    throw new Error(
      'Preview inputs should be wrapped with `PreviewInputProvider`'
    );
  }

  return context;
};
