import styles from './ToolBar.module.scss';

import { Close, TableRows } from '@mui/icons-material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Theme, Typography, useMediaQuery } from '@mui/material';
import Fab from '@mui/material/Fab';
import { useEventCallback } from '@mui/material/utils';
import {
  ChangeEvent,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { actions as tableActions, TableInstance } from 'react-table';

import {
  ColumnInstance,
  DeleteEntitiesMaxCountAlertDialog,
  FilterType,
  useDeleteDialog,
  useTableStateBag,
} from '@work4all/components';
import { ConvertPopover } from '@work4all/components/lib/components/convert-popover/ConvertPopover';
import { IDragObject } from '@work4all/components/lib/components/draggable-item/DraggableItem';
import { ActionsBar } from '@work4all/components/lib/dataDisplay/actions-bar/ActionsBar';
import { useToolbarCustomActions } from '@work4all/components/lib/dataDisplay/actions-bar/hooks/use-toolbar-custom-actions';
import {
  ButtonWithLink,
  CustomToolbar,
  DisabledParams,
} from '@work4all/components/lib/dataDisplay/actions-bar/ToolbarTypes';
import {
  filterIsEmpty,
  genFilterTitle,
} from '@work4all/components/lib/dataDisplay/basic-table/utils/genFilterTitle';
import {
  SortAscIcon,
  SortDescIcon,
  ViewColumnSelectedIcon,
} from '@work4all/components/lib/icons';
import { IActionConfig } from '@work4all/components/lib/input/actions/types';
import {
  EventType,
  sendAmplitudeData,
} from '@work4all/components/lib/utils/amplitude/amplitude';

import { useNavigate } from '@work4all/data';
import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { reactRefSetter } from '@work4all/utils';
import { DndTypes } from '@work4all/utils/lib/variables';

import { ListPageContext } from '../../../../../containers/file-entities-lists/list-page-context';
import { HomeWidgetsContext } from '../../../../../containers/home/components/home-widgets/HomeWidgetsContextProvider';
import { HomeWidgetType } from '../../../../../containers/home/components/home-widgets/HomeWidgetType.enum';
import { settings, useSetting } from '../../../../../settings';
import { useTableLayoutState } from '../../../table-layout';
import { TableFilter } from '../../TableFilter';
import { WithBubble } from '../with-bubble/WithBubble';

import { ActionsBarMobile } from './components/actions-bar-mobile/ActionsBarMobile';
import { ActionConfig as ActionsMenuMobileActionConfig } from './components/actions-bar-mobile/ActionsMenuMobile';
import { ColumnsVisibility } from './components/columns-visibility';
import { FiltersOverlay } from './components/filters/filters-overlay/FiltersOverlay';
import { SelectedFiltersList } from './components/filters/selected-filters-list/SelectedFiltersList';
import { GroupingsVisibilty } from './components/groupings/GroupingsVisibilty';
import { SelectedGroupsList } from './components/groupings/selected-groups-list/SelectedGroupsList';
import { LayoutAction } from './components/layout-action/LayoutAction';
import { SearchAction } from './components/search-action/SearchAction';
import { SortColumnPopover } from './components/SortColumnPopover';
import { TableLayoutPopover } from './components/TableLayoutPopover';
import { TableSettingsOverlay } from './components/TableSettingsOverlay';

interface RemoveToolbarAction extends DisabledParams {
  maxCount: number;
  handler: (ids: string[]) => void;
  /**
   * When passing `canDeleteEntity` we will check every selected entity.
   */
  canDeleteEntity?: (id: string) => {
    /**
     * If `true` will show a confirmation dialog, if `false` will show a prevent dialog.
     */
    value: boolean;
    /**
     * The message for the prevent dialog.
     */
    preventMessage: string;
  };
}

interface ConvertToolbarAction extends DisabledParams {
  exclude: Entities;
  handler: (entity: Entities) => void;
}

interface OpenPaymentMaskToolbarAction extends DisabledParams {
  handler: () => void;
}

export interface IToolBar {
  mobile?: boolean;
  hideLayoutSelect?: boolean;
  tableInstanceRef: React.RefObject<TableInstance>;
  exportCSV?: JSX.Element;
  actions?: {
    add?: ButtonWithLink;
    edit?: ButtonWithLink<string> | null;
    remove?: RemoveToolbarAction | null;
    createWidget?: boolean;
    convert?: ConvertToolbarAction | null;
    transform?: ((ids: string[]) => void) | null;
    export?: ((ids: string[]) => void) | null;
    resetColumns?: () => void;
    custom?: CustomToolbar;
    openInNewTab?: {
      getPath: (id: string) => string;
    };
    /**
     * Default: true.
     */
    groupingEnabled?: boolean;
    clone?: {
      handler: () => void;
      disabled: boolean;
    };
    openPaymentMask?: OpenPaymentMaskToolbarAction;
  };
}

const emptyColumns: ColumnInstance[] = [];
const emptyGroupBy: string[] = [];

export const Toolbar: React.FC<IToolBar> = (props) => {
  const {
    tableInstanceRef,
    actions = {},
    mobile = false,
    hideLayoutSelect = false,
  } = props;
  const { tableState, columnsById, setUnderPressSelect } = useTableStateBag();
  const groupBy = tableState?.groupBy || emptyGroupBy;
  const { t } = useTranslation();
  const listPageContext = useContext(ListPageContext);

  const homeWidgetsContext = useContext(HomeWidgetsContext);
  const addWidget = homeWidgetsContext?.addWidget;
  const closedEntitiesSetting = useSetting(
    settings.hideClosedEntities({ entityType: listPageContext.entityType })
  );

  const setHideClosedEntitiesSetting = useEventCallback((value: boolean) => {
    closedEntitiesSetting.set(value);
  });

  const columns: ColumnInstance[] = useMemo(() => {
    const result = Object.keys(columnsById).map((key) => {
      return columnsById[key];
    });

    return [
      ...result.filter((c) => c.isPrimaryFilter),
      ...result.filter((c) => !c.isPrimaryFilter),
    ];
  }, [columnsById]);

  const chips = useMemo(() => {
    // visibleColumns don't change when filters are updated
    // to trigger memo update I pass additional filters value.
    return (
      columns
        // Filter non-selected and primary filters
        ?.filter((col) => !(!col.isPrimaryFilter && filterIsEmpty(col)))
        // Move all selected filters before empty ones.
        ?.sort((a, b) => {
          if (!filterIsEmpty(a) && filterIsEmpty(b)) {
            return -1;
          }
          return 0;
        })
        ?.map((col) => {
          return {
            title: filterIsEmpty(col)
              ? (col.Header as string)
              : genFilterTitle(col),
            readOnly: !!col.filterValue?.readOnly,
            isPrimaryFilter: col.isPrimaryFilter,
            renderFilter: (item) => (
              <TableFilter column={col}>{item}</TableFilter>
            ),
            handleDelete: filterIsEmpty(col)
              ? undefined
              : () => {
                  if (
                    (col as ColumnInstance).filterType ===
                    FilterType.ClosedStatus
                  ) {
                    col.setFilter({
                      filterType: FilterType.ClosedStatus,
                      value: false,
                    });

                    setHideClosedEntitiesSetting(false);
                  } else {
                    col.setFilter(null);
                  }
                },
          };
        })
        .filter(Boolean)
        // Move all read-only filter to the beginning of the list.
        .sort((a, b) => {
          if (a.readOnly && !b.readOnly) {
            return -1;
          }
          return 0;
        }) ?? []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableState?.filters]);

  const [, drop] = useDrop(
    () => ({
      accept: DndTypes.BASIC_TABLE_COL,
      drop: (draggedItem: IDragObject) => {
        tableInstanceRef.current?.setGroupBy([...groupBy, draggedItem.id]);
      },
    }),
    [groupBy]
  );

  const groupingsPopoverState = usePopoverState();
  const columnsPopoverState = usePopoverState();
  const convertPopoverState = usePopoverState(mobile ? 'top' : 'bottom');
  const sortColumnPopoverState = usePopoverState(mobile ? 'top' : 'bottom');
  const layoutPopoverState = usePopoverState(mobile ? 'top' : 'bottom');
  const [removeAlertDialogOpen, setRemoveAlertDialogOpen] = useState(false);

  const settingsRootRef = useRef<HTMLDivElement | HTMLButtonElement>();
  const filtersRootRef = useRef<HTMLDivElement | HTMLButtonElement>();
  const groupingsRootRef = useRef<HTMLDivElement | HTMLButtonElement>();
  const columnsRootRef = useRef<HTMLDivElement | HTMLButtonElement>();
  const convertRootRef = useRef<HTMLDivElement | HTMLButtonElement>();
  const mobileMenuButtonRef = useRef<HTMLButtonElement>(null);
  const desktopMenuButtonRef = useRef<HTMLButtonElement>(null);

  const [showFilterOverlay, setFilterOverlay] = useState(false);
  const [showSettingsOverlay, setSettingsOverlay] = useState(false);

  const selectedRowsIds = tableInstanceRef.current?.state.selectedRowIds;
  const selectedRowsIdsList = useMemo(() => {
    if (!selectedRowsIds) return [];

    return (
      Object.entries(selectedRowsIds)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .filter(([_id, isSelected]) => isSelected)
        .map(([id]) => id)
    );
  }, [selectedRowsIds]);

  const openColumnsPopover = columnsPopoverState.handleClick;
  const openConvertPopover = convertPopoverState.handleClick;
  const openGroupingPopover = groupingsPopoverState.handleClick;
  const openSortColumnPopover = sortColumnPopoverState.handleClick;
  const openLayoutPopover = layoutPopoverState.handleClick;

  const tableStateBag = useTableStateBag();

  const navigate = useNavigate();

  const handleSearchFilter = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!e?.target?.value || e?.target?.value.length === 0) {
        tableStateBag.setSearchFilterText('');
      } else {
        tableStateBag.setSearchFilterText(e?.target?.value);
      }
    },
    [tableStateBag]
  );

  const customMappedActions = useToolbarCustomActions({
    selectedRowsIdsList,
    custom: props?.actions?.custom,
  });

  const [layoutValue] = useTableLayoutState();

  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );

  const { showDeleteDialog, DeleteDialog } = useDeleteDialog();

  const mappedActions = useMemo(() => {
    const isSomethingSelected = selectedRowsIdsList.length > 0;
    const isExactlyOneSelected = selectedRowsIdsList.length === 1;

    const add = actions.add;
    const edit = actions.edit;
    const remove = actions.remove;
    const convert = actions.convert;
    const exportAction = actions.export;
    const transform = actions.transform;
    const createWidget = actions.createWidget;
    const clone = actions.clone;
    const openPaymentMask = actions.openPaymentMask;

    const config: Record<
      | 'add'
      | 'edit'
      | 'filter'
      | 'columns'
      | 'openPaymentMask'
      | 'remove'
      | 'convert'
      | 'group'
      | 'export'
      | 'go'
      | 'search'
      | 'sortColumn'
      | 'sortOrder'
      | 'layout'
      | 'createWidget'
      | 'clone'
      | 'settings',
      (IActionConfig & ActionsMenuMobileActionConfig) | null
    > = {
      add: add
        ? add.getHref
          ? {
              href: add.getHref(),
              disabled: add.disabled,
              disableReason: add.disableReason,
              dataTestId: `add-${listPageContext.entityType}`,
            }
          : {
              onClick: () => add.handler(),
              disabled: add.disabled,
              disableReason: add.disableReason,
            }
        : null,
      edit:
        edit && isExactlyOneSelected
          ? edit.getHref
            ? {
                href: edit.getHref(cleanIds(selectedRowsIdsList)[0]),
                disabled: edit.disabled,
                disableReason: edit.disableReason,
              }
            : {
                onClick: () => {
                  edit.handler(cleanIds(selectedRowsIdsList)[0]);
                },
                disableReason: edit.disableReason,
                disabled: edit.disabled,
              }
          : null,

      filter: tableStateBag?.tableInstance?.allColumns.find((x) => x.canFilter)
        ? {
            onClick: (e) => {
              e.preventDefault();
              e.stopPropagation();
              sendAmplitudeData(EventType.TabFilter, {
                name: listPageContext.entityType,
              });
              setFilterOverlay(true);
            },
            children: (
              <WithBubble count={mobile ? 0 : chips.length}>
                <SelectedFiltersList list={chips} />
              </WithBubble>
            ),
            rootRef: filtersRootRef,
          }
        : null,

      settings: {
        onClick: () => {
          setSettingsOverlay(true);
        },
        rootRef: settingsRootRef,
        label: t('SETTINGS.SETTINGS'),
      },

      columns: {
        onClick: (event) => {
          return openColumnsPopover(
            event,
            (mobile ? mobileMenuButtonRef : desktopMenuButtonRef).current
          );
        },
        rootRef: columnsRootRef,
        label: layoutValue === 'cards' ? t('MASK.ROWS') : undefined,
        icon: layoutValue === 'cards' ? <TableRows /> : undefined,
      },

      remove:
        remove && isSomethingSelected
          ? {
              onClick: async () => {
                let entitiesIdsToDelete = [];

                if (selectedRowsIdsList.length > remove.maxCount) {
                  setRemoveAlertDialogOpen(true);
                  return;
                } else if (remove.canDeleteEntity) {
                  for (const id of selectedRowsIdsList) {
                    const { value, preventMessage } =
                      remove.canDeleteEntity(id);
                    const userResponse = await showDeleteDialog({
                      ids: [id],
                      type: value ? 'confirm' : 'prevent',
                      preventMessage,
                    });

                    if (userResponse === 'delete') {
                      entitiesIdsToDelete.push(id);
                    }
                  }
                } else {
                  const userResponse = await showDeleteDialog({
                    ids: selectedRowsIdsList,
                    type: 'confirm',
                  });

                  if (userResponse === 'delete') {
                    entitiesIdsToDelete = selectedRowsIdsList;
                  }
                }

                if (entitiesIdsToDelete.length > 0) {
                  remove.handler(entitiesIdsToDelete);
                }
              },
              disabled: remove.disabled,
              disableReason: remove.disableReason,
              dataTestId: `delete-${listPageContext.entityType}`,
            }
          : null,

      convert:
        convert && isSomethingSelected
          ? {
              onClick: (event) => {
                openConvertPopover(
                  event,
                  (mobile ? mobileMenuButtonRef : convertRootRef).current
                );
              },
              margin: 'lg',
              rootRef: convertRootRef,
            }
          : null,

      group:
        actions.groupingEnabled === undefined || actions.groupingEnabled
          ? {
              onClick: (event) => {
                openGroupingPopover(
                  event,
                  (mobile ? mobileMenuButtonRef : desktopMenuButtonRef).current
                );
              },
              margin: 'lg',
              rootRef: reactRefSetter(groupingsRootRef, drop),
              children: (
                <WithBubble count={tableState?.groupBy?.length}>
                  <SelectedGroupsList
                    columnsById={columnsById}
                    tableInstanceRef={tableInstanceRef}
                    groupedBy={tableState?.groupBy}
                  />
                </WithBubble>
              ),
            }
          : undefined,

      export:
        exportAction && isSomethingSelected
          ? {
              onClick: () => {
                exportAction(selectedRowsIdsList);
              },
            }
          : null,

      go:
        transform && isSomethingSelected
          ? {
              onClick: () => {
                transform(selectedRowsIdsList);
              },
            }
          : null,

      search: {
        onClick: () => {
          console.warn("This shouldn't be used");
        },
        render: () => <SearchAction handleSearchFilter={handleSearchFilter} />,
      },
      sortColumn: (() => {
        const sortByColumn =
          (tableState?.sortBy.length ?? 0) > 0
            ? tableInstanceRef.current?.allColumns.find(
                (column) => column.id === tableState.sortBy[0].id
              )
            : null;

        const columnOrRow = layoutValue === 'cards' ? 'row' : 'column';

        return {
          onClick: (event) => {
            openSortColumnPopover(event, mobileMenuButtonRef.current);
          },
          label: sortByColumn
            ? t(`COMMON.SORT_${columnOrRow.toUpperCase()}`, {
                [columnOrRow]: sortByColumn.Header,
              })
            : t(`COMMON.SORT_${columnOrRow.toUpperCase()}_EMPTY`),
          icon: <ViewColumnSelectedIcon />,
        };
      })(),

      sortOrder:
        (tableState?.sortBy.length ?? 0) > 0
          ? {
              onClick: () => {
                tableInstanceRef.current.setSortBy([
                  {
                    id: tableState.sortBy[0].id,
                    desc: !tableState.sortBy[0].desc,
                  },
                ]);
              },
              label: tableState.sortBy[0].desc
                ? t('COMMON.SORT_ORDER_DESC')
                : t('COMMON.SORT_ORDER_ASC'),
              icon: tableState.sortBy[0].desc ? (
                <SortDescIcon />
              ) : (
                <SortAscIcon />
              ),
            }
          : null,

      layout: !hideLayoutSelect
        ? {
            onClick: (event) => {
              openLayoutPopover(
                event,
                (mobile ? mobileMenuButtonRef : desktopMenuButtonRef).current
              );
            },
            render: mobile ? undefined : () => <LayoutAction />,
            label: t('COMMON.LAYOUT_MODE'),
            icon: <RemoveRedEyeIcon />,
          }
        : undefined,

      createWidget:
        createWidget && !isMobile
          ? {
              onClick: () => {
                const url = new URL(window.location.href);
                const presetFields = url.searchParams.get('presetFields');
                const customizedTitle = url.searchParams.get('customizedTitle');

                addWidget({
                  id: new Date().toISOString(),
                  type: HomeWidgetType.entityWidget,
                  title:
                    customizedTitle ||
                    t(`COMMON.${listPageContext.entityType.toUpperCase()}`, {
                      count: 2,
                    }),
                  definition: {
                    entity: listPageContext.entityType,
                    filters: tableStateBag.tableState.filters,
                    presetFields,
                    customizedTitle,
                  },
                });
                navigate('/home');
              },
            }
          : null,

      clone:
        clone && isSomethingSelected
          ? { onClick: clone.handler, disabled: clone.disabled }
          : null,

      openPaymentMask: openPaymentMask
        ? {
            onClick: openPaymentMask.handler,
            disabled: openPaymentMask.disabled,
          }
        : null,
    };

    return config;
  }, [
    selectedRowsIdsList,
    actions.add,
    actions.edit,
    actions.remove,
    actions.convert,
    actions.export,
    actions.transform,
    actions.createWidget,
    actions.groupingEnabled,
    tableStateBag?.tableInstance?.allColumns,
    tableStateBag.tableState,
    chips,
    layoutValue,
    t,
    drop,
    tableState?.groupBy,
    tableState?.sortBy,
    columnsById,
    tableInstanceRef,
    mobile,
    listPageContext.entityType,
    openColumnsPopover,
    openConvertPopover,
    openGroupingPopover,
    handleSearchFilter,
    openSortColumnPopover,
    openLayoutPopover,
    addWidget,
    navigate,
    isMobile,
    actions.clone,
    showDeleteDialog,
    actions.openPaymentMask,
    hideLayoutSelect,
  ]);

  const removeGroupingHandler = useCallback(() => {
    tableInstanceRef.current.allColumns.forEach(
      (col) => col.isGrouped && col.toggleGroupBy()
    );
  }, [tableInstanceRef]);

  return (
    <>
      {!mobile && tableInstanceRef.current?.selectedFlatRows.length > 1 && (
        <Fab
          variant="extended"
          className={styles.resetSelectionFab}
          size="medium"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            tableInstanceRef?.current?.dispatch({
              type: tableActions.resetSelectedRows,
            });
          }}
        >
          <Close sx={{ mr: 1 }} />
          <Typography variant="body1">Auswahl aufheben</Typography>
        </Fab>
      )}
      {showFilterOverlay && (
        <FiltersOverlay
          overlayOpen={showFilterOverlay}
          onClose={() => setFilterOverlay(false)}
        />
      )}
      <TableSettingsOverlay
        entityType={listPageContext.entityType}
        isOpen={showSettingsOverlay}
        close={() => setSettingsOverlay(false)}
      />

      <GroupingsVisibilty
        popoverState={groupingsPopoverState}
        tableInstanceRef={tableInstanceRef}
        visibleColumns={tableInstanceRef.current?.allColumns}
        onResetColumns={removeGroupingHandler}
      />
      <ColumnsVisibility
        popoverState={columnsPopoverState}
        tableInstanceRef={tableInstanceRef}
        visibleColumns={tableInstanceRef.current?.allColumns ?? emptyColumns}
        onResetColumns={actions.resetColumns}
        title={layoutValue === 'cards' ? t('MASK.ROWS') : undefined}
      />
      <SortColumnPopover
        sortBy={tableState?.sortBy}
        popoverState={sortColumnPopoverState}
        tableInstanceRef={tableInstanceRef}
        visibleColumns={tableInstanceRef.current?.allColumns ?? emptyColumns}
      />
      <TableLayoutPopover popoverState={layoutPopoverState} />

      {actions.remove ? (
        <DeleteEntitiesMaxCountAlertDialog
          open={removeAlertDialogOpen}
          maxCount={actions.remove.maxCount}
          onClose={() => setRemoveAlertDialogOpen(false)}
        />
      ) : null}

      {actions.remove ? <DeleteDialog /> : null}

      {actions.convert ? (
        <ConvertPopover
          exclude={actions.convert.exclude}
          onClick={(entity) => {
            actions.convert.handler(entity);
            convertPopoverState.onClose();
          }}
          popoverState={convertPopoverState}
        />
      ) : null}

      {mobile ? (
        <ActionsBarMobile
          actions={actions}
          mappedActions={mappedActions}
          onSearchFilter={handleSearchFilter}
          openFilterOverlay={() => setFilterOverlay(true)}
          onUnderPressDeselect={() => setUnderPressSelect(false)}
          filtersCount={chips.length}
          mobile={mobile}
          tableInstanceRef={tableInstanceRef}
          mobileMenuButtonRef={mobileMenuButtonRef}
        />
      ) : (
        <div className={styles.root}>
          <ActionsBar
            margin="xs"
            {...mappedActions}
            isGrouped={tableState?.groupBy?.length > 0}
            menuButtonRef={desktopMenuButtonRef}
            customActions={customMappedActions}
          />
        </div>
      )}
    </>
  );
};

export const cleanIds = (ids: string[]) => {
  return ids.filter(Boolean).map((id) => {
    if (!id.includes('.')) return id;
    const parts = id.split('.');
    return parts[parts.length - 1];
  });
};
