import styles from './FooterWrapper.module.scss';

import clsx from 'clsx';
import React from 'react';

interface IFooterWrapperProps {
  children?: React.ReactNode;
  className?: string;
  elementRef?: React.ForwardedRef<HTMLDivElement>;
}

export const FooterWrapper: React.FC<IFooterWrapperProps> = (props) => {
  const className = clsx(styles.root, props.className);
  return (
    <div ref={props.elementRef} className={className}>
      {props.children}
    </div>
  );
};
